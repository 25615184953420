import ProductSelect, {
  ProductOption,
} from 'components/ProductSelect/ProductSelect';
import { FilterDetail, FilterItem, useFilter } from 'features/filter';
import { OrderFilterItem, OrderFilterKeys } from 'features/order-filter/types';
import { useGetProducts } from 'features/product-list';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { MultiValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import getProductPriceByLanguage from 'utils/get-product-price-by-language';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';

const ProductFilter = () => {
  const { setFilter, filter } = useFilter<OrderFilterKeys, OrderFilterItem>();
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetProducts(
    debouncedSearchTerm as string,
  );
  const language = useReduxSelector(selectLanguageCode);

  const handleChange = (newValue: MultiValue<ProductOption>) => {
    setFilter(
      'productIds',

      newValue?.map(({ value, label }) => {
        const findProduct = data?.pages
          .map((i) => i.data)
          .flat()
          .find((i) => i.id.toString() === value);
        const {
          productLocalisations = [],
          isAvailable,
          vendorProductId,
        } = findProduct || {};
        return {
          id: value,
          name: label,
          // Add product props to fill the custom ProductOption
          // The already selected products, that may not be found in the filtered data array (findProduct = undefined),
          // will be populated by the previous state in the useVideoFilter hook in setFilterByKey
          productProps: findProduct
            ? {
                label: getProductTitleByLanguage(
                  productLocalisations,
                  language,
                ),
                price: getProductPriceByLanguage(
                  productLocalisations,
                  language,
                ),
                isAvailable,
                subtitle: vendorProductId,
              }
            : undefined,
        } as FilterItem;
      }),
    );
  };

  const getValue = () => {
    return (
      (filter?.productIds
        ?.map(({ id, name, productProps }) => {
          return {
            value: id.toString(),
            ...productProps,
            label: productProps?.label || name,
          };
        })
        .filter((i) => i !== undefined) as ProductOption[]) || []
    );
  };

  const infiniteProps = {
    onLoadMore: () => fetchNextPage(),
    hasNextPage,
    filterOption: () => true,
    ...searchableProps,
  };

  return (
    <FilterDetail>
      <ProductSelect
        {...infiniteProps}
        isMulti
        variant="open"
        products={data?.pages.map((i) => i.data).flat()}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        language={language}
        optionTheme="select"
        name="product-filter"
      />
    </FilterDetail>
  );
};

export default ProductFilter;
