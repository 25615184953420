import SelectInteractive, { DefaultOption } from 'components/SelectInteractive';
import { InfiniteScrollProps } from 'components/SelectInteractive/SelectInteractive';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { FilterItem } from 'features/video-filter/types';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import { useGetCategoryFilter } from '../../queries';
import FilterDetail from './FilterDetail';
import { useState } from 'react';

const CategoryFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const [isInfinite, setIsInfinite] = useState(true);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetCategoryFilter(
    debouncedSearchTerm as string,
  );
  const language = useReduxSelector(selectLanguageCode);

  const handleChange = (newValue: MultiValue<DefaultOption>) => {
    setFilter(
      'category',
      newValue?.map(
        ({ value, label }) =>
          ({
            id: value,
            name: label,
          } as FilterItem),
      ),
    );
  };

  const getValue = () => {
    return (
      filter?.category?.map(({ id, name }) => {
        return { value: id.toString(), label: name };
      }) || []
    );
  };

  const getOptions = () => {
    const d = data?.pages.map((i) => i.data).flat() || [];
    return d.map((i) => ({
      value: i.id.toString(),
      label: i.name,
    }));
  };

  const loadMore = () => {
    fetchNextPage();
  };

  const infiniteScrollProps: InfiniteScrollProps = {
    dataLength: data?.pages.map((i) => i.data).flat().length || 0,
    next: () => loadMore(),
    hasMore: hasNextPage || false,
  };

  const infiniteProps = isInfinite
    ? {
        infiniteScrollProps,
        filterOption: () => true,
        ...searchableProps,
      }
    : {};

  return (
    <FilterDetail>
      <SelectInteractive
        {...infiniteProps}
        filterOption={() => true}
        isMulti
        variant="open"
        options={getOptions()}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        placeholder={t('video:filter.channel.placeholder')}
        infiniteScrollTargetId="sidebar--scroll-target"
        isOpenSelect
        noOptionsMessage={
          data?.pages.map((i) => i.data).flat().length === 0 &&
          (debouncedSearchTerm?.length || 0) === 0
            ? t('video:filter.category.empty')
            : t('noOptions')
        }
      />
    </FilterDetail>
  );
};

export default CategoryFilter;
