import classNames from 'classnames';
import { Spacer, SpacerProps } from 'components/Layout/Layout';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props {
  children?: ReactNode;
  message?: string;
  spacerProps?: SpacerProps;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props & WithTranslation, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Spacer
          tag="p"
          margin={[2, 0]}
          padding={[1, 2]}
          {...this.props.spacerProps}
          className={classNames(
            'bg-dark-10',
            'c-dark-50',
            this.props.spacerProps?.className,
          )}
          style={{
            borderRadius: '4px',
            display: 'inline-block',
            ...this.props.spacerProps?.style,
          }}
        >
          {this.props.message || this.props.t('errorBoundary')}
        </Spacer>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
