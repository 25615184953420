import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import ImageInput from '../../../components/ImageInput/ImageInput';
import Input from '../../../components/Input/Input';
import { InputProps } from '../../../types/InputType';
import getFormError from '../../../utils/get-form-error';

export type LandingPageSettingsFormValues = {
  title: string;
  paragraph: string;
  image: string;
  jooliLink: string;
  jooliImprintLink: string;
  jooliPrivacyPolicyLink: string;
};

const LandingPageSettingsForm = ({
  onSubmit,
  edit,
}: {
  onSubmit: (values: LandingPageSettingsFormValues) => void;
  edit?: LandingPageSettingsFormValues;
}) => {
  const { t } = useTranslation(['translation', 'settings']);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('form.required')),
    paragraph: Yup.string().required(t('form.required')),
    jooliLink: Yup.string().required(t('form.required')).url(t('form.url')),
    jooliImprintLink: Yup.string()
      .required(t('form.required'))
      .url(t('form.url')),
    jooliPrivacyPolicyLink: Yup.string()
      .required(t('form.required'))
      .url(t('form.url')),
    image: Yup.string().required(t('form.required')),
  });

  const initialValues: LandingPageSettingsFormValues = edit || {
    title: '',
    paragraph: '',
    image: '',
    jooliLink: '',
    jooliImprintLink: '',
    jooliPrivacyPolicyLink: '',
  };

  const landingPageSettingsForm = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const titleInputProps: InputProps = {
    ...landingPageSettingsForm.getFieldProps('title'),
    label: t(`settings:landingPageSettingsForm.labels.title`),
    placeholder: t(`settings:landingPageSettingsForm.placeholders.title`),
    error: getFormError(landingPageSettingsForm, 'title'),
  };

  const paragraphInputProps: InputProps = {
    ...landingPageSettingsForm.getFieldProps('paragraph'),
    label: t(`settings:landingPageSettingsForm.labels.paragraph`),
    placeholder: t(`settings:landingPageSettingsForm.placeholders.paragraph`),
    error: getFormError(landingPageSettingsForm, 'paragraph'),
  };

  const imageInputProps: InputProps & { placeholder: string } = {
    ...landingPageSettingsForm.getFieldProps('image'),
    label: t(`settings:landingPageSettingsForm.labels.image`),
    placeholder: t(`settings:landingPageSettingsForm.placeholders.image`),
    error: getFormError(landingPageSettingsForm, 'image'),
  };

  const jooliLinkInputProps: InputProps = {
    ...landingPageSettingsForm.getFieldProps('jooliLink'),
    label: t(`settings:landingPageSettingsForm.labels.jooliLink`),
    placeholder: t(`settings:landingPageSettingsForm.placeholders.jooliLink`),
    error: getFormError(landingPageSettingsForm, 'jooliLink'),
  };

  const jooliImprintLinkInputProps: InputProps = {
    ...landingPageSettingsForm.getFieldProps('jooliImprintLink'),
    label: t(`settings:landingPageSettingsForm.labels.jooliImprintLink`),
    placeholder: t(
      `settings:landingPageSettingsForm.placeholders.jooliImprintLink`,
    ),
    error: getFormError(landingPageSettingsForm, 'jooliImprintLink'),
  };

  const jooliPrivacyPolicyLinkInputProps: InputProps = {
    ...landingPageSettingsForm.getFieldProps('jooliPrivacyPolicyLink'),
    label: t(`settings:landingPageSettingsForm.labels.jooliPrivacyPolicyLink`),
    placeholder: t(
      `settings:landingPageSettingsForm.placeholders.jooliPrivacyPolicyLink`,
    ),
    error: getFormError(landingPageSettingsForm, 'jooliPrivacyPolicyLink'),
  };

  const handleImageChange = (paths: { fileUrl: string; filePath: string }) => {
    landingPageSettingsForm.setFieldValue('image', paths.fileUrl);
  };

  const handleImageDelete = () => {
    landingPageSettingsForm.setFieldValue('image', '');
  };

  return (
    <form onSubmit={landingPageSettingsForm.handleSubmit}>
      <div className="landing-page__form-inner">
        <div className="landing-page__form-column--1">
          <div className="settings__block settings__block--75">
            <Input {...titleInputProps} />
          </div>

          <div className="settings__block settings__block--75">
            <Input {...paragraphInputProps} />
          </div>

          <div className="settings__block settings__block--75">
            <Input {...jooliLinkInputProps} />
          </div>

          <div className="settings__block settings__block--75">
            <Input {...jooliImprintLinkInputProps} />
          </div>

          <div className="settings__block settings__block--75">
            <Input {...jooliPrivacyPolicyLinkInputProps} />
          </div>
        </div>

        <div className="landing-page__form-column--2">
          <div className="settings__block">
            <ImageInput
              {...imageInputProps}
              className="landing-page__form-image-wrap"
              onChange={handleImageChange}
              maxFileSize={3_000_000}
            />
          </div>
        </div>
      </div>

      <Button type="submit" text={edit ? t('save') : t('settings:add')} />
    </form>
  );
};

export default LandingPageSettingsForm;
