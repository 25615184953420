/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoryResponseMinimalDTO } from '../models/CategoryResponseMinimalDTO';
import type { CategoryTreeDto } from '../models/CategoryTreeDto';
import type { PaginatedGetCategoriesResponseDto } from '../models/PaginatedGetCategoriesResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CategoriesService {

    /**
     * Gets paginated and searchable Categories
     * @param offset
     * @param limit
     * @param searchTerm
     * @returns PaginatedGetCategoriesResponseDto
     * @throws ApiError
     */
    public static getCategories(
        offset?: number,
        limit?: number,
        searchTerm?: string,
    ): CancelablePromise<PaginatedGetCategoriesResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories',
            query: {
                'offset': offset,
                'limit': limit,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Gets the Category tree from a given root Category
     * @param rootCategoryId
     * @param depth
     * @returns CategoryTreeDto
     * @throws ApiError
     */
    public static getCategoryTree(
        rootCategoryId?: number,
        depth?: number,
    ): CancelablePromise<Array<CategoryTreeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories/tree',
            query: {
                'rootCategoryId': rootCategoryId,
                'depth': depth,
            },
        });
    }

    /**
     * Gets the Categories without nesting
     * @returns CategoryResponseMinimalDTO
     * @throws ApiError
     */
    public static getCategoriesMinimal(): CancelablePromise<Array<CategoryResponseMinimalDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/categories/minimal',
        });
    }

}
