import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelDetailTab } from 'container/ChannelVideoContainer/ChannelVideoContainer';
import { PaginatedVideoResponseDTO } from 'generated';
import placeholder from 'images/placeholder-video.svg';
import { useReduxSelector } from 'redux/hooks';
import { selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import InfoVideoList from 'components/InfoVideoList/InfoVideoList';
import ListItem from 'components/ListItem/ListItem';

const VideoList = ({
  paginatedVideoDTO,
  channelId,
}: {
  channelId: number;
  paginatedVideoDTO: PaginatedVideoResponseDTO;
}) => {
  const { t } = useTranslation(['translation', 'channel', 'video']);
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const moreLink = useMemo(() => {
    if (paginatedVideoDTO.total - paginatedVideoDTO.links.current.limit > 0) {
      return {
        link: `/channels/${channelId}`,
        text: t('video:count', {
          count:
            paginatedVideoDTO.total - paginatedVideoDTO.links.current.limit,
        }),
      };
    }

    if (paginatedVideoDTO.total === 0) {
      return {
        link: `/channels/${channelId}/${ChannelDetailTab.PRODUCT}`,
        text: t('channel:productVideoVideo.add'),
      };
    }
  }, [paginatedVideoDTO, channelId, t]);

  return (
    <InfoVideoList
      moreLink={moreLink}
      label={t('video:videoWithCount', {
        count: paginatedVideoDTO.total,
      })}
      videos={paginatedVideoDTO.data}
      renderItem={(video) => (
        <ListItem
          key={video.id}
          image={video.videoPreviews?.find((i) => i.size === 'small')?.uri}
          title={
            video.product
              ? getProductTitleByLanguage(
                  video.product.productLocalisations,
                  video.language,
                )
              : t('video:introVideo')
          }
          subtitle={
            video.product
              ? `ID: ${video.product.vendorProductId} - ${t(
                  'video:name.label',
                )}: ${video.name}`
              : `${t('video:name.label')}: ${video.name}`
          }
          language={video.language}
          border={true}
          placeholder={placeholder}
          languageNotSupportedError={
            !supportedLanguages?.some((i) => i.iso === video.language)
          }
        />
      )}
    />
  );
};

export default VideoList;
