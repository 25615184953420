import InputField from 'components/Input/InputField';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import LocalisationTabs from 'components/LocalisationTabs/LocalisationTabs';
import TabPane from 'components/LocalisationTabs/TabPane';
import PageCollapse from 'components/PageCollapse/PageCollapse';
import CategoriesField from 'features/categories/Categories/CategoriesField';
import { HashtagAsyncField } from 'features/hashtags';
import { productInfoKeys } from 'features/product-info/queries';
import { videoFilterKeys } from 'features/video-filter/queries';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ApiError, ProductResponseDTO, ProductsService } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut, selectVendorAgent } from 'redux/slices/auth/authSlice';
import { selectLocaleIso } from 'redux/slices/i18n/i18nSlice';
import notAuthenticated from 'utils/not-authenticated';
import * as Yup from 'yup';
import ProductFormControls from './ProductFormControls';
import ProductLocalisation from './ProductLocalisation';
import {
  getEmptyProduct,
  removeEmptyProductInfosFromLocalisations,
} from './utils';

export interface Props {
  edit?: boolean;
  productId?: number;
}

const ProductDetailContainer = ({ edit, productId }: Props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState<ProductResponseDTO>();
  const [loading, setLoading] = useState<boolean>(edit ? true : false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const { t } = useTranslation(['translation', 'product', 'edit']);
  const localeIso = useReduxSelector(selectLocaleIso);
  const dispatch = useReduxDispatch();
  const [initialValues, setInitialValues] = useState<ProductResponseDTO>(
    values || getEmptyProduct(localeIso),
  );
  const { setAppStatus } = useAppStatus();
  const vendorAgent = useReduxSelector(selectVendorAgent);

  useEffect(() => {
    setInitialValues(values || getEmptyProduct(localeIso));
  }, [values, localeIso]);

  const nameSchema = Yup.object().shape({
    vendorProductId: Yup.string().required(t('form.required')).ensure(),
    productLocalisations: Yup.array().of(
      Yup.object().shape({
        shopUrl: Yup.string()
          .required(t('form.required'))
          .url(t('form.url'))
          .ensure(),
        price: Yup.number()
          .required(t('form.required'))
          .typeError(t('form.number'))
          .moreThan(0, t('form.numberPositive')),
        discountPrice: Yup.number()
          .min(100, t('form.numberMin', { min: 1 }))
          .transform((_, val) => (val === Number(val) ? val : null))
          .when('price', (price, schema: Yup.NumberSchema) =>
            price !== undefined
              ? schema.lessThan(price, t('form.discountPriceHigherThanPrice'))
              : schema,
          )
          .nullable(true),
        name: Yup.string().required(t('form.required')),
      }),
    ),
  });

  const handleAddProduct = async (
    values: ProductResponseDTO,
    actions: FormikHelpers<ProductResponseDTO>,
  ) => {
    try {
      const res = await ProductsService.addProduct({
        vendorProductId: values.vendorProductId,
        isAvailable: values.isAvailable,
        productLocalisations: removeEmptyProductInfosFromLocalisations(
          values.productLocalisations.map((i) => ({
            ...i,
            name: i.name.replace(/[\r\n]+/gm, ' ').trim(),
          })),
        ),
        videos: [],
        categoryId: values.categories?.[0]?.id,
        hashtagIds: values.hashtags?.map((i) => i.id),
      });

      if (res) {
        setAppStatus(
          t('product:add.success', { product: res.vendorProductId }),
          'success',
          true,
        );

        navigate(`/products/${res.id}`);
      }
    } catch (error) {
      if (notAuthenticated(error as ApiError)) {
        dispatch(logOut());
      } else if ((error as ApiError)?.body?.error === 'DuplicatedEntryError') {
        setAppStatus(t('product:add.error.duplicateVendorId'), 'error');
        actions.setFieldError(
          'vendorProductId',
          t('product:add.error.duplicateVendorId'),
        );
      } else {
        setAppStatus(t('product:add.error.save'), 'error');
      }
    }
  };

  const handleUpdateProduct = async (
    values: ProductResponseDTO,
    actions: FormikHelpers<ProductResponseDTO>,
  ) => {
    if (productId) {
      setLoadingSave(true);
      const updateProduct = async () => {
        const res = await ProductsService.updateProduct(productId, {
          isAvailable: values.isAvailable,
          vendorProductId:
            values.vendorProductId !== initialValues.vendorProductId
              ? values.vendorProductId
              : undefined,
          categoryId: values.categories?.[0]?.id,
          hashtagIds: values.hashtags?.map((i) => i.id),
        });
        return res;
      };

      const updateProductLocale = async () => {
        return await Promise.all(
          values.productLocalisations.map(
            async ({ id, productInfo, currencyCode, name, ...rest }) => {
              return await ProductsService.updateProductLocalisation(
                productId,
                id,
                {
                  productInfo: productInfo.filter(
                    (info) => !(info.label === '' && info.text === ''),
                  ),
                  name: name.replace(/[\r\n]+/gm, ' ').trim(),
                  ...rest,
                },
              );
            },
          ),
        );
      };

      await Promise.all([updateProduct(), updateProductLocale()])
        .then((responses) => {
          // take only localisations out of ProductResponseDTO[] response
          const loc = responses[1].flatMap((i) => i.productLocalisations);
          setLoadingSave(false);
          setAppStatus(
            t('product:add.success', {
              product: responses[0].vendorProductId,
            }),
            'success',
          );
          setInitialValues({
            ...responses[0],
            // remove duplicates
            productLocalisations: loc.filter(
              (i, index) => loc.indexOf(i) === index,
            ),
            vendorProductId: responses[0].vendorProductId,
          });
          actions.resetForm();
          queryClient.invalidateQueries(
            productInfoKeys.getProduct(
              productId,
              vendorAgent?.currentVendor.id,
            ),
          );
          queryClient.invalidateQueries(videoFilterKeys.getFilter);
        })
        .catch((error) => {
          if (notAuthenticated(error)) {
            dispatch(logOut());
          } else if (
            (error as ApiError)?.body?.error === 'DuplicatedEntryError'
          ) {
            setAppStatus(t('product:add.error.duplicateVendorId'), 'error');
            actions.setFieldError(
              'vendorProductId',
              t('product:add.error.duplicateVendorId'),
            );
            setLoadingSave(false);
          } else {
            setLoadingSave(false);
            setAppStatus(t('product:add.error.save'), 'error');
            actions.resetForm();
          }
        });
    }
  };

  useEffect(() => {
    if (edit) {
      const getProductInfo = async () => {
        if (productId) {
          try {
            const res = await ProductsService.getProductById(productId);
            setLoading(false);
            setValues(res);
          } catch (error) {
            if (notAuthenticated(error as ApiError)) {
              dispatch(logOut());
            } else {
              setLoading(false);
            }
          }
        }
      };
      getProductInfo();
    }
  }, [productId, dispatch, edit]);

  const handleSubmit = (
    values: ProductResponseDTO,
    actions: FormikHelpers<ProductResponseDTO>,
  ) => {
    if (edit) {
      handleUpdateProduct(values, actions);
    } else {
      handleAddProduct(values, actions);
    }
  };

  return (
    <section className="page__section">
      <PageCollapse>
        {loading && <Loader />}
        <Formik
          initialValues={initialValues}
          validationSchema={nameSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<ProductResponseDTO>) => (
            <Form>
              <Spacer>
                <LocalisationTabs hideActiveTabHeadline>
                  {formik.values.productLocalisations.map((locale, index) => (
                    <TabPane
                      key={locale.locale}
                      id={`locale--${index}`}
                      name={locale.locale}
                    >
                      <ProductLocalisation
                        index={index}
                        currency={locale.currencyCode}
                        key={index}
                      />
                    </TabPane>
                  ))}
                </LocalisationTabs>
              </Spacer>

              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={`${t('product:vendor_product_id')}*`}>
                  <p className="page__form-desc">{t('product:id_desc')}</p>
                  <Field
                    component={InputField}
                    placeholder={t('product:placeholder.vendor_product_id')}
                    wrapperClassName="form__group"
                    name="vendorProductId"
                  />
                </LabelText>
              </Spacer>
              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={t('product:categories.label')}>
                  <Spacer tag="p" marginBottom={4} className="page__form-desc">
                    {t('product:categories.desc')}
                  </Spacer>
                  <CategoriesField />
                </LabelText>
              </Spacer>
              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={t('product:hashtag.label')}>
                  <Spacer marginBottom={4} tag="p" className="page__form-desc">
                    {t('product:hashtag.desc')}
                  </Spacer>
                  <HashtagAsyncField />
                </LabelText>
              </Spacer>
              <ProductFormControls
                formik={formik}
                edit={edit}
                productId={productId}
                loadingSave={loadingSave}
              />
            </Form>
          )}
        </Formik>
      </PageCollapse>
    </section>
  );
};

export default ProductDetailContainer;
