import React, { useState } from 'react';
import ImageUploader, {
  Validation,
} from '../../components/ImageUploader/ImageUploader';
import { request } from '../../generated/core/request';
import { OpenAPI } from '../../generated/core/OpenAPI';

type Props = {
  onChange: (paths: { fileUrl: string; filePath: string }) => void;
  onDelete: () => void;
  value?: string;
  text: string;
  description?: string;
  disabled?: boolean;
} & Validation;

function ImageUploadContainer({ onChange, ...props }: Props) {
  const [error, setError] = useState(false);

  const handleSubmit = async (file: File) => {
    try {
      const res = await request<{
        fileUrl: string;
        filePath: string;
      }>(OpenAPI, {
        method: 'POST',
        url: '/api/file/image',
        formData: { file },
      });
      if (res?.fileUrl) {
        onChange({ fileUrl: res.fileUrl, filePath: res.filePath });
      } else {
        console.error('Could not upload image');
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <ImageUploader onChange={handleSubmit} uploadError={error} {...props} />
  );
}

export default ImageUploadContainer;
