import { FieldInputProps } from 'formik';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { BankingDetailDTO } from '../../../generated';
import Color from '../../../types/Color';

const BankDetails = ({
  onSubmit,
  accountNameError,
  bankNameError,
  ibanError,
  bicError,
  accountNameFieldProps,
  bankNameFieldProps,
  ibanFieldProps,
  bicFieldProps,
  accountNameLabel,
  bankNameLabel,
  ibanLabel,
  bicLabel,
  submitText,
  deleteText,
  onDeleteClick,
  bankingDetails,
}: {
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  accountNameError?: string;
  bankNameError?: string;
  ibanError?: string;
  bicError?: string;
  accountNameFieldProps: FieldInputProps<any>;
  bankNameFieldProps: FieldInputProps<any>;
  ibanFieldProps: FieldInputProps<any>;
  bicFieldProps: FieldInputProps<any>;
  accountNameLabel: string;
  bankNameLabel: string;
  ibanLabel: string;
  bicLabel: string;
  submitText: string;
  deleteText: string;
  onDeleteClick: () => void;
  bankingDetails: BankingDetailDTO | null;
}) => {
  return (
    <form className="settings__form" onSubmit={onSubmit}>
      <div className="settings__block settings__block--50">
        <Input
          label={accountNameLabel}
          placeholder={accountNameLabel}
          error={accountNameError}
          {...accountNameFieldProps}
        />
      </div>

      <div className="settings__block settings__block--50">
        <Input
          label={bankNameLabel}
          placeholder={bankNameLabel}
          error={bankNameError}
          {...bankNameFieldProps}
        />
      </div>

      <div className="settings__block settings__block--50">
        <Input
          label={ibanLabel}
          placeholder={ibanLabel}
          error={ibanError}
          {...ibanFieldProps}
        />
      </div>

      <div className="settings__block settings__block--50">
        <Input
          label={bicLabel}
          placeholder={bicLabel}
          error={bicError}
          {...bicFieldProps}
        />
      </div>

      <Button
        className="settings__submit-btn"
        text={submitText}
        type="submit"
      />

      {bankingDetails ? (
        <Button
          className="settings__submit-btn"
          text={deleteText}
          type="button"
          onClick={onDeleteClick}
          color={Color.secondary}
          style={{ marginLeft: '1rem' }}
        />
      ) : null}
    </form>
  );
};

export default BankDetails;
