import BankDetailsContainer from '../../features/Settings/BankDetails/BankDetailsContainer';
import VendorAgentsContainer from '../../features/Settings/VendorAgents';

const SettingsAdminTab = () => {
  return (
    <>
      <div className="form__block form__block--noborder">
        <VendorAgentsContainer />
      </div>

      <div className="form__block">
        <BankDetailsContainer />
      </div>
    </>
  );
};

export default SettingsAdminTab;
