import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import IconButton from '../../../components/IconButton/IconButton';
import LinkCode from '../../../components/LinkCode/LinkCode';
import Select from '../../../components/Select/Select';
import { VendorAgentResponseDTO } from '../../../generated';
import { useReduxSelector } from '../../../redux/hooks';
import { selectVendorAgent } from '../../../redux/slices/auth/authSlice';

const ListItem = ({
  vendorAgent,
  roleOptions,
  onRoleChange,
  onDeleteClick,
  deleteLoading,
}: {
  vendorAgent: VendorAgentResponseDTO;
  roleOptions: { value: string; text: string }[];
  onRoleChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: number,
  ) => void;
  onDeleteClick: (id: number) => void;
  deleteLoading: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(['translation', 'settings']);
  const vendorAgentRedux = useReduxSelector(selectVendorAgent);
  const isSuperAdmin =
    vendorAgent.role === VendorAgentResponseDTO.role.SUPER_ADMIN;

  if (!vendorAgentRedux) {
    return null;
  }

  const isCurrent = vendorAgentRedux?.id === vendorAgent.id;

  const handleDelete = () => {
    onDeleteClick(vendorAgent.id);
    setIsModalOpen(false);
  };

  return (
    <>
      <li className="settings__list-item">
        <span className="settings__text">{vendorAgent.name}</span>

        <div className="settings__list-item-right">
          <LinkCode link={vendorAgent.email} />

          <div className="settings__list-item-actions">
            {isSuperAdmin || isCurrent ? (
              <Select
                options={
                  isSuperAdmin
                    ? [
                        {
                          value: VendorAgentResponseDTO.role.SUPER_ADMIN,
                          text: VendorAgentResponseDTO.role.SUPER_ADMIN,
                        },
                      ]
                    : roleOptions
                }
                value={vendorAgent.role}
                disabled
              />
            ) : (
              <Select
                options={roleOptions}
                value={vendorAgent.role}
                onChange={(event) => onRoleChange(event, vendorAgent.id)}
              />
            )}

            <IconButton
              className="settings__list-item-delete"
              big
              loading={deleteLoading}
              icon="delete"
              onClick={() => setIsModalOpen(true)}
              disabled={isSuperAdmin || vendorAgentRedux?.id === vendorAgent.id}
            />
          </div>
        </div>
      </li>

      <ConfirmModal
        isOpen={isModalOpen}
        contentLabel="Delete Vendor Agent"
        headline={t('delete')}
        text={t('settings:really_delete_vendor_agent', {
          name: vendorAgent.name,
        })}
        onCancelClick={() => setIsModalOpen(false)}
        confirmText={t('delete')}
        onConfirmClick={handleDelete}
      />
    </>
  );
};

const VendorAgentsList = ({
  vendorAgents,
  roleOptions,
  onRoleChange,
  onDeleteClick,
  deleteLoading,
}: {
  vendorAgents: VendorAgentResponseDTO[];
  roleOptions: { value: string; text: string }[];
  onRoleChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: number,
  ) => void;
  onDeleteClick: (id: number) => void;
  deleteLoading: number[];
}) => {
  return (
    <ul className="settings__list">
      {vendorAgents.map((agent) => (
        <ListItem
          key={agent.id}
          vendorAgent={agent}
          onRoleChange={onRoleChange}
          onDeleteClick={onDeleteClick}
          roleOptions={roleOptions}
          deleteLoading={deleteLoading.indexOf(agent.id) !== -1}
        />
      ))}
    </ul>
  );
};

export default VendorAgentsList;
