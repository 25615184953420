import { FunctionComponent, SVGProps } from 'react';
import {
  Active,
  ActiveSmall,
  Add,
  AddFilled,
  Android,
  Attach,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  ArrowLeft,
  Collapse,
  CaretDown,
  CaretRight,
  CaretUp,
  CaretLeft,
  Change,
  Average,
  Block,
  Calendar,
  Channel,
  Check,
  CheckFilled,
  Circle,
  CircleFilled,
  Clock,
  Close,
  Copy,
  Delete,
  Download,
  Edit,
  Error,
  Eye,
  EyeOff,
  FileIcon,
  Filter,
  GlobeBlocked,
  Home,
  Hashtag,
  Help,
  ImageIcon,
  Info,
  InfoFilled,
  InstallMobile,
  Ios,
  Key,
  Label,
  LabelFilled,
  LockIcon,
  LockOpen,
  LockOutline,
  Mail,
  MailOutline,
  Money,
  More,
  NotificationIcon,
  Pause,
  PauseFilled,
  Person,
  Phone,
  Play,
  PlayFilled,
  Product,
  Progress,
  ProgressBorder,
  ProgressWhite,
  Progressing,
  Refresh,
  Remove,
  RemoveFilled,
  Search,
  Settings,
  Share,
  Sound,
  Sum,
  Upload,
  Video,
} from './SVG';

export type Icon =
  | 'active'
  | 'active-small'
  | 'add'
  | 'add-filled'
  | 'android'
  | 'attach'
  | 'arrow-down'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrow-left'
  | 'caret-down'
  | 'caret-right'
  | 'caret-up'
  | 'caret-left'
  | 'change'
  | 'average'
  | 'block'
  | 'calendar'
  | 'collapse'
  | 'channel'
  | 'check'
  | 'check-filled'
  | 'circle'
  | 'circle-filled'
  | 'clock'
  | 'close'
  | 'copy'
  | 'delete'
  | 'download'
  | 'edit'
  | 'error-plain'
  | 'eye'
  | 'eye-off'
  | 'file'
  | 'filter'
  | 'globe-blocked'
  | 'home'
  | 'hashtag'
  | 'help'
  | 'image'
  | 'info'
  | 'info-filled'
  | 'install-mobile'
  | 'ios'
  | 'key'
  | 'label'
  | 'label-filled'
  | 'lock'
  | 'lock-open'
  | 'lock-outline'
  | 'mail'
  | 'mail-outline'
  | 'money'
  | 'more'
  | 'notification'
  | 'pause'
  | 'pause-filled'
  | 'person'
  | 'phone'
  | 'play'
  | 'play-filled'
  | 'product'
  | 'progress'
  | 'progress-border'
  | 'progress-white'
  | 'progressing'
  | 'refresh'
  | 'remove'
  | 'remove-filled'
  | 'search'
  | 'settings'
  | 'share'
  | 'sound'
  | 'sum'
  | 'upload'
  | 'video';

export const icons: {
  [key in Icon]: FunctionComponent<SVGProps<SVGSVGElement>> & {
    title?: string | undefined;
  };
} = {
  active: Active,
  'active-small': ActiveSmall,
  add: Add,
  'add-filled': AddFilled,
  android: Android,
  attach: Attach,
  'arrow-down': ArrowDown,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'arrow-left': ArrowLeft,
  'caret-down': CaretDown,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'caret-left': CaretLeft,
  change: Change,
  average: Average,
  block: Block,
  calendar: Calendar,
  channel: Channel,
  check: Check,
  'check-filled': CheckFilled,
  circle: Circle,
  'circle-filled': CircleFilled,
  clock: Clock,
  close: Close,
  copy: Copy,
  collapse: Collapse,
  delete: Delete,
  download: Download,
  edit: Edit,
  'error-plain': Error,
  eye: Eye,
  'eye-off': EyeOff,
  file: FileIcon,
  filter: Filter,
  'globe-blocked': GlobeBlocked,
  home: Home,
  hashtag: Hashtag,
  help: Help,
  image: ImageIcon,
  info: Info,
  'info-filled': InfoFilled,
  'install-mobile': InstallMobile,
  ios: Ios,
  key: Key,
  label: Label,
  'label-filled': LabelFilled,
  lock: LockIcon,
  'lock-open': LockOpen,
  'lock-outline': LockOutline,
  mail: Mail,
  'mail-outline': MailOutline,
  money: Money,
  more: More,
  notification: NotificationIcon,
  pause: Pause,
  'pause-filled': PauseFilled,
  person: Person,
  phone: Phone,
  play: Play,
  'play-filled': PlayFilled,
  product: Product,
  progress: Progress,
  'progress-border': ProgressBorder,
  'progress-white': ProgressWhite,
  progressing: Progressing,
  refresh: Refresh,
  remove: Remove,
  'remove-filled': RemoveFilled,
  search: Search,
  settings: Settings,
  share: Share,
  sound: Sound,
  sum: Sum,
  upload: Upload,
  video: Video,
};

export default icons;
