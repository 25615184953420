/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VendorOrderShipmentProductPackageDimensionDto } from '../models/VendorOrderShipmentProductPackageDimensionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorOrderShipmentService {

    /**
     * Get package dimensions for a given productId.
     * @param productId
     * @returns VendorOrderShipmentProductPackageDimensionDto
     * @throws ApiError
     */
    public static getPackageDimensions(
        productId: number,
    ): CancelablePromise<VendorOrderShipmentProductPackageDimensionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/order-shipment/package-dimension/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

}
