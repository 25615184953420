import classNames from 'classnames';
import Icon, { IconType } from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { ElementType, ReactNode } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import TooltipPlace from 'types/TooltipPlace';
import './icon-link.scss';

interface CompProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  type?: string;
}

interface LinkProps extends RouterLinkProps {
  icon: IconType;
  tag?: never;
  tooltipPlace?: TooltipPlace;
  tooltipDelay?: number;
  tooltip?: string;
}

type NotLinkProps = {
  icon: IconType;
  tooltipPlace?: TooltipPlace;
  tooltipDelay?: number;
  tooltip?: string;

  to?: never;
  replace?: never;
  state?: never;
  reloadDocument?: never;
} & CompProps;

export type Props = NotLinkProps | LinkProps;

const Inner = ({ children, icon }: { children: ReactNode; icon: IconType }) => (
  <>
    <Icon icon={icon} />
    <span className="icon-link__text">{children}</span>
  </>
);

const RenderTag = ({
  to,
  className,
  state,
  reloadDocument,
  icon,
  children,
  tag: Tag = 'div',
  ...props
}: Props) => {
  if (to) {
    return (
      <Link
        className={classNames('icon-link', className)}
        to={to}
        state={state}
        reloadDocument={reloadDocument}
      >
        <Inner icon={icon}>{children}</Inner>
      </Link>
    );
  }
  return (
    <Tag className={classNames('icon-link', className)} {...props}>
      <Inner icon={icon}>{children}</Inner>
    </Tag>
  );
};

const IconLink = ({ tooltip, tooltipPlace, tooltipDelay, ...props }: Props) => {
  if (tooltip) {
    return (
      <Tooltip
        content={tooltip}
        direction={tooltipPlace}
        hoverDelay={tooltipDelay}
        className="icon-link__wrap"
      >
        <RenderTag {...props} />
      </Tooltip>
    );
  }

  return <RenderTag {...props} />;
};

export default IconLink;
