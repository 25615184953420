import UpdateHashtagMax from 'features/Settings/UpdateHashtagsMax/UpdateHashtagMax';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IconType } from '../../components/Icon/Icon';
import IconTabBar from '../../components/IconTabBar/IconTabBar';
import AddVendorContainer from '../../features/Settings/AddVendor/AddVendorContainer';
import LandingPage from '../../features/Settings/LandingPage';
import LanguageSelector from '../../features/Settings/LanguageSelector/LanguageSelector';
import NotificationsContainer from '../../features/Settings/Notifications';
import TermsManager from '../../features/Settings/TermsManager/TermsManager';
import ToggleStatisticClicks from '../../features/Settings/ToggleStatisticClicks/ToggleStatisticClicks';
import UpdateAppVersionsContainer from '../../features/Settings/UpdateAppVersions/UpdateAppVersionsContainer';
import Utm from '../../features/Settings/Utm';
import ToggleShowVoucher from 'features/Settings/ToggleShowVoucher/ToggleShowVoucher';
import ToggleShowShopping from 'features/Settings/ToggleShowShopping/ToggleShowShopping';
import ToggleShowOrders from 'features/Settings/ToggleShowOrders/ToggleShowOrders';
import ToggleDiscount from 'features/Settings/ToggleDiscount/ToggleDiscount';
import ToggleShowShipping from 'features/Settings/ToggleShowShipping/ToggleShowShipping';

enum ActiveTab {
  SETTINGS = 'SETTINGS',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
}

const SettingsSuperAdminTab = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.SETTINGS);

  const renderTab = () => {
    switch (activeTab) {
      case ActiveTab.SETTINGS:
        return (
          <>
            <div className="form__block form__block--noborder">
              <AddVendorContainer />
            </div>

            <div className="form__block">
              <UpdateAppVersionsContainer />
            </div>

            <div className="form__block">
              <LanguageSelector />
            </div>

            <div className="form__block">
              <Utm />
            </div>

            <div className="form__block">
              <TermsManager />
            </div>

            <div className="form__block">
              <LandingPage />
            </div>

            <div className="form__block">
              <ToggleStatisticClicks />
            </div>

            <div className="form__block">
              <ToggleShowVoucher />
            </div>

            <div className="form__block">
              <ToggleShowShopping />
            </div>

            <div className="form__block">
              <ToggleShowOrders />
            </div>

            <div className="form__block">
              <ToggleDiscount />
            </div>

            <div className="form__block">
              <ToggleShowShipping />
            </div>

            <div className="form__block">
              <UpdateHashtagMax />
            </div>
          </>
        );
      case ActiveTab.PUSH_NOTIFICATIONS:
        return <NotificationsContainer />;
      default:
        return null;
    }
  };

  const tabIcons: { [key in ActiveTab]: IconType } = {
    [ActiveTab.SETTINGS]: 'settings',
    [ActiveTab.PUSH_NOTIFICATIONS]: 'notification',
  };

  const tabTexts = {
    [ActiveTab.SETTINGS]: t('settings:headlines.settings'),
    [ActiveTab.PUSH_NOTIFICATIONS]: t('settings:headlines.notifications'),
  };

  return (
    <>
      <div className="settings__icon-tab-bar">
        <IconTabBar
          activeTab={activeTab}
          onChange={(tab) => setActiveTab(tab)}
          tabs={Object.values(ActiveTab).map((tab) => ({
            tab,
            icon: tabIcons[tab],
            name: tabTexts[tab],
          }))}
        />
      </div>

      <div>{renderTab()}</div>
    </>
  );
};

export default SettingsSuperAdminTab;
