import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import FileManager from 'components/FileManager/FileManager';
import { clearFiles, finishedUploadStatus } from 'features/video-upload';
import { useEffect, useState } from 'react';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { VideoUploadFeature } from '../types';
import ManagedFile from './ManagedFile';
import './video-upload-manager.scss';

const InBoundary = () => {
  const files = useReduxSelector((state) => state.videoUpload.files);
  const dispatch = useReduxDispatch();
  const [completedFiles, setCompletedFiles] = useState<string[]>([]);

  const handleClose = () => {
    dispatch(clearFiles());
  };

  const allFilesCompleted =
    Object.values(files).filter((i) => !finishedUploadStatus.includes(i.status))
      .length === 0;

  useEffect(() => {
    const completedFiles = Object.values(files).filter(
      (file) => file.status === VideoUploadFeature.Status.CREATE_SUCCESS,
    );
    setCompletedFiles(completedFiles.map((file) => file.fileKey));
  }, [files]);

  if (Object.keys(files).length === 0) {
    return null;
  }

  return (
    <FileManager
      files={Object.values(files)}
      onClose={handleClose}
      numberOfCompletedFiles={completedFiles.length}
      showClose={allFilesCompleted}
      renderItem={(file) => (
        <ErrorBoundary>
          <ManagedFile {...file} {...files[file.fileKey]} key={file.fileKey} />
        </ErrorBoundary>
      )}
    />
  );
};

/**
 * Renders a FileManager that holds all logic for uploading files. It will render a ManagedFile for each file that is in the redux store, which will handle the upload itself individually when it was granted by the store. It can also clear all files from the redux store.
 This component should only exist once in the template, as it is a fixed div, that overlaps parts of the content.
 The component only renders when there are files to manage in the redux store.
 */
const VideoUploadManager = () => {
  return (
    <div className="video-upload-manager">
      <ErrorBoundary>
        <InBoundary />
      </ErrorBoundary>
    </div>
  );
};

export default VideoUploadManager;
