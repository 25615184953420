import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Headline from '../../../components/Headline/Headline';
import { VendorAgentsService } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import UpdatePasswordForm from './UpdatePasswordForm';

const UpdatePassword = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();

  const updatePasswordValidationSchema = Yup.object().shape({
    password: Yup.string().required(t('form.required')),
    newPassword: Yup.string()
      .required(t('form.required'))
      .matches(
        /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};,<.>~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t('translation:form.password-criteria', {
          specialChars: '!?@#$%^&*()-_=+{};,<.>~',
        }),
      ),
  });

  const handleSubmit = async (
    {
      password,
      newPassword,
    }: {
      password: string;
      newPassword: string;
    },
    callback: Function,
  ) => {
    try {
      await VendorAgentsService.updatePassword({
        newPassword,
        password,
      });

      setAppStatus(
        t('settings:success.update_vendor_agent_password'),
        'success',
        true,
      );

      callback();
    } catch (error) {
      setAppStatus(
        t('settings:error.update_vendor_agent_password'),
        'error',
        true,
      );
    }
  };

  const updatePasswordForm = useFormik({
    initialValues: { newPassword: '', password: '' },
    validationSchema: updatePasswordValidationSchema,
    onSubmit: (values) =>
      handleSubmit(values, () => {
        updatePasswordForm.resetForm();
      }),
  });

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.change-password')}
      </Headline>

      <UpdatePasswordForm
        onSubmit={updatePasswordForm.handleSubmit}
        oldPasswordLabel={t('settings:labels.old-password')}
        oldPasswordError={
          updatePasswordForm.touched.password &&
          updatePasswordForm.errors.password
            ? t(updatePasswordForm.errors.password)
            : undefined
        }
        oldPasswordFieldProps={updatePasswordForm.getFieldProps('password')}
        newPasswordLabel={t('settings:labels.new-password')}
        newPasswordError={
          updatePasswordForm.touched.newPassword &&
          updatePasswordForm.errors.newPassword
            ? t(updatePasswordForm.errors.newPassword)
            : undefined
        }
        newPasswordFieldProps={updatePasswordForm.getFieldProps('newPassword')}
        submitText={t('settings:submit')}
      />
    </>
  );
};

export default UpdatePassword;
