import { OptionWithIconProps } from 'components/SelectInteractive';
import { FieldProps, getIn } from 'formik';
import { OnChangeValue } from 'react-select';
import { onSubmitDontBlur } from '../../utils/on-submit-dont-blur';
import ChannelSelect, { getOptionFromChannel, Props } from './ChannelSelect';

const ChannelSelectField = <IsMulti extends boolean = false>({
  field,
  form: { errors, touched, setFieldValue },
  channels,
  isMulti,
  ...props
}: Props<OptionWithIconProps, IsMulti> & FieldProps) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);
  const options = channels?.map((i) => getOptionFromChannel(i));

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) =>
              field.value.indexOf((option as OptionWithIconProps).value) >= 0,
          )
        : options.find(
            (option) => (option as OptionWithIconProps).value === field.value,
          );
    } else {
      return isMulti ? [] : ('' as any);
    }
  };

  const onChange = (option: OnChangeValue<OptionWithIconProps, IsMulti>) => {
    setFieldValue(
      field.name,
      isMulti
        ? (option as OptionWithIconProps[]).map(
            (item: OptionWithIconProps) => item.value,
          )
        : (option as OptionWithIconProps).value,
    );
  };

  return (
    <ChannelSelect<IsMulti>
      {...field}
      {...props}
      channels={channels}
      value={getValue()}
      onChange={onChange}
      error={tou && err ? err : undefined}
      onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
    />
  );
};

export default ChannelSelectField;
