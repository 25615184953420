import SelectInteractive, {
  OptionWithIcon,
  OptionWithIconProps,
  ValueContainerIconValue,
} from 'components/SelectInteractive';
import { Props as SelectInteractiveProps } from 'components/SelectInteractive/';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { SingleValue, components } from 'react-select';
import { ColorUse } from 'types/Color';

export type Props<isMulti extends boolean = false> = Omit<
  SelectInteractiveProps<OptionWithIconProps, isMulti>,
  'options'
> & { options?: VideoResponseDTO.visibility[] };

export const getVisibilityIcon = (
  visibility: VideoResponseDTO.visibility,
  asIconType?: boolean,
) => {
  switch (visibility) {
    case VideoResponseDTO.visibility.PRIVATE:
      return !asIconType ? (
        <StatusIcon icon="key" color={ColorUse['dark-10']} square />
      ) : (
        'key'
      );
    case VideoResponseDTO.visibility.PUBLIC:
      return !asIconType ? (
        <StatusIcon icon="eye" color={ColorUse.confirm} square />
      ) : (
        'eye'
      );
    default:
      return undefined;
  }
};

const VisibilitySelect = <isMulti extends boolean = false>({
  options,
  ...props
}: Props<isMulti>) => {
  const { t } = useTranslation(['translation', 'video']);

  const mappedOptions = (
    options || Object.values(VideoResponseDTO.visibility)
  ).map((i) => ({
    value: i,
    label: t(`video:visibility.${i}.label`),
    icon: getVisibilityIcon(i, false),
  }));

  const getDescription = () => {
    const isSingle = !Array.isArray(props.value);
    const singleValue = props.value as SingleValue<OptionWithIconProps>;

    if (
      isSingle &&
      !props.isMulti &&
      singleValue?.value &&
      singleValue?.value !== VideoResponseDTO.visibility.PUBLIC
    ) {
      return t(`video:visibility.${singleValue.value}.description`);
    }
  };

  return (
    <SelectInteractive
      {...props}
      options={mappedOptions}
      components={{
        Option: OptionWithIcon,
        ValueContainer: !props.isMulti
          ? ValueContainerIconValue
          : components.ValueContainer,
      }}
      noOptionsMessage={props.noOptionsMessage || t('video:visibility.empty')}
      title={t('video:visibility.title')}
      label={t('video:visibility.title')}
      placeholder={t('video:visibility.placeholder')}
      description={getDescription()}
    />
  );
};

export default VisibilitySelect;

// t('video:visibility.public.label')
// t('video:visibility.private.label')
// t('video:visibility.public.description')
// t('video:visibility.private.description')
