import React from 'react';
import classNames from 'classnames';
import './labeled-info-text.scss';
import { TextInfoDTO } from '../../generated';
import LabeledInfoTextFormComponent from './LabeledInfoTextFormComponent';

export interface Props {
  className?: string;
  fieldName?: string;
  staticContent?: TextInfoDTO[];
  error?: string;
}

const LabeledInfoText = ({
  fieldName,
  className,
  error,
  staticContent,
}: Props) => {
  const classString = 'labeled-info-text';

  return (
    <>
      {error && <span className={`${classString}__error`}>{error}</span>}
      {staticContent ? (
        <div className={classNames(classString, className)}>
          {staticContent.map((block, index) => (
            <div key={index} className={`${classString}__block`}>
              {block.label && (
                <h3 className={`${classString}__label`}>{block.label}</h3>
              )}
              {block.text && (
                <p className={`small ${classString}__text`}>{block.text}</p>
              )}
            </div>
          ))}
        </div>
      ) : (
        fieldName && (
          <LabeledInfoTextFormComponent
            name={fieldName}
            className={className}
          />
        )
      )}
    </>
  );
};

export default LabeledInfoText;
