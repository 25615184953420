import { DefaultOption } from 'components/SelectInteractive';
import SelectInteractive, {
  InfiniteScrollProps,
} from 'components/SelectInteractive/SelectInteractive';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { VideoResponseDTO } from 'generated';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useGetTypeFilter } from '../../queries';
import FilterDetail from './FilterDetail';
import { FilterItem } from 'features/video-filter/types';

const TypeFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const [isInfinite, setIsInfinite] = useState(true);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetTypeFilter(
    debouncedSearchTerm as string,
  );

  const handleChange = (newValue: MultiValue<DefaultOption>) => {
    setFilter(
      'type',
      newValue?.map(
        ({ value, label }) =>
          ({
            id: value,
            key: value as VideoResponseDTO.type,
            name: label,
          } as FilterItem),
      ),
    );
  };

  const getValue = () => {
    return (
      filter?.type?.map(({ id, name }) => {
        return { value: id, label: name };
      }) || []
    );
  };

  const getOptions = () => {
    const d =
      data?.pages
        .map((i) => i.data)
        .flat()
        .filter((i) => i.type !== VideoResponseDTO.type.PROMOTION) || [];
    return d.map((i) => ({
      value: i.type,
      label: t(`video:type.${i.type.toLocaleLowerCase()}.label`),
    }));
  };

  const loadMore = () => {
    fetchNextPage();
  };

  // props for server side search
  const infiniteScrollProps: InfiniteScrollProps = {
    dataLength: data?.pages.map((i) => i.data).flat().length || 0,
    next: () => loadMore(),
    hasMore: hasNextPage || false,
  };

  const infiniteProps = isInfinite
    ? {
        infiniteScrollProps,
        filterOption: () => true,
        ...searchableProps,
      }
    : {};

  useEffect(() => {
    // when there are not more types then the limit of 10, the clientside search can be used
    const totals = data?.pages[data?.pages.length - 1]?.total;
    if (totals && totals < 10) {
      setIsInfinite(false);
    }
  }, [data]);

  return (
    <FilterDetail>
      <SelectInteractive
        {...infiniteProps}
        isMulti
        variant="open"
        options={getOptions()}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        infiniteScrollTargetId="sidebar--scroll-target"
        isOpenSelect
        name="type-filter"
      />
    </FilterDetail>
  );
};

export default TypeFilter;
