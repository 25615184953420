import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Input from '../../../components/Input/Input';
import { InputProps } from '../../../types/InputType';

export type AddVendorFormValues = {
  company: string;
  name: string;
  email: string;
  salesVendor: boolean;
};

const AddVendorForm = ({
  onSubmit,
}: {
  onSubmit: (values: AddVendorFormValues, callback: Function) => void;
}) => {
  const { t } = useTranslation(['translation', 'settings']);

  const addVendorValidationSchema = Yup.object().shape(
    {
      email: Yup.string().when('name', {
        is: (value: any) => !!value,
        then: Yup.string().required(t('form.required')).email(t('form.email')),
      }),
      name: Yup.string().when('email', {
        is: (value: any) => !!value,
        then: Yup.string().required(t('form.required')),
      }),
      company: Yup.string().required(t('form.required')),
    },
    [['email', 'name']],
  );

  const initialAddVendor: AddVendorFormValues = {
    company: '',
    name: '',
    email: '',
    salesVendor: false,
  };

  const addVendorForm = useFormik({
    initialValues: initialAddVendor,
    validationSchema: addVendorValidationSchema,
    onSubmit: (values) =>
      onSubmit(values, () => {
        addVendorForm.setFieldValue('salesVendor', false);
        addVendorForm.resetForm();
      }),
  });

  const companyInputProps: InputProps = {
    ...addVendorForm.getFieldProps('company'),
    label: t(`settings:labels.company`),
    error:
      addVendorForm.touched.company && addVendorForm.errors.company
        ? t(addVendorForm.errors.company)
        : undefined,
  };

  const emailInputProps: InputProps = {
    ...addVendorForm.getFieldProps('email'),
    label: t(`settings:labels.email`),
    error:
      addVendorForm.touched.email && addVendorForm.errors.email
        ? t(addVendorForm.errors.email)
        : undefined,
  };

  const nameInputProps: InputProps = {
    ...addVendorForm.getFieldProps('name'),
    label: t(`settings:labels.name`),
    error:
      addVendorForm.touched.name && addVendorForm.errors.name
        ? t(addVendorForm.errors.name)
        : undefined,
  };

  const salesVendorInputProps: InputProps = {
    ...addVendorForm.getFieldProps('salesVendor'),
    label: t(`settings:labels.salesVendor`),
    checked: addVendorForm.getFieldProps('salesVendor').value,
  };

  return (
    <form onSubmit={addVendorForm.handleSubmit}>
      <div className="settings__block settings__block--50">
        <Input {...companyInputProps} />
      </div>

      <p className="settings__block caption--primary">
        {t('settings:labels.addVendorAgent')}
      </p>

      <div className="settings__block settings__block--50">
        <Input {...emailInputProps} />
      </div>

      <div className="settings__block settings__block--50">
        <Input {...nameInputProps} />
      </div>

      <div className="settings__block settings__block--50">
        <Checkbox
          {...salesVendorInputProps}
          label={salesVendorInputProps.label}
          onChange={(checked, id, event) =>
            salesVendorInputProps.onChange(event)
          }
        />
      </div>

      <Button type="submit" text={t('settings:add')} />
    </form>
  );
};

export default AddVendorForm;
