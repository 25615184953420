import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  VendorAgentResponseDTO,
  VendorAgentResponseWithVendorDTO,
  VendorAgentsService,
  VendorResponseDTO,
} from '../../../generated';
import { RootState } from '../../store';

export type AuthVendorAgentType = Omit<
  VendorAgentResponseWithVendorDTO,
  'defaultLocale' | 'supportedLanguages' | 'defaultTimezone' | 'termsInfo'
> | null;

export interface AuthState {
  vendorAgent: AuthVendorAgentType;
  status: 'idle' | 'loading';
}

const initialState: AuthState = {
  vendorAgent: null,
  status: 'loading',
};

export const init = createAsyncThunk(
  'auth/init',
  async (_, { rejectWithValue }) => {
    try {
      const vendorAgent = await VendorAgentsService.getVendorAgent();

      return vendorAgent;
    } catch (error) {
      throw rejectWithValue('no session found');
    }
  },
);

export const logIn = createAsyncThunk(
  'auth/loggedIn',
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await VendorAgentsService.loginVendorAgent({
        email,
        password,
      });

      return response;
    } catch (error) {
      throw rejectWithValue('unauthorized');
    }
  },
);

export const logOut = createAsyncThunk('auth/loggedOut', async () => {
  Cookies.remove('token');
});

export const changeVendor = createAsyncThunk(
  'auth/changedVendor',
  async (vendorId: number, { rejectWithValue }) => {
    try {
      const response = await VendorAgentsService.switchVendor({
        vendorId,
      });

      return response;
    } catch (error) {
      throw rejectWithValue('error switching vendor');
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeVendor.fulfilled, (state, action) => {
        const {
          payload: {
            supportedLanguages,
            defaultLocale,
            defaultTimezone,
            termsInfo,
            ...vendorAgent
          },
        } = action;
        state.vendorAgent = vendorAgent;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.vendorAgent = null;
      })
      .addCase(logIn.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(logIn.fulfilled, (state, action) => {
        const {
          payload: {
            supportedLanguages,
            defaultLocale,
            defaultTimezone,
            termsInfo,
            ...vendorAgent
          },
        } = action;
        state.vendorAgent = vendorAgent;
        state.status = 'idle';
      })
      .addCase(logIn.rejected, (state) => {
        state.vendorAgent = null;
        state.status = 'idle';
      })
      .addCase(init.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(init.fulfilled, (state, action) => {
        const {
          payload: {
            supportedLanguages,
            defaultLocale,
            defaultTimezone,
            termsInfo,
            ...vendorAgent
          },
        } = action;

        if (vendorAgent) {
          state.vendorAgent = vendorAgent;
        } else {
          state.vendorAgent = null;
        }

        state.status = 'idle';
      })
      .addCase(init.rejected, (state) => {
        state.vendorAgent = null;
        state.status = 'idle';
      });
  },
});

export const selectVendorAgent = (state: RootState) => state.auth.vendorAgent;
export const selectIsAuthLoading = (state: RootState) =>
  state.auth.status === 'loading';

export const selectVendorAgentRole = (state: RootState) =>
  state.auth.vendorAgent?.role;

export const selectIsLoggedIn = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    return vendorAgent != null;
  },
);

export const selectIsSuperAdmin = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    if (!vendorAgent) {
      return false;
    }

    return (
      vendorAgent.role === VendorAgentResponseWithVendorDTO.role.SUPER_ADMIN
    );
  },
);

export const selectIsAdmin = createSelector(
  selectVendorAgent,
  selectIsSuperAdmin,
  (vendorAgent, isSuperAdmin) => {
    if (!vendorAgent) {
      return false;
    }

    return (
      vendorAgent.role === VendorAgentResponseWithVendorDTO.role.ADMIN ||
      vendorAgent.role === VendorAgentResponseWithVendorDTO.role.SALES_ADMIN ||
      isSuperAdmin
    );
  },
);

export const selectisSuperAdminDifferentVendor = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    if (!vendorAgent || !vendorAgent.currentVendor) {
      return false;
    }

    return vendorAgent.currentVendor.id !== vendorAgent.vendor.id;
  },
);

export const selectIsSalesAgent = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    return (
      vendorAgent?.role === VendorAgentResponseDTO.role.SALES_ADMIN ||
      vendorAgent?.role === VendorAgentResponseDTO.role.SALES_AGENT
    );
  },
);

export const selectIsDefaultVendor = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    return vendorAgent?.vendor.type === VendorResponseDTO.type.DEFAULT;
  },
);

export const selectBankingDetail = createSelector(
  selectVendorAgent,
  (vendorAgent) => {
    return vendorAgent?.currentVendor.bankingDetail || null;
  },
);

// Action creators are generated for each case reducer function
export const {} = authSlice.actions;

export default authSlice.reducer;
