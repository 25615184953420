import Button from 'components/Button/Button';
import ImageInput from 'components/ImageInput/ImageInput';
import Input from 'components/Input/Input';
import Radio from 'components/Radio/Radio';
import { SelectSupportedLanguages } from 'features/language';
import { useFormik } from 'formik';
import { LandingPageDTO, LandingPageResponseDTO } from 'generated';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import { InputProps } from 'types/InputType';
import getFormError from 'utils/get-form-error';
import * as Yup from 'yup';

enum LandingPageSettingType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

const EditLandingPageForm = ({
  submitLoading,
  onSubmit,
  edit,
}: {
  edit: LandingPageResponseDTO;
  submitLoading: boolean;
  onSubmit: (values: LandingPageDTO) => Promise<void>;
}) => {
  const { t } = useTranslation(['translation', 'settings']);
  const languageIso = useReduxSelector(selectLanguageCode);
  const [landingPageSettingType, setLandingPageSettingType] =
    useState<LandingPageSettingType>(
      edit.paragraph && edit.title
        ? LandingPageSettingType.CUSTOM
        : LandingPageSettingType.DEFAULT,
    );

  const initialValuesCustom: LandingPageDTO = useMemo(
    () => ({
      title: edit.title,
      paragraph: edit.paragraph,
      image: edit.image,
      languageIso: '',
    }),
    [edit],
  );

  const initialValuesDefault: LandingPageDTO = useMemo(
    () => ({
      title: '',
      paragraph: '',
      image: '',
      languageIso: edit.languageIso,
    }),
    [edit],
  );

  const initialValues =
    edit.paragraph && edit.title ? initialValuesCustom : initialValuesDefault;

  const addRefLinkValidationSchema = Yup.object().shape({
    title:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    paragraph:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    image:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    languageIso:
      landingPageSettingType === LandingPageSettingType.DEFAULT
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
  });

  const handleSubmit = (values: LandingPageDTO) => {
    onSubmit(values).then(() => {
      editLandingPageForm.resetForm();
    });
  };

  const editLandingPageForm = useFormik({
    initialValues,
    validationSchema: addRefLinkValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const titleInputProps: InputProps = {
    label: t(`settings:editLandingPageForm.labels.title`),
    error: getFormError(editLandingPageForm, 'title'),
    placeholder: t(`settings:editLandingPageForm.placeholders.text`),
    ...editLandingPageForm.getFieldProps('title'),
  };

  const landingPageParagraphInputProps: InputProps = {
    label: t(`settings:editLandingPageForm.labels.paragraph`),
    error: getFormError(editLandingPageForm, 'paragraph'),
    placeholder: t(`settings:editLandingPageForm.placeholders.text`),
    ...editLandingPageForm.getFieldProps('paragraph'),
  };

  const landingPageImageInputProps: InputProps & {
    placeholder: string;
  } = {
    label: t(`settings:editLandingPageForm.labels.image`),
    placeholder: t(`settings:editLandingPageForm.placeholders.image`),
    error: getFormError(editLandingPageForm, 'image'),
    ...editLandingPageForm.getFieldProps('image'),
  };

  const landingPageLanguageIsoInputProps: InputProps = {
    label: t(`settings:editLandingPageForm.labels.languageIso`),
    error: getFormError(editLandingPageForm, 'languageIso'),
    ...editLandingPageForm.getFieldProps('languageIso'),
  };

  const handleImageChange = (paths: { fileUrl: string; filePath: string }) => {
    editLandingPageForm.setFieldValue('image', paths.fileUrl);
  };

  const handleRadioChange = async (value: LandingPageSettingType) => {
    setLandingPageSettingType(value);
    editLandingPageForm.setErrors(
      await editLandingPageForm.validateForm(editLandingPageForm.values),
    );

    if (value === LandingPageSettingType.CUSTOM) {
      editLandingPageForm.setFieldValue('languageIso', '');
    } else {
      editLandingPageForm.setFieldValue('languageIso', languageIso);
      editLandingPageForm.setFieldValue('title', '');
      editLandingPageForm.setFieldValue('paragraph', '');
      editLandingPageForm.setFieldValue('image', '');
    }
  };

  return (
    <div className="add-ref-link">
      <p className="modal__text">{t('settings:editLandingPageForm.text')}</p>

      <form
        className="settings__form"
        onSubmit={editLandingPageForm.handleSubmit}
      >
        <div>
          <Radio
            name="landingPageSettingType"
            checked={landingPageSettingType === LandingPageSettingType.DEFAULT}
            onChange={() => handleRadioChange(LandingPageSettingType.DEFAULT)}
            label={t('settings:editLandingPageForm.labels.radio-default')}
          />

          <div className="settings__inline-form">
            <div className="settings__block--50">
              <div className="add-ref-link__input">
                <SelectSupportedLanguages
                  {...landingPageLanguageIsoInputProps}
                  disabled={
                    landingPageSettingType === LandingPageSettingType.CUSTOM
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <Radio
            name="landingPageSettingType"
            checked={landingPageSettingType === LandingPageSettingType.CUSTOM}
            onChange={() => handleRadioChange(LandingPageSettingType.CUSTOM)}
            label={t('settings:editLandingPageForm.labels.radio-custom')}
          />

          <div className="settings__inline-form">
            <div className="settings__block--50">
              <div className="add-ref-link__input">
                <Input
                  {...titleInputProps}
                  disabled={
                    landingPageSettingType === LandingPageSettingType.DEFAULT
                  }
                />
              </div>
            </div>

            <div className="settings__block--50">
              <div className="add-ref-link__input">
                <Input
                  {...landingPageParagraphInputProps}
                  disabled={
                    landingPageSettingType === LandingPageSettingType.DEFAULT
                  }
                />
              </div>
            </div>

            <div className="settings__block">
              <ImageInput
                {...landingPageImageInputProps}
                className="ref-links__form-image"
                onChange={handleImageChange}
                maxFileSize={3_000_000}
                disabled={
                  landingPageSettingType === LandingPageSettingType.DEFAULT
                }
              />
            </div>
          </div>
        </div>

        <div className="modal__controls">
          <Button
            loading={submitLoading}
            text={t('save')}
            type="submit"
            color={Color.primary}
            appearance="ghost"
          />
        </div>
      </form>
    </div>
  );
};

export default EditLandingPageForm;
