import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { Props as SelectProps } from 'components/Select/Select';
import { useReduxSelector } from 'redux/hooks';
import { selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';

export type Props = Omit<SelectProps, 'options'>;

/** TODO use components/LanguageSelect internally */
const SelectSupportedLanguages = ({ ...selectProps }: Props) => {
  const { t } = useTranslation();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  return (
    <Select
      {...selectProps}
      options={supportedLanguages.map(({ iso }) => ({
        value: iso,
        text: t(`language.${iso.substring(0, 2)}`),
      }))}
      label={t('video:language.label')}
      value={
        supportedLanguages.some((i) => i.iso === selectProps.value)
          ? selectProps.value
          : ''
      }
      emptyOptionText={
        !supportedLanguages.some((i) => i.iso === selectProps.value) &&
        selectProps.value !== ''
          ? t('language.notSupported')
          : undefined
      }
    />
  );
};

export default SelectSupportedLanguages;
