import { FlexBox, FlexBoxProps, Spacer } from 'components/Layout';
import Price from 'components/Price/Price';

export interface Props {
  price: number;
  discountPrice?: number | null;
  currency?: string;
  className?: string;
}

const PriceDiscount = ({
  price,
  currency = 'EUR',
  discountPrice,
  ...rest
}: Props & FlexBoxProps) => {
  return (
    <FlexBox
      display={discountPrice ? 'flex' : 'block'}
      justifyContent="flex-start"
      alignItems="flex-start"
      {...rest}
    >
      <Spacer>
        <Price
          amount={price}
          currency={currency}
          crossedOut={discountPrice !== undefined && discountPrice !== null}
        />
      </Spacer>
      {discountPrice && (
        <Spacer marginLeft={2}>
          <Price amount={discountPrice} currency={currency} discounted />
        </Spacer>
      )}
    </FlexBox>
  );
};
export default PriceDiscount;
