import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import './collapse.scss';

export type Props = {
  collapsed: boolean;
  onClick: () => void;
  moreText?: string;
  lessText?: string;
  className?: string;
};

const Collapse = ({
  collapsed,
  onClick,
  moreText,
  lessText,
  className,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FlexBox
      tag="button"
      className={classNames('collapse', className)}
      onClick={onClick}
      type="button"
      alignItems="center"
      marginTop={8}
    >
      <span className="collapse__text">
        {collapsed ? moreText || t('more.show') : lessText || t('more.less')}
      </span>
      <span className="collapse__icon">
        <Icon icon={collapsed ? 'caret-down' : 'caret-up'} />
      </span>
    </FlexBox>
  );
};

export default Collapse;
