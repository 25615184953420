import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  CategoriesService,
  CategoryResponseMinimalDTO,
  CategoryTreeDto,
  PaginatedGetCategoriesResponseDto,
} from 'generated';
import { queryClient } from 'index';

export enum KEYS {
  getCategories = 'getCategories',
  getCategoriesMinimal = 'getCategoriesMinimal',
  getCategoryTree = 'getCategoryTree',
}

export const categoriesKeys = {
  getCategories: (searchTerm?: string) =>
    [KEYS.getCategories, searchTerm].filter((i) => i !== undefined),
  getCategoriesMinimal: [KEYS.getCategoriesMinimal],
  getCategoryTree: (rootCategoryId?: number) =>
    [KEYS.getCategoryTree, rootCategoryId].filter((i) => i !== undefined),
};

export const useGetCategories = (searchTerm?: string) => {
  return useInfiniteQuery<PaginatedGetCategoriesResponseDto, ApiError>({
    queryKey: categoriesKeys.getCategories(searchTerm),
    queryFn: async ({ pageParam = 0 }) =>
      CategoriesService.getCategories(pageParam, undefined, searchTerm),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
    staleTime: 600 * 1000, // 10 minutes
  });
};

export const useGetCategoryTree = (rootCategoryId?: number, depth?: number) => {
  return useQuery<CategoryTreeDto[], ApiError>({
    queryKey: categoriesKeys.getCategoryTree(rootCategoryId),
    queryFn: () => CategoriesService.getCategoryTree(rootCategoryId, depth),
    staleTime: 600 * 1000, // 10 minutes
    onSuccess: (data) => {
      const recursive = (tree: CategoryTreeDto[]) => {
        tree?.forEach((categoryWithChildren) => {
          queryClient.setQueryData<CategoryTreeDto[]>(
            categoriesKeys.getCategoryTree(categoryWithChildren.id),
            [categoryWithChildren],
          );

          if (categoryWithChildren.children) {
            recursive(categoryWithChildren.children);
          }
        });
      };
      recursive(data);
    },
  });
};

export const useGetCategoriesMinimal = () => {
  return useQuery<CategoryResponseMinimalDTO[], ApiError>({
    queryKey: categoriesKeys.getCategoriesMinimal,
    queryFn: () => CategoriesService.getCategoriesMinimal(),
    staleTime: 600 * 1000, // 10 minutes
  });
};
