import classNames from 'classnames';
import Icon, { IconType } from 'components/Icon/Icon';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { components, GroupBase, ValueContainerProps } from 'react-select';
import { OptionWithIconProps } from './OptionWithIcon';

export const ValueContainerIconLabelValue = <
  OptionType extends OptionWithIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>({
  children,
  ...props
}: ValueContainerProps<OptionType, IsMulti, Group>) => {
  const { icon, value } = props.getValue()[0];
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <components.ValueContainer
      {...props}
      className={classNames(
        props.className,
        'select-interactive__value-container--custom',
        'select-interactive__value-container--number',
        icon && isDesktop && 'select-interactive__value-container--icon',
      )}
    >
      {isDesktop ? (
        <>
          {icon && (
            <span className="select-interactive__value-icon">
              {typeof icon === 'string' ? (
                <Icon icon={icon as IconType} />
              ) : (
                icon
              )}
            </span>
          )}

          {children}
          {value && (
            <span className="select-interactive__number">
              {Number(value).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}
            </span>
          )}
        </>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};

export default ValueContainerIconLabelValue;
