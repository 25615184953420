import { GroupBase, StylesConfig } from 'react-select';
import { DefaultOption } from './components/Option';
import c from '../../utils/colors';
import { BREAKPOINTS } from 'global-constants';

const isDesktop = `@media (min-width: ${BREAKPOINTS.m})`;

export const getCustomStyles = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(): StylesConfig<OptionType, IsMulti, Group> => {
  return {
    control: (provided, state) => ({
      ...provided,
      fontSize: '1.6rem',
      display: 'inline-flex',
      backgroundColor:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-dark)'
          : 'var(--color-white)',
      borderColor: 'var(--color-dark-20)',
      borderRadius: 'var(--border-radius-default)',
      letterSpacing: '0.03em',
      height: '5rem',
      transition: 'all var(--default-transition-duration)',
      color:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-white)'
          : 'var(--color-dark)',
      width: '100%',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      opacity: state.isDisabled ? 0.6 : 1,
      margin: '1px',

      [isDesktop]: {
        borderColor: state.selectProps.error
          ? 'var(--color-warning)'
          : state.selectProps.appearance === 'dark'
          ? 'var(--color-dark-50)'
          : 'var(--color-dark-20)',
        height: '3.5rem',
        fontSize: '1.2rem',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 0 0 1.2rem',
    }),
    indicatorSeparator: () => ({}),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-dark-50)'
          : 'var(--color-primary)',
      padding: '0.8rem 1.6rem 0.8rem 0.8rem',
      fontSize: '1rem',
    }),
    option: (_, state) => {
      if (state.selectProps.appearance === 'dark') {
        if (state.isSelected)
          return {
            color: 'var(--color-dark)',
            backgroundColor: 'var(--color-confirm-50)',
          };
        return {
          color: 'var(--color-white)',
          backgroundColor: state.isFocused ? 'hsl(0,0%,25%)' : 'transparent',
        };
      }
      if (state.isSelected) {
        return {
          color: 'var(--color-dark)',
          backgroundColor: state.isSelected
            ? state.isFocused
              ? 'var(--color-secondary-75)'
              : 'var(--color-secondary-50)'
            : 'var(--color-white)',
        };
      }
      return {
        color: 'var(--color-dark)',
        backgroundColor: state.isFocused
          ? 'var(--color-secondary-50)'
          : 'var(--color-white)',
      };
    },
    menu: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-dark)'
          : 'var(--color-white)',
      zIndex: 1000,
      boxShadow: undefined,
      marginBottom: 0,

      [isDesktop]: state.selectProps.isOpenSelect
        ? undefined
        : {
            width: 'auto',
            minWidth: '20rem',
            boxShadow: provided.boxShadow,
            padding: '0.8rem 0 0',
            height: 'auto',
          },
    }),
    noOptionsMessage: (provided, state) => ({
      fontSize: '1.3rem',
      color: 'var(--color-dark-50)',
      padding: state.selectProps.isOpenSelect
        ? '1.2rem 1.2rem'
        : '0 1.2rem 1.2rem',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      lineHeight: 1,
      color:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-white)'
          : 'var(--color-dark)',
    }),
    input: (provided, state) => ({
      ...provided,
      color:
        state.selectProps.appearance === 'dark'
          ? 'var(--color-white)'
          : 'var(--color-dark)',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      paddingRight: 0,
      fontSize: '1.6rem',
      lineHeight: '1',
      color: 'var(--color-primary)',
      '&:hover': {
        color: 'var(--color-primary-50)',
      },

      '&:last-child': {
        paddingRight: '0.8rem',
      },
    }),
    placeholder: (p, s) => ({
      ...p,
      lineHeight: 1,
      color:
        s.selectProps.appearance === 'dark'
          ? 'var(--color-white)'
          : 'var(--color-dark-50)',
      [isDesktop]: {
        color:
          s.selectProps.appearance === 'dark'
            ? 'var(--color-white)'
            : s.isMulti
            ? 'var(--color-dark)'
            : 'var(--color-dark-50)',
      },
    }),
  };
};

export const primaryColors = (appearance?: 'dark' | 'light') => {
  if (appearance === 'dark') {
    return {
      primary: c('--color-confirm'),
      primary75: c('--color-confirm-75'),
      primary50: c('--color-confirm-50'),
      primary25: c('--color-confirm-25'),
    };
  }
  return {
    primary: c('--color-primary'),
    primary75: c('--color-primary-75'),
    primary50: c('--color-primary-50'),
    primary25: c('--color-primary-25'),
  };
};

export default getCustomStyles;
