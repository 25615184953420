import classNames from 'classnames';
import Checkbox from 'components/Checkbox/Checkbox';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import EncodingBadge from 'components/EncodingBadge/EncodingBadge';
import Icon from 'components/Icon/Icon';
import IconButton from 'components/IconButton/IconButton';
import LazyImage from 'components/LazyImage/LazyImage';
import ProductInfo from 'components/ProductInfo/ProductInfo';
import Tooltip from 'components/Tooltip/Tooltip';
import VideoDownload from 'components/VideoDownload/VideoDownload';
import { VideoItemDTO, VideoResponseDTO } from 'generated';
import useFormatInTimeZone from 'hooks/useFormatInTimeZone';
import placeholder from 'images/placeholder-video-mediathek-gradient.svg';
import React, { useState } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import Color from 'types/Color';
import Language from 'types/Language';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import { randomString } from 'utils/random-string';
import MediathekItemEdit, { Values } from './MediathekItemEdit';
import './mediathek-item.scss';

export interface Props {
  item: VideoResponseDTO;
  className?: string;
  deactivated?: boolean;
  selectable?: {
    selected: boolean;
    onSelectChange: (isSelected: boolean) => void;
    disabled?: boolean;
  };
  onCollapseChange?: (collapsed: boolean) => void;
  activePlayer?: boolean;
  setActivePlayer?: (isActive: boolean, item?: VideoResponseDTO) => void;
  editable?: {
    onEditStart?: () => void;
    onEditSave: (item: VideoResponseDTO, refresh?: boolean) => void;
  };
  onDelete?: (id: number) => void;
  onClick?: (item: VideoResponseDTO) => void;
  languageNotSupportedError?: boolean;
  showDownload?: boolean;
}

const MediathekItem = ({
  item,
  className,
  deactivated,
  selectable,
  onCollapseChange,
  activePlayer,
  setActivePlayer,
  editable,
  onDelete,
  onClick,
  languageNotSupportedError,
  showDownload,
}: Props) => {
  const { t } = useTranslation(['translation', 'video', 'channel']);

  const isId = item.id || randomString();
  const [collapsed, setCollapsed] = useState(true);
  const [edit, setEdit] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const videoUrlMp4 = item.videoItems?.find(
    (i) => i.streamType === 'mp4_low',
  )?.url;
  const videoUrlHls = item.videoItems?.find((i) => i.streamType === 'hls')?.url;
  const preview = item.videoPreviews?.find((i) => i.size === 'small')?.uri;
  const date = useFormatInTimeZone({ date: item.updatedAt, formatStr: 'Pp' });

  const handleSelectChange = (selected: boolean) => {
    selectable?.onSelectChange(selected);
  };

  const handleCollapse = (isCollapsed: boolean) => {
    onCollapseChange && onCollapseChange(isCollapsed);
    setCollapsed(isCollapsed);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick && onClick(item);
    handleSelectChange(!selectable?.selected);
  };

  // TODO better solution with react-query
  const handleDelete = () => {
    setDeleteLoading(true);
    onDelete && onDelete(item.id);
    setTimeout(() => {
      setDeleteLoading(false);
      setDeleteModalOpen(false);
    }, 500);
  };

  const handleEditSave = (values: Values) => {
    setEdit(false);
    editable?.onEditSave({ ...item, ...values });
  };

  const handleRefresh = () => {
    editable?.onEditSave(item, true);
  };

  return (
    <Flipped flipId={item.id.toString()}>
      <li
        className={classNames(
          {
            'mediathek-item': true,
            'mediathek-item--selected': selectable?.selected,
            'mediathek-item--deactivated': deactivated,
            'mediathek-item--disabled': selectable?.disabled,
            'mediathek-item--open': !collapsed,
            'mediathek-item--player': activePlayer,
            'mediathek-item--error': languageNotSupportedError,
          },
          className,
        )}
      >
        <Flipped inverseFlipId={item.id.toString()}>
          <div className="mediathek-item__inner">
            <Flipped flipId={`main--${item.id.toString()}`} translate opacity>
              <div className="mediathek-item__main">
                <div className="mediathek-item__controls">
                  <div className="mediathek-item__controls-left">
                    {selectable && (
                      <Checkbox
                        checked={selectable.selected}
                        id={`check--${isId}`}
                        name={`check--${isId}`}
                        onChange={handleSelectChange}
                        label={item.name === '' ? t('no_title') : item.name}
                        disabled={selectable.disabled}
                        hideLabel
                      />
                    )}
                  </div>
                  <div className="mediathek-item__controls-right">
                    {!edit &&
                      item.videoPreviews &&
                      item.videoPreviews.length > 0 && (
                        <IconButton
                          icon={activePlayer ? 'close' : 'play'}
                          tooltip={
                            activePlayer
                              ? t('video:play.hide')
                              : t('video:play.show')
                          }
                          appearance="filled"
                          tooltipPlace="up"
                          onClick={() =>
                            setActivePlayer &&
                            setActivePlayer(!activePlayer, item)
                          }
                          disabled={
                            item.videoItems?.length === 0 || !item.videoItems
                          }
                        />
                      )}
                    {item.product && !edit && (
                      <IconButton
                        icon={'info'}
                        tooltip={t('video:productInfo')}
                        appearance="filled"
                        tooltipPlace="up"
                        onClick={() => handleCollapse(!collapsed)}
                      />
                    )}

                    {onDelete && !edit && (
                      <>
                        <IconButton
                          icon={'delete'}
                          tooltip={t('video:delete.tooltip')}
                          color={Color.primary}
                          appearance="ghost"
                          tooltipPlace="up"
                          onClick={() => setDeleteModalOpen(true)}
                        />

                        <ConfirmModal
                          isOpen={deleteModalOpen}
                          headline={t('video:delete.tooltip')}
                          text={t('video:really_delete', {
                            title: item.name,
                          })}
                          confirmText={t('video:delete.tooltip')}
                          onConfirmClick={handleDelete}
                          onCancelClick={() => setDeleteModalOpen(false)}
                          confirmLoading={deleteLoading}
                        />
                      </>
                    )}
                    {showDownload &&
                      !edit &&
                      (item.videoItems?.length || 0) > 0 && (
                        <VideoDownload
                          big={false}
                          items={[
                            VideoItemDTO.streamType.MP4_HIGH,
                            VideoItemDTO.streamType.MP4_MEDIUM,
                            VideoItemDTO.streamType.MP4_LOW,
                          ].map(
                            (type) =>
                              item.videoItems?.find(
                                (i) => i.streamType === type,
                              ) as VideoItemDTO,
                          )}
                        />
                      )}
                    {editable && (
                      <IconButton
                        icon={edit ? 'close' : 'edit'}
                        tooltip={t('video:edit')}
                        color={Color.primary}
                        appearance="ghost"
                        tooltipPlace="up"
                        onClick={() => setEdit(!edit)}
                      />
                    )}
                  </div>
                </div>
                <div className="mediathek-item__poster">
                  <div
                    className={classNames(
                      'mediathek-item__image',
                      activePlayer && 'mediathek-item__image--player',
                    )}
                    onClick={handleClick}
                  >
                    {activePlayer && (videoUrlMp4 || videoUrlHls) ? (
                      <ReactPlayer
                        url={videoUrlMp4 || videoUrlHls}
                        controls={true}
                        playing
                        width="auto"
                        height="100%"
                      />
                    ) : (
                      <LazyImage
                        src={preview || placeholder}
                        alt={item.name}
                        aspectRatio={202 / 360}
                        lqip={placeholder}
                      />
                    )}
                    {!activePlayer && !edit && (
                      <>
                        <div className="mediathek-item__meta">
                          {languageNotSupportedError && (
                            <Tooltip
                              content={t('video:languageNotSupported.text')}
                              direction="down"
                              className={classNames(
                                'encoding-badge__wrap',
                                className,
                              )}
                            >
                              <div className="icon encoding-badge encoding-badge--small encoding-badge--processing">
                                <Icon icon="info" />
                                {t('video:languageNotSupported.badge')}
                              </div>
                            </Tooltip>
                          )}
                          {item.encodingStatus &&
                            item.encodingStatus !==
                              VideoResponseDTO.encodingStatus.NONE &&
                            item.encodingStatus !==
                              VideoResponseDTO.encodingStatus.READY && (
                              <EncodingBadge
                                encodingStatus={item.encodingStatus}
                              />
                            )}

                          <div
                            className={classNames(
                              'mediathek-item__lang',
                              languageNotSupportedError &&
                                'mediathek-item__lang--error',
                            )}
                          >
                            {item.language}
                          </div>
                        </div>
                        {(deactivated || languageNotSupportedError) && (
                          <div className="mediathek-item__image-overlay">
                            <Icon icon="block" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {item.product && (
                  <div className="mediathek-item__label">
                    <h2 className="mediathek-item__headline">
                      {getProductTitleByLanguage(
                        item.product.productLocalisations,
                        item.language,
                      )}
                    </h2>
                    <div className="mediathek-item__subtitle">
                      <div className="mediathek-item__subtitle-item">{`${t(
                        'video:productId',
                      )}: ${item.product.vendorProductId}`}</div>
                      <div className="mediathek-item__subtitle-item">{`${t(
                        'video:name.label',
                      )}: ${item.name}`}</div>
                    </div>
                  </div>
                )}
                {!item.product && (
                  <div className="mediathek-item__label">
                    {edit ? (
                      <MediathekItemEdit
                        id={item.id}
                        editable={{ ...editable, onEditSave: handleEditSave }}
                        values={{
                          name: item.name,
                          language: item.language as Language,
                        }}
                      />
                    ) : (
                      <>
                        <h2 className="mediathek-item__headline">
                          {item.name}
                        </h2>
                        {item.updatedAt && (
                          <div className="mediathek-item__subtitle">
                            <div className="mediathek-item__subtitle-item">
                              <span className="mediathek-item__nobrake">
                                {t('video:lastRefreshed')}
                                &nbsp;
                              </span>
                              <span className="mediathek-item__nobrake">
                                {t('date', { date })}
                              </span>
                            </div>
                            {editable && (
                              <div className="mediathek-item__subtitle-wrap">
                                <IconButton
                                  type="button"
                                  tooltip={t('channel:introVideo.refresh')}
                                  onClick={handleRefresh}
                                  icon="refresh"
                                  appearance="filled"
                                  wrapperClassName="mediathek-item__refresh"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </Flipped>

            {item.product && !collapsed && (
              <Flipped flipId={`product--${item.id.toString()}`}>
                <div className="mediathek-item__collapse">
                  <button
                    className="mediathek-item__close"
                    type="button"
                    onClick={() => handleCollapse(!collapsed)}
                  >
                    <Icon icon="close" />
                  </button>
                  <ProductInfo
                    {...item.product}
                    videos={[]}
                    channels={[]}
                    hashtags={[]}
                    categories={item.product.categories || []}
                    small
                  />
                </div>
              </Flipped>
            )}
          </div>
        </Flipped>
      </li>
    </Flipped>
  );
};

export default MediathekItem;
