import classNames from 'classnames';
import Button from 'components/Button/Button';
import HowTo from 'components/HowTo/HowTo';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import { requestUpload } from 'features/video-upload';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import i18n from 'i18n';
import howToImage from 'images/video-empty.png';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import randomString from 'utils/random-string';
import { VideoUploadFeature } from '../types';
import '../video-upload.scss';
import VideoUploadFiles from './VideoUploadFiles';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';

export type Props = {
  /** What happens when you click on the cancel button, needed e.g. for closing a modal */
  onClose: () => void;
};

/**
 * Renders a dropzone for video files and enables the transfer to the redux store, that handles the upload in the background.
 */
const VideoUpload = ({ onClose }: Props) => {
  const [files, setFiles] = useState<VideoUploadFeature.FileDataCollection[]>(
    [],
  );
  const { t } = useTranslation(['translation', 'video']);
  const dispatch = useReduxDispatch();
  const isSDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.s})`);
  const languageCode = useReduxSelector(selectLanguageCode);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const r = randomString();
      const newFile: VideoUploadFeature.FileDataCollection = {
        fileName: file.name,
        size: file.size,
        status: VideoUploadFeature.Status.IDLE,
        language: languageCode,
        fileKey: `${file.name}-${r}`,
        fileData: file,
      };
      setFiles((prev) => [...prev, newFile]);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': ['.mp4'],
      'video/mkv': ['.mkv'],
      'video/mov': ['.mov'],
    },
    multiple: true,
    noClick: true,
  });

  const handleUpload = () => {
    // sends files to a queue in the redux store to be processed there in the background
    dispatch(requestUpload({ files }));
    onClose();
  };

  return (
    <FlexBox flexDirection="column">
      <div
        {...getRootProps({
          className: classNames(
            'video-upload__drop',
            isDragActive && 'video-upload__drop--active',
          ),
        })}
      >
        <input {...getInputProps()} />

        {files.length > 0 && !isDragActive && (
          <Spacer tag="p" className="small" marginBottom={3}>
            {t('video:upload.count', {
              total: files.length,
              count: files.filter(
                (i) =>
                  i.status === VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS,
              ).length,
            })}
          </Spacer>
        )}

        {(files.length === 0 || isDragActive) && (
          <HowTo
            image={howToImage}
            text={
              isDragActive ? t('video:upload.drag') : t('video:upload.howto')
            }
            button={{
              text: t('video:upload.file'),
              icon: 'file',
              onClick: open,
            }}
            hideButton={isDragActive}
            className="video-upload__howto"
          />
        )}
        <VideoUploadFiles onAdd={open} files={files} setFiles={setFiles} />
      </div>
      <FlexItem
        tag="footer"
        className="modal__fixed-footer video-upload__footer"
      >
        <FlexItem marginRight={isSDesktop ? 2 : 0}>
          <Button appearance="ghost" text={t('cancel')} onClick={onClose} />
        </FlexItem>
        <FlexItem marginTop={isSDesktop ? 0 : 3} alignSelf="flex-end">
          <Button
            text={t('video:upload.upload')}
            onClick={handleUpload}
            disabled={files.length === 0}
          />
        </FlexItem>
      </FlexItem>
    </FlexBox>
  );
};

export default VideoUpload;
