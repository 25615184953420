import VendorSelect from 'components/VendorSelect/VendorSelect';
import {
  CancelVideoUploadsModal,
  clearFiles,
  finishedUploadStatus,
} from 'features/video-upload';
import useSidebar from 'hooks/useSidebar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import {
  changeVendor,
  selectIsSuperAdmin,
  selectVendorAgent,
} from '../../../redux/slices/auth/authSlice';

const VendorAsyncSelect = () => {
  const { vendors } = useReduxSelector((state) => state.vendors);
  const { files } = useReduxSelector((state) => state.videoUpload);
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const isSuperAdmin = useReduxSelector(selectIsSuperAdmin);
  const navigate = useNavigate();
  const { closeSidebar } = useSidebar();
  const [uploadCancelModal, setUploadCancelModal] = useState(false);
  const [saveVendorSelection, setSaveVendorSelection] = useState<number>();
  const { t } = useTranslation(['translation', 'video']);

  const handleRedirect = () => {
    const pathnameParts = location.pathname.split('/');

    if (pathnameParts.length > 2) {
      closeSidebar();
      const target = `/${pathnameParts[1]}`;

      console.log('switch Vendor', target);
      navigate(target);
    }
  };

  const handleCancelUploads = () => {
    dispatch(clearFiles());
    setUploadCancelModal(false);

    if (saveVendorSelection) {
      handleVendorDispatch(saveVendorSelection);
    }
  };

  const handleVendorDispatch = async (newVendorId: number) => {
    await dispatch(changeVendor(newVendorId)).unwrap();
    handleRedirect();
  };

  const handleVendorChange = async (newVendorId: number) => {
    setSaveVendorSelection(newVendorId);

    // If there are videos currently uploading, show a warning and do not change the vendor.
    // It is not possible to save a video that has been uploaded to another vendor,
    // because it would loose its connection to the 3Q project.
    if (
      Object.values(files).filter(
        (i) => !finishedUploadStatus.includes(i.status),
      ).length > 0
    ) {
      setUploadCancelModal(true);
    } else {
      dispatch(clearFiles());
      handleVendorDispatch(newVendorId);
    }
  };

  return (
    <>
      <VendorSelect
        onVendorChange={handleVendorChange}
        vendors={vendors}
        vendorAgent={vendorAgent}
        isSuperAdmin={isSuperAdmin}
      />
      <CancelVideoUploadsModal
        isOpen={uploadCancelModal}
        onCancelClick={() => setUploadCancelModal(false)}
        onConfirmClick={handleCancelUploads}
        text={t('video:upload.cancelModal.vendorText')}
      />
    </>
  );
};

export default VendorAsyncSelect;
