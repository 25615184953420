import { Spacer } from 'components/Layout/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { ReactNode } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { components, GroupBase, MenuProps } from 'react-select';
import { DefaultOption } from './Option';

const Inner = ({
  isDesktop,
  isOpenSelect,
  title,
  children,
}: {
  isDesktop?: boolean;
  isOpenSelect?: boolean;
  title?: string;
  children: ReactNode;
}) => (
  <>
    {isDesktop && !isOpenSelect && (
      <div className="select-interactive__menu-header">
        {title && (
          <div className="caption--primary select-interactive__title">
            {title}
          </div>
        )}
      </div>
    )}
    <div className="select-interactive__drop">{children}</div>
  </>
);

export const Menu = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  props: MenuProps<OptionType, IsMulti, Group>,
) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <components.Menu {...props}>
      {isDesktop && !props.selectProps.isOpenSelect ? (
        <Inner
          isDesktop={isDesktop}
          isOpenSelect={props.selectProps.isOpenSelect}
          title={props.selectProps.title}
          children={props.children}
        />
      ) : (
        <Flipper
          flipKey={props.selectProps.options.map((i) => i.label)}
          className="select-interactive__flipper"
        >
          <Inner
            isDesktop={isDesktop}
            title={props.selectProps.title}
            children={props.children}
            isOpenSelect={props.selectProps.isOpenSelect}
          />
        </Flipper>
      )}
      {!isDesktop && props.selectProps.hint && (
        <Spacer className="select-interactive__hint" padding={4}>
          {props.selectProps.hint}
        </Spacer>
      )}
      {!isDesktop && props.selectProps.success && (
        <Spacer
          className="select-interactive__hint select-interactive__hint--success"
          padding={4}
        >
          {props.selectProps.success}
        </Spacer>
      )}
    </components.Menu>
  );
};

export default Menu;
