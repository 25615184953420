import InputField from 'components/Input/InputField';
import { Spacer } from 'components/Layout';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useZipCodeField from '../hooks/useZipCodeField';
import { useGetPostCodes } from '../queries';

const ZipCodeField = () => {
  const { t } = useTranslation();
  const {
    zipField,
    countryField,
    cityField,
    provinceField,
    resetAutoFillValues,
  } = useZipCodeField();
  const { setFieldTouched, handleChange } = useFormikContext();
  const { data, refetch, isError, isFetching, isLoading } = useGetPostCodes(
    zipField.value,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setFieldTouched('zip', true, true);
    if (zipField.value?.length === 6) {
      refetch();
    }
  }, [zipField.value]);

  useEffect(() => {
    if (data?.pages) {
      const zipCodeFind = data.pages[0].data[0];
      const { city } = zipCodeFind || {};

      cityField.setValue(city?.name || '');
      provinceField.setValue(city?.state);
      countryField.setValue(city?.state?.country);
    }
  }, [data]);

  useEffect(() => {
    if (
      isError ||
      (data?.pages.map((i) => i.data).flat().length ?? 0) === 0 ||
      !data
    ) {
      setFieldTouched('zip', true, true);
      resetAutoFillValues();
    }
  }, [isError, data]);

  const digitsOnly = (value?: string) => /^\d+$/.test(value || '');
  const fetchZipIsNotError = () => {
    if (
      (isError && !isFetching && !isLoading) ||
      (data?.pages.map((i) => i.data).flat().length ?? 0) === 0
    ) {
      return false;
    }
    return true;
  };
  const validation = Yup.string()
    .test(
      'Digits only',
      t('orders:detail.shipping.zip.numericalError'),
      digitsOnly,
    )
    .min(6, t('orders:detail.shipping.zip.lengthError'))
    .max(6, t('orders:detail.shipping.zip.lengthError'))
    .test(
      'fetchZipIsNotError',
      t('orders:detail.shipping.zip.notFoundError'),
      fetchZipIsNotError,
    );

  const validateZipCode = async (value: string) => {
    let error = undefined;
    try {
      await validation.validate(value);
    } catch (err: any) {
      error = err.errors[0];
    }
    return error || false;
  };

  return (
    <>
      <Spacer marginBottom={4}>
        <Field
          messagePosition="top"
          name="zip"
          component={InputField}
          required
          label={`${t('orders:detail.shipping.createShipment.address.zip')}*`}
          validate={validateZipCode}
          loading={isFetching}
        />
      </Spacer>
      <Spacer marginBottom={4}>
        <Field
          messagePosition="top"
          name="city"
          value={cityField.inputValue}
          component={InputField}
          required
          label={`${t('orders:detail.shipping.createShipment.address.city')}*`}
          disabled={true}
        />
      </Spacer>
      <Spacer marginBottom={4}>
        <Field
          messagePosition="top"
          name="province"
          value={provinceField.inputValue}
          component={InputField}
          required
          label={`${t(
            'orders:detail.shipping.createShipment.address.province',
          )}*`}
          disabled={true}
        />
      </Spacer>
      <Spacer marginBottom={4}>
        <Field
          messagePosition="top"
          name="country"
          value={countryField.inputValue}
          component={InputField}
          required
          label={`${t(
            'orders:detail.shipping.createShipment.address.country',
          )}*`}
          disabled={true}
        />
      </Spacer>
    </>
  );
};

export default ZipCodeField;
