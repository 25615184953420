import classNames from 'classnames';
import React from 'react';
import Icon, { IconType } from '../Icon/Icon';
import './icon-tab-bar.scss';

export type IconTab<T> = {
  tab: T;
  name: string;
  icon: IconType;
};

export interface Props<T> {
  tabs: IconTab<T>[];
  activeTab: T;
  onChange: (tab: T) => void;
}
const IconTabBar = <T extends string>({
  tabs,
  onChange,
  activeTab,
}: Props<T>) => {
  return (
    <nav className="icon-tab-bar">
      {tabs.map(({ tab, icon, name }) => (
        <button
          className={classNames(
            'icon-tab-bar__button',
            activeTab === tab && 'icon-tab-bar__button--active',
          )}
          key={tab}
          type="button"
          onClick={() => onChange(tab)}
        >
          <div className="icon icon-tab-bar__icon">
            <Icon icon={icon} />
          </div>
          <div className="caption icon-tab-bar__name">{name}</div>
        </button>
      ))}
    </nav>
  );
};

export default IconTabBar;
