import { GroupBase, SingleValueProps, components } from 'react-select';
import { DefaultOption } from './Option';

export const SingleValue = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>({
  children,
  ...props
}: SingleValueProps<OptionType, IsMulti, Group>) => {
  const value = props.getValue()[0];
  const { selectProps } = props;

  const getValue = () => {
    if (selectProps.formatValue) {
      return selectProps.formatValue(value);
    }
    return children;
  };
  return (
    <components.SingleValue {...props}>{getValue()}</components.SingleValue>
  );
};

export default SingleValue;
