/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchLinkHashtagsRequestDto } from '../models/BatchLinkHashtagsRequestDto';
import type { BatchLinkHashtagsResponseDto } from '../models/BatchLinkHashtagsResponseDto';
import type { HashtagRequestDTO } from '../models/HashtagRequestDTO';
import type { HashtagResponseDTO } from '../models/HashtagResponseDTO';
import type { HashtagSettingsDTO } from '../models/HashtagSettingsDTO';
import type { HashtagsToEntityRequestDTO } from '../models/HashtagsToEntityRequestDTO';
import type { PaginatedHashtagDTO } from '../models/PaginatedHashtagDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HashtagsService {

    /**
     * Get a paginated list of all hashtags
     * @param offset
     * @param limit
     * @param searchTerm
     * @returns PaginatedHashtagDTO
     * @throws ApiError
     */
    public static getHashtagsPaginated(
        offset?: number,
        limit?: number,
        searchTerm?: string,
    ): CancelablePromise<PaginatedHashtagDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hashtag',
            query: {
                'offset': offset,
                'limit': limit,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * Add a new Hashtag
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static addHashtag(
        requestBody: HashtagRequestDTO,
    ): CancelablePromise<HashtagResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/hashtag',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all Hashtags of a specific Entity
     * @param entityType
     * @param entityId
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static getHashtagsForEntity(
        entityType: string,
        entityId: number,
    ): CancelablePromise<Array<HashtagResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hashtag/{entityType}/{entityId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
            },
        });
    }

    /**
     * Link Hashtags to Entity
     * @param entityType
     * @param entityId
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static linkHashtagsToEntity(
        entityType: string,
        entityId: number,
        requestBody: HashtagsToEntityRequestDTO,
    ): CancelablePromise<Array<HashtagResponseDTO>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hashtag/{entityType}/{entityId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Unlink Hashtags from Entity
     * @param entityType
     * @param entityId
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static unlinkHashtagsFromEntity(
        entityType: string,
        entityId: number,
        requestBody: HashtagsToEntityRequestDTO,
    ): CancelablePromise<Array<HashtagResponseDTO>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/hashtag/{entityType}/{entityId}',
            path: {
                'entityType': entityType,
                'entityId': entityId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a Hashtag
     * @param hashtagId
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static updateHashtag(
        hashtagId: number,
        requestBody: HashtagRequestDTO,
    ): CancelablePromise<HashtagResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hashtag/{hashtagId}',
            path: {
                'hashtagId': hashtagId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a Hashtag and unlink all Entities from it.
     * @param hashtagId
     * @returns any
     * @throws ApiError
     */
    public static deleteHashtag(
        hashtagId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/hashtag/{hashtagId}',
            path: {
                'hashtagId': hashtagId,
            },
        });
    }

    /**
     * Link Hashtags to Entity
     * @param entityType
     * @param requestBody
     * @returns BatchLinkHashtagsResponseDto
     * @throws ApiError
     */
    public static batchLinkHashtagsToEntities(
        entityType: string,
        requestBody: BatchLinkHashtagsRequestDto,
    ): CancelablePromise<BatchLinkHashtagsResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hashtag/batch/{entityType}',
            path: {
                'entityType': entityType,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update Hashtags settings
     * @param requestBody
     * @returns HashtagSettingsDTO
     * @throws ApiError
     */
    public static changeHashtagSettings(
        requestBody: HashtagSettingsDTO,
    ): CancelablePromise<HashtagSettingsDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/hashtag/settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Hashtags settings
     * @returns HashtagSettingsDTO
     * @throws ApiError
     */
    public static getHashtagSettings(): CancelablePromise<HashtagSettingsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/hashtag/settings',
        });
    }

}
