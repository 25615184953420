import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import EncodingBadge from 'components/EncodingBadge/EncodingBadge';
import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import LazyImage from 'components/LazyImage/LazyImage';
import Status from 'components/VideoStatus/VideoStatus';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { isAvailable } from 'features/availability';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import Color, { ColorName } from 'types/Color';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import placeholderImage from '../../images/placeholder-video.svg';
import './video-card.scss';

export type Video = Omit<VideoResponseDTO, 'type'> & {
  type?: VideoResponseDTO.type;
};

export interface Props {
  /**TODO: size type from BE*/
  size?: number;
  /**TODO: updated type from BE*/
  updated?: {
    vendor_agent?: string;
    timestamp: string;
  };
  onClick: () => void;
}

const VideoCard = ({
  videoPreviews,
  status = VideoResponseDTO.status.DRAFT,
  language,
  name,
  product,
  channels,
  encodingStatus,
  type,
  visibility,
  onClick,
}: Video & Props) => {
  const { t } = useTranslation(['video', 'translation']);

  const getSubtitle = () => {
    if (product) {
      return (
        (language
          ? getProductTitleByLanguage(product?.productLocalisations, language)
          : product?.productLocalisations[0]) || t('no_title')
      );
    }

    if (type === VideoResponseDTO.type.CHANNEL) {
      return t('video:type.channel.label');
    }

    if (status === VideoResponseDTO.status.DRAFT) {
      return t('list:openToEdit');
    }
  };

  const videoName = name === '' ? t('no_title') : name;

  return (
    <li
      className={classNames(
        'video-card',
        status && `video-card--${status}`,
        visibility && `video-card--${visibility}`,
        type && `video-card--${type}`,
      )}
    >
      <button type="button" onClick={onClick} className="video-card__button">
        <FlexBox className="video-card__inner" flexDirection="column">
          <FlexBox justifyContent="space-between" marginBottom={1}>
            <Status status={status} />
            <FlexItem tag="span" className="video-card__language" pushRight>
              {language}
            </FlexItem>
          </FlexBox>
          <FlexBox className="video-card__wrap">
            <div className="video-card__image">
              {videoPreviews && videoPreviews.length > 0 && (
                <LazyImage
                  src={
                    videoPreviews?.find((i) => i.size === 'small')?.uri ||
                    placeholderImage
                  }
                  lqip={placeholderImage}
                  aspectRatio={1}
                  alt={t('list:preview', { title: videoName })}
                />
              )}

              {encodingStatus &&
                encodingStatus !== VideoResponseDTO.encodingStatus.NONE &&
                encodingStatus !== VideoResponseDTO.encodingStatus.READY && (
                  <div className="video-card__encoding">
                    <EncodingBadge
                      encodingStatus={encodingStatus}
                      appearance="overlay"
                    />
                  </div>
                )}
            </div>

            <FlexBox flexDirection="column" className="video-card__content">
              <div className="video-card__title">
                {visibility === VideoResponseDTO.visibility.PRIVATE ? (
                  <Chip visibility={VideoResponseDTO.visibility.PRIVATE}>
                    {videoName}
                  </Chip>
                ) : (
                  <span>{videoName}</span>
                )}
              </div>

              <div className="video-card__subtitle">
                {product && (
                  <VideoStatusIcon
                    status={
                      product?.isAvailable
                        ? VideoResponseDTO.status.LIVE
                        : VideoResponseDTO.status.IDLE
                    }
                  />
                )}
                <span className="video-card__sub">{getSubtitle()}</span>
              </div>

              {channels && (
                <FlexBox
                  tag="ul"
                  className="flex-box__item--grow video-card__channel-list"
                  marginTop={1}
                  alignItems="flex-end"
                >
                  {channels.map(({ name, id, availability }) => (
                    <li key={id} className="video-card__channel">
                      <Chip
                        color={
                          isAvailable(availability)
                            ? Color.confirm
                            : ColorName['black-20']
                        }
                      >
                        {name}
                      </Chip>
                    </li>
                  ))}
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>

          <div className="video-card__arrow">
            <Icon icon="caret-right" />
          </div>
        </FlexBox>
      </button>
    </li>
  );
};

export default VideoCard;
