import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import { SelectHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import randomString from 'utils/random-string';
import Icon, { IconType } from '../Icon/Icon';
import './select.scss';

export type Option = {
  value: string;
  text: string;
};

export interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: Array<Option>;
  label?: string;
  wrapperClassName?: string;
  error?: string;
  enableEmptyOption?: boolean;
  emptyOptionText?: string;
  appearance?: 'light' | 'dark' | 'filter';
  onReset?: () => void;
  icon?: IconType | React.ReactNode;
  success?: string;
  /** Where to show error or success message */
  messagePosition?: 'bottom' | 'top';
  loading?: boolean;
}

const Select = ({
  options,
  label,
  wrapperClassName,
  error,
  enableEmptyOption,
  emptyOptionText,
  appearance,
  onReset,
  icon,
  success,
  messagePosition,
  loading,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const errorId = error && `error--${rest.id}`;
  const selectId = rest.id || `${rest.name}--${randomString()}`;
  return (
    <div
      className={classNames(
        'select',
        error && 'select--error',
        appearance && `select--${appearance}`,
        messagePosition && `input--msg-${messagePosition}`,
        rest.multiple && 'select--multiple',
        icon && 'select--icon',
        success && 'select--success',
        loading && 'select--loading',
        wrapperClassName,
      )}
    >
      {label && (
        <label className="select__label" htmlFor={selectId}>
          {label}
        </label>
      )}

      <div className="select__wrap">
        {icon && (
          <span className="select__icon">
            {typeof icon === 'string' ? <Icon icon={icon as IconType} /> : icon}
          </span>
        )}
        {onReset && rest.value && rest.value !== '' && (
          <button className="select__reset" type="button" onClick={onReset}>
            <Icon icon="remove-filled" />
          </button>
        )}
        <select
          disabled={options?.length === 0 || !options}
          aria-errormessage={errorId}
          {...rest}
          id={selectId}
          className={classNames('select__select', rest.className)}
        >
          {!rest.multiple && (
            <option
              className="select__hint"
              disabled={!enableEmptyOption}
              value=""
            >
              {emptyOptionText || t('select')}
            </option>
          )}

          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        {loading && <Loader small />}
      </div>
      {error && (
        <div role="alert" id={errorId} className="input__error">
          {error}
        </div>
      )}
      {success && (
        <div role="alert" className="input__error input__success">
          {success}
        </div>
      )}
    </div>
  );
};

export default Select;
