import { Grid, GridItem, Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { AnimatedRoute } from 'features/animated-routing';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetVideo } from '../queries';
import '../video-detail.scss';
import VideoDetailChannels from './components/VideoDetailChannels';
import VideoDetailProduct from './components/VideoDetailProduct';
import VideoDetailUpdate from './components/VideoDetailUpdate';

const VideoDetail = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { id } = useParams();
  const videoId = id ? parseInt(id) : 0;
  const { data, isError, isLoading } = useGetVideo(videoId);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  if (isError) {
    return (
      <Spacer className="bg-warning-25" padding={[4, 6]} margin={[0, -2]}>
        <p className="small">{t('video:error.not-found')}</p>
      </Spacer>
    );
  }

  if (isLoading && !data) {
    return (
      <Spacer paddingTop={6}>
        <Loader />
      </Spacer>
    );
  }

  return (
    <AnimatedRoute>
      <div className="video-detail">
        {isDesktop ? (
          <Grid className="video-detail__panels">
            <GridItem>
              <VideoDetailUpdate videoId={videoId} />
              <VideoDetailChannels videoId={videoId} />
            </GridItem>
            <GridItem>
              <VideoDetailProduct videoId={videoId} />
            </GridItem>
          </Grid>
        ) : (
          <>
            <VideoDetailUpdate videoId={videoId} />
            <VideoDetailProduct videoId={videoId} />
            <VideoDetailChannels videoId={videoId} />
          </>
        )}
      </div>
    </AnimatedRoute>
  );
};

export default VideoDetail;
