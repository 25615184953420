/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateVideoTypeDto = {
    type: UpdateVideoTypeDto.type;
    force?: boolean;
};

export namespace UpdateVideoTypeDto {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }


}

