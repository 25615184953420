import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import './price.scss';

export interface Props {
  amount: number;
  currency?: string;
  className?: string;
  crossedOut?: boolean;
  discounted?: boolean;
}

export const formatPrice = (amount: number, currency: string) => {
  const lang = i18n.language;
  const priceFormatted = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency,
  }).format(amount / 100);

  return priceFormatted;
};

const Price = ({
  amount,
  currency = 'EUR',
  className,
  crossedOut,
  discounted,
}: Props) => {
  const priceFormatted = formatPrice(amount, currency);
  return (
    <div
      className={classNames(
        'price',
        crossedOut && 'price--crossed',
        discounted && 'price--discount',
        className,
      )}
    >
      <span className="price__text">{priceFormatted}</span>
    </div>
  );
};
export default Price;
