import classNames from 'classnames';
import Checkbox, { Props as CheckboxProps } from 'components/Checkbox/Checkbox';
import { FlexBox } from 'components/Layout/Layout';
import './list-item-select.scss';

export type Props = {
  selected: boolean;
  children?: React.ReactNode;
  appearance?: 'chip' | 'default';
} & Omit<CheckboxProps, 'checked' | 'label' | 'appearance'>;

/**
 * A List Item that contains a checkbox and renders all children
 */
const ListItemSelect = ({
  selected,
  children,
  appearance = 'default',
  className,
  ...checkboxProps
}: Props) => {
  return (
    <li
      className={classNames(
        'list-item-select',
        appearance && `list-item-select--${appearance}`,
        className,
      )}
    >
      <FlexBox
        className={classNames(
          'list-item-select__option-wrap',
          selected && 'list-item-select__option-wrap--selected',
        )}
      >
        <Checkbox
          {...checkboxProps}
          checked={selected}
          wrapperClassName="list-item-select__checkbox list-item-select__option"
          label={children}
        />
      </FlexBox>
    </li>
  );
};

export default ListItemSelect;
