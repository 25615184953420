import { CancelToken } from 'axios';

export namespace VideoUploadFeature {
  export enum Status {
    /**
     * Video is newly added
     */
    IDLE = 'idle',
    // t('video:upload.status.idle.label')
    // t('video:upload.status.idle.description')
    /**
     * A upload Url for the video is being fetched
     */
    GET_UPLOAD_URL = 'getUploadUrl',
    // t('video:upload.status.getUploadUrl.label')
    // t('video:upload.status.getUploadUrl.description')
    /**
     * Fetching the Upload Url resultet in an error
     */
    GET_UPLOAD_URL_ERROR = 'getUploadUrlError',
    // t('video:upload.status.getUploadUrlError.label')
    // t('video:upload.status.getUploadUrlError.description')
    /**
     * Fetching the Upload Url was successful
     */
    GET_UPLOAD_URL_SUCCESS = 'getUploadUrlSuccess',
    // t('video:upload.status.getUploadUrlSuccess.label')
    // t('video:upload.status.getUploadUrlSuccess.description')
    /**
     * Video has a place in the upload queue
     */
    UPLOAD_GRANTED = 'uploadGranted',
    // t('video:upload.status.uploadGranted.label')
    // t('video:upload.status.uploadGranted.description')
    /**
     * Video is being uploaded to 3Q
     */
    UPLOADING = 'uploading',
    // t('video:upload.status.uploading.label')
    // t('video:upload.status.uploading.description')
    /**
     * Video upload resultet in an error
     */
    UPLOAD_ERROR = 'uploadError',
    // t('video:upload.status.uploadError.label')
    // t('video:upload.status.uploadError.description')
    /**
     * Video upload was cancelled
     */
    UPLOAD_CANCELED = 'uploadCancelled',
    // t('video:upload.status.uploadCancelled.label')
    // t('video:upload.status.uploadCancelled.description')
    /**
     * Video upload was successful
     */
    UPLOAD_SUCCESS = 'uploadSuccess',
    // t('video:upload.status.uploadSuccess.label')
    // t('video:upload.status.uploadSuccess.description')

    /**
     * Video is being created
     */
    CREATE = 'create',
    // t('video:upload.status.create.label')
    // t('video:upload.status.create.description')
    /**
     * Video creation was successful
     */
    CREATE_SUCCESS = 'createSuccess',
    // t('video:upload.status.createSuccess.label')
    // t('video:upload.status.createSuccess.description')
    /**
     * Video creation resultet in an error
     */
    CREATE_ERROR = 'createError',
    // t('video:upload.status.createError.label')
    // t('video:upload.status.createError.description')
    /**
     * The provided video name is a duplicate
     */
    CREATE_NAME_ERROR = 'createNameError',
    // t('video:upload.status.createNameError.label')
    // t('video:upload.status.createNameError.description')
  }

  export type FileData = {
    /** Identify file item by key so that removing items doesn't disconnect the language selects */
    fileKey: string;
    fileName: string;
    language: string;
    status: Status;
    size?: number;
    threeQFileId?: number;
    uploadUrl?: string;
    /** number between 0 and 100 */
    progress?: number;
  };

  export type FileDataCollection = VideoUploadFeature.FileData & {
    fileData: File;
  };

  export type VideoUploadState = {
    files: {
      [fileKey: string]: FileDataCollection;
    };
    /** Waiting queue for files to be uploaded */
    queue: string[];
    totalProgress: number;
    maxConcurrentUploads: number;
    uploadCount: number;
  };

  export interface UseUploadVideoVariables {
    file: File;
    fileKey: string;
    uri: string;
    cancelToken?: CancelToken;
    progressCallBack?: (progressEvent: ProgressEvent) => void;
    fileName: string;
    language: string;
    status: Status;
    abortFunction?: (reason?: string | undefined) => void;
  }

  // external API response from 3Q
  export type ThreeQUploadResponse = {
    FileId: number;
  };

  export type UploadVideoToThreeQResponse = Omit<FileData, 'threeQFileId'> & {
    threeQFileId: number;
    signal: AbortSignal;
  };
}
