/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptedTermsResponseDTO } from '../models/AcceptedTermsResponseDTO';
import type { AcceptTermsDTO } from '../models/AcceptTermsDTO';
import type { AddTermsDTO } from '../models/AddTermsDTO';
import type { PaginatedTermsResponseDTO } from '../models/PaginatedTermsResponseDTO';
import type { TermsResponseDTO } from '../models/TermsResponseDTO';
import type { UpdateTermsDTO } from '../models/UpdateTermsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlatformTermsService {

    /**
     * Get paginated list of all Platform Terms ever uploaded
     * @param offset
     * @param limit
     * @returns PaginatedTermsResponseDTO
     * @throws ApiError
     */
    public static getPlatformTermsPaginated(
        offset?: number,
        limit?: number,
    ): CancelablePromise<PaginatedTermsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/terms',
            query: {
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * Add Platform Terms
     * @param requestBody
     * @returns TermsResponseDTO
     * @throws ApiError
     */
    public static addTerms(
        requestBody: AddTermsDTO,
    ): CancelablePromise<TermsResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/terms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove Platform Terms
     * @param platformTermsId
     * @returns TermsResponseDTO
     * @throws ApiError
     */
    public static deleteTerms(
        platformTermsId: number,
    ): CancelablePromise<TermsResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/terms/{platformTermsId}',
            path: {
                'platformTermsId': platformTermsId,
            },
        });
    }

    /**
     * Update Platform Terms
     * @param platformTermsId
     * @param requestBody
     * @returns TermsResponseDTO
     * @throws ApiError
     */
    public static updateTerms(
        platformTermsId: number,
        requestBody: UpdateTermsDTO,
    ): CancelablePromise<TermsResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/terms/{platformTermsId}',
            path: {
                'platformTermsId': platformTermsId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Accept Platform Terms for a Vendor
     * @param platformTermsId
     * @param requestBody
     * @returns AcceptedTermsResponseDTO
     * @throws ApiError
     */
    public static acceptTerms(
        platformTermsId: number,
        requestBody: AcceptTermsDTO,
    ): CancelablePromise<AcceptedTermsResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/terms/{platformTermsId}/accept',
            path: {
                'platformTermsId': platformTermsId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
