import { createSlice } from '@reduxjs/toolkit';
import { init, logIn } from '../auth/authSlice';

export interface AuthState {
  apiBaseUrl?: string;
}

const initialState: AuthState = {
  apiBaseUrl: undefined,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(init.fulfilled, (state, action) => {
        console.log('init fullfilled');

        state.apiBaseUrl = action.payload.apiBaseUrl;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        console.log('login fullfilled');

        state.apiBaseUrl = action.payload.apiBaseUrl;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = globalSlice.actions;

export default globalSlice.reducer;
