import useAppStatus from 'hooks/useAppStatus';
import { useEffect, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Grid from '../../components/Grid/Grid';
import GridItem from '../../components/Grid/GridItem';
import Headline from '../../components/Headline/Headline';
import HowTo from '../../components/HowTo/HowTo';
import IconTabBar, { IconTab } from '../../components/IconTabBar/IconTabBar';
import Loader from '../../components/Loader/Loader';
import Mediathek from '../../components/Mediathek/Mediathek';
import MediathekItem from '../../components/MediathekItem/MediathekItem';
import { BREAKPOINTS } from '../../global-constants';
import {
  ApiError,
  ChannelsService,
  VideoResponseDTO,
  VideosService,
} from '../../generated';
import useList from '../../hooks/useList';
import { useMediaQuery } from '../../hooks/useMediaquery';
import howToImage from '../../images/video-empty.png';
import videoListReducer from '../../reducer/reducer-video-list';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut } from '../../redux/slices/auth/authSlice';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import notAuthenticated from '../../utils/not-authenticated';
import VideoUploadContainer from '../VideoUploadContainer/VideoUploadContainer';
import { ChannelDetailTab } from './ChannelVideoContainer';

export interface Props {
  channelId?: number;
  tabs: IconTab<ChannelDetailTab>[];
  activeTab: ChannelDetailTab;
  onTabChange: (tab: ChannelDetailTab) => void;
}

const ChannelIntroVideoList = ({
  channelId,
  tabs,
  activeTab,
  onTabChange,
}: Props) => {
  const { t } = useTranslation(['video', 'channel']);
  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
    options: { disableSetActiveItem: true },
  });
  const { getArray, setActiveItem, updateMediathekItem, deleteItem } =
    listFunctions;
  const isXL = useMediaQuery(`(min-width: ${BREAKPOINTS.xl})`);
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<ApiError>();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const dispatch = useReduxDispatch();
  const location = useLocation();
  const { setAppStatus } = useAppStatus();

  const getVideos = () => {
    if (channelId) {
      getArray({
        service: async () => {
          try {
            const introVideo = await ChannelsService.getIntroVideo(channelId);

            return [introVideo];
          } catch (error) {
            return [];
          }
        },
        props: {
          initial: true,
          onSuccess: (res) => {
            if (res.length === 0) {
              setShowHowTo(true);
            }
          },
        },
      });
    }
  };

  useEffect(() => {
    getVideos();
  }, [channelId]);

  useEffect(() => {
    setShowHowTo(listState.items?.length === 0);
  }, [listState.items]);

  useEffect(() => {
    if (
      location.pathname === `/channels/${channelId}/${ChannelDetailTab.INTRO}`
    ) {
      onTabChange(ChannelDetailTab.INTRO);
    }
  }, [location.pathname]);

  const handleSetActivePlayer = (
    isActive: boolean,
    item?: VideoResponseDTO,
  ) => {
    setActiveItem(isActive ? item : undefined);
  };

  const handleOnCloseUploadModal = () => {
    setAddModalOpen(false);
    getVideos();
  };

  const handleUpdateVideo = (item: VideoResponseDTO, refresh?: boolean) => {
    if (channelId) {
      const update = async () => {
        try {
          const { name, language, type } = item;
          const res = await ChannelsService.updateIntroVideo(channelId, {
            name,
            language,
            type,
            generateNewKey: refresh,
          });

          if (res) {
            updateMediathekItem(item.id, { ...item, ...res });
            setError(undefined);
          }
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            setError(error as ApiError);
          }
        }
      };
      update();
    }
  };

  const handleDelete = (videoId: number) => {
    if (channelId) {
      deleteItem({
        service: () => VideosService.deleteVideo(videoId),
        props: {
          onSuccess: (res) => {
            setAddModalOpen(false);
            setAppStatus(t('video:delete.success'));
          },
          onError: (res) => {
            setAddModalOpen(false);
            setAppStatus(t('video:delete.error'), 'error');
          },
        },
      });
    }
  };

  return (
    <Grid>
      <GridItem templateArea="header">
        <IconTabBar tabs={tabs} activeTab={activeTab} onChange={onTabChange} />
        <Headline headingLevel="h1" size={2} className="page__title">
          {t('channel:introVideo.title')}
        </Headline>
        <p className="page__description">
          {t('channel:introVideo.description')}
        </p>
      </GridItem>

      {/* {!showHowTo &&  (
        <GridItem templateArea="sidebar" align="end" justify="end">
          <div className="grid-item__inner">
            <Button text={t('add')} onClick={() => setAddModalOpen(true)} />
          </div>
        </GridItem>
      )} */}

      {showHowTo && (
        <GridItem column={isXL ? '1/3' : '1/4'}>
          <HowTo
            button={{
              onClick: () => {
                setAddModalOpen(true);
              },
              text: t('video:add'),
            }}
            text={t('channel:introVideo.empty')}
            image={howToImage}
          />
        </GridItem>
      )}
      <GridItem column={isXL ? '1/3' : '2/4'}>
        {!listState.initialyLoaded && <Loader />}

        {listState.initialyLoaded && !showHowTo && (
          <div className="grid-item__inner">
            <Flipper
              flipKey={`flip--${listState.items?.length}`}
              spring="stiff"
            >
              <Mediathek
                count={{
                  itemsVisible: listState.items?.length,
                  total: listState.totalCount,
                }}
                loading={listState.loading}
                error={listState.error}
              >
                {listState.items &&
                  listState.items.map((item, index) => (
                    <MediathekItem
                      key={item.id}
                      item={item}
                      activePlayer={activeItem?.id === item.id}
                      setActivePlayer={handleSetActivePlayer}
                      editable={{ onEditSave: handleUpdateVideo }}
                      onDelete={() => handleDelete(item.id)}
                      onClick={(item) => setActiveItem(item)}
                      languageNotSupportedError={
                        !supportedLanguages.some((i) => i.iso === item.language)
                      }
                      showDownload
                    />
                  ))}
              </Mediathek>
            </Flipper>
          </div>
        )}
      </GridItem>

      {channelId && (
        <VideoUploadContainer
          onClose={handleOnCloseUploadModal}
          isOpen={addModalOpen}
          channelId={channelId}
        />
      )}
      <GridItem column={isXL ? '1/3' : '2/4'}>
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default ChannelIntroVideoList;
