import React, { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools/';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import { LoaderFullScreen } from './components/Loader/Loader';
import { SocketProvider } from './context/sockets';
import { StatusProvider } from './hooks/useAppStatus';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import { createBrowserHistory } from 'history';
import { SidebarProvider } from 'hooks/useSidebar';

export const queryClient = new QueryClient();
const history = createBrowserHistory();

const cachedPush = history.push;

// override history.push method to allow to exit animations and delayed FLIP
history.push = (to, state) => {
  if (typeof to === 'string') {
    return cachedPush(to);
  }
  if (state.animate) {
    state.animate().then(() => {
      delete state.animate;
      cachedPush(to);
    });
  } else {
    cachedPush(to);
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <SocketProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <StatusProvider>
              <Suspense fallback={<LoaderFullScreen />}>
                <BrowserRouter>
                  <SidebarProvider>
                    <App />
                  </SidebarProvider>
                </BrowserRouter>
              </Suspense>
            </StatusProvider>
          </QueryClientProvider>
        </SocketProvider>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
