import classNames from 'classnames';
import { Flipped, Flipper, spring } from 'react-flip-toolkit';
import Icon from '../Icon/Icon';
import './status-bar.scss';

const StatusBar = ({
  status,
  onClose,
  type,
}: {
  status?: string;
  onClose?: () => void;
  type?: 'error' | 'success' | 'warning';
}) => {
  const onAppear = (el: HTMLElement) => {
    el.style.opacity = '1';
    spring({
      onUpdate: (val) => {
        el.style.transform = `translateY(${(1 - (val as number)) * -100}%)`;
      },
    });
  };

  const onExit = (
    el: HTMLElement,
    index: number,
    removeElement: () => void,
  ) => {
    spring({
      onUpdate: (val) =>
        (el.style.transform = `translateY(${(-val as number) * 100}%)`),
      onComplete: removeElement,
    });
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Flipper
      className="status-bar__wrap"
      flipKey={status}
      handleEnterUpdateDelete={({
        animateExitingElements,
        animateEnteringElements,
      }) => {
        animateExitingElements();
        animateEnteringElements();
      }}
    >
      {status && (
        <Flipped flipId="status-bar" onAppear={onAppear} onExit={onExit}>
          <div
            className={classNames('status-bar', type && `status-bar--${type}`)}
          >
            <Flipped inverseFlipId="status-bar">
              <span className="status-bar__text">{status}</span>
            </Flipped>
            {onClose && (
              <button
                className="status-bar__close"
                type="button"
                onClick={handleClose}
              >
                <Icon icon="close" />
              </button>
            )}
          </div>
        </Flipped>
      )}
    </Flipper>
  );
};

export default StatusBar;
