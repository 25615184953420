import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  useBlockLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { SummaryDTO, SummaryTotalsDTO } from 'generated';
import 'styles/layout/_tooltip.scss';
import c from 'utils/colors';
import { secondsToMinutesAndSeconds } from 'utils/time';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import './statistics.scss';

function Table({
  columns,
  data: dataProp,
  getRowProps,
  selectedVideoId,
  loading,
  totals,
}: {
  columns: Column<SummaryDTO>[];
  data?: SummaryDTO[];
  getRowProps: (row: Row<SummaryDTO>) => any;
  selectedVideoId?: number;

  loading?: boolean;
  totals?: SummaryTotalsDTO;
}) {
  const data = useMemo(() => dataProp || [], [dataProp]);
  const tableInstance = useTable(
    {
      columns,
      data: data || [],
      autoResetSortBy: false,
    },
    useBlockLayout,
    useGlobalFilter,
    useSortBy,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = tableInstance;

  const { t } = useTranslation(['translation', 'statistics']);

  const selectedRow = useMemo(() => {
    const rowIndex = rows.findIndex(
      (row) =>
        row.original.videoId === selectedVideoId &&
        row.original.videoId !== undefined,
    );
    if (rowIndex === -1) {
      return undefined;
    }

    return rowIndex;
  }, [selectedVideoId, rows]);

  const renderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps([
            {
              style: { ...style },
            },
            getRowProps(row),
          ])}
          className="statistics__tr"
        >
          <div
            className="statistics__tr-inner"
            style={{
              borderColor:
                selectedRow === index ? c('--color-confirm') : 'transparent',
            }}
          >
            {row.cells.map((cell) => {
              return (
                <div
                  {...cell.getCellProps({
                    // @ts-ignore
                    style: cell.column.style,
                  })}
                  className="statistics__td"
                >
                  <div className="statistics__td-inner">
                    <span
                      className="statistics__cell-value"
                      title={
                        typeof cell.value === 'string' ||
                        cell.value instanceof String
                          ? cell.value.toString()
                          : undefined
                      }
                    >
                      {cell.render('Cell')}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows, selectedRow],
  );

  const getTotalsValue = (key: keyof SummaryTotalsDTO) => {
    if (!totals) return;

    if (totals[key] === null) {
      return 0;
    }

    if (key === 'avgViewDuration') {
      return (
        <span>{`${secondsToMinutesAndSeconds(
          totals?.avgViewDuration,
        )} / ${secondsToMinutesAndSeconds(totals?.videoDuration)}`}</span>
      );
    }

    if (totals[key] !== undefined) {
      return totals[key];
    }

    return key;
  };

  return (
    <>
      <div className="statistics__table">
        <div className="statistics__table-inner" {...getTableProps()}>
          <div className="statistics__thead">
            {headerGroups.map((headerGroup) => (
              <div
                className="statistics__tr"
                {...headerGroup.getHeaderGroupProps()}
              >
                <div className="statistics__tr-inner">
                  {headerGroup.headers.map((column) => (
                    <div
                      className="statistics__th"
                      {...column.getHeaderProps([
                        {
                          // @ts-ignore
                          style: column.style,
                        },
                        column.getSortByToggleProps(),
                      ])}
                    >
                      {column.render('Header')}

                      <span className="statistics__arrow">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon icon="caret-down" />
                          ) : (
                            <Icon icon="arrow-up" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="statistics__totals">
            {footerGroups.map((group) => (
              <div className="statistics__tr" {...group.getFooterGroupProps()}>
                <div className="statistics__tr-inner">
                  {group.headers.map((column) => (
                    <div
                      className="statistics__td"
                      {...column.getFooterProps([
                        {
                          // @ts-ignore
                          style: column.style,
                        },
                      ])}
                    >
                      {getTotalsValue(column.Footer as keyof SummaryTotalsDTO)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div
            className="statistics__tbody"
            {...getTableBodyProps()}
            style={{ height: 400 }}
          >
            {loading ? (
              <Loader />
            ) : (
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    height={height}
                    itemCount={rows.length}
                    itemSize={39}
                    width={width}
                    className="statistics__fixed-size-list"
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
