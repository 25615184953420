import classNames from 'classnames';
import Status, { Props } from 'components/Status/Status';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import './video-status.scss';

const VideoStatus = ({
  status = VideoResponseDTO.status.DRAFT,
  className,
  ...props
}: Omit<Props, 'status' | 'tooltip' | 'color'> & {
  status: VideoResponseDTO.status;
}) => {
  const { t } = useTranslation();

  return (
    <Status
      status={t(`status.${status}`)}
      tooltip={t(`status.tooltip.${status}`)}
      className={classNames(`video-status--${status}`, className)}
      {...props}
    />
  );
};

export default VideoStatus;

// t('status.live')
// t('status.draft')
// t('status.idle')
// t('status.tooltip.live')
// t('status.tooltip.draft')
// t('status.tooltip.idle')
