// TODO replace with generic useFilter
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { FilterItem, VideoFilterType } from '../types';

export interface VideoFilterContextType {
  filter?: VideoFilterType;
  setFilter: (key: keyof VideoFilterType, value?: FilterItem[]) => void;
  removeFilterByKey: (key: keyof VideoFilterType, id: string) => void;
  removeFilterById: (id: string) => void;
  resetFilter: () => void;
  resetFilterByKey: (key: keyof VideoFilterType) => void;
}

const VideoFilterContext = createContext<VideoFilterContextType>(
  {} as VideoFilterContextType,
);

export const VideoFilterProvider = ({
  children,
  initial,
}: {
  children: ReactNode;
  initial?: VideoFilterType;
}) => {
  const [filter, setFilter] = useState<VideoFilterType | undefined>(initial);

  const setFilterByKey = (key: keyof VideoFilterType, value?: FilterItem[]) => {
    setFilter((prev) => {
      // replace value and remove duplicates
      return {
        ...prev,
        [key]: value
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id),
          )
          .map((i) => ({
            ...i,
            // add productProps from prev state as they will get lost if not found in the new value
            // this will happen when a serverside filter is applied and the already selected product is not found in the new data array
            productProps:
              i.productProps ||
              prev?.[key]?.find((j) => j.id === i.id)?.productProps,
          })),
      };
    });
  };

  const removeFilterByKey = (key: keyof VideoFilterType, id: string) => {
    setFilter((prev) => {
      const newValue = prev?.[key]?.filter((item) => item.id !== id);
      return {
        ...prev,
        [key]: newValue,
      };
    });
  };

  const removeFilterById = (id: string) => {
    setFilter((prev) => {
      const newValue = Object.keys(prev || {}).reduce(
        (acc, key) => ({
          ...acc,
          [key]: prev?.[key]?.filter((item) => item.id !== id),
        }),
        {} as VideoFilterType,
      );
      return newValue;
    });
  };

  const reset = () => {
    setFilter(undefined);
  };

  const resetByKey = (key: keyof VideoFilterType) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: undefined,
      };
    });
  };

  const memoedValue = useMemo(
    () => ({
      setFilter: setFilterByKey,
      filter,
      resetFilter: reset,
      resetFilterByKey: resetByKey,
      removeFilterByKey,
      removeFilterById,
    }),
    [filter],
  );

  return (
    <VideoFilterContext.Provider value={memoedValue}>
      {children}
    </VideoFilterContext.Provider>
  );
};

export default function useVideoFilter() {
  return useContext(VideoFilterContext);
}
