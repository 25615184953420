import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal/Modal';
import { NotificationsService } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import { selectSupportedLanguages } from '../../../redux/slices/i18n/i18nSlice';
import {
  addNewNotification,
  editNotification,
  resetDraft,
} from '../../../redux/slices/notifications/notificationSlice';
import {
  NotificationDraft,
  NotificationDraftType,
} from '../../../redux/slices/notifications/types';
import { mapNotificationDraftToNotificationDTO } from '../../../redux/slices/notifications/utils/mapper';
import AddNotificationForm from './AddNotificationForm';

const AddNotificationFormContainer = () => {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(['translation', 'settings']);
  const { draft, status } = useReduxSelector((state) => state.notifications);
  const { vendors } = useReduxSelector((state) => state.vendors);
  const { setAppStatus } = useAppStatus();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  const handleSubmit = (values: NotificationDraft) => {
    switch (values.type) {
      case NotificationDraftType.NEW:
        dispatch(addNewNotification(values));
        break;
      case NotificationDraftType.COPY:
        dispatch(addNewNotification(values));
        break;
      case NotificationDraftType.EDIT:
        dispatch(editNotification(values));
        break;

      default:
        break;
    }
  };

  const handleSendTest = async (token: string, draft: NotificationDraft) => {
    const notificationDto = mapNotificationDraftToNotificationDTO(draft);

    try {
      await NotificationsService.sendTestNotification({
        notification: notificationDto,
        token,
      });
    } catch (error) {
      setAppStatus(t('settings:error.notification_test'), 'error', true);
    }
  };

  return draft && vendors ? (
    <Modal
      isOpen={true}
      className="modal--extra-wide"
      onClose={() => dispatch(resetDraft())}
      headline={t('settings:headlines.create-notification')}
      hasHeaderBorder
      shouldCloseOnOverlayClick={false}
    >
      <AddNotificationForm
        onSubmit={handleSubmit}
        supportedLanguages={supportedLanguages}
        draft={draft}
        vendors={vendors}
        t={t}
        onSendTest={handleSendTest}
        isSubmitLoading={status === 'loading-submit'}
      />
    </Modal>
  ) : null;
};

export default AddNotificationFormContainer;
