import classNames from 'classnames';
import { ReactNode } from 'react';
import './grid.scss';

export interface Props {
  children: ReactNode;
  className?: string;
  style?: { [k: string]: string };
}

const Grid = ({ children, ...rest }: Props) => {
  return (
    <section
      {...rest}
      className={classNames('page__section', 'grid', rest.className)}
    >
      {children}
    </section>
  );
};

export default Grid;
