import { VoucherResponseDto } from 'generated';
import AddVoucher from '../AddVoucher';
import EditVoucher from '../EditVoucher';

interface Props {
  channelId: number;
  voucher?: VoucherResponseDto;
}

const VoucherContainer = ({ voucher, channelId }: Props) => {
  if (voucher) {
    return <EditVoucher voucher={voucher} channelId={channelId} />;
  }

  return <AddVoucher channelId={channelId} />;
};

export default VoucherContainer;
