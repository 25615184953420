import { Props as ButtonType } from 'components/Button/Button';
import { Props as IconButtonType } from 'components/IconButton/IconButton';
import { FlexBox, FlexItem, SpacerProps } from 'components/Layout/Layout';
import { ElementType, ReactElement } from 'react';
import './controls.scss';

export interface Props {
  left?:
    | Array<ReactElement<ButtonType | IconButtonType>>
    | ReactElement<ButtonType | IconButtonType>;
  right?:
    | Array<ReactElement<ButtonType | IconButtonType>>
    | ReactElement<ButtonType | IconButtonType>;
  tag?: ElementType;
  className?: string;
}

const Controls = ({
  left,
  right,
  tag: Tag = 'footer',
  className,
  ...spacerProps
}: Props & SpacerProps) => {
  return (
    <FlexBox
      justifyContent="space-between"
      className="bg-white controls"
      padding={[8, 0]}
      marginTop={8}
      borderTop={1}
      breakpoint="xs"
      flexWrap="wrap"
      {...spacerProps}
    >
      {left && (
        <FlexItem
          className="controls__left"
          display={Array.isArray(left) ? 'flex' : undefined}
        >
          {Array.isArray(left)
            ? left.map((ButtonComponent) => ButtonComponent)
            : left}
        </FlexItem>
      )}
      {right && (
        <FlexItem
          className="controls__right"
          display={Array.isArray(right) ? 'flex' : undefined}
          justifyContent="flex-end"
        >
          {Array.isArray(right)
            ? right.map((ButtonComponent) => ButtonComponent)
            : right}
        </FlexItem>
      )}
    </FlexBox>
  );
};

export default Controls;
