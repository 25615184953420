import { defaultFallbackLocale } from '../../global-constants';
import { ProductLocalisationResponseDTO } from '../../generated';

export const removeEmptyProductInfosFromLocalisations = (
  localisations: ProductLocalisationResponseDTO[],
) => {
  return localisations.map(({ id, productInfo, ...rest }) => ({
    productInfo: productInfo.filter(
      (info) => !(info.label === '' && info.text === ''),
    ),
    ...rest,
  }));
};

export const getEmptyProduct = (defaultLocale?: string) => ({
  id: 0,
  vendorId: 0,
  vendorProductId: '',
  isAvailable: true,
  productLocalisations: [
    {
      id: 0,
      name: '',
      locale: defaultLocale || defaultFallbackLocale,
      productInfo: [{ id: 'rfg94', label: '', text: '' }],
      shopUrl: '',
      price: 0,
      discountPrice: null,
    },
  ],
  categories: [],
  hashtags: [],
});
