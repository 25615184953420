import ActivateToggle, {
  Props as ActivateToggleProps,
} from 'components/ActivateToggle/ActivateToggle';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';

export interface Props
  extends Omit<ActivateToggleProps, 'active' | 'onToggle'> {
  visibility: VideoResponseDTO.visibility;
  onToggle: (newVisibility: VideoResponseDTO.visibility) => void;
}

/**
 * Controlled Toggle for changing the visibility of a video between public and private.
 */
const VisibilityToggle = ({ visibility, onToggle, ...props }: Props) => {
  const { t } = useTranslation(['video']);

  const handleToggle = () => {
    const newVisibility =
      visibility === VideoResponseDTO.visibility.PRIVATE
        ? VideoResponseDTO.visibility.PUBLIC
        : VideoResponseDTO.visibility.PRIVATE;
    onToggle(newVisibility);
  };
  return (
    <ActivateToggle
      active={visibility === VideoResponseDTO.visibility.PUBLIC}
      onToggle={handleToggle}
      activeIcon="eye"
      inactiveIcon="key"
      appearance="toggle"
      activeText={t('video:visibility.public.label')}
      inactiveText={t('video:visibility.private.label')}
      {...props}
    />
  );
};

export default VisibilityToggle;
