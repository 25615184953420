/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HashtagEntityDTO = {
    entityType?: HashtagEntityDTO.entityType;
    entityId?: number;
};

export namespace HashtagEntityDTO {

    export enum entityType {
        VIDEO = 'video',
        PRODUCT = 'product',
    }


}

