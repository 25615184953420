import classNames from 'classnames';
import React from 'react';
import Color, { ColorUse } from '../../types/Color';
import './loader.scss';

const Loader = ({
  color = Color.secondary,
  small,
}: {
  color?: Color | ColorUse;
  small?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'loader',
        color && `loader--${color}`,
        small && 'loader--small',
      )}
    >
      <div className="loader__ring loader__ring--left"></div>
      <div className="loader__ring loader__ring--right"></div>
    </div>
  );
};

export const LoaderFullScreen = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Loader />
  </div>
);

export default Loader;
