import { Grid, GridItem } from 'components/Layout';
import { VendorOrderShipmentProductPackageDimensionDto } from 'generated';
import { useTranslation } from 'react-i18next';
import './packaging.scss';
import { packagingEmpty } from 'features/order-shipment/utils';
import { useEffect, useState } from 'react';

const Packaging = ({
  packageBreadth,
  packageHeight,
  packageLength,
  packageWeight,
}: VendorOrderShipmentProductPackageDimensionDto) => {
  const { t } = useTranslation(['translation', 'orders']);

  const [isEmpty, setIsEmpty] = useState(
    packagingEmpty({
      packageBreadth,
      packageHeight,
      packageLength,
      packageWeight,
    }),
  );

  useEffect(() => {
    setIsEmpty(
      packagingEmpty({
        packageBreadth,
        packageHeight,
        packageLength,
        packageWeight,
      }),
    );
  }, [packageBreadth, packageHeight, packageLength, packageWeight]);

  if (isEmpty) {
    return (
      <span className="packaging packaging--empty">
        {t('orders:detail.shipping.createShipment.start.packagingNotFound')}
      </span>
    );
  }
  return (
    <Grid tag="dl" className="packaging">
      <GridItem tag="dt">
        {t('orders:detail.shipping.createShipment.packaging.breadth')}:
      </GridItem>
      <GridItem tag="dd">{packageBreadth || 0} cm</GridItem>

      <GridItem tag="dt">
        {t('orders:detail.shipping.createShipment.packaging.height')}:
      </GridItem>
      <GridItem tag="dd">{packageHeight || 0} cm</GridItem>

      <GridItem tag="dt">
        {t('orders:detail.shipping.createShipment.packaging.length')}:
      </GridItem>
      <GridItem tag="dd">{packageLength || 0} cm</GridItem>

      <GridItem tag="dt">
        {t('orders:detail.shipping.createShipment.packaging.weight')}:
      </GridItem>
      <GridItem tag="dd">{packageWeight || 0} kg</GridItem>
    </Grid>
  );
};

export default Packaging;
