import React, { ElementType } from 'react';
import './layout.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
}

const Layout = ({ children, tag: Tag = 'div', className, ...props }: Props) => {
  return (
    <Tag className="layout" {...props}>
      <div className="layout__inner">{children}</div>
    </Tag>
  );
};

export default Layout;

export { default as FlexBox } from './Flex/FlexBox';
export type { Props as FlexBoxProps, FlexBoxType } from './Flex/FlexBox';
export { default as FlexItem } from './Flex/FlexItem';
export type { Props as FlexItemProps } from './Flex/FlexItem';
export { default as Grid } from './Grid/Grid';
export type { Props as GridProps } from './Grid/Grid';
export { default as GridItem } from './Grid/GridItem';
export type { GridItemProps } from './Grid/GridItem';
export { default as LayoutGrid } from './LayoutGrid/LayoutGrid';
export type { Props as LayoutGridProps } from './LayoutGrid/LayoutGrid';
export { default as LayoutGridItem } from './LayoutGrid/LayoutGridItem';
export type { Props as LayoutGridItemProps } from './LayoutGrid/LayoutGridItem';
export { default as Section } from './Section/Section';
export type { Props as SectionProps } from './Section/Section';
export { default as Spacer } from './Spacer/Spacer';
export type { Props as SpacerProps, SpacerType } from './Spacer/Spacer';
