import classNames from 'classnames';

export interface GridItemProps {
  row?: string;
  column?: string;
  templateArea?: 'header' | 'main' | 'footer' | 'sidebar';
  children: React.ReactNode;
  className?: string;
  align?: 'end' | 'start' | 'center';
  justify?: 'end' | 'start' | 'center';
}
const GridItem = ({
  row,
  column,
  templateArea,
  align,
  justify,
  ...rest
}: GridItemProps) => {
  const style = {
    gridRow: row,
    gridColumn: column,
  };
  return (
    <div
      className={classNames(
        'grid-item',
        templateArea && `grid-item--${templateArea}`,
        (align || justify) && 'grid-item--flex',
        align && `grid-item--align-${align}`,
        justify && `grid-item--justify-${justify}`,
        rest.className,
      )}
      style={style}
    >
      {rest.children}
    </div>
  );
};

export default GridItem;
