import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import useDebouncedEffect from 'use-debounced-effect';
import { Filter } from '../../pages/StatisticsPage';
import c from '../../utils/colors';
import Checkbox from '../Checkbox/Checkbox';
import { Block, Circle, CircleFilled, Remove } from '../Icon/SVG';
import Search from '../Search/Search';
import { DefaultOption } from '../SelectInteractive/components/Option';
import SelectInteractive, {
  OptionWithIcon,
  OptionWithIconProps,
} from '../SelectInteractive';

const StatisticFilters = ({
  onFilterChange,
  filterValues,
  onSearchTermChange,
  onCheckBoxChange,
}: {
  onFilterChange: (
    value: MultiValue<DefaultOption> | null,
    filter: 'main' | 'visibility' | 'type',
  ) => void;
  onSearchTermChange: (value: string) => void;
  filterValues: Filter;
  onCheckBoxChange: (checked: boolean) => void;
}) => {
  const { t } = useTranslation(['translation', 'statistics']);
  const [searchTerm, setSearchTerm] = useState<string>(
    filterValues.searchTerm || '',
  );

  const mainFilterOptions = [
    {
      label: t('statistics:table.filter.includeLive'),
      value: 'isLive',
      icon: <CircleFilled className="icon" color={c('--color-confirm')} />,
    },
    {
      label: t('statistics:table.filter.includeNotLive'),
      value: 'inactive',
      icon: <Circle className="icon" />,
    },
    {
      label: t('statistics:table.filter.showDeleted'),
      value: 'showDeleted',
      icon: <Remove className="icon" color={c('--color-warning')} />,
    },
    {
      label: t('statistics:table.filter.showDeactivated'),
      value: 'showDeactivated',
      icon: <Block className="icon" color={c('--color-dark-50')} />,
    },
  ];

  const visibilityFilterOptions = [
    { label: t('statistics:table.filter.private'), value: 'private' },
    { label: t('statistics:table.filter.public'), value: 'public' },
  ];

  const typeFilterOptions = [
    { label: t('statistics:table.filter.typeChannel'), value: 'channel' },
    // { label: t('statistics:table.filter.typePromotion'), value: 'promotion' },
    { label: t('statistics:table.filter.typeProduct'), value: 'product' },
  ];

  useDebouncedEffect(
    () => {
      onSearchTermChange(searchTerm);
    },
    400,
    [searchTerm],
  );
  return (
    <div className="statistics__filter">
      <div className="statistics__table-filter">
        <Search
          searchTerm={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={t('statistics:table.filter-placeholder')}
        />
        <SelectInteractive<OptionWithIconProps, true>
          isMulti
          options={mainFilterOptions}
          value={filterValues.main}
          onChange={(value) => onFilterChange(value, 'main')}
          title={t('statistics:table.filter.mainTitle')}
          placeholder={t('statistics:table.filter.mainTitle')}
          label={t('statistics:table.filter.mainTitle')}
          components={{ Option: OptionWithIcon }}
        />
        <SelectInteractive
          isMulti
          options={visibilityFilterOptions}
          value={filterValues.visibility}
          onChange={(value) => onFilterChange(value, 'visibility')}
          title={t('statistics:table.filter.visibilityTitle')}
          placeholder={t('statistics:table.filter.visibility')}
          label={t('statistics:table.filter.visibility')}
        />
        <SelectInteractive
          isMulti
          options={typeFilterOptions}
          value={filterValues.type}
          onChange={(value) => onFilterChange(value, 'type')}
          title={t('statistics:table.filter.typeTitle')}
          placeholder={t('statistics:table.filter.type')}
          label={t('statistics:table.filter.type')}
        />
      </div>
      <div className="statistics__table-filter">
        <Checkbox
          checked={filterValues.showOnlyLive ? true : false}
          name="isLive"
          onChange={(checked) => onCheckBoxChange(checked)}
          label={t('statistics:table.filter.onlyLive')}
        />
      </div>
    </div>
  );
};

export default StatisticFilters;
