/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppVendorDto = {
    company: string;
    type?: AppVendorDto.type;
};

export namespace AppVendorDto {

    export enum type {
        DEFAULT = 'default',
        SALES = 'sales',
    }


}

