import { useTranslation } from 'react-i18next';
import VisibilityAsyncToggle from '../../features/visibility/VisibilityAsyncToggle/VisibilityAsyncToggle';
import { VideoResponseDTO } from '../../generated';
import Color from '../../types/Color';
import getProductTitleByLanguage from '../../utils/get-product-title-by-language';
import Headline from '../Headline/Headline';
import IconButton from '../IconButton/IconButton';
import LabelText from '../LabelText/LabelText';
import ProductInfo from '../ProductInfo/ProductInfo';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './channel-video-info.scss';

const ChannelVideoInfo = ({
  onShareClick,
  onToggledVisibility,
  error,
  ...video
}: VideoResponseDTO & {
  onToggledVisibility: (updatedVideo: VideoResponseDTO) => void;
  onShareClick?: () => void;
  error?: {
    badge: string;
    text: string;
  };
}) => {
  const { t } = useTranslation(['translation', 'video']);
  const {
    id,
    name,
    language,
    product,
    videoItems,
    encodingStatus,
    videoPreviews,
    visibility,
  } = video;

  return (
    <div className="channel-video-info">
      <div className="channel-video-info__preview">
        <VideoPlayer
          id={id}
          name={name}
          language={language}
          product={product}
          videoItems={videoItems}
          encodingStatus={encodingStatus}
          videoPreviews={videoPreviews}
          error={error}
          isVisible={visibility === VideoResponseDTO.visibility.PUBLIC}
        >
          {onShareClick && (
            <IconButton
              onClick={onShareClick}
              tooltip={t('video:share.title')}
              big
            />
          )}

          <VisibilityAsyncToggle
            videoId={id}
            onToggledVisibility={onToggledVisibility}
          />
        </VideoPlayer>

        <div className="channel-video-info__text">
          {product && (
            <Headline
              headingLevel="h2"
              size={2}
              className="channel-video-info__title"
            >
              {getProductTitleByLanguage(
                product.productLocalisations,
                language,
              )}
            </Headline>
          )}

          <div className="channel-video-info__subtitle">
            {product && (
              <div className="channel-video-info__subtitle-item">{`${t(
                'video:productId',
              )}: ${product.vendorProductId}`}</div>
            )}

            <div className="channel-video-info__subtitle-item">{`${t(
              'video:name.label',
            )}: ${name}`}</div>
          </div>
        </div>
      </div>

      {product && (
        <LabelText
          label={t('video:product.label')}
          className="channel-video-info__block"
          collapsible
        >
          <div className="channel-video-info__pro-edit">
            <IconButton
              icon="edit"
              link={`/products/${product.id}`}
              wrapperClassName="channel-video-info__prod-edit-button"
              color={Color.confirm}
              tooltip={t('edit')}
              tooltipPlace="up"
            />
          </div>
          <ProductInfo
            {...product}
            videos={[]}
            channels={[]}
            categories={product.categories || []}
            key={product.id}
            hashtags={[]}
            small
          />
        </LabelText>
      )}
    </div>
  );
};

export default ChannelVideoInfo;
