import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from '../Icon/Icon';

interface Props {
  removeItem: () => void;
  moveItemUp: () => void;
  moveItemDown: () => void;
  showUpButton: boolean;
  showDownButton: boolean;
}

const LabeledInfoTextControls = ({
  removeItem,
  moveItemUp,
  moveItemDown,
  showUpButton,
  showDownButton,
}: Props) => {
  const classString = 'labeled-info-text';

  const { t } = useTranslation(['edit']);

  return (
    <div className={`${classString}__controls`}>
      {showUpButton && (
        <Tooltip content={t('edit:block.up')} direction="up">
          <button
            className={`${classString}__button ${classString}__button--up`}
            type="button"
            onClick={moveItemUp}
          >
            <Icon icon="caret-down" />
          </button>
        </Tooltip>
      )}
      <Tooltip content={t('edit:block.remove')} direction="up">
        <button
          className={`${classString}__button ${classString}__button--remove`}
          type="button"
          onClick={removeItem}
        >
          <Icon icon="remove" />
        </button>
      </Tooltip>
      {showDownButton && (
        <Tooltip content={t('edit:block.down')} direction="up">
          <button
            className={`${classString}__button ${classString}__button--down`}
            type="button"
            onClick={moveItemDown}
          >
            <Icon icon="caret-down" />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default LabeledInfoTextControls;
