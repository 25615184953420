import Chip from 'components/Chip/Chip';
import { FlexBox, Spacer } from 'components/Layout';
import { orderDetailKeys, useGetOrder } from 'features/order-detail';
import { ExtendedVendorOrderDto } from 'features/order-detail/queries';
import {
  useGetShipmentInvoice,
  useGetShipmentManifests,
  useGetShipmentTrackingLabel,
} from 'features/order-shipment/queries';
import { queryClient } from 'index';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import { ColorUse } from 'types/Color';

const ShippingDownloads = ({ orderNumber }: { orderNumber: string }) => {
  const { t } = useTranslation(['translation', 'orders']);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const manifestMutation = useGetShipmentManifests();
  const { isLoading: manifestIsLoading, data: manifestData } = manifestMutation;
  const invoiceMutation = useGetShipmentInvoice();
  const { isLoading: invoiceIsLoading, data: invoiceData } = invoiceMutation;
  const labelMutation = useGetShipmentTrackingLabel();
  const { isLoading: shippingLabelLoading, data: shippingLabelData } =
    labelMutation;
  // the links get saved at the order, to minimize the amount of requests
  const { data } = useGetOrder(orderNumber);

  useEffect(() => {
    if (!data?.invoiceLink) {
      invoiceMutation.mutate({
        orderNumbers: [orderNumber],
      });
    }
    if (!data?.trackingLink) {
      labelMutation.mutate({
        orderNumbers: [orderNumber],
      });
    }

    if (!data?.manifestLink) {
      manifestMutation.mutate({
        orderNumbers: [orderNumber],
      });
    }
  }, [data]);

  useEffect(() => {
    queryClient.setQueryData(
      orderDetailKeys.getOrder(orderNumber, vendorAgent?.currentVendor.id),
      (old?: ExtendedVendorOrderDto) =>
        ({
          ...old,
          manifestLink: manifestData?.manifestLink,
          trackingLink: shippingLabelData?.trackingLink,
          invoiceLink: invoiceData?.invoiceLink,
        } as ExtendedVendorOrderDto),
    );
  }, [manifestData, invoiceData, shippingLabelData]);

  const handleDownload = (url?: string | null) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  return (
    <div className="shipping__downloads">
      <Spacer tag="h3" className="caption" marginBottom={3}>
        {t('orders:detail.shipping.downloads.title')}
      </Spacer>
      <FlexBox flexWrap="wrap" margin={-1}>
        <Spacer margin={1} className="shipping__download">
          <Chip
            big
            download
            interactionIcon={!data?.invoiceLink ? 'block' : 'download'}
            icon="money"
            color={ColorUse['secondary-50']}
            isLoading={invoiceIsLoading}
            disabled={!data?.invoiceLink}
            onClick={() => handleDownload(data?.invoiceLink)}
          >
            {t('orders:detail.shipping.downloads.invoice')}
          </Chip>
        </Spacer>
        <Spacer margin={1} className="shipping__download">
          <Chip
            big
            download
            interactionIcon={!data?.trackingLink ? 'block' : 'download'}
            icon="label"
            color={ColorUse['secondary-50']}
            isLoading={shippingLabelLoading}
            disabled={!data?.trackingLink}
            onClick={() => handleDownload(data?.trackingLink)}
          >
            {t('orders:detail.shipping.downloads.shippinLabel')}
          </Chip>
        </Spacer>
        <Spacer margin={1} className="shipping__download">
          <Chip
            big
            download
            interactionIcon={!data?.manifestLink ? 'block' : 'download'}
            icon="attach"
            color={ColorUse['secondary-50']}
            isLoading={manifestIsLoading}
            disabled={!data?.manifestLink}
            link={data?.manifestLink}
            onClick={() => handleDownload(data?.manifestLink)}
          >
            {t('orders:detail.shipping.downloads.manifest')}
          </Chip>
        </Spacer>
      </FlexBox>
    </div>
  );
};

export default ShippingDownloads;
