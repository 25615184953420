import FileProgressItem from 'components/FileProgressItem/FileProgressItem';
import { useEffect, useRef } from 'react';
import { useReduxDispatch } from 'redux/hooks';
import { VideoUploadFeature } from '../types';
import {
  finishedUploadStatus,
  removeFile,
  stopWaitingForUpload,
  uploadVideo,
  waitingUploadStatus,
} from '../videoUploadSlice';

/**
 * Renders a FileProgressItem that is responsible for uploading itself, when the upload was granted from the redux store.
 It also handles the canceling of the upload and removing itself from the VideoUploadManager. 
 */
const ManagedFile = ({
  status,
  fileKey,
  ...props
}: VideoUploadFeature.FileDataCollection) => {
  const dispatch = useReduxDispatch();
  const uploadThunk = useRef<any>();

  useEffect(() => {
    // If file is removed from redux store, cancel upload
    // This could happen e.g. when changing the vendor
    return () => {
      uploadThunk.current?.abort();
    };
  }, []);

  const handleCancelUpload = () => {
    if (!finishedUploadStatus.includes(status)) {
      uploadThunk.current?.abort();
    } else if (waitingUploadStatus.includes(status)) {
      dispatch(stopWaitingForUpload({ fileKey }));
    } else {
      dispatch(removeFile({ fileKey }));
    }
  };

  useEffect(() => {
    if (status === VideoUploadFeature.Status.UPLOAD_GRANTED) {
      if (props.uploadUrl) {
        const promise = dispatch(
          uploadVideo({
            fileKey,
            status,
            uri: props.uploadUrl,
            file: props.fileData,
            ...props,
          }),
        );

        uploadThunk.current = promise;
      }
    }
  }, [status, props.uploadUrl]);

  return (
    <FileProgressItem
      {...props}
      status={status}
      fileKey={fileKey}
      onClose={handleCancelUpload}
    />
  );
};

export default ManagedFile;
