import Address from 'components/Address/Address';
import Button from 'components/Button/Button';
import Chip from 'components/Chip/Chip';
import Headline from 'components/Headline/Headline';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import Packaging from 'components/Packaging/Packaging';
import ProductTaxes from 'components/ProductTaxes/ProductTaxes';
import useCreateCompleteShipment from 'features/order-shipment/hooks/useCreateCompleteShipment';
import {
  addressNotComplete,
  getReqiredAddressFields,
  packagingNotComplete,
  taxesNotComplete,
} from 'features/order-shipment/utils';
import { VendorAddressDto } from 'generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import { ShipmentComponentProps } from '../components/CreateShipment';
import '../shipping.scss';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';

const CreateShipmentStart = ({
  address,
  packagingData,
  taxesData,
  setStep,
  orderNo,
  pickupdate,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const [error, setError] = useState();
  const [packagingError, setPackagingError] = useState();
  const [addressError, setAddressError] = useState<string>();
  const [taxesError, setTaxesError] = useState<string>();
  const createCompleteShipmentMutation = useCreateCompleteShipment({ setStep });
  const { isLoading } = createCompleteShipmentMutation;
  const timeZone = useReduxSelector(selectTimezone);

  const missingAddressFieldsText = () => {
    const requiredFields = Object.keys(getReqiredAddressFields());
    const missingFields = requiredFields.filter(
      (field) => !address?.[field as keyof VendorAddressDto],
    );

    return `${t(
      'orders:detail.shipping.createShipment.start.error.missingFields',
    )}: ${missingFields
      .map((i) => t(`orders:detail.shipping.createShipment.address.${i}`))
      .join(', ')}`;
  };

  const handleCreateShipment = () => {
    const packagingIsNotComplete = packagingNotComplete(packagingData);
    const addressIsNotComplete = addressNotComplete(address);
    const taxesIsNotComplete = taxesNotComplete(taxesData);

    const somethingNotComplete =
      !packagingData ||
      packagingIsNotComplete ||
      !address ||
      addressIsNotComplete ||
      !taxesData ||
      taxesIsNotComplete;

    setPackagingError(undefined);
    setAddressError(undefined);
    setTaxesError(undefined);

    if (somethingNotComplete) {
      if (!packagingData || packagingIsNotComplete) {
        setPackagingError(
          t(
            'orders:detail.shipping.createShipment.start.error.packagingNotComplete',
          ),
        );
      }
      if (!address || addressIsNotComplete) {
        setAddressError(missingAddressFieldsText());
      }
      if (!taxesData || taxesIsNotComplete) {
        setTaxesError(
          t(
            'orders:detail.shipping.createShipment.start.error.taxesNotComplete',
          ),
        );
      }
      return;
    }

    createCompleteShipmentMutation.mutate(
      {
        orderNo,
        breadth: packagingData?.packageBreadth,
        height: packagingData?.packageHeight,
        length: packagingData?.packageLength,
        weight: packagingData?.packageWeight,
        pickupDate: pickupdate ? format(pickupdate, 'yyyy-MM-dd') : undefined,
      },
      {
        onSuccess: () => {
          setStep({ key: 'downloads' });
          setPackagingError(undefined);
          setAddressError(undefined);
        },
        onError: () => {
          setError(
            t('orders:detail.shipping.createShipment.start.error.generic'),
          );
        },
      },
    );
  };

  return (
    <>
      <Headline
        headingLevel="h2"
        size={2}
        className="order-detail__box-headline"
      >
        {t('orders:detail.shipping.createShipment.start.title')}
      </Headline>
      <Spacer marginTop={5}>
        <Spacer className="shipping__hint" padding={2} marginBottom={10}>
          <LabelText
            label={t('orders:detail.shipping.createShipment.start.hint.title')}
          >
            {t('orders:detail.shipping.createShipment.start.hint.description')}
          </LabelText>
        </Spacer>

        <FlexBox
          justifyContent="space-between"
          flexDirection={address ? 'row' : 'column'}
          className="shipping__address"
        >
          <FlexItem flexGrow={1} marginBottom={address ? 0 : 3}>
            <LabelText
              label={t('orders:detail.shipping.createShipment.start.address')}
            >
              {address ? (
                <Address {...address} />
              ) : (
                <span className="shipping__notfound">
                  {t(
                    'orders:detail.shipping.createShipment.start.addressNotFound',
                  )}
                </span>
              )}
              {addressError && (
                <Spacer marginTop={5}>
                  <Chip color={ColorUse['warning-50']}>{addressError}</Chip>
                </Spacer>
              )}
            </LabelText>
          </FlexItem>
          <FlexItem>
            {address ? (
              <IconButton
                icon="edit"
                appearance="ghost"
                onClick={() => setStep({ key: 'address', data: null })}
              />
            ) : (
              <Button
                text={t('orders:detail.shipping.createShipment.add')}
                onClick={() => setStep({ key: 'address', data: null })}
                appearance="ghost"
              />
            )}
          </FlexItem>
        </FlexBox>

        <FlexBox
          justifyContent="space-between"
          marginTop={8}
          flexDirection={packagingData ? 'row' : 'column'}
          className="shipping__packaging"
        >
          <FlexItem flexGrow={1} marginBottom={packagingData ? 0 : 3}>
            <LabelText
              label={t('orders:detail.shipping.createShipment.packaging.title')}
            >
              <Packaging {...packagingData} />

              {packagingError && (
                <Spacer marginTop={5}>
                  <Chip color={ColorUse['warning-50']}>{packagingError}</Chip>
                </Spacer>
              )}
            </LabelText>
          </FlexItem>
          <FlexItem>
            {packagingData ? (
              <IconButton
                icon="edit"
                appearance="ghost"
                onClick={() => setStep({ key: 'packaging', data: null })}
              />
            ) : (
              <Button
                text={t('orders:detail.shipping.createShipment.add')}
                onClick={() => setStep({ key: 'packaging', data: null })}
                appearance="ghost"
              />
            )}
          </FlexItem>
        </FlexBox>
        <FlexBox
          justifyContent="space-between"
          marginTop={8}
          flexDirection={taxesData ? 'row' : 'column'}
          className="shipping__taxes"
        >
          <FlexItem flexGrow={1} marginBottom={taxesData ? 0 : 3}>
            <LabelText
              label={t('orders:detail.shipping.createShipment.taxes.title')}
            >
              <ProductTaxes {...taxesData} />

              {taxesError && (
                <Spacer marginTop={5}>
                  <Chip color={ColorUse['warning-50']}>{taxesError}</Chip>
                </Spacer>
              )}
            </LabelText>
          </FlexItem>
          <FlexItem>
            {taxesData ? (
              <IconButton
                icon="edit"
                appearance="ghost"
                onClick={() => setStep({ key: 'taxes', data: null })}
              />
            ) : (
              <Button
                text={t('orders:detail.shipping.createShipment.add')}
                onClick={() => setStep({ key: 'taxes', data: null })}
                appearance="ghost"
              />
            )}
          </FlexItem>
        </FlexBox>
        <FlexBox marginTop={8}>
          <FlexItem flexGrow={1}>
            <LabelText
              label={t(
                'orders:detail.shipping.createShipment.pickupdate.title',
              )}
            >
              <Spacer tag="p">
                {pickupdate ? (
                  <>
                    <Spacer>
                      {t(
                        'orders:detail.shipping.createShipment.pickupdate.dateHint',
                      )}
                    </Spacer>
                    <Spacer>
                      {t('dateP', {
                        date: utcToZonedTime(pickupdate, timeZone),
                      })}
                    </Spacer>
                  </>
                ) : (
                  t('orders:detail.shipping.createShipment.pickupdate.nextday')
                )}
              </Spacer>
            </LabelText>
          </FlexItem>
          <FlexItem>
            <IconButton
              icon="edit"
              appearance="ghost"
              onClick={() => setStep({ key: 'pickupdate', data: null })}
            />
          </FlexItem>
        </FlexBox>
      </Spacer>
      <FlexItem pushBottom>
        <Spacer marginTop={10}>
          <Spacer className="small c-dark-50" marginBottom={5}>
            <p>{t('orders:detail.shipping.createShipment.start.nextday')}</p>
          </Spacer>

          {error && (
            <Spacer marginBottom={5}>
              <Chip color={ColorUse['warning-50']}>{error}</Chip>
            </Spacer>
          )}

          <Button
            text={t('orders:detail.shipping.createShipment.start.confirm')}
            disabled={!address || !packagingData || !taxesData}
            color={ColorUse.confirm}
            onClick={handleCreateShipment}
            loading={isLoading}
          />
        </Spacer>
      </FlexItem>
    </>
  );
};

export default CreateShipmentStart;
