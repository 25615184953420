import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import placeholder from '../../images/placeholder-video-mediathek-gradient.svg';
import LazyImage from '../LazyImage/LazyImage';
import './order-item.scss';

export interface Props {
  className?: string;
  title?: string;
  image?: string;
  subtitle?: string;
  variant: string | null;
  productId?: number;
  amount?: number;
}

const OrderItem = ({
  className,
  image,
  title,
  subtitle,
  variant,
  productId,
  amount,
}: Props) => {
  const { t } = useTranslation(['translation', 'list', 'orders']);

  return (
    <li
      className={classNames(
        {
          'order-item': true,
        },
        className,
      )}
    >
      <div className="order-item__image">
        <LazyImage
          src={image || placeholder}
          alt={t('list:preview', { title: title })}
          aspectRatio={100 / 150}
          lqip={placeholder}
          className="order-item__image-lazy"
        />
      </div>

      <div className="order-item__detail">
        <FlexBox>
          <FlexItem flexGrow={1} className="order-item__title-wrap">
            <div className="order-item__title">
              {title === '' ? t('no_title') : title}
            </div>
            {subtitle && <div className="order-item__subtitle">{subtitle}</div>}
          </FlexItem>
          {amount && (
            <FlexItem pushRight>
              <Chip tooltip={t('orders:product.amount')}>{amount}</Chip>
            </FlexItem>
          )}
        </FlexBox>

        <FlexBox
          className="order-item__info"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <LabelText label={t('orders:product.variant.label')}>
            {variant ? variant : t('orders:product.variant.empty')}
          </LabelText>
          {productId && (
            <Link className="order-item__link" to={`/products/${productId}`}>
              {t('orders:product.link')}
            </Link>
          )}
        </FlexBox>
      </div>
    </li>
  );
};

export default OrderItem;
