import Button from 'components/Button/Button';
import InputField from 'components/Input/InputField';
import TextAreaField from 'components/Input/TextAreaField';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import {
  initialAvailabilityValues,
  validateAvailability,
} from 'features/availability';
import AvailabilityFieldSet from 'features/availability/AvailabilityFieldSet/AvailabilityFieldSet';
import { Field, Form, Formik, FormikProps } from 'formik';
import { VoucherDto } from 'generated/models/VoucherDto';
import { VoucherResponseDto } from 'generated/models/VoucherResponseDto';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import * as Yup from 'yup';
import { mapVoucherResponseDtoToVoucherDto } from '../voucher.mappers';

const VoucherForm = ({
  voucher,
  onSubmit,
  onCancel,
  loading,
}: {
  voucher?: VoucherResponseDto;
  onSubmit: (voucherDto: VoucherDto) => void;
  onCancel: () => void;
  loading?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'channel']);

  const nameSchema = Yup.object().shape({
    text: Yup.string().max(40).required(t('form.required')),
    code: Yup.string()
      .max(40)
      .required(t('form.required'))
      .test(
        'code',
        t('channel:voucher.validation.code-mismatch'),
        (value, context) => {
          if (context.parent.text) {
            return context.parent.text.includes(value);
          }
        },
      ),
    condition: Yup.string().required(t('form.required')),
    availability: validateAvailability(t('channel:available.endBeforeStart')),
  });

  const handleSubmit = (values: VoucherDto) => {
    onSubmit(values);
  };

  return (
    <div>
      <Formik
        initialValues={
          voucher
            ? mapVoucherResponseDtoToVoucherDto(voucher)
            : {
                text: '',
                code: '',
                condition: '',
                availability: initialAvailabilityValues().availability,
              }
        }
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize={true}
      >
        {(formik: FormikProps<VoucherDto>) => (
          <Form>
            <FlexBox margin={[4, 0]}>
              <FlexItem flexBasis="30rem">
                <Field
                  name="text"
                  component={InputField}
                  label={t('channel:voucher.text.label')}
                  placeholder={t('channel:voucher.text.placeholder')}
                />
              </FlexItem>

              <FlexItem marginLeft={2} flexGrow={1}>
                <Field
                  name="code"
                  component={InputField}
                  label={t('channel:voucher.code.label')}
                  placeholder={t('channel:voucher.code.placeholder')}
                />
              </FlexItem>
            </FlexBox>

            <FlexBox marginBottom={4}>
              <FlexItem flexGrow={1}>
                <Field
                  name="condition"
                  component={TextAreaField}
                  label={t('channel:voucher.condition')}
                />
              </FlexItem>
            </FlexBox>

            <AvailabilityFieldSet />

            <div className="modal__controls">
              <Button
                text={t('cancel')}
                type="button"
                onClick={onCancel}
                color={Color.primary}
                appearance="ghost"
              />

              <Button
                text={t('save')}
                type="submit"
                color={Color.primary}
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VoucherForm;
