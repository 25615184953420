import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { defaultFallbackLanguage } from 'global-constants';
import {
  NotificationsService,
  PaginatedNotificationResponseDTO,
  GetNotificationsQueryDTO,
  NotificationResponseDTO,
} from 'generated';
import { AppDispatch, RootState } from '../../store';
import {
  NotificationDraftCopy,
  NotificationDraftEdit,
  NotificationDraftNew,
  NotificationDraftType,
  NotificationsState,
  NotificationThunkError,
} from './types';
import {
  mapNotificationDraftToNotificationDTO,
  mapNotificationResponseDtoToNotificationDraftCopy,
  mapNotificationResponseDtoToNotificationDraftEdit,
} from './utils/mapper';

const ENTITIES_PER_PAGE = 10;

const notificationsAdapter = createEntityAdapter<NotificationResponseDTO>({
  sortComparer: (a, b) => b.date.localeCompare(a.date),
});

const initialState = notificationsAdapter.getInitialState<NotificationsState>({
  hasMore: undefined,
  total: 0,
  itemsVisible: 0,
  status: 'idle',
  filter: {
    status: GetNotificationsQueryDTO.status.ALL,
  },
  draft: undefined,
  error: undefined,
});

export const fetchNotifications = createAsyncThunk<
  PaginatedNotificationResponseDTO,
  void,
  { state: RootState; rejectValue: NotificationThunkError }
>('notifications/fetch', async (_, { getState, rejectWithValue }) => {
  const state = getState().notifications;

  const { status } = state.filter;

  try {
    const response = await NotificationsService.getNotifications(
      0,
      ENTITIES_PER_PAGE,
      status,
    );

    return response;
  } catch (error) {
    return rejectWithValue(NotificationThunkError.FETCH);
  }
});

export const fetchMoreNotifications = createAsyncThunk<
  PaginatedNotificationResponseDTO,
  void,
  { state: RootState; rejectValue: NotificationThunkError }
>('notifications/fetch-more', async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const { status } = state.notifications.filter;
  const { ids } = state.notifications;

  try {
    const response = await NotificationsService.getNotifications(
      ids.length,
      ENTITIES_PER_PAGE,
      status,
    );

    return response;
  } catch (error) {
    return rejectWithValue(NotificationThunkError.FETCH);
  }
});

export const deleteNotification = createAsyncThunk<
  NotificationResponseDTO,
  number
>('notifications/delete', async (id) => {
  const response = await NotificationsService.deleteNotification(id);

  return response;
});

export const addNewNotification = createAsyncThunk<
  NotificationResponseDTO,
  NotificationDraftNew | NotificationDraftCopy,
  { rejectValue: NotificationThunkError }
>('notifications/new', async (draft, { rejectWithValue }) => {
  const notificationDto = mapNotificationDraftToNotificationDTO(draft);

  try {
    const response = await NotificationsService.addNotification(
      notificationDto,
    );

    return response;
  } catch (error) {
    return rejectWithValue(NotificationThunkError.ADD);
  }
});

export const editNotification = createAsyncThunk<
  NotificationResponseDTO,
  NotificationDraftEdit,
  { rejectValue: NotificationThunkError }
>('notifications/edit', async (draft, { rejectWithValue }) => {
  const id = draft.id;
  const notificationDto = mapNotificationDraftToNotificationDTO(draft);

  try {
    const response = await NotificationsService.updateNotification(
      id,
      notificationDto,
    );

    return response;
  } catch (error) {
    return rejectWithValue(NotificationThunkError.EDIT);
  }
});

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addNewNotification.pending, (state) => {
        state.status = 'loading-submit';
        state.error = undefined;
      })
      .addCase(addNewNotification.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.total = state.total + 1;
        state.itemsVisible = state.itemsVisible + 1;
        notificationsAdapter.addOne(state, payload);
        state.draft = initialState.draft;
      })
      .addCase(addNewNotification.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(editNotification.pending, (state) => {
        state.status = 'loading-submit';
        state.error = undefined;
      })
      .addCase(editNotification.fulfilled, (state, { payload }) => {
        notificationsAdapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });
        state.status = 'idle';
        state.draft = initialState.draft;
      })
      .addCase(editNotification.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        notificationsAdapter.setAll(state, payload.data);
        state.total = payload.total;
        state.itemsVisible = payload.links.current.offset + payload.data.length;
        state.hasMore = payload.links.next?.limit !== undefined;
        state.status = 'idle';
      })
      .addCase(fetchNotifications.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = payload;
      })
      .addCase(fetchMoreNotifications.pending, (state) => {
        state.status = 'loading-more';
        state.error = undefined;
      })
      .addCase(fetchMoreNotifications.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.hasMore = payload.links.next?.limit !== undefined;
        state.itemsVisible = payload.links.current.offset + payload.data.length;
        notificationsAdapter.addMany(state, payload.data);
      })
      .addCase(fetchMoreNotifications.rejected, (state, { payload }) => {
        state.status = 'idle';
        state.error = payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.status = 'loading-delete';
        state.error = undefined;
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        notificationsAdapter.removeOne(state, payload.id);
        state.total = state.total - 1;
        state.itemsVisible = state.itemsVisible - 1;
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.status = 'idle';
      });
  },
  reducers: {
    reset: (state) => {
      return initialState;
    },
    filterStatusSet: (
      state,
      action: PayloadAction<GetNotificationsQueryDTO.status>,
    ) => {
      state.filter.status = action.payload;
    },
    preparedNew: (state, action: PayloadAction<NotificationDraftNew>) => {
      state.draft = action.payload;
    },
    prepareEdit: (state, action: PayloadAction<NotificationResponseDTO>) => {
      const draft = mapNotificationResponseDtoToNotificationDraftEdit(
        action.payload,
      );

      state.draft = draft;
    },
    prepareCopy: (state, action: PayloadAction<NotificationResponseDTO>) => {
      const draft = mapNotificationResponseDtoToNotificationDraftCopy(
        action.payload,
      );
      state.draft = draft;
    },
    resetDraft: (state) => {
      state.draft = initialState.draft;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  filterStatusSet: setFilterStatus,
  prepareEdit,
  prepareCopy,
  preparedNew,
  reset,
  resetDraft,
} = notificationsSlice.actions;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectById: selectNotificationById,
  selectIds: selectNotificationIds,
  // Pass in a selector that returns the posts slice of state
} = notificationsAdapter.getSelectors(
  (state: RootState) => state.notifications,
);

export const prepareNewDraft =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const supportedLanguages = getState().i18n.supportedLanguages;
    const language =
      supportedLanguages && supportedLanguages.length > 0
        ? supportedLanguages[0].iso
        : defaultFallbackLanguage;

    dispatch(
      preparedNew({
        title: '',
        body: '',
        imageUrl: '',
        platforms: ['ios', 'android'],
        language,
        optionKey: undefined,
        optionValue: '',
        date: undefined,
        type: NotificationDraftType.NEW,
        vendorId: '',
        analyticsLabel: '',
      }),
    );
  };

export default notificationsSlice.reducer;
