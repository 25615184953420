import React, { useLayoutEffect } from 'react';
import Footer from '../components/Footer/Footer';
import Headline from '../components/Headline/Headline';
import Logo from '../images/logo-creator-2x.png';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'react-tooltip-lite';
import Color from 'types/Color';

const LandingPage = ({
  children,
  headline,
  width,
  hint,
}: {
  children: React.ReactNode;
  headline?: string;
  width?: number;
  hint?: React.ReactNode;
}) => {
  const [hintIsOpen, setHintIsOpen] = React.useState(false);

  useLayoutEffect(() => {
    document.querySelector('body')?.classList.add('body--login');

    return () =>
      document.querySelector('body')?.classList.remove('body--landing');
  }, []);

  return (
    <div className="page page--landing">
      <div className="page__logo">
        <img src={Logo} width={241} height={54} alt="Jooli Logo" />
      </div>

      <div className="page__landing" style={{ maxWidth: `${width}px` }}>
        {headline && (
          <Headline
            className="page__landing-headline"
            headingLevel="h1"
            size={2}
          >
            {headline}
          </Headline>
        )}

        {children}
      </div>

      {hint && (
        <div className="page__help">
          <Tooltip
            content={hint}
            isOpen={hintIsOpen}
            tipContentClassName="help__tooltip"
          >
            <IconButton
              icon="help"
              onClick={() => setHintIsOpen(!hintIsOpen)}
              color={Color.secondary}
              appearance="filled"
              big
            />
          </Tooltip>
        </div>
      )}

      <Footer className="page__footer" />
    </div>
  );
};

export default LandingPage;
