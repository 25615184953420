import axios, { CancelTokenSource } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadCon from 'react-loadcon';
import * as Yup from 'yup';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import Modal from '../../components/Modal/Modal';
import VideoUploader, {
  Values,
} from '../../components/VideoUploader/VideoUploader';
import { AllowedTypes } from '../../components/VideoUploadField/VideoUploadField';
import {
  ApiError,
  ChannelsService,
  ProductsService,
  VideoDTO,
  VideoRequestUploadUrlDTO,
  VideosService,
} from '../../generated';
import useOpen from '../../hooks/useOpen';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut, selectVendorAgent } from '../../redux/slices/auth/authSlice';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import Color from '../../types/Color';
import Language from '../../types/Language';
import notAuthenticated from '../../utils/not-authenticated';
import { queryClient } from 'index';
import { videoListKeys } from 'features/video-list';
import { VideoUploadFeature } from 'features/video-upload/types';

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  productId?: number;
  channelId?: number;
}

export enum Status {
  normal = 'normal',
  active = 'active',
  exception = 'exception',
  success = 'success',
}

/** This is the 1.0 uploader that can only upload one video at once and blocks the UI for that.
 * Will possibly be replaced by the multiple uploader in features/video-upload.
 */
const VideoUploadContainer = ({
  isOpen,
  onClose,
  productId,
  channelId,
}: Props) => {
  const { t } = useTranslation();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const dispatch = useReduxDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [subjectName, setSubjectName] = useState<string>();
  const { isOpen: cancelIsOpen, open, close } = useOpen(false);
  const [status, setStatus] = useState<Status>(Status.normal);
  const [error, setError] = useState<ApiError>();
  const [fileId, setFileId] = useState<string>();
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const initialValues = {
    file: null,
    name: '',
    language: supportedLanguages?.[0]?.iso as Language,
  };

  const filetype = (name: string) => name.substring(name.lastIndexOf('.') + 1);

  const nameSchema = Yup.object().shape({
    file: Yup.mixed().test(
      'fileType',
      t('form.wrongType', { type: '.mp4, .mov, .mpv' }),
      (file) => {
        return (
          file &&
          Object.values(AllowedTypes).includes(
            file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
          )
        );
      },
    ),
  });

  useEffect(() => {
    if (productId) {
      const setName = async () => {
        try {
          const res = await ProductsService.getProductById(productId);
          setSubjectName(
            `${t('video:upload.product.id')}: ${res.vendorProductId}`,
          );
        } catch (error) {
          setSubjectName(undefined);
        }
      };
      setName();
    }
  }, [productId]);

  useEffect(() => {
    if (channelId) {
      const setName = async () => {
        try {
          const res = await ChannelsService.getChannel(channelId);
          setSubjectName(res.name);
        } catch (error) {
          setSubjectName(undefined);
        }
      };
      setName();
    }
  }, [channelId]);

  useEffect(() => {
    if (status === Status.success) {
      close();
    }
  }, [status]);

  const uploadFile = async (
    file: File,
    uri: string,
    options: { language: Language; name: string },
  ) => {
    const cancel = axios.CancelToken.source();
    setCancelToken(cancel);

    // upload file to 3Q
    axios({
      method: 'put',
      url: uri,
      data: file,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total ?? 1),
        );
        setProgress(percentCompleted);
      },
      cancelToken: cancel.token,
    })
      .then(
        async ({ data }: { data: VideoUploadFeature.ThreeQUploadResponse }) => {
          if (data.FileId) {
            const req = {
              language: options.language,
              name: options.name,
              threeQFileId: parseInt(data.FileId.toString(), 10),
            };

            if (productId) {
              setFileId(data.FileId.toString());
              // add a new video to the product with the FileId from 3Q
              return ProductsService.addProductVideo(productId, {
                type: VideoDTO.type.PRODUCT,
                ...req,
              });
            } else if (channelId) {
              setFileId(data.FileId.toString());
              // add new introduction video to channel with the FileId from 3Q
              return ChannelsService.addIntroVideo(channelId, {
                type: VideoDTO.type.CHANNEL,
                ...req,
              });
            }
          }
        },
      )
      .then((res) => {
        setStatus(Status.success);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('axios cancel error', error.message);
          setStatus(Status.normal);
        } else {
          setStatus(Status.exception);
          setError(error);
        }
      });
  };

  const handleUpload = ({ file, name, language }: Values) => {
    if (file) {
      setStatus(Status.active);
      // get Url for uploading to 3Q
      const getUploadUrl = async () => {
        try {
          const res = await VideosService.createVideoUploadUrl({
            fileName: file.name,
            fileFormat: filetype(
              file.name,
            ).toLocaleLowerCase() as VideoRequestUploadUrlDTO.fileFormat,
          });
          if (res.url) {
            await uploadFile(file, res.url, { name, language });
            queryClient.invalidateQueries(
              videoListKeys.getVideos(vendorAgent?.currentVendor.id),
            );
          }
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            setStatus(Status.normal);
            dispatch(logOut());
          } else {
            setStatus(Status.exception);
            setError(error as ApiError);
          }
        }
      };
      getUploadUrl();
    }
  };

  const handleCancelAndClose = (
    resetForm: FormikHelpers<Values>['resetForm'],
  ) => {
    if (cancelToken && cancelToken.cancel) {
      cancelToken.cancel('Operation canceled due to modal close');
    }
    setProgress(0);
    setStatus(Status.normal);
    close();
    onClose();
    handleClose(resetForm);
  };

  const handleClose = (resetForm: FormikHelpers<Values>['resetForm']) => {
    resetForm();
    setStatus(Status.normal);
    setProgress(0);
    onClose();
  };

  const handleStartNext = (resetForm: FormikHelpers<Values>['resetForm']) => {
    resetForm();
    setProgress(0);
    setStatus(Status.normal);
  };

  const handleChangeName = (
    name: string,
    language: Language,
    setErros: FormikHelpers<Values>['setErrors'],
  ) => {
    if (fileId) {
      const addProductVideo = async () => {
        try {
          let res;
          const req = {
            language: language,
            name: name,
            threeQFileId: parseInt(fileId, 10),
          };
          if (productId) {
            res = await ProductsService.addProductVideo(productId, {
              type: VideoDTO.type.PRODUCT,
              ...req,
            });
          } else if (channelId) {
            res = await ChannelsService.addIntroVideo(channelId, {
              type: VideoDTO.type.CHANNEL,
              ...req,
            });
          }
          if (res) {
            setStatus(Status.success);
            setError(undefined);
            setErros({});
          }
        } catch (error) {
          setStatus(Status.exception);
          setError(error as ApiError);
        }
      };

      addProductVideo();
    } else {
      setStatus(Status.exception);
      setError(undefined);
    }
  };

  const typo = () => {
    if (productId) {
      return {
        title: t('video:upload.product.title'),
        description: t('video:upload.product.description'),
      };
    } else if (channelId) {
      return {
        title: t('video:upload.channel.title'),
        description: t('video:upload.channel.description'),
      };
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpload}
      validationSchema={nameSchema}
    >
      {(formik: FormikProps<Values>) => (
        <Modal
          isOpen={isOpen}
          onClose={
            status === Status.active
              ? open
              : () => handleClose(formik.resetForm)
          }
          variant="full"
          headline={subjectName || ''}
        >
          <>
            <Form className="video-uploader__form">
              <LoadCon
                percentage={progress}
                status={status}
                type="donut"
                color={Color.dark}
              />
              <VideoUploader
                {...typo()}
                progress={progress}
                onCancel={open}
                onStartNext={() => handleStartNext(formik.resetForm)}
                onClose={() => handleClose(formik.resetForm)}
                status={status}
                error={error}
                onChangeName={() =>
                  handleChangeName(
                    formik.values.name,
                    formik.values.language,
                    formik.setErrors,
                  )
                }
                type={channelId ? 'channel' : 'product'}
              />
            </Form>

            <ConfirmModal
              isOpen={cancelIsOpen}
              headline={t('video:upload.end')}
              text={t('video:upload.reallyEnd')}
              cancelText={t('video:upload.continue')}
              onCancelClick={close}
              confirmText={t('video:upload.endShort')}
              onConfirmClick={() => handleCancelAndClose(formik.resetForm)}
            />
          </>
        </Modal>
      )}
    </Formik>
  );
};

export default VideoUploadContainer;
