import { useState } from 'react';

export function useListSelection(initial?: number[]) {
  const [selectedIds, setSelectedIds] = useState<number[]>(initial || []);

  const toggleSelectItem = (id: number) => {
    if (selectedIds.includes(id)) {
      removeItem(id);
    } else {
      addItem(id);
    }
  };

  const setSelectedItems = (itemIds: number[]) => {
    setSelectedIds(itemIds);
  };

  const selectItem = (id: number, select: boolean) => {
    if (select) {
      addItem(id);
    } else {
      removeItem(id);
    }
  };

  const addItem = (id: number) => {
    setSelectedIds((prevState) => [...new Set(prevState.concat(id))]);
  };

  const removeItem = (id: number) => {
    setSelectedIds((prevState) => {
      return prevState.filter((i) => i !== id);
    });
  };

  return {
    selectedIds,
    setSelectedItems,
    toggleSelectItem,
    selectItem,
  };
}
