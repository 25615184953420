import FilterBar from 'components/FilterBar/FilterBar';
import { useNavigate } from 'react-router-dom';
import useVideoFilter from '../hooks/useVideoFilter';
import { getFilterItemsFromFilter } from '../utils';

// TODO use new generic filter feature
const VideoFilter = () => {
  const { filter, resetFilter, removeFilterById } = useVideoFilter();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/videos/filter');
  };

  const onRemoveItem = (id: string) => {
    removeFilterById(id);
  };

  return (
    <FilterBar
      items={getFilterItemsFromFilter(filter)}
      onReset={resetFilter}
      onRemoveItem={onRemoveItem}
      onClick={onClick}
    />
  );
};

export default VideoFilter;
