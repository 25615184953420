import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Button from '../components/Button/Button';
import NotFound from '../components/NotFound/NotFound';
import ChannelDetailForm from 'features/channel-detail-form';
import ChannelVideoContainer from '../container/ChannelVideoContainer/ChannelVideoContainer';
import Color from '../types/Color';
import Error from '../types/Error';
import PageType from '../types/PageType';

const ChannelDetailPage: FC<PageType> = () => {
  const { id } = useParams();
  const numberId = id ? parseInt(id, 10) : undefined;
  const { t } = useTranslation(['translation', 'channel']);
  const [loadSuccess, setLoadSuccess] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<Error>();

  useEffect(() => {
    clearAllBodyScrollLocks();
  }, []);

  const handleLoadSuccess = () => {
    setLoadSuccess(true);
  };

  const handleLoadError = (error: Error) => {
    setLoadError(error);
  };

  return (
    <div className="page page--detail">
      {loadError ? (
        <NotFound error={loadError}>
          <p>{t('channel:notFound')}</p>
          <Button
            text={t('channel:navigateTo')}
            icon="arrow-right"
            link="/channels"
            color={Color.secondary}
            appearance="ghost"
          />
        </NotFound>
      ) : (
        <>
          <div className="page__breadcrumb">
            <Breadcrumbs />
          </div>
          <ChannelDetailForm
            channelId={numberId}
            edit
            onLoadSuccess={handleLoadSuccess}
            onLoadError={handleLoadError}
          />
          {loadSuccess && <ChannelVideoContainer channelId={numberId} />}
        </>
      )}
    </div>
  );
};
export default ChannelDetailPage;
