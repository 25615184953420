import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import './avatar.scss';

const Avatar = ({ name }: { name: string }) => (
  <Tooltip direction="up" content={name} className="avatar">
    <div className="avatar__content">
      {name.length >= 1 && name.charAt(0).toUpperCase()}
    </div>
  </Tooltip>
);

export default Avatar;
