import DatePicker from 'components/DatePicker/DatePicker';
import { Spacer } from 'components/Layout';
import { isAfter, isBefore, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useFilter } from 'features/filter';
import { OrderFilterItem, OrderFilterKeys } from 'features/order-filter/types';
import FilterDetail from 'features/video-filter/VideoFilterSideBar/components/FilterDetail';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';

const CreatedAtFilter = () => {
  const { t } = useTranslation(['translation', 'orders']);
  const { filter, setFilter } = useFilter<OrderFilterKeys, OrderFilterItem>();
  const timeZone = useReduxSelector(selectTimezone);

  const isBeforeEndDate = (date: Date): boolean => {
    if (filter?.createdAtTo?.[0]?.zonedDate) {
      return isBefore(
        utcToZonedTime(date, timeZone),
        utcToZonedTime(filter?.createdAtTo?.[0]?.zonedDate, timeZone),
      );
    }
    return true;
  };
  const isAfterStartDate = (date: Date): boolean => {
    if (filter?.createdAtFrom?.[0]?.zonedDate) {
      return isAfter(
        utcToZonedTime(date, timeZone),
        utcToZonedTime(filter?.createdAtFrom?.[0]?.zonedDate, timeZone),
      );
    }
    return true;
  };

  const props = {
    showTimeSelect: true,
    timeFormat: 'HH:mm',
    timeIntervals: 15,
    timeCaption: 'time',
    isClearable: true,
  };

  const getMinDate = () => {
    return filter?.createdAtFrom?.[0]?.zonedDate
      ? (subDays(
          utcToZonedTime(filter?.createdAtFrom?.[0]?.zonedDate, timeZone),
          0,
        ) as Date)
      : null;
  };

  const getMaxDate = () => {
    return filter?.createdAtTo?.[0]?.zonedDate
      ? subDays(
          utcToZonedTime(filter?.createdAtTo?.[0]?.zonedDate, timeZone),
          0,
        )
      : null;
  };

  const handleChange = (name: string, value?: Date) => {
    console.log(value);
    if (!value) {
      setFilter(name as OrderFilterKeys, []);
      return;
    }
    setFilter(name as OrderFilterKeys, [
      {
        id: name,
        zonedDate: value.toISOString(),
        name:
          name === 'createdAtFrom'
            ? `${t('orders:filter.createdAt.from.label')} ${t('date', {
                date: value,
              })}`
            : `${t('orders:filter.createdAt.to.label')} ${t('date', {
                date: value,
              })}`,
      },
    ]);
  };

  return (
    <FilterDetail>
      <Spacer tag="p" className="small" marginBottom={2}>
        {t('orders:filter.createdAt.description')}
      </Spacer>
      <form>
        <Spacer marginBottom={2}>
          <DatePicker
            name="createdAtFrom"
            label={t('orders:filter.createdAt.from.label')}
            startDate={
              filter?.createdAtFrom?.[0]?.zonedDate
                ? utcToZonedTime(
                    filter?.createdAtFrom?.[0]?.zonedDate,
                    timeZone,
                  )
                : null
            }
            endDate={
              filter?.createdAtTo?.[0]?.zonedDate
                ? utcToZonedTime(filter?.createdAtTo?.[0]?.zonedDate, timeZone)
                : null
            }
            {...props}
            selectsStart={true}
            placeholderText={t('channel:date.start.placeholder')}
            maxDate={getMaxDate()}
            filterTime={isBeforeEndDate}
            onChange={handleChange}
            value={filter?.createdAtFrom?.[0]?.zonedDate as string}
          />
        </Spacer>
        <Spacer>
          <DatePicker
            name="createdAtTo"
            label={t('orders:filter.createdAt.to.label')}
            startDate={
              filter?.createdAtFrom?.[0]?.zonedDate
                ? utcToZonedTime(
                    filter?.createdAtFrom?.[0]?.zonedDate,
                    timeZone,
                  )
                : null
            }
            endDate={
              filter?.createdAtTo?.[0]?.zonedDate
                ? utcToZonedTime(filter?.createdAtTo?.[0]?.zonedDate, timeZone)
                : null
            }
            {...props}
            selectsEnd={true}
            placeholderText={t('channel:date.end.placeholder')}
            filterTime={(date) => isAfterStartDate(date)}
            minDate={getMinDate()}
            value={filter?.createdAtTo?.[0]?.zonedDate as string}
            onChange={handleChange}
          />
        </Spacer>
      </form>
    </FilterDetail>
  );
};

export default CreatedAtFilter;
