import classNames from 'classnames';
import React, { ElementType } from 'react';
import { getSpacerClassNames, SpacerType } from '../Spacer/Spacer';
import { BREAKPOINTS } from '../../../global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

interface CompProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  breakpoint?: keyof typeof BREAKPOINTS;
  type?: string;
}

export type FlexBoxType = {
  display?: 'flex' | 'inline-flex' | 'block' | 'inline-block';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  alignContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'baseline';
};

export type Props = CompProps & FlexBoxType & SpacerType;

export const getFlexBoxClassNames = (
  props: FlexBoxType,
  isBreakpoint = true,
) => {
  const {
    display,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
  } = props;

  let direction = flexDirection;

  if (['row', 'row-reverse', undefined].some((e) => e === flexDirection)) {
    if (!isBreakpoint) {
      direction = (flexDirection || 'column')?.replace('row', 'column') as
        | 'row'
        | 'row-reverse'
        | 'column'
        | 'column-reverse';
    }
  }

  return classNames(
    display && `flex-box--display-${display}`,
    direction && `flex-box--flex-direction-${direction}`,
    flexWrap && `flex-box--flex-wrap-${flexWrap}`,
    justifyContent && `flex-box--justify-content-${justifyContent}`,
    alignItems && `flex-box--align-items-${alignItems}`,
    alignContent && `flex-box--align-content-${alignContent}`,
  );
};

const FlexBox = ({
  tag: Tag = 'div',
  children,
  className,
  display,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  border,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
  breakpoint,
  ...props
}: Props) => {
  const isBreakpoint = useMediaQuery(
    `(min-width: ${BREAKPOINTS[breakpoint || 'xs']})`,
  );

  return (
    <Tag
      className={classNames(
        'flex-box',
        getFlexBoxClassNames(
          {
            display,
            flexDirection,
            flexWrap,
            justifyContent,
            alignItems,
            alignContent,
          },
          breakpoint ? isBreakpoint : true,
        ),
        getSpacerClassNames({
          margin,
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          border,
          borderTop,
          borderBottom,
          borderLeft,
          borderRight,
        }),
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default FlexBox;
