import classNames from 'classnames';
import Icon, { IconType } from 'components/Icon/Icon';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { GroupBase, ValueContainerProps, components } from 'react-select';
import { OptionWithIconProps } from './OptionWithIcon';

export const ValueContainerIconValue = <
  OptionType extends OptionWithIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>({
  children,
  ...props
}: ValueContainerProps<OptionType, IsMulti, Group>) => {
  const value = props.getValue()[0];
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <components.ValueContainer
      {...props}
      className={classNames(
        props.className,
        'select-interactive__value-container--custom',
        value?.icon && isDesktop && 'select-interactive__value-container--icon',
      )}
    >
      {value?.icon && isDesktop && (
        <span className="select-interactive__value-icon">
          {typeof value?.icon === 'string' ? (
            <Icon icon={value?.icon as IconType} />
          ) : (
            value?.icon
          )}
        </span>
      )}
      {children}
    </components.ValueContainer>
  );
};

export default ValueContainerIconValue;
