import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading, {
  ImageListType,
  ImageType,
} from 'react-images-uploading';
import IconButton from '../IconButton/IconButton';
import './image-uploader.scss';
import { getErrors } from './utils';

export type Dimensions = {
  width: number;
  height: number;
};

export type Validation = {
  acceptType?: string[];
  ratio?: Dimensions;
  minDimensions?: Dimensions;
  maxDimensions?: Dimensions;
  maxFileSize?: number;
};

type Props = {
  text: string;
  onChange: (file: File) => void;
  value?: string;
  onDelete: () => void;
  description?: string;
  uploadError?: boolean;
  disabled?: boolean;
} & Validation;

const ImageUploader = ({
  text,
  onChange,
  onDelete,
  value,
  acceptType = ['jpeg', 'jpg', 'gif', 'svg', 'png'],
  ratio,
  minDimensions,
  maxDimensions,
  maxFileSize,
  description,
  uploadError,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const validation = {
    acceptType,
    // ratio,
    minDimensions,
    maxDimensions,
    maxFileSize,
  };

  const [image, setImage] = useState<ImageType>();
  const [errors, setErrors] = useState<
    | {
        acceptType?: boolean;
        ratio?: boolean;
        minDimensions?: boolean;
        maxDimensions?: boolean;
        maxFileSize?: boolean;
        fileLoad?: boolean;
      }
    | undefined
  >(uploadError ? { fileLoad: true } : undefined);

  const handleChange = async (imageList: ImageListType) => {
    const newErrors = await getErrors(validation, imageList[0].file);

    if (newErrors || uploadError) {
      setErrors({ ...newErrors, fileLoad: uploadError });
      return;
    }

    if (imageList[0].file) {
      setErrors(undefined);
      setImage(imageList[0]);
    }
  };

  const handleDeleteClick = () => {
    onDelete();
    setImage(undefined);
  };

  useEffect(() => {
    if (image?.file && !errors) {
      onChange(image.file);
    }
  }, [image, errors]);

  useEffect(() => {
    if (uploadError) {
      setErrors((pre) => ({ ...pre, fileLoad: true }));
    }
  }, [uploadError]);

  return (
    <ImageUploading value={image ? [image] : []} onChange={handleChange}>
      {({ onImageUpload, dragProps }) => {
        return (
          <>
            <div className="image-uploader">
              {value && (
                <div className="image-uploader__delete-button">
                  <IconButton
                    icon="delete"
                    onClick={handleDeleteClick}
                    tooltip={t('edit:imageInput.delete')}
                    tooltipPlace="right"
                  />
                </div>
              )}

              <button
                className={classnames({
                  'image-uploader__drop-area': true,
                  'image-uploader__drop-area--disabled': disabled,
                })}
                onClick={onImageUpload}
                {...dragProps}
                type="button"
                disabled={disabled}
              >
                {value === '' ? (
                  <span className="image-uploader__text">{text}</span>
                ) : value || image?.dataURL ? (
                  <div className="image-uploader__preview-wrap">
                    <img
                      className="image-uploader__preview-img"
                      src={image?.dataURL || value}
                      alt="Preview"
                    />
                  </div>
                ) : (
                  <span className="image-uploader__text">{text}</span>
                )}
              </button>
              {description && (
                <div className="image-uploader__desc">
                  <span className="caption">{t('edit:imageInput.info')}</span>
                  <p>{description}</p>
                </div>
              )}
            </div>
            {errors && (
              <ul className="image-uploader__errors">
                {Object.keys(errors).map((error) => (
                  <li key={error} className="image-uploader__error">
                    {t(`form.${error}`)}
                  </li>
                ))}
              </ul>
            )}
          </>
        );
      }}
    </ImageUploading>
  );
};

export default ImageUploader;

// t('form.maxDimensions')
// t('form.ratio')
