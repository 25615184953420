import { useTranslation } from 'react-i18next';
import Select, { GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Props } from '../SelectInteractive';
import CreateButton from './CreateButton';
import { DefaultOption } from './Option';

const DesktopSelect = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, Group>,
) => {
  const { t } = useTranslation();

  if (props.onCreateOption) {
    return (
      <CreatableSelect
        formatCreateLabel={(inputValue) =>
          CreateButton(inputValue, t('add'), props.createButtonPrefix)
        }
        menuPosition="absolute"
        {...props}
      />
    );
  }

  return <Select menuPosition="absolute" {...props} />;
};

export default DesktopSelect;
