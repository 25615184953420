/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedGetCategoryFiltersResponseDTO } from '../models/PaginatedGetCategoryFiltersResponseDTO';
import type { PaginatedGetChannelsFiltersResponseDTO } from '../models/PaginatedGetChannelsFiltersResponseDTO';
import type { PaginatedGetHashtagFiltersResponseDTO } from '../models/PaginatedGetHashtagFiltersResponseDTO';
import type { PaginatedGetProductFiltersResponseDTO } from '../models/PaginatedGetProductFiltersResponseDTO';
import type { PaginatedGetStatusFiltersResponseDTO } from '../models/PaginatedGetStatusFiltersResponseDTO';
import type { PaginatedGetTypeFiltersResponseDTO } from '../models/PaginatedGetTypeFiltersResponseDTO';
import type { PaginatedGetVisibilityFiltersResponseDTO } from '../models/PaginatedGetVisibilityFiltersResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FiltersService {

    /**
     * Get searchable channels of the vendor
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetChannelsFiltersResponseDTO
     * @throws ApiError
     */
    public static getChannelFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetChannelsFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/channels',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable products of the vendor
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetProductFiltersResponseDTO
     * @throws ApiError
     */
    public static getProductFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetProductFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/products',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable video status
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetStatusFiltersResponseDTO
     * @throws ApiError
     */
    public static getStatusFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetStatusFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/status',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable categories of the vendor
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetCategoryFiltersResponseDTO
     * @throws ApiError
     */
    public static getCategoryFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetCategoryFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/categories',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable video type
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetTypeFiltersResponseDTO
     * @throws ApiError
     */
    public static getTypeFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetTypeFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/type',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable video visibility
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetVisibilityFiltersResponseDTO
     * @throws ApiError
     */
    public static getVisibilityFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetVisibilityFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/visibility',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

    /**
     * Get searchable video visibility
     * @param offset
     * @param limit
     * @param query
     * @returns PaginatedGetHashtagFiltersResponseDTO
     * @throws ApiError
     */
    public static getHashtagFilters(
        offset?: number,
        limit?: number,
        query?: string,
    ): CancelablePromise<PaginatedGetHashtagFiltersResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/filters/hashtags',
            query: {
                'offset': offset,
                'limit': limit,
                'query': query,
            },
        });
    }

}
