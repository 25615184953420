import { useState } from 'react';

const usePageCollapse = (initial?: boolean) => {
  const [open, setOpen] = useState<boolean>(initial || false);

  const togglePageCollapse = () => {
    setOpen(!open);
  };

  const closePageCollapse = () => {
    setOpen(false);
  };
  const openPageCollapse = () => {
    setOpen(true);
  };

  return {
    pageCollapseIsOpen: open,
    togglePageCollapse,
    closePageCollapse,
    openPageCollapse,
  };
};

export default usePageCollapse;
