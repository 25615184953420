import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Headline from 'components/Headline/Headline';
import Loader from 'components/Loader/Loader';
import ActivateToggle from 'components/ActivateToggle/ActivateToggle';
import { KeyValueDTO } from 'generated/models/KeyValueDTO';
import { KeyValueStoreService } from 'generated/services/KeyValueStoreService';

const ToggleStatisticClicks = () => {
  // TODO take from kvstore Redux
  const [showClicks, setShowClicks] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['translation', 'settings']);

  const getValue = async (): Promise<boolean> => {
    const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
      KeyValueDTO.key.STATISTICS_SHOW_CLICKS,
    );

    return keyValueDto.value === 'true';
  };

  const setValue = async (): Promise<void> => {
    setLoading(true);

    const keyValueDto = await KeyValueStoreService.setKeyValueStoreItem({
      key: KeyValueDTO.key.STATISTICS_SHOW_CLICKS,
      value: showClicks ? 'false' : 'true',
    });

    setLoading(false);

    setShowClicks(keyValueDto.value === 'true');
  };

  useEffect(() => {
    getValue().then((_showClicks) => setShowClicks(_showClicks));
  }, []);

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:toggle-statistics-clicks.headline')}
      </Headline>

      <p className="settings__block page__form-desc">
        {t('settings:toggle-statistics-clicks.description')}
      </p>

      {showClicks === undefined ? (
        <Loader />
      ) : (
        <div>
          <label id="statistic-clicks-toggle-desc" className="input__label">
            {t('edit')}
          </label>

          <ActivateToggle
            active={showClicks}
            onToggle={setValue}
            ariaDescribedBy="#statistic-clicks-toggle-desc"
            activeIcon="eye"
            inactiveIcon="eye-off"
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default ToggleStatisticClicks;
