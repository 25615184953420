const c = (variableName: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName);

export const rgba = (variableName: string, alpha: number) => {
  const value = c(variableName);
  const alphaValue = (alpha * 100).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${value}${alphaValue}`;
};

export default c;
