import { HashtagsService } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import QUERY_KEYS from 'types/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';

export const hashtagSettingsQueryKeys = {
  getHashtagSettings: [QUERY_KEYS.getHashtagSettings],
};

export const useGetHashtagSettings = () => {
  return useQuery({
    queryKey: hashtagSettingsQueryKeys.getHashtagSettings,
    queryFn: () => HashtagsService.getHashtagSettings(),
  });
};

/** Only SuperAdmin */
export const useUpdateHashtagSettings = () => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['settings']);

  return useMutation({
    mutationFn: ({
      maximumHashtagsPerEntity,
    }: {
      maximumHashtagsPerEntity: number;
    }) =>
      HashtagsService.changeHashtagSettings({
        maximumHashtagsPerEntity,
      }),
    mutationKey: [QUERY_KEYS.updateHashtagSettings],
    onSuccess: () => {
      setAppStatus(t('settings:hashtags.success'));
      queryClient.invalidateQueries(
        hashtagSettingsQueryKeys.getHashtagSettings,
      );
    },
    onError: () => {
      setAppStatus(t('settings:hashtags.error'), 'error');
    },
  });
};
