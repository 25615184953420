import QueryList from 'components/List/QueryList';
import Loader from 'components/Loader/Loader';

import IconButton from 'components/IconButton/IconButton';
import { Spacer } from 'components/Layout';
import OrderListItem from 'components/OrderListItem/OrderListItem';
import { useFilter } from 'features/filter';
import {
  OrderFilterItem,
  OrderFilterKeys,
  getFilterQueryFromFilter,
} from 'features/order-filter';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import React, { Key, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import Color from 'types/Color';
import { useGetOrders } from '../queries';

const OrderData = () => {
  const { t } = useTranslation(['video']);
  const { filter, resetFilter } = useFilter<OrderFilterKeys, OrderFilterItem>();
  const getOrdersQuery = useGetOrders(getFilterQueryFromFilter(filter));
  const { status, data, isFetching } = getOrdersQuery;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const vendorAgent = useReduxSelector(selectVendorAgent);

  useEffect(() => {
    resetFilter();
  }, [vendorAgent?.currentVendor]);

  if (status === 'loading' && !isFetching) {
    return <Loader />;
  }

  if (status === 'error') {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>
        {t('orders:list.error')}
      </Spacer>
    );
  }

  if (
    status === 'success' &&
    data?.pages.map((i) => i.data).flat().length === 0
  ) {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>
        {t('orders:list.empty')}
      </Spacer>
    );
  }

  return (
    <QueryList
      {...getOrdersQuery}
      rightSlot={
        !isDesktop ? (
          <IconButton
            highlightNumber={
              Object.values(filter || []).flat().length || undefined
            }
            onClick={() => navigate('filter')}
            icon="filter"
            color={Color.confirm}
            big
          />
        ) : undefined
      }
    >
      {data?.pages.map((page, i) => (
        <React.Fragment key={(page.links?.next?.offset || i) as Key}>
          {page.data.map((order, index) => (
            <OrderListItem
              key={order.orderNo}
              currentOrderStatus={order.currentOrderStatus}
              createdAt={order.createdAt}
              product={order.orderItems[0].product}
              orderNo={order.orderNo}
              onClick={() => navigate(`/orders/${order.orderNo}`)}
            />
          ))}
        </React.Fragment>
      ))}
    </QueryList>
  );
};

export default OrderData;
