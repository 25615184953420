import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useOpen from '../../hooks/useOpen';
import Color from '../../types/Color';
import Button from '../Button/Button';
import Imprint from '../Imprint/Imprint';
import Modal from '../Modal/Modal';
import './footer.scss';

const Footer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { open, close, isOpen } = useOpen(false);
  const thisYear = new Date().getFullYear();

  return (
    <footer className={classNames('footer', className)}>
      © {thisYear} - jooli.com GmbH{' '}
      <span>
        -{' '}
        <button type="button" onClick={open} className="footer__link">
          {t('imprintTitle')}
        </button>
      </span>
      <Modal isOpen={isOpen} onClose={close} headline={t('imprintTitle')}>
        <Imprint />

        <div className="modal__controls">
          <Button
            text={t('close')}
            type="button"
            onClick={close}
            color={Color.primary}
            appearance="ghost"
          />
        </div>
      </Modal>
    </footer>
  );
};

export default Footer;
