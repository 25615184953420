import { curveLinear } from 'd3-shape';
import React from 'react';
import { AxisOptions, Chart as ReactChart } from 'react-charts';
import { useTranslation } from 'react-i18next';
import { DailyCountDTO } from '../../generated';
import { Series } from '../../pages/StatisticsPage';
import c, { rgba } from '../../utils/colors';
import Loader from '../Loader/Loader';
import './statistics.scss';

const Chart = ({
  data,
  loading,
  showClicks,
}: {
  data?: Series[] | null;
  loading?: boolean;
  showClicks?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'statistics']);

  const primaryAxis = React.useMemo<AxisOptions<DailyCountDTO>>(
    () => ({
      getValue: (datum) => new Date(datum.date),
      scaleType: 'time',
    }),
    [],
  );

  const secondaryAxes = React.useMemo<AxisOptions<DailyCountDTO>[]>(
    () => [
      {
        getValue: (datum) => datum.count,
        scaleType: 'linear',
        elementType: 'line',
        curve: (context) => curveLinear(context),
      },
    ],
    [],
  );

  return (
    <div className="statistics__chart">
      <div className="statistics__chart-inner">
        {data === null ? (
          <div className="statistics__chart-center">
            {t('statistics:chart.no-data')}
          </div>
        ) : loading ? (
          <div className="statistics__chart-center">
            <Loader />
          </div>
        ) : (
          data && (
            <ReactChart
              options={{
                data,
                primaryAxis,
                secondaryAxes,
                defaultColors: [
                  showClicks
                    ? rgba('--color-confirm', 0.8)
                    : c('--color-confirm'),
                  c('--color-warning'),
                ],
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Chart;
