import SelectInteractive, {
  OptionWithIcon,
  OptionWithIconProps,
  ValueContainerIconValue,
} from 'components/SelectInteractive';
import { Props as SelectInteractiveProps } from 'components/SelectInteractive/';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

export type Props<isMulti extends boolean = false> = Omit<
  SelectInteractiveProps<OptionWithIconProps, isMulti>,
  'options'
> & { options?: VideoResponseDTO.status[] };

const StatusSelect = <isMulti extends boolean = false>({
  options,
  ...props
}: Props<isMulti>) => {
  const { t } = useTranslation(['translation', 'video']);

  const mappedOptions = (options || Object.values(VideoResponseDTO.status)).map(
    (i) => ({
      value: i,
      label: t(`status.${i}`),
      icon: <VideoStatusIcon status={i} />,
    }),
  );

  return (
    <SelectInteractive
      {...props}
      options={mappedOptions}
      components={{
        Option: OptionWithIcon,
        ValueContainer: !props.isMulti
          ? ValueContainerIconValue
          : components.ValueContainer,
      }}
      noOptionsMessage={props.noOptionsMessage || t('video:visibility.empty')}
      title={t('statusTitle')}
      label={t('statusTitle')}
    />
  );
};

export default StatusSelect;
