import classNames from 'classnames';
import React, { ElementType } from 'react';

interface CompProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
}

export type SpacerType = {
  margin?:
    | [top?: number, right?: number, bottom?: number, left?: number]
    | number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  padding?:
    | [top?: number, right?: number, bottom?: number, left?: number]
    | number;
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  border?:
    | [top?: number, right?: number, bottom?: number, left?: number]
    | number;
  borderTop?: number;
  borderRight?: number;
  borderBottom?: number;
  borderLeft?: number;
};

export type Props = CompProps & SpacerType;

export const getSpacerClassNames = ({
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  border: spacerBorder,
  borderTop: spacerBorderTop,
  borderBottom: spacerBorderBottom,
  borderLeft: spacerBorderLeft,
  borderRight: spacerBorderRight,
}: SpacerType) => {
  const top =
    marginTop ||
    (margin as number[])?.[0] ||
    (Number.isFinite(margin) ? margin : undefined);
  const right =
    marginRight ||
    (margin as number[])?.[1] ||
    (Number.isFinite(margin) ? margin : undefined);
  const bottom =
    marginBottom ||
    ((margin as number[])?.[2] === 0 ? 0 : (margin as number[])?.[2]) ||
    ((margin as number[])?.[0] === 0 ? 0 : (margin as number[])?.[0]) ||
    (Number.isFinite(margin) ? margin : undefined);
  const left =
    marginLeft ||
    ((margin as number[])?.[3] === 0 ? 0 : (margin as number[])?.[3]) ||
    ((margin as number[])?.[1] === 0 ? 0 : (margin as number[])?.[1]) ||
    (Number.isFinite(margin) ? margin : undefined);
  const topInner =
    paddingTop ||
    (padding as number[])?.[0] ||
    (Number.isFinite(padding) ? padding : undefined);
  const rightInner =
    paddingRight ||
    (padding as number[])?.[1] ||
    (Number.isFinite(padding) ? padding : undefined);
  const bottomInner =
    paddingBottom ||
    ((padding as number[])?.[2] === 0 ? 0 : (padding as number[])?.[2]) ||
    ((padding as number[])?.[0] === 0 ? 0 : (padding as number[])?.[0]) ||
    (Number.isFinite(padding) ? padding : undefined);
  const leftInner =
    paddingLeft ||
    ((padding as number[])?.[3] === 0 ? 0 : (padding as number[])?.[3]) ||
    ((padding as number[])?.[1] === 0 ? 0 : (padding as number[])?.[1]) ||
    (Number.isFinite(padding) ? padding : undefined);
  const borderTop =
    spacerBorderTop ||
    (spacerBorder as number[])?.[0] ||
    (Number.isFinite(spacerBorder) ? spacerBorder : undefined);
  const borderRight =
    spacerBorderRight ||
    (spacerBorder as number[])?.[1] ||
    (Number.isFinite(spacerBorder) ? spacerBorder : undefined);
  const borderBottom =
    spacerBorderBottom ||
    ((spacerBorder as number[])?.[2] === 0
      ? 0
      : (spacerBorder as number[])?.[2]) ||
    ((spacerBorder as number[])?.[0] === 0
      ? 0
      : (spacerBorder as number[])?.[0]) ||
    (Number.isFinite(spacerBorder) ? spacerBorder : undefined);
  const borderLeft =
    spacerBorderLeft ||
    ((spacerBorder as number[])?.[3] === 0
      ? 0
      : (spacerBorder as number[])?.[3]) ||
    ((spacerBorder as number[])?.[1] === 0
      ? 0
      : (spacerBorder as number[])?.[1]) ||
    (Number.isFinite(spacerBorder) ? spacerBorder : undefined);

  return classNames(
    top && `spacer--top-${(top as number) < 0 ? 'minus' : ''}${top}`,
    right && `spacer--right-${(right as number) < 0 ? 'minus' : ''}${right}`,
    bottom &&
      `spacer--bottom-${(bottom as number) < 0 ? 'minus' : ''}${bottom}`,
    left && `spacer--left-${(left as number) < 0 ? 'minus' : ''}${left}`,
    topInner && `spacer--padding-top-${topInner}`,
    rightInner && `spacer--padding-right-${rightInner}`,
    bottomInner && `spacer--padding-bottom-${bottomInner}`,
    leftInner && `spacer--padding-left-${leftInner}`,
    borderTop && `spacer--border-top-${borderTop}`,
    borderRight && `spacer--border-right-${borderRight}`,
    borderLeft && `spacer--border-left-${borderLeft}`,
    borderBottom && `spacer--border-bottom-${borderBottom}`,
  );
};

const Spacer = ({
  tag: Tag = 'div',
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  border,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
  children,
  className,
  ...props
}: Props) => {
  const classes = classNames(
    'spacer',
    getSpacerClassNames({
      margin,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      border,
      borderTop,
      borderBottom,
      borderLeft,
      borderRight,
    }),
    className,
  );
  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
};

export default Spacer;
