import React from 'react';
import { useTranslation } from 'react-i18next';
import Headline from '../../../components/Headline/Headline';
import Select from '../../../components/Select/Select';
import {
  defaultFallbackLanguage,
  languageWhiteList,
} from '../../../global-constants';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import {
  changedLanguage,
  selectLanguageCode,
  selectLocaleIso,
} from '../../../redux/slices/i18n/i18nSlice';

const LanguageSelector = () => {
  const { t } = useTranslation();
  const languageCode = useReduxSelector(selectLanguageCode);
  const dispatch = useReduxDispatch();
  const localeIso = useReduxSelector(selectLocaleIso);

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(changedLanguage(event.target.value));
  };

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.language')}
      </Headline>

      <p className="settings__block page__form-desc">
        {t('settings:languageHint')}
      </p>

      <p className="settings__block caption">default-Locale: {localeIso}</p>

      <p className="settings__block caption">
        fallback-language: {defaultFallbackLanguage}
      </p>

      <div className="settings__block">
        <Select
          options={languageWhiteList.map((lang) => ({
            value: lang,
            text: t(`language.${lang}`),
          }))}
          onChange={changeLanguage}
          value={languageCode}
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
