import Button from 'components/Button/Button';
import ChannelSelectField from 'components/ChannelSelect/ChannelSelectField';
import InputField from 'components/Input/InputField';
import { FlexBox, Spacer } from 'components/Layout/Layout';
import { isAvailable } from 'features/availability';
import { Field, Form, Formik, FormikProps } from 'formik';
import { ChannelMinimalResponseDTO } from 'generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import * as Yup from 'yup';
import { useAddSharingToken } from '../queries';

type Values = {
  title: string;
  channel?: string;
};

const ShareAdd = ({
  channels,
  videoId,
}: {
  channels?: ChannelMinimalResponseDTO[];
  videoId: number;
}) => {
  const { t } = useTranslation(['translation', 'video']);
  const [error, setError] = useState();
  const addSharingTokenMutation = useAddSharingToken(videoId);

  const handleAddShareLink = ({ title, channel }: Values) => {
    if (channel) {
      addSharingTokenMutation.mutate({ title, channel: channel });
    }
  };

  const selectFirstActiveChannel = () => {
    const activeChannel = channels?.find((i) => {
      if (i.availability) {
        return isAvailable(i.availability);
      }
      return false;
    });

    const channel = activeChannel || channels?.[0];
    return channel ? channel.id.toString() : undefined;
  };

  return (
    <Formik
      initialValues={{
        title: '',
        channel: selectFirstActiveChannel(),
      }}
      onSubmit={handleAddShareLink}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('form.required')),
        channel: Yup.string().required(t('form.required')),
      })}
    >
      {(formik: FormikProps<Values>) => (
        <Form>
          <Spacer>
            <Field
              component={InputField}
              name="title"
              label={t('video:share.add')}
              placeholder={t('video:share.placeholder')}
            />
          </Spacer>
          <Spacer margin={[4, 0]}>
            <Field
              component={ChannelSelectField}
              name="channel"
              channels={channels}
              noOptionsMessage={t('video:share.empty-channel')}
            />
          </Spacer>
          <FlexBox justifyContent="flex-end">
            <Button
              text={t('add')}
              color={Color.primary}
              appearance="filled"
              type="submit"
              icon="add"
            />
          </FlexBox>

          {error && (
            <div role="alert" className="input__error">
              {error}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ShareAdd;
