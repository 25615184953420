import { TFunction } from 'react-i18next';
import { Tab } from '../../components/Tabs/Tabs';
import { VendorAgentResponseWithVendorDTO } from '../../generated';
import { SettingsTab } from './SettingsTabsContainer';

const getTextByTab = (tab: SettingsTab, t: TFunction<string[]>) => {
  switch (tab) {
    case SettingsTab.profil:
      return t('settings:tabBar.profil');

    case SettingsTab.admin:
      return t('settings:tabBar.admin');

    case SettingsTab.referrals:
      return t('settings:tabBar.referrals');

    case SettingsTab.superAdmin:
      return t('settings:tabBar.superAdmin');

    default:
      return '';
  }
};

const getAvailableTabs = ({
  role,
  t,
}: {
  role?: VendorAgentResponseWithVendorDTO.role;
  t: TFunction<string[]>;
}): Tab<SettingsTab>[] => {
  const getTab = (tab: SettingsTab): Tab<SettingsTab> => ({
    tab,
    text: getTextByTab(tab, t),
  });

  switch (role) {
    case VendorAgentResponseWithVendorDTO.role.SUPER_ADMIN:
      return [
        getTab(SettingsTab.profil),
        getTab(SettingsTab.admin),
        getTab(SettingsTab.referrals),
        getTab(SettingsTab.superAdmin),
      ];
    case VendorAgentResponseWithVendorDTO.role.ADMIN:
    case VendorAgentResponseWithVendorDTO.role.SALES_ADMIN:
      return [
        getTab(SettingsTab.profil),
        getTab(SettingsTab.admin),
        getTab(SettingsTab.referrals),
      ];
    case VendorAgentResponseWithVendorDTO.role.REPORTER:
      return [getTab(SettingsTab.profil)];
    case VendorAgentResponseWithVendorDTO.role.SALES_AGENT:
      return [getTab(SettingsTab.profil), getTab(SettingsTab.referrals)];
    default:
      console.log('default case');

      return [];
  }
};

export default getAvailableTabs;
