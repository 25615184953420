import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { videoFilterKeys } from 'features/video-filter/queries';
import { videoListKeys } from 'features/video-list';
import {
  ApiError,
  BatchLinkHashtagsResponseDto,
  HashtagEntityDTO,
  HashtagResponseDTO,
  HashtagsService,
  PaginatedHashtagDTO,
  ProductsService,
  VideosService,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import QUERY_KEYS from 'types/QueryKeys';

export const hashtagQueryKeys = {
  getHashtags: (searchTerm?: string | void) => [
    QUERY_KEYS.getHashtags,
    searchTerm,
  ],
  getHashtagsForEntity: (entityId: number, vendorAgentId?: number) => [
    QUERY_KEYS.getHashtagsForEntity,
    entityId,
    vendorAgentId,
  ],
};

export const useGetHashtags = (searchTerm?: string) => {
  return useInfiniteQuery<PaginatedHashtagDTO, ApiError>({
    queryKey: hashtagQueryKeys.getHashtags(searchTerm),
    queryFn: async ({ pageParam = 0 }) =>
      HashtagsService.getHashtagsPaginated(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};

export const useAddHashtag = () => {
  return useMutation<HashtagResponseDTO, ApiError, { name: string }>({
    mutationKey: [QUERY_KEYS.addHashtag],
    mutationFn: ({ name }) =>
      HashtagsService.addHashtag({
        name: name.toLowerCase(),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(hashtagQueryKeys.getHashtags());
      queryClient.invalidateQueries(videoFilterKeys.getHashtagFilter());
    },
  });
};

export const useUpdateHashtagsOfVideo = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<
    HashtagResponseDTO[],
    ApiError,
    {
      hashtagIds: number[];
      videoId: number;
    }
  >({
    mutationFn: ({ hashtagIds, videoId }) =>
      VideosService.updateVideoHashtags(videoId, { hashtagIds }),
    onSuccess: (data, { videoId }) => {
      queryClient.setQueryData(
        hashtagQueryKeys.getHashtagsForEntity(
          videoId,
          vendorAgent?.currentVendor.id,
        ),
        () => data,
      );
      queryClient.invalidateQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      );
      queryClient.invalidateQueries(videoFilterKeys.getHashtagFilter());
    },
  });
};

export const useUpdateHashtagsOfProduct = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<
    HashtagResponseDTO[],
    ApiError,
    {
      hashtagIds: number[];
      productId: number;
    }
  >({
    mutationFn: ({ hashtagIds, productId }) =>
      ProductsService.updateProductHashtags(productId, { hashtagIds }),
    onSuccess: (data, { productId }) => {
      queryClient.setQueryData(
        hashtagQueryKeys.getHashtagsForEntity(
          productId,
          vendorAgent?.currentVendor.id,
        ),
        () => data,
      );
      queryClient.invalidateQueries(videoFilterKeys.getHashtagFilter());
      // TODO invalidate Product query
    },
  });
};

export const useLinkHashtagsToProducts = () => {
  return useMutation<
    BatchLinkHashtagsResponseDto,
    ApiError,
    { hashtagIds: number[]; productIds: number[] }
  >({
    mutationKey: [QUERY_KEYS.linkHashtagsToProducts],
    mutationFn: ({ hashtagIds, productIds }) =>
      HashtagsService.batchLinkHashtagsToEntities(
        HashtagEntityDTO.entityType.PRODUCT,
        {
          hashtagIds,
          entityIds: productIds,
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(videoFilterKeys.getHashtagFilter());
    },
  });
};

export const useGetHashtagsForEntity = (
  entityId: number,
  entityType: HashtagEntityDTO.entityType,
) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useQuery({
    queryKey: hashtagQueryKeys.getHashtagsForEntity(
      entityId,
      vendorAgent?.currentVendor.id,
    ),
    queryFn: () => HashtagsService.getHashtagsForEntity(entityType, entityId),
  });
};
