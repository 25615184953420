import Steps from 'components/Steps/Steps';
import { ApiError, ChannelMinimalResponseDTO } from 'generated';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../video-type.scss';
import {
  TypeDefault,
  TypeIntroError,
  TypeIntroSuccess,
  TypeProductSuccess,
  TypeSetChannels,
  TypeSetProduct,
} from './components';
import TypeDefaultError from './components/TypeDefaultError';

export enum StepKeys {
  DEFAULT = 'default',
  PRODUCT_SUCCESS = 'productSuccess',
  SET_PRODUCT = 'setProduct',
  INTRO_SUCCESS = 'introSuccess',
  SET_CHANNELS = 'setChannels',
  INTRO_ERROR = 'introError',
  DEFAULT_ERROR = 'defaultError',
}

export type StepErrors = {
  [K in StepKeys]?: ApiError;
};
export type StepChannelTransfers = {
  [K in StepKeys]?: ChannelMinimalResponseDTO[];
};

export type StepChildProps = {
  setStep: Dispatch<SetStateAction<StepKeys>>;
  videoId: number;
  setErrors: Dispatch<SetStateAction<StepErrors>>;
  setChannelTransfer: Dispatch<SetStateAction<StepChannelTransfers>>;
  errors: StepErrors;
  channelTransfer: StepChannelTransfers;
};

// t('video:type.steps.productSuccess')
// t('video:type.steps.setProduct')
// t('video:type.steps.introSuccess')
// t('video:type.steps.introError')
// t('video:type.steps.setChannels')
// t('video:type.steps.defaultError')

const VideoType = ({ videoId }: { videoId: number }) => {
  const [step, setStep] = useState<StepKeys>(StepKeys.DEFAULT);
  const [errors, setErrors] = useState<StepErrors>({});
  const [channelTransfer, setChannelTransfer] = useState<StepChannelTransfers>(
    {},
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const props = {
    videoId,
    setStep,
    setErrors,
    errors,
    channelTransfer,
    setChannelTransfer,
  };

  const nav = {
    [StepKeys.DEFAULT]: {
      comp: <TypeDefault {...props} />,
    },
    [StepKeys.PRODUCT_SUCCESS]: {
      comp: <TypeProductSuccess {...props} />,
    },
    [StepKeys.SET_PRODUCT]: {
      comp: <TypeSetProduct {...props} />,
    },
    [StepKeys.INTRO_SUCCESS]: {
      comp: <TypeIntroSuccess {...props} />,
    },
    [StepKeys.INTRO_ERROR]: {
      comp: <TypeIntroError {...props} />,
    },
    [StepKeys.SET_CHANNELS]: {
      comp: <TypeSetChannels {...props} />,
    },
    [StepKeys.DEFAULT_ERROR]: {
      comp: <TypeDefaultError {...props} />,
    },
  };

  useEffect(() => {
    setSearchParams({});
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    if (step) {
      params.append('typeStep', step);
    } else {
      params.delete('typeStep');
    }
    setSearchParams(params);
  }, [step]);

  return (
    <Steps stepKey={step} className="video-detail__child video-type">
      {nav[step].comp}
    </Steps>
  );
};

export default VideoType;
