import { useField, useFormikContext } from 'formik';
import React from 'react';
import DatePicker, { DatePickerProps } from './DatePicker';

const DatePickerField = ({
  name,
  ...props
}: Omit<DatePickerProps, 'value' | 'onChange'> & {
  onChange?: (name: string, value?: Date) => void;
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, { error }] = useField<string>(name);
  const fieldProps = field;

  const handleChange = (name: string, utcDate?: Date | null) => {
    if (utcDate) {
      setFieldValue(field.name, utcDate);
      props?.onChange?.(field.name, utcDate);
    } else {
      setFieldValue(field.name, undefined);
      props?.onChange?.(field.name, undefined);
    }
  };

  return (
    <DatePicker
      {...props}
      {...fieldProps}
      onChange={handleChange}
      error={error}
    />
  );
};

export default DatePickerField;
