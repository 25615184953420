import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Chip from 'components/Chip/Chip';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Footer from 'components/Footer/Footer';
import Headline from 'components/Headline/Headline';
import Icon from 'components/Icon/Icon';
import Layout, {
  FlexBox,
  FlexItem,
  Section,
  Sidebar,
  Spacer,
} from 'components/Layout';
import Modal from 'components/Modal/Modal';
import { FilterProvider } from 'features/filter';
import { OrderFilterBar, OrderFilterSideBar } from 'features/order-filter';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useSidebar from 'hooks/useSidebar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useReduxSelector } from 'redux/hooks';
import { selectWorldzone } from 'redux/slices/i18n/i18nSlice';
import OrderData from './OrderData';

/** Route /orders/ */
const OrderList = () => {
  const { t } = useTranslation(['translation', 'orders']);
  const sidebarProps = useSidebar();
  const { openSidebar } = sidebarProps;
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const isFilterRoute = useMatch('/orders/filter');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const worldzone = useReduxSelector(selectWorldzone);

  useEffect(() => {
    if (isFilterRoute) {
      openSidebar();
    }
  }, [isFilterRoute]);

  const handleCloseOrder = () => {
    setIsOpen(false);
    navigate('/orders');
  };

  useEffect(() => {
    if (params.id && !isFilterRoute) {
      setIsOpen(true);
    }
  }, [params, isFilterRoute]);

  const BetaInfo = (
    <>
      <p>
        <Spacer tag="span" marginRight={1}>
          <Icon icon="info-filled" className="c-pink" />
        </Spacer>
        {t('orders:betaDescription')}
      </p>
      <Spacer marginTop={2}>
        {worldzone !== 'eu' && (
          <>
            {t('orders:beta.contact.call')}:{' '}
            <a
              className="order-item__link"
              href={`tel:${t('orders:beta.contact.number.plain')}`}
            >
              {t('orders:beta.contact.number.format')}
            </a>
            <br />
          </>
        )}
        {t('orders:beta.contact.write')}:{' '}
        <a
          className="order-item__link"
          href={`mailto:${t('orders:beta.contact.mail')}`}
        >
          {t('orders:beta.contact.mail')}
        </a>
      </Spacer>
    </>
  );

  return (
    <Layout>
      <Section appearance="medium" breakLeft>
        <FlexBox
          justifyContent="space-between"
          flexDirection={isDesktop ? 'row' : 'column'}
        >
          <Breadcrumbs />
        </FlexBox>
      </Section>

      <Section appearance="medium" style={{ flexGrow: 1 }}>
        <FlexBox marginBottom={7} alignItems="center">
          <Headline headingLevel="h1" size={2} className="page__title">
            {t('ordersTitle')}
          </Headline>
          <FlexItem marginLeft={2} display="flex" alignItems="center">
            <Chip>{t('beta')}</Chip>
          </FlexItem>
        </FlexBox>

        <Spacer margin={[0, 0, 8]} className="small">
          {BetaInfo}
        </Spacer>

        {isDesktop && (
          <Spacer marginBottom={14}>
            <OrderFilterBar />
          </Spacer>
        )}

        <Spacer margin={isDesktop ? 0 : [0, -2]}>
          <ErrorBoundary>
            <OrderData />
          </ErrorBoundary>
        </Spacer>
      </Section>

      <Sidebar {...sidebarProps} appearance="narrow" closeIconIsVisible={false}>
        <ErrorBoundary>
          {/* route /orders/filter */}
          <OrderFilterSideBar />
        </ErrorBoundary>
      </Sidebar>

      <Spacer marginTop={8}>
        <Footer />
      </Spacer>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseOrder}
        variant="full"
        hideHeader
        className="order-detail__modal"
      >
        {/* route /orders/:id */}
        <Outlet context={{ onClose: handleCloseOrder }} />
      </Modal>
    </Layout>
  );
};

const OrderListWithFilterProvider = () => {
  return (
    <FilterProvider>
      <OrderList />
    </FilterProvider>
  );
};
export default OrderListWithFilterProvider;
