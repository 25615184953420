import SelectInteractive, {
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive';
import { AppOrderStatusDto } from 'generated';
import { useTranslation } from 'react-i18next';
import OrderStatusActionOption, {
  OrderStatusActionOptionType,
} from './components/OrderStatusActionOption';
import { orderActionPeriods } from 'utils/order-action';

export type Props<IsMulti extends boolean = false> = Omit<
  SelectInteractiveProps<OrderStatusActionOptionType, IsMulti>,
  'options'
> & {
  options?: AppOrderStatusDto.action[];
};

const OrderStatusActionSelect = <isMulti extends boolean = false>({
  options,
  ...props
}: Props<isMulti>) => {
  const { t } = useTranslation(['translation', 'orders']);

  const actionWithAlternativeNameInFilter = [
    // t('orders:status.filter.paidBasketItems')
    AppOrderStatusDto.action.PAID_BASKET_ITEMS,
  ];

  const mappedOptions = (options || Object.values(AppOrderStatusDto.action))
    .map((i) => {
      let label = t(`orders:status.${i}`);
      if (actionWithAlternativeNameInFilter.includes(i)) {
        label = t(`orders:status.filter.${i}`);
      }

      return {
        value: i,
        label,
      };
    })
    .sort((a, b) => {
      const indexA = Object.values(orderActionPeriods).findIndex((i) =>
        i.includes(a.value),
      );
      const indexB = Object.values(orderActionPeriods).findIndex((i) =>
        i.includes(b.value),
      );

      const isAInPeriod = indexA !== -1;
      const isBInPeriod = indexB !== -1;

      const fallBackIndexA = isAInPeriod ? indexA : 1;
      const fallBackIndexB = isBInPeriod ? indexB : 1;

      return fallBackIndexB - fallBackIndexA;
    });

  return (
    <SelectInteractive
      {...props}
      options={mappedOptions}
      components={{
        Option: OrderStatusActionOption,
      }}
      noOptionsMessage={
        props.noOptionsMessage || t('orders:statusSelect.empty')
      }
      title={t('orders:statusSelect.title')}
      label={t('orders:statusSelect.title')}
      placeholder={t('orders:statusSelect.placeholder')}
    />
  );
};

export default OrderStatusActionSelect;
