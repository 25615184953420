import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import Icon from 'components/Icon/Icon';
import './action-panel.scss';

export interface Props {
  onClose: () => void;
  open?: boolean;
  children?: ReactNode | Array<ReactNode>;
  fixed?: boolean;
}

const ActionPanel = ({
  onClose,
  open = true,
  children,
  fixed = true,
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const targetRef = useRef<HTMLDivElement>(null);

  const handleClose = () => onClose();

  return (
    <>
      <div
        ref={targetRef}
        className={classNames(
          'action-panel',
          open && 'action-panel--open',
          // fixed && 'action-panel--fixed',
        )}
      >
        <div className="action-panel__content">
          {!isDesktop && open && (
            <button
              className="action-panel__close"
              type="button"
              onClick={handleClose}
            >
              <Icon icon="close" />
            </button>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default ActionPanel;
