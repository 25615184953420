/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VideoItemDTO } from './VideoItemDTO';

export type IntroVideoUpdateDTO = {
    generateNewKey?: boolean;
    type: IntroVideoUpdateDTO.type;
    language: string;
    name: string;
    threeQFileId?: number;
    meta?: string;
    videoOffset?: number;
    videoDuration?: number;
    orientation?: IntroVideoUpdateDTO.orientation;
    videoItems?: Array<VideoItemDTO>;
    visibility?: IntroVideoUpdateDTO.visibility;
    hashtagIds?: Array<number>;
};

export namespace IntroVideoUpdateDTO {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }

    export enum orientation {
        PORTRAIT = 'portrait',
        LANDSCAPE = 'landscape',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

