import { FieldProps, getIn } from 'formik';
import { FC } from 'react';
import { onSubmitDontBlur } from '../../utils/on-submit-dont-blur';
import Input, { Props } from './Input';

const TextAreaField: FC<Props & FieldProps> = ({
  field,
  form: { errors, touched },
  ...props
}) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);

  return (
    <Input
      {...field}
      {...props}
      type="textarea"
      error={tou && err ? err : undefined}
      onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
    />
  );
};

export default TextAreaField;
