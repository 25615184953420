/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalPaymentApiIntentDto = {
    paymentProvider: InternalPaymentApiIntentDto.paymentProvider;
    /**
     * This will only be set in context of Stripe payments
     */
    clientSecret?: string;
    /**
     * This will only be set in context of Stripe payments
     */
    ephemeralKey?: string;
    transactionId: string;
    customerId: string;
};

export namespace InternalPaymentApiIntentDto {

    export enum paymentProvider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }


}

