import classNames from 'classnames';
import { DefaultOption } from 'components/SelectInteractive';
import { ControlProps, GroupBase, components } from 'react-select';
import './language-control.scss';

export const LanguageControl = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>({
  children,
  ...props
}: ControlProps<OptionType, IsMulti, Group>) => {
  return (
    <components.Control
      {...props}
      className={classNames(
        'button',
        'button--filled',
        'button--secondary',
        'button--small',
        'language-control',
      )}
    >
      {children}
    </components.Control>
  );
};

export default LanguageControl;
