import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import Headline from 'components/Headline/Headline';
import HowTo from 'components/HowTo/HowTo';
import Icon from 'components/Icon/Icon';
import Layout, {
  FlexBox,
  FlexItem,
  LayoutGrid,
  LayoutGridItem,
  Section,
  Spacer,
} from 'components/Layout/Layout';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import Loader from 'components/Loader/Loader';
import { isAvailable } from 'features/availability';
import { ChannelWithVideosDTO } from 'generated';
import useSidebar from 'hooks/useSidebar';
import placeholderImage from 'images/placeholder-channel.svg';
import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import ChannelInfo from '../ChannelInfo/ChannelInfo';
import { useGetChannels } from '../queries';

export interface Props {
  className?: string;
}

const ChannelListContainer = ({ className }: Props) => {
  const { t } = useTranslation(['translation', 'channel']);
  const sidebarProps = useSidebar();
  const { status, data, error, isFetching, fetchNextPage, hasNextPage } =
    useGetChannels();
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const [activeItem, setActiveItem] = useState<
    ChannelWithVideosDTO | undefined
  >(data?.pages?.[0].data?.[0]);

  const handleOpenDetails = (item: ChannelWithVideosDTO) => {
    setActiveItem(item);
    sidebarProps.openSidebar();
  };

  useEffect(() => {
    if (status === 'success') {
      setActiveItem(data?.pages?.[0].data?.[0]);
    }
  }, [status, vendorAgent?.currentVendor]);

  useEffect(() => {
    setActiveItem(
      data?.pages
        .map((p) => p.data)
        .flat()
        .find((i) => i.id === activeItem?.id) || data?.pages?.[0].data?.[0],
    );
  }, [data]);

  const hasNoActiveChannels =
    data?.pages
      .map((page) => page.data)
      .flat()
      .filter((i) => isAvailable(i.availability)).length === 0;

  if (status === 'loading') {
    return <Loader />;
  }

  if (status === 'error') {
    return (
      <div>
        ERROR: {error?.message}, {error?.status}, {error?.statusText}
      </div>
    );
  }

  if (
    status === 'success' &&
    data?.pages.map((i) => i.data).flat().length === 0
  ) {
    return (
      <Layout>
        <Section appearance="breaker" className="flex-box__item--grow">
          <HowTo button={{ link: '/channels/new' }} text={t('channel:empty')} />
        </Section>
        <Spacer marginTop={8}>
          <Footer />
        </Spacer>
      </Layout>
    );
  }

  return (
    <LayoutGrid>
      <LayoutGridItem templateArea="header">
        <Breadcrumbs />

        <Headline headingLevel="h1" size={2} className="page__title">
          {t('channel:title')}
        </Headline>

        {hasNoActiveChannels && (
          <Spacer tag="p" margin={[3, 0]}>
            <Spacer tag="span" marginRight={1}>
              <Icon icon="info-filled" className="c-pink" />
            </Spacer>
            {t('channel:avWarning')}
          </Spacer>
        )}
      </LayoutGridItem>
      <LayoutGridItem templateArea="main">
        <FlexBox>
          <FlexItem pushRight>
            <Button link="/channels/new" text={t('add')} icon="add" />
          </FlexItem>
        </FlexBox>
        <Spacer marginTop={10}>
          <List
            count={{
              itemsVisible: data?.pages.map((page) => page.data).flat().length,
              total: data.pages[data.pages.length - 1].total,
            }}
            handleLoadMore={() => fetchNextPage()}
            loading={isFetching}
            error={error || undefined}
            showLoadMore={hasNextPage}
            className={className}
            type="channel"
          >
            {data?.pages.map((page, i) => (
              <React.Fragment key={(page.links?.next?.offset || i) as Key}>
                {page.data.map((channel, index) => (
                  <ListItem
                    active={channel === activeItem}
                    key={index}
                    title={channel.name}
                    image={channel.iconUrl || placeholderImage}
                    onClick={() => handleOpenDetails(channel)}
                    deactivated={
                      channel.availability
                        ? !isAvailable(channel.availability)
                        : true
                    }
                  />
                ))}
              </React.Fragment>
            ))}
          </List>
        </Spacer>
      </LayoutGridItem>
      <LayoutGridItem templateArea="sidebar" sidebarProps={sidebarProps}>
        {activeItem && <ChannelInfo activeItem={activeItem} />}
      </LayoutGridItem>
      <LayoutGridItem templateArea="footer">
        <Footer />
      </LayoutGridItem>
    </LayoutGrid>
  );
};

export default ChannelListContainer;
