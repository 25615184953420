import Button from 'components/Button/Button';
import Mediathek from 'components/Mediathek/Mediathek';
import MediathekFooter from 'components/Mediathek/MediathekFooter';
import MediathekHeader from 'components/Mediathek/MediathekHeader';
import MediathekItem from 'components/MediathekItem/MediathekItem';
import Modal from 'components/Modal/Modal';
import Search from 'components/Search/Search';
import {
  ApiError,
  ChannelsService,
  VideoResponseDTO,
  VideosService,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import useList from 'hooks/useList';
import { useListSelection } from 'hooks/useListSelection';
import { useEffect, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import videoListReducer from 'reducer/reducer-video-list';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut } from 'redux/slices/auth/authSlice';
import {
  selectLocaleIso,
  selectSupportedLanguages,
} from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import useDebouncedEffect from 'use-debounced-effect';
import notAuthenticated from 'utils/not-authenticated';

export interface Props {
  open: boolean;
  onClose: () => void;
  channelId?: number;
}

const MediathekContainer = ({ open, onClose, channelId }: Props) => {
  const { t } = useTranslation(['translation', 'video', 'channel']);
  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
    options: { disableSetActiveItem: true },
  });
  const [preselectedVideos, setPreselectedVideos] = useState<number[]>();
  const { selectedIds, setSelectedItems, toggleSelectItem } =
    useListSelection(preselectedVideos);
  const [searchTerm, setSearchTerm] = useState('');
  const [channelName, setChannelName] = useState('');
  const [itemChangedCollapseState, setItemChangedCollapseState] =
    useState(true);
  const [count, setCount] = useState(
    selectedIds.filter((i) => !preselectedVideos?.includes(i)).length || 0,
  );
  const localeIso = useReduxSelector(selectLocaleIso);
  const dispatch = useReduxDispatch();

  const { setAppStatus } = useAppStatus();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  const { get, setActiveItem } = listFunctions;

  const getVideos = () => {
    get({
      service: VideosService.getVideos,
      props: {
        initial: true,
        onSuccess: async (response) => {
          if (channelId) {
            try {
              const res = await ChannelsService.getChannelVideoIds(channelId);
              if (res) {
                setPreselectedVideos(res.videoIds);
              }
            } catch (error) {
              setPreselectedVideos([]);
            }
          }
        },
      },
    });
  };

  useEffect(() => {
    if (channelId) {
      const getChannel = async () => {
        try {
          const res = await ChannelsService.getChannel(channelId);

          setChannelName(res.name);
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            setChannelName(t('no_title'));
          }
        }
      };
      getChannel();
    }
  }, [channelId]);

  useEffect(() => {
    setCount(selectedIds.filter((i) => !preselectedVideos?.includes(i)).length);
  }, [selectedIds, preselectedVideos]);

  useDebouncedEffect(
    () => {
      try {
        listFunctions.get({
          props: {
            initial: true,
          },
          service: () =>
            VideosService.getVideos(
              undefined, // offset
              undefined, // limit
              searchTerm,
              localeIso,
            ),
        });
      } catch (error) {
        console.log(error);
      }
    },
    400,
    [searchTerm],
  );

  const handleLoadMore = () => {
    get({
      service: () =>
        VideosService.getVideos(
          listState.items?.length, // offset
          undefined, // limit
          searchTerm,
          localeIso,
        ),
    });
  };

  const handleSelectChange = (id: number) => {
    if (!preselectedVideos?.includes(id)) {
      toggleSelectItem(id);
    }
  };

  const handleSelectAll = () => {
    setSelectedItems(listState.items?.map((i) => i.id) || []);
  };

  const handleSave = () => {
    if (channelId) {
      const save = async () => {
        try {
          const res = await ChannelsService.addVideos(channelId, {
            videoIds: selectedIds.filter(
              (i) => !preselectedVideos?.includes(i),
            ),
          });

          if (res) {
            onClose();
            setAppStatus(t('channel:videoAdd.success', { count }), 'success');
          }
        } catch (error) {
          console.log(error); //TODO
        }
      };
      save();
    }
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleResetSelection = () => {
    setSelectedItems([]);
  };

  const handleItemCollapsed = () => {
    setItemChangedCollapseState(!itemChangedCollapseState);
  };

  const handleSetActivePlayer = (
    isActive: boolean,
    item?: VideoResponseDTO,
  ) => {
    setActiveItem(isActive ? item : undefined);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleModalClose}
      variant="full"
      scrollTargetId="infiniteScrollTarget"
      onAfterOpen={getVideos}
      headline={t('channel:mediathek.headline')}
    >
      <MediathekHeader
        title={t('channel:mediathek.title')}
        subtitle={channelName}
      >
        <Search
          searchTerm={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          placeholder={t('video:searchPlaceholder')}
        />

        <Button
          text={t('list:selectAll')}
          onClick={handleSelectAll}
          color={Color.primary}
          appearance="ghost"
        />
      </MediathekHeader>

      <Flipper
        flipKey={`flip--${
          listState.items?.length
        }--${itemChangedCollapseState.toString()}-${searchTerm}
        )}`}
        spring="stiff"
        decisionData={itemChangedCollapseState}
      >
        <Mediathek
          count={{
            itemsVisible: listState.items?.length,
            total: listState.totalCount,
          }}
          loading={listState.loading}
          error={listState.error}
          onSelectAll={handleSelectAll}
          hasMore={listState.loadMore}
          onLoadMore={handleLoadMore}
          onResetSelection={handleResetSelection}
          showHowTo={listState.items?.length === 0}
        >
          {listState.items &&
            listState.items.map((item, index) => (
              <MediathekItem
                key={item.id}
                deactivated={item.product && !item.product.isAvailable}
                selectable={{
                  selected:
                    selectedIds?.includes(item.id) ||
                    preselectedVideos?.includes(item.id) ||
                    false,
                  onSelectChange: () => handleSelectChange(item.id),
                  disabled: preselectedVideos?.includes(item.id) || false,
                }}
                item={item}
                onCollapseChange={handleItemCollapsed}
                activePlayer={activeItem?.id === item.id}
                setActivePlayer={handleSetActivePlayer}
                languageNotSupportedError={
                  !supportedLanguages.some((i) => i.iso === item.language)
                }
              />
            ))}
        </Mediathek>
        <MediathekFooter>
          <Button
            text={t('cancel')}
            appearance="ghost"
            onClick={handleModalClose}
          />
          <Button
            text={t('channel:addWithCount', {
              count,
            })}
            onClick={handleSave}
            disabled={count === 0}
          />
        </MediathekFooter>
      </Flipper>
    </Modal>
  );
};

export default MediathekContainer;
