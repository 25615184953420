import FilterBar from 'components/FilterBar/FilterBar';
import { useFilter } from 'features/filter';
import { useNavigate } from 'react-router-dom';
import { OrderFilterItem, OrderFilterKeys } from '../types';
import { getFilterItemsFromFilter } from '../utils';

const OrderFilterBar = () => {
  const { filter, resetFilter, removeFilterById } = useFilter<
    OrderFilterKeys,
    OrderFilterItem
  >();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/orders/filter');
  };

  const onRemoveItem = (id: string) => {
    removeFilterById(id);
  };

  return (
    <FilterBar
      items={getFilterItemsFromFilter(filter)}
      onReset={resetFilter}
      onRemoveItem={onRemoveItem}
      onClick={onClick}
    />
  );
};

export default OrderFilterBar;
