import { VideoResponseDTO } from 'generated';
import { useOutletContext } from 'react-router-dom';

export type ActiveVideo = {
  video: VideoResponseDTO;
  visited: boolean;
  visitedParent: () => void;
};

export function useVideoOutletContext() {
  return useOutletContext<ActiveVideo>();
}
