import classNames from 'classnames';
import React, { ElementType } from 'react';
import './section.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  appearance?: 'breaker' | 'full' | 'wide' | 'medium';
  breakLeft?: boolean;
  breakRight?: boolean;
}

const Section = ({
  children,
  tag: Tag = 'section',
  appearance = 'full',
  breakLeft,
  breakRight,
  className,
  ...props
}: Props) => {
  return (
    <Tag
      {...props}
      className={classNames(
        'section',
        appearance && `section--${appearance}`,
        breakLeft && 'section--break-left',
        breakRight && 'section--break-right',
        className,
      )}
    >
      <div className="section__inner">{children}</div>
    </Tag>
  );
};

export default Section;
