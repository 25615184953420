import Address from 'components/Address/Address';
import Headline from 'components/Headline/Headline';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import OrderShippingStatus from 'components/OrderShippingStatus/OrderShippingStatus';
import { AppOrderStatusDto, VendorAddressDto } from 'generated';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPeriodKeyByOrderAction } from 'utils/order-action';
import ShippingDefaultAdditionalInfo from './ShippingDefaultAdditionalInfo';
import ShippingDownloads from './ShippingDownloads';

export interface Props {
  productId: number;
  pickupAddress?: VendorAddressDto;
  orderActionStatus: AppOrderStatusDto.action;
  orderNumber: string;
  requestedPickupDate?: string | null;
}

const ShippingDefault = ({
  productId,
  pickupAddress,
  orderActionStatus,
  orderNumber,
  requestedPickupDate,
}: Props) => {
  const { t } = useTranslation(['translation', 'orders']);
  const [collapsed, setCollapsed] = useState(true);

  const [renderCancelHint, setRenderCancelHint] = useState(false);

  useEffect(() => {
    const cancelActions = [
      AppOrderStatusDto.action.CANCEL_ORDER_BEFORE_SHIPPING,
      AppOrderStatusDto.action.CANCEL_SHIPMENT,
    ];
    if (cancelActions.includes(orderActionStatus)) {
      setRenderCancelHint(true);
    }
  }, [orderActionStatus]);

  return (
    <>
      <FlexBox justifyContent="space-between" flexWrap="wrap-reverse">
        <Headline
          headingLevel="h2"
          size={2}
          className="order-detail__box-headline"
        >
          {t('orders:detail.shipping.title')}
        </Headline>
        {getPeriodKeyByOrderAction(orderActionStatus) !== 'end' && (
          <OrderShippingStatus status={orderActionStatus} />
        )}
      </FlexBox>
      <Spacer marginTop={5}>
        {renderCancelHint && (
          <Spacer
            className="shipping__hint shipping__hint--cancel"
            padding={2}
            marginBottom={10}
          >
            <LabelText
              label={t(
                `orders:detail.shipping.cancel.${orderActionStatus}.title`,
              )}
            >
              {t(
                `orders:detail.shipping.cancel.${orderActionStatus}.description`,
              )}
            </LabelText>
          </Spacer>
        )}
        {!renderCancelHint && (
          <>
            <FlexBox
              justifyContent="space-between"
              flexDirection={pickupAddress ? 'row' : 'column'}
            >
              {
                <FlexItem flexGrow={1} className="shipping__address">
                  <LabelText
                    label={t(
                      'orders:detail.shipping.createShipment.start.address',
                    )}
                  >
                    {pickupAddress ? (
                      <Address {...pickupAddress} />
                    ) : (
                      <span className="shipping__notfound">
                        {t('orders:detail.shipping.addressNotFound')}
                      </span>
                    )}
                  </LabelText>
                </FlexItem>
              }
            </FlexBox>

            <Spacer marginTop={6} marginBottom={10}>
              <ShippingDownloads orderNumber={orderNumber} />
            </Spacer>

            <ShippingDefaultAdditionalInfo
              productId={productId}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              requestedPickupDate={requestedPickupDate}
            />
          </>
        )}
      </Spacer>
    </>
  );
};

export default ShippingDefault;

// t('orders:detail.shipping.cancel.cancelOrderBeforeShipping.title')
// t('orders:detail.shipping.cancel.cancelOrderBeforeShipping.description')
// t('orders:detail.shipping.cancel.cancelShipment.title')
// t('orders:detail.shipping.cancel.cancelShipment.description')
