import { useState } from 'react';

const useOpen = (initial: boolean) => {
  const [isOpen, setOpen] = useState<boolean>(initial);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const toggle = () => {
    setOpen(!isOpen);
  };

  return {
    isOpen,
    open,
    close,
    toggle,
  };
};

export default useOpen;
