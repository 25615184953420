import { ProductLocalisationDTO } from '../generated';

const getProductTitleByLanguage = (
  localisations: ProductLocalisationDTO[],
  videoLang: string,
) => {
  const showLoc = localisations.find((l) => {
    const locale = new Intl.Locale(l.locale);
    return locale.language === videoLang;
  });

  return showLoc ? showLoc.name : localisations[0]?.name;
};

export default getProductTitleByLanguage;
