import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import { VideoItemDTO } from '../../generated';
import IconButton, { Props as IconButtonProps } from '../IconButton/IconButton';
import './video-download.scss';

const VideoDownload = ({
  items,
  big = true,
  ...props
}: {
  items: VideoItemDTO[];
  big?: boolean;
} & IconButtonProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('video');
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', bodyClick);

    return () => document.removeEventListener('mousedown', bodyClick);
  }, []);

  const bodyClick = (event: MouseEvent) => {
    const target = event.target as HTMLDivElement;
    if (
      tooltipRef?.current?.contains(target) ||
      target.closest('.video-download__toggle')
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="video-download">
      <Tooltip
        arrow={false}
        isOpen={open}
        useHover={false}
        direction="down"
        className="video-download__tooltip"
        tipContentClassName="video-download__flyout"
        content={
          <div className="video-download__flyout" ref={tooltipRef}>
            <h2 className="caption--primary">{t('video:download.quality')}</h2>
            <ul className="video-download__menu">
              {items
                .filter((f) => f !== undefined)
                .map((i) => (
                  <li className="video-download__item" key={i.url}>
                    <a
                      href={i.url}
                      className="video-download__url"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(`video:download.streamType.${i.streamType}`)}
                    </a>
                  </li>
                ))}
            </ul>
            <p className="video-download__text">{t('video:download.howto')}</p>
          </div>
        }
      >
        <IconButton
          onClick={() => setOpen(!open)}
          icon="download"
          appearance={open ? 'filled' : 'ghost'}
          className="video-download__toggle"
          big={big}
          {...props}
        />
      </Tooltip>
    </div>
  );
};

export default VideoDownload;

// t('video:download.streamType.mp4_low');
// t('video:download.streamType.mp4_medium');
// t('video:download.streamType.mp4_high');
