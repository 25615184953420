import React from 'react';
import { useTranslation } from 'react-i18next';
import Headline from '../../../components/Headline/Headline';
import Modal from '../../../components/Modal/Modal';
import Terms from '../../../components/Terms/Terms';
import useOpen from '../../../hooks/useOpen';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import {
  acceptOrDeclineTerms,
  selectTermsAcceptedDate,
  selectTermsInfo,
} from '../../../redux/slices/terms/termsSlice';

const TermsViewer = () => {
  const { t } = useTranslation(['settings', 'translation']);
  const { open, close, isOpen } = useOpen(false);
  const termsInfo = useReduxSelector(selectTermsInfo);
  const termsAcceptedDate = useReduxSelector(selectTermsAcceptedDate);
  const dispatch = useReduxDispatch();

  const isAccepted = termsAcceptedDate != null;

  const handleAcceptDecline = async (accepted: boolean) => {
    if (termsInfo) {
      await dispatch(acceptOrDeclineTerms({ id: termsInfo.id, accepted }));
      close();
    }
  };

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.terms')}
      </Headline>

      <p className="page__form-desc">
        {t('settings:terms.desc')}{' '}
        <button type="button" onClick={open} className="footer__link">
          {t('settings:headlines.terms')}
        </button>
        .
      </p>

      <Modal
        isOpen={isOpen}
        onClose={close}
        headline={t('settings:headlines.terms')}
        hasHeaderBorder
        variant="wide"
      >
        <Terms
          terms={termsInfo}
          onAcceptDecline={handleAcceptDecline}
          isAccepted={isAccepted}
        />
      </Modal>
    </>
  );
};

export default TermsViewer;
