import { ChangeEvent } from 'react';
import './radio.scss';

const Radio = ({
  label,
  checked,
  onChange,
  name,
}: {
  name: string;
  label: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="radio">
      <label className="radio__label input__label">
        <input
          className="radio__input"
          type="radio"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Radio;
