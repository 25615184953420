import { configureStore } from '@reduxjs/toolkit';
import { listenerMiddleware } from './middlewares/listenerMiddleware';
import authSlice from './slices/auth/authSlice';
import globalSlice from './slices/global/globalSlice';
import i18nSlice from './slices/i18n/i18nSlice';
import notificationReducer from './slices/notifications/notificationSlice';
import termsSlice from './slices/terms/termsSlice';
import utmSlice from './slices/utm/utmSlice';
import vendorsReducer from './slices/vendors/vendorsSlice';
import kvstoreReducer from './slices/kvstore/kvstoreSlice';
import { videoUploadReducer } from 'features/video-upload';

export const store = configureStore({
  reducer: {
    notifications: notificationReducer,
    vendors: vendorsReducer,
    auth: authSlice,
    i18n: i18nSlice,
    terms: termsSlice,
    utm: utmSlice,
    global: globalSlice,
    videoUpload: videoUploadReducer,
    kvstore: kvstoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'videoUpload/requestUpload',
          'videoUpload/uploadVideo',
          'videoUpload/uploadVideo/fulfilled',
        ],
        ignoredPaths: ['videoUpload'],
      },
    }).prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
