import { useMutation, useQuery } from '@tanstack/react-query';
import { productListKeys } from 'features/product-list';
import { videoListKeys } from 'features/video-list/queries';
import { ApiError, ProductResponseDTO, ProductsService } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import QUERY_KEYS from 'types/QueryKeys';

export const productInfoKeys = {
  getProduct: (id: number, vendorAgentId?: number) => [
    ...productListKeys.getProducts(vendorAgentId),
    id,
  ],
};

export const useGetProduct = (id: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useQuery({
    queryKey: productInfoKeys.getProduct(id, vendorAgent?.currentVendor.id),
    queryFn: () => ProductsService.getProductById(id),
    // TODO initial Data from product list
  });
};

export const useUpdateProductAvailability = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'product']);

  return useMutation<
    ProductResponseDTO,
    ApiError,
    { productId: number; active: boolean }
  >({
    mutationKey: [
      QUERY_KEYS.updateProductAvailability,
      vendorAgent?.currentVendor.id,
    ],
    mutationFn: ({ productId, active }) =>
      ProductsService.updateProduct(productId, { isAvailable: active }),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        productInfoKeys.getProduct(
          variables.productId,
          vendorAgent?.currentVendor.id,
        ),
        (old?: ProductResponseDTO) => ({ ...old, ...data }),
      );
      queryClient.invalidateQueries({
        queryKey: videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      });
    },
    onError: () => setAppStatus(t('product:saveMsg.error'), 'error'),
  });
};
