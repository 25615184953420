import StatusIcon from 'components/StatusIcon/StatusIcon';
import { VideoUploadFeature } from 'features/video-upload/types';
import { ColorUse } from 'types/Color';
import { IconType } from 'components/Icon/Icon';

export type Props = {
  status: VideoUploadFeature.Status;
};

const VideoUploadStatusIcon = ({ status }: Props) => {
  const iconMap: Map<
    VideoUploadFeature.Status,
    { icon: IconType; color: ColorUse }
  > = new Map();
  iconMap.set(VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR, {
    icon: 'error-plain',
    color: ColorUse['warning-50'],
  });
  iconMap.set(VideoUploadFeature.Status.CREATE_ERROR, {
    icon: 'error-plain',
    color: ColorUse['warning-50'],
  });
  iconMap.set(VideoUploadFeature.Status.UPLOAD_ERROR, {
    icon: 'error-plain',
    color: ColorUse['warning-50'],
  });
  iconMap.set(VideoUploadFeature.Status.IDLE, {
    icon: 'progressing',
    color: ColorUse['dark-20'],
  });
  iconMap.set(VideoUploadFeature.Status.GET_UPLOAD_URL, {
    icon: 'upload',
    color: ColorUse['dark-20'],
  });
  iconMap.set(VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS, {
    icon: 'upload',
    color: ColorUse['dark-20'],
  });
  iconMap.set(VideoUploadFeature.Status.UPLOAD_GRANTED, {
    icon: 'upload',
    color: ColorUse['dark-20'],
  });
  iconMap.set(VideoUploadFeature.Status.UPLOADING, {
    icon: 'upload',
    color: ColorUse['process-50'],
  });
  iconMap.set(VideoUploadFeature.Status.UPLOAD_SUCCESS, {
    icon: 'upload',
    color: ColorUse['process-50'],
  });
  iconMap.set(VideoUploadFeature.Status.CREATE, {
    icon: 'progressing',
    color: ColorUse['process-50'],
  });
  iconMap.set(VideoUploadFeature.Status.CREATE_NAME_ERROR, {
    icon: 'progressing',
    color: ColorUse['process-50'],
  });
  iconMap.set(VideoUploadFeature.Status.UPLOAD_CANCELED, {
    icon: 'close',
    color: ColorUse['warning-50'],
  });
  iconMap.set(VideoUploadFeature.Status.CREATE_SUCCESS, {
    icon: 'check',
    color: ColorUse['confirm'],
  });

  return (
    <StatusIcon
      className={`video-status-icon--${status}`}
      icon={iconMap?.get(status)?.icon || 'progressing'}
      color={iconMap?.get(status)?.color || ColorUse['dark-20']}
    />
  );
};

export default VideoUploadStatusIcon;
