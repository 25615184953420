import { useEffect } from 'react';
import { useVideoOutletContext } from '../hooks/useVideoOutletContext';
import '../video-detail.scss';
import VideoDetail from '../VideoDetail/VideoDetail';

const VideoDetailOutlet = () => {
  const { visitedParent } = useVideoOutletContext();

  // keeps user from accessing child routes directly
  useEffect(() => {
    visitedParent();
  }, [visitedParent]);

  return <VideoDetail />;
};

export default VideoDetailOutlet;
