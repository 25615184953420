/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LandingPageSettingDTO } from '../models/LandingPageSettingDTO';
import type { LandingPageSettingResponseDTO } from '../models/LandingPageSettingResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LandingPageSettingsService {

    /**
     * Gets the LandingPage settings
     * @returns LandingPageSettingResponseDTO
     * @throws ApiError
     */
    public static getLandingpageSettings(): CancelablePromise<Array<LandingPageSettingResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/landingpage',
        });
    }

    /**
     * Add LandingPage setting
     * @param requestBody
     * @returns LandingPageSettingResponseDTO
     * @throws ApiError
     */
    public static addLandingpageSetting(
        requestBody: LandingPageSettingDTO,
    ): CancelablePromise<LandingPageSettingResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/landingpage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update LandingPage setting
     * @param id
     * @param requestBody
     * @returns LandingPageSettingResponseDTO
     * @throws ApiError
     */
    public static updateLandingpageSetting(
        id: number,
        requestBody: LandingPageSettingDTO,
    ): CancelablePromise<LandingPageSettingResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/landingpage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
