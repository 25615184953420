import { useVideoOutletContext } from 'features/video-detail';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import VideoDelete from '../VideoDelete/VideoDelete';

/** Route /videos/:id/delete */
const VideoDeleteOutlet = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const videoId = id ? parseInt(id) : 0;
  const { video, visited } = useVideoOutletContext();
  return visited ? (
    <VideoDelete
      onCancelClick={() => navigate(-1)}
      onSuccessDelete={() => navigate('/videos')}
      id={video?.id}
      name={video?.name}
      videoStatus={video?.status}
    />
  ) : (
    <Navigate to={`/videos/${videoId}`} />
  );
};

export default VideoDeleteOutlet;
