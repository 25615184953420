import React from 'react';
import { NavLink } from 'react-router-dom';
import HeaderActiveIcon from './HeaderActiveIcon';

export interface Props {
  to: string;
  name: string;
  onClick: () => void;
}

const HeaderMenuItem = ({ to, name, onClick }: Props) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'header__item header__item--active' : 'header__item'
      }
      to={to}
      onClick={onClick}
    >
      <div className="header__item-text">{name}</div>
      <HeaderActiveIcon />
    </NavLink>
  );
};

export default HeaderMenuItem;
