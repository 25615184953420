import LazyImage from 'components/LazyImage/LazyImage';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import placeholder from 'images/placeholder-video-mediathek-gradient.svg';
import { VideoItemDTO, VideoPreviewDTO } from 'generated';
import './player.scss';
import Icon from 'components/Icon/Icon';

export interface Props {
  videoItems?: Array<VideoItemDTO>;
  videoPreviews?: Array<VideoPreviewDTO>;
  name?: string;
  showPlayButton?: boolean;
}

const Player = ({ videoItems, videoPreviews, name, showPlayButton }: Props) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const videoUrlMp4 = videoItems?.find(
    (i) => i.streamType === VideoItemDTO.streamType.MP4_LOW,
  )?.url;
  const videoUrlHls = videoItems?.find(
    (i) => i.streamType === VideoItemDTO.streamType.HLS,
  )?.url;
  const preview = videoPreviews?.find(
    (i) => i.size === VideoPreviewDTO.size.LARGE,
  )?.uri;

  return (
    <div className="player">
      {showPlayer && (videoUrlMp4 || videoUrlHls) ? (
        <div className="player__video">
          <ReactPlayer
            url={videoUrlMp4 || videoUrlHls}
            controls={true}
            playing
            width={
              getComputedStyle(
                document.documentElement.querySelector('.player') ||
                  document.documentElement,
              ).getPropertyValue('--video-width') || 'auto'
            }
            height={
              getComputedStyle(
                document.documentElement.querySelector('.player') ||
                  document.documentElement,
              ).getPropertyValue('--video-height') || '100%'
            }
          />
        </div>
      ) : (
        <button className="player__image" onClick={() => setShowPlayer(true)}>
          {preview && name && (
            <LazyImage
              src={preview}
              alt={name}
              aspectRatio={202 / 360}
              lqip={placeholder}
              className="player__image-img"
            />
          )}
          {showPlayButton && <Icon icon="play" className="player__icon" />}
        </button>
      )}
    </div>
  );
};

export default Player;
