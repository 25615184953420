import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { FilterType, FilterContext } from '../types';
import { FilterItem } from 'features/video-filter';

const FilterReactContext = createContext<FilterContext<'', FilterItem>>(
  {} as FilterContext<'', FilterItem>,
);

export const FilterProvider = <
  FilterKeys extends string,
  T extends FilterItem,
>({
  children,
  initial,
}: {
  children: ReactNode;
  initial?: FilterType<FilterKeys, T>;
}) => {
  const [filter, setFilter] = useState<FilterType<FilterKeys, T> | undefined>(
    initial,
  );

  const setFilterByKey = (key: FilterKeys, value?: FilterItem[]) => {
    setFilter((prev) => {
      // replace value and remove duplicates
      return {
        ...prev,
        [key]: value
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id),
          )
          .map((i) => ({
            // add additional props not included in FiltereItem from prev state as they will get lost if not found in the new value
            // this will happen when a serverside filter is applied and the already selected prop is not found in the new data array
            ...prev?.[key]?.find((j) => j.id === i.id),
            ...i,
          })),
      };
    });
  };

  const removeFilterById = (id: string) => {
    setFilter((prev) => {
      const newValue = Object.keys(prev || {}).reduce(
        (acc, key: FilterKeys) => {
          const newObj = {
            ...acc,
            [key]: prev?.[key]?.filter((item) => item.id !== id),
          };
          // remove empty arrays
          Object.keys(newObj).forEach((key) => {
            if (newObj[key]?.length === 0) {
              delete newObj[key];
            }
          });
          return newObj;
        },
        {} as FilterType<FilterKeys, T>,
      );
      return newValue;
    });
  };

  const reset = () => {
    setFilter(undefined);
  };

  const resetByKey = (key: keyof FilterType<FilterKeys, T>) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: undefined,
      };
    });
  };

  const memoedValue = useMemo(
    () => ({
      setFilter: setFilterByKey,
      filter,
      resetFilter: reset,
      resetFilterByKey: resetByKey,
      removeFilterById,
    }),
    [filter],
  ) as FilterContext<'', FilterItem>;

  return (
    <FilterReactContext.Provider value={memoedValue}>
      {children}
    </FilterReactContext.Provider>
  );
};

export default function useFilter<
  FilterKeys extends string,
  T extends FilterItem,
>() {
  const context = useContext<FilterContext<FilterKeys, T>>(
    FilterReactContext as unknown as React.Context<
      FilterContext<FilterKeys, T>
    >,
  );
  if (!context) {
    throw new Error('useFilter must be used under FilterProvider');
  }
  return context;
}
