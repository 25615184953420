import { UseMutationResult } from '@tanstack/react-query';
import { useUpdateVideoChannels } from 'features/video-channels';
import { Variables } from 'features/video-channels/queries';
import { ApiError, VideoResponseDTO } from 'generated';
import React, { ReactNode } from 'react';

type MutationType = UseMutationResult<VideoResponseDTO, ApiError, Variables>;

export const VideoChannelsMutationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const videoChannelsMutation = useUpdateVideoChannels();

  return (
    <MutationContext.Provider value={videoChannelsMutation}>
      {children}
    </MutationContext.Provider>
  );
};
const MutationContext = React.createContext<MutationType>({} as MutationType);

/** Provides the useUpdateVideoChannels Mutation from feature/video-channels inside a Context Provider.
 * The VideoChannelsMutationProvider needs to be a parent of the component the hook is used in.
 * The same mutation state can then be used throughout the children components.
 * Make sure to reset the mutation, if you want to mutate it again.
 */
const useUpdateVideoChannelsWithContext = () =>
  React.useContext(MutationContext);

export default useUpdateVideoChannelsWithContext;
