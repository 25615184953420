import classNames from 'classnames';
import React, { FC } from 'react';
import { randomString } from '../../utils/random-string';
import Icon from '../Icon/Icon';
import './checkbox.scss';

export interface Props {
  className?: string;
  id?: string;
  label?: string | React.ReactNode;
  onChange(
    checked: boolean,
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void;
  hideLabel?: boolean;
  wrapperClassName?: string;
  title?: string;
  name: string;
  checked?: boolean;
  labelId?: string;
  errorId?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  success?: string;
  appearance?: 'light' | 'dark';
}

const Checkbox: FC<Props> = ({
  className,
  id,
  label,
  onChange,
  hideLabel = false,
  wrapperClassName,
  title = '',
  labelId,
  error,
  success,
  appearance,
  ...rest
}: Props) => {
  const checkboxId = id || `checkbox-${randomString()}`;
  const errorId = error && `error--${id}`;

  return (
    <div className={classNames('checkbox__wrap', wrapperClassName)}>
      <label
        htmlFor={checkboxId}
        className={classNames(
          'checkbox',
          rest.disabled && 'checkbox--disabled',
          error && 'checkbox--error',
          success && 'checkbox--success',
          appearance && `checkbox--${appearance}`,
        )}
      >
        <input
          {...rest}
          type="checkbox"
          onChange={(evt) => {
            onChange(evt.target.checked, checkboxId, evt);
          }}
          className="checkbox__input"
          id={checkboxId}
          aria-invalid={error ? 'true' : undefined}
          aria-labelledby={labelId}
          aria-errormessage={rest.errorId}
          disabled={rest.disabled}
        />

        <span className="checkbox__indicator">
          <Icon icon="check" className="checkbox__indicator-icon" />
        </span>
        <span
          className={classNames({
            checkbox__label: true,
            'hidden-visually': hideLabel,
          })}
          title={title}
        >
          {label}
          {rest.required && (
            <abbr className="checkbox__label-required" title="Pflichtfeld">
              *
            </abbr>
          )}
        </span>
      </label>
      {error && (
        <div role="alert" id={rest.errorId || errorId} className="input__error">
          {error}
        </div>
      )}
      {success && (
        <div role="alert" className="input__error input__success">
          {success}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
