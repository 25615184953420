import ListItem from 'components/ListItem/ListItem';
import Modal from 'components/Modal/Modal';
import { BatchLinkHashtagsErrorDto, GetProductsResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import placeholderImage from 'images/placeholder-product.svg';
import List from 'components/List/List';
import { Spacer } from 'components/Layout/Layout';
import Button from 'components/Button/Button';
import Color from 'types/Color';

export type Props = {
  errors?: BatchLinkHashtagsErrorDto[];
  openErrorModal: boolean;
  setOpenErrorModal: (i: boolean) => void;
  products?: GetProductsResponseDTO[];
};
const ErrorModal = ({
  errors,
  openErrorModal,
  setOpenErrorModal,
  products,
}: Props) => {
  const { t } = useTranslation(['translation', 'product']);
  const productsWithErrors = errors
    ?.filter((i) => i.reason === BatchLinkHashtagsErrorDto.reason.MAX_HASHTAGS)
    .map((i) => i.entity)
    .map(({ entityId }) => products?.find((p) => p.id === entityId));
  return (
    <Modal
      isOpen={openErrorModal}
      headline={t('product:edit.maxError.headline')}
      onClose={() => setOpenErrorModal(false)}
      variant="wide"
    >
      <Spacer className="modal__text" marginBottom={4}>
        <p>{t('product:edit.maxError.desc')}</p>
      </Spacer>

      <List>
        {productsWithErrors?.map((item, index) => (
          <ListItem
            deactivated={!item?.isAvailable}
            key={index}
            title={
              item?.productLocalisations[0]
                ? item?.productLocalisations[0]?.name
                : t('no_title')
            }
            subtitle={item?.vendorProductId}
            price={{
              amount: item?.productLocalisations[0]
                ? item?.productLocalisations[0].price
                : 0,
              currency: item?.productLocalisations[0]?.currencyCode,
            }}
            image={
              item?.videos[0]?.videoPreviews?.find((i) => i.size === 'small')
                ?.uri
            }
            id={`${item?.id}`}
            placeholder={placeholderImage}
          />
        ))}
      </List>

      <div className="modal__controls">
        <Button
          text={t('close')}
          type="button"
          onClick={() => setOpenErrorModal(false)}
          color={Color.primary}
          className="flex-box__item--push-right "
        />
      </div>
    </Modal>
  );
};

export default ErrorModal;
