import Button from 'components/Button/Button';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox } from 'components/Layout/Layout';
import { AnimatedRoute } from 'features/animated-routing';
import useSidebar from 'hooks/useSidebar';
import { useTranslation } from 'react-i18next';
import { useDeleteVideo } from '../queries';
import { VideoResponseDTO } from 'generated';
import '../video-delete.scss';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

export interface Props {
  id: number;
  name: string;
  onCancelClick: () => void;
  onSuccessDelete?: () => void;
  videoStatus?: VideoResponseDTO.status;
}

const VideoDelete = ({
  id,
  name,
  onCancelClick,
  onSuccessDelete,
  videoStatus,
}: Props) => {
  const { t } = useTranslation();
  const videoDeleteMutation = useDeleteVideo();
  const { closeSidebar } = useSidebar();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const handleDelete = () => {
    videoDeleteMutation.mutate(
      { videoId: id },
      {
        onSuccess: () => {
          closeSidebar();
          onSuccessDelete?.();
        },
        onError: () => onCancelClick(),
      },
    );
  };

  return (
    <AnimatedRoute>
      <div className="video-detail__child video-delete">
        <LabelText label={name}>
          {t('video:really_delete', { title: name })}
        </LabelText>
        <FlexBox justifyContent="space-between" margin={[8, 0]}>
          <Button
            text={t('cancel')}
            onClick={onCancelClick}
            appearance="ghost"
            small={!isDesktop}
          />

          <Button text={t('delete')} onClick={handleDelete} small />
        </FlexBox>
      </div>
    </AnimatedRoute>
  );
};

export default VideoDelete;
