import Help from 'components/Help/Help';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoginContainer from '../features/Login';
import LandingPage from './LandingPage';

const LoginPage = () => {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    document.querySelector('body')?.classList.add('body--login');
    return () => {
      document.querySelector('body')?.classList.remove('body--login');
    };
  });

  return (
    <LandingPage headline={t('login-headline')} hint={<Help />}>
      <LoginContainer />
    </LandingPage>
  );
};

export default LoginPage;
