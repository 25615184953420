import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import IconButton from 'components/IconButton/IconButton';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import Modal from 'components/Modal/Modal';
import { isAvailable } from 'features/availability';
import { ChannelsService, VoucherDto, VoucherResponseDto } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { FunctionPropsTyped, Service } from 'hooks/useList';
import useOpen from 'hooks/useOpen';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VoucherForm from '../VoucherForm';
import { useDeleteVoucher, useEditVoucher } from '../queries';

interface Props {
  channelId: number;
  voucher: VoucherResponseDto;
}

const EditVoucher = ({ voucher, channelId }: Props) => {
  const { t } = useTranslation(['translation', 'channel']);
  const { setAppStatus } = useAppStatus();
  const editModal = useOpen(false);
  const deleteModal = useOpen(false);
  const editMutation = useEditVoucher();
  const deleteMutation = useDeleteVoucher();

  const handleSubmit = async (voucher: VoucherDto) => {
    editMutation.mutate(
      { channelId, voucher },
      {
        onSettled: () => {
          editModal.close();
        },
        onError: () => {
          setAppStatus(t('channel:voucher.update.error'), 'error');
        },
      },
    );
  };

  const handleDelete = async () => {
    deleteMutation.mutate(
      { channelId },
      {
        onSettled: () => {
          deleteModal.close();
        },
        onError: () => {
          setAppStatus(t('channel:voucher.delete.error'), 'error');
        },
      },
    );
  };

  return (
    <>
      <FlexBox justifyContent="space-between" alignItems="center">
        <span className="label-text__text">
          {voucher.text}
          {!isAvailable(voucher.availability) ? (
            <span> ({t('channel:voucher.inactive')})</span>
          ) : (
            ''
          )}
        </span>

        <FlexBox>
          <FlexItem>
            <IconButton onClick={editModal.open} icon="edit" />
          </FlexItem>

          <FlexItem marginLeft={1}>
            <IconButton onClick={deleteModal.open} icon="delete" />
          </FlexItem>
        </FlexBox>
      </FlexBox>

      <Modal
        headline={t('channel:voucher.update-text')}
        isOpen={editModal.isOpen}
        onClose={editModal.close}
        variant="wide"
      >
        <p className="modal__text">{t('channel:voucher.edit-modal-text')}</p>

        <VoucherForm
          voucher={voucher}
          onSubmit={handleSubmit}
          onCancel={editModal.close}
          loading={editMutation.isLoading}
        />
      </Modal>

      <ConfirmModal
        isOpen={deleteModal.isOpen}
        headline={t('delete')}
        text={t('channel:voucher.really_delete', {
          title: voucher.code,
        })}
        onCancelClick={deleteModal.close}
        onConfirmClick={handleDelete}
        confirmLoading={deleteMutation.isLoading}
        confirmText={t('delete')}
      />
    </>
  );
};

export default EditVoucher;
