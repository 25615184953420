import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Loader from '../Loader/Loader';
import './pdf-viewer.scss';

const PdfViewer = ({
  file,
  setError,
  onLoadSuccess,
}: {
  file: string;
  setError: (error: Error) => void;
  onLoadSuccess?: () => void;
}) => {
  const [numPages, setNumPages] = useState<number>();
  const { t } = useTranslation();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    onLoadSuccess && onLoadSuccess();
  }

  const handleClick = ({ pageNumber }: { pageNumber: string }) => {
    const page = document.querySelector(`[data-page-number='${pageNumber}']`);
    if (page) {
      page.scrollIntoView();
    }
  };

  return (
    <>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        error={t('form.fileLoad')}
        onLoadError={setError}
        loading={<Loader />}
        onItemClick={handleClick}
        options={{
          verbosity: 0,
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={550} />
        ))}
      </Document>
    </>
  );
};

export default PdfViewer;
