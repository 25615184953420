import classNames from 'classnames';
import filesize from 'filesize';
import { FieldProps, getIn } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Color from '../../types/Color';
import Button from '../Button/Button';
import './upload-field.scss';

export interface Props {
  onChange?: (file: File) => void;
  disabled?: boolean;
  allowedTypes: string[];
  className?: string;
  size?: boolean;
  fileName?: boolean;
  maxFileSize?: number;
}

const UploadField: FC<FieldProps & Props> = ({
  field,
  form,
  allowedTypes,
  className,
  size,
  fileName,
  maxFileSize,
  ...props
}) => {
  const { t } = useTranslation(['video']);
  const err = getIn(form.errors, field.name);
  const tou = getIn(form.touched, field.name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      form.setFieldValue(field.name, event.target.files[0]);

      if (
        maxFileSize &&
        event.target.files[0] &&
        event.target.files[0].size > maxFileSize
      ) {
        form.setFieldError(field.name, t('translation:form.tooBig'));
        form.setFieldTouched(field.name, true, false);
      }
      props.onChange && props.onChange(event.target.files[0]);
    }
  };

  const getAllowedTypes = () => {
    let allowed = '';
    allowedTypes.forEach((type, index) => {
      allowed = `${allowed}${index === 0 ? '' : ','}.${type}`;
    });
    return allowed;
  };

  return (
    <label
      className={classNames(
        'upload-field',
        props.disabled && 'upload-field--disabled',
        err && tou && 'upload-field--error',
        className,
      )}
    >
      <input
        className="hidden-visually upload-field__input"
        name={field.name}
        type="file"
        accept={getAllowedTypes()}
        onChange={handleChange}
        disabled={props.disabled}
      />

      <Button
        text={field.value ? t('video:upload.change') : t('video:upload.file')}
        icon="file"
        className="upload-field__button"
        appearance={field.value ? 'ghost' : 'filled'}
        disabled={props.disabled}
        color={err && tou ? Color.warning : Color.primary}
      />
      {field.value && (
        <div className="upload-field__value">
          {size && (
            <>
              <span className="caption upload-field__detail">
                {t('video:upload.size')}:
              </span>
              <span>
                {filesize(field.value.size, {
                  locale: i18n.language,
                })}
              </span>
            </>
          )}
          {fileName && (
            <>
              <br></br>
              <span className="caption upload-field__detail">
                {t('video:upload.fileName')}:
              </span>
              <span>{field.value.name}</span>
            </>
          )}
        </div>
      )}
      {err && tou && <div className="input__error">{err}</div>}
    </label>
  );
};

export default UploadField;
