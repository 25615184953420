import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ChannelDetailForm from 'features/channel-detail-form';
import PageType from '../types/PageType';

const AddChannelPage: FC<PageType> = () => {
  const { id } = useParams();
  const numberId = id ? parseInt(id) : undefined;

  return (
    <div className="page page--detail">
      <div className="page__breadcrumb">
        <Breadcrumbs />
      </div>
      <ChannelDetailForm channelId={numberId} />
    </div>
  );
};
export default AddChannelPage;
