import Navi, { Props as NaviProps } from 'components/Navi/Navi';
import Steps from 'components/Steps/Steps';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './tree-select.scss';
import TreePanel from './components/TreePanel';

export type Props = {
  tree?: TreeItem[];
  /** This array needs to be sorted by depth starting with the lowest. All Ids must be from a single tree branch */
  value: TreeItem[];
  onChange: (selectedItems: TreeItem[]) => void;
  /** If the tree is connected to a search and opens from a non-tree target, you can give this panel another id than 0 */
  rootPanelId?: number;
  /** Adds a close button in the mobile view */
  onClose?: () => void;
  /** Populates the Navi component with additional props and components if needed */
  mobileNavigationProps?: Partial<NaviProps>;
  onOpenPanel?: (panelId: number) => void;
};

export type TreeItem = {
  id: number;
  name: string;
  children?: TreeItem[];
  /** if the tree depth is not fully loaded, this can indicate to load more subtrees **/
  hasChildren?: boolean;
};

const TreeSelect = ({
  tree,
  value,
  onChange,
  rootPanelId = 0,
  onClose,
  mobileNavigationProps,
  onOpenPanel,
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation(['product']);
  const [showPanels, _setShowPanels] = useState<number[]>([rootPanelId]);
  const [caption, setCaption] = useState<string>(t('product:addCategories'));

  useEffect(() => {
    _setShowPanels([rootPanelId, ...(isDesktop ? value.map((i) => i.id) : [])]);
  }, [isDesktop]);

  useEffect(() => {
    onOpenPanel?.(showPanels[showPanels.length - 1]);
  }, [showPanels]);

  const setShowPanels = (panel: number, level: number) => {
    _setShowPanels((panels) => {
      return [...(panels.slice(0, level + 1) || []), panel];
    });
  };

  const handleSelect = (item: TreeItem, level: number, checked: boolean) => {
    const cutBranch = rootPanelId
      ? value.slice(1, level + 1)
      : value.slice(0, level);

    if (checked) {
      onChange([...cutBranch, item]);
      if (isDesktop) {
        setShowPanels(item.id, rootPanelId ? level + 1 : level);
      }
    } else {
      onChange(cutBranch.filter((i) => i.id !== item.id));
    }
  };

  const navigateBack = () => {
    _setShowPanels(showPanels.slice(0, -1));
  };

  const handleClose = () => {
    _setShowPanels([rootPanelId]);
    onClose?.();
  };

  const panelProps = {
    items: tree,
    selectedItems: value,
    onSelectChange: handleSelect,
    showPanels,
    setShowPanels,
    panelId: rootPanelId,
    level: 0,
    setCaption,
  };

  return (
    <ul className="tree-select">
      {!isDesktop ? (
        <>
          <Navi
            forwardKey={showPanels.toString()}
            onBackClick={navigateBack}
            onClose={handleClose}
            childCenterSlot={<span className="caption">{caption}</span>}
            childRouteIsActive={showPanels.length > 1}
            parentCenterSlot={
              <span className="caption--lavender">
                {t('product:addCategories')}
              </span>
            }
            {...mobileNavigationProps}
          />
          <Steps stepKey={!isDesktop ? showPanels.toString() : 'true'}>
            <TreePanel {...panelProps} />
          </Steps>
        </>
      ) : (
        <TreePanel {...panelProps} />
      )}
    </ul>
  );
};

export default TreeSelect;
