import Modal from 'components/Modal/Modal';
import { VoucherDto } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import useOpen from 'hooks/useOpen';
import { useTranslation } from 'react-i18next';
import VoucherForm from '../VoucherForm';
import { useAddVoucher } from '../queries';

interface Props {
  channelId: number;
}

const AddVoucher = ({ channelId }: Props) => {
  const { t } = useTranslation(['translation', 'channel']);
  const { isOpen, open, close } = useOpen(false);
  const { setAppStatus } = useAppStatus();
  const addMutation = useAddVoucher();

  const handleSubmit = async (voucher: VoucherDto) => {
    addMutation.mutate(
      { channelId, voucher },
      {
        onError: () => {
          setAppStatus(t('channel:voucher.add.error'), 'error');
        },
        onSettled: () => {
          close();
        },
      },
    );
  };

  return (
    <>
      <div className="add-voucher">
        <span className="add-voucher__text">
          {t('channel:voucher.no-voucher-text')}
        </span>

        <div className="info-video-list__more">
          <button className="info-video-list__more-link" onClick={open}>
            {t('channel:voucher.add-text')}
          </button>
        </div>
      </div>

      <Modal
        headline={t('channel:voucher.add-text')}
        isOpen={isOpen}
        onClose={close}
        variant="wide"
      >
        <p className="modal__text">{t('channel:voucher.add-modal-text')}</p>

        <VoucherForm
          onSubmit={handleSubmit}
          onCancel={close}
          loading={addMutation.isLoading}
        />
      </Modal>
    </>
  );
};

export default AddVoucher;
