import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useLocationState = () => {
  const location = useLocation();

  const memoState = useMemo(() => {
    const state = location.state as { from: string };

    if (state && state.from) {
      return state.from;
    }

    return '/';
  }, [location]);

  return memoState;
};

export default useLocationState;
