import LabelText from 'components/LabelText/LabelText';
import { FlexBox, Spacer } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import ShippingDownloads from './ShippingDownloads';
import { ShipmentComponentProps } from './CreateShipment';
import Button from 'components/Button/Button';
import { queryClient } from 'index';
import { orderDetailKeys } from 'features/order-detail';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

const CreateShipmentsDownloads = ({
  orderNo,
  step,
  setStep,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const vendorAgent = useReduxSelector(selectVendorAgent);

  /** 
  We transferred the mutation data from useCreateCompleteShipment to the downloads step, because setting the queryData will result in rerendering of the Shipping Component. This will cause this step to never be accessible, because it is still part of the CreateShipment Process, even if the Shipment was successfully created.
  
  This is a workaround, because the order action status does not represent, that a manifestLink needs to be created before the shipment really is commissioned. 
  */
  const handleNext = () => {
    if (step?.data) {
      queryClient
        .setQueryData(
          orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
          (oldData: any) => ({
            ...oldData,
            ...step?.data,
          }),
        )
        .then(() => setStep({ key: 'start', data: step?.data }));
    } else {
      queryClient
        .invalidateQueries(
          orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
        )
        .then(() => setStep({ key: 'start', data: step?.data }));
    }
  };

  return (
    <LabelText label={t('orders:detail.shipping.downloads.title')}>
      {t('orders:detail.shipping.downloads.description')}

      <Spacer marginTop={6} marginBottom={10}>
        <ShippingDownloads orderNumber={orderNo} />
      </Spacer>

      <FlexBox marginTop={6} justifyContent="flex-end">
        <Button text={t('next')} onClick={handleNext} />
      </FlexBox>
    </LabelText>
  );
};

export default CreateShipmentsDownloads;
