import classNames from 'classnames';
import Button from 'components/Button/Button';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { ProductInfoContainer } from 'features/product-info';
import { useLinkProductToVideoWithContext } from 'features/video-link-product';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGetVideo } from '../../queries';
import VideoDetailProductControls from './VideoDetailProductControls';

const VideoDetailProduct = ({ videoId }: { videoId: number }) => {
  const { state } = useLocation() as {
    state: { from?: string; error?: boolean };
  };
  const { t } = useTranslation(['translation', 'video']);
  const { data: video } = useGetVideo(videoId);

  const linkProductMutation = useLinkProductToVideoWithContext();
  const detailProductRef = useRef<HTMLDivElement>(null);
  const [productError, setProductError] = useState();
  const [productSuccess, setProductSuccess] = useState();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  useEffect(() => {
    if (state?.from && state.from === 'change') {
      setTimeout(() => {
        detailProductRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [state]);

  useEffect(() => {
    if (linkProductMutation.isSuccess) {
      setProductSuccess(t('inline-edit.success'));
      setTimeout(() => {
        linkProductMutation.reset();
      }, 7000);
    } else {
      setProductSuccess(undefined);
    }

    if (linkProductMutation.isError) {
      setProductError(t('inline-edit.error'));
    } else {
      setProductError(undefined);
    }
  }, [linkProductMutation.status]);

  const handleUnlink = () => {
    linkProductMutation.reset();
    linkProductMutation.mutate({ productId: null, videoId });
  };

  const getEmptyProductText = () => {
    if (video?.type === VideoResponseDTO.type.CHANNEL) {
      return t('video:product.empty-type-channel');
    } else return t('video:product.empty');
  };

  return (
    <div ref={detailProductRef} className="video-detail__overlay-wrap">
      {linkProductMutation.isLoading && (
        <div className="video-detail__overlay">
          <Loader />
        </div>
      )}

      <Spacer
        className={classNames(
          'video-detail__secondary',
          linkProductMutation.isError && 'video-detail__secondary--warning',
          linkProductMutation.isSuccess && 'video-detail__secondary--success',
        )}
        padding={isDesktop ? [4, 6, 4, 8] : 2}
        margin={isDesktop ? 0 : [0, -2]}
        id="detail-product"
      >
        <ErrorBoundary>
          {video?.product && video.product.id ? (
            <ProductInfoContainer
              className="video-detail__product-info"
              productId={video?.product?.id}
              error={productError}
              success={productSuccess}
              initialLanguage={video.language}
              controlsSlot={
                <VideoDetailProductControls onUnlink={handleUnlink} />
              }
            />
          ) : (
            <>
              <h2 className="caption--dark">{t('video:product.label')}</h2>
              <Spacer margin={[1, 0, 4]} className="small c-dark-50">
                {getEmptyProductText()}
              </Spacer>
              <Spacer>
                {video?.type !== VideoResponseDTO.type.CHANNEL && (
                  <Button small text={t('product:footer.add')} link="change" />
                )}
              </Spacer>
            </>
          )}
        </ErrorBoundary>
      </Spacer>
    </div>
  );
};

export default VideoDetailProduct;
