import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { useGetVideo } from 'features/video-detail';
import { useUpdateVideoType } from 'features/video-type';
import { getVideoTypeAppearance } from 'features/video-type/utils';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StepChildProps, StepKeys } from '../VideoType';

const TypeDefault = ({ videoId, setStep, setErrors }: StepChildProps) => {
  const { data: video } = useGetVideo(videoId);
  const { t } = useTranslation(['translation', 'video']);
  const updateMutation = useUpdateVideoType();
  const navigate = useNavigate();

  const enabledTypes = [
    VideoResponseDTO.type.PRODUCT,
    VideoResponseDTO.type.CHANNEL,
  ];

  const handleSetType = (type: VideoResponseDTO.type) => {
    updateMutation.mutate(
      {
        videoId,
        type,
      },
      {
        onSuccess: (data) => {
          const getKey = () => {
            switch (type) {
              case VideoResponseDTO.type.PRODUCT:
                return StepKeys.PRODUCT_SUCCESS;

              case VideoResponseDTO.type.CHANNEL:
                return StepKeys.INTRO_SUCCESS;

              default:
                return StepKeys.DEFAULT;
            }
          };
          setStep(getKey());
        },
        onError: (error) => {
          const getKey = () => {
            switch (type) {
              case VideoResponseDTO.type.CHANNEL:
                return StepKeys.INTRO_ERROR;

              default:
                return StepKeys.DEFAULT;
            }
          };

          setErrors({
            [getKey()]: error,
          });

          const step = getKey();
          const nextStep = step !== StepKeys.DEFAULT;
          if (nextStep) {
            setStep(step);
          } else {
            navigate(`/videos/${videoId}`);
          }
        },
      },
    );
  };

  // Will keep the loader after its finished to not flicker on step change
  if (
    updateMutation.isLoading ||
    updateMutation.isSuccess ||
    updateMutation.isError
  ) {
    return (
      <FlexBox justifyContent="center">
        <Loader />
      </FlexBox>
    );
  }

  return (
    <div>
      <div className="video-type__list">
        {enabledTypes.map((type) => {
          const appearance = getVideoTypeAppearance(type);
          return (
            <FlexBox
              key={type}
              tag={video?.type === type ? 'div' : 'button'}
              className={classNames(
                'video-type__button',
                `video-type__button--${appearance.color}`,
              )}
              type={video?.type === type ? undefined : 'button'}
              alignItems="center"
              onClick={
                video?.type === type ? undefined : () => handleSetType(type)
              }
            >
              <Icon icon={appearance.icon} />
              <Spacer className="video-type__button-text" marginLeft={2}>
                <span>{t(`video:type.${type.toLocaleLowerCase()}.label`)}</span>
              </Spacer>

              <FlexItem
                pushRight
                className={classNames(
                  'video-type__button-arrow',
                  video?.type === type && 'video-type__button-arrow--disabled',
                )}
              >
                <Icon icon={video?.type === type ? 'check' : 'arrow-right'} />
              </FlexItem>
            </FlexBox>
          );
        })}
      </div>

      <Spacer className="video-type__desc" marginTop={9}>
        {enabledTypes.map((type) => (
          <LabelText
            key={type}
            label={t(`video:type.${type}.label`)}
            className="video-type__desc-block"
          >
            {t(`video:type.${type}.desc`)}
          </LabelText>
        ))}
      </Spacer>
    </div>
  );
};

export default TypeDefault;
