import classNames from 'classnames';
import IconButton from 'components/IconButton/IconButton';
import Input, { Props as InputProps } from 'components/Input/Input';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './inline-edit.scss';

export interface Props extends InputProps {
  onEditSave: () => void;
  onEditCancel: () => void;
  edit?: boolean;
}

/** Text Input with submit and cancel button for inline editing.
 * To use this with Formik, please use the InputInlineEditField component. */
const InputInlineEdit = ({
  onEditCancel,
  onEditSave,
  edit,
  ...inputProps
}: Props) => {
  const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation();

  const handleEditSave = () => {
    onEditSave();
    ref.current?.blur();
  };

  const handleEditCancel = () => {
    onEditCancel();
    ref.current?.blur();
  };

  const keyDownHandler = (
    event: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && edit) {
      event.preventDefault();
      handleEditSave();
    }

    if (event.key === 'Escape' && edit) {
      event.preventDefault();
      handleEditCancel();
    }
  };

  return (
    <div
      className={classNames(
        'inline-edit__wrap',
        edit && 'inline-edit__wrap--edit',
        (inputProps.success || inputProps.error) && 'inline-edit__wrap--badge',
      )}
    >
      <Input
        {...inputProps}
        ref={ref}
        onKeyDown={keyDownHandler}
        messagePosition="top"
        enterKeyHint="send"
      />
      {edit && !inputProps.loading && (
        <FlexBox
          className="inline-edit__controls"
          marginTop={2}
          justifyContent="flex-end"
        >
          <FlexItem>
            <IconButton
              key="check"
              icon="check"
              type="button"
              tooltip={t('save')}
              onClick={handleEditSave}
              appearance="filled"
              big={!isDesktop}
              disabled={inputProps.error ? true : false}
            />
          </FlexItem>
          <FlexItem marginLeft={2}>
            <IconButton
              key="close"
              icon="close"
              onClick={handleEditCancel}
              tooltip={t('cancel')}
              big={!isDesktop}
            />
          </FlexItem>
        </FlexBox>
      )}
    </div>
  );
};

export default InputInlineEdit;
