import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import { useReduxDispatch } from '../../../redux/hooks';
import {
  prepareNewDraft,
  reset,
} from '../../../redux/slices/notifications/notificationSlice';
import AddNotificationFormContainer from './AddNotificationFormContainer';
import NotificationListContainer from './NotificationListContainer';
import './notifications.scss';

const NotificationsContainer = () => {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(['translation', 'settings']);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [],
  );

  return (
    <div>
      <Button
        text={t('settings:add')}
        onClick={() => dispatch(prepareNewDraft())}
      />

      <AddNotificationFormContainer />

      <NotificationListContainer />
    </div>
  );
};

export default NotificationsContainer;
