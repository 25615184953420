import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocationState from '../../hooks/useLocationState';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logIn, selectIsLoggedIn } from '../../redux/slices/auth/authSlice';
import './login.scss';
import LoginForm, { LoginFormValues } from './LoginForm';

const LoginContainer = () => {
  const [error, setError] = useState(false);
  const dispatch = useReduxDispatch();
  const location = useLocationState();
  const navigate = useNavigate();
  const isLoggedIn = useReduxSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location, { replace: true });
    }
  }, [isLoggedIn]);

  const handleSubmit = async ({ email, password }: LoginFormValues) => {
    try {
      await dispatch(logIn({ email, password })).unwrap();
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div className="login">
      <LoginForm onSubmit={handleSubmit} error={error} />
    </div>
  );
};

export default LoginContainer;
