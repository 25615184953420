import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import ChannelVideoInfo from '../../components/ChannelVideoInfo/ChannelVideoInfo';
import InputField from '../../components/Input/InputField';
import LinkCode from '../../components/LinkCode/LinkCode';
import LinkListItem from '../../components/LinkListItem/LinkListItem';
import List from '../../components/List/List';
import Modal from '../../components/Modal/Modal';
import {
  ApiError,
  CreateSharingUrlDTO,
  SharingService,
  SharingUrlDTO,
  VendorAgentResponseDTO,
  VendorAgentsService,
  VideoResponseDTO,
} from '../../generated';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut, selectVendorAgent } from '../../redux/slices/auth/authSlice';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import Color from '../../types/Color';
import notAuthenticated from '../../utils/not-authenticated';

export interface Props {
  channelId: number;
  item: VideoResponseDTO;
  shareUrls?: Array<SharingUrlDTO>;
  updateShareUrls: (newSharingUrls: SharingUrlDTO[]) => void;
  updateVisibility: (newVideo: VideoResponseDTO) => void;
}

const ChannelVideoInfoShare = ({
  item,
  shareUrls,
  updateShareUrls,
  updateVisibility,
  channelId,
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation(['translation', 'video']);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const dispatch = useReduxDispatch();

  const [error, setError] = useState();
  const [vendorAgents, setVendorAgents] = useState<VendorAgentResponseDTO[]>();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  const openShareModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const updateSharingTokenName = (sharingTokenId: number, name: string) => {
    const update = async () => {
      try {
        setError(undefined);

        const res = await SharingService.updateSharingToken(sharingTokenId, {
          name,
        });

        if (res) {
          const newUrls =
            shareUrls?.map((s) => {
              if (s.id === sharingTokenId) {
                return res;
              } else {
                return s;
              }
            }) || [];

          updateShareUrls(newUrls);
        }
      } catch (error) {
        if (notAuthenticated(error as ApiError)) {
          dispatch(logOut());
        } else {
          setError(t('video:share.error'));
        }
      }
    };
    update();
  };

  const updateSharingTokenCancel = () => {
    setError(undefined);
  };

  const handleDeleteShareUrl = (sharingTokenId: number) => {
    const deleteUrl = async () => {
      try {
        const res = await SharingService.softRemoveSharingToken(sharingTokenId);

        if (res) {
          const newUrls =
            shareUrls?.filter((share) => share.id !== sharingTokenId) || [];
          updateShareUrls(newUrls);
        }
      } catch (error) {
        if (notAuthenticated(error as ApiError)) {
          dispatch(logOut());
        } else {
          setError(t('video:share.error'));
        }
      }
    };
    deleteUrl();
  };

  const addShareLink = (
    { title }: { title: string },
    actions: FormikHelpers<{
      title: string;
    }>,
  ) => {
    const add = async () => {
      try {
        const res = await SharingService.createSharingUrl({
          name: title,
          videoId: item.id,
          channelId,
          sharingType: CreateSharingUrlDTO.sharingType.VENDOR_AGENT,
          sharingRefId: vendorAgent?.id,
        });

        if (res) {
          const newUrls = shareUrls || [];
          newUrls.push(res);
          updateShareUrls(newUrls);
          actions.resetForm();
        }
      } catch (error) {
        if (notAuthenticated(error as ApiError)) {
          dispatch(logOut());
        } else {
          setError(t('video:share.error'));
        }
      }
    };
    add();
  };

  const getVendorAgents = () => {
    if (!vendorAgents) {
      const get = async () => {
        try {
          const res = await VendorAgentsService.getVendorAgents();
          if (res) {
            setVendorAgents(res);
          }
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            setError(t('video:share.error'));
          }
        }
      };
      get();
    }
  };

  const getVendorAgentById = (id?: number) =>
    vendorAgents?.find((agent) => agent.id === id);

  return (
    <>
      <ChannelVideoInfo
        {...item}
        onShareClick={openShareModal}
        onToggledVisibility={updateVisibility}
        error={
          supportedLanguages.some((i) => i.iso === item.language)
            ? undefined
            : {
                badge: t('video:languageNotSupported.badge'),
                text: t('video:languageNotSupported.text'),
              }
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onAfterOpen={getVendorAgents}
        variant="wide"
        headline={t('video:share.title')}
      >
        <div className="modal__text">{t('video:share.msg')}</div>
        <div className="modal__caption modal__caption--black-50">
          {item.product?.productLocalisations[0].name} ({item.language})
        </div>
        {(shareUrls?.length === 0 || !shareUrls) && (
          <div className="modal__text modal__text--black-50">
            {t('video:share.empty')}
          </div>
        )}
        {shareUrls && (
          <List className="modal__list">
            {shareUrls.map((share) => (
              <LinkListItem
                key={share.id}
                userName={
                  getVendorAgentById(share.referenceId || undefined)?.name
                } //TODO name not ID
                onDelete={() => handleDeleteShareUrl(share.id)}
                onEditSave={(url: string) =>
                  updateSharingTokenName(share.id, url)
                }
                title={share.name || t('no_title')}
                onEditCancel={updateSharingTokenCancel}
              >
                <LinkCode link={share.url} copyToClipBoard />
              </LinkListItem>
            ))}
          </List>
        )}

        <div className="modal__fixed modal__fixed--bottom">
          <Formik
            initialValues={{ title: '' }}
            onSubmit={addShareLink}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(t('form.required')),
            })}
          >
            {(formik: FormikProps<{ title: string }>) => (
              <Form className="modal__form modal__form--flex">
                <Field
                  component={InputField}
                  wrapperClassName="form__group"
                  name="title"
                  label={t('video:share.add')}
                  placeholder={t('video:share.placeholder')}
                />
                <Button
                  text={t('add')}
                  color={Color.primary}
                  appearance="filled"
                  type="submit"
                  icon="add"
                />
              </Form>
            )}
          </Formik>

          {error && (
            <div role="alert" className="input__error">
              {error}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChannelVideoInfoShare;
