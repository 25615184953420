import { ClearIndicatorProps, GroupBase } from 'react-select';
import Icon from '../../Icon/Icon';
import { DefaultOption } from './Option';

/** This component replaces the originals clear indicator function to clean the values.
This one clears the search input value */
export const ClearIndicator = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  props: ClearIndicatorProps<OptionType, IsMulti, Group>,
) =>
  props.selectProps?.inputValue.length > 0 ? (
    <button
      onClick={props.selectProps.onClearInputValue}
      className="select-interactive__clear-indicator"
      type="button"
    >
      <Icon icon="close" />
    </button>
  ) : null;

export default ClearIndicator;
