import { addDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ChannelMinimalResponseDTO } from '../../generated';
import { useReduxSelector } from '../../redux/hooks';
import { selectTimezone } from '../../redux/slices/i18n/i18nSlice';
import DatePickerField from '../DatePicker/DatePickerField';
import Select from '../Select/Select';
import './statistics.scss';

const Controls = ({
  channels,
  selectedChannel,
  onChannelChange,
  onStartChange,
  onEndChange,
}: {
  channels?: ChannelMinimalResponseDTO[];
  selectedChannel?: string;
  onChannelChange: (channel: string) => void;
  onStartChange: (name: string, value?: Date | [Date, Date]) => void;
  onEndChange: (name: string, value?: Date | [Date, Date]) => void;
}) => {
  const { t } = useTranslation(['translation', 'statistics']);
  const timeZone = useReduxSelector(selectTimezone);
  const today = new Date();

  return (
    <div className="statistics__controls">
      <Formik
        initialValues={{
          start: addDays(today, -30),
          end: today,
        }}
        onSubmit={({ start, end }: { start?: Date; end?: Date }) => {}}
      >
        {(formik) => {
          return (
            <div className="statistics__controls-inner">
              <Select
                label={t('statistics:controls.select-label')}
                value={selectedChannel}
                onChange={(e) => onChannelChange(e.target.value)}
                options={
                  channels?.map((channel) => ({
                    text: channel.name,
                    value: channel.id.toString(),
                  })) || [{ text: '', value: '' }]
                }
              />
              <div className="statistics__controls-inner">
                <DatePickerField
                  name="start"
                  label={t('statistics:controls.start-label')}
                  selectsStart={true}
                  placeholderText={t('statistics:controls.start-placeholder')}
                  showTimeSelect={false}
                  dateFormat={'P'}
                  onChange={onStartChange}
                  maxDate={utcToZonedTime(new Date(), timeZone)}
                  startDate={
                    formik.values.start
                      ? utcToZonedTime(formik.values.start, timeZone)
                      : undefined
                  }
                  endDate={
                    formik.values.end
                      ? utcToZonedTime(formik.values.end, timeZone)
                      : undefined
                  }
                />
                <DatePickerField
                  name="end"
                  label={t('statistics:controls.end-label')}
                  selectsEnd={true}
                  placeholderText={t('statistics:controls.end-placeholder')}
                  showTimeSelect={false}
                  dateFormat={'P'}
                  onChange={onEndChange}
                  maxDate={utcToZonedTime(new Date(), timeZone)}
                  startDate={
                    formik.values.start
                      ? utcToZonedTime(formik.values.start, timeZone)
                      : undefined
                  }
                  endDate={
                    formik.values.end
                      ? utcToZonedTime(formik.values.end, timeZone)
                      : undefined
                  }
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Controls;
