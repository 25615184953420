import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FieldProps } from 'formik';
import SelectSupportedLanguages, { Props } from './SelectSupportedLanguages';

const SelectSupportedLanguagesField = ({
  field,
  form,
  ...props
}: Props & FieldProps) => {
  return (
    <ErrorBoundary>
      <SelectSupportedLanguages {...field} {...props} />
    </ErrorBoundary>
  );
};

export default SelectSupportedLanguagesField;
