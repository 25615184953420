/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LocaleDTO } from './LocaleDTO';
import type { VendorResponseDTO } from './VendorResponseDTO';

export type NotificationResponseDTO = {
  id: number;
  title: string;
  body: string;
  imageUrl: string;
  date: string;
  ios: boolean;
  android: boolean;
  status: NotificationResponseDTO.status;
  optionKey?: NotificationResponseDTO.optionKey;
  optionValue?: string;
  language: LocaleDTO;
  vendor?: VendorResponseDTO;
  analyticsLabel: string | null;
};

export namespace NotificationResponseDTO {
  export enum status {
    PENDING = 'pending',
    SENT = 'sent',
  }

  export enum optionKey {
    SCP = 'scp',
    LINK = 'link',
    CHANNEL_KEY = 'channelKey',
    URL = 'url',
  }
}
