/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternalOrderDto } from './InternalOrderDto';
import type { InternalOrderShipmentProviderUpdateDto } from './InternalOrderShipmentProviderUpdateDto';

export type InternalOrderShipmentDto = {
    createdAt: string;
    updatedAt?: string | null;
    id: number;
    orderId: number;
    order?: InternalOrderDto | null;
    orderShipmentProviderUpdates: Array<InternalOrderShipmentProviderUpdateDto>;
    status: InternalOrderShipmentDto.status;
    type: InternalOrderShipmentDto.type;
    packageWeight?: number | null;
    packageLength?: number | null;
    packageBreadth?: number | null;
    packageHeight?: number | null;
    requestedPickupDate?: string;
};

export namespace InternalOrderShipmentDto {

    export enum status {
        PENDING = 'pending',
        PROGRESS = 'progress',
        CREATED = 'created',
        FULFILLED = 'fulfilled',
        ERROR = 'error',
        CANCELLED = 'cancelled',
        RETURN_CREATED = 'returnCreated',
        RETURN_FULFILLED = 'returnFulfilled',
        RETURN_PROGRESS = 'returnProgress',
    }

    export enum type {
        DELIVERY = 'Delivery',
        RETURN = 'Return',
    }


}

