import StatusIcon from 'components/StatusIcon/StatusIcon';
import { VideoResponseDTO } from 'generated';
import './video-status-icon.scss';

export type Props = {
  status: VideoResponseDTO.status;
};

const VideoStatusIcon = ({ status }: Props) => {
  const iconMap = new Map();
  iconMap.set(VideoResponseDTO.status.DRAFT, 'error-plain');
  iconMap.set(VideoResponseDTO.status.LIVE, 'check');
  iconMap.set(VideoResponseDTO.status.IDLE, 'block');

  return (
    <StatusIcon
      className={`video-status-icon--${status}`}
      icon={iconMap.get(status)}
    />
  );
};

export default VideoStatusIcon;
