/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VideoVisibilityUpdateDTO = {
    videoIds: Array<number>;
    visibility: VideoVisibilityUpdateDTO.visibility;
};

export namespace VideoVisibilityUpdateDTO {

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

