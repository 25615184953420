import classNames from 'classnames';
import { IconType } from 'components/Icon/Icon';
import { Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import {
  GroupBase,
  InputActionMeta,
  Props as ReactSelectProps,
} from 'react-select';
import { CreatableProps } from 'react-select/creatable';
import randomString from 'utils/random-string';
import { BREAKPOINTS } from '../../global-constants';
import { useMediaQuery } from '../../hooks/useMediaquery';
import c from '../../utils/colors';
import ClearIndicator from './components/ClearIndicator';
import Control from './components/Control';
import DesktopSelect from './components/DesktopSelect';
import DropDownIndicator from './components/DropDownIndicator';
import InfiniteMenuList from './components/InfiniteMenuList';
import Menu from './components/Menu';
import MobileSelect from './components/MobileSelect';
import NativeMobileSelect from './components/NativeMobileSelect';
import Option, { DefaultOption } from './components/Option';
import './select-interactive.scss';
import getCustomStyles, { primaryColors } from './styles';
import SingleValue from './components/SingleValue';
import { ReactNode } from 'react';
import OpenSelect from './components/OpenSelect';

export type InfiniteScrollProps = {
  dataLength: number;
  next: () => void | undefined;
  hasMore: boolean;
};

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    title?: string;
    appearance?: 'light' | 'dark';
    /** needed for native select on mobile */
    label?: string;
    hiddenLabel?: boolean;
    error?: string;
    /** These props are needed to work with the InfiniteMenuList, that renders an InfiniteScroll component for the MenuList.*/
    infiniteScrollProps?: InfiniteScrollProps;
    /** If you have an outer scrollable container that should trigger the infiniteScroll add this Id to this container also. */
    infiniteScrollTargetId?: string;
    /**
     * Normally a Modal will open on mobile to show a bigger version of the select menu.
     * You can instead render a native select for mobile browsers, which is very user friendly.
     * This makes sense when you want to render a very simple single-select that is not searchable,
     * or doesn't need to be searchable on mobile.
     */
    showNativeSelectOnMobile?: boolean;
    /**
     * When a create button is added (when onCreateOption prop is present)
     * you can add a prefix to the button text.
     * e.g. #MyText with the prefix '#'.
     */
    createButtonPrefix?: string;
    /** On Desktop the hint will be rendered above the Select. 
    * On Mobile it will render at the bottom of the Modal.
    * Can be used to give hints to the user that are not errors,
    e.g. "the maximum number of selected items is reached".
    */
    hint?: string;
    description?: string;
    /** If provided, a clear button for the search input will be rendered. */
    onClearInputValue?: () => void;
    success?: string;
    /** Where to show error or success message */
    messagePosition?: 'bottom' | 'top';
    /** defaults to caret-down */
    indicatorIcon?: IconType;
    /** If select is always open (e.g. mobile inside a modal) some desktop styles need to be removed.
     * You don't need to set this from outside, as the variant=open will add it for you and render the OpenSelect */
    isOpenSelect?: boolean;
    /** If the label in the control should vary from the label of the option you can format the value yourself */
    formatValue?: (value: Option) => ReactNode;
    /** Default variant is a closed select, that opens on click. Open variant is a searchable select that is always open and not closable.
     * The open variant is internally used when opening a select in a modal on mobile, but if you want that style also on desktop, you need to change the variant to open.
     */
    variant?: 'open' | 'default';
    /** Maximum number of selectable options. */
    maxSelectedOptions?: number;
    /** When the maximum number of selected options is reached, this message is shown instead of the default. */
    maxSelectedOptionsMessage?: string;
  }
}

export type Props<
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
> = ReactSelectProps<OptionType, IsMulti, Group> &
  CreatableProps<OptionType, IsMulti, Group>;

const SelectInteractive = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, Group>,
) => {
  const {
    label,
    hiddenLabel,
    error,
    hint,
    description,
    success,
    messagePosition,
    variant = 'default',
  } = props;
  const errorId = error && `error--${props.id}`;
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const multiSelectProps = props.isMulti
    ? {
        controlShouldRenderValue: false,
        hideSelectedOptions: props.hideSelectedOptions || false,
      }
    : undefined;
  const selectId = props.id || `${props.name}--${randomString()}`;

  const customProps: ReactSelectProps<OptionType, IsMulti, Group> = {
    autoFocus: false,
    classNamePrefix: 'select-interactive',
    defaultInputValue: '',
    placeholder: props.placeholder || t('select'),
    loadingMessage: () => t('loading'),
    'aria-errormessage': errorId,
    onMenuClose: () => props.onInputChange?.('', {} as InputActionMeta),
    ...props,
    ...multiSelectProps,
    noOptionsMessage: () => props.noOptionsMessage || t('noOptions'),
    id: selectId,
    styles: {
      ...getCustomStyles<OptionType, IsMulti, Group>(),
      ...props.styles,
    },
    className: classNames(
      'select-interactive',
      props.appearance && `select-interactive--${props.appearance}`,
      props.components &&
        `select-interactive--${props.components.Option?.displayName}`,
      success && 'select-interactive--success',
      error && 'select-interactive--error',
      props.className,
    ),
    components: {
      DropdownIndicator: DropDownIndicator,
      Option,
      Menu,
      LoadingIndicator: (props) => <Loader {...props} small />,
      ClearIndicator,
      MenuList: InfiniteMenuList,
      Control,
      SingleValue,
      ...props.components,
    },
    theme: (theme) => ({
      ...theme,
      borderRadius: parseInt(c('--border-radius-default')),
      colors: {
        ...theme.colors,
        ...primaryColors(),
        danger: c('--color-pink'),
        dangerLight: c('--color-pink-light'),
      },
    }),
  };

  if (!isDesktop && props.showNativeSelectOnMobile) {
    return <NativeMobileSelect {...props} />;
  }

  if (variant === 'open') {
    return <OpenSelect {...customProps} />;
  }

  return (
    <div
      className={classNames(
        'select',
        error && 'select--error',
        success && 'select--success',
        props.appearance && `select--${props.appearance}`,
        messagePosition && `input--msg-${messagePosition}`,
        props.className,
      )}
    >
      {label && !hiddenLabel && (
        <label className="select__label" htmlFor={selectId}>
          {label}
        </label>
      )}
      {description && (
        <Spacer
          tag="p"
          marginBottom={2}
          marginTop={1}
          className="small select-interactive__description"
        >
          {description}
        </Spacer>
      )}
      {hint && !error && isDesktop && (
        <Spacer tag="p" className="select-interactive__hint" marginBottom={2}>
          {hint}
        </Spacer>
      )}
      {error && (
        <div role="alert" id={errorId} className="input__error">
          {error}
        </div>
      )}
      {success && (
        <div role="alert" className="input__error input__success">
          {success}
        </div>
      )}
      {isDesktop ? (
        <DesktopSelect {...customProps} />
      ) : (
        <MobileSelect {...customProps} />
      )}
    </div>
  );
};

export default SelectInteractive;
