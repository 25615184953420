import classNames from 'classnames';
import Button from 'components/Button/Button';
import Headline from 'components/Headline/Headline';
import Icon from 'components/Icon/Icon';
import IconButton from 'components/IconButton/IconButton';
import TextAreaField from 'components/Input/TextAreaField';
import LazyImage from 'components/LazyImage/LazyImage';
import Modal from 'components/Modal/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import ImageUploadContainer from 'container/ImageUploadContainer/ImageUploadContainer';
import ChannelActivateContainer from 'features/channel-activate';
import { Field, getIn, useFormikContext } from 'formik';
import { AvailabilityDTO } from 'generated/models/AvailabilityDTO';
import { maxImageSize } from 'global-constants';
import useAvailability from 'hooks/useAvailability';
import placeholder from 'images/placeholder-channel.svg';
import placeholderImage from 'images/placeholder-product.svg';
import { useEffect, useState } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import 'styles/layout/_tooltip.scss';
import ChannelType from 'types/ChannelType';
import Color, { ColorUse } from 'types/Color';
import './channel-header.scss';

export interface Props {
  onUpdateIconUrl: (
    values: ChannelType,
    paths: { fileUrl?: string | null; filePath?: string | null },
  ) => void;
  edit?: boolean;
  onEdit?: () => void;
  onUpdateItem: (availability?: AvailabilityDTO) => void;
}

const ChannelHeader = ({
  onUpdateIconUrl,
  edit,
  onEdit,
  onUpdateItem,
}: Props) => {
  const { t } = useTranslation(['translation', 'channel']);
  const [isOpenM, setIsOpenM] = useState(false);
  const [isOpenI, setIsOpenI] = useState(false);
  const { values, setFieldValue, errors, touched } =
    useFormikContext<ChannelType>();
  const [iconUrl, setIconUrl] = useState<string | undefined | null>(
    values.iconUrl,
  );
  const [paths, setPaths] = useState<{
    fileUrl?: string | null;
    filePath?: string | null;
  }>({
    fileUrl: values.iconUrl,
    filePath: values.iconPath,
  });
  const { available } = useAvailability();
  const errIconUrl = getIn(errors, 'iconUrl');
  const touIconUrl = getIn(touched, 'iconUrl');

  useEffect(() => {
    setIconUrl(values.iconUrl);
  }, [values.iconUrl]);

  const handleUpdateIconUrl = () => {
    setIsOpenI(false);
    setFieldValue('iconUrl', paths.fileUrl);
    setFieldValue('iconPath', paths.filePath);
    onUpdateIconUrl(values, {
      fileUrl: paths.fileUrl,
      filePath: paths.filePath,
    });
  };

  return (
    <div
      className={classNames('channel-header', edit && 'channel-header--edit')}
    >
      <Tooltip
        content={t('channel:icon.add')}
        direction="down"
        isOpen={edit ? undefined : false}
      >
        <button
          className={classNames(
            'channel-header__image',
            errIconUrl && touIconUrl && 'channel-header__image--error',
          )}
          type={edit ? 'button' : undefined}
          onClick={edit ? () => setIsOpenI(true) : undefined}
        >
          {iconUrl && (
            <LazyImage
              src={iconUrl || placeholder || placeholderImage}
              lqip={placeholder || placeholderImage}
              aspectRatio={1}
              alt={t('channel:icon.logoAlt')}
            />
          )}
          {edit && (
            <div className="channel-header__overlay">
              <Icon icon="image" />
            </div>
          )}
        </button>
      </Tooltip>

      <Modal
        isOpen={isOpenI}
        headline={t('channel:icon.label')}
        onClose={() => setIsOpenI(false)}
      >
        <ImageUploadContainer
          text={t('channel:icon.text')}
          value={iconUrl === placeholder ? undefined : iconUrl || undefined}
          onChange={setPaths}
          onDelete={() => setIconUrl('')}
          maxDimensions={{ width: 400, height: 400 }}
          maxFileSize={maxImageSize}
          // ratio={{ width: 1, height: 1 }}
          description={t('channel:imageUploadDesc')}
        />
        <div className="modal__controls">
          <Button
            text={t('cancel')}
            type="button"
            onClick={() => setIsOpenI(false)}
            color={Color.primary}
            appearance="ghost"
          />
          <Button
            text={t('save')}
            type="button"
            onClick={handleUpdateIconUrl}
            color={Color.primary}
          />
        </div>
      </Modal>

      {edit ? (
        <Field
          component={TextAreaField}
          placeholder={t('channel:placeholder.title')}
          className="channel-header__headline"
          name="name"
          big
        />
      ) : (
        <>
          <Headline headingLevel="h1" className="channel-header__headline">
            {values.name || t('no_title')}
          </Headline>

          <div className="channel-header__edit">
            <Flipped inverseFlipId="pageCollapse" translate>
              <IconButton
                icon="edit"
                onClick={onEdit}
                tooltip={t('channel:editInfo')}
                color={ColorUse.dark}
              />
            </Flipped>
          </div>
        </>
      )}

      {!edit && (
        <div
          className={classNames(
            'channel-header__av',
            available && 'channel-header__av--active',
          )}
        >
          <IconButton
            icon={available ? 'check' : 'block'}
            color={available ? Color.confirm : Color.warning}
            appearance={'filled'}
            tooltip={t('channel:available.change')}
            onClick={() => setIsOpenM(true)}
            big
          />

          <Modal
            isOpen={isOpenM}
            onClose={() => setIsOpenM(false)}
            variant="wide"
            headline={t('channel:status.caption')}
          >
            <div className="modal__text">{t('channel:status.description')}</div>
            <ChannelActivateContainer
              onClose={() => setIsOpenM(false)}
              channelId={values.id}
              availability={values.availability}
            />
          </Modal>

          <span className="channel-header__av-text">
            {available
              ? t('channel:available.active')
              : t('channel:available.deactive')}
          </span>
        </div>
      )}

      {errIconUrl && touIconUrl && (
        <div className="channel-header__image-error">{errIconUrl}</div>
      )}
    </div>
  );
};

export default ChannelHeader;
