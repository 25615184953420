import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  ProductTaxDto,
  ProductTaxResponseDto,
  ProductsService,
} from 'generated';
import { queryClient } from 'index';

export const productTaxesKeys = {
  useGetProductTaxes: (productId: number) => [productId],
};

export const useGetProductTaxes = (productId: number) => {
  return useQuery({
    queryKey: productTaxesKeys.useGetProductTaxes(productId),
    queryFn: () => ProductsService.getProductTaxes(productId),
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateProductTaxes = () => {
  return useMutation<
    ProductTaxResponseDto,
    ApiError,
    { productId: number; requestBody: ProductTaxDto }
  >({
    mutationFn: ({ productId, requestBody }) =>
      ProductsService.updateProductTaxes(productId, requestBody),
    onSuccess: (data, { productId }) => {
      queryClient.setQueryData(
        productTaxesKeys.useGetProductTaxes(productId),
        () => data,
      );
    },
  });
};
