/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TypeFilterResponseDto = {
    type: TypeFilterResponseDto.type;
};

export namespace TypeFilterResponseDto {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }


}

