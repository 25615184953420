import { ReactElement } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useLocation } from 'react-router-dom';
import { animateElementIn, animateElementOut } from 'utils/animate';
import './animated-route.scss';

/** Wrap this around nested route Outlets to animate the transitions between child routes.
 * Use a real HTML Element as its only child, Fragments will not animate.
 * It will also not animate if you put a custom component as a child.
 */
const AnimatedRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  return (
    // <Flipped
    //   // key={location.pathname}
    //   flipId={Date.now()}
    //   onAppear={animateElementIn}
    //   onExit={animateElementOut}
    // >
    <div className="animated-route__flipped-child">{children}</div>
    // </Flipped>
  );
};

export default AnimatedRoute;
