import { useQuery } from '@tanstack/react-query';
import { channelListKeys } from 'features/channel-list/queries';
import { ApiError, ChannelDTO, ChannelsService } from 'generated';

export const channelDetailKeys = {
  getChannel: (channelId: number, vendorAgentId?: number) => [
    ...channelListKeys.getChannels(vendorAgentId),
    channelId,
  ],
};

export const useGetChannel = (channelId: number) => {
  return useQuery<ChannelDTO, ApiError>({
    queryKey: channelDetailKeys.getChannel(channelId),
    queryFn: () => ChannelsService.getChannel(channelId),
  });
};
