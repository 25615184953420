import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useReduxSelector } from '../../redux/hooks';
import {
  selectIsSalesAgent,
  selectVendorAgent,
} from '../../redux/slices/auth/authSlice';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const location = useLocation();
  const isSalesAgent = useReduxSelector(selectIsSalesAgent);

  if (!vendorAgent) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isSalesAgent && !location.pathname.startsWith('/settings')) {
    return <Navigate to="/settings" />;
  }

  return children;
};

export default ProtectedRoute;
