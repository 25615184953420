import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import IconLink from 'components/IconLink/IconLink';
import { FlexBox, Spacer } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import { StepChildProps, StepKeys } from '../VideoType';

const TypeProdcutSuccess = ({ videoId, setStep }: StepChildProps) => {
  const { t } = useTranslation(['translation', 'video']);
  const navigate = useNavigate();
  const handleSkip = () => {
    navigate(`/videos/${videoId}`);
  };

  return (
    <div>
      <Chip role="alert" color={ColorUse['confirm-50']}>
        {t('video:type.success')}
      </Chip>
      <Spacer tag="p" margin={[2, 0, 4]} className="c-dark-50 small">
        {t('video:type.product.success')}
      </Spacer>

      <label className="select__label" htmlFor={`button--${videoId}`}>
        {t('productTitle')}
      </label>
      <button
        id={`button--${videoId}`}
        type="button"
        className={classNames(
          'select__select',
          'select-interactive__fake-select',
          'select__wrap',
        )}
        onClick={() => setStep(StepKeys.SET_PRODUCT)}
      >
        <span className="select-interactive__fake-text">{t('select')}</span>
      </button>

      <FlexBox justifyContent="flex-end" marginTop={4}>
        <IconLink icon="arrow-right" onClick={handleSkip}>
          {t('video:type.skip')}
        </IconLink>
      </FlexBox>
    </div>
  );
};

export default TypeProdcutSuccess;

// t('video:type.channel.desc');
// t('video:type.product.desc');
// t('video:type.promotion.desc');
// t('video:type.channel.label');
// t('video:type.product.label');
// t('video:type.promotion.label');
