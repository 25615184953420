import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import Chip from 'components/Chip/Chip';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import List from 'components/List/List';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { isAvailable } from 'features/availability';
import { useGetMinimalChannels } from 'features/channel-list';
import { useUpdateVideoChannelsWithContext } from 'features/video-channels';
import { useGetVideo } from 'features/video-detail';
import { useUpdateVideoType } from 'features/video-type';
import {
  ChannelMinimalResponseDTO,
  UpdateVideoTypeConflictResponseDTO,
  VideoResponseDTO,
} from 'generated';
import { useListSelection } from 'hooks/useListSelection';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import { StepChildProps, StepKeys } from '../VideoType';
import ListItemSelectChannel from 'components/ListItemSelectChannel/ListItemSelectChannel';

export type ChannelWithConflict = ChannelMinimalResponseDTO & {
  conflict?: boolean;
};

const TypeIntroError = ({
  videoId,
  errors,
  channelTransfer,
  setStep,
}: StepChildProps) => {
  const { t } = useTranslation(['translation', 'video']);
  const navigate = useNavigate();
  const { data, isLoading } = useGetMinimalChannels();
  const { data: video } = useGetVideo(videoId);
  const [channels, setChannels] = useState<ChannelWithConflict[]>();
  const { selectedIds, toggleSelectItem, setSelectedItems } = useListSelection(
    [],
  );
  const [selectAll, setSelectAll] = useState(false);
  const channelMutation = useUpdateVideoChannelsWithContext();
  const typeMutation = useUpdateVideoType();

  useEffect(() => {
    const hasError = errors[StepKeys.INTRO_ERROR];

    if (hasError?.status === 409) {
      const conflict = hasError.body as UpdateVideoTypeConflictResponseDTO;
      const conflictedChannels =
        data
          ?.filter((c) => conflict.channelIds.includes(c.id))
          .map((i) => ({ ...i, conflict: true })) || [];

      // add transferred channels from StepKeys.SET_CHANNELS to the list and remove duplicates
      const assembleChannels = [
        ...(video?.channels || []),
        ...(channelTransfer[StepKeys.INTRO_ERROR] || []),
      ].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id),
      );
      const nonConflictedChannels =
        assembleChannels.filter((i) => !conflict.channelIds.includes(i.id)) ||
        [];

      setSelectedItems(nonConflictedChannels.map((i) => i.id));
      setChannels([...conflictedChannels, ...nonConflictedChannels]);
    } else {
      setStep(StepKeys.DEFAULT_ERROR);
    }
  }, [errors, data]);

  useEffect(() => {
    setSelectAll(selectedIds.length === channels?.length);
  }, [selectedIds]);

  const handleSelectAll = () => {
    setSelectedItems(!selectAll ? channels?.map((i) => i.id) || [] : []);
  };

  const handleOverwrite = () => {
    const onSuccesMutateWithForce = async () => {
      // When coming from StepKeys.SET_CHANNELS this is already a channel type
      if (video?.type !== VideoResponseDTO.type.CHANNEL) {
        typeMutation.mutate(
          {
            videoId,
            type: VideoResponseDTO.type.CHANNEL,
            force: true,
          },
          {
            onSuccess: () => {
              navigate(`/videos/${videoId}`, { state: { from: 'type' } });
            },
          },
        );
      } else {
        navigate(`/videos/${videoId}`, { state: { from: 'type' } });
      }
    };

    // If we do not keep all channels, we have to unlink the video from the not wanted channels first
    const hasChannelsToUnlink = channels?.length !== selectedIds.length;
    // If we get here from STEP_KEYS.SET_CHANNELS, the video needs to be connected to the selected channels first
    const hasChannelsToLink = !selectedIds.every((elem) =>
      video?.channels?.map((i) => i.id).includes(elem),
    );

    if (hasChannelsToUnlink || hasChannelsToLink) {
      channelMutation.mutate(
        {
          videoId,
          channels: channels?.filter((i) => selectedIds.includes(i.id)),
          force: hasChannelsToLink,
        },
        {
          onSuccess: onSuccesMutateWithForce,
        },
      );
    } else {
      onSuccesMutateWithForce();
    }
  };

  const handleConfirmError = () => {
    navigate(`/videos/${videoId}`, { state: { from: 'type', error: true } });
  };

  if (typeMutation.isError || channelMutation.isError) {
    return (
      <>
        <Chip role="alert" color={ColorUse['warning-50']}>
          {t('inline-edit.error')}
        </Chip>
        <Spacer tag="p" margin={[2, 0, 8]} className="c-dark-50 small">
          {t('video:type.overwrite-error')}
        </Spacer>

        <Spacer tag="footer" marginTop={4} className="video-type__footer">
          <FlexBox justifyContent="space-between">
            <FlexItem pushRight>
              <Button text={t('confirm')} onClick={handleConfirmError} />
            </FlexItem>
          </FlexBox>
        </Spacer>
      </>
    );
  }

  return (
    <FlexBox flexDirection="column">
      <FlexItem>
        <Chip role="alert" color={ColorUse['warning-50']}>
          {t('video:type.overwrite')}
        </Chip>
      </FlexItem>
      <Spacer tag="p" margin={[2, 0, 8]} className="c-dark-50 small">
        {t('video:type.channel.error')}
      </Spacer>

      {!typeMutation.isError && (
        <div className="video-type__select-list">
          <Spacer className="video-type__select-header" borderBottom={1}>
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              name="select-all-channels-to-overwrite"
              label={
                <span className="c-dark-50 small">
                  {t('video:type.channel-select', {
                    count: selectedIds.length,
                  })}
                </span>
              }
              wrapperClassName="video-type__select-all"
            />
          </Spacer>
          <List loading={isLoading}>
            {channels?.map(({ id, name, ...rest }) => (
              <ListItemSelectChannel
                key={id}
                selected={selectedIds.includes(id) || false}
                name={`${id}--${name}`}
                id={`${id}--${name}`}
                onChange={() => toggleSelectItem(id)}
                channel={{ id, name, ...rest }}
              />
            ))}
          </List>
        </div>
      )}
      <FlexItem tag="footer" className="video-type__footer" pushBottom>
        <FlexBox justifyContent="space-between">
          <Button
            text={t('cancel')}
            onClick={() => navigate(`/videos/${videoId}`)}
            appearance="ghost"
          />
          <Button
            text={t('save')}
            onClick={handleOverwrite}
            loading={channelMutation.isLoading || typeMutation.isLoading}
          />
        </FlexBox>
      </FlexItem>
    </FlexBox>
  );
};

export default TypeIntroError;
