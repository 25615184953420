/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VisibilityFilterResponseDto = {
    visibility: VisibilityFilterResponseDto.visibility;
};

export namespace VisibilityFilterResponseDto {

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

