/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StatusFilterResponseDto = {
    status: StatusFilterResponseDto.status;
};

export namespace StatusFilterResponseDto {

    export enum status {
        DRAFT = 'draft',
        LIVE = 'live',
        IDLE = 'idle',
    }


}

