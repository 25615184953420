import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';

const ProductCreateButton = () => {
  const { t } = useTranslation(['translation', 'product']);

  return (
    <FlexBox className="product-option__create" alignItems="center">
      <FlexItem flexGrow={1} className="product-option__content">
        <div className="list-item__title">{t('product:create.title')}</div>
        <div className="list-item__subtitle">
          {t('product:create.subtitle')}
        </div>
      </FlexItem>

      <FlexItem
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        flexShrink={0}
      >
        <FlexItem
          display="inline-flex"
          className="select-interactive__add-button"
          alignItems="center"
          pushRight
        >
          <span>{t('add')}</span>
          <Icon icon="add-filled" />
        </FlexItem>
      </FlexItem>
    </FlexBox>
  );
};

export default ProductCreateButton;
