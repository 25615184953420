import { AvailabilityDTO } from 'generated';
import * as Yup from 'yup';
import parseDateString from 'utils/parse-date-string';
import { TFunction } from 'i18next';
import { utcToZonedTime } from 'date-fns-tz';

export const initialAvailabilityValues = (
  availability?: AvailabilityDTO,
): { availability: AvailabilityDTO } => {
  return {
    availability: availability
      ? {
          type: availability.type,
          active: availability.active,
          start: availability?.start ? availability.start : undefined,
          end: availability?.end ? availability.end : undefined,
        }
      : {
          type: AvailabilityDTO.type.PERMANENT,
          active: false,
          start: undefined,
          end: undefined,
        },
  };
};

export const mapAvailabilityValues = (availability: AvailabilityDTO) => {
  const startDate =
    availability?.start && availability?.type !== AvailabilityDTO.type.PERMANENT
      ? new Date(availability.start).toISOString()
      : undefined;
  const endDate =
    availability?.end && availability?.type !== AvailabilityDTO.type.PERMANENT
      ? new Date(availability.end).toISOString()
      : undefined;

  const newAvailability = availability
    ? {
        ...availability,
        start: availability?.start && startDate,
        end: availability?.end && endDate,
      }
    : undefined;
  return newAvailability;
};

export const validateAvailability = (errorMessage: string) =>
  Yup.object().shape(
    {
      start: Yup.date()
        .transform(parseDateString)
        .nullable()
        .default(undefined),
      end: Yup.date()
        .transform(parseDateString)
        .when(
          'start',
          (start: Date, schema: Yup.DateSchema) =>
            start && schema.min(start, errorMessage),
        )
        .nullable()
        .default(undefined),
    },
    [['end', 'start']],
  );

export const isAvailable = (availability?: AvailabilityDTO | null) => {
  if (
    availability?.type === AvailabilityDTO.type.PERMANENT &&
    availability.active === true
  ) {
    return true;
  }
  if (availability?.type === AvailabilityDTO.type.DURATION && availability) {
    const endDate = availability.end ? new Date(availability.end) : null;
    const startDate = availability.start ? new Date(availability.start) : null;
    const today = new Date();
    const endDateHasPassed = endDate
      ? today.getTime() >= endDate.getTime()
      : false;
    const startDateHasPassed = startDate
      ? today.getTime() >= startDate.getTime()
      : true;
    return !endDateHasPassed && startDateHasPassed;
  }
  return false;
};

export const getAvailabilityAsText = (
  availability: AvailabilityDTO | null,
  t: TFunction,
  timeZone: string,
) => {
  const avText = isAvailable(availability)
    ? t('channel:available.active')
    : t('channel:available.deactive');
  const startDate = availability?.start
    ? t('date', {
        date:
          availability?.start && utcToZonedTime(availability?.start, timeZone),
      })
    : undefined;
  const endDate = availability?.end
    ? t('date', {
        date: availability?.end && utcToZonedTime(availability?.end, timeZone),
      })
    : undefined;
  const startText = !availability?.end
    ? `${t('from')} ${startDate}`
    : `${startDate} - `;
  const endText = !availability?.start ? `${t('to')} ${endDate}` : endDate;

  const dateString = `${t('channel:available.active')} ${
    startDate ? startText : ''
  } ${endDate ? endText : ''}`;

  return startDate || endDate ? dateString : avText;
};

export default initialAvailabilityValues;
