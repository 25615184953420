import { EntityId } from '@reduxjs/toolkit';
import { useState } from 'react';
import { TFunction } from 'react-i18next';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import { selectLocale } from '../../../redux/slices/i18n/i18nSlice';
import {
  deleteNotification,
  prepareCopy,
  prepareEdit,
  selectNotificationById,
} from '../../../redux/slices/notifications/notificationSlice';
import NotificationListItem from 'components/NotificationListItem/NotificationListItem';

const NotificationListItemContainer = ({
  notificationId,
  t,
}: {
  notificationId: EntityId;
  t: TFunction<string[]>;
}) => {
  const dispatch = useReduxDispatch();
  const notification = useReduxSelector((state) =>
    selectNotificationById(state, notificationId),
  );
  const status = useReduxSelector((state) => state.notifications.status);
  const locale = useReduxSelector(selectLocale);
  const [deleteNotificationModal, setDeleteNotificationModal] = useState(false);

  const { setAppStatus } = useAppStatus();

  const handleDeleteNotificationClick = async () => {
    setDeleteNotificationModal(true);
  };

  const handleDeleteNotification = async () => {
    if (!notification) {
      return;
    }

    try {
      const deletedNotification = await dispatch(
        deleteNotification(notification.id),
      ).unwrap();

      setAppStatus(
        t('settings:success.notification_remove', {
          title: deletedNotification.title,
        }),
        'success',
        true,
      );
    } catch (error) {
      setAppStatus(t('settings:error.notification_remove'), 'error', true);
    }
  };

  const handleEditNotificationClick = async () => {
    if (!notification) {
      return;
    }

    dispatch(prepareEdit(notification));
  };

  const handleCopyNotificationClick = async () => {
    if (!notification) {
      return;
    }

    dispatch(prepareCopy(notification));
  };

  return notification ? (
    <>
      <NotificationListItem
        locale={locale}
        notification={notification}
        onCopyClick={handleCopyNotificationClick}
        onDeleteClick={handleDeleteNotificationClick}
        onEditClick={handleEditNotificationClick}
        statusTextPending={t('settings:notifications.statusTextPending')}
        statusTextSent={t('settings:notifications.statusTextSent')}
      />

      {deleteNotificationModal && (
        <ConfirmModal
          headline={t('settings:really_delete')}
          confirmText={t('delete')}
          isOpen={deleteNotificationModal}
          onCancelClick={() => setDeleteNotificationModal(false)}
          text={t('settings:really_delete_notification', {
            title: notification.title,
          })}
          onConfirmClick={() =>
            handleDeleteNotification().then(() =>
              setDeleteNotificationModal(false),
            )
          }
          confirmLoading={status === 'loading-delete'}
        />
      )}
    </>
  ) : null;
};

export default NotificationListItemContainer;
