import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Headline from '../../../components/Headline/Headline';
import Tabs from '../../../components/Tabs/Tabs';
import { LandingPageSettingsService } from '../../../generated';
import { useReduxSelector } from '../../../redux/hooks';
import { selectSupportedLanguagesTabs } from '../../../redux/slices/i18n/i18nSlice';
import './landing-page.scss';
import LandingPageSettingsFormContainer from './LandingPageSettingsFormContainer';

const LandingPageContainer = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const tabs = useReduxSelector(selectSupportedLanguagesTabs);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tab);

  const { data: landingPageSettings } = useQuery(
    ['getLandingPageSettings'],
    LandingPageSettingsService.getLandingpageSettings,
  );

  const activeSetting = landingPageSettings?.find(
    (setting) => setting.languageIso === activeTab,
  );

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.landing-page')}
      </Headline>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab)}
      />

      <LandingPageSettingsFormContainer
        activeSetting={activeSetting}
        languageIso={activeTab}
      />
    </div>
  );
};

export default LandingPageContainer;
