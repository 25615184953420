import { useTranslation } from 'react-i18next';
import Headline from '../../../components/Headline/Headline';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxDispatch } from '../../../redux/hooks';
import { addVendor } from '../../../redux/slices/vendors/vendorsSlice';
import AddVendorForm, { AddVendorFormValues } from './AddVendorForm';

const AddVendorContainer = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const dispatch = useReduxDispatch();
  const { setAppStatus } = useAppStatus();

  const handleNewVendorSubmit = async (
    values: AddVendorFormValues,
    callback: Function,
  ) => {
    try {
      await dispatch(addVendor(values)).unwrap();

      setAppStatus(t('settings:success.add_vendor'), 'success', true);

      callback();
    } catch (error) {
      setAppStatus(t('settings:error.add_vendor'), 'error', true);
    }
  };

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.add-vendor')}
      </Headline>

      <AddVendorForm onSubmit={handleNewVendorSubmit} />
    </>
  );
};

export default AddVendorContainer;
