import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Field, FieldProps } from 'formik';
import Categories, { Props } from './Categories';
import { CategoriesResponseDto } from 'generated';

const CategoriesFieldWrap = ({
  field,
  form: { setFieldValue },
  ...props
}: Props & FieldProps) => {
  const onChange = (newValue?: CategoriesResponseDto[]) => {
    setFieldValue(field.name, newValue);
    props.onSelectChange?.(newValue || []);
  };

  return (
    <ErrorBoundary>
      <Categories {...field} {...props} onSelectChange={onChange} />
    </ErrorBoundary>
  );
};

/**
 * This component is a Formik field, that controls the selection from a recursive tree such as categories.
 * Put this component as the component prop in a Formik Field.
 * This only works with a Formik wrapper, that populates this field with a context.
 */
const CategoriesField = ({ name }: { name?: string }) => {
  return (
    <Field
      component={CategoriesFieldWrap}
      name={name || 'categories'}
      scrollTargetId={`scrolltarget--${name || 'categories'}`}
    />
  );
};

export default CategoriesField;
