import classNames from 'classnames';
import { ElementType } from 'react';

export interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  gridColumn?: string;
  gridRow?: string;
  gridArea?: string;
  justifySelf?: 'start' | 'end' | 'center' | 'stretch';
  alignSelf?: 'start' | 'end' | 'center' | 'stretch';
  tag?: ElementType;
}
const GridItem = ({
  tag: Tag = 'div',
  children,
  className,
  style,
  gridColumn,
  gridRow,
  gridArea,
  justifySelf,
  alignSelf,
  ...props
}: GridItemProps) => {
  return (
    <Tag
      {...props}
      className={classNames(
        'grid__item',
        justifySelf && `grid__item--justify-self-${justifySelf}`,
        alignSelf && `grid__item--align-self-${alignSelf}`,
        className,
      )}
      style={{
        ...style,
        gridArea,
        gridColumn,
        gridRow,
      }}
    >
      {children}
    </Tag>
  );
};

export default GridItem;
