/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReferralCreateDTO = {
    referralLinkType: ReferralCreateDTO.referralLinkType;
    referralLinkObjectKey?: string;
    campaignName: string;
    term?: string;
    content?: string;
    source?: string;
    medium?: string;
    category?: string;
    landingPageTitle?: string;
    landingPageParagraph?: string;
    landingPageImage?: string;
    landingPageLanguageIso?: string;
};

export namespace ReferralCreateDTO {

    export enum referralLinkType {
        CHANNEL = 'channel',
        PRODUCT = 'product',
        APP = 'app',
        VIDEOLIST = 'videolist',
    }


}

