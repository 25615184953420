import React from 'react';
import { GroupBase, OptionProps } from 'react-select';
import Option, { DefaultOption } from './Option';
import { Icon as IconType } from '../../Icon/icon-spec';
import Icon from '../../Icon/Icon';

export interface OptionWithNumberAndIconProps extends DefaultOption {
  icon?: IconType | React.ReactNode;
  number?: number;
}

export const OptionWithNumberAndIcon = <
  OptionType extends OptionWithNumberAndIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, Group>,
) => {
  const { data } = optionProps;
  return (
    <Option
      className="select-interactive__option-number-and-icon"
      rightZone={
        data.number && (
          <span className="select-interactive__number">
            {data.number.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        )
      }
      leftZone={
        data.icon && (
          <span className="select-interactive__option-icon">
            {typeof data.icon === 'string' ? (
              <Icon icon={data.icon as IconType} />
            ) : (
              data.icon
            )}
          </span>
        )
      }
      {...optionProps}
    />
  );
};

export default OptionWithNumberAndIcon;
