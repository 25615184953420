import classNames from 'classnames';
import QueryList from 'components/List/QueryList';
import { useGetVideos } from 'features/video-list/queries';
import { Fragment, Key } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Grid from '../components/Grid/Grid';
import GridItem from '../components/Grid/GridItem';
import Headline from '../components/Headline/Headline';
import useAppStatus from '../hooks/useAppStatus';
import '../styles/layout/_page.scss';

const VideosPage = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { appStatus } = useAppStatus();
  const videoQuery = useGetVideos();
  const { data } = videoQuery;

  return (
    <div
      className={classNames('page', 'page--video', appStatus && 'page--status')}
    >
      <Grid>
        <GridItem className="page__header" templateArea="header">
          <Breadcrumbs />

          <Headline headingLevel="h1" size={2} className="page__title">
            {t('video:title')}
          </Headline>

          <QueryList {...videoQuery}>
            {data?.pages.map((page, i) => (
              <Fragment key={(page.links?.next?.offset || i) as Key}>
                {page.data.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor:
                        item.status === 'draft'
                          ? 'tomato'
                          : item.status === 'live'
                          ? 'teal'
                          : 'yellow',
                    }}
                  >
                    {item.id}: {item.name} - {item.status}
                  </div>
                ))}
              </Fragment>
            ))}
          </QueryList>
        </GridItem>
      </Grid>
    </div>
  );
};
export default VideosPage;
