import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  UtmMediumResponseDTO,
  UtmService,
  UtmSourceResponseDTO,
} from '../../../generated';
import { startAppListening } from '../../middlewares/listenerMiddleware';
import { RootState } from '../../store';
import { init, logIn } from '../auth/authSlice';

type Status = 'not-fetched' | 'fetched';

export interface UtmState {
  mediums: UtmMediumResponseDTO[];
  sources: UtmSourceResponseDTO[];
  status: Status;
}

const SLICE_NAME = 'utm';

const initialState: UtmState = {
  mediums: [],
  sources: [],
  status: 'not-fetched',
};

export const fetchUtmData = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await UtmService.getUtmData();
      return response;
    } catch (error) {
      throw rejectWithValue('error fetching utm data');
    }
  },
);

export const updateUtmSource = createAsyncThunk(
  `${SLICE_NAME}/updateUtmSource`,
  async (
    {
      sourceId,
      newMediumIds,
    }: {
      sourceId: number;
      newMediumIds: number[];
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await UtmService.updateSource(sourceId, {
        mediumIds: newMediumIds,
      });

      return response;
    } catch (error) {
      throw rejectWithValue('error updating utm source');
    }
  },
);

export const addUtmSource = createAsyncThunk(
  `${SLICE_NAME}/addUtmSource`,
  async (name: string, { rejectWithValue }) => {
    try {
      const source = await UtmService.addUtmSource({
        name,
      });

      return source;
    } catch (error) {
      throw rejectWithValue('error adding utm source');
    }
  },
);

export const addUtmMedium = createAsyncThunk(
  `${SLICE_NAME}/addUtmMedium`,
  async (name: string, { rejectWithValue }) => {
    try {
      const medium = await UtmService.addUtmMedium({
        name,
      });

      return medium;
    } catch (error) {
      throw rejectWithValue('error adding utm medium');
    }
  },
);

export const deleteUtmMedium = createAsyncThunk(
  `${SLICE_NAME}/deleteUtmMedium`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await UtmService.deleteMedium(id);

      return response;
    } catch (error) {
      throw rejectWithValue('error deleting utm medium');
    }
  },
);

export const deleteUtmSource = createAsyncThunk(
  `${SLICE_NAME}/deleteUtmSource`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await UtmService.deleteSource(id);

      return response;
    } catch (error) {
      throw rejectWithValue('error deleting utm source');
    }
  },
);

export const utmSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUtmData.fulfilled, (state, action) => {
        state.mediums = action.payload.mediums;
        state.sources = action.payload.sources;
        state.status = 'fetched';
      })
      .addCase(updateUtmSource.fulfilled, (state, action) => {
        state.mediums = action.payload.mediums;
        state.sources = action.payload.sources;
      })
      .addCase(addUtmSource.fulfilled, (state, action) => {
        state.sources.push(action.payload);
      })
      .addCase(addUtmMedium.fulfilled, (state, action) => {
        state.mediums.push(action.payload);
      })
      .addCase(deleteUtmMedium.fulfilled, (state, action) => {
        state.mediums = action.payload.mediums;
        state.sources = action.payload.sources;
      })
      .addCase(deleteUtmSource.fulfilled, (state, action) => {
        state.mediums = action.payload.mediums;
        state.sources = action.payload.sources;
      });
  },
});

// Create the middleware instance and methods

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
startAppListening({
  matcher: isAnyOf(logIn.fulfilled, init.fulfilled),
  effect: async (_, { dispatch, getState }) => {
    if (getState().utm.status === 'not-fetched') {
      dispatch(fetchUtmData());
    }
  },
});

export const selectUtmSources = (state: RootState) => state.utm.sources;
export const selectUtmMediums = (state: RootState) => state.utm.mediums;

// Action creators are generated for each case reducer function
export const {} = utmSlice.actions;

export default utmSlice.reducer;
