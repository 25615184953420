/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VideoItemDTO = {
    streamType: VideoItemDTO.streamType;
    codec: VideoItemDTO.codec;
    url: string;
};

export namespace VideoItemDTO {

    export enum streamType {
        HLS = 'hls',
        HDS = 'hds',
        DASH = 'dash',
        SMOOTHSTREAMING = 'smoothstreaming',
        MP4_HIGH = 'mp4_high',
        MP4_MEDIUM = 'mp4_medium',
        MP4_LOW = 'mp4_low',
    }

    export enum codec {
        H264 = 'h264',
        H265 = 'h265',
    }


}

