/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BankingDetailDTO } from './BankingDetailDTO';

export type InternalVendorDto = {
    createdAt?: string;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    deletedDate?: string | null;
    id: number;
    threeQProjectId?: number | null;
    bankingDetail?: BankingDetailDTO | null;
    company: string;
    type?: InternalVendorDto.type;
};

export namespace InternalVendorDto {

    export enum type {
        DEFAULT = 'default',
        SALES = 'sales',
    }


}

