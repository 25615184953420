import QueryList from 'components/List/QueryList';
import Loader from 'components/Loader/Loader';
import VideoCard from 'components/VideoCard/VideoCard';
import {
  getFilterQueryFromFilter,
  useVideoFilter,
} from 'features/video-filter';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useSidebar from 'hooks/useSidebar';
import React, { Key, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetVideos } from '../queries';
import VideoTicketItem from './VideoTicketItem';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import IconButton from 'components/IconButton/IconButton';
import Color from 'types/Color';
import { useTranslation } from 'react-i18next';
import { Spacer } from 'components/Layout';

const VideoData = () => {
  const { t } = useTranslation(['video']);
  const { filter, resetFilter } = useVideoFilter();
  const getVideosQuery = useGetVideos(getFilterQueryFromFilter(filter));
  const { status, data, isFetching } = getVideosQuery;
  const navigate = useNavigate();
  const { openSidebar } = useSidebar();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const handleOpenVideo = (video: VideoResponseDTO) => {
    navigate(`/videos/${video.id}`);
    openSidebar();
  };

  useEffect(() => {
    resetFilter();
  }, [vendorAgent?.currentVendor]);

  if (status === 'loading' && !isFetching) {
    return <Loader />;
  }

  if (status === 'error') {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>{t('video:list.error')}</Spacer>
    );
  }

  if (
    status === 'success' &&
    data?.pages.map((i) => i.data).flat().length === 0
  ) {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>{t('video:list.empty')}</Spacer>
    );
  }

  return (
    <QueryList
      {...getVideosQuery}
      rightSlot={
        !isDesktop ? (
          <IconButton
            highlightNumber={
              Object.values(filter || []).flat().length || undefined
            }
            onClick={() => navigate('filter')}
            icon="filter"
            color={Color.confirm}
            big
          />
        ) : undefined
      }
    >
      {data?.pages.map((page, i) => (
        <React.Fragment key={(page.links?.next?.offset || i) as Key}>
          {page.data.map((video, index) =>
            isDesktop ? (
              <VideoTicketItem
                {...video}
                key={index}
                onClick={() => handleOpenVideo(video)}
              />
            ) : (
              <VideoCard
                {...video}
                key={index}
                onClick={() => handleOpenVideo(video)}
              />
            ),
          )}
        </React.Fragment>
      ))}
    </QueryList>
  );
};

export default VideoData;
