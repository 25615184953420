import classNames from 'classnames';
import { Spacer, SpacerProps } from 'components/Layout/Layout';
import LoginContainer from 'features/Login/LoginContainer';
import LandingPage from 'pages/LandingPage';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Props {
  children?: ReactNode;
  message?: string;
  spacerProps?: SpacerProps;
}

interface State {
  hasError: boolean;
}

class AppErrorBoundary extends Component<Props & WithTranslation, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <LandingPage headline={this.props.t('error-headline')}>
          <Spacer
            tag="p"
            margin={[0, 0, 4]}
            padding={[1, 2]}
            {...this.props.spacerProps}
            className={classNames(
              'bg-dark-10',
              'c-dark-50',
              this.props.spacerProps?.className,
            )}
            style={{
              borderRadius: '4px',
              display: 'inline-block',
              ...this.props.spacerProps?.style,
            }}
          >
            {this.props.message || this.props.t('errorBoundaryApp')}
          </Spacer>

          {/* TODO This will actually not work properly, because isLoggedIn is never true */}
          <LoginContainer />
        </LandingPage>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(AppErrorBoundary);
