import classNames from 'classnames';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import Chip from 'components/Chip/Chip';
import LabelText from 'components/LabelText/LabelText';
import LabeledInfoText from 'components/LabeledInfoText/LabeledInfoText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import LocalisationTabs from 'components/LocalisationTabs/LocalisationTabs';
import TabPane from 'components/LocalisationTabs/TabPane';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import { useGetProduct } from '../queries';

const ProductInfoContainer = ({
  productId,
  error,
  success,
  initialLanguage,
  footerSlot,
  controlsSlot,
  className,
}: {
  productId: number;
  error?: string;
  success?: string;
  initialLanguage?: string;
  footerSlot?: ReactNode;
  controlsSlot?: ReactNode;
  className?: string;
}) => {
  const { t } = useTranslation();
  const { data } = useGetProduct(productId);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const initialLocalisation = () => {
    const find = data?.productLocalisations.find(
      (l) => l.locale.substring(0, 2) === initialLanguage,
    );

    if (find) {
      return {
        id: find.locale,
        title: find.name,
        subtitle: data?.vendorProductId,
        name: find.locale,
        price: { amount: find.price, currency: find.currencyCode },
        discountPrice: find.discountPrice,
        currencyCode: find.currencyCode,
      };
    }
  };

  return (
    <FlexBox
      className={classNames('product-info', className)}
      flexDirection="column"
    >
      <FlexBox justifyContent="space-between" alignItems="flex-start">
        <FlexItem tag="header">
          <Spacer marginBottom={isDesktop ? 6 : 2}>
            <h2 className="caption--dark">{t('video:product.label')}</h2>
          </Spacer>

          {controlsSlot && (
            <Spacer
              marginBottom={isDesktop ? 2 : 10}
              className="small product-info__controls"
            >
              {controlsSlot}
            </Spacer>
          )}
        </FlexItem>
        <FlexItem>
          {error && (
            <Spacer margin={[1, 0, 4]}>
              <Chip role="alert" color={ColorUse['warning-50']}>
                {error}
              </Chip>
            </Spacer>
          )}
          {success && (
            <Spacer margin={[1, 0, 4]}>
              <Chip role="alert" color={ColorUse['confirm-50']}>
                {success}
              </Chip>
            </Spacer>
          )}
        </FlexItem>
      </FlexBox>
      {data && (
        <LocalisationTabs
          initial={initialLocalisation()}
          showTabPaneContent={isDesktop}
          status={
            !data?.isAvailable
              ? VideoResponseDTO.status.IDLE
              : VideoResponseDTO.status.LIVE
          }
        >
          {data.productLocalisations.map((info) => (
            <TabPane
              key={info.locale}
              id={info.locale}
              title={info.name === '' ? t('no_title') : info.name}
              subtitle={data.vendorProductId}
              name={info.locale}
              price={{ amount: info.price, currency: info.currencyCode }}
              discountPrice={info.discountPrice}
            >
              {isDesktop && (
                <>
                  {info.shopUrl && (
                    <LabelText label={t('product:shop_url')}>
                      <p className="product-info__text product-info__text--ellipsis">
                        <a
                          className="link"
                          href={info.shopUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {info.shopUrl}
                        </a>
                      </p>
                    </LabelText>
                  )}
                  {/* TODO Callapsible when two much content - collapsible=true */}
                  {info.productInfo && info.productInfo.length !== 0 && (
                    <Spacer marginTop={4}>
                      <LabelText
                        label={t('product:info')}
                        collapsible
                        initiallyCollapsed
                      >
                        <LabeledInfoText staticContent={info.productInfo} />
                      </LabelText>
                    </Spacer>
                  )}
                </>
              )}
            </TabPane>
          ))}
        </LocalisationTabs>
      )}
      {data?.categories && data?.categories.length > 0 && (
        <Spacer marginTop={6}>
          <LabelText label={t('product:categories.label')}>
            {data?.categories.map((category, i) => (
              <Spacer key={category.id} marginBottom={i !== 0 ? 2 : 0}>
                <CategoryBranch category={category} />
              </Spacer>
            ))}
          </LabelText>
        </Spacer>
      )}
      {data?.hashtags && data?.hashtags.length > 0 && (
        <Spacer marginTop={6}>
          <LabelText label={t('product:hashtag.label')}>
            {data?.hashtags.map((hashtag, index) => (
              <span className="product-info__category" key={hashtag.id}>
                {`#${hashtag.name}${
                  (data?.hashtags?.length || 0) > 1 &&
                  index !== (data?.hashtags?.length || 0) - 1
                    ? ', '
                    : ''
                }`}
              </span>
            ))}
          </LabelText>
        </Spacer>
      )}

      <FlexItem
        tag="footer"
        justifyContent="flex-end"
        paddingTop={isDesktop ? 20 : 10}
        className="small"
        pushBottom
      >
        <FlexItem
          padding={1}
          margin={[0, 1]}
          className="video-detail__product-footer-item"
          pushLeft
        >
          <Link to={`/products/${productId}`} className="icon-link">
            <span className="icon-link__text">{t('product:footer.go')}</span>
          </Link>
        </FlexItem>

        {footerSlot}
      </FlexItem>
    </FlexBox>
  );
};

export default ProductInfoContainer;
