import {
  NotificationDTO,
  NotificationResponseDTO,
} from '../../../../generated';
import { getPlatformsFromDraftPlatforms } from '../../../../utils/get-platforms-from-draft-platforms';
import {
  NotificationDraft,
  NotificationDraftCopy,
  NotificationDraftEdit,
  NotificationDraftType,
} from '../types';

export const mapNotificationDraftToNotificationDTO = ({
  platforms: draftPlatforms,
  ...draft
}: NotificationDraft): NotificationDTO => {
  const platforms = getPlatformsFromDraftPlatforms(draftPlatforms);

  if (
    draft.type === NotificationDraftType.COPY ||
    draft.type === NotificationDraftType.EDIT
  ) {
    const { id, type, ...notificationDto } = draft;

    return { ...notificationDto, ...platforms };
  }

  const { type, ...notificationDto } = draft;

  return { ...notificationDto, ...platforms };
};

export const mapNotificationResponseDtoToNotificationDraftEdit = ({
  status,
  vendor,
  ios,
  android,
  ...rest
}: NotificationResponseDTO): NotificationDraftEdit => {
  return {
    ...rest,
    language: rest.language.language,
    type: NotificationDraftType.EDIT,
    vendorId: vendor?.id ? vendor?.id.toString() : undefined,
    platforms: [ios ? 'ios' : undefined, android ? 'android' : undefined],
    analyticsLabel: rest.analyticsLabel || '',
  };
};

export const mapNotificationResponseDtoToNotificationDraftCopy = ({
  status,
  vendor,
  ios,
  android,
  optionKey = undefined,
  optionValue = '',
  ...rest
}: NotificationResponseDTO): NotificationDraftCopy => {
  return {
    ...rest,
    optionKey: optionKey === null ? undefined : optionKey,
    optionValue: optionValue === null ? undefined : optionValue,
    date: undefined,
    language: rest.language.language,
    type: NotificationDraftType.COPY,
    vendorId: vendor?.id ? vendor?.id.toString() : undefined,
    platforms: [ios ? 'ios' : undefined, android ? 'android' : undefined],
    analyticsLabel: rest.analyticsLabel || '',
  };
};
