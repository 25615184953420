import { useOutletContext } from 'react-router-dom';
import OrderDetail from '../OrderDetail/OrderDetail';

type ContextType = { onClose: () => void };

/** route /orders/:id */
const OrderDetailOutlet = () => {
  const { onClose } = useOutletContext<ContextType>();
  return <OrderDetail onClose={onClose} />;
};

export default OrderDetailOutlet;
