import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Tabs from '../../components/Tabs/Tabs';
import { useReduxSelector } from '../../redux/hooks';
import { selectVendorAgentRole } from '../../redux/slices/auth/authSlice';
import getAvailableTabs from './get-available-tabs';
import { Spacer } from 'components/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

export enum SettingsTab {
  profil = 'profil',
  admin = 'admin',
  referrals = 'referrals',
  superAdmin = 'superAdmin',
}

const SettingsTabsContainer = () => {
  const role = useReduxSelector(selectVendorAgentRole);
  const { t } = useTranslation(['translation', 'settings']);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const tabs = getAvailableTabs({ role, t });
  const location = useLocation();
  const basePath = '/settings/';

  const [activeTab, setActiveTab] = useState<SettingsTab>(
    (location.pathname.substring(
      location.pathname.indexOf(basePath) + basePath.length,
    ) as SettingsTab) || SettingsTab.profil,
  );

  const handleTabChange = (tab: SettingsTab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      navigate(`${basePath}${tab}`);
    }
  };

  return (
    <>
      <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />

      <Spacer
        className="bg-white"
        padding={isDesktop ? [7, 7, 14] : [7, 2, 14]}
        margin={isDesktop ? [7, 0] : [7, -2]}
      >
        <Outlet />
      </Spacer>
    </>
  );
};

export default SettingsTabsContainer;
