import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout/Layout';

const CreateButton = (
  inputValue: string,
  createText: string,
  prefix?: string,
) => {
  return (
    <FlexBox className="select-interactive__add">
      <span className="select-interactive__new">
        {prefix}
        {inputValue}
      </span>
      <FlexItem
        display="inline-flex"
        className="select-interactive__add-button"
        alignItems="center"
        pushRight
      >
        <span>{createText}</span>
        <Icon icon="add-filled" />
      </FlexItem>
    </FlexBox>
  );
};

export default CreateButton;
