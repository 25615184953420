import classNames from 'classnames';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FlexBox } from 'components/Layout/Layout';
import Status from 'components/VideoStatus/VideoStatus';
import { AnimatedOutlet } from 'features/animated-routing';
import ChildNavigator from 'features/child-navigation';
import { ProductLinkMutationProvider } from 'features/video-link-product';
import { VideoChannelsMutationProvider } from 'features/video-channels';
import { StepKeys as VideoTypeStepKeys } from 'features/video-type';
import useSidebar from 'hooks/useSidebar';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetVideo } from '../queries';
import './video-detail-navigator.scss';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

/** route /videos/:id/ Sidebar  */
const VideoDetailNavigatorOutlet = () => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation(['translation', 'video']);
  const { closeSidebar } = useSidebar();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const videoId = id ? parseInt(id) : 0;
  const { data, isError, isLoading } = useGetVideo(videoId);
  const navigate = useNavigate();
  const location = useLocation();
  const [parentCenterSlot, setParentCenterSlot] = useState();
  const [childCenterSlot, setChildCenterSlot] = useState<ReactElement>();
  const [theme, setTheme] = useState(
    location.pathname === `/videos/${id}/change` ? 'secondary' : 'white',
  );
  const [visited, setVisited] = useState(false);
  const visitedParent = () => setVisited(true);

  const handleCloseSidebar = () => {
    closeSidebar();
    navigate('/videos');
  };

  useEffect(() => {
    if (!data && isLoading) {
      setParentCenterSlot(t('video:loading.label'));
    } else if (isError) {
      setParentCenterSlot(t('video:error.label'));
    } else {
      setParentCenterSlot(undefined);
    }
  }, [data, isError, isLoading]);

  useEffect(() => {
    setTheme(
      location.pathname === `/videos/${id}/change` ? 'secondary' : 'white',
    );
  }, [location.pathname]);

  useEffect(() => {
    const typeStep = searchParams.get('typeStep');
    // needed for the Steps navigation in feature video-type
    if (typeStep && typeStep !== VideoTypeStepKeys.DEFAULT) {
      setChildCenterSlot(
        <h2 className="caption caption--primary child-navigator__caption">
          {t(`video:type.steps.${typeStep}`)}
        </h2>,
      );
    } else {
      setChildCenterSlot(undefined);
    }
  }, [searchParams]);

  return (
    <FlexBox
      className={classNames(
        'video-detail-navigator',
        theme && `video-detail-navigator--${theme}`,
      )}
      flexDirection="column"
    >
      <ChildNavigator
        parentRoute={`/videos/${data?.id}`}
        onClose={handleCloseSidebar}
        parentRightSlot={
          data && !isError && !isDesktop ? (
            <Status status={data.status} />
          ) : undefined
        }
        parentCenterSlot={
          parentCenterSlot ? (
            <h2 className="caption child-navigator__caption">
              {parentCenterSlot}
            </h2>
          ) : undefined
        }
        childCenterSlot={childCenterSlot}
      />

      <ProductLinkMutationProvider>
        <VideoChannelsMutationProvider>
          {/* routes /videos/:id/{children}  */}
          <AnimatedOutlet context={{ video: data, visited, visitedParent }} />
        </VideoChannelsMutationProvider>
      </ProductLinkMutationProvider>
    </FlexBox>
  );
};

export default VideoDetailNavigatorOutlet;
