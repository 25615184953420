import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';
import './search.scss';

export default function Search({
  searchTerm,
  onChange,
  placeholder,
  className,
}: {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={classNames('search', className)}>
      <Input
        label={t('search')}
        placeholder={placeholder}
        name="searchTerm"
        value={searchTerm}
        onChange={onChange}
        type="text"
        hiddenLabel={true}
      />
      <Icon icon="search" />
    </div>
  );
}
