import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './button.scss';
import React from 'react';
import Loader from '../Loader/Loader';
import Icon from '../Icon/Icon';
import { Icon as IconType } from '../Icon/icon-spec';
import Color, { ColorUse } from '../../types/Color';

export interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  text: string;
  link?: string;
  small?: boolean;
  icon?: IconType;
  appearance?: 'filled' | 'ghost';
  color?: Color | ColorUse;
  disableFocus?: boolean;
  loading?: boolean;
}

const Button = ({
  text,
  link,
  small = false,
  icon,
  appearance = 'filled',
  color = Color.primary,
  disableFocus,
  loading,
  ...rest
}: Props) => {
  const classNames = classnames(
    'button',
    `button--${appearance}`,
    `button--${color}`,
    small && 'button--small',
    disableFocus && 'button--disableFocus',
    loading && 'button--loading',
    rest.className,
  );

  let typeIs = rest.type;
  if (!rest.type && !link) {
    typeIs = 'button';
  }

  if (!link && !rest.onClick && !rest.onMouseDown && rest.type !== 'submit') {
    return (
      <div className={classnames('button', classNames)}>
        {text && <span className="button__text">{text}</span>}
        {icon && (
          <div className="button__icon">
            <Icon icon={icon} />
          </div>
        )}
      </div>
    );
  }
  return !link ? (
    <button {...rest} className={classNames} type={typeIs}>
      {text && <span className="button__text">{text}</span>}
      {icon && !loading && (
        <div className="button__icon">
          <Icon icon={icon} />
        </div>
      )}
      {loading && (
        <div className="button__loader">
          <Loader small color={Color.dark} />
        </div>
      )}
    </button>
  ) : (
    <Link to={link} className={classNames}>
      {text && <span className="button__text">{text}</span>}
      {icon && (
        <div className="button__icon">
          <Icon icon={icon} />
        </div>
      )}
    </Link>
  );
};

export default Button;
