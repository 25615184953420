import classNames from 'classnames';
import icons from './icon-spec';

export type IconType = keyof typeof icons;

export interface Props {
  icon: IconType;
  className?: string;
  style?: React.CSSProperties;
}

const Icon = ({ icon, className, style }: Props) => {
  const SVG = icons[icon];

  return <SVG className={classNames('icon', className)} style={style} />;
};

export default Icon;
