import SelectInteractive, {
  DefaultOption,
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive/';
import { getTaxOptions } from 'features/order-shipment/utils';
import { FieldProps, getIn } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnChangeValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLocaleIso, selectWorldzone } from 'redux/slices/i18n/i18nSlice';
import onSubmitDontBlur from 'utils/on-submit-dont-blur';

export type Props = Omit<
  SelectInteractiveProps<DefaultOption, false>,
  'options'
>;

export const TaxesSelect = (props: Props) => {
  const worldzone = useReduxSelector(selectWorldzone);
  const locale = useReduxSelector(selectLocaleIso);
  const [options, setOptions] = useState(getTaxOptions(locale, worldzone));
  const { t } = useTranslation(['translation', 'orders']);

  useEffect(() => {
    setOptions(getTaxOptions(locale, worldzone));
  }, [locale, worldzone]);

  return (
    <SelectInteractive
      name="rate"
      isSearchable={false}
      {...props}
      options={options}
    />
  );
};

export const TaxesSelectField = ({
  field,
  form: { errors, touched, setFieldValue },
  isMulti,
  ...props
}: Props & FieldProps) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);
  const worldzone = useReduxSelector(selectWorldzone);
  const locale = useReduxSelector(selectLocaleIso);
  const [options, setOptions] = useState(getTaxOptions(locale, worldzone));

  useEffect(() => {
    setOptions(getTaxOptions(locale, worldzone));
  }, [locale, worldzone]);

  const getValue = () => {
    return (
      options.find(
        (option) => option.value.toString() === field.value?.toString(),
      ) || { value: '', label: '' }
    );
  };

  const onChange = (option: OnChangeValue<DefaultOption, false>) => {
    setFieldValue(field.name, (option as DefaultOption).value);
  };
  return (
    <TaxesSelect
      {...field}
      {...props}
      value={getValue()}
      onChange={onChange}
      error={tou && err ? err : undefined}
      onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
    />
  );
};

export default TaxesSelectField;
