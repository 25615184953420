import IconButton from 'components/IconButton/IconButton';
import InputField from 'components/Input/InputField';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import { Field } from 'formik';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';

const LinkListItemTitle = ({
  edit,
  active,
  title,
  showInteraction,
  setShowInteraction,
  setReallyDelete,
}: {
  edit?: boolean;
  active?: boolean;
  title: string;
  showInteraction: boolean;
  setShowInteraction: React.Dispatch<React.SetStateAction<boolean>>;
  setReallyDelete: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation();

  const handleMore = () => {
    setShowInteraction(!showInteraction);
    setReallyDelete(false);
  };

  if (edit) {
    return (
      <Field
        component={InputField}
        wrapperClassName="link-list-item__field"
        name="title"
      />
    );
  }

  return (
    <FlexBox className="link-list-item__title" alignItems="flex-end">
      {active && <span className="caption--primary">{t('public')}: </span>}
      <span>{title}</span>

      {!isDesktop && (
        <FlexItem pushRight>
          <IconButton
            icon="more"
            color={showInteraction ? Color.secondary : Color.dark}
            big
            appearance="blank"
            onClick={handleMore}
            className="link-list-item__more"
          />
        </FlexItem>
      )}
    </FlexBox>
  );
};

export default LinkListItemTitle;
