import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { InputProps } from '../../types/InputType';
import getFormError from '../../utils/get-form-error';
import './login.scss';

export type LoginFormValues = {
  email: string;
  password: string;
};

const LoginForm = ({
  onSubmit,
  error,
}: {
  error: boolean;
  onSubmit: (values: LoginFormValues) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form.email'))
      .trim()
      .required(t('form.required')),
    password: Yup.string().trim().required(t('form.required')),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const loginForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const emailInputProps: InputProps = {
    ...loginForm.getFieldProps('email'),
    label: t('login.labels.email'),
    placeholder: t('login.placeholders.email'),
    error: getFormError(loginForm, 'email'),
  };

  const passwordInputProps: InputProps = {
    ...loginForm.getFieldProps('password'),
    label: t('login.labels.password'),
    placeholder: t('login.placeholders.password'),
    error: getFormError(loginForm, 'password'),
  };

  return (
    <>
      <form onSubmit={loginForm.handleSubmit}>
        <Input {...emailInputProps} />

        <Input type="password" {...passwordInputProps} />

        <NavLink className="login__reset-link" to="/reset-password">
          {t('login.forgot')}
        </NavLink>

        <div className="login__submit-btn">
          <Button text={t('login.submit')} type="submit" />
        </div>
      </form>

      {error && <div className="login__error">{t('login.unauthorized')}</div>}
    </>
  );
};

export default LoginForm;
