import classNames from 'classnames';
import { BREAKPOINTS } from '../../../global-constants';
import React, { ElementType } from 'react';
import { getSpacerClassNames, SpacerType } from '../Spacer/Spacer';
import { FlexBoxType, getFlexBoxClassNames } from './FlexBox';
import { useMediaQuery } from 'hooks/useMediaquery';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  order?: number;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  alignSelf?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  tag?: ElementType;
  pushLeft?: boolean;
  pushRight?: boolean;
  pushTop?: boolean;
  pushBottom?: boolean;
  breakpoint?: keyof typeof BREAKPOINTS;
}

const FlexItem = ({
  tag: Tag = 'div',
  children,
  order,
  flexGrow,
  flexShrink,
  flexBasis,
  alignSelf,
  className,
  pushLeft,
  pushRight,
  pushTop,
  pushBottom,
  display,
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  breakpoint,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  border,
  borderTop,
  borderBottom,
  borderLeft,
  borderRight,
  ...props
}: Props & SpacerType & FlexBoxType) => {
  const isBreakpoint = useMediaQuery(
    `(min-width: ${BREAKPOINTS[breakpoint || 'xs']})`,
  );

  const flexBoxClassNames = getFlexBoxClassNames(
    {
      display,
      flexDirection,
      flexWrap,
      justifyContent,
      alignItems,
      alignContent,
    },
    breakpoint ? isBreakpoint : true,
  );

  const spacerClassNames = getSpacerClassNames({
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
  });

  return (
    <Tag
      {...props}
      className={classNames(
        'flex-box__item',
        flexBoxClassNames.length > 0 && 'flex-box',
        spacerClassNames.length > 0 && 'spacer',
        alignSelf && `flex-box__item--align-self-${alignSelf}`,
        pushLeft && 'flex-box__item--push-left',
        pushRight && 'flex-box__item--push-right',
        pushBottom && 'flex-box__item--push-bottom',
        pushTop && 'flex-box__item--push-top',
        flexBoxClassNames,
        spacerClassNames,
        className,
      )}
      style={{
        ...props.style,
        order,
        flexGrow,
        flexShrink,
        flexBasis,
      }}
    >
      {children}
    </Tag>
  );
};

export default FlexItem;
