import ChannelSelect, {
  getOptionFromChannel,
} from 'components/ChannelSelect/ChannelSelect';
import { OptionWithIconProps } from 'components/SelectInteractive';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useGetChannelFilter } from '../../queries';
import FilterDetail from './FilterDetail';
import { FilterItem } from 'features/video-filter/types';

const ChannelFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const [isInfinite, setIsInfinite] = useState(true);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetChannelFilter(
    debouncedSearchTerm as string,
  );

  const handleChange = (newValue: MultiValue<OptionWithIconProps>) => {
    setFilter(
      'channel',
      newValue?.map(
        ({ value, label }) =>
          ({
            id: value,
            name: label,
          } as FilterItem),
      ),
    );
  };

  const getValue = () => {
    return (
      filter?.channel?.map(({ id, name }) => {
        const findChannel = data?.pages
          .map((i) => i.data)
          .flat()
          .find((i) => i.id.toString() === id);

        return findChannel
          ? getOptionFromChannel(findChannel)
          : { value: id.toString(), label: name };
      }) || []
    );
  };

  // props for server side search
  const infiniteProps = isInfinite
    ? {
        onLoadMore: () => fetchNextPage(),
        hasNextPage,
        filterOption: () => true,
        ...searchableProps,
      }
    : {};

  useEffect(() => {
    // when there are not more channels then the limit of 10, the clientside search can be used
    const totals = data?.pages[data?.pages.length - 1]?.total;
    if (totals && totals < 10) {
      setIsInfinite(false);
    }
  }, [data]);

  return (
    <FilterDetail>
      <ChannelSelect
        {...infiniteProps}
        isMulti
        variant="open"
        channels={data?.pages.map((i) => i.data).flat()}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        placeholder={t('video:filter.channel.placeholder')}
        noOptionsMessage={t('noOptions')}
      />
    </FilterDetail>
  );
};

export default ChannelFilter;
