import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import VideoTicket, { Props } from 'components/VideoTicket/VideoTicket';
import { useDeleteVideo } from 'features/video-delete';
import { useUpdateVisibility } from 'features/visibility';
import { VideoResponseDTO } from 'generated';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const VideoTicketItem = (props: Props) => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation(['translation', 'video']);
  const deleteMutation = useDeleteVideo();
  const visibilityMutation = useUpdateVisibility();

  const handleDelete = () => {
    deleteMutation.mutate({ videoId: props.id });
    setDeleteModal(false);
  };
  const handleShare = () => {
    navigate(`/videos/${props.id}/share`);
  };

  const handleVisibilityChange = (
    newVisibility: VideoResponseDTO.visibility,
  ) => {
    visibilityMutation.mutate({
      videoId: props.id,
      visibility: newVisibility,
    });
  };

  return (
    <>
      <VideoTicket
        {...props}
        deleteButtonProps={{ onClick: () => setDeleteModal(true) }}
        shareButtonProps={{ onClick: handleShare }}
        visibilitySelectProps={{
          onToggle: handleVisibilityChange,
          error: visibilityMutation.isError
            ? t('inline-edit.error')
            : undefined,
        }}
      />
      <ConfirmModal
        isOpen={deleteModal}
        onCancelClick={() => setDeleteModal(false)}
        onConfirmClick={handleDelete}
        headline={t('video:deleteVideo')}
        text={t('video:really_delete', { title: props.name })}
        confirmText={t('delete')}
      />
    </>
  );
};

export default VideoTicketItem;
