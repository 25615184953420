import { useInfiniteQuery } from '@tanstack/react-query';
import {
  ApiError,
  FiltersService,
  PaginatedGetCategoryFiltersResponseDTO,
  PaginatedGetChannelsFiltersResponseDTO,
  PaginatedGetHashtagFiltersResponseDTO,
  PaginatedGetProductFiltersResponseDTO,
  PaginatedGetStatusFiltersResponseDTO,
  PaginatedGetTypeFiltersResponseDTO,
  PaginatedGetVisibilityFiltersResponseDTO,
} from 'generated';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  getFilter = 'getFilter',
  getProductFilter = 'getProductFilter',
  getChannelFilter = 'getChannelFilter',
  getTypeFilter = 'getTypeFilter',
  getVisibilityFilter = 'getVisibilityFilter',
  getStatusFilter = 'getStatusFilter',
  getCategoryFilter = 'getCategoryFilter',
  getHashtagFilter = 'getHashtagFilter',
}

export const videoFilterKeys = {
  // can invalidate all queries with this key
  getFilter: [KEYS.getFilter],
  getProductFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getProductFilter,
    vendorId,
    searchTerm,
  ],
  getChannelFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getChannelFilter,
    vendorId,
    searchTerm,
  ],
  getTypeFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getTypeFilter,
    vendorId,
    searchTerm,
  ],
  getVisibilityFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getVisibilityFilter,
    vendorId,
    searchTerm,
  ],
  getStatusFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getStatusFilter,
    vendorId,
    searchTerm,
  ],
  getCategoryFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getCategoryFilter,
    vendorId,
    searchTerm,
  ],
  getHashtagFilter: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getFilter,
    KEYS.getHashtagFilter,
    vendorId,
    searchTerm,
  ],
};

export const useGetProductFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetProductFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getProductFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getProductFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
export const useGetChannelFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetChannelsFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getChannelFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getChannelFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
export const useGetTypeFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetTypeFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getTypeFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getTypeFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
export const useGetVisibilityFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetVisibilityFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getVisibilityFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getVisibilityFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};

export const useGetStatusFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetStatusFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getStatusFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getStatusFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};

export const useGetCategoryFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetCategoryFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getCategoryFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getCategoryFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};

export const useGetHashtagFilter = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedGetHashtagFiltersResponseDTO, ApiError>({
    queryKey: videoFilterKeys.getHashtagFilter(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      FiltersService.getHashtagFilters(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
