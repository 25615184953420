/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppOrderStatusDto } from './AppOrderStatusDto';
import type { InternalOrderBasicProductDto } from './InternalOrderBasicProductDto';
import type { TotalPricingDto } from './TotalPricingDto';
import type { VendorAddressDto } from './VendorAddressDto';
import type { VendorCustomerAddressDto } from './VendorCustomerAddressDto';
import type { VendorOrderBasicChannelDto } from './VendorOrderBasicChannelDto';
import type { VendorOrderItemDto } from './VendorOrderItemDto';
import type { VendorOrderShipmentDto } from './VendorOrderShipmentDto';
import type { VendorPaymentDto } from './VendorPaymentDto';

export type VendorOrderDto = {
    paymentMethod: VendorOrderDto.paymentMethod | null;
    paymentProvider: VendorOrderDto.paymentProvider;
    /**
     * The product property is only available until we have more than one item per order.
     */
    product?: InternalOrderBasicProductDto;
    /**
     * The channel property is only available until we have more than one item per order.
     */
    channel?: VendorOrderBasicChannelDto;
    pickupAddress?: VendorAddressDto;
    shippingAddress?: VendorCustomerAddressDto;
    invoiceAddress?: VendorCustomerAddressDto;
    shipments?: Array<VendorOrderShipmentDto>;
    orderItems: Array<VendorOrderItemDto>;
    orderStatusHistory: Array<AppOrderStatusDto>;
    currentOrderStatus: AppOrderStatusDto | null;
    vendorPossibleOrderStatusActions?: Array<'purchaseBasketItems' | 'paidBasketItems' | 'createShipment' | 'cancelShipment' | 'cancelOrderBeforeShipping' | 'pickupOrderFromWarehouse' | 'deliverOrderToCustomer' | 'notDeliveredOrder' | 'doPaymentCOD' | 'closeOrder' | 'lostOrder' | 'requestReturnByCustomer' | 'createReturnShipment' | 'pickupOrderFromCustomer' | 'rejectReturnRequest' | 'deliverOrderToWarehouse' | 'refundPayment'>;
    payment?: VendorPaymentDto;
    createdAt?: string;
    updatedAt?: string | null;
    orderNo: string | null;
    comment: string | null;
    pricing: TotalPricingDto;
};

export namespace VendorOrderDto {

    export enum paymentMethod {
        CASH_ON_DELIVERY = 'cashOnDelivery',
        ACSS_DEBIT = 'acss_debit',
        AFFIRM = 'affirm',
        AFTERPAY_CLEARPAY = 'afterpay_clearpay',
        ALIPAY = 'alipay',
        AU_BECS_DEBIT = 'au_becs_debit',
        BACS_DEBIT = 'bacs_debit',
        BANCONTACT = 'bancontact',
        BLIK = 'blik',
        BOLETO = 'boleto',
        CARD = 'card',
        CASHAPP = 'cashapp',
        CUSTOMER_BALANCE = 'customer_balance',
        EPS = 'eps',
        FPX = 'fpx',
        GIROPAY = 'giropay',
        GRABPAY = 'grabpay',
        IDEAL = 'ideal',
        KLARNA = 'klarna',
        KONBINI = 'konbini',
        LINK = 'link',
        OXXO = 'oxxo',
        P24 = 'p24',
        PAYNOW = 'paynow',
        PAYPAL = 'paypal',
        PIX = 'pix',
        PROMPTPAY = 'promptpay',
        SEPA_DEBIT = 'sepa_debit',
        SOFORT = 'sofort',
        US_BANK_ACCOUNT = 'us_bank_account',
        WECHAT_PAY = 'wechat_pay',
        ZIP = 'zip',
    }

    export enum paymentProvider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }


}

