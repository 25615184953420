import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import IconButton from 'components/IconButton/IconButton';
import LinkCode from 'components/LinkCode/LinkCode';
import ListItemSimple from 'components/ListItemSimple/ListItemSimple';
import { format, Locale } from 'date-fns';
import { NotificationResponseDTO } from 'generated';
import './notification-list-item.scss';

export type Props = {
  locale: Locale;
  statusTextPending: string;
  statusTextSent: string;
  notification: NotificationResponseDTO;
  onDeleteClick: () => void;
  onEditClick: () => void;
  onCopyClick: () => void;
};

const NotificationListItem = ({
  notification,
  onDeleteClick,
  statusTextPending,
  statusTextSent,
  onEditClick,
  onCopyClick,
  locale,
}: Props) => {
  const renderStatusPending = () => (
    <span className="notification-list-item__status notification-list-item__status--pending">
      {statusTextPending}
      <Icon icon="clock" />
    </span>
  );

  const renderStatusSent = () => (
    <span className="notification-list-item__status notification-list-item__status--sent">
      {statusTextSent} <Icon icon="check-filled" />
    </span>
  );

  const renderHeaderRight = () => {
    const status =
      notification.status === NotificationResponseDTO.status.PENDING
        ? renderStatusPending()
        : renderStatusSent();

    return (
      <>
        <div className="notification-list-item__platform">
          <span
            className={classNames({
              'notification-list-item__platform-icon': true,
              'notification-list-item__platform-icon--active': notification.ios,
            })}
          >
            <Icon icon="ios" />
          </span>

          <span
            className={classNames({
              'notification-list-item__platform-icon': true,
              'notification-list-item__platform-icon--active':
                notification.android,
            })}
          >
            <Icon icon="android" />
          </span>
        </div>

        <span className="notification-list-item__lang">
          {notification.language.iso}
        </span>

        {status}

        <span className="notification-list-item__date">
          {format(new Date(notification.date), 'Pp', {
            locale,
          })}
        </span>

        {notification.status !== NotificationResponseDTO.status.SENT && (
          <span className="notification-list-item__action-button">
            <IconButton icon="delete" onClick={onDeleteClick} />
          </span>
        )}

        <span className="notification-list-item__action-button">
          <IconButton icon="copy" onClick={onCopyClick} />
        </span>
      </>
    );
  };

  const renderHeadline = () => (
    <span className="notification-list-item__headline">
      {notification.title}
    </span>
  );

  const renderBody = () => (
    <>
      <p>{notification.body}</p>

      {notification.analyticsLabel && (
        <div className="notification-list-item__analytics-label">
          <LinkCode link={notification.analyticsLabel} copyToClipBoard />
        </div>
      )}
    </>
  );

  return (
    <ListItemSimple
      headline={renderHeadline()}
      headerRight={renderHeaderRight()}
      body={renderBody()}
    />
  );
};

export default NotificationListItem;
