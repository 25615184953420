import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FieldProps } from 'formik';
import { OnChangeValue } from 'react-select';
import getProductPriceByLanguage from 'utils/get-product-price-by-language';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import ProductSelect, { ProductOption, Props } from './ProductSelect';

/** Only single select */
const ProductSelectField = ({
  field,
  form: { setFieldValue },
  ...props
}: Omit<Props<false>, 'isMulti'> & FieldProps) => {
  const onChange = (option: OnChangeValue<ProductOption, false>) => {
    setFieldValue(
      field.name,
      props.products?.find((i) => i.id.toString() === option?.value),
    );
  };

  const getValue = () => {
    if (!field.value) {
      return null;
    }
    const {
      id,
      language,
      isAvailable,
      vendorProductId,
      videos,
      productLocalisations,
    } = field.value;
    return {
      value: id.toString(),
      label: getProductTitleByLanguage(productLocalisations, language),
      price: getProductPriceByLanguage(productLocalisations, language),
      isAvailable,
      subtitle: vendorProductId,
      numberOfVideos: videos?.length,
    };
  };

  return (
    <ErrorBoundary>
      <ProductSelect
        {...field}
        {...props}
        value={getValue()}
        onChange={onChange}
      />
    </ErrorBoundary>
  );
};

export default ProductSelectField;
