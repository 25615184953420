import { AppOrderStatusDto } from 'generated';
import { ColorUse } from 'types/Color';

export const orderActionColors = {
  error: ColorUse['warning-50'],
  process: ColorUse['primary-25'],
  end: ColorUse.confirm,
};

export const orderActionPeriods = {
  // t('orders:periodKey.error.title');
  // t('orders:periodKey.error.tooltip');
  error: [
    // t('orders:status.cancelOrderBeforeShipping');
    AppOrderStatusDto.action.CANCEL_ORDER_BEFORE_SHIPPING,
    // t('orders:status.notDeliveredOrder');
    AppOrderStatusDto.action.NOT_DELIVERED_ORDER,
    // t('orders:status.rejectReturnRequest');
    AppOrderStatusDto.action.REJECT_RETURN_REQUEST,
    // t('orders:status.cancelShipment');
    AppOrderStatusDto.action.CANCEL_SHIPMENT,
    // t('orders:status.lostOrder');
    AppOrderStatusDto.action.LOST_ORDER,
  ],
  // t('orders:periodKey.process.title');
  // t('orders:periodKey.process.tooltip');
  process: [
    // t('orders:status.purchaseBasketItems');
    AppOrderStatusDto.action.PURCHASE_BASKET_ITEMS,
    // t('orders:status.paidBasketItems');
    AppOrderStatusDto.action.PAID_BASKET_ITEMS,
    // t('orders:status.pickupOrderFromWarehouse');
    AppOrderStatusDto.action.PICKUP_ORDER_FROM_WAREHOUSE,
    // t('orders:status.doPaymentCOD');
    AppOrderStatusDto.action.DO_PAYMENT_COD,
    // t('orders:status.requestReturnByCustomer');
    AppOrderStatusDto.action.REQUEST_RETURN_BY_CUSTOMER,
    // t('orders:status.pickupOrderFromCustomer');
    AppOrderStatusDto.action.PICKUP_ORDER_FROM_CUSTOMER,
    // t('orders:status.createShipment');
    AppOrderStatusDto.action.CREATE_SHIPMENT,
    // t('orders:status.createReturnShipment');
    AppOrderStatusDto.action.CREATE_RETURN_SHIPMENT,
  ],
  // t('orders:periodKey.end.title');
  // t('orders:periodKey.end.tooltip');
  end: [
    // t('orders:status.deliverOrderToCustomer');
    AppOrderStatusDto.action.DELIVER_ORDER_TO_CUSTOMER,
    // t('orders:status.deliverOrderToWarehouse');
    AppOrderStatusDto.action.DELIVER_ORDER_TO_WAREHOUSE,
    // t('orders:status.refundPayment');
    AppOrderStatusDto.action.REFUND_PAYMENT,
    // t('orders:status.closeOrder');
    AppOrderStatusDto.action.CLOSE_ORDER,
  ],
};

export const getPeriodKeyByOrderAction = (action: AppOrderStatusDto.action) => {
  return (
    Object.keys(orderActionPeriods).find((key) =>
      orderActionPeriods[key].includes(action),
    ) || 'process'
  );
};

export const getOrderActionColorByStatus = (
  action: AppOrderStatusDto.action,
) => {
  const periodKey = getPeriodKeyByOrderAction(action);

  return orderActionColors[
    (periodKey as keyof typeof orderActionColors) || process
  ];
};

export const doesOrderActionNeedConfirmation = (
  action: AppOrderStatusDto.action,
) => {
  const actions = [
    AppOrderStatusDto.action.PURCHASE_BASKET_ITEMS,
    AppOrderStatusDto.action.PAID_BASKET_ITEMS,
  ];

  return actions.includes(action);
};
