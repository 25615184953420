import { FlexBox } from 'components/Layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { VendorAgentsService } from '../../generated';
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from './ResetPasswordForm';
import './reset-password.scss';

const ResetPassword = () => {
  const [done, setDone] = useState<string>();
  const { t } = useTranslation();

  const handleSubmit = async ({ email }: ResetPasswordFormValues) => {
    try {
      await VendorAgentsService.resetPassword({ email });

      setDone(email);
    } catch (error) {}
  };

  return (
    <div className="reset-password">
      {!done ? (
        <ResetPasswordForm onSubmit={handleSubmit} />
      ) : (
        <div>
          <p className="reset-password__success-text icon">
            <Icon icon="info" />
            {t('resetPassword.texts.success', { email: done })}
          </p>

          <FlexBox className="reset-password__submit-btn">
            <NavLink to="/">
              <Button text={t('back')} appearance="ghost" />
            </NavLink>
            <Button
              text={t('resetPassword.buttons.resend')}
              onClick={() => handleSubmit({ email: done })}
            />
          </FlexBox>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
