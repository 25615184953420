import Chip, { Props as ChipProps } from 'components/Chip/Chip';
import { Spacer } from 'components/Layout/Layout';
import { getVideoTypeAppearance } from 'features/video-type';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import { Props } from '../VideoUpdateForm';

const VideoUpdateType = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation(['translation', 'video']);
  const { values } = useFormikContext<Omit<Props, 'id'>>();
  const navigate = useNavigate();
  const [appearance, setAppearance] = useState<Partial<ChipProps>>(
    getVideoTypeAppearance(values.type),
  );
  const { state } = useLocation() as {
    state: { from?: string; error?: boolean };
  };

  useEffect(() => {
    setAppearance(getVideoTypeAppearance(values.type));
  }, [values.type]);

  const handleInteraction = () => {
    navigate('type');
  };

  return (
    <Spacer marginBottom={4} className="video-detail__type-button">
      {state?.from === 'type' && state?.error && (
        <Chip color={ColorUse['warning-50']}>{t('inline-edit.error')}</Chip>
      )}
      <Chip
        {...appearance}
        tooltip={t(`video:type.${values.type.toLocaleLowerCase()}.desc`)}
        interactionIcon="arrow-right"
        onInteraction={handleInteraction}
        interactionTooltip={t('video:type.change')}
        tooltipPlace="up"
        big
      >
        {t(`video:type.${values.type.toLocaleLowerCase()}.label`)}
      </Chip>
    </Spacer>
  );
};

export default VideoUpdateType;
