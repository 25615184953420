import classNames from 'classnames';
import { ReactNode } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { components, GroupBase, OptionProps } from 'react-select';
import Icon from '../../Icon/Icon';

export interface DefaultOption {
  label: string;
  value: string;
}

export const Option = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, Group> & {
    rightZone?: ReactNode;
    leftZone?: ReactNode;
    centerZone?: ReactNode;
    className?: string;
  },
) => {
  const { data } = optionProps;
  const { rightZone, leftZone, centerZone, className, ...spread } = optionProps;
  return (
    <Flipped flipId={data.value}>
      <div
        className={classNames(
          'select-interactive__option-wrap',
          optionProps.isSelected && 'select-interactive__option-wrap--selected',
          className,
        )}
      >
        <components.Option {...spread}>
          <span className="select-interactive__option-inner">
            <span className="select-interactive__check">
              <Icon icon="check" className="select-interactive__check-icon" />
            </span>
            {leftZone && leftZone}
            <span
              className="select-interactive__label"
              title={typeof data?.label === 'string' ? data.label : undefined}
            >
              {centerZone ? centerZone : data?.label}
            </span>
            {rightZone && rightZone}
          </span>
        </components.Option>
      </div>
    </Flipped>
  );
};

export default Option;
