import { FormikProps } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActivateToggle from '../../components/ActivateToggle/ActivateToggle';
import Button from '../../components/Button/Button';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import Icon from '../../components/Icon/Icon';
import { ApiError, ProductResponseDTO, ProductsService } from '../../generated';
import useAppStatus from '../../hooks/useAppStatus';
import useOpen from '../../hooks/useOpen';
import { useReduxDispatch } from '../../redux/hooks';
import { logOut } from '../../redux/slices/auth/authSlice';
import notAuthenticated from '../../utils/not-authenticated';

export interface Props {
  edit?: boolean;
  productId?: number;
  formik: FormikProps<ProductResponseDTO>;
  loadingSave: boolean;
}

const ProductFormControls = ({
  edit,
  productId,
  formik,
  loadingSave,
}: Props) => {
  const { t } = useTranslation(['translation', 'product']);
  const { isOpen, open, close } = useOpen(false);
  const {
    isOpen: cancelIsOpen,
    open: cancelOpen,
    close: cancelClose,
  } = useOpen(false);
  const [deactivateLoading, setDeactivateLoading] = useState<boolean>(false);
  const { values, setFieldValue, touched } = formik;
  const dispatch = useReduxDispatch();
  const { setAppStatus } = useAppStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleDeactivate = () => {
    if (edit) {
      if (productId) {
        const setAvailability = async () => {
          setDeactivateLoading(true);
          try {
            const res = await ProductsService.updateProduct(productId, {
              isAvailable: !values?.isAvailable,
            });

            if (res) {
              setFieldValue('isAvailable', !values?.isAvailable);
              setDeactivateLoading(false);
            }
          } catch (error) {
            setAppStatus(t('product:deactivate.error'), 'error');
          }
        };
        setAvailability();
      }
    } else {
      setFieldValue('isAvailable', !values?.isAvailable);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    const deleteProduct = async () => {
      try {
        if (productId) {
          const res = await ProductsService.deleteProduct(productId);

          if (res) {
            close();
            navigate('/products');

            setAppStatus(
              t('product:delete.success', { product: res.vendorProductId }),
              'success',
              true,
            );
          }
        }
      } catch (error) {
        if (notAuthenticated(error as ApiError)) {
          dispatch(logOut());
        } else {
          open();
          setAppStatus(t('product:delete.error'), 'error');
        }
      }
    };
    deleteProduct();
  };

  const handleCancel = () => {
    if (Object.keys(touched).length > 0) {
      cancelOpen();
    } else {
      navigate('/products');
    }
  };

  return (
    <div className="form__footer">
      <div className="form__controls">
        <div className="form__controls-left">
          <ActivateToggle
            onToggle={handleDeactivate}
            active={values.isAvailable}
            loading={deactivateLoading}
          />

          {edit && (
            <>
              <Button
                text={t('delete')}
                appearance="ghost"
                type="button"
                onClick={open}
              />

              <ConfirmModal
                isOpen={isOpen}
                headline={t('delete')}
                text={t('product:really_delete', {
                  title: values.vendorProductId,
                })}
                onCancelClick={close}
                onConfirmClick={handleDelete}
                confirmLoading={loading}
                confirmText={t('delete')}
              />
            </>
          )}
        </div>
        <div className="form__controls-right">
          {!edit && (
            <div className="form__controls-info">
              <span className="form__controls-info-icon">
                <Icon icon="info" />
              </span>
              <span className="form__controls-info-text">
                {t('product:videosAfterSafe')}
              </span>
            </div>
          )}
          <Button
            text={t('cancel')}
            type="button"
            appearance="ghost"
            onClick={handleCancel}
          />

          <ConfirmModal
            isOpen={cancelIsOpen}
            headline={t('cancel')}
            text={t('product:really_delete_changes')}
            cancelText={t('no')}
            onCancelClick={cancelClose}
            confirmText={t('leave')}
            confirmLink="/products"
          />

          <Button text={t('save')} type="submit" loading={loadingSave} />
        </div>
      </div>
    </div>
  );
};

export default ProductFormControls;
