import FileListItem, {
  Props as FileListItemProps,
} from 'components/FileListItem/FileListItem';
import { DefaultOption } from 'components/SelectInteractive';
import { VideoRequestUploadUrlDTO } from 'generated';
import { useEffect, useState } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { OnChangeValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
import { ColorUse } from 'types/Color';
import { useGetVideoUploadUrl } from '../queries';
import { VideoUploadFeature } from '../types';

export type Props = Partial<FileListItemProps> & {
  index: number;
  updateFile: (
    fileKey: string,
    newData: Partial<VideoUploadFeature.FileData>,
  ) => void;
  file: VideoUploadFeature.FileData;
};

/**
 * Renders a single file with a language select. The file will fetch its own upload url and update itself accordingly.
 * The file will later be added to the redux store for background-uploading by the VideoUpload component.
 * It does not matter if the uploadUrl is already fetched, this will happen automatically in the background process if no uploadUrl is present
 * This is just for a possibly faster upload process.
 */
const VideoUploadFile = ({ index, updateFile, file, ...props }: Props) => {
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const { t } = useTranslation();
  const uploadUrlMutation = useGetVideoUploadUrl(index);
  const [language, setLanguage] = useState(file.language?.substring(0, 2));

  const getStatusBadge = (status: VideoUploadFeature.Status) => {
    const statusColors: {
      [key in VideoUploadFeature.Status]?: ColorUse;
    } = {
      [VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR]: ColorUse['warning-50'],
      [VideoUploadFeature.Status.GET_UPLOAD_URL]: ColorUse['process-50'],
      [VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS]: ColorUse['confirm'],
    };
    return {
      color: statusColors[status],
      children: t(`video:upload.status.${status}.label`),
      tooltip: t(`video:upload.status.${status}.description`),
    };
  };

  const onLanguageChange = (option: OnChangeValue<DefaultOption, false>) => {
    setLanguage((option as DefaultOption).value?.substring(0, 2));
    updateFile(file.fileKey, {
      language: (option as DefaultOption).value?.substring(0, 2),
    });
  };

  const getLanguageValue = () => {
    if (supportedLanguages) {
      const result = supportedLanguages.find((lang) => {
        return lang.iso === language?.substring(0, 2);
      });
      return { value: result?.iso, label: result?.language?.substring(0, 2) };
    } else {
      return '' as any;
    }
  };

  const filetype = (name: string) => name.substring(name.lastIndexOf('.') + 1);

  const mapMutationStatusToUploadStatus = (
    status: 'idle' | 'loading' | 'error' | 'success',
  ) => {
    switch (status) {
      case 'loading': {
        return VideoUploadFeature.Status.GET_UPLOAD_URL;
      }
      case 'success': {
        return VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS;
      }
      case 'error': {
        return VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR;
      }
      default:
        return VideoUploadFeature.Status.IDLE;
    }
  };

  useEffect(() => {
    updateFile(file.fileKey, {
      status: mapMutationStatusToUploadStatus(uploadUrlMutation.status),
    });
  }, [uploadUrlMutation.status]);

  useEffect(() => {
    if (file.status === VideoUploadFeature.Status.IDLE) {
      uploadUrlMutation.mutate(
        {
          fileName: file.fileName,
          fileFormat: filetype(
            file.fileName,
          ).toLocaleLowerCase() as VideoRequestUploadUrlDTO.fileFormat,
        },
        {
          onSuccess: ({ url }) => {
            updateFile(file.fileKey, {
              status: VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS,
              uploadUrl: url,
            });
          },
          onError: (error) => {
            updateFile(file.fileKey, {
              status: VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR,
            });
            console.log(error);
          },
        },
      );
    }
  }, [file.status]);

  return (
    <Flipped flipId={file.fileKey}>
      {(flippedProps) => (
        <FileListItem
          {...flippedProps}
          {...props}
          fileName={file.fileName}
          size={file.size}
          chipProps={
            file.status !== VideoUploadFeature.Status.IDLE
              ? { ...getStatusBadge(file.status) }
              : undefined
          }
          languageSelectProps={{
            name: file.fileKey,
            languages: supportedLanguages,
            value: getLanguageValue(),
            onChange: (o) => onLanguageChange(o),
          }}
        />
      )}
    </Flipped>
  );
};

export default VideoUploadFile;
