import RefLinksContainer from '../../features/Settings/RefLinks/RefLinksContainer';

const SettingsReferralsTab = () => {
  return (
    <>
      <div className="form__block form__block--noborder">
        <RefLinksContainer />
      </div>
    </>
  );
};

export default SettingsReferralsTab;
