import classNames from 'classnames';
import IconButton from 'components/IconButton/IconButton';
import { Spacer } from 'components/Layout';
import { AppOrderStatusDto, VendorAddressDto, VendorOrderDto } from 'generated';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import CreateShipment from './components/CreateShipment';
import ShippingDefault from './components/ShippingDefault';

type ShippingState = 'new' | 'default';
type Matcher = { [key in AppOrderStatusDto.action]?: ShippingState };
type ComponentMatcher = { [key in ShippingState]: JSX.Element };

const Shipping = ({
  orderStatus,
  productId,
  orderNo,
  pickupAddress,
  paymentMethod,
  requestedPickupDate,
}: {
  orderNo: string;
  orderStatus: AppOrderStatusDto.action;
  // This is just a workaround for packaging details for now
  productId: number;
  pickupAddress?: VendorAddressDto;
  paymentMethod?: VendorOrderDto.paymentMethod | null;
  requestedPickupDate?: string | null;
}) => {
  const { t } = useTranslation(['translation', 'orders']);
  const [actionIsRequired, setActionIsRequired] = useState(false);
  const actionMatcher: Matcher = {
    [AppOrderStatusDto.action.PURCHASE_BASKET_ITEMS]: 'new',
    [AppOrderStatusDto.action.PAID_BASKET_ITEMS]: 'new',
  };

  const componentMatcher: ComponentMatcher = {
    new: (
      <CreateShipment
        productId={productId}
        orderNo={orderNo}
        paymentMethod={paymentMethod}
      />
    ),
    default: (
      <ShippingDefault
        productId={productId}
        pickupAddress={pickupAddress}
        orderActionStatus={orderStatus}
        orderNumber={orderNo}
        requestedPickupDate={requestedPickupDate}
      />
    ),
  };

  const shippingStatusThatRequiresAction: Array<ShippingState | undefined> = [
    'new',
  ];

  useEffect(() => {
    if (shippingStatusThatRequiresAction.includes(actionMatcher[orderStatus])) {
      setActionIsRequired(true);
    } else {
      setActionIsRequired(false);
    }
  }, [orderStatus]);

  return (
    <Spacer
      padding={4}
      className={classNames(
        'bg-white',
        'order-detail__box',
        'order-detail__box--grow',
        'shipping__box',
        actionIsRequired && 'order-detail__box--action',
      )}
    >
      {actionIsRequired && (
        <IconButton
          wrapperClassName="order-detail__action-marker"
          appearance="blank"
          tooltip={t('orders:detail.actionRequired')}
          icon="info-filled"
          color={ColorUse.dark}
        />
      )}

      {componentMatcher[actionMatcher[orderStatus] || 'default']}
    </Spacer>
  );
};

export default Shipping;
