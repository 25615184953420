/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppCreatePaymentSessionRequestDto = {
    paymentProvider: AppCreatePaymentSessionRequestDto.paymentProvider;
    /**
     * Starting from app version x.x.x, this property will be required. Currently, it is optional due to compatibility reasons with old apps.
     */
    basketItemNumbers: Array<string>;
    appsFlyerId?: string;
    appInstanceId?: string;
};

export namespace AppCreatePaymentSessionRequestDto {

    export enum paymentProvider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }


}

