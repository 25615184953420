import IconButton from 'components/IconButton/IconButton';
import { FlexBox } from 'components/Layout/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import TooltipPlace from 'types/TooltipPlace';

const LinkListItemControls = ({
  edit,
  active,
  reallyDelete,
  handleDelete,
  setReallyDelete,
  handleEditCancel,
  handleEdit,
  handleEditSave,
}: {
  edit?: boolean;
  active?: boolean;
  reallyDelete: boolean;
  handleDelete: () => void;
  setReallyDelete: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditCancel: () => void;
  handleEdit: () => void;
  handleEditSave: () => void;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const iconButtonProps = {
    wrapperClassName: 'link-list-item__button',
    tooltipPlace: 'up' as TooltipPlace,
    color: reallyDelete ? Color.warning : Color.primary,
    big: !isDesktop,
  };

  if (edit || reallyDelete) {
    return (
      <FlexBox
        key={edit ? 'edit' : 'delete'}
        className="link-list-item__controls"
      >
        <IconButton
          key="check"
          icon="check"
          type={edit ? 'submit' : 'button'}
          tooltip={reallyDelete ? t('delete') : t('save')}
          onClick={reallyDelete ? handleDelete : handleEditSave}
          appearance="filled"
          {...iconButtonProps}
        />
        <IconButton
          key="close"
          icon="close"
          onClick={
            reallyDelete ? () => setReallyDelete(false) : handleEditCancel
          }
          tooltip={t('cancel')}
          {...iconButtonProps}
        />
      </FlexBox>
    );
  }

  return (
    <FlexBox className="link-list-item__controls">
      {!active && (
        <IconButton
          key="delete"
          icon="delete"
          onClick={() => setReallyDelete(true)}
          tooltip={t('delete')}
          {...iconButtonProps}
        />
      )}
      <IconButton
        key="edit"
        icon="edit"
        onClick={handleEdit}
        tooltip={t('edit')}
        {...iconButtonProps}
      />
    </FlexBox>
  );
};

export default LinkListItemControls;
