import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  ApiError,
  ChannelMinimalResponseDTO,
  ChannelsService,
  PaginatedChannelWithVideosResponseDTO,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import produce from 'immer';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  getChannels = 'getChannels',
  getMinimalChannels = 'getMinimalChannels',
}

export const channelListKeys = {
  getChannels: (vendorAgentId?: number) => [KEYS.getChannels, vendorAgentId],
  getMinimalChannels: (vendorAgentId?: number) => [
    KEYS.getMinimalChannels,
    vendorAgentId,
  ],
};

export const useGetChannels = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useInfiniteQuery<PaginatedChannelWithVideosResponseDTO, ApiError>({
    queryKey: channelListKeys.getChannels(vendorAgent?.currentVendor.id),
    queryFn: async ({ pageParam = 0 }) =>
      ChannelsService.getChannels(pageParam),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};

export const useGetMinimalChannels = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useQuery<ChannelMinimalResponseDTO[]>({
    queryKey: channelListKeys.getMinimalChannels(vendorAgent?.currentVendor.id),
    queryFn: () => ChannelsService.getMinimalChannels(),
  });
};

export const useDeleteChannel = (id: number) => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'channel']);
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const mutation = useMutation<
    ChannelMinimalResponseDTO,
    ApiError,
    { id: number }
  >({
    mutationFn: () => ChannelsService.removeChannel(id),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<
        InfiniteData<PaginatedChannelWithVideosResponseDTO>
      >(channelListKeys.getChannels(vendorAgent?.currentVendor.id), (old) =>
        produce(old, (draft) => {
          if (draft) {
            draft.pages =
              draft?.pages.map((page) => ({
                ...page,
                data: page.data.filter((i) => i.id !== variables.id),
              })) || [];
          }
        }),
      );

      setAppStatus(t('channel:delete.success'), 'success');
    },
    onError: (error) => {
      setAppStatus(t('channel:delete.error'), 'error');
    },
  });

  return mutation;
};
