import { ProductLocalisationResponseDTO } from '../generated';

const getProductPriceByLanguage = (
  localisations: ProductLocalisationResponseDTO[],
  videoLang: string,
) => {
  const showLoc = localisations.find((l) => {
    const locale = new Intl.Locale(l.locale);
    return locale.language === videoLang;
  });

  const price = showLoc ? showLoc.price : localisations[0]?.price;
  const currencyCode = showLoc
    ? showLoc.currencyCode
    : localisations[0]?.currencyCode;

  return {
    amount: price,
    currency: currencyCode,
  };
};

export default getProductPriceByLanguage;
