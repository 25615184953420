import { enGB } from 'date-fns/locale';

const endpoints = {
  ApiUrl: process.env.REACT_APP_API_URL ?? '',
};

export const Htaccess = {
  user: process.env.REACT_APP_HTACCESS_USER ?? '',
  pw: process.env.REACT_APP_HTACCESS_PW ?? '',
};

export const BREAKPOINTS = {
  xs: '30em',
  s: '48em',
  m: '64em',
  l: '80em',
  xl: '90.0625em',
};

export const HEIGHT_BREAKPOINTS = {
  s: '40em',
  m: '44em',
};

export const localeCurrency: { [key: string]: string } = {
  'de-de': 'EUR',
  'in-in': 'INR',
};

export const languageWhiteList = [
  'en',
  'en-IN',
  'de',
  'de-DE',
  'it',
  'fr',
  'hi-IN',
  'hi',
];
export const defaultFallbackLanguage = 'en';
export const defaultFallbackLocale = 'en';
export const defaultFallbackLocaleObject = enGB;

export const maxFileSize = 10485760; //bytes
export const maxImageSize = 2097152; // bytes

export default endpoints;
