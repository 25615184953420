import { default as classNames } from 'classnames';
import Icon, { IconType } from 'components/Icon/Icon';
import { Spacer } from 'components/Layout/Layout';
import LazyImage from 'components/LazyImage/LazyImage';
import Tooltip from 'components/Tooltip/Tooltip';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { VideoResponseDTO } from 'generated';
import placeholder from 'images/placeholder-channel.svg';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Color, { ColorName, ColorUse } from 'types/Color';
import TooltipPlace from 'types/TooltipPlace';
import './chip.scss';
import Loader from 'components/Loader/Loader';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** If you set status, color will be overwritten */
  color?: Color | ColorName | ColorUse;
  status?: VideoResponseDTO.status;
  visibility?: VideoResponseDTO.visibility;
  children: ReactNode;
  hint?: string;
  tooltip?: string;
  tooltipPlace?: TooltipPlace;
  tooltipDelay?: number;
  big?: boolean;
  icon?: IconType;
  iconColor?: Color | ColorUse;
  image?: string;
  placeholderImage?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  link?: string | null;
  download?: boolean;

  /** Add an interactive button on the right */
  onInteraction?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  interactionIcon?: IconType;
  interactionTooltip?: string;
}

const ChipContent = ({
  color = Color.secondary,
  hint,
  children,
  status,
  interactionIcon,
  interactionTooltip,
  onInteraction,
  setIsInteracting,
  isInteracting,
  tooltip,
  visibility,
  big,
  icon,
  iconColor,
  className,
  image,
  placeholderImage,
  tooltipDelay,
  tooltipPlace,
  isHovering,
  disabled,
  isLoading,
  link,
  download,
  ...props
}: Props & {
  setIsInteracting: React.Dispatch<React.SetStateAction<boolean>>;
  isInteracting: boolean;
  isHovering: boolean;
}) => {
  const tooltipTimeout = useRef<NodeJS.Timeout | null>(null);
  const interactionIconSet =
    visibility === VideoResponseDTO.visibility.PRIVATE
      ? 'key'
      : interactionIcon;
  const { t } = useTranslation(['video']);

  const handleInteractionMouseEnter = () => {
    tooltipTimeout.current = setTimeout(() => {
      setIsInteracting(true);
    }, tooltipDelay || 500);
  };

  const handleInteractionMouseLeave = () => {
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    setIsInteracting(false);
  };

  const Tag = (props: Props) => {
    if (props.onClick && !onInteraction) {
      if (link) {
        return (
          <a
            href={link}
            download={download}
            {...(props as React.HTMLAttributes<HTMLAnchorElement>)}
          >
            {props.children}
          </a>
        );
      }
      return (
        <button
          type="button"
          {...(props as React.HTMLAttributes<HTMLButtonElement>)}
        >
          {props.children}
        </button>
      );
    }
    return <div {...props}>{props.children}</div>;
  };

  return (
    <Tag
      className={classNames(
        'chip',
        color &&
          !status &&
          visibility !== VideoResponseDTO.visibility.PRIVATE &&
          `chip--${color}`,
        (hint || visibility === VideoResponseDTO.visibility.PRIVATE || image) &&
          'chip--hint',
        status && `chip--${status}`,
        isInteracting && 'chip--interaction',
        onInteraction && 'chip--interactive',
        visibility && `chip--${visibility}`,
        (big || image) && 'chip--big',
        image && 'chip--image',
        disabled && 'chip--disabled',
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {image && (
        <Spacer marginRight={2} className="chip__image">
          <LazyImage
            src={image || placeholder}
            lqip={placeholderImage || placeholder}
            aspectRatio={1}
            alt={t('list:preview', { title: 'channel' })}
          />
        </Spacer>
      )}
      {icon && (
        <div
          className={classNames(
            'chip__icon',
            iconColor && `chip__icon--${iconColor}`,
          )}
        >
          <Icon icon={icon} />
        </div>
      )}
      {status && <VideoStatusIcon status={status} />}
      <div className="chip__text">{children}</div>
      {(hint || visibility === VideoResponseDTO.visibility.PRIVATE) && (
        <span className="chip__hint">
          {visibility === VideoResponseDTO.visibility.PRIVATE
            ? t('video:visibility.private.label')
            : hint}
        </span>
      )}

      {onInteraction ? (
        <button
          className="chip__interaction"
          onClick={(e) => onInteraction(e)}
          type="button"
          onMouseLeave={handleInteractionMouseLeave}
          onMouseEnter={handleInteractionMouseEnter}
          disabled={disabled}
        >
          {isLoading ? (
            <Loader
              small
              color={disabled ? ColorUse['dark-50'] : ColorUse.dark}
            />
          ) : (
            <Icon
              icon={interactionIcon || (disabled ? 'block' : 'remove-filled')}
            />
          )}
        </button>
      ) : (
        interactionIconSet && (
          <div className="chip__interaction">
            {isLoading ? (
              <Loader
                small
                color={disabled ? ColorUse['dark-50'] : ColorUse.dark}
              />
            ) : (
              <Icon icon={interactionIconSet} />
            )}
          </div>
        )
      )}
    </Tag>
  );
};

const Chip = (props: Omit<Props, 'setTooltipContent'>) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isInteracting, setIsInteracting] = useState(false);
  const tooltipTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    tooltipTimeout.current = setTimeout(() => {
      setIsHovering(true);
    }, props.tooltipDelay || 550);
  };

  const handleMouseLeave = () => {
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    setIsHovering(false);
  };

  useEffect(() => {
    if (isInteracting && tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
  }, [isInteracting]);

  if (props.tooltip || props.interactionTooltip) {
    return (
      <Tooltip
        content={props.tooltip || ''}
        isOpen={isHovering && !isInteracting && props.tooltip !== undefined}
        direction={props.tooltipPlace}
        className={classNames(
          'chip__wrap',
          props.hint && 'chip__wrap--hint',
          (props.big || props.image) && 'chip__wrap--big',
          props.wrapperClassName,
        )}
      >
        <Tooltip
          isOpen={isInteracting && props.interactionTooltip !== undefined}
          content={props.interactionTooltip || ''}
          direction={props.tooltipPlace}
          className="chip__inner"
        >
          <ChipContent
            {...props}
            setIsInteracting={setIsInteracting}
            isInteracting={isInteracting}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHovering={isHovering}
          />
        </Tooltip>
      </Tooltip>
    );
  }

  return (
    <ChipContent
      setIsInteracting={setIsInteracting}
      isInteracting={isInteracting}
      isHovering={isHovering}
      {...props}
    />
  );
};

export default Chip;
