import classNames from 'classnames';
import { FlexBox, FlexItem } from 'components/Layout';
import { AppOrderStatusDto } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import useFormatInTimeZone from 'hooks/useFormatInTimeZone';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from 'components/Collapse/Collapse';

const HistoryItem = ({ createdAt, action }: AppOrderStatusDto) => {
  const date = useFormatInTimeZone({
    date: createdAt,
    formatStr: 'P',
  });
  const time = useFormatInTimeZone({
    date: createdAt,
    formatStr: 'p',
  });

  const { t } = useTranslation(['translation', 'orders']);
  return (
    <li className="order-detail__history-item">
      {createdAt && (
        <div className="order-detail__history-item__date">{date}</div>
      )}
      <FlexBox>
        <FlexItem className="order-detail__history-time">{time}</FlexItem>
        <FlexItem className="order-detail__history-action" marginLeft={1}>
          – {t(`orders:history.action.${action}`)}
        </FlexItem>
      </FlexBox>
    </li>
  );
};

const OrderHistory = ({
  orderStatusHistory,
}: {
  orderStatusHistory: AppOrderStatusDto[];
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [collapsed, setCollapsed] = useState(!isDesktop);

  useEffect(() => {
    setCollapsed(!isDesktop);
  }, [isDesktop]);

  return (
    <div
      className={classNames(
        'order-detail__widget',
        collapsed && 'order-detail__widget--collapsed',
      )}
    >
      <ul className="order-detail__history">
        {(!collapsed ? orderStatusHistory : [orderStatusHistory[0]]).map(
          (item) => (
            <HistoryItem key={item.createdAt} {...item} />
          ),
        )}
      </ul>
      {orderStatusHistory.length > 1 && !isDesktop && (
        <Collapse
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}
    </div>
  );
};

export default OrderHistory;

// t('orders:history.action.cancelOrderBeforeShipping');
// t('orders:history.action.notDeliveredOrder');
// t('orders:history.action.rejectReturnRequest');
// t('orders:history.action.cancelShipment');
// t('orders:status.lostOrder');
// t('orders:history.action.purchaseBasketItems');
// t('orders:history.action.pickupOrderFromWarehouse');
// t('orders:history.action.doPaymentCOD');
// t('orders:history.action.requestReturnByCustomer');
// t('orders:history.action.pickupOrderFromCustomer');
// t('orders:history.action.createShipment');
// t('orders:history.action.createReturnShipment');
// t('orders:history.action.deliverOrderToCustomer');
// t('orders:history.action.deliverOrderToWarehouse');
// t('orders:history.action.refundPayment');
// t('orders:history.action.paidBasketItems');
// t('orders:history.action.closeOrder');
// t('orders:status.closeOrder');
