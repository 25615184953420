import { EntityId } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Spacer } from 'components/Layout/Layout';
import List from 'components/List/List';
import { TFunction } from 'react-i18next';
import { GetNotificationsQueryDTO } from '../../../generated/models/GetNotificationsQueryDTO';
import { NotificationListStatus } from '../../../redux/slices/notifications/types';
import NotificationListItemContainer from './NotificationListItemContainer';

const NotificationList = ({
  statusFilterValue,
  onStatusFilterChange,
  status,
  total,
  itemsVisible,
  hasMore,
  entities,
  onLoadMoreClick,
  t,
}: {
  t: TFunction<string[]>;
  statusFilterValue: GetNotificationsQueryDTO.status;
  onStatusFilterChange: (val: GetNotificationsQueryDTO.status) => void;
  status: NotificationListStatus;
  total: number;
  itemsVisible: number;
  hasMore?: boolean;
  entities: EntityId[];
  onLoadMoreClick: () => void;
}) => {
  const filterTranslations = {
    [GetNotificationsQueryDTO.status.ALL]: t(
      `settings:notifications.statusFilter.all`,
    ),
    [GetNotificationsQueryDTO.status.PENDING]: t(
      `settings:notifications.statusFilter.pending`,
    ),
    [GetNotificationsQueryDTO.status.SENT]: t(
      `settings:notifications.statusFilter.sent`,
    ),
  };

  return (
    <div>
      <div className="notifications-filter">
        <label id="statusFilerLabel" className="notifications-filter__label">
          {t('settings:labels.notifications.statusFilter')}
        </label>

        {Object.values(GetNotificationsQueryDTO.status).map((val) => (
          <button
            aria-labelledby="statusFilerLabel"
            key={val}
            className={classNames({
              'notifications-filter__button': true,
              'notifications-filter__button--active': val === statusFilterValue,
            })}
            onClick={() => onStatusFilterChange(val)}
          >
            {filterTranslations[val]}
          </button>
        ))}
      </div>

      {entities?.length ? (
        <List
          loading={status === 'loading' || status === 'loading-more'}
          count={{ total, itemsVisible }}
          type="pushNotification"
          showLoadMore={hasMore}
          handleLoadMore={() => onLoadMoreClick()}
        >
          {entities.map((item) => (
            <NotificationListItemContainer
              key={item.toString()}
              notificationId={item.toString()}
              t={t}
            />
          ))}
        </List>
      ) : (
        <Spacer tag="p" margin={[2, 0]}>
          {t('settings:notifications.noItems')}
        </Spacer>
      )}
    </div>
  );
};

export default NotificationList;
