import React from 'react';

const ProgressIcon = () => (
  <svg width="0" height="0" viewBox="0 0 307 200">
    <defs>
      <clipPath id="progressClip">
        <path
          d="M136.4 14.4c6.2 2.7 12.1 6 17.6 9.9a92.4 92.4 0 0190-9.9A94.4 94.4 0 01293.7 64 92.7 92.7 0 01208 193a92.6 92.6 0 01-54-17 92.4 92.4 0 01-53.7 17 92.6 92.6 0 01-86-56.6 92.7 92.7 0 010-72.4A94.4 94.4 0 0164 14.4a92.4 92.4 0 0172.5 0zm-36 22.5A62.6 62.6 0 0056 55.3a64.2 64.2 0 000 89.2 62.3 62.3 0 0076 9.6 92.7 92.7 0 01.4-108.3c-9.6-5.9-20.7-9-32-9zm107.5 0h.1a62.6 62.6 0 00-32.4 8.9A92.5 92.5 0 01176 154a62.3 62.3 0 0056.3 3.7 63.3 63.3 0 0019.8-102.5 61.2 61.2 0 00-44.2-18.4zM154.2 68l-.4.7a64.2 64.2 0 00.3 63 63.2 63.2 0 000-63.7z"
          id="a"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ProgressIcon;
