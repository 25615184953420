/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LandingPageDTO } from '../models/LandingPageDTO';
import type { LandingPageResponseDTO } from '../models/LandingPageResponseDTO';
import type { PaginatedReferralResponseDTO } from '../models/PaginatedReferralResponseDTO';
import type { ReferralCreateDTO } from '../models/ReferralCreateDTO';
import type { ReferralResponseDTO } from '../models/ReferralResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferralsService {

    /**
     * List all ReferralLinks that belong to authenticated VendorAgent
     * @param offset
     * @param limit
     * @param search
     * @param source
     * @param medium
     * @returns PaginatedReferralResponseDTO
     * @throws ApiError
     */
    public static getReferralLinks(
        offset?: number,
        limit?: number,
        search?: string,
        source?: string,
        medium?: string,
    ): CancelablePromise<PaginatedReferralResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals',
            query: {
                'offset': offset,
                'limit': limit,
                'search': search,
                'source': source,
                'medium': medium,
            },
        });
    }

    /**
     * Create a new ReferralLink in Firebase
     * @param requestBody
     * @returns ReferralResponseDTO
     * @throws ApiError
     */
    public static createReferralLink(
        requestBody: ReferralCreateDTO,
    ): CancelablePromise<ReferralResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/referrals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update LandingPage settings of Referral
     * @param id
     * @param requestBody
     * @returns LandingPageResponseDTO
     * @throws ApiError
     */
    public static updateReferralLandingPage(
        id: number,
        requestBody: LandingPageDTO,
    ): CancelablePromise<LandingPageResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/referrals/{id}/landingPage',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove the specified ReferralLink
     * @param referralId
     * @returns ReferralResponseDTO
     * @throws ApiError
     */
    public static softRemoveReferralLink(
        referralId: number,
    ): CancelablePromise<ReferralResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/referrals/{referralId}',
            path: {
                'referralId': referralId,
            },
        });
    }

    /**
     * Send an e-mail with the specified referral to all Admins of the current vendor
     * @param referralId
     * @returns void
     * @throws ApiError
     */
    public static sendReferralEmailToAdmins(
        referralId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/referrals/{referralId}/sendmail',
            path: {
                'referralId': referralId,
            },
        });
    }

}
