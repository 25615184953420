import classNames from 'classnames';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { TreeItem } from '../TreeSelect';
import TreeSelectItem from './TreeSelectItem';
import { Spacer } from 'components/Layout';

const TreePanel = ({
  items,
  selectedItems,
  showPanels,
  setShowPanels,
  onSelectChange,
  panelId,
  level,
  setCaption,
}: {
  items?: TreeItem[];
  selectedItems: TreeItem[];
  showPanels: number[];
  setShowPanels: (panel: number, level: number) => void;
  onSelectChange: (item: TreeItem, level: number, checked: boolean) => void;
  panelId: number;
  level: number;
  setCaption: (caption: string) => void;
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const itemsWithChildren = items?.filter(
    (c) => c.children && c.children.length > 0,
  );
  const childrenTree =
    itemsWithChildren && (itemsWithChildren?.length || 0) > 0
      ? itemsWithChildren.map((c) => ({
          children: c.children,
          parentId: c.id,
        }))
      : undefined;

  const openChild = (item: TreeItem) => {
    setShowPanels(item.id, level);
    onSelectChange(item, level, true);
    setCaption(item.name);
  };

  const handleSelect = (item: TreeItem, level: number, checked: boolean) => {
    onSelectChange(item, level, checked);
    if (item.hasChildren || (item.children?.length ?? 0) > 0) {
      setCaption(item.name);
    }
  };

  const panelIsVisible = () => {
    return isDesktop
      ? showPanels?.includes(panelId)
      : showPanels?.[showPanels.length - 1] === panelId;
  };

  return (
    <div className="tree-select__cards" id={`tree-select__cards--${level}`}>
      {panelIsVisible() && (
        <div className="tree-select__card" id={`tree-select__card--${panelId}`}>
          {isDesktop && (
            <Spacer
              tag="h2"
              className="caption tree-select__headline"
              marginBottom={1}
            >
              {selectedItems[level].name}
            </Spacer>
          )}
          {items?.map((c) => (
            <TreeSelectItem
              key={c.id}
              treeItem={c}
              onOpenChild={openChild}
              onSelect={handleSelect}
              level={level}
              selected={selectedItems.map((i) => i.id).includes(c.id)}
            />
          ))}
        </div>
      )}

      {childrenTree &&
        childrenTree.map(({ children, parentId }, i) => (
          <TreePanel
            key={i}
            items={children}
            level={level + 1}
            selectedItems={selectedItems}
            panelId={parentId}
            showPanels={showPanels}
            setShowPanels={setShowPanels}
            // allCategories={allCategories}
            onSelectChange={onSelectChange}
            setCaption={setCaption}
          />
        ))}
    </div>
  );
};

export default TreePanel;
