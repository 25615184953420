import Icon, { IconType } from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout/Layout';
import { ReactElement } from 'react';
import './navi.scss';
import classNames from 'classnames';

export interface Props {
  onClose?: () => void;
  childCenterSlot?: ReactElement;
  childRightSlot?: ReactElement;
  parentCenterSlot?: ReactElement;
  parentRightSlot?: ReactElement;
  childRouteIsActive?: boolean;
  onBackClick: () => void;
  forwardKey: string;
  children?: ReactElement;
  closeIcon?: IconType;
}

const Navi = ({
  onClose,
  childCenterSlot,
  childRightSlot,
  parentCenterSlot,
  parentRightSlot,
  childRouteIsActive = false,
  onBackClick,
  forwardKey,
  children,
  closeIcon = 'close',
}: Props) => {
  return (
    <div className={classNames('navi', childRouteIsActive && 'navi--child')}>
      <FlexBox
        className="navi__inner"
        justifyContent="space-between"
        alignItems="center"
        key={forwardKey}
      >
        <div className="navi__button-wrap">
          {childRouteIsActive && (
            <button
              className="navi__button"
              type="button"
              onClick={onBackClick}
            >
              <Icon icon="arrow-left" />
            </button>
          )}

          {!childRouteIsActive && onClose && (
            <button className="navi__button" type="button" onClick={onClose}>
              <Icon icon={closeIcon} />
            </button>
          )}
        </div>

        <div className="navi__in">
          {childRouteIsActive ? childCenterSlot : parentCenterSlot}
        </div>
        <div className="navi__in">
          {childRouteIsActive ? childRightSlot : parentRightSlot}
        </div>
      </FlexBox>
      {children}
    </div>
  );
};

export default Navi;
