import { useFormik } from 'formik';
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Headline from '../../../components/Headline/Headline';
import { BankingDetailDTO, VendorsService } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import {
  init,
  selectBankingDetail,
} from '../../../redux/slices/auth/authSlice';
import BankDetails from './BankDetails';

const BankDetailsContainer = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const bankingDetails = useReduxSelector(selectBankingDetail);
  const { setAppStatus } = useAppStatus();
  const dispatch = useReduxDispatch();

  const handleBankDetailsDelete = async () => {
    try {
      await VendorsService.deleteBankingDetails();

      dispatch(init());

      setAppStatus(
        t('settings:success.delete_banking_details'),
        'success',
        true,
      );
    } catch (error) {
      setAppStatus(t('settings:error.delete_banking_details'), 'error', true);
    }
  };

  const handleBankDetailsSubmit = async (
    bankingDetailDTO: BankingDetailDTO,
  ) => {
    const iban = electronicFormatIBAN(bankingDetailDTO.iban);

    if (!iban) {
      // this case should never occure, since it is already checked in bankDetailsValidationSchema
      setAppStatus(t('settings:error.update_banking_details'), 'error', true);
      return;
    }

    try {
      await VendorsService.updateBankingDetails({
        ...bankingDetailDTO,
        iban,
      });

      dispatch(init());

      setAppStatus(
        t('settings:success.update_banking_details'),
        'success',
        true,
      );
    } catch (error) {
      setAppStatus(t('settings:error.update_banking_details'), 'error', true);
    }
  };

  const bankDetailsValidationSchema = Yup.object().shape({
    iban: Yup.string()
      .test('iban-test', t('form.iban'), (value) => {
        if (!value) {
          return false;
        }

        let iban = electronicFormatIBAN(value);

        if (!iban) {
          return false;
        }

        return isValidIBAN(iban);
      })
      .required(t('form.required')),
    bic: Yup.string()
      .test('bic-test', t('form.bic'), (value) => {
        if (!value) {
          return false;
        }

        return isValidBIC(value);
      })
      .required(t('form.required')),
    accountName: Yup.string().required(t('form.required')),
    bankName: Yup.string().required(t('form.required')),
  });

  const initialBankingDetails: BankingDetailDTO = bankingDetails || {
    iban: '',
    bic: '',
    accountName: '',
    bankName: '',
  };

  const bankDetailsForm = useFormik({
    initialValues: initialBankingDetails,
    validationSchema: bankDetailsValidationSchema,
    onSubmit: handleBankDetailsSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.bankDetails')}
      </Headline>

      <BankDetails
        ibanFieldProps={bankDetailsForm.getFieldProps('iban')}
        bicFieldProps={bankDetailsForm.getFieldProps('bic')}
        accountNameFieldProps={bankDetailsForm.getFieldProps('accountName')}
        bankNameFieldProps={bankDetailsForm.getFieldProps('bankName')}
        ibanLabel={t('settings:labels.iban')}
        bicLabel={t('settings:labels.bic')}
        accountNameLabel={t('settings:labels.accountName')}
        bankNameLabel={t('settings:labels.bankName')}
        onSubmit={bankDetailsForm.handleSubmit}
        submitText={t(bankingDetails ? 'settings:submit' : 'settings:add')}
        deleteText={t('settings:delete')}
        ibanError={
          bankDetailsForm.touched.iban && bankDetailsForm.errors.iban
            ? t(bankDetailsForm.errors.iban)
            : undefined
        }
        bicError={
          bankDetailsForm.touched.bic && bankDetailsForm.errors.bic
            ? t(bankDetailsForm.errors.bic)
            : undefined
        }
        accountNameError={
          bankDetailsForm.touched.accountName &&
          bankDetailsForm.errors.accountName
            ? t(bankDetailsForm.errors.accountName)
            : undefined
        }
        bankNameError={
          bankDetailsForm.touched.bankName && bankDetailsForm.errors.bankName
            ? t(bankDetailsForm.errors.bankName)
            : undefined
        }
        onDeleteClick={handleBankDetailsDelete}
        bankingDetails={bankingDetails}
      />
    </>
  );
};

export default BankDetailsContainer;
