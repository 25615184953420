import { Dimensions, Validation } from './ImageUploader';

const getImage = (file: File): Promise<HTMLImageElement> => {
  const image = new Image();
  return new Promise((resolve) => {
    image.addEventListener('load', () => resolve(image));
    image.src = URL.createObjectURL(file);
  });
};

const isRatioInvalid = (image: HTMLImageElement, validRatio: Dimensions) => {
  const ratio = validRatio.width / validRatio.height;

  return image.width / image.height !== ratio;
};

const isMinDimensionsInvalid = (
  image: HTMLImageElement,
  validDimensions: Dimensions,
) =>
  image.width < validDimensions.width && image.height < validDimensions.height;

const isMaxDimensionsInvalid = (
  image: HTMLImageElement,
  validDimensions: Dimensions,
) =>
  image.width > validDimensions.width && image.height > validDimensions.height;

const isAcceptTypeInvalid = (acceptType: string[], fileName: string) => {
  const type: string = fileName.split('.').pop() || '';

  return (
    acceptType.findIndex((item) => item.toLowerCase() === type.toLowerCase()) <
    0
  );
};

const isMaxFileSizeInvalid = (fileSize: number, maxFileSize: number) => {
  return fileSize > maxFileSize;
};

export const getErrors = async (validation: Validation, file?: File) => {
  if (file) {
    const imgage = await getImage(file);

    const errors = {
      ...(validation.acceptType &&
        isAcceptTypeInvalid(validation.acceptType, file.name) && {
          acceptType: true,
        }),
      ...(validation.ratio &&
        isRatioInvalid(imgage, validation.ratio) && {
          ratio: true,
        }),
      ...(validation.minDimensions &&
        isMinDimensionsInvalid(imgage, validation.minDimensions) && {
          minDimensions: true,
        }),
      ...(validation.maxDimensions &&
        isMaxDimensionsInvalid(imgage, validation.maxDimensions) && {
          maxDimensions: true,
        }),
      ...(validation.maxFileSize &&
        isMaxFileSizeInvalid(file.size, validation.maxFileSize) && {
          maxFileSize: true,
        }),
    };

    return Object.keys(errors).length ? errors : undefined;
  }
};
