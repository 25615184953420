import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { useGetAppVersions } from '../../../hooks/queries';
import { InputProps } from '../../../types/InputType';

const UpdateAppVersionsForm = ({
  onSubmit,
}: {
  onSubmit: (values: { ios: string; android: string }) => void;
}) => {
  const { t } = useTranslation(['translation', 'settings']);
  const { data } = useGetAppVersions();

  const appVersionsValidationSchema = Yup.object().shape({
    ios: Yup.string()
      .required(t('form.required'))
      .matches(
        /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/gm,
        t('form.semver-criteria'),
      ),
    android: Yup.string()
      .required(t('form.required'))
      .matches(
        /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/gm,
        t('form.semver-criteria'),
      ),
  });

  const updateAppVersionsForm = useFormik({
    initialValues: {
      ios: data.ios.version,
      android: data.android.version,
    },
    validationSchema: appVersionsValidationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const iosInputProps: InputProps = {
    ...updateAppVersionsForm.getFieldProps('ios'),
    label: t(`settings:labels.ios-label`),
    error:
      updateAppVersionsForm.touched.ios && updateAppVersionsForm.errors.ios
        ? t(updateAppVersionsForm.errors.ios)
        : undefined,
  };

  const androidInputProps: InputProps = {
    ...updateAppVersionsForm.getFieldProps('android'),
    label: t(`settings:labels.android-label`),
    error:
      updateAppVersionsForm.touched.android &&
      updateAppVersionsForm.errors.android
        ? t(updateAppVersionsForm.errors.android)
        : undefined,
  };
  return (
    <form onSubmit={updateAppVersionsForm.handleSubmit}>
      <div className="settings__block settings__block--50">
        <Input {...iosInputProps} />
      </div>

      <div className="settings__block settings__block--50">
        <Input {...androidInputProps} />
      </div>

      <Button type="submit" text={t('settings:submit')} />
    </form>
  );
};

export default UpdateAppVersionsForm;
