import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import './add-button.scss';

export type Props = {
  title?: string;
  subtitle?: string;
  buttonText?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
/** Button to add at the end of lists or select options  */
const AddButton = ({ title, subtitle, buttonText, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <button className="list-item add-button " type="button" {...props}>
      <FlexBox alignItems="center" justifyContent="space-between">
        <FlexItem flexGrow={1} className="add-button__content">
          {title && <div className="list-item__title">{title}</div>}
          {subtitle && <div className="list-item__subtitle">{subtitle}</div>}
        </FlexItem>

        <FlexItem
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          flexShrink={0}
        >
          <FlexItem
            display="inline-flex"
            className="add-button__button"
            alignItems="center"
            pushRight
          >
            <span>{buttonText || t('add')}</span>
            <Icon icon="add-filled" />
          </FlexItem>
        </FlexItem>
      </FlexBox>
    </button>
  );
};

export default AddButton;
