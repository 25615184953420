import HashtagSelect from 'components/HashtagSelect/HashtagSelect';
import { OptionWithIconProps } from 'components/SelectInteractive';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { FilterItem } from 'features/video-filter/types';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useGetHashtagFilter } from '../../queries';
import FilterDetail from './FilterDetail';
import { InfiniteScrollProps } from 'components/SelectInteractive/SelectInteractive';
import { ColorUse } from 'types/Color';

const HashtagFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetHashtagFilter(
    debouncedSearchTerm as string,
  );

  const handleChange = (newValue: MultiValue<OptionWithIconProps>) => {
    setFilter(
      'hashtag',
      newValue?.map(
        ({ value, label }) =>
          ({
            id: value,
            name: label,
            chipProps: {
              icon: 'hashtag',
              iconColor: ColorUse['dark-50'],
            },
          } as FilterItem),
      ),
    );
  };

  const getValue = () => {
    return (
      filter?.hashtag?.map(({ id, name }) => {
        return { value: id.toString(), label: name };
      }) || []
    );
  };

  const loadMore = () => {
    fetchNextPage();
  };

  const infiniteProps: InfiniteScrollProps = {
    dataLength: data?.pages.map((i) => i.data).flat().length || 0,
    next: () => loadMore(),
    hasMore: hasNextPage || false,
  };

  return (
    <FilterDetail>
      <HashtagSelect
        {...searchableProps}
        infiniteScrollProps={infiniteProps}
        filterOption={() => true}
        isMulti
        variant="open"
        hashtags={data?.pages.map((i) => i.data).flat()}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        noOptionsMessage={
          data?.pages.map((i) => i.data).flat().length === 0 &&
          (debouncedSearchTerm?.length || 0) === 0
            ? t('video:filter.hashtag.empty')
            : t('noOptions')
        }
      />
    </FilterDetail>
  );
};

export default HashtagFilter;
