import classNames from 'classnames';
import { Field, FieldArray, useField } from 'formik';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { TextInfoDTO } from '../../generated';
import { randomString } from '../../utils/random-string';
import Icon from '../Icon/Icon';
import InputField from '../Input/InputField';
import TextAreaField from '../Input/TextAreaField';
import './labeled-info-text.scss';
import LabeledInfoTextControls from './LabeledInfoTextControls';

export interface Props {
  name: string;
  className?: string;
}

const LabeledInfoTextFormComponent = ({ className, name }: Props) => {
  const [field, { error, touched }] = useField<TextInfoDTO[]>(name);
  const { t } = useTranslation();
  const classString = 'labeled-info-text';

  const handleKeyDown = (event: KeyboardEvent, namePrefix: string) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextSibling: HTMLElement | null = document.getElementById(
        `${namePrefix}.text`,
      );

      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  return (
    <div
      className={classNames(
        classString,
        `${classString}--edit`,
        error && touched && `${classString}--error`,
        className,
      )}
    >
      <div className={`${classString}__form`}>
        {field.value && (
          <FieldArray
            name={name}
            render={(arrayHelpers) => (
              <>
                <Flipper flipKey={JSON.stringify(field.value)}>
                  {field.value.map((v, i) => (
                    <Flipped key={v.id} flipId={v.id} translate>
                      <div className={`${classString}__block`}>
                        <LabeledInfoTextControls
                          moveItemUp={() => arrayHelpers.move(i, i - 1)}
                          moveItemDown={() => arrayHelpers.move(i, i + 1)}
                          removeItem={arrayHelpers.handleRemove(i)}
                          showDownButton={i + 1 < field.value.length}
                          showUpButton={i - 1 >= 0}
                        />
                        <div className={`${classString}__inputs`}>
                          <button
                            type="button"
                            className={`${classString}__label-control`}
                            onClick={() =>
                              arrayHelpers.replace(i, {
                                ...v,
                                label:
                                  v.label || v.label === '' ? undefined : '',
                              })
                            }
                          >
                            <span
                              className={`${classString}__label-control-text`}
                            >
                              {v.label || v.label === ''
                                ? t('edit:label.remove')
                                : t('edit:label.add')}
                            </span>
                            <span
                              className={`${classString}__label-control-icon`}
                            >
                              {v.label || v.label === '' ? (
                                <Icon icon="label-filled" />
                              ) : (
                                <Icon icon="label" />
                              )}
                            </span>
                          </button>
                          {(v.label || v.label === '') && (
                            <Field
                              component={InputField}
                              className={`${classString}__textarea ${classString}__label`}
                              name={`${name}.${i}.label`}
                              placeholder={
                                v.label === ''
                                  ? t('edit:label.addOptional')
                                  : undefined
                              }
                              onKeyDown={(e: KeyboardEvent) =>
                                handleKeyDown(e, `${name}.${i}`)
                              }
                            />
                          )}
                          <Field
                            component={TextAreaField}
                            className={`${classString}__textarea ${classString}__text`}
                            name={`${name}.${i}.text`}
                            id={`${name}.${i}.text`}
                            placeholder={
                              v.text === '' ? t('edit:text.add') : undefined
                            }
                          />
                        </div>
                      </div>
                    </Flipped>
                  ))}
                </Flipper>

                <button
                  className={`${classString}__block ${classString}__add`}
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      label: '',
                      text: '',
                      id: randomString(),
                    })
                  }
                >
                  <div className={`${classString}__controls`}>
                    <div
                      className={`${classString}__button ${classString}__button--add`}
                    >
                      <span className="hidden-visually">
                        {t('edit:block.add')}
                      </span>
                      <Icon icon="add" />
                    </div>
                  </div>
                  <div className={`${classString}__add-text`}>
                    {t('edit:block.add')}
                  </div>
                </button>
                {error && touched && (
                  <div className={`input__error ${classString}__field-error`}>
                    {error}
                  </div>
                )}
              </>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default LabeledInfoTextFormComponent;
