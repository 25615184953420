import Status, { Props } from 'components/Status/Status';
import { AppOrderStatusDto } from 'generated';
import { useTranslation } from 'react-i18next';
import {
  getOrderActionColorByStatus,
  getPeriodKeyByOrderAction,
} from 'utils/order-action';
import './order-shipping-status.scss';

const OrderShippingStatus = ({
  status = AppOrderStatusDto.action.PURCHASE_BASKET_ITEMS,
  className,
  ...props
}: Omit<Props, 'action' | 'tooltip' | 'color'> & {
  status: AppOrderStatusDto.action;
}) => {
  const { t } = useTranslation();
  const periodKey = getPeriodKeyByOrderAction(status);
  const color = getOrderActionColorByStatus(status);

  const getStatusTexts = () => {
    const needsSpecificStatus = [
      AppOrderStatusDto.action.DELIVER_ORDER_TO_WAREHOUSE,
      AppOrderStatusDto.action.REFUND_PAYMENT,
    ];

    if (needsSpecificStatus.includes(status)) {
      return {
        status: t(`orders:detail.shipping.status.${status}.title`),
        tooltip: t(`orders:detail.shipping.status.${status}.tooltip`),
      };
    } else {
      return {
        status: t(`orders:periodKey.${periodKey}.title`),
        tooltip: t(`orders:periodKey.${periodKey}.tooltip`),
      };
    }
  };

  return <Status {...getStatusTexts()} color={color} {...props} />;
};

export default OrderShippingStatus;

// t('orders:detail.shipping.status.deliverOrderToWarehouse.title')
// t('orders:detail.shipping.status.deliverOrderToWarehouse.tooltip')
// t('orders:detail.shipping.status.refundPayment.title')
// t('orders:detail.shipping.status.refundPayment.tooltip')
