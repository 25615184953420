import ProductSelectField from 'components/ProductSelect/ProductSelectField';
import { Field } from 'formik';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetProducts } from '../queries';

export type Props = {
  error?: string;
  success?: string;
};

const ProductAsyncSelectField = ({ error, success }: Props) => {
  const [emptyText, setEmptyText] = useState(null);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetProducts(
    debouncedSearchTerm as string,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate('/products/new');
  };

  useEffect(() => {
    if (
      status === 'success' &&
      data.pages.map((i) => i.data).flat().length === 0
    ) {
      if (searchableProps.inputValue.replace(/\s/g, '').length > 0) {
        setEmptyText(t('product:select.searchNotFound'));
      } else {
        setEmptyText(t('product:select.empty'));
      }
    } else if (status === 'error') {
      setEmptyText(t('product:select.notFound'));
    } else {
      setEmptyText(t('product:select.empty'));
    }
  }, [searchableProps.inputValue, data, status]);

  return (
    <Field
      {...searchableProps}
      component={ProductSelectField}
      products={data?.pages.map((i) => i.data).flat()}
      isLoading={status === 'loading'}
      onLoadMore={() => fetchNextPage()}
      hasNextPage={hasNextPage}
      error={error}
      success={success}
      noOptionsMessage={emptyText}
      onCreateOption={handleCreate}
      name="product"
      variant="open"
    />
  );
};

export default ProductAsyncSelectField;
