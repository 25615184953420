import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AcceptedTermsResponseDTO,
  PlatformTermsService,
  TermsResponseDTO,
} from '../../../generated';
import { RootState } from '../../store';
import { init, logIn } from '../auth/authSlice';

export type TermsState = Partial<AcceptedTermsResponseDTO>;

const initialState: TermsState = {
  acceptedDate: undefined,
  termsInfo: undefined,
};

export const acceptOrDeclineTerms = createAsyncThunk(
  'terms/accept',
  async ({ id, accepted }: { id: number; accepted: boolean }) => {
    try {
      const response = await PlatformTermsService.acceptTerms(id, {
        accepted,
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  },
);

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    updated: (state, action: PayloadAction<TermsResponseDTO>) => {
      state.acceptedDate = undefined;
      state.termsInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptOrDeclineTerms.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.acceptedDate = action.payload.termsInfo?.acceptedDate;
        state.termsInfo = action.payload.termsInfo?.termsInfo;
      })
      .addCase(init.fulfilled, (state, action) => {
        state.acceptedDate = action.payload.termsInfo?.acceptedDate;
        state.termsInfo = action.payload.termsInfo?.termsInfo;
      });
  },
});

export const selectTermsAcceptedDate = (state: RootState) =>
  state.terms.acceptedDate;
export const selectTermsInfo = (state: RootState) => state.terms.termsInfo;

// Action creators are generated for each case reducer function
export const { updated: updateTerms } = termsSlice.actions;

export default termsSlice.reducer;
