import Chip, { Props as ChipProps } from 'components/Chip/Chip';
import { FlexBox, Spacer } from 'components/Layout/Layout';
import {
  InfiniteMenuList,
  OptionWithIcon,
  selectInteractiveStyles,
} from 'components/SelectInteractive';
import { DefaultOption } from 'components/SelectInteractive/components/Option';
import SelectInteractive, {
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive/SelectInteractive';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, InputActionMeta, MultiValue, Options } from 'react-select';
import { ColorUse } from 'types/Color';
import randomString from 'utils/random-string';
import './creatable-select.scss';

export type Props<OptionType extends DefaultOption> = Omit<
  SelectInteractiveProps<OptionType, true>,
  | 'hint'
  | 'isOptionDisabled'
  | 'filterOption'
  | 'isValidNewOption'
  | 'isClearable'
  | 'hideSelectedOptions'
  | 'infiniteScrollProps'
  | 'components'
  | 'isMulti'
  | 'onCreateOption'
  | 'onInputChange'
  | 'showNativeSelectOnMobile'
  | 'value'
  | 'onChange'
  | 'options'
> & {
  /** Form error message. */
  error?: string;
  /** When there is an error while creating a new option,
   * this error message can be set in addition to any general form errors. */
  onCreateErrorMessage?: string;
  /** Adds an infinite scroll function for paginated options.
   * It should update the options by adding the newly fetched options of the next page.
   * This will only be called when hasNextPage is true. */
  onLoadMore?: () => void;
  /** Decides wether onLoadMore is called again if infinite scroll is used. */
  hasNextPage?: boolean;
  chipProps?: Partial<ChipProps>;
  /** Provide a function of what happens when the create button is clicked,
   * it should add the newly created option to the options array.*/
  onCreateOption: (inputValue: string) => void;
  /** This should update the inputValue prop. Use the useSearchabelSelect hook to get the basic functions and props. */
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  value?: OptionType[];
  options?: OptionType[];
  onChange?: (
    newValue: OptionType[],
    actionMeta: ActionMeta<OptionType>,
  ) => void;
};

/**
 * This component renders a controlled searchable multiple select with infinite-scroll paginated options and an add-button.
 * Use the useSearchabelSelect hook to get the basic functions and props.
 * If you need this to work as a Formik Field use CreatableMultiSelectField.
 */
const CreatableMultiSelect = (props: Props<DefaultOption>) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [hint, setHint] = useState<string>();

  const {
    options,
    value,
    onChange,
    onCreateOption,
    onInputChange,
    inputValue,
    isLoading,
    chipProps,
    label,
    hiddenLabel,
    description,
  } = props;

  const handleRemove = (removeValue: DefaultOption) => {
    onChange?.(
      (value as DefaultOption[])?.filter((i) => i.value !== removeValue.value),
      {} as ActionMeta<DefaultOption>,
    );
  };

  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to "",
    // prevent setting a value cleans the input
    if (
      event.action !== 'input-blur' &&
      event.action !== 'menu-close' &&
      event.action !== 'set-value'
    ) {
      onInputChange?.(inputText, event);
    }
  };

  const maxCount =
    (value as DefaultOption[]).length >= (props.maxSelectedOptions || 5);

  const handleShowCreateButton = () => {
    if (inputValue) {
      const find = (options as DefaultOption[])?.find(
        (o) => o.label === inputValue,
      );

      return !isLoading &&
        inputValue.replace(/\s/g, '').length >= 3 &&
        !find &&
        !maxCount
        ? true
        : false;
    }
    return false;
  };

  const infiniteScrollProps = {
    dataLength: options?.length || 0,
    next: () => props.onLoadMore?.(),
    hasMore: props.hasNextPage || false,
  };

  const selectId = props.id || `${props.name}--${randomString()}`;

  useEffect(() => {
    if (maxCount) {
      setHint(props.maxSelectedOptionsMessage || t('form.max'));
    } else {
      setHint(undefined);
    }
  }, [value]);

  const handleIsOptionDisabled = (
    option: DefaultOption,
    selectValue: Options<DefaultOption>,
  ) => {
    if (maxCount) {
      if (selectValue.includes(option)) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <div className="creatable-select">
      {label && !hiddenLabel && (
        <label className="select__label" htmlFor={selectId}>
          {label}
        </label>
      )}
      {description && (
        <Spacer
          tag="p"
          marginBottom={2}
          marginTop={1}
          className="small select-interactive__description"
        >
          {description}
        </Spacer>
      )}
      {props.onCreateErrorMessage && (
        <Spacer
          tag="p"
          className="small creatable-select__error"
          marginBottom={2}
        >
          {props.onCreateErrorMessage}
        </Spacer>
      )}
      <FlexBox className="creatable-select__values" flexWrap="wrap">
        {Array.isArray(value) &&
          (value as DefaultOption[])?.map((i, index) =>
            isDesktop ? (
              <Chip
                color={ColorUse['secondary-50']}
                key={i.value}
                onInteraction={() => handleRemove(i)}
                interactionIcon="remove-filled"
                {...chipProps}
              >
                {i.label}
              </Chip>
            ) : (
              <span key={i.value}>
                {props.createButtonPrefix ? props.createButtonPrefix : ''}
                {i.label}
                {index !== value.length - 1 ? <>,&nbsp;</> : ' '}
              </span>
            ),
          )}
      </FlexBox>

      <SelectInteractive<DefaultOption, true>
        {...props}
        id={selectId}
        label={undefined}
        description={undefined}
        isMulti
        components={{
          Option: OptionWithIcon,
          MenuList: InfiniteMenuList,
        }}
        infiniteScrollProps={infiniteScrollProps}
        onCreateOption={onCreateOption}
        hideSelectedOptions
        styles={{
          menu: (provided, state) => ({
            ...selectInteractiveStyles<DefaultOption, true>()?.menu?.(
              provided,
              state,
            ),
            position: 'relative',
          }),
        }}
        onInputChange={handleInputChange}
        isValidNewOption={handleShowCreateButton}
        isOptionDisabled={handleIsOptionDisabled}
        hint={hint}
        onChange={
          props.onChange as (
            newValue: MultiValue<DefaultOption>,
            actionMeta: ActionMeta<DefaultOption>,
          ) => void
        }
        backspaceRemovesValue={false}
        filterOption={() => true}
      />
    </div>
  );
};

export default CreatableMultiSelect;
