import React, { FC } from 'react';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Footer from '../components/Footer/Footer';
import ProductDetailContainer from '../container/ProductDetailContainer/ProductDetailContainer';
import PageType from '../types/PageType';

const AddProductPage: FC<PageType> = () => {
  return (
    <div className="page page--detail">
      <div className="page__breadcrumb">
        <Breadcrumbs />
      </div>
      <ProductDetailContainer />
      <Footer />
    </div>
  );
};
export default AddProductPage;
