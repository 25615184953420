import { useState } from 'react';
import { useDebounce } from 'use-debounce';

export type SearchableSelectHookProps = {
  /** Change the inputValue while typing. The default will remove all not diacretic characters from the string.*/
  normalizeInputValue?: (inputValue?: string) => void;
  /** If you want something additional happening onInputChange. */
  onInputChange?: (inputValue: string, event: any) => void;
  /** If you want something additional happening onClear. */
  onClear?: () => void;
};

export const normalizeInputValue = (value: string) =>
  value?.replace(/[^\p{L}\p{M}\p{N}\p{Z}&]/gu, '').toLowerCase();

/** This hook provides the basic props and functions needed to get CreatableMultiSelect to work correctly.
 * It handles the controlled inputValue and also offers a debouncedSearchTerm value to use in API-calls.
 * This can also be used for any searchable List.
 */
const useSearchableSelect = (config?: SearchableSelectHookProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const normalize = config?.normalizeInputValue || normalizeInputValue;

  const [debouncedSearchTerm] = useDebounce(normalize(searchTerm), 500);

  const clearInputValue = () => {
    onInputChange('', null);
    config?.onClear?.();
  };

  const onInputChange = (inputText: string, event: any) => {
    setSearchTerm(inputText);
    config?.onInputChange?.(inputText, event);
  };

  return {
    debouncedSearchTerm,
    clearInputValue,
    setInputValue: setSearchTerm,
    searchableProps: {
      onInputChange,
      inputValue: searchTerm,
      onClearInputValue: clearInputValue,
    },
  };
};

export default useSearchableSelect;
