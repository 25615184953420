import classNames from 'classnames';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import OrderActionChip from 'components/OrderActionChip/OrderActionChip';
import { AppOrderStatusDto, InternalOrderBasicProductDto } from 'generated';
import './order-list-item.scss';
import { useTranslation } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import Icon from 'components/Icon/Icon';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { orderActionPeriods } from 'utils/order-action';

export type Props = {
  orderNo: string | null;
  createdAt?: string;
  currentOrderStatus: AppOrderStatusDto | null;
  product?: InternalOrderBasicProductDto;
  onClick?: () => void;
};

const OrderListItem = ({
  currentOrderStatus,
  orderNo,
  createdAt,
  product,
  onClick,
}: Props) => {
  const { t } = useTranslation(['translation', 'orders']);
  const timeZone = useReduxSelector(selectTimezone);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const getPeriodKeyFromAction = () => {
    return (
      Object.keys(orderActionPeriods).find((key) =>
        orderActionPeriods[key].includes(
          currentOrderStatus?.action as AppOrderStatusDto.action,
        ),
      ) || 'process'
    );
  };
  return (
    <FlexBox
      className={classNames(
        'order-list-item',
        currentOrderStatus && `order-list-item--${getPeriodKeyFromAction()}`,
        onClick && 'order-list-item--action',
      )}
      tag="li"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
    >
      <FlexItem className="order-list-item__box order-list-item__number-box">
        <div className="order-list-item__box-inner">
          <div className="order-list-item__number">{orderNo}</div>
          {createdAt && (
            <div className="order-list-item__date">
              {t('date', { date: utcToZonedTime(createdAt, timeZone) })}
            </div>
          )}
        </div>
      </FlexItem>
      <FlexItem
        className="order-list-item__box "
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        {isDesktop && (
          <FlexItem className="order-list-item__content" flexGrow={1}>
            {product?.name && (
              <div className="order-list-item__title">{product.name}</div>
            )}
            {(product?.vendorProductId || product?.sku) && (
              <div className="order-list-item__subtitle">
                {product.vendorProductId || product.sku}
              </div>
            )}
          </FlexItem>
        )}

        <FlexItem className="order-list-item__status" pushRight>
          {currentOrderStatus && (
            <OrderActionChip status={currentOrderStatus?.action} />
          )}
          {currentOrderStatus?.createdAt && isDesktop && (
            <Spacer className="order-list-item__date" marginTop={1}>
              {t('date', {
                date: utcToZonedTime(currentOrderStatus?.createdAt, timeZone),
              })}
            </Spacer>
          )}
        </FlexItem>

        {onClick && (
          <FlexItem
            className="order-list-item__arrow"
            display="flex"
            alignItems="center"
            marginLeft={2}
          >
            <Icon icon="caret-right" />
          </FlexItem>
        )}
      </FlexItem>
    </FlexBox>
  );
};

export default OrderListItem;
