import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Props as ReactModalProps } from 'react-modal';
import Color from '../../types/Color';
import Button from '../Button/Button';
import Modal, { ModalVariant } from '../Modal/Modal';

export type Props = ReactModalProps & {
  headline: string;
  text: string;
  onCancelClick: () => void;
  onConfirmClick?: () => void;
  confirmLoading?: boolean;
  cancelText?: string;
  confirmText: string;
  confirmLink?: string;
  variant?: ModalVariant;
};

/** Render a react-modal component that shows a text, a confirm and a cancel button. If you need to add your own content to the Modal please use the internally used Modal component. */
const ConfirmModal = ({
  headline,
  text,
  onCancelClick,
  onConfirmClick,
  confirmLoading,
  cancelText,
  confirmText,
  confirmLink,
  ...props
}: Props) => {
  const { t } = useTranslation(['translation']);

  const className = classNames('modal', props.className);
  const overlayClassName = classNames('modal__overlay', props.overlayClassName);

  return (
    <Modal
      {...props}
      className={className}
      overlayClassName={overlayClassName}
      headline={headline}
      onClose={onCancelClick}
      onRequestClose={onCancelClick}
    >
      <div className="modal__text">{text}</div>

      <div className="modal__controls">
        <Button
          text={cancelText || t('cancel')}
          type="button"
          onClick={onCancelClick}
          color={Color.primary}
          appearance="ghost"
        />

        <Button
          text={confirmText}
          type="button"
          onClick={onConfirmClick}
          color={Color.primary}
          loading={confirmLoading}
          link={confirmLink}
        />
      </div>
    </Modal>
  );
};

export default ConfirmModal;
