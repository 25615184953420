import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { ApiError, LinksDTO } from 'generated';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Color from '../../types/Color';
import { randomString } from '../../utils/random-string';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { Props as ListItemType } from '../ListItem/ListItem';
import Loader from '../Loader/Loader';
import './list.scss';

export interface CompProps {
  className?: string;
  children?: Array<ReactElement<ListItemType>>;
  selectAll?: {
    checked: boolean;
    onSelectAll: (checked: boolean) => void;
  };
  errorMsg?: string;
  rightSlot?: ReactElement;
}

export type PaginatedResponseMinimal = {
  total: number;
  links: LinksDTO;
};

export type Props<ResponseType extends PaginatedResponseMinimal> =
  UseInfiniteQueryResult<ResponseType> & CompProps;

const QueryList = <ResponseType extends PaginatedResponseMinimal>({
  className,
  isLoading,
  isFetching,
  isError,
  children,
  data,
  hasNextPage,
  fetchNextPage,
  selectAll,
  errorMsg,
  rightSlot,
}: Props<ResponseType> & UseInfiniteQueryResult<ResponseType, ApiError>) => {
  const { t } = useTranslation(['list']);
  const itemsVisible = data?.pages?.map((i: any) => i.data).flat().length || 0;
  const totals = data?.pages[data?.pages.length - 1]?.total;
  const hasItems = itemsVisible || -1 > 0;

  return (
    <div
      className={classNames(
        'list',
        selectAll && 'list--select',
        'query-list',
        className,
      )}
    >
      <div className="list__controls">
        {selectAll && (
          <Checkbox
            name={randomString()}
            onChange={selectAll.onSelectAll}
            checked={selectAll.checked}
          />
        )}
        <div className="list__count">
          {t('list:amount-query', {
            itemsVisible,
            total: (data?.pages.length || 0) < 1 ? itemsVisible : totals,
          })}
        </div>
        {rightSlot && <div className="list__right-slot">{rightSlot}</div>}
      </div>
      {isError && (errorMsg || t('list:error'))}
      {isLoading && !children && <Loader />}
      {hasItems && children && (
        <div className="list__container">
          <ul className="list__items">{children}</ul>

          {hasNextPage && (
            <div className="list__more">
              {isFetching && <Loader small />}
              <Button
                text={t('list:load_more')}
                icon="arrow-down"
                color={Color.primary}
                appearance="ghost"
                className="list__button-more"
                onClick={() => fetchNextPage()}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QueryList;
