import { GetNotificationsQueryDTO, NotificationDTO } from '../../../generated';

export type NotificationListStatus =
  | 'idle'
  | 'loading'
  | 'loading-more'
  | 'loading-delete'
  | 'loading-submit'
  | 'failed';

export enum NotificationDraftType {
  NEW = 'new',
  EDIT = 'edit',
  COPY = 'copy',
}

export enum NotificationThunkError {
  ADD = 'add_notification_error',
  EDIT = 'edit_notification_error',
  FETCH = 'fetch_notification_error',
}

export type DraftPlatforms = Partial<['ios', 'android']>;

export type NotificationDraftNew = Omit<NotificationDTO, 'android' | 'ios'> & {
  type: NotificationDraftType.NEW;
  platforms: DraftPlatforms;
};

export type NotificationDraftEdit = Omit<NotificationDTO, 'android' | 'ios'> & {
  type: NotificationDraftType.EDIT;
  id: number;
  platforms: DraftPlatforms;
};

export type NotificationDraftCopy = Omit<NotificationDTO, 'android' | 'ios'> & {
  type: NotificationDraftType.COPY;
  id: number;
  platforms: DraftPlatforms;
};

export type NotificationDraft =
  | NotificationDraftNew
  | NotificationDraftEdit
  | NotificationDraftCopy;

export interface NotificationsState {
  total: number;
  itemsVisible: number;
  status: NotificationListStatus;
  hasMore?: boolean;
  filter: {
    status: GetNotificationsQueryDTO.status;
  };
  draft: NotificationDraft | undefined;
  error: string | undefined;
}
