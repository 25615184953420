/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreferredLanguageDTO } from './PreferredLanguageDTO';

export type VendorAgentResponseDTO = {
    id: number;
    email: string;
    name: string;
    apiKeySecret?: string;
    role: VendorAgentResponseDTO.role;
    vendorId: number;
    preferredLanguages?: Array<PreferredLanguageDTO>;
};

export namespace VendorAgentResponseDTO {

    export enum role {
        SALES_AGENT = 'sales_agent',
        SALES_ADMIN = 'sales_admin',
        REPORTER = 'reporter',
        ADMIN = 'admin',
        SUPER_ADMIN = 'super_admin',
    }


}

