import { useMutation } from '@tanstack/react-query';
import { ApiError, VideoResponseDTO, VideosService } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import QUERY_KEYS from 'types/QueryKeys';

export const useDeleteVideo = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'video']);

  return useMutation<VideoResponseDTO, ApiError, { videoId: number }>({
    mutationFn: ({ videoId }) => VideosService.deleteVideo(videoId),
    onSuccess: () => {
      setAppStatus(t('video:delete.success'));
      queryClient.invalidateQueries([
        QUERY_KEYS.getVideos,
        vendorAgent?.currentVendor.id,
      ]);
    },
    onError: (error) => {
      if (error.status === 409) {
        setAppStatus(t('video:delete.error--active'), 'error');
      } else {
        setAppStatus(t('video:delete.error'), 'error');
      }
    },
  });
};
