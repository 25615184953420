import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Headline from 'components/Headline/Headline';
import Loader from 'components/Loader/Loader';
import ActivateToggle from 'components/ActivateToggle/ActivateToggle';
import { KeyValueDTO } from 'generated/models/KeyValueDTO';
import { KeyValueStoreService } from 'generated/services/KeyValueStoreService';
import useAppStatus from 'hooks/useAppStatus';
import { setKeyValueStoreItem } from 'redux/slices/kvstore/kvstoreSlice';

const ToggleShowShipping = () => {
  const [showShipping, setShowShipping] = useState<boolean | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();

  const getValue = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  const setValue = async (): Promise<void> => {
    setLoading(true);
    try {
      setKeyValueStoreItem({
        key: KeyValueDTO.key.CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT,
        value: showShipping ? 'false' : 'true',
      });
      const keyValueDto = await KeyValueStoreService.setKeyValueStoreItem({
        key: KeyValueDTO.key.CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT,
        value: showShipping ? 'false' : 'true',
      });
      setShowShipping(keyValueDto.value === 'true');
    } catch (error) {
      setShowShipping(false);
      setAppStatus(t('settings:toggle-enable-shipping.error'), 'error');
    }
    setLoading(false);
  };

  useEffect(() => {
    getValue().then((_showShipping) => setShowShipping(_showShipping));
  }, []);

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:toggle-enable-shipping.headline')}
      </Headline>

      <p className="settings__block page__form-desc">
        {t('settings:toggle-enable-shipping.description')}
      </p>

      {showShipping === undefined ? (
        <Loader />
      ) : (
        <div>
          <label id="statistic-shipping-toggle-desc" className="input__label">
            {t('edit')}
          </label>

          <ActivateToggle
            active={showShipping}
            onToggle={setValue}
            ariaDescribedBy="#statistic-shipping-toggle-desc"
            loading={loading}
            activeIcon="eye"
            inactiveIcon="eye-off"
          />
        </div>
      )}
    </div>
  );
};

export default ToggleShowShipping;
