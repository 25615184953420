import classNames from 'classnames';
import Avatar from 'components/Avatar/Avatar';
import Checkbox from 'components/Checkbox/Checkbox';
import Chip, { Props as ChipProps } from 'components/Chip/Chip';
import EncodingBadge from 'components/EncodingBadge/EncodingBadge';
import Icon from 'components/Icon/Icon';
import IconButton, {
  Props as IconButtonProps,
} from 'components/IconButton/IconButton';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import LazyImage from 'components/LazyImage/LazyImage';
import Status from 'components/VideoStatus/VideoStatus';
import VideoCard from 'components/VideoCard/VideoCard';
import VisibilityToggle, {
  Props as visibilitySelectProps,
} from 'components/VisibilityToggle/VisibilityToggle';
import { getAvailabilityAsText, isAvailable } from 'features/availability';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import useFormatInTimeZone from 'hooks/useFormatInTimeZone';
import { useMediaQuery } from 'hooks/useMediaquery';
import placeholder from 'images/placeholder-video-mediathek-gradient.svg';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import { ColorUse } from 'types/Color';
import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import millisecondsToMinutesAndSeconds from 'utils/time';
import './video-ticket.scss';
import Tooltip from 'components/Tooltip/Tooltip';

export interface Props extends VideoResponseDTO {
  updatedBy?: string;
  deleteButtonProps?: Partial<IconButtonProps>;
  shareButtonProps?: Partial<IconButtonProps>;
  channelChipProps?: Partial<ChipProps>;
  productChipProps?: Partial<ChipProps>;
  visibilitySelectProps?: Omit<visibilitySelectProps, 'visibility'>;
  selectableProps?: {
    selected: boolean;
    onSelectChange: (isSelected: boolean) => void;
    disabled?: boolean;
  };
  onClick?: () => void;
}

const VideoTicket = ({
  deleteButtonProps,
  shareButtonProps,
  channelChipProps,
  productChipProps,
  visibilitySelectProps,
  selectableProps,
  onClick,
  ...props
}: Props) => {
  const {
    updatedAt,
    videoPreviews,
    language,
    name,
    type,
    product,
    channels,
    status,
    videoDuration,
    updatedBy,
    visibility,
    encodingStatus,
  } = props;
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation(['translation', 'video']);
  const date = useFormatInTimeZone({
    date: updatedAt,
    formatStr: 'Pp',
  });
  const timeZone = useReduxSelector(selectTimezone);

  const handleOpenSidebar = () => {};

  const handleIconButtonClick = (
    e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>,
    func?: (
      e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>,
    ) => void,
  ) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    func?.(e);
  };

  const handleListItemOnClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (
      (event.target as HTMLButtonElement).closest(
        '.icon-button, .chip, .activate-toggle, .button, .checkbox',
      )
    ) {
      return;
    }
    onClick?.();
  };

  if (!isDesktop) {
    return <VideoCard {...props} onClick={handleOpenSidebar} />;
  }

  return (
    <li
      className={classNames(
        'video-ticket__wrap',
        onClick && 'video-ticket__wrap--editable',
        selectableProps && 'video-ticket__wrap--selectable',
        selectableProps?.selected && 'video-ticket__wrap--selected',
      )}
      onClick={handleListItemOnClick}
      tabIndex={1}
    >
      {selectableProps && (
        <Checkbox
          wrapperClassName="video-ticket__selectable"
          checked={selectableProps.selected}
          id={`check--${props.id}`}
          name={`check--${props.id}`}
          onChange={(selected: boolean) =>
            selectableProps?.onSelectChange(selected)
          }
          label={props.name === '' ? t('no_title') : props.name}
          disabled={selectableProps.disabled}
          hideLabel
        />
      )}

      <div
        className={classNames(
          'video-ticket',
          props.status && `video-ticket--${props.status}`,
          props.type && `video-ticket--${props.type}`,
        )}
      >
        <div className="video-ticket__inner">
          <div className="video-ticket__image">
            {videoPreviews && videoPreviews.length > 0 && (
              <LazyImage
                src={
                  videoPreviews?.find((i) => i.size === 'small')?.uri ||
                  placeholder
                }
                lqip={placeholder}
                aspectRatio={100 / 150}
                alt={t('list:preview', { title: name })}
              />
            )}

            {language ? (
              <div className="video-ticket__language-button">
                <span className="video-ticket__language">{language}</span>
              </div>
            ) : (
              <div className="video-ticket__language-button">
                <div className="video-ticket__language">
                  <Icon icon="globe-blocked" />
                </div>
              </div>
            )}
          </div>

          <div className="video-ticket__content">
            {type && (
              <div className="caption--dark">
                {t(`video:type.${type}.label`)}
              </div>
            )}
            <div className="video-ticket__title">
              {name === '' ? t('no_title') : name}
            </div>
            {product && (
              <div className="video-ticket__product">
                <Chip
                  hint={product.vendorProductId}
                  status={
                    !product.isAvailable
                      ? VideoResponseDTO.status.IDLE
                      : VideoResponseDTO.status.LIVE
                  }
                  interactionTooltip={t('video:detachProduct')}
                  {...productChipProps}
                >
                  {(language
                    ? getProductTitleByLanguage(
                        product?.productLocalisations,
                        language,
                      )
                    : product?.productLocalisations[0]) || t('no_title')}
                </Chip>
              </div>
            )}

            <FlexBox
              justifyContent="space-between"
              className="video-ticket__channels"
              alignItems="flex-end"
            >
              {channels && channels.length > 0 && (
                <FlexItem flexGrow={1}>
                  <h2 className="caption video-ticket__caption">
                    {t('channelTitle')}
                  </h2>
                  <ul className="video-ticket__channel-list">
                    {channels.map(({ name, id, availability }) => (
                      <li key={id} className="video-ticket__channel">
                        <Chip
                          status={
                            isAvailable(availability)
                              ? VideoResponseDTO.status.LIVE
                              : VideoResponseDTO.status.IDLE
                          }
                          tooltip={getAvailabilityAsText(
                            availability,
                            t,
                            timeZone,
                          )}
                          interactionTooltip={t('video:channel.remove')}
                          {...channelChipProps}
                        >
                          {name}
                        </Chip>
                      </li>
                    ))}
                  </ul>
                </FlexItem>
              )}
              {encodingStatus &&
                encodingStatus !== VideoResponseDTO.encodingStatus.NONE &&
                encodingStatus !== VideoResponseDTO.encodingStatus.READY && (
                  <FlexItem pushRight>
                    <EncodingBadge encodingStatus={encodingStatus} />
                  </FlexItem>
                )}
            </FlexBox>
          </div>

          <div className="video-ticket__meta">
            <Status status={status} big />

            <div className="video-ticket__meta-inner">
              <div className="video-ticket__meta-content">
                <dl className="video-ticket__specs">
                  {videoDuration ? (
                    <div className="video-ticket__specs-item">
                      <dt>{t('video:meta.length')}</dt>
                      <dd>{millisecondsToMinutesAndSeconds(videoDuration)}</dd>
                    </div>
                  ) : null}
                </dl>

                {updatedAt && (
                  <Tooltip
                    content={
                      type === VideoResponseDTO.type.CHANNEL
                        ? t('video:meta.lastUpdated.desc.channel')
                        : t('video:meta.lastUpdated.desc.default')
                    }
                  >
                    <div className="video-ticket__updated-at">
                      <span className="video-ticket__updated-title">
                        {t('video:meta.lastUpdated.label')}
                      </span>
                      {updatedBy && <Avatar name={updatedBy} />}
                      <span className="video-ticket__updated-time">{date}</span>
                    </div>
                  </Tooltip>
                )}
              </div>

              <div className="video-ticket__meta-content video-ticket__controls">
                <div className="video-ticket__controls-item">
                  {deleteButtonProps && (
                    <IconButton
                      icon="delete"
                      tooltip={t('video:deleteVideo')}
                      appearance="ghost"
                      big
                      {...deleteButtonProps}
                      onClick={(e) =>
                        handleIconButtonClick(e, deleteButtonProps?.onClick)
                      }
                    />
                  )}
                  {shareButtonProps &&
                    status === VideoResponseDTO.status.LIVE && (
                      <IconButton
                        icon="share"
                        tooltip={
                          status === VideoResponseDTO.status.LIVE
                            ? t('video:share.title')
                            : t('video:share.disabled')
                        }
                        color={ColorUse['primary-50']}
                        disabled={status !== VideoResponseDTO.status.LIVE}
                        appearance="filled"
                        big
                        onClick={(e) =>
                          handleIconButtonClick(e, shareButtonProps?.onClick)
                        }
                        {...shareButtonProps}
                      />
                    )}
                </div>
                <div className="video-ticket__controls-item">
                  {visibility && visibilitySelectProps && (
                    <VisibilityToggle
                      visibility={visibility}
                      textPosition="top"
                      {...visibilitySelectProps}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {onClick && (
        <button
          className="video-ticket__button"
          type="button"
          onClick={(e) =>
            handleIconButtonClick(
              e as React.MouseEvent<
                HTMLButtonElement & HTMLAnchorElement,
                MouseEvent
              >,
              onClick,
            )
          }
        >
          <FlexBox
            flexDirection="column"
            justifyContent="flex-end"
            className="video-ticket__button-inner"
          >
            <span className="video-ticket__button-text">{t('edit')}</span>
          </FlexBox>
        </button>
      )}
    </li>
  );
};

export default VideoTicket;
