/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AcceptedTermsResponseDTO } from './AcceptedTermsResponseDTO';
import type { LocaleDTO } from './LocaleDTO';
import type { PreferredLanguageDTO } from './PreferredLanguageDTO';
import type { VendorResponseDTO } from './VendorResponseDTO';

export type VendorAgentResponseWithVendorDTO = {
    id: number;
    email: string;
    name: string;
    apiKeySecret?: string;
    role: VendorAgentResponseWithVendorDTO.role;
    defaultLocale: string;
    defaultTimezone: string;
    supportedLanguages: Array<LocaleDTO>;
    vendor: VendorResponseDTO;
    currentVendor: VendorResponseDTO;
    termsInfo?: AcceptedTermsResponseDTO;
    preferredLanguages: Array<PreferredLanguageDTO>;
};

export namespace VendorAgentResponseWithVendorDTO {

    export enum role {
        SALES_AGENT = 'sales_agent',
        SALES_ADMIN = 'sales_admin',
        REPORTER = 'reporter',
        ADMIN = 'admin',
        SUPER_ADMIN = 'super_admin',
    }


}

