import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout';
import { TotalPricingDto, VendorOrderDto } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from 'components/Collapse/Collapse';
import Pricing from './Pricing';

const Payment = ({
  paymentMethod,
  pricing,
  productId,
}: {
  paymentMethod?: VendorOrderDto.paymentMethod | null;
  pricing?: TotalPricingDto | null;
  // This is only a temporary solution and only works for one single orderItem
  productId?: number;
}) => {
  const { t } = useTranslation(['translation', 'orders']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [collapsed, setCollapsed] = useState(!isDesktop);

  useEffect(() => {
    setCollapsed(!isDesktop);
  }, [isDesktop]);

  return (
    <>
      {pricing && !isDesktop && (
        <Spacer marginBottom={collapsed ? 6 : 10}>
          <Pricing pricing={pricing} productId={productId || 0} />
        </Spacer>
      )}
      {paymentMethod && !collapsed && (
        <LabelText label={t('orders:detail.payment.type.label')}>
          {t(`orders:detail.payment.type.method.${paymentMethod}`)}
        </LabelText>
      )}

      {paymentMethod && !isDesktop && (
        <Collapse
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}
    </>
  );
};

export default Payment;

// t('orders:detail.payment.type.method.cashOnDelivery'),
// t('orders:detail.payment.type.method.acss_debit'),
// t('orders:detail.payment.type.method.affirm'),
// t('orders:detail.payment.type.method.afterpay_clearpay'),
// t('orders:detail.payment.type.method.alipay'),
// t('orders:detail.payment.type.method.au_becs_debit'),
// t('orders:detail.payment.type.method.bacs_debit'),
// t('orders:detail.payment.type.method.bancontact'),
// t('orders:detail.payment.type.method.blik'),
// t('orders:detail.payment.type.method.boleto'),
// t('orders:detail.payment.type.method.card'),
// t('orders:detail.payment.type.method.cashapp'),
// t('orders:detail.payment.type.method.customer_balance'),
// t('orders:detail.payment.type.method.eps'),
// t('orders:detail.payment.type.method.fpx'),
// t('orders:detail.payment.type.method.giropay'),
// t('orders:detail.payment.type.method.grabpay'),
// t('orders:detail.payment.type.method.ideal'),
// t('orders:detail.payment.type.method.klarna'),
// t('orders:detail.payment.type.method.konbini'),
// t('orders:detail.payment.type.method.link'),
// t('orders:detail.payment.type.method.oxxo'),
// t('orders:detail.payment.type.method.p24'),
// t('orders:detail.payment.type.method.paynow'),
// t('orders:detail.payment.type.method.paypal'),
// t('orders:detail.payment.type.method.pix'),
// t('orders:detail.payment.type.method.promptpay'),
// t('orders:detail.payment.type.method.sepa_debit'),
// t('orders:detail.payment.type.method.sofort'),
// t('orders:detail.payment.type.method.us_bank_account'),
// t('orders:detail.payment.type.method.wechat_pay'),
// t('orders:detail.payment.type.method.zip'),
