import Button from 'components/Button/Button';
import Headline from 'components/Headline/Headline';
import InputField from 'components/Input/InputField';
import { FlexItem, Spacer } from 'components/Layout';
import { useUpdateProductTaxes } from 'features/product-taxes';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ProductTaxDto } from 'generated';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import {
  selectCountryCode,
  selectWorldzone,
} from 'redux/slices/i18n/i18nSlice';
import * as Yup from 'yup';
import { ShipmentComponentProps } from './CreateShipment';
import TaxesSelect, { TaxesSelectField } from './TaxesSelect';

type Values = Omit<ProductTaxDto, 'country' | 'rate'> & { rate?: string };

const CreateShipmentTaxes = ({
  taxesData,
  productId,
  setStep,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const taxesMutation = useUpdateProductTaxes();
  const countryCode = useReduxSelector(selectCountryCode);
  const worldzone = useReduxSelector(selectWorldzone);
  const [initialValues, setInitialValues] = useState<Values>({
    rate: taxesData?.rate.toString() || (worldzone === 'in' ? '18' : '19'),
    hsn: taxesData?.hsn || '',
  });

  useEffect(() => {
    setInitialValues((pre) => {
      return Object.assign(pre, {
        rate: taxesData?.rate.toString() || (worldzone === 'in' ? '18' : '19'),
        hsn: taxesData?.hsn || '',
      });
    });
  }, [taxesData]);

  const validateHsn = (value?: string) => /^[0-9]{4,8}$/.test(value || '');

  const nameSchema = Yup.object().shape({
    rate: Yup.number().required(t('translation:form.required')),
    hsn: Yup.string()
      .required(t('translation:form.required'))
      .test(
        'Valid HSN',
        t('orders:detail.shipping.createShipment.taxes.hsnInvalid'),
        validateHsn,
      ),
  });

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    taxesMutation.mutate(
      {
        productId,
        requestBody: {
          ...values,
          rate: parseFloat(values.rate || '0'),
          country: countryCode,
        },
      },
      {
        onSuccess: () => {
          formikHelpers.resetForm({ values });
          setInitialValues(values);
          setStep({ key: 'start', data: null });
        },
      },
    );
  };

  return (
    <>
      <Headline
        headingLevel="h2"
        size={2}
        className="order-detail__box-headline"
      >
        {t('orders:detail.shipping.createShipment.taxes.title')}
      </Headline>
      <Spacer marginTop={5} tag="p" className="small c-dark-50">
        {t('orders:detail.shipping.createShipment.taxes.description')}
      </Spacer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form noValidate>
            <Spacer marginTop={5}>
              <Spacer marginBottom={4}>
                <Field
                  component={TaxesSelectField}
                  name="rate"
                  required
                  label={`${t('product:taxes.rate')}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="hsn"
                  component={InputField}
                  required
                  label={`${t('product:taxes.hsn')}*`}
                />
              </Spacer>
            </Spacer>
            <FlexItem display="flex" pushBottom justifyContent="space-between">
              <Button
                appearance="ghost"
                text={t('orders:detail.shipping.createShipment.address.abort')}
                onClick={() => setStep({ key: 'start', data: null })}
              />
              <Button
                text={t('orders:detail.shipping.createShipment.address.save')}
                type="submit"
              />
            </FlexItem>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateShipmentTaxes;
