/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VideoPreviewDTO = {
    size: VideoPreviewDTO.size;
    uri: string;
};

export namespace VideoPreviewDTO {

    export enum size {
        SMALL = 'small',
        MEDIUM = 'medium',
        LARGE = 'large',
    }


}

