import { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import Icon from '../../Icon/Icon';
import { DefaultOption } from './Option';

export const DropDownIndicator = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  props: DropdownIndicatorProps<OptionType, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <div className="select-interactive__dropdown-indicator">
      <Icon icon={props.selectProps.indicatorIcon || 'caret-down'} />
    </div>
  </components.DropdownIndicator>
);

export default DropDownIndicator;
