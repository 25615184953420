import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import { KeyValueDTO, KeyValueStoreService } from 'generated';
import { startAppListening } from 'redux/middlewares/listenerMiddleware';
import { init, logIn } from '../auth/authSlice';

// TODO This is not used anywhere, but should be used for all super admin toggles and the pendants asking the key value in the UI

const initialState: { [key in KeyValueDTO.key]?: string | undefined } = {
  ...Object.fromEntries(
    Object.keys(KeyValueDTO.key).map((key) => [key, undefined]),
  ),
  STATISTICS_SHOW_CLICKS: 'false',
  CREATOR_SHOW_VOUCHER: 'false',
  CREATOR_SHOW_ORDERS: 'false',
  ENABLE_DISCOUNT_PRICES: 'false',
};

export const getKeyValueStoreItem = createAsyncThunk(
  'kvstore/getItem',
  async ({ key }: { key: KeyValueDTO.key }) => {
    try {
      const response = await KeyValueStoreService.getKeyValueStoreItem(key);

      return response;
    } catch (error) {
      console.log(error);
    }
  },
);
export const setKeyValueStoreItem = createAsyncThunk(
  'kvstore/setItem',
  async (req: { key: KeyValueDTO.key; value?: string }) => {
    try {
      const response = await KeyValueStoreService.setKeyValueStoreItem(req);

      return response;
    } catch (error) {
      console.log(error);
    }
  },
);

export const initialKeyValueStore = () => {
  return async (dispatch: any) => {
    Object.keys(KeyValueDTO.key).forEach((key) => {
      dispatch(getKeyValueStoreItem({ key: key as KeyValueDTO.key }));
    });
  };
};

export const kvstoreSlice = createSlice({
  name: 'kvstore',
  initialState,
  reducers: {
    setKeyValueStoreItem: (
      state,
      action: PayloadAction<{ key: KeyValueDTO.key; value?: string }>,
    ) => {
      const { value, key } = action.payload;
      state[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKeyValueStoreItem.fulfilled, (state, action) => {
      const { value, key } = action.payload || {};
      if (key) {
        state[key] = value;
      }
    });
    builder.addCase(setKeyValueStoreItem.fulfilled, (state, action) => {
      const { value, key } = action.payload || {};
      if (key) {
        state[key] = value;
      }
    });
  },
});

// TODO
// startAppListening({
//   matcher: isAnyOf(logIn.fulfilled, init.fulfilled),
//   effect: async (_, { dispatch, getState }) => {
//     if (getState().utm.status === 'not-fetched') {
//       dispatch(initialKeyValueStore());
//     }
//   },
// });

export const {} = kvstoreSlice.actions;
export default kvstoreSlice.reducer;
