import React from 'react';
import Headline from '../Headline/Headline';

export interface Props {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
}

const MediathekHeader = ({ children, title, subtitle }: Props) => {
  return (
    <div className="mediathek__header">
      {subtitle && <div className="mediathek__subtitle">{subtitle}</div>}
      {title && (
        <Headline headingLevel="h1" size={2}>
          {title}
        </Headline>
      )}
      <div className="mediathek__filter">{children}</div>
    </div>
  );
};

export default MediathekHeader;
