import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  VendorAddressDto,
  VendorAddressRequestDto,
  VendorAddressService,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export const vendorAddressKeys = {
  getAddresses: (vendorAgentId?: number) => [vendorAgentId],
};

export const useGetVendorAddresses = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useQuery({
    queryKey: vendorAddressKeys.getAddresses(vendorAgent?.currentVendor.id),
    queryFn: () => VendorAddressService.getAddresses(),
  });
};

export const useCreateVendorAddress = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<VendorAddressDto, ApiError, VendorAddressRequestDto>({
    mutationFn: (data) => VendorAddressService.createAddress(data),
    onSuccess: (data) => {
      queryClient.setQueryData(
        vendorAddressKeys.getAddresses(vendorAgent?.currentVendor.id),
        () => data,
      );
    },
  });
};
