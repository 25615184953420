import classNames from 'classnames';
import NativeSelect from 'components/Select/Select';
import { useEffect, useState } from 'react';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Props as ReactSelectProps,
} from 'react-select';
import { DefaultOption } from './Option';
import { OptionWithIconProps } from './OptionWithIcon';

const NativeMobileSelect = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: ReactSelectProps<OptionType, IsMulti, Group>,
) => {
  const {
    value,
    options,
    onChange,
    placeholder,
    className,
    label,
    error,
    isMulti,
  } = props;

  const [withIcon, setWithIcon] = useState(
    (value as OptionWithIconProps)?.icon,
  );

  useEffect(() => {
    setWithIcon((value as OptionWithIconProps)?.icon);
  }, [value]);

  const handleNativeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const findOption = options?.find(
      (i) => (i as OptionType).value === event.target.value,
    );
    const icon = (findOption as OptionWithIconProps)?.icon;
    const label = (findOption as OptionWithIconProps)?.label;

    onChange?.(
      props.isMulti
        ? ([{ value, label, icon }] as unknown as OnChangeValue<
            OptionType,
            IsMulti
          >)
        : ({
            value: event.target.value,
            label,
            icon,
          } as unknown as OnChangeValue<OptionType, IsMulti>),
      {} as ActionMeta<OptionType>,
    );
  };

  return (
    <NativeSelect
      wrapperClassName={classNames('select-interactive', className)}
      placeholder={placeholder?.toString()}
      value={value ? (value as OptionType).value : isMulti ? [''] : ''}
      onChange={handleNativeChange}
      options={
        options?.map((o) => {
          return 'value' in o
            ? { value: o.value, text: o.label || '' }
            : { value: '', text: o.label || '' };
        }) || []
      }
      label={label}
      multiple={props.isMulti}
      appearance={props.appearance}
      icon={withIcon}
      error={error}
    />
  );
};

export default NativeMobileSelect;
