import { VideoResponseDTO } from 'generated';
import { FilterItem, VideoFilterQueryType, VideoFilterType } from './types';

export const getFilterQueryFromFilter = (
  filter?: VideoFilterType,
): VideoFilterQueryType => {
  if (filter) {
    return {
      channelIds: filter.channel?.map((item) => parseInt(item.id, 10)),
      productIds: filter.product?.map((item) => parseInt(item.id, 10)),
      categoryIds: filter.category?.map((item) => parseInt(item.id, 10)),
      hashtagIds: filter.hashtag?.map((item) => parseInt(item.id, 10)),
      type: filter.type?.map((item) => item.key) as VideoResponseDTO.type[],
      status: filter.status?.map(
        (item) => item.key,
      ) as VideoResponseDTO.status[],
      visibility: filter.visibility?.map(
        (item) => item.key,
      ) as VideoResponseDTO.visibility[],
    };
  }
  return {};
};

export const getFilterItemsFromFilter = (
  filter?: VideoFilterType,
): FilterItem[] => {
  if (filter) {
    return Object.values(filter)
      .filter((i) => i !== undefined)
      .flat();
  }
  return [];
};
