import { formatInTimeZone } from 'date-fns-tz';
import { useReduxSelector } from '../redux/hooks';
import { selectLocale, selectTimezone } from '../redux/slices/i18n/i18nSlice';

const useFormatInTimeZone = ({
  date,
  formatStr,
}: {
  date?: string | number | Date;
  formatStr: string;
}) => {
  const timeZone = useReduxSelector(selectTimezone);
  const locale = useReduxSelector(selectLocale);

  if (!date) {
    return undefined;
  }

  return formatInTimeZone(date, timeZone, formatStr, { locale });
};

export default useFormatInTimeZone;
