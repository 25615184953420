import React, { FC } from 'react';
import { FieldProps } from 'formik';
import './video-upload-field.scss';
import UploadField from '../UploadField/UploadField';

export interface Props {
  onChange?: (file: File) => void;
  disabled?: boolean;
}

export enum AllowedTypes {
  mp4 = 'mp4',
  mov = 'mov',
  mkv = 'mkv',
}

const VideoUploadField: FC<FieldProps & Props> = (props) => {
  return (
    <UploadField
      allowedTypes={Object.keys(AllowedTypes)}
      className="video-upload-field"
      {...props}
    />
  );
};

export default VideoUploadField;
