import { useEffect, useState } from 'react';
import { VendorAgentsService } from '../generated';

const useVerifyToken = (token?: string): { tokenError: boolean } => {
  const [tokenError, setTokenError] = useState(false);

  useEffect(() => {
    const verifyToken = async (token: string) => {
      try {
        await VendorAgentsService.verifyToken({ token });
      } catch (error) {
        setTokenError(true);
      }
    };

    if (!token) {
      setTokenError(true);
    } else {
      verifyToken(token);
    }
  }, [token]);

  return { tokenError };
};

export default useVerifyToken;
