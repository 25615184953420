/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelShipmentParametersDto } from '../models/CancelShipmentParametersDto';
import type { CreateShipmentParametersDto } from '../models/CreateShipmentParametersDto';
import type { OrderNumbersDto } from '../models/OrderNumbersDto';
import type { PaginatedVendorOrderDto } from '../models/PaginatedVendorOrderDto';
import type { VendorOrderDto } from '../models/VendorOrderDto';
import type { VendorOrderShipmentDto } from '../models/VendorOrderShipmentDto';
import type { VendorOrderShipmentInvoiceDto } from '../models/VendorOrderShipmentInvoiceDto';
import type { VendorOrderShipmentManifestDto } from '../models/VendorOrderShipmentManifestDto';
import type { VendorOrderShipmentTrackingLabelDto } from '../models/VendorOrderShipmentTrackingLabelDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorOrderService {

    /**
     * Gets all orders paginated
     * @param offset
     * @param limit
     * @param orderNo Please use property `orderNumbers` instead.
     * @param productName A minimum of 3 chars is required otherwise the filter will be ignored.
     * @param customerName A minimum of 3 chars is required otherwise the filter will be ignored.
     * @param orderNumbers
     * @param productIds
     * @param createdAtFrom
     * @param createdAtTo
     * @param orderStatusAction
     * @param sortBy
     * @param sortDirection
     * @returns PaginatedVendorOrderDto
     * @throws ApiError
     */
    public static getAll(
        offset?: number,
        limit?: number,
        orderNo?: string,
        productName?: string,
        customerName?: string,
        orderNumbers?: Array<string>,
        productIds?: Array<number>,
        createdAtFrom?: string,
        createdAtTo?: string,
        orderStatusAction?: 'purchaseBasketItems' | 'paidBasketItems' | 'createShipment' | 'cancelShipment' | 'cancelOrderBeforeShipping' | 'pickupOrderFromWarehouse' | 'deliverOrderToCustomer' | 'notDeliveredOrder' | 'doPaymentCOD' | 'closeOrder' | 'lostOrder' | 'requestReturnByCustomer' | 'createReturnShipment' | 'pickupOrderFromCustomer' | 'rejectReturnRequest' | 'deliverOrderToWarehouse' | 'refundPayment',
        sortBy: 'createdAt' = 'createdAt',
        sortDirection: 'ASC' | 'DESC' = 'DESC',
    ): CancelablePromise<PaginatedVendorOrderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/order/all',
            query: {
                'offset': offset,
                'limit': limit,
                'orderNo': orderNo,
                'productName': productName,
                'customerName': customerName,
                'orderNumbers': orderNumbers,
                'productIds': productIds,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'orderStatusAction': orderStatusAction,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
            },
        });
    }

    /**
     * Get order by orderNo
     * @param orderNo
     * @returns VendorOrderDto
     * @throws ApiError
     */
    public static getByOrderNo(
        orderNo: string,
    ): CancelablePromise<VendorOrderDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/order/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
        });
    }

    /**
     * Create shipment by orderNo
     * @param orderNo
     * @param requestBody
     * @returns VendorOrderShipmentDto
     * @throws ApiError
     */
    public static createShipment(
        orderNo: string,
        requestBody: CreateShipmentParametersDto,
    ): CancelablePromise<VendorOrderShipmentDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendor/order/shipment/create/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Cancel shipment by orderNo
     * @param orderNo
     * @param requestBody
     * @returns VendorOrderShipmentDto
     * @throws ApiError
     */
    public static cancelShipment(
        orderNo: string,
        requestBody: CancelShipmentParametersDto,
    ): CancelablePromise<VendorOrderShipmentDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/vendor/order/shipment/cancel/{orderNo}',
            path: {
                'orderNo': orderNo,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get shipment tracking label by orderNumbers
     * @param requestBody
     * @returns VendorOrderShipmentTrackingLabelDto
     * @throws ApiError
     */
    public static getShipmentTrackingLabel(
        requestBody: OrderNumbersDto,
    ): CancelablePromise<VendorOrderShipmentTrackingLabelDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendor/order/shipment/tracking-label',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get shipment manifest by orderNumbers
     * @param requestBody
     * @returns VendorOrderShipmentManifestDto
     * @throws ApiError
     */
    public static getShipmentManifests(
        requestBody: OrderNumbersDto,
    ): CancelablePromise<VendorOrderShipmentManifestDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendor/order/shipment/manifests',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get shipment invoice by orderNumbers
     * @param requestBody
     * @returns VendorOrderShipmentInvoiceDto
     * @throws ApiError
     */
    public static getShipmentInvoice(
        requestBody: OrderNumbersDto,
    ): CancelablePromise<VendorOrderShipmentInvoiceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendor/order/shipment/invoice',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
