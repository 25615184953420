import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface SideBarContextType {
  sidebarIsOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

const SidebarContext = createContext<SideBarContextType>({
  sidebarIsOpen: false,
} as SideBarContextType);

export const SidebarProvider = ({
  children,
  initial = false,
}: {
  children: ReactNode;
  initial?: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(initial);

  const openSidebar = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  const memoedValue = useMemo(
    () => ({
      sidebarIsOpen: open,
      openSidebar,
      closeSidebar,
    }),
    [open],
  );

  return (
    <SidebarContext.Provider value={memoedValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export default function useSidebar() {
  return useContext(SidebarContext);
}
