import Icon from 'components/Icon/Icon';
import Input from 'components/Input/Input';
import { Spacer } from 'components/Layout';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';

export type Props = {
  searchableProps: {
    onInputChange: (inputText: string, event: any) => void;
    inputValue: string;
    onClearInputValue: () => void;
  };
  debouncedSearchTerm: string | void;
  onClose: () => void;
  loading?: boolean;
};

const CategegoriesHeader = ({ searchableProps, onClose, loading }: Props) => {
  const { t } = useTranslation(['translation', 'product']);
  const isDesktop = useMediaQuery(`(min-width:${BREAKPOINTS.m} )`);

  return (
    <header className="categories__modal-header">
      <div className="modal__header">
        <h1 className="modal__caption">{t('product:categories.modalTitle')}</h1>
        <button className="modal__close" type="button" onClick={onClose}>
          <Icon icon="close" />
        </button>
      </div>
      <Spacer className="categories__search-wrap">
        <Input
          name="categories-search"
          value={searchableProps.inputValue}
          onChange={(e) => searchableProps.onInputChange(e.target.value, e)}
          onClear={searchableProps.onClearInputValue}
          className="categories__search"
          placeholder={t('product:categories.search')}
          label={t('product:categories.all')}
          hiddenLabel={!isDesktop}
          loading={loading}
        />
      </Spacer>
    </header>
  );
};

export default CategegoriesHeader;
