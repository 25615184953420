import { useVideoOutletContext } from 'features/video-detail';
import { Navigate, useParams } from 'react-router-dom';
import VideoType from '../VideoType/VideoType';

/** Route /videos/:id/type */
const VideoTypeOutlet = () => {
  const { id } = useParams();
  const { visited } = useVideoOutletContext();

  const videoId = id ? parseInt(id) : 0;
  return visited ? (
    <VideoType videoId={videoId} />
  ) : (
    <Navigate to={`/videos/${videoId}`} />
  );
};

export default VideoTypeOutlet;
