import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Grid from '../components/Grid/Grid';
import GridItem from '../components/Grid/GridItem';
import Headline from '../components/Headline/Headline';
import ProductContainer from '../container/ProductContainer/ProductContainer';
import useAppStatus from '../hooks/useAppStatus';
import '../styles/layout/_page.scss';

const ProductsPage = () => {
  const { t } = useTranslation(['translation', 'product']);
  const { appStatus } = useAppStatus();

  return (
    <div
      className={classNames(
        'page',
        'page--product',
        appStatus && 'page--status',
      )}
    >
      <Grid>
        <GridItem className="page__header" templateArea="header">
          <Breadcrumbs />

          <Headline headingLevel="h1" size={2} className="page__title">
            {t('product:title')}
          </Headline>
        </GridItem>

        <ProductContainer />
      </Grid>
    </div>
  );
};
export default ProductsPage;
