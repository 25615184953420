import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Terms from '../components/Terms/Terms';
import { useReduxDispatch, useReduxSelector } from '../redux/hooks';
import {
  acceptOrDeclineTerms,
  selectTermsInfo,
} from '../redux/slices/terms/termsSlice';
import LandingPage from './LandingPage';

const TermsPage = () => {
  const { t } = useTranslation();

  const [error, setError] = useState<string>();
  const termsInfo = useReduxSelector(selectTermsInfo);
  const dispatch = useReduxDispatch();

  useLayoutEffect(() => {
    document.querySelector('body')?.classList.add('body--login');
    return () => {
      document.querySelector('body')?.classList.remove('body--login');
    };
  });

  const handleAcceptDecline = async (accepted: boolean) => {
    if (termsInfo) {
      await dispatch(acceptOrDeclineTerms({ id: termsInfo.id, accepted }));
    }
  };

  const handleError = () => {
    setError(t('settings:terms.acceptError'));
  };

  return (
    <LandingPage width={550}>
      <Terms
        terms={termsInfo}
        onAcceptDecline={handleAcceptDecline}
        error={error}
        onLoadError={handleError}
      />
    </LandingPage>
  );
};

export default TermsPage;
