import { useMutation } from '@tanstack/react-query';
import { videoDetailKeys } from 'features/video-detail/queries';
import { videoListKeys } from 'features/video-list';
import {
  ApiError,
  VideoIdsDTO,
  VideoResponseDTO,
  VideosService,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export const useUpdateVisibility = () => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'video']);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<
    VideoIdsDTO,
    ApiError,
    { videoId: number; visibility: VideoResponseDTO.visibility }
  >({
    mutationFn: ({ videoId, visibility }) =>
      VideosService.updateVideosVisibility({ videoIds: [videoId], visibility }),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<VideoResponseDTO>(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor.id,
        ),
        (old?: VideoResponseDTO) => {
          if (old) return { ...old, visibility: variables.visibility };
        },
      );
      queryClient.invalidateQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      );
    },
    onError: (error) => {
      setAppStatus(t('video:visibility.error'));
    },
  });
};
