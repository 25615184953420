import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { videoListKeys } from 'features/video-list';
import {
  ApiError,
  VideoDTO,
  VideoRequestUploadUrlDTO,
  VideoResponseDTO,
  VideoUploadDTO,
  VideosService,
} from 'generated';
import { queryClient } from 'index';
import _ from 'lodash';
import { startAppListening } from 'redux/middlewares/listenerMiddleware';
import { logOut } from 'redux/slices/auth/authSlice';
import notAuthenticated from 'utils/not-authenticated';
import { VideoUploadFeature } from './types';

const initialState: VideoUploadFeature.VideoUploadState = {
  files: {},
  queue: [],
  totalProgress: 0,
  maxConcurrentUploads: 3,
  uploadCount: 0,
};
/**
 * Statuses that indicate that the upload is finished
 */
export const finishedUploadStatus = [
  VideoUploadFeature.Status.CREATE_SUCCESS,
  VideoUploadFeature.Status.CREATE_ERROR,
  VideoUploadFeature.Status.CREATE,
  VideoUploadFeature.Status.CREATE_NAME_ERROR,
  VideoUploadFeature.Status.UPLOAD_ERROR,
  VideoUploadFeature.Status.UPLOAD_CANCELED,
  VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR,
];

/**
 * Statuses that indicate that the file has not started the uploading process yet.
 */
export const waitingUploadStatus = [
  VideoUploadFeature.Status.UPLOAD_GRANTED,
  VideoUploadFeature.Status.IDLE,
  VideoUploadFeature.Status.GET_UPLOAD_URL,
  VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS,
];

/**
 * Get the upload url for a video from 3Q to use in the upload process. To start the upload use the uploadVideo action.
 */
export const getUploadUrl = createAsyncThunk<
  VideoUploadDTO & { fileKey: string },
  VideoRequestUploadUrlDTO & { fileKey: string },
  { rejectValue: VideoUploadFeature.Status }
>(
  'videoUpload/getUploadUrl',
  async ({ fileKey, ...variables }, { rejectWithValue, dispatch }) => {
    try {
      const res = await VideosService.createVideoUploadUrl(variables);
      return { ...res, fileKey };
    } catch (error) {
      if (notAuthenticated(error as ApiError)) {
        dispatch(logOut());
      }
      return rejectWithValue(VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR);
    }
  },
);

/**
 * Upload a video to 3Q. To get the upload url use the getUploadUrl action.
 * This action is blocked if the file does not have the status UPLOAD_GRANTED.
 * To get that status use the requestUpload action.
 */
export const uploadVideo = createAsyncThunk<
  VideoUploadFeature.UploadVideoToThreeQResponse,
  VideoUploadFeature.UseUploadVideoVariables,
  { rejectValue: VideoUploadFeature.Status }
>(
  'videoUpload/uploadVideo',
  async (
    { file, progressCallBack, ...variables },
    { rejectWithValue, dispatch, signal },
  ) => {
    // Make canceled thunk cancel axios request
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    // Set progress to 0 on start
    uploadSlice.actions.updateProgress({
      fileKey: variables.fileKey,
      progress: 0,
    });
    try {
      // upload file to 3Q
      const { data } = await axios.put<
        File,
        AxiosResponse<VideoUploadFeature.ThreeQUploadResponse>
      >(variables.uri, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          dispatch(
            uploadSlice.actions.updateProgress({
              fileKey: variables.fileKey,
              progress: Math.round(
                (progressEvent.loaded * 100) / (progressEvent?.total || 1),
              ),
            }),
          );
          progressCallBack?.(progressEvent);
        },
        cancelToken: source.token,
      });
      return {
        ...variables,
        threeQFileId: data.FileId,
        uploadUrl: variables.uri,
        signal,
      };
    } catch (error) {
      if (axios.isCancel(error) || signal.aborted) {
        console.log('Upload canceled', variables.fileName);
        return rejectWithValue(VideoUploadFeature.Status.UPLOAD_CANCELED);
      } else {
        return rejectWithValue(VideoUploadFeature.Status.UPLOAD_ERROR);
      }
    }
  },
  {
    condition: ({ status }) => {
      if (status !== VideoUploadFeature.Status.UPLOAD_GRANTED) {
        return false;
      }
    },
  },
);

/**
 * Create a video in the database. The video needs to be uploaded first by using the uploadVideo action.
 */
export const createVideo = createAsyncThunk<
  VideoResponseDTO & { fileKey: string },
  VideoDTO & { fileKey: string },
  {
    rejectValue: {
      status: VideoUploadFeature.Status;
      requestPayload: VideoDTO & { fileKey: string };
    };
  }
>(
  'videUpload/createVideo',
  async ({ fileKey, ...variables }, { rejectWithValue, dispatch }) => {
    try {
      const res = await VideosService.addVideo(variables);
      return { ...res, fileKey };
    } catch (error) {
      if (notAuthenticated(error as ApiError)) {
        dispatch(logOut());
      }
      return rejectWithValue({
        status:
          (error as ApiError).body?.error === 'DuplicatedEntryError'
            ? VideoUploadFeature.Status.CREATE_NAME_ERROR
            : VideoUploadFeature.Status.CREATE_ERROR,
        requestPayload: { fileKey, ...variables },
      });
    }
  },
);

export const uploadSlice = createSlice({
  name: 'videoUpload',
  initialState,
  extraReducers: (builder) => {
    // getUploadUrl
    builder.addCase(getUploadUrl.pending, ({ files }, action) => {
      if (files[action.meta.arg.fileKey]) {
        files[action.meta.arg.fileKey].status =
          VideoUploadFeature.Status.GET_UPLOAD_URL;
      }
    });
    builder.addCase(getUploadUrl.fulfilled, ({ files }, action) => {
      if (files[action.meta.arg.fileKey]) {
        files[action.payload.fileKey].status =
          VideoUploadFeature.Status.GET_UPLOAD_URL_SUCCESS;
        files[action.payload.fileKey].uploadUrl = action.payload.url;
      }
    });
    builder.addCase(getUploadUrl.rejected, ({ files }, action) => {
      if (files[action.meta.arg.fileKey]) {
        files[action.meta.arg.fileKey].status =
          action.payload || VideoUploadFeature.Status.GET_UPLOAD_URL_ERROR;
      }
    });

    // uploadVideo
    builder.addCase(uploadVideo.pending, (state, action) => {
      if (state.files[action.meta.arg.fileKey]) {
        state.files[action.meta.arg.fileKey].status =
          VideoUploadFeature.Status.UPLOADING;
      }
      state.uploadCount++;
    });
    builder.addCase(uploadVideo.fulfilled, (state, action) => {
      if (state.files[action.payload.fileKey]) {
        state.files[action.payload.fileKey].status =
          VideoUploadFeature.Status.UPLOAD_SUCCESS;
        state.files[action.payload.fileKey].threeQFileId =
          action.payload.threeQFileId;
      }
      state.uploadCount--;
    });
    builder.addCase(uploadVideo.rejected, (state, action) => {
      if (state.files[action.meta.arg.fileKey]) {
        const status =
          action.error.name === 'AbortError'
            ? VideoUploadFeature.Status.UPLOAD_CANCELED
            : VideoUploadFeature.Status.UPLOAD_ERROR;
        state.files[action.meta.arg.fileKey].status = status;
      }
      state.uploadCount--;
    });

    // createVideo
    builder.addCase(createVideo.pending, ({ files }, action) => {
      if (files[action.meta.arg.fileKey]) {
        files[action.meta.arg.fileKey].status =
          VideoUploadFeature.Status.CREATE;
      }
    });
    builder.addCase(createVideo.fulfilled, ({ files }, action) => {
      if (files[action.payload.fileKey]) {
        files[action.payload.fileKey].status =
          VideoUploadFeature.Status.CREATE_SUCCESS;
      }
    });
    builder.addCase(createVideo.rejected, ({ files }, action) => {
      if (files[action.meta.arg.fileKey]) {
        files[action.meta.arg.fileKey].status =
          action.payload?.status || VideoUploadFeature.Status.CREATE_ERROR;
      }
    });
  },
  reducers: {
    /**
     * Pushes new files to a wainting queue to be uploaded. A listener will handle the process from there.
     */
    requestUpload: (
      state,
      action: PayloadAction<{
        files: VideoUploadFeature.FileDataCollection[];
        progressCallBack?: (progressEvent: ProgressEvent<EventTarget>) => void;
      }>,
    ) => {
      action.payload.files.forEach((file) => {
        state.files[file.fileKey] = file;
        state.queue.push(file.fileKey);
      });
    },
    /**
     * Removes the first file from the queue and gives it a status of UPLOAD_GRANTED.
     * You can listen to this status to start the upload process in your component, e.g. VideoUploadManager.
     */
    grantUploadForNextInLine: (state) => {
      const nextInLine = state.queue.shift();
      if (nextInLine) {
        state.files[nextInLine].status =
          VideoUploadFeature.Status.UPLOAD_GRANTED;
      }
    },
    updateProgress: (
      { files },
      {
        payload: { fileKey, progress },
      }: PayloadAction<{ fileKey: string; progress: number }>,
    ) => {
      if (files[fileKey]) {
        files[fileKey].progress = progress;
      }
    },
    removeAllFinishedUploads: (state) => {
      state.files = _.omitBy(state.files, (file) =>
        finishedUploadStatus.includes(state.files[file.fileKey].status),
      );
    },
    clearFiles: (state) => {
      state.files = {};
    },
    removeFile: (
      { files },
      { payload: { fileKey } }: PayloadAction<{ fileKey: string }>,
    ) => {
      if (files[fileKey]) {
        delete files[fileKey];
      }
    },
    /**
     * This will change the status of a file to UPLOAD_CANCELED. Use this, if you want to cancel the upload BEFORE it has started.
     * If the upload has already started, use the abort function from the uploadVideo action!
     */
    stopWaitingForUpload: (
      { files },
      { payload: { fileKey } }: PayloadAction<{ fileKey: string }>,
    ) => {
      if (files[fileKey]) {
        files[fileKey].status = VideoUploadFeature.Status.UPLOAD_CANCELED;
      }
    },
  },
});

/**
 * When uploads are finished or newly requested, grant upload for next ones in line
 */
startAppListening({
  matcher: isAnyOf(
    uploadVideo.fulfilled,
    uploadVideo.rejected,
    uploadSlice.actions.requestUpload,
  ),
  effect: async (action, listenerApi) => {
    const freeSlots =
      listenerApi.getState().videoUpload.maxConcurrentUploads -
      listenerApi.getState().videoUpload.uploadCount;
    [
      ...Array(
        Math.min(freeSlots, listenerApi.getState().videoUpload.queue.length),
      ),
    ].forEach(() => {
      listenerApi.dispatch(uploadSlice.actions.grantUploadForNextInLine());
    });
  },
});

/**
 * Remove all finished uploads if new uploads are requested to keep the list clean.
 */
startAppListening({
  actionCreator: uploadSlice.actions.requestUpload,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(uploadSlice.actions.removeAllFinishedUploads());
  },
});

/**
 * Start the create process for videos that just got uploaded to persist them in the database
 */
startAppListening({
  actionCreator: uploadVideo.fulfilled,
  effect: async (action, listenerApi) => {
    console.log('Persist uploaded video', action.payload.fileName);
    listenerApi.dispatch(
      createVideo({
        fileKey: action.payload.fileKey,
        type: VideoDTO.type.PRODUCT,
        language: action.payload.language,
        name: action.payload.fileName,
        threeQFileId: action.payload.threeQFileId as number,
      }),
    );
  },
});

/**
 * If the video name already exists, add a (1) to the name and try again
 */
startAppListening({
  actionCreator: createVideo.rejected,
  effect: async (action, listenerApi) => {
    if (
      action.payload?.requestPayload &&
      action.payload?.status === VideoUploadFeature.Status.CREATE_NAME_ERROR
    ) {
      const numberInBracketsAtTheEnd =
        action.payload.requestPayload.name.match(/\((\d+)\)$/);
      const putNumber = numberInBracketsAtTheEnd
        ? parseInt(numberInBracketsAtTheEnd[1]) + 1
        : 1;
      const nameWithoutNumber = action.payload.requestPayload.name.replace(
        /\((\d+)\)$/,
        '',
      );
      listenerApi.dispatch(
        createVideo({
          ...action.payload.requestPayload,
          name: `${nameWithoutNumber}(${putNumber})`,
        }),
      );
    }
  },
});

/**
 * Update the video list when a video was created
 */
startAppListening({
  actionCreator: createVideo.fulfilled,
  effect: async (action, listenerApi) => {
    queryClient.invalidateQueries(
      videoListKeys.getVideos(
        listenerApi.getState().auth.vendorAgent?.currentVendor.id,
      ),
    );
  },
});

export const { requestUpload, clearFiles, stopWaitingForUpload, removeFile } =
  uploadSlice.actions;
export default uploadSlice.reducer;
