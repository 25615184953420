import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { IconType } from '../../components/Icon/Icon';
import ChannelIntroVideoList from './ChannelIntroVideoList';
import ChannelProductVideoList from './ChannelProductVideoList';

export enum ChannelDetailTab {
  PRODUCT = 'product-videos',
  INTRO = 'intro-videos',
}

export interface Props {
  channelId?: number;
}

const ChannelVideoContainer = ({ channelId }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['channel']);
  const [activeTab, setActiveTab] = useState<ChannelDetailTab>(
    ChannelDetailTab.PRODUCT,
  );

  const tabs: Array<{ tab: ChannelDetailTab; name: string; icon: IconType }> = [
    {
      tab: ChannelDetailTab.PRODUCT,
      name: t('channel:tab.productVideo'),
      icon: 'product',
    },
    {
      icon: 'channel',
      tab: ChannelDetailTab.INTRO,
      name: t('channel:tab.introVideo'),
    },
  ];

  const handleTabChange = (tab: ChannelDetailTab) => {
    if (activeTab !== tab) {
      navigate(`/channels/${channelId}/${tab}`);
      setActiveTab(tab);
    }
  };

  const props = {
    channelId,
    tabs,
    activeTab,
    onTabChange: handleTabChange,
  };

  return (
    <Routes>
      <Route index element={<ChannelProductVideoList {...props} />} />
      <Route
        path={`/${ChannelDetailTab.PRODUCT}`}
        element={<ChannelProductVideoList {...props} />}
      />
      <Route
        path={`/${ChannelDetailTab.INTRO}`}
        element={<ChannelIntroVideoList {...props} />}
      />
    </Routes>
  );
};

export default ChannelVideoContainer;
