import Headline from 'components/Headline/Headline';
import { FlexItem, Spacer } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { ShipmentComponentProps } from './CreateShipment';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import InputField from 'components/Input/InputField';
import Button from 'components/Button/Button';
import { queryClient } from 'index';
import { orderShipmentKeys } from 'features/order-shipment/queries';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

type Values = {
  breadth: number;
  height: number;
  length: number;
  weight: number;
};

const CreateShipmentPackaging = ({
  packagingData,
  setStep,
  productId,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const [initialValues, setInitialValues] = useState<Values>({
    breadth: packagingData?.packageBreadth || 0,
    height: packagingData?.packageHeight || 0,
    length: packagingData?.packageLength || 0,
    weight: packagingData?.packageWeight || 0,
  });

  useEffect(() => {
    setInitialValues((pre) => {
      return Object.assign(pre, {
        breadth: packagingData?.packageBreadth || 0,
        height: packagingData?.packageHeight || 0,
        length: packagingData?.packageLength || 0,
        weight: packagingData?.packageWeight || 0,
      });
    });
  }, [packagingData]);

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    // the packaging dimensions will be set by creating the shipment, so we have to fake a mutation for now
    queryClient.setQueryData(
      orderShipmentKeys.getPackageDimensions(
        productId,
        vendorAgent?.currentVendor.id,
      ),
      () => ({
        packageBreadth: values.breadth,
        packageHeight: values.height,
        packageLength: values.length,
        packageWeight: values.weight,
        productId: productId,
      }),
    );

    formikHelpers.resetForm({ values });
    setInitialValues(values);
    setStep({ key: 'start', data: null });
  };

  const nameSchema = Yup.object().shape({
    breadth: Yup.number()
      .required(t('translation:form.required'))
      .moreThan(0, t('form.numberPositive')),
    height: Yup.number()
      .required(t('translation:form.required'))
      .moreThan(0, t('form.numberPositive')),
    length: Yup.number()
      .required(t('translation:form.required'))
      .moreThan(0, t('form.numberPositive')),
    weight: Yup.number()
      .required(t('translation:form.required'))
      .moreThan(0, t('form.numberPositive')),
  });

  return (
    <>
      <Headline
        headingLevel="h2"
        size={2}
        className="order-detail__box-headline"
      >
        {t('orders:detail.shipping.createShipment.packaging.title')}
      </Headline>
      <Spacer marginTop={5} tag="p" className="small c-dark-50">
        {t('orders:detail.shipping.createShipment.packaging.description')}
      </Spacer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form noValidate>
            <Spacer marginTop={5}>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="breadth"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.packaging.breadth',
                  )} (cm) *`}
                  type="number"
                  min="0"
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="height"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.packaging.height',
                  )} (cm) *`}
                  type="number"
                  min="0"
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="length"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.packaging.length',
                  )} (cm) *`}
                  type="number"
                  min="0"
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="weight"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.packaging.weight',
                  )} (kg) *`}
                  type="number"
                  min="0"
                />
              </Spacer>
            </Spacer>
            <FlexItem display="flex" pushBottom justifyContent="space-between">
              <Button
                appearance="ghost"
                text={t('orders:detail.shipping.createShipment.address.abort')}
                onClick={() => setStep({ key: 'start', data: null })}
              />
              <Button
                text={t('orders:detail.shipping.createShipment.address.save')}
                type="submit"
              />
            </FlexItem>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateShipmentPackaging;
