import { ReactComponent as ActiveSmallIcon } from '../../icons/active-small.svg';
import { ReactComponent as ActiveIcon } from '../../icons/active.svg';
import { ReactComponent as AddFilledIcon } from '../../icons/add-filled.svg';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as AndroidIcon } from '../../icons/android.svg';
import { ReactComponent as AttachIcon } from '../../icons/attach.svg';
import { ReactComponent as CaretDownIcon } from '../../icons/caret-down.svg';
import { ReactComponent as CaretRightIcon } from '../../icons/caret-right.svg';
import { ReactComponent as CaretUpIcon } from '../../icons/caret-up.svg';
import { ReactComponent as CaretLeftIcon } from '../../icons/caret-left.svg';
import { ReactComponent as ChangeIcon } from '../../icons/change.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from '../../icons/arrow-up.svg';
import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-left.svg';
import { ReactComponent as AverageIcon } from '../../icons/average.svg';
import { ReactComponent as BlockIcon } from '../../icons/block.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as CollapseIcon } from '../../icons/collapse.svg';
import { ReactComponent as ChannelIcon } from '../../icons/channel.svg';
import { ReactComponent as CheckFilledIcon } from '../../icons/check-filled.svg';
import { ReactComponent as CheckIcon } from '../../icons/check.svg';
import { ReactComponent as CircleFilledIcon } from '../../icons/circle-filled.svg';
import { ReactComponent as CircleIcon } from '../../icons/circle.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as CopyIcon } from '../../icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { ReactComponent as ErrorIcon } from '../../icons/error-plain.svg';
import { ReactComponent as EyeOffIcon } from '../../icons/eye-off.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as FileIconIcon } from '../../icons/file.svg';
import { ReactComponent as FilterIcon } from '../../icons/filter.svg';
import { ReactComponent as GlobeBlockedIcon } from '../../icons/globe-blocked.svg';
import { ReactComponent as HomeIcon } from '../../icons/home.svg';
import { ReactComponent as HashtagIcon } from '../../icons/hashtag.svg';
import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { ReactComponent as ImageIconIcon } from '../../icons/image.svg';
import { ReactComponent as InfoFilledIcon } from '../../icons/info-filled.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as InstallMobileIcon } from '../../icons/install-mobile.svg';
import { ReactComponent as IosIcon } from '../../icons/ios.svg';
import { ReactComponent as KeyIcon } from '../../icons/key.svg';
import { ReactComponent as LabelFilledIcon } from '../../icons/label-filled.svg';
import { ReactComponent as LabelIcon } from '../../icons/label.svg';
import { ReactComponent as LockOpenIcon } from '../../icons/lock-open.svg';
import { ReactComponent as LockOutlineIcon } from '../../icons/lock-outline.svg';
import { ReactComponent as LockIconI } from '../../icons/lock.svg';
import { ReactComponent as MailOutlineIcon } from '../../icons/mail-outline.svg';
import { ReactComponent as MailIcon } from '../../icons/mail.svg';
import { ReactComponent as MoneyIcon } from '../../icons/money.svg';
import { ReactComponent as MoreIcon } from '../../icons/more.svg';
import { ReactComponent as NotificationIconI } from '../../icons/notification.svg';
import { ReactComponent as PauseFilledIcon } from '../../icons/pause-filled.svg';
import { ReactComponent as PauseIcon } from '../../icons/pause.svg';
import { ReactComponent as PersonIcon } from '../../icons/person.svg';
import { ReactComponent as PhoneIcon } from '../../icons/phone.svg';
import { ReactComponent as PlayFilledIcon } from '../../icons/play-filled.svg';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import { ReactComponent as ProductIcon } from '../../icons/product.svg';
import { ReactComponent as ProgressBorderIcon } from '../../icons/progress-border.svg';
import { ReactComponent as ProgressWhiteIcon } from '../../icons/progress-w.svg';
import { ReactComponent as ProgressIcon } from '../../icons/progress.svg';
import { ReactComponent as ProgressingIcon } from '../../icons/progressing.svg';
import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as RemoveFilledIcon } from '../../icons/remove-filled.svg';
import { ReactComponent as RemoveIcon } from '../../icons/remove.svg';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as ShareIcon } from '../../icons/share.svg';
import { ReactComponent as SoundIcon } from '../../icons/sound.svg';
import { ReactComponent as SumIcon } from '../../icons/sum.svg';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import { ReactComponent as VideoIcon } from '../../icons/video.svg';

export const ActiveSmall = ActiveSmallIcon;
export const Active = ActiveIcon;
export const AddFilled = AddFilledIcon;
export const Add = AddIcon;
export const Android = AndroidIcon;
export const Attach = AttachIcon;
export const CaretDown = CaretDownIcon;
export const CaretRight = CaretRightIcon;
export const CaretLeft = CaretLeftIcon;
export const Change = ChangeIcon;
export const CaretUp = CaretUpIcon;
export const ArrowDown = ArrowDownIcon;
export const ArrowRight = ArrowRightIcon;
export const ArrowUp = ArrowUpIcon;
export const ArrowLeft = ArrowLeftIcon;
export const Average = AverageIcon;
export const Block = BlockIcon;
export const Calendar = CalendarIcon;
export const Channel = ChannelIcon;
export const CheckFilled = CheckFilledIcon;
export const Check = CheckIcon;
export const Clock = ClockIcon;
export const Close = CloseIcon;
export const Circle = CircleIcon;
export const CircleFilled = CircleFilledIcon;
export const Copy = CopyIcon;
export const Collapse = CollapseIcon;
export const Delete = DeleteIcon;
export const Download = DownloadIcon;
export const Edit = EditIcon;
export const Error = ErrorIcon;
export const EyeOff = EyeOffIcon;
export const Eye = EyeIcon;
export const FileIcon = FileIconIcon;
export const Filter = FilterIcon;
export const GlobeBlocked = GlobeBlockedIcon;
export const Home = HomeIcon;
export const Hashtag = HashtagIcon;
export const Help = HelpIcon;
export const ImageIcon = ImageIconIcon;
export const InfoFilled = InfoFilledIcon;
export const Info = InfoIcon;
export const InstallMobile = InstallMobileIcon;
export const Ios = IosIcon;
export const Key = KeyIcon;
export const LabelFilled = LabelFilledIcon;
export const Label = LabelIcon;
export const LockOpen = LockOpenIcon;
export const LockOutline = LockOutlineIcon;
export const LockIcon = LockIconI;
export const MailOutline = MailOutlineIcon;
export const Mail = MailIcon;
export const More = MoreIcon;
export const Money = MoneyIcon;
export const NotificationIcon = NotificationIconI;
export const PauseFilled = PauseFilledIcon;
export const Pause = PauseIcon;
export const Person = PersonIcon;
export const Phone = PhoneIcon;
export const PlayFilled = PlayFilledIcon;
export const Play = PlayIcon;
export const Product = ProductIcon;
export const ProgressBorder = ProgressBorderIcon;
export const ProgressWhite = ProgressWhiteIcon;
export const Progress = ProgressIcon;
export const Progressing = ProgressingIcon;
export const Refresh = RefreshIcon;
export const RemoveFilled = RemoveFilledIcon;
export const Remove = RemoveIcon;
export const Search = SearchIcon;
export const Settings = SettingsIcon;
export const Share = ShareIcon;
export const Sound = SoundIcon;
export const Sum = SumIcon;
export const Upload = UploadIcon;
export const Video = VideoIcon;
