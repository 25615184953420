import { VoucherDto, VoucherResponseDto } from 'generated';

export const mapVoucherResponseDtoToVoucherDto = ({
  availability,
  ...voucherResponseDto
}: VoucherResponseDto): VoucherDto => {
  return {
    ...voucherResponseDto,
    availability: {
      type: availability.type,
      active: availability.active,
      start: availability.start,
      end: availability.end,
    },
  };
};
