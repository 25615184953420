import { Spacer } from 'components/Layout/Layout';
import { VideoChannels } from 'features/video-channels';
import { useTranslation } from 'react-i18next';

const VideoDetailChannels = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation(['translation', 'video']);

  return (
    <Spacer padding={[4, 0, 14]} className="video-detail__channels">
      <Spacer tag="p" className="caption--dark" marginBottom={8}>
        {t('channelTitle')}
      </Spacer>
      <VideoChannels videoId={videoId} />
    </Spacer>
  );
};

export default VideoDetailChannels;
