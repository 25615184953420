import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../components/List/List';
import ListItem from '../../components/ListItem/ListItem';
import { VideoResponseDTO } from '../../generated';
import { State } from '../../hooks/useList';
import { useListSelection } from '../../hooks/useListSelection';
import { useReduxSelector } from '../../redux/hooks';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import getProductTitleByLanguage from '../../utils/get-product-title-by-language';

export interface Props {
  listState: State<VideoResponseDTO>;
  handleLoadMore: () => void;
  activeItem?: VideoResponseDTO;
  handleOpenDetails: (item: VideoResponseDTO) => void;
  preselected?: number[];
  onSelectionChange: (selectedIds: number[]) => void;
  selectionClearKey?: string;
}

const ChannelVideoList = ({
  listState,
  handleLoadMore,
  activeItem,
  handleOpenDetails,
  preselected,
  onSelectionChange,
  selectionClearKey,
}: Props) => {
  const { selectedIds, setSelectedItems, toggleSelectItem } =
    useListSelection(preselected);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { t } = useTranslation('video');
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      listState.items && setSelectedItems(listState.items.map((i) => i.id));
    } else {
      setSelectedItems(preselected || []);
    }
  };

  const handleSelectChange = (id: number) => {
    if (!preselected?.includes(id)) {
      toggleSelectItem(id);
    }
  };

  useEffect(() => {
    onSelectionChange(selectedIds);
    setSelectedItems(selectedIds);
    setSelectAll(selectedIds.length === listState.items?.length);
  }, [selectedIds]);

  useEffect(() => {
    setSelectedItems(preselected || []);
  }, [preselected]);

  useEffect(() => {
    setSelectedItems(preselected || []);
  }, [selectionClearKey]);

  return (
    <List
      count={{
        itemsVisible: listState.items?.length,
        total: listState.totalCount,
      }}
      handleLoadMore={handleLoadMore}
      loading={listState.loading}
      error={listState.error}
      showLoadMore={listState.loadMore}
      className="page__list"
      type="video"
      selectAll={{
        checked: selectAll,
        onSelectAll: handleSelectAll,
      }}
    >
      {listState.items &&
        listState.items.map((item, index) => {
          const listProps = item.product && {
            active: item.id === activeItem?.id,
            key: index,
            title: getProductTitleByLanguage(
              item.product.productLocalisations,
              item.language,
            ),
            subtitle: `ID: ${item.product.vendorProductId} - ${t(
              'video:name.label',
            )}: ${item.name}`,
            image: item.videoPreviews?.find((i) => i.size)?.uri,
            onClick: () => handleOpenDetails(item),
            language: item.language,
            deactivated: item.product && !item.product.isAvailable,
            highlighted:
              selectedIds?.includes(item.id) && !preselected?.includes(item.id),
            selectable: {
              selected: selectedIds?.includes(item.id) || false,
              onSelectChange: () => handleSelectChange(item.id),
              disabled: preselected?.includes(item.id) || false,
            },
            encodingStatus: item.encodingStatus,
            languageNotSupportedError: !supportedLanguages.some(
              (i) => i.iso === item.language,
            ),
            isPrivate: item.visibility === VideoResponseDTO.visibility.PRIVATE,
          };

          return <ListItem {...listProps} />;
        })}
    </List>
  );
};

export default ChannelVideoList;
