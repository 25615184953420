import classNames from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Color from '../../types/Color';
import Error from '../../types/Error';
import { randomString } from '../../utils/random-string';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import { Props as ListItemType } from '../ListItem/ListItem';
import Loader from '../Loader/Loader';
import './list.scss';

export interface Props {
  className?: string;
  count?: {
    itemsVisible?: number;
    total?: number;
  };
  error?: Error;
  loading?: boolean;
  handleLoadMore?: () => void;
  children?: Array<ReactElement<ListItemType>>;
  showLoadMore?: boolean;
  type?: 'product' | 'channel' | 'video' | 'pushNotification';
  selectAll?: {
    checked: boolean;
    onSelectAll: (checked: boolean) => void;
  };
}

/**
 *
 * Wrap this component around any collection of ListItems to create a List.
 * This also renders a count of the items in the list, and a load more button.
 * If you want to be able to select all items in the list with a checkbox, pass the selectAll prop.
 * If you are using an Infinite Query from react-query you can use the component QueryList instead
 * and spread the result of useInfiniteQuery into the props.
 */
const List = ({
  className,
  count,
  error,
  loading,
  handleLoadMore,
  children,
  showLoadMore,
  type = 'product',
  selectAll,
}: Props) => {
  const { t } = useTranslation(['list']);
  return (
    <div className={classNames('list', selectAll && 'list--select', className)}>
      {(selectAll || count) && (
        <div className="list__controls">
          {selectAll && (
            <Checkbox
              name={randomString()}
              onChange={selectAll.onSelectAll}
              checked={selectAll.checked}
            />
          )}
          {count && (
            <div className="list__count">
              {t('list:amount', {
                itemsVisible: count?.itemsVisible || 0,
                typeWithCount: t(`list:${type}`, { count: count?.total || 0 }),
              })}
            </div>
          )}
        </div>
      )}
      {error && error.text}
      {loading && !children && <Loader />}
      {children && (
        <div className="list__container">
          <ul className="list__items">{children}</ul>

          {handleLoadMore && showLoadMore && (
            <div className="list__more">
              {loading && <Loader />}
              <Button
                text={t('list:load_more')}
                icon="arrow-down"
                color={Color.primary}
                appearance="ghost"
                className="list__button-more"
                onClick={handleLoadMore}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default List;

// Added for parser to work with list:amount
// t('list:product', {count: 1})
// t('list:channel', {count: 1})
// t('list:video', {count: 1})
// t('list:pushNotification', {count: 1})
