import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ProductDetailContainer from '../container/ProductDetailContainer/ProductDetailContainer';
import ProductVideoContainer from '../container/ProductVideoContainer/ProductVideoContainer';
import PageType from '../types/PageType';

const ProductDetailPage: FC<PageType> = () => {
  const { id } = useParams();
  const numberId = id ? parseInt(id, 10) : undefined;

  useEffect(() => {
    clearAllBodyScrollLocks();
  }, []);

  return (
    <div className="page page--detail">
      <div className="page__breadcrumb">
        <Breadcrumbs />
      </div>
      <ProductDetailContainer productId={numberId} edit={true} />
      <ProductVideoContainer productId={numberId} />
    </div>
  );
};
export default ProductDetailPage;
