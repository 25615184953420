import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
  GroupBase,
  InputActionMeta,
  MenuPosition,
  SelectInstance,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  ClearIndicator,
  Control,
  InfiniteMenuList,
  Menu,
  ReactSelectProps,
  primaryColors,
  selectInteractiveStyles,
  useMultiSelectSort,
} from '..';
import { Props } from '../SelectInteractive';
import CreateButton from './CreateButton';
import { DefaultOption } from './Option';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import c from 'utils/colors';

/** Renders a select that is always open with a searchbar on top.
Used internally in the MobileSelect of SelectInteractive.
When used outside of the SelectInteractive context, you need to add error/success messages yourself.
Consider using the SelectInteractive with the variant=open to get this component with the populated props. */
const OpenSelect = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, Group>,
) => {
  const { t } = useTranslation();
  const ref = useRef<SelectInstance<OptionType, IsMulti, Group> | null>(null);
  const { options } = useMultiSelectSort<OptionType, Group>({
    options: props.options,
    value: props.value,
  });

  const sharedProps: ReactSelectProps<OptionType, IsMulti, Group> = {
    autoFocus: false,
    classNamePrefix: 'select-interactive',
    defaultInputValue: '',
    loadingMessage: () => t('loading'),
    onMenuClose: () => props.onInputChange?.('', {} as InputActionMeta),
    ...props,
    options: props.isMulti ? options : props.options,
    noOptionsMessage: props.noOptionsMessage || t('noOptions'),
    menuIsOpen: true,
    isOpenSelect: true,
    openMenuOnFocus: true,
    components: {
      Menu,
      LoadingIndicator: (props) => <Loader {...props} small />,
      ClearIndicator,
      MenuList: InfiniteMenuList,
      Control,
      ...props.components,
      DropdownIndicator: null,
    },
    hint: props.error ? props.error : props.hint,
    placeholder: props.placeholder || t('search'),
    hideSelectedOptions: false,
    isDisabled: false,
    controlShouldRenderValue: false,
    menuPosition: 'static' as MenuPosition,
    className: classNames('select-interactive--open-select', props.className),
    styles: {
      ...selectInteractiveStyles<OptionType, IsMulti, Group>(),
      ...props.styles,
      menu: () => ({
        ...selectInteractiveStyles<OptionType, IsMulti, Group>().menu,
        position: 'static',
      }),
    },
    theme: props.theme
      ? props.theme
      : (theme) => ({
          ...theme,
          borderRadius: parseInt(c('--border-radius-default')),
          colors: {
            ...theme.colors,
            ...primaryColors(),
            danger: c('--color-pink'),
            dangerLight: c('--color-pink-light'),
          },
        }),
  };

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="open-select">
      {props.onCreateOption ? (
        <CreatableSelect
          formatCreateLabel={(inputValue) =>
            CreateButton(inputValue, t('add'), props.createButtonPrefix)
          }
          ref={ref}
          {...sharedProps}
        />
      ) : (
        <Select
          {...sharedProps}
          ref={ref}
          isOptionDisabled={(option, selectValue) =>
            Array.isArray(selectValue) &&
            props.isMulti &&
            selectValue?.length >= 3 &&
            !selectValue.includes(option)
              ? true
              : false
          }
        />
      )}
    </div>
  );
};

export default OpenSelect;
