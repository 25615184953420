import { useMutation } from '@tanstack/react-query';
import { videoDetailKeys } from 'features/video-detail/queries';
import { videoListKeys } from 'features/video-list';
import { ApiError, VideoResponseDTO, VideosService } from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
/**
 * Update video type
 */
export const useUpdateVideoType = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<
    VideoResponseDTO,
    ApiError,
    { type: VideoResponseDTO.type; videoId: number; force?: boolean }
  >({
    mutationFn: async ({ type, videoId, force }) =>
      VideosService.updateVideoType(videoId, { type, force }),
    onSuccess: (data, { videoId }) => {
      queryClient.setQueryData(
        videoDetailKeys.getVideo(videoId, vendorAgent?.currentVendor.id),
        (old?: VideoResponseDTO) => ({
          ...old,
          ...data,
          product: data.product ? data.product : undefined,
        }),
      );
      queryClient.invalidateQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      );
    },
  });
};
