import AddButton from 'components/AddButton/AddButton';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import VideoUploadFile from './VideoUploadFile';
import { VideoUploadFeature } from '../types';

export type Props = {
  onAdd: () => void;
  setFiles: React.Dispatch<
    React.SetStateAction<VideoUploadFeature.FileDataCollection[]>
  >;
  files: VideoUploadFeature.FileData[];
};

/**
 * List of files with an add more button. Each file will fetch its own upload url.
 */
const VideoUploadFiles = ({ onAdd, setFiles, files }: Props) => {
  const { t } = useTranslation();

  const updateFile = (
    fileKey: string,
    newData: Partial<VideoUploadFeature.FileData>,
  ) => {
    setFiles((pre) =>
      pre.map((file) =>
        file.fileKey === fileKey ? { ...file, ...newData } : file,
      ),
    );
  };

  const removeFile = (index: number) => {
    setFiles((pre) => pre.filter((_, i) => i !== index));
  };

  return (
    <Flipper flipKey={files.toString()}>
      <ul className="video-upload__list">
        {files.length > 0 &&
          files.map((file, index) => (
            <VideoUploadFile
              file={file}
              onClose={() => removeFile(index)}
              key={file.fileKey}
              index={index}
              updateFile={updateFile}
            />
          ))}
        {files.length > 0 && (
          <AddButton title={t('video:upload.add')} onClick={onAdd} />
        )}
      </ul>
    </Flipper>
  );
};

export default VideoUploadFiles;
