import { VendorCityDto, VendorCountryDto, VendorStateDto } from 'generated';

export type ZipCodeValueOption = {
  name: string;
  iso: string;
};

export type ZipCodeValue = {
  zip?: string;
  country?: Partial<VendorCountryDto>;
  city?: Partial<VendorCityDto>;
  province?: Partial<VendorStateDto>;
};

export enum ZipCodeLocationKey {
  zip = 'zip',
  city = 'city',
  province = 'province',
  provinceCode = 'provinceCode',
  country = 'country',
  countryCode = 'countryCode',
  countryName = 'countryName',
}
