import Chip from 'components/Chip/Chip';
import { FlexBox, FlexItem } from 'components/Layout';
import ListItemSelect, {
  Props as ListItemSelectProps,
} from 'components/ListItemSelect/ListItemSelect';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { isAvailable } from 'features/availability';
import { ChannelMinimalResponseDTO, VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';

export type Props = Omit<ListItemSelectProps, 'children'> & {
  channel: ChannelWithConflict;
};
export type ChannelWithConflict = ChannelMinimalResponseDTO & {
  conflict?: boolean;
};

const ListItemSelectChannel = ({ channel, ...props }: Props) => {
  const { t } = useTranslation(['translation', 'video']);
  return (
    <ListItemSelect {...props}>
      <FlexBox alignItems="center">
        <VideoStatusIcon
          status={
            isAvailable(channel.availability)
              ? VideoResponseDTO.status.LIVE
              : VideoResponseDTO.status.IDLE
          }
        />

        <FlexItem marginLeft={2}>{channel.name}</FlexItem>
        {channel.conflict && (
          <FlexItem pushRight>
            <Chip color={ColorUse['warning-50']}>
              {t('video:type.conflict')}
            </Chip>
          </FlexItem>
        )}
      </FlexBox>
    </ListItemSelect>
  );
};

export default ListItemSelectChannel;
