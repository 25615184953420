import Button from 'components/Button/Button';
import Headline from 'components/Headline/Headline';
import InputField from 'components/Input/InputField';
import Progress from 'components/Progress/Progress';
import VideoUploadField from 'components/VideoUploadField/VideoUploadField';
import { Status } from 'container/VideoUploadContainer/VideoUploadContainer';
import { SelectSupportedLanguages } from 'features/language';
import { Field, useFormikContext } from 'formik';
import { ApiError } from 'generated';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import Language from 'types/Language';
import './video-uploader.scss';

export interface Props {
  title?: string;
  description?: string;
  progress: number;
  onCancel: () => void;
  onStartNext: () => void;
  onClose: () => void;
  status: Status;
  error?: ApiError;
  onChangeName: () => void;
  type?: 'channel' | 'product';
}

export type Values = { file: File | null; name: string; language: Language };

const VideoUploader = ({
  title,
  description,
  progress,
  onCancel,
  onStartNext,
  onClose,
  status,
  error,
  onChangeName,
  type = 'product',
}: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldError } = useFormikContext<Values>();

  const handleChange = (value: File) => {
    if (value) {
      setFieldValue('name', value.name);
    }
  };

  const getStatusText = () => {
    if (status === Status.success) {
      return {
        title: t('video:upload.finished.title', {
          type: t(`video:type.${type}.label`),
        }),
        subtitle: t('video:upload.finished.subtitle'),
      };
    }

    // name duplicate error
    if (
      status === Status.exception &&
      error?.body?.error === 'DuplicatedEntryError'
    ) {
      return {
        title: t('video:upload.nameError.title'),
        subtitle: t('video:upload.nameError.subtitle'),
      };
    }

    if (status === Status.exception) {
      return {
        title: t('video:upload.exception.title'),
        subtitle: t('video:upload.exception.subtitle'),
      };
    }

    if (status === Status.active) {
      return {
        title: t('video:upload.started.title', {
          type: t(`video:type.${type}.label`),
        }),
      };
    }
  };

  useEffect(() => {
    // name duplicate error
    if (error && error.body?.error === 'DuplicatedEntryError') {
      setFieldError('name', t('video:upload.nameError.field'));
    }
  }, [error]);

  return (
    <div className="video-uploader">
      <div className="video-uploader__header">
        <Headline headingLevel="h1" size={2}>
          {title || t('video:upload.title')}
        </Headline>
        {description && (
          <div className="video-uploader__description">{description}</div>
        )}
      </div>
      <div className="video-uploader__area">
        <div className="video-uploader__fields">
          <Field
            name="file"
            component={VideoUploadField}
            onChange={handleChange}
            disabled={status === Status.active || status === Status.success}
            size
          />
          <Field
            name="name"
            component={InputField}
            disabled={
              (!values.file || progress >= 98) &&
              error?.body?.error !== 'DuplicatedEntryError'
            }
            label={t('video:name.label')}
          />
          <SelectSupportedLanguages
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              setFieldValue('language', event.target.value)
            }
            value={values.language}
            disabled={!values.file || progress >= 98}
          />
          {values.file && status === Status.normal && (
            <Button
              text={t('video:upload.upload')}
              type="submit"
              color={Color.primary}
            />
          )}
          {status === Status.exception &&
            error?.body?.error === 'DuplicatedEntryError' && (
              <Button
                text={t('save')}
                type="button"
                color={Color.primary}
                onClick={onChangeName}
              />
            )}
        </div>
        {status === Status.normal && (
          <div className="video-uploader__footer">
            <Button
              text={t('close')}
              appearance="ghost"
              color={Color.primary}
              onClick={onClose}
            />
          </div>
        )}
        {status !== Status.normal && (
          <div className="video-uploader__progress">
            <Progress
              progress={progress || 0}
              status={status}
              {...getStatusText()}
            />
            {status === Status.active && (
              <Button
                text={t('cancel')}
                type="button"
                onClick={onCancel}
                color={Color.primary}
                appearance="ghost"
              />
            )}
            {status === Status.success && (
              <div className="video-uploader__button-group">
                {type === 'product' && (
                  <Button
                    color={Color.primary}
                    appearance="ghost"
                    type="button"
                    text={t('video:upload.finished.next')}
                    onClick={onStartNext}
                    icon="add"
                  />
                )}

                <Button
                  color={Color.primary}
                  type="button"
                  text={t('video:upload.finished.done')}
                  onClick={onClose}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoUploader;

// t('video:type.channel')
// t('video:type.product')
