import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import {
  ApiError,
  VendorAddressService,
  VendorPaginatedPostCodesDto,
} from 'generated';
import { useReduxSelector } from 'redux/hooks';
import { selectCountryCode } from 'redux/slices/i18n/i18nSlice';

export enum KEYS {
  getPostCodes = 'getPostCodes',
}

export const countryCityStateKeys = {
  getPostCodes: (searchTerm?: string | void) => [KEYS.getPostCodes, searchTerm],
};

export const useGetPostCodes = (
  searchTerm?: string,
  config?: UseInfiniteQueryOptions<
    VendorPaginatedPostCodesDto,
    ApiError,
    VendorPaginatedPostCodesDto,
    VendorPaginatedPostCodesDto
  >,
) => {
  const countryCode = useReduxSelector(selectCountryCode);
  return useInfiniteQuery<VendorPaginatedPostCodesDto, ApiError>({
    queryKey: countryCityStateKeys.getPostCodes(searchTerm),
    queryFn: async ({ pageParam = 0 }) =>
      VendorAddressService.searchPostCodes(
        countryCode,
        pageParam,
        1,
        'name',
        'DESC',
        searchTerm,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
    ...config,
  });
};
