import classnames from 'classnames';
import Icon from 'components/Icon/Icon';
import { Icon as IconType } from 'components/Icon/icon-spec';
import Loader from 'components/Loader/Loader';
import Tooltip from 'components/Tooltip/Tooltip';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Color, { ColorUse } from 'types/Color';
import { randomString } from 'utils/random-string';
import './icon-button.scss';

export type TooltipPlace = 'up' | 'down' | 'left' | 'right';

export interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  icon?: IconType;
  appearance?: 'filled' | 'ghost' | 'blank';
  color?: Color | ColorUse;
  big?: boolean;
  tooltip?: string;
  wrapperClassName?: string;
  link?: string;
  tooltipPlace?: TooltipPlace;
  tooltipDelay?: number;
  loading?: boolean;
  /** Number on the top left of the button, indicating there is a number of items highlighted hidden by the button */
  highlightNumber?: number;
}

const IconButton = ({
  type = 'button',
  icon = 'share',
  appearance = 'ghost',
  color = Color.primary,
  big,
  tooltip,
  wrapperClassName,
  link,
  tooltipPlace = 'down',
  tooltipDelay = 500,
  loading,
  highlightNumber,
  ...rest
}: Props) => {
  const { className, ...props } = rest;
  const id = rest.id || randomString();
  const ref = useRef<HTMLButtonElement & HTMLAnchorElement>(null);

  const classNames = classnames(
    'icon-button',
    `icon-button--${appearance}`,
    `icon-button--${color}`,
    big && 'icon-button--big',
    rest.disabled && 'icon-button--disabled',
    className,
  );

  const HtmlTag = ({
    children,
    ...rest
  }: React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement>) => {
    if (link) {
      return (
        <Link
          to={link}
          data-tip={tooltip}
          data-for={id}
          className={classNames}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    const { onClick, ...restRest } = rest;

    if (!onClick && type !== 'submit') {
      return (
        <div className={classNames} data-tip={tooltip} data-for={id}>
          {children}
        </div>
      );
    }

    const newOnClick = (
      event: React.MouseEvent<
        HTMLButtonElement & HTMLAnchorElement,
        globalThis.MouseEvent
      >,
    ) => {
      if (type !== 'submit') {
        event.preventDefault();
      }
      ref.current?.blur();
      onClick && onClick(event);
    };

    return (
      <button
        data-tip={tooltip}
        data-for={id}
        className={classNames}
        type={type}
        onMouseDown={newOnClick}
        style={{
          pointerEvents: restRest.disabled ? 'none' : 'all',
        }}
        ref={ref}
        {...restRest}
      >
        {children}
      </button>
    );
  };

  return (
    <div className={classnames('icon-button__container', wrapperClassName)}>
      {tooltip ? (
        <Tooltip
          content={tooltip}
          direction={tooltipPlace}
          hoverDelay={tooltipDelay}
          className="icon-button__wrap"
        >
          <HtmlTag {...props}>
            {loading ? <Loader small color={color} /> : <Icon icon={icon} />}
          </HtmlTag>
        </Tooltip>
      ) : (
        <HtmlTag {...props}>
          {loading ? <Loader small color={color} /> : <Icon icon={icon} />}
        </HtmlTag>
      )}
      {highlightNumber && (
        <div
          className={classnames(
            'icon-button__highlight',
            `icon-button__highlight--${appearance}`,
            `icon-button__highlight--${color}`,
            big && 'icon-button__highlight--big',
          )}
        >
          {highlightNumber > 10 ? '10+' : highlightNumber}
        </div>
      )}
    </div>
  );
};

export default IconButton;
