import InputInlineEditField from 'components/InputInlineEdit/InputInlineEditField';
import { Spacer } from 'components/Layout/Layout';
import { Field, getIn, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateVideoName } from '../../queries';
import { Props } from '../VideoUpdateForm';

const VideoUpdateName = ({ videoId }: { videoId: number }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation(['translation', 'video']);
  const { initialValues, values, errors, setFieldValue } =
    useFormikContext<Omit<Props, 'id'>>();
  const nameMutation = useUpdateVideoName();
  const fieldName = 'name';

  const reset = () => {
    setEdit(false);
    // show success badge for 7s
    setTimeout(() => {
      nameMutation.reset();
    }, 7000);
  };

  const handleEditSave = () => {
    nameMutation.mutate(
      { videoId, name: values.name },
      {
        onSuccess: reset,
        onError: () => {
          setEdit(false);
          setFieldValue(fieldName, getIn(initialValues, fieldName), true);
        },
      },
    );
  };

  const handleEditCancel = () => {
    setEdit(false);
    nameMutation.reset();
  };

  useEffect(() => {
    if (getIn(values, fieldName) === getIn(initialValues, fieldName)) {
      setEdit(false);
    } else {
      setEdit(true);
      nameMutation.reset();
    }
  }, [values]);

  return (
    <Spacer marginBottom={4}>
      <Field
        component={InputInlineEditField}
        onEditCancel={handleEditCancel}
        onEditSave={handleEditSave}
        name={fieldName}
        label={t('video:name.label')}
        disabled={nameMutation.isLoading}
        success={
          nameMutation.isSuccess && !edit ? t('inline-edit.success') : undefined
        }
        error={
          nameMutation.isError || errors.name
            ? errors.name || t('inline-edit.error')
            : undefined
        }
        edit={edit}
        loading={nameMutation.isLoading}
      />
    </Spacer>
  );
};

export default VideoUpdateName;
