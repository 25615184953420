import classNames from 'classnames';
import { Spacer } from 'components/Layout';
import PriceDiscount from 'components/PriceDiscount/PriceDiscount';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { ReactElement, useState } from 'react';
import Button from '../Button/Button';
import Headline from '../Headline/Headline';
import { TabPaneType } from './TabPane';
import './localisation-tabs.scss';
import { useTranslation } from 'react-i18next';

export interface TabsType {
  children: Array<ReactElement<TabPaneType>>;
  className?: string;
  initial?: TabPaneType;
  showTabPaneContent?: boolean;
  hideActiveTabHeadline?: boolean;
  status?: VideoResponseDTO.status;
}

const LocalisationTabs = ({
  children,
  className,
  initial,
  showTabPaneContent = true,
  hideActiveTabHeadline = false,
  status,
}: TabsType) => {
  const [activeTab, setActiveTab] = useState<TabPaneType>(
    initial || (children[0] && children[0].props),
  );
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const handleActiveTab = (props: TabPaneType) => {
    setActiveTab(props);
  };

  return (
    <div
      className={classNames(
        'localisation-tabs',
        status && 'localisation-tabs--status',
        className,
      )}
    >
      <div className="localisation-tabs__title-wrap">
        {activeTab?.price && (
          <PriceDiscount
            price={activeTab.price.amount}
            currency={activeTab.price.currency}
            discountPrice={activeTab.discountPrice}
          />
        )}

        {!hideActiveTabHeadline && (
          <Headline
            headingLevel="h2"
            size={isDesktop ? 2 : 3}
            className="localisation-tabs__title"
          >
            {status && (
              <Spacer marginRight={1} tag="span">
                <VideoStatusIcon status={status} />
              </Spacer>
            )}
            {activeTab?.title || t('no_title')}
          </Headline>
        )}

        {activeTab?.subtitle && (
          <p className="localisation-tabs__subtitle">{activeTab.subtitle}</p>
        )}
      </div>

      <div className="localisation-tabs__header">
        <ul className="localisation-tabs__list">
          {children &&
            children.map((tab, index) => (
              <li className="localisation-tabs__list-item" key={index}>
                <Button
                  className="localisation-tabs__tab"
                  text={tab.props.name.substring(0, 2)}
                  appearance={
                    activeTab?.id === tab.props.id ? 'filled' : 'ghost'
                  }
                  disableFocus={true}
                  onClick={
                    children.length > 1
                      ? () => handleActiveTab(tab.props)
                      : undefined
                  }
                  small={!isDesktop}
                />
              </li>
            ))}
        </ul>
      </div>

      {showTabPaneContent && (
        <div className="localisation-tabs__content">
          {Array.isArray(children)
            ? children.map((panel) => {
                if (panel.props.id === activeTab.id) {
                  return panel;
                }
                return null;
              })
            : children}
        </div>
      )}
    </div>
  );
};

export default LocalisationTabs;
