import FileProgressItem, {
  Props as FileProgressItemProps,
} from 'components/FileProgressItem/FileProgressItem';
import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './file-manager.scss';

export type Props = {
  files: FileProgressItemProps[];
  renderItem?: (file: FileProgressItemProps) => React.ReactNode;
  onClose: () => void;
  title?: string;
  showClose?: boolean;
  numberOfCompletedFiles?: number;
};

const collapseAnimation = {
  initial: {
    height: 0,
  },
  animate: {
    height: 'auto',
    transition: {
      height: {
        duration: 0.4,
      },
    },
  },
  exit: {
    height: 0,
    transition: {
      height: {
        duration: 0.4,
      },
    },
  },
};

const FileManager = ({
  onClose,
  files,
  title,
  showClose,
  numberOfCompletedFiles = 0,
  renderItem,
}: Props) => {
  const { t } = useTranslation(['translation', 'video']);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <section className="file-manager">
      <header className="file-manager__header">
        <FlexBox alignItems="center">
          <h2 className="file-manager__title">
            <span>{title || t('video:upload.title-multi')}</span>
            <span className="file-manager__progress">
              {numberOfCompletedFiles} / {files.length}
            </span>
          </h2>

          <FlexItem>
            <button
              className="file-manager__button"
              type="button"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Icon icon="collapse" />
            </button>
          </FlexItem>
          {showClose && (
            <FlexItem marginLeft={4}>
              <button
                type="button"
                onClick={onClose}
                className="file-manager__button"
              >
                <Icon icon="close" />
              </button>
            </FlexItem>
          )}
        </FlexBox>
      </header>
      <AnimatePresence initial={false}>
        {!collapsed && (
          <motion.div
            {...collapseAnimation}
            key={collapsed ? 'collapsed' : 'open'}
            className="file-manager__body"
          >
            <div className="file-manager__body-inner">
              {files.length === 0 && (
                <Spacer className="file-manager__empty" tag="p">
                  {t('video:upload.empty')}
                </Spacer>
              )}
              {files.length > 0 && (
                <ul className="file-manager__list">
                  {files.map((file) =>
                    renderItem ? (
                      <React.Fragment key={file.fileKey}>
                        {renderItem(file)}
                      </React.Fragment>
                    ) : (
                      <FileProgressItem {...file} key={file.fileKey} />
                    ),
                  )}
                </ul>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default FileManager;
