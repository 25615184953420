import { useQuery } from '@tanstack/react-query';
import {
  ApiError,
  ChannelsService,
  ChannelVideoIdsResponseDTO,
} from 'generated';

export enum KEYS {
  getChannelVideoIds = 'getChannelVideoIds',
}

export const channelVideoListKeys = {
  getChannelVideoIds: (channelId: number) => [
    KEYS.getChannelVideoIds,
    channelId,
  ],
};

export const useGetChannelVideoIds = (channelId: number) => {
  return useQuery<ChannelVideoIdsResponseDTO, ApiError>({
    queryKey: channelVideoListKeys.getChannelVideoIds(channelId),
    queryFn: () => ChannelsService.getChannelVideoIds(channelId),
  });
};
