import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import ValidatePasswordInput from '../../components/ValidatePasswordInput/ValidatePasswortInput';
import { InputProps } from '../../types/InputType';
import getFormError from '../../utils/get-form-error';
import './new-password.scss';

export type NewPasswordFormValues = {
  password: string;
};

const NewPasswordForm = ({
  onSubmit,
}: {
  onSubmit: (values: NewPasswordFormValues) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('form.required'))
      .matches(
        /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};,<.>~]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t('form.password-criteria', {
          specialChars: '!?@#$%^&*()-_=+{};,<.>~',
        }),
      ),
  });

  const initialValues: NewPasswordFormValues = { password: '' };

  const newPasswordForm = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const passwordInputProps: InputProps = {
    ...newPasswordForm.getFieldProps('password'),
    label: t('newPasswordForm.labels.password'),
    placeholder: t('newPasswordForm.placeholders.password'),
    error: getFormError(newPasswordForm, 'password'),
  };

  return (
    <form onSubmit={newPasswordForm.handleSubmit}>
      <ValidatePasswordInput {...passwordInputProps} />

      <div className="new-password__submit-btn">
        <Button text={t('newPasswordForm.submit')} type="submit" />
      </div>
    </form>
  );
};

export default NewPasswordForm;
