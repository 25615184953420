/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VideoItemDTO } from './VideoItemDTO';
import type { VideoPreviewDTO } from './VideoPreviewDTO';

export type AppVideoDTO = {
    videoId?: string;
    type?: AppVideoDTO.type;
    language: string;
    name: string;
    meta?: string;
    videoOffset?: number;
    aspectRatio: number;
    maxWidth: number;
    maxHeight: number;
    videoDuration?: number;
    orientation?: AppVideoDTO.orientation;
    channelKeyReference: string;
    visibility: AppVideoDTO.visibility;
    videoPreviews?: Array<VideoPreviewDTO>;
    videoItems: Array<VideoItemDTO>;
};

export namespace AppVideoDTO {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }

    export enum orientation {
        PORTRAIT = 'portrait',
        LANDSCAPE = 'landscape',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

