import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Footer from 'components/Footer/Footer';
import Headline from 'components/Headline/Headline';
import Layout, {
  FlexBox,
  FlexItem,
  Section,
  Sidebar,
  Spacer,
} from 'components/Layout';
import VideoFilterBar, { VideoFilterProvider } from 'features/video-filter';
import { VideoUploadModal } from 'features/video-upload';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useSidebar from 'hooks/useSidebar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';
import VideoData from './VideoData';

/** Route /videos/ */
const VideoList = () => {
  const { t } = useTranslation(['translation', 'video']);
  const sidebarProps = useSidebar();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { openSidebar } = useSidebar();
  const childRouteIsActive = useMatch('/videos/:path/*');
  const isFilterRoute = useMatch('/videos/filter');

  useEffect(() => {
    if (childRouteIsActive) {
      openSidebar();
    }
  }, [childRouteIsActive]);

  return (
    <Layout>
      <Section appearance="medium" breakLeft>
        <FlexBox
          justifyContent="space-between"
          flexDirection={isDesktop ? 'row' : 'column'}
        >
          <Breadcrumbs />
          <FlexItem
            alignSelf={!isDesktop ? 'flex-end' : 'flex-start'}
            marginTop={isDesktop ? 6 : -14}
          >
            <VideoUploadModal />
          </FlexItem>
        </FlexBox>
      </Section>

      <Section appearance="medium" style={{ flexGrow: 1 }}>
        <Spacer marginBottom={7}>
          <Headline headingLevel="h1" size={2} className="page__title">
            {t('videosTitle')}
          </Headline>
        </Spacer>

        {isDesktop && (
          <Spacer marginBottom={14}>
            <VideoFilterBar />
          </Spacer>
        )}

        <Spacer margin={isDesktop ? 0 : [0, -2]}>
          <ErrorBoundary>
            <VideoData />
          </ErrorBoundary>
        </Spacer>
      </Section>

      <Sidebar
        {...sidebarProps}
        appearance={isFilterRoute ? 'narrow' : 'overlay'}
        closeIconIsVisible={false}
      >
        <ErrorBoundary>
          {/* route /videos/:id  or /videos/filter */}
          <Outlet />
        </ErrorBoundary>
      </Sidebar>

      <Spacer marginTop={8}>
        <Footer />
      </Spacer>
    </Layout>
  );
};

const VideoListWithFilterProvider = () => {
  return (
    <VideoFilterProvider>
      <VideoList />
    </VideoFilterProvider>
  );
};
export default VideoListWithFilterProvider;
