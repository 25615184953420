/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppCustomerUpdateDto = {
    firstName: string;
    lastName: string;
    gender: AppCustomerUpdateDto.gender;
    dateOfBirth?: string;
    email: string;
    telephone: string;
};

export namespace AppCustomerUpdateDto {

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_9' = 9,
    }


}

