/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditNotificationDTO } from '../models/EditNotificationDTO';
import type { NotificationDTO } from '../models/NotificationDTO';
import type { NotificationResponseDTO } from '../models/NotificationResponseDTO';
import type { PaginatedNotificationResponseDTO } from '../models/PaginatedNotificationResponseDTO';
import type { TestNotificationDTO } from '../models/TestNotificationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * Get notifications
     * @param offset
     * @param limit
     * @param status
     * @returns PaginatedNotificationResponseDTO
     * @throws ApiError
     */
    public static getNotifications(
        offset?: number,
        limit?: number,
        status?: 'pending' | 'sent' | 'all',
    ): CancelablePromise<PaginatedNotificationResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/notifications',
            query: {
                'offset': offset,
                'limit': limit,
                'status': status,
            },
        });
    }

    /**
     * Add new Notification
     * @param requestBody
     * @returns NotificationResponseDTO
     * @throws ApiError
     */
    public static addNotification(
        requestBody: NotificationDTO,
    ): CancelablePromise<NotificationResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notifications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sends a test Notification
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static sendTestNotification(
        requestBody: TestNotificationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/notifications/test',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update notification
     * @param id
     * @param requestBody
     * @returns NotificationResponseDTO
     * @throws ApiError
     */
    public static updateNotification(
        id: number,
        requestBody: EditNotificationDTO,
    ): CancelablePromise<NotificationResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/notifications/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * delete notification
     * @param id
     * @returns NotificationResponseDTO
     * @throws ApiError
     */
    public static deleteNotification(
        id: number,
    ): CancelablePromise<NotificationResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/notifications/{id}',
            path: {
                'id': id,
            },
        });
    }

}
