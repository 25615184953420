/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VideoRequestUploadUrlDTO = {
    fileName: string;
    fileFormat: VideoRequestUploadUrlDTO.fileFormat;
};

export namespace VideoRequestUploadUrlDTO {

    export enum fileFormat {
        MXF = 'mxf',
        MP4 = 'mp4',
        M4V = 'm4v',
        MPG = 'mpg',
        MPEG = 'mpeg',
        MOV = 'mov',
        WEBM = 'webm',
        MKV = 'mkv',
        AVI = 'avi',
        _3GP = '3gp',
        WMV = 'wmv',
        TS = 'ts',
        MP3 = 'mp3',
        WAV = 'wav',
        AAC = 'aac',
        OPUS = 'opus',
        M4A = 'm4a',
    }


}

