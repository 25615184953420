/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddProductToVideoDto } from '../models/AddProductToVideoDto';
import type { BatchUpdateVideoLanguageDto } from '../models/BatchUpdateVideoLanguageDto';
import type { HashtagResponseDTO } from '../models/HashtagResponseDTO';
import type { PaginatedVideoResponseDTO } from '../models/PaginatedVideoResponseDTO';
import type { UpdateEntityHashtagsDTO } from '../models/UpdateEntityHashtagsDTO';
import type { UpdateVideoChannelsDto } from '../models/UpdateVideoChannelsDto';
import type { UpdateVideoLanguageDto } from '../models/UpdateVideoLanguageDto';
import type { UpdateVideoNameDto } from '../models/UpdateVideoNameDto';
import type { UpdateVideoTypeDto } from '../models/UpdateVideoTypeDto';
import type { VideoDTO } from '../models/VideoDTO';
import type { VideoIdsDTO } from '../models/VideoIdsDTO';
import type { VideoRequestUploadUrlDTO } from '../models/VideoRequestUploadUrlDTO';
import type { VideoResponseDTO } from '../models/VideoResponseDTO';
import type { VideoUpdateDTO } from '../models/VideoUpdateDTO';
import type { VideoUploadDTO } from '../models/VideoUploadDTO';
import type { VideoVisibilityUpdateDTO } from '../models/VideoVisibilityUpdateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VideosService {

    /**
     * Gets the video with the specified id
     * @param id
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static getVideo(
        id: number,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/videos/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a Videos
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateVideo(
        id: number,
        requestBody: VideoUpdateDTO,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a Video
     * @param id
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static deleteVideo(
        id: number,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/videos/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Add a Video
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static addVideo(
        requestBody: VideoDTO,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/videos',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get videos associated with currently authenticated vendor
     * @param offset
     * @param limit
     * @param searchTerm
     * @param searchLocale
     * @returns PaginatedVideoResponseDTO
     * @throws ApiError
     */
    public static getVideos(
        offset?: number,
        limit?: number,
        searchTerm?: string,
        searchLocale?: string,
    ): CancelablePromise<PaginatedVideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/videos',
            query: {
                'offset': offset,
                'limit': limit,
                'searchTerm': searchTerm,
                'searchLocale': searchLocale,
            },
        });
    }

    /**
     * Add multiple Videos
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static addVideos(
        requestBody: Array<string>,
    ): CancelablePromise<Array<VideoResponseDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/videos/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a video upload URL for uploading a video
     * @param requestBody
     * @returns VideoUploadDTO
     * @throws ApiError
     */
    public static createVideoUploadUrl(
        requestBody: VideoRequestUploadUrlDTO,
    ): CancelablePromise<VideoUploadDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/videos/uploadUrl',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get videos associated with currently authenticated vendor
     * @param offset
     * @param limit
     * @param channelIds
     * @param productIds
     * @param categoryIds
     * @param hashtagIds
     * @param type
     * @param status
     * @param visibility
     * @returns PaginatedVideoResponseDTO
     * @throws ApiError
     */
    public static getVideosV2(
        offset?: number,
        limit?: number,
        channelIds?: Array<number>,
        productIds?: Array<number>,
        categoryIds?: Array<number>,
        hashtagIds?: Array<number>,
        type?: Array<'product' | 'promotion' | 'channel'>,
        status?: Array<'draft' | 'live' | 'idle'>,
        visibility?: Array<'public' | 'private'>,
    ): CancelablePromise<PaginatedVideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/videos',
            query: {
                'offset': offset,
                'limit': limit,
                'channelIds': channelIds,
                'productIds': productIds,
                'categoryIds': categoryIds,
                'hashtagIds': hashtagIds,
                'type': type,
                'status': status,
                'visibility': visibility,
            },
        });
    }

    /**
     * Change the visibility of multiple Videos
     * @param requestBody
     * @returns VideoIdsDTO
     * @throws ApiError
     */
    public static updateVideosVisibility(
        requestBody: VideoVisibilityUpdateDTO,
    ): CancelablePromise<VideoIdsDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/batch/visibility',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param appSecret
     * @returns string
     * @throws ApiError
     */
    public static getHlsStreamingUrls(
        appSecret: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/videos/streamingUrls',
            headers: {
                'app-secret': appSecret,
            },
        });
    }

    /**
     * Updates the video name
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateVideoName(
        id: number,
        requestBody: UpdateVideoNameDto,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/{id}/name',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates the video type
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateVideoType(
        id: number,
        requestBody: UpdateVideoTypeDto,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/{id}/type',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates the video language
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateVideoLanguage(
        id: number,
        requestBody: UpdateVideoLanguageDto,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/{id}/language',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Batch updates video languages
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static batchUpdateVideoLanguages(
        requestBody: BatchUpdateVideoLanguageDto,
    ): CancelablePromise<Array<VideoResponseDTO>> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/batch/language',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Run status update on videos
     * @param authorization
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateProductStatus(
        authorization: string,
        requestBody: VideoIdsDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/videos/status-update',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * updates the product associated with the video
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateVideoProduct(
        id: number,
        requestBody: AddProductToVideoDto,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/videos/{id}/product',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates the video hashtags
     * @param id
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static updateVideoHashtags(
        id: number,
        requestBody: UpdateEntityHashtagsDTO,
    ): CancelablePromise<Array<HashtagResponseDTO>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/videos/{id}/hashtags',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Adds a video to a list of channels. When force flag is set it will also do it for channel intro videos.
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static addVideoToChannels(
        id: number,
        requestBody: UpdateVideoChannelsDto,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/videos/{id}/channels',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
