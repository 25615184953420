import Button from 'components/Button/Button';
import DatePickerField from 'components/DatePicker/DatePickerField';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Headline from 'components/Headline/Headline';
import { FlexBox, Spacer } from 'components/Layout';
import { subBusinessDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { orderDetailKeys } from 'features/order-detail';
import { ExtendedVendorOrderDto } from 'features/order-detail/queries';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import parseDateString from 'utils/parse-date-string';
import * as Yup from 'yup';
import { ShipmentComponentProps } from './CreateShipment';

type Values = {
  pickupdate?: string;
};

const CreateShipmentPickupdate = ({
  setStep,
  orderNo,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const timeZone = useReduxSelector(selectTimezone);

  const getNextBusinessDay = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return utcToZonedTime(subBusinessDays(today, -1), timeZone);
  };
  const [initialValues, setInitialValues] = useState<Values>({
    pickupdate: undefined,
  });

  useEffect(() => {
    const data: ExtendedVendorOrderDto | undefined = queryClient.getQueryData(
      orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
    );
    if (data?.pickupdate) {
      setInitialValues({
        pickupdate: data.pickupdate,
      });
    }
  }, []);

  const nameSchema = Yup.object().shape({
    pickupdate: Yup.date()
      .required(t('translation:form.required'))
      .transform(parseDateString)
      .min(
        getNextBusinessDay(),
        t('orders:detail.shipping.createShipment.pickupdate.minDate'),
      )
      .nullable()
      .default(undefined),
  });
  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    // the pickup date will be set by creating the shipment, so we have to fake a mutation for now
    queryClient.setQueryData(
      orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
      (old?: ExtendedVendorOrderDto) =>
        ({
          ...old,
          pickupdate: values.pickupdate,
        } as ExtendedVendorOrderDto),
    );

    formikHelpers.resetForm({ values });
    setInitialValues(values);
    setStep({ key: 'start', data: null });
  };

  return (
    <>
      <Headline
        headingLevel="h2"
        size={2}
        className="order-detail__box-headline"
      >
        {t('orders:detail.shipping.createShipment.pickupdate.title')}
      </Headline>
      <Spacer marginTop={5} tag="p" className="small c-dark-50">
        {t('orders:detail.shipping.createShipment.pickupdate.description')}
      </Spacer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form noValidate>
            <Spacer marginTop={5} className="shipping__date">
              <ErrorBoundary>
                <DatePickerField
                  showTimeSelect={false}
                  dateFormat={'P'}
                  name="pickupdate"
                  label={t(
                    'orders:detail.shipping.createShipment.pickupdate.label',
                  )}
                  placeholderText={t(
                    'orders:detail.shipping.createShipment.pickupdate.placeholder',
                  )}
                  minDate={getNextBusinessDay()}
                />
              </ErrorBoundary>
            </Spacer>

            <FlexBox justifyContent="space-between" marginTop={5}>
              <Button
                appearance="ghost"
                text={t('orders:detail.shipping.createShipment.address.abort')}
                onClick={() => setStep({ key: 'start', data: null })}
              />
              <Button
                text={t('orders:detail.shipping.createShipment.address.save')}
                type="submit"
              />
            </FlexBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateShipmentPickupdate;
