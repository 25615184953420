import { useInfiniteQuery } from '@tanstack/react-query';
import { OrderListFilterQueryType } from 'features/order-filter';
import {
  ApiError,
  PaginatedVendorOrderDto,
  VendorOrderService,
} from 'generated';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  getOrders = 'getOrders',
}

export const orderListKeys = {
  getOrders: (vendorAgentId?: number, filterQuery?: OrderListFilterQueryType) =>
    [KEYS.getOrders, vendorAgentId, filterQuery].filter((i) => i !== undefined),
};

export const useGetOrders = (filter?: OrderListFilterQueryType) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useInfiniteQuery<PaginatedVendorOrderDto, ApiError>({
    queryKey: orderListKeys.getOrders(vendorAgent?.currentVendor.id, filter),
    queryFn: async ({ pageParam = 0 }) =>
      VendorOrderService.getAll(
        pageParam, //offset
        undefined, //limit
        filter?.orderNo, //orderNo
        undefined, // productName
        filter?.customerName, //customerName
        filter?.orderNo ? [filter?.orderNo] : undefined, //orderNumbers
        filter?.productIds, //productIds
        filter?.createdAtFrom, //createdAtFrom
        filter?.createdAtTo, //createdAtTo
        filter?.orderStatus, //orderStatus
        'createdAt',
        'DESC',
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
