import { useParams } from 'react-router-dom';
import ShareContainer from '../ShareContainer/ShareContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

/** Route /videos/:id/share */
const ShareOutlet = () => {
  const { id } = useParams();

  const videoId = id ? parseInt(id) : 0;
  return (
    <ErrorBoundary>
      <ShareContainer videoId={videoId} />
    </ErrorBoundary>
  );
};

export default ShareOutlet;
