import FilterSideBar from 'features/filter/FilterSideBar/FilterSideBar';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreatedAtFilter from './components/CreatedAtFilter';
import OrderNoFilter from './components/OrderNoFilter';
import OrderStatusFilter from './components/OrderStatusFilter';
import ProductFilter from './components/ProductFilter';
import CustomerFilter from './components/CustomerFilter';

const OrderFilterSideBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation', 'orders']);

  const nav: {
    [key: string]: {
      caption: string;
      component: ReactNode;
    };
  } = {
    orderNo: {
      caption: t('orders:filter.orderNo.caption'),
      component: <OrderNoFilter />,
    },
    // inherits filter createdAtFrom and createdAtTo
    createdAt: {
      caption: t('orders:filter.createdAt.caption'),
      component: <CreatedAtFilter />,
    },
    productIds: {
      caption: t('orders:filter.product.caption'),
      component: <ProductFilter />,
    },
    orderStatus: {
      caption: t('orders:filter.orderStatus.caption'),
      component: <OrderStatusFilter />,
    },
    customerName: {
      caption: t('orders:filter.customerName.caption'),
      component: <CustomerFilter />,
    },
  };

  return <FilterSideBar nav={nav} onClose={() => navigate('/orders')} />;
};

export default OrderFilterSideBar;
