import SelectInteractive from './SelectInteractive';

export default SelectInteractive;
export { default as OpenSelect } from './components/OpenSelect';

// components
export { default as InfiniteMenuList } from './components/InfiniteMenuList';
export { default as Menu } from './components/Menu';
export { default as Option } from './components/Option';
export { default as ClearIndicator } from './components/ClearIndicator';
export { default as DropDownIndicator } from './components/DropDownIndicator';
export { default as OptionWithIcon } from './components/OptionWithIcon';
export { default as OptionWithNumberAndIcon } from './components/OptionWithNumberAndIcon';
export { default as ValueContainerIconLabelValue } from './components/ValueContainerIconLabelValue';
export { default as ValueContainerIconValue } from './components/ValueContainerIconValue';
export { default as Control } from './components/Control';

// styles
export { default as selectInteractiveStyles } from './styles';
export { primaryColors, getCustomStyles } from './styles';

// types
export type { DefaultOption } from './components/Option';
export type { OptionWithIconProps } from './components/OptionWithIcon';
export type { OptionWithNumberAndIconProps } from './components/OptionWithNumberAndIcon';
export type { Props } from './SelectInteractive';
export type { Props as ReactSelectProps } from 'react-select';
export type { CreatableProps } from 'react-select/creatable';

// hooks
export { default as useMultiSelectSort } from './hooks/useMultiSelectSort';
