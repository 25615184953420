import VisibilityToggle from 'components/VisibilityToggle/VisibilityToggle';
import { useGetVideo } from 'features/video-detail';
import { useTranslation } from 'react-i18next';
import { useUpdateVisibility } from '..';
import { VideoResponseDTO } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';

const VisibilityAsyncToggle = ({
  videoId,
  onToggledVisibility,
}: {
  videoId: number;
  /** This is deprecated and is only used for updating the old lists using useList hook */
  onToggledVisibility?: (updatedVideo: VideoResponseDTO) => void;
}) => {
  const { t } = useTranslation(['video']);
  const { setAppStatus } = useAppStatus();
  const mutation = useUpdateVisibility();
  const { data: video } = useGetVideo(videoId);

  const handleToggle = async (newVisibility: VideoResponseDTO.visibility) => {
    mutation.mutate(
      {
        videoId,
        visibility: newVisibility,
      },
      {
        onError: (error) => {
          setAppStatus(t('video:visibility.error'), 'error', true);
        },
        onSuccess: (updatedVideoIds, variables) => {
          if (video) {
            onToggledVisibility?.({
              ...video,
              id: videoId,
              visibility: variables.visibility,
            });
          }
        },
      },
    );
  };

  return (
    <VisibilityToggle
      onToggle={handleToggle}
      loading={mutation.isLoading}
      visibility={video?.visibility || VideoResponseDTO.visibility.PUBLIC}
    />
  );
};

export default VisibilityAsyncToggle;
