// This file is legacy and will be deleted when everything is migrated to react-query!

import { Reducer, useEffect, useReducer, useState } from 'react';
import { BREAKPOINTS } from '../global-constants';
import {
  ApiError,
  ChannelMinimalResponseDTO,
  LinksDTO,
  PaginatedVideoResponseDTO,
  ProductResponseDTO,
  SharingUrlDTO,
  VideoResponseDTO,
} from '../generated';
import { useMediaQuery } from '../hooks/useMediaquery';
import { useReduxDispatch } from '../redux/hooks';
import { logOut } from '../redux/slices/auth/authSlice';
import Error from '../types/Error';
import notAuthenticated from '../utils/not-authenticated';

export interface Props<ItemType extends Item> {
  reducer: (
    state: State<ItemType>,
    action: Action<ItemType>,
  ) => State<ItemType>;
  initial?: State<ItemType>;
  options?: {
    disableSetActiveItem?: boolean;
  };
}

export type Paginated<T extends Item> = {
  data: Array<T>;
  total: number;
  links: LinksDTO;
};

export interface State<T extends Item> {
  items?: T[];
  activeItem?: T;
  loading: boolean;
  error?: Error;
  totalCount?: number;
  loadMore: boolean;
  initialyLoaded?: boolean;
  activeRange?: { limit: number; offset: number };
}

export type Item = {
  id: number;
  isAvailable?: boolean;
  videos?: VideoResponseDTO[];
  paginatedVideoDTO?: PaginatedVideoResponseDTO;
  sharingUrls?: SharingUrlDTO[];
  channels?: ChannelMinimalResponseDTO[];
};

export type Service<Res> = () => Promise<Res>;

export type FunctionPropsTyped<T> = {
  onSuccess?: (res: T) => void;
  onError?: (error: Error) => void;
  initial?: boolean;
};

export type Action<T> =
  | { type: ActionType; payload?: any }
  | {
      type: ActionType.UPDATE;
      payload: { id: number; item: T };
    }
  | {
      type: ActionType.UPDATE_MULTI;
      payload: { items: T[] };
    }
  | {
      type: ActionType.ADD;
      payload: { item: T };
    }
  | {
      type: ActionType.DELETE;
      payload: { id: number };
    }
  | {
      type: ActionType.DELETE_MULTI;
      payload: { items: number[] };
    }
  | {
      type: ActionType.GET_SUCCESS;
      payload: {
        items: T[];
        totalCount: number;
        next: boolean;
        initial?: boolean;
      };
    }
  | {
      type: ActionType.GET_ERROR;
      payload: { error: Error };
    }
  | {
      type: ActionType.LOADING;
    }
  | {
      type: ActionType.DEACTIVATE;
      payload: { id: number; isAvailable: boolean };
    };

export enum ActionType {
  GET_SUCCESS = 'GET_SUCCESS',
  GET_ERROR = 'GET_ERROR',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  UPDATE_MULTI = 'UPDATE_MULTI',
  DELETE = 'DELETE',
  DELETE_MULTI = 'DELETE_MULTI',
  LOADING = 'LOADING',
  DEACTIVATE = 'DEACTIVATE',
  RELOAD = 'RELOAD',
}

const useList = <ItemType extends Item>({
  reducer,
  initial,
  options,
}: Props<ItemType>) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [activeItem, setActiveItem] = useState<ItemType>();
  const [activateToggleLoading, setActivateToggleLoading] =
    useState<boolean>(false);
  const [
    {
      items,
      totalCount,
      loading,
      error,
      loadMore,
      initialyLoaded,
      activeRange,
    },
    dispatch,
  ] = useReducer<Reducer<State<ItemType>, Action<ItemType>>>(
    reducer,
    initial || {
      loading: false,
      loadMore: true,
    },
  );
  const reduxDispatch = useReduxDispatch();

  const handleError = (error: Error) => {
    if (error && notAuthenticated(error)) {
      reduxDispatch(logOut());
    } else {
      dispatch({
        type: ActionType.GET_ERROR,
        payload: { error },
      });
    }
  };

  const getItemsByServicePaginated = async ({
    service,
    props,
  }: {
    service: Service<Paginated<ItemType>>;
    props?: FunctionPropsTyped<Paginated<ItemType>>;
  }) => {
    dispatch({ type: ActionType.LOADING });

    try {
      const res = await service();

      dispatch({
        type: ActionType.GET_SUCCESS,
        payload: {
          items: res.data,
          totalCount: res.total,
          next: res.links.next,
          initial: props?.initial,
          activeRange: res.links.current,
        },
      });

      if (isDesktop && props?.initial && !options?.disableSetActiveItem) {
        setActiveItem(res.data[0]);
      }

      props && props.onSuccess && props.onSuccess(res);
    } catch (error) {
      handleError(error as ApiError);
      props && props.onError && props.onError(error as ApiError);
    }
  };

  const getItemsByService = async ({
    service,
    props,
  }: {
    service: Service<ItemType[]>;
    props?: FunctionPropsTyped<ItemType[]>;
  }) => {
    dispatch({ type: ActionType.LOADING });

    try {
      const res = await service();

      dispatch({
        type: ActionType.GET_SUCCESS,
        payload: {
          items: res,
          totalCount: res.length,
          initial: props?.initial,
        },
      });

      if (isDesktop && props?.initial && !options?.disableSetActiveItem) {
        setActiveItem(res[0]);
      }

      props && props.onSuccess && props.onSuccess(res);
    } catch (error) {
      handleError(error as ApiError);
      props && props.onError && props.onError(error as ApiError);
    }
  };

  const deleteItemByService = async <Res extends Item>({
    service,
    props,
  }: {
    service: Service<Res>;
    props?: FunctionPropsTyped<Res>;
  }) => {
    dispatch({ type: ActionType.LOADING });
    const deleteFunc = async () => {
      try {
        const res = await service();

        if (isDesktop) {
          const index = items?.findIndex((item) => item.id === res.id);
          const active = index === 0 ? items?.[1] : items?.[(index || 1) - 1];

          setActiveItem(active);
        } else {
          setActiveItem(undefined);
        }

        dispatch({
          type: ActionType.DELETE,
          payload: { id: res?.id || items?.[0]?.id },
        });
        props && props.onSuccess && props.onSuccess(res);
      } catch (error) {
        handleError(error as ApiError);
        props && props.onError && props.onError(error as ApiError);
      }
    };
    deleteFunc();
  };

  const deleteItemsByService = async <Res,>({
    service,
    props,
    itemsToDelete,
  }: {
    service: Service<Res>;
    itemsToDelete: Array<any>;
    props?: FunctionPropsTyped<Res>;
  }) => {
    dispatch({ type: ActionType.LOADING });
    const func = async () => {
      try {
        const res = await service();

        if (res) {
          if (isDesktop && items) {
            setActiveItem(items[0]);
          } else {
            setActiveItem(undefined);
          }

          dispatch({
            type: ActionType.DELETE_MULTI,
            payload: { items: itemsToDelete },
          });
        }
        props && props.onSuccess && props.onSuccess(res);
      } catch (error) {
        handleError(error as ApiError);
        props && props.onError && props.onError(error as ApiError);
      }
    };
    func();
  };

  const toggleActivateItemByService = async <Res extends Item>({
    service,
    props,
  }: {
    service: Service<Res>;
    props?: FunctionPropsTyped<Res>;
  }) => {
    setActivateToggleLoading(true);
    const setAvailability = async () => {
      try {
        const res = await service();

        if (res && items) {
          dispatch({
            type: ActionType.DEACTIVATE,
            payload: { id: res.id, isAvailable: res.isAvailable },
          });

          setActiveItem((prevState) => {
            if (!prevState) {
              return;
            }
            return {
              ...prevState,
              ...res,
            };
          });
          setActivateToggleLoading(false);
        }
        props && props.onSuccess && props.onSuccess(res);
      } catch (error) {
        handleError(error as ApiError);
        props && props.onError && props.onError(error as ApiError);
      }
    };
    setAvailability();
  };

  // TODO also possible with new updateItem<T> with dependency injection ??
  const updateVideoItem = ({
    res,
    newChannelList,
    newSharingUrls,
  }: {
    res?: VideoResponseDTO;
    // insert whole new Channel-Array
    newChannelList?: ChannelMinimalResponseDTO[];
    // insert whole new SharingUrl-Array
    newSharingUrls?: SharingUrlDTO[];
  }) => {
    dispatch({
      type: ActionType.UPDATE,
      payload: {
        id: activeItem?.id,
        item: {
          ...activeItem,
          ...res,
          channels: newChannelList ? newChannelList : activeItem?.channels,
          sharingUrls: newSharingUrls
            ? newSharingUrls
            : activeItem?.sharingUrls,
        },
      },
    });

    setActiveItem((prevState) => {
      if (!prevState) {
        return;
      }
      return {
        ...prevState,
        ...activeItem,
        ...res,
        channels: newChannelList ? newChannelList : activeItem?.channels,
        sharingUrls: newSharingUrls ? newSharingUrls : activeItem?.sharingUrls,
      };
    });
  };

  const updateVideoItems = (
    videos: Array<{
      res?: VideoResponseDTO;
      // insert whole new Channel-Array
      newChannelList?: ChannelMinimalResponseDTO[];
      // insert whole new SharingUrl-Array
      newSharingUrls?: SharingUrlDTO[];
    }>,
  ) => {
    dispatch({
      type: ActionType.UPDATE_MULTI,
      payload: {
        items: videos.map((v) => ({
          ...v.res,
          channels: v.newChannelList ? v.newChannelList : v.res?.channels,
          sharingUrls: v.newSharingUrls ? v.newSharingUrls : v.res?.sharingUrls,
        })),
      },
    });

    const newActiveItem = videos.find((v) => v.res?.id === activeItem?.id);
    if (newActiveItem) {
      setActiveItem((prevState) => {
        if (!prevState) return;
        return {
          ...prevState,
          ...activeItem,
          ...newActiveItem.res,
          channels: newActiveItem.newChannelList
            ? newActiveItem.newChannelList
            : activeItem?.channels,
          sharingUrls: newActiveItem.newSharingUrls
            ? newActiveItem.newSharingUrls
            : activeItem?.sharingUrls,
        };
      });
    }
  };

  const updateActiveItem = <T,>(res: T) => {
    dispatch({
      type: ActionType.UPDATE,
      payload: {
        id: activeItem?.id,
        item: { ...activeItem, ...res },
      },
    });

    setActiveItem((prevState) => {
      if (!prevState) {
        return;
      }
      return {
        ...prevState,
        ...activeItem,
        ...res,
      };
    });
  };

  const updateMediathekItem = <T,>(id: number, res: T) => {
    dispatch({
      type: ActionType.UPDATE,
      payload: {
        id: id,
        item: res,
      },
    });
  };

  const updateActiveItemByService = async <T,>({
    service,
    props,
  }: {
    service: Service<T>;
    props?: FunctionPropsTyped<T>;
  }) => {
    dispatch({ type: ActionType.LOADING });

    try {
      const res = await service();
      updateActiveItem<T>(res);
      props && props.onSuccess && props.onSuccess(res);
    } catch (error) {
      handleError(error as ApiError);
      props && props.onError && props.onError(error as ApiError);
    }
  };

  const reload = () => {
    dispatch({ type: ActionType.RELOAD });
  };

  const addItemByService = async <T,>({
    service,
    props,
  }: {
    service: Service<T>;
    props?: FunctionPropsTyped<T>;
  }) => {
    dispatch({ type: ActionType.LOADING });

    try {
      const res = await service();

      dispatch({
        type: ActionType.ADD,
        payload: { item: res },
      });
      setActiveItem((prevState) => {
        if (!prevState) {
          return;
        }
        return {
          ...prevState,
          ...res,
        };
      });
      props && props.onSuccess && props.onSuccess(res);
    } catch (error) {
      handleError(error as ApiError);
      props && props.onError && props.onError(error as ApiError);
    }
  };

  const updateProducts = (updatedItems: ProductResponseDTO[]) => {
    dispatch({
      type: ActionType.UPDATE_MULTI,
      payload: {
        items: items
          ?.filter((i) => updatedItems.map((i) => i.id).includes(i.id))
          .map((item, i) => ({ ...item, ...updatedItems[i] })),
      },
    });

    const find = updatedItems.find((i) => i.id === activeItem?.id);

    if (find) {
      setActiveItem((prevState) => {
        if (!prevState) {
          return;
        }
        return {
          ...prevState,
          ...find,
        };
      });
    }
  };

  useEffect(() => {
    if (isDesktop && !activeItem && !options?.disableSetActiveItem) {
      setActiveItem(items && items[0]);
    }
  }, [isDesktop, items]);

  return {
    listState: {
      items,
      totalCount,
      loading,
      error,
      loadMore,
      initialyLoaded,
      activeRange,
    },
    activeItem,
    activateToggleLoading,
    listFunctions: {
      setActiveItem,
      updateActiveItem: updateActiveItemByService,
      updateVideoItem,
      updateVideoItems,
      updateMediathekItem,
      updateProducts,
      get: getItemsByServicePaginated,
      getArray: getItemsByService,
      deleteItem: deleteItemByService,
      deleteItems: deleteItemsByService,
      deactivate: toggleActivateItemByService,
      reload,
      add: addItemByService,
      setError: handleError,
    },
  };
};

export default useList;
