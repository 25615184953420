import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetNotificationsQueryDTO } from '../../../generated';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import {
  fetchMoreNotifications,
  fetchNotifications,
  selectNotificationIds,
  setFilterStatus,
} from '../../../redux/slices/notifications/notificationSlice';
import NotificationList from './NotificationList';

// TODO use react-query instead of redux
const NotificationListContainer = () => {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(['translation', 'settings']);

  const {
    status,
    hasMore,
    total,
    itemsVisible,
    filter: { status: statusFilterValue },
  } = useReduxSelector((state) => state.notifications);

  const notificationIds = useReduxSelector(selectNotificationIds);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [statusFilterValue, dispatch]);

  useEffect(() => {
    if (notificationIds.length === 0 && hasMore) {
      dispatch(fetchNotifications());
    }
  }, [notificationIds, hasMore, dispatch]);

  const handleLoadMoreClick = () => {
    dispatch(fetchMoreNotifications());
  };

  const handleStatusFilterChange = (value: GetNotificationsQueryDTO.status) => {
    dispatch(setFilterStatus(value));
  };

  return (
    <NotificationList
      status={status}
      entities={notificationIds}
      statusFilterValue={statusFilterValue}
      onStatusFilterChange={handleStatusFilterChange}
      total={total}
      itemsVisible={itemsVisible}
      hasMore={hasMore}
      onLoadMoreClick={handleLoadMoreClick}
      t={t}
    />
  );
};

export default NotificationListContainer;
