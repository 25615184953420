import { useRef, useState } from 'react';
import { ChannelMinimalResponseDTO } from '../../generated';

const useChannelController = (initial?: Array<ChannelMinimalResponseDTO>) => {
  const [channels, setChannels] = useState<Array<ChannelMinimalResponseDTO>>(
    initial || [],
  );
  const initialSave = useRef(initial);

  const removeChannel = (id: number) => {
    setChannels((prevState) => prevState.filter((c) => c.id !== id));
  };

  const addChannel = (channel: ChannelMinimalResponseDTO) => {
    setChannels((prevState) => prevState.concat(channel));
  };

  const revertToInitialChannels = () => {
    setChannels(initialSave.current || []);
  };

  return {
    channels,
    removeChannel,
    addChannel,
    revertToInitialChannels,
  };
};

export default useChannelController;
