import Collapse from 'components/Collapse/Collapse';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout';
import Packaging from 'components/Packaging/Packaging';
import ProductTaxes from 'components/ProductTaxes/ProductTaxes';
import { utcToZonedTime } from 'date-fns-tz';
import { useGetOrder } from 'features/order-detail';
import { useGetPackageDimensions } from 'features/order-shipment';
import { useGetProductTaxes } from 'features/product-taxes';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';

/** Loads additional information on shipping. This is an additional component to load the extra infos only when really needed. */
const ShippingDefaultAdditionalInfo = ({
  productId,
  collapsed,
  setCollapsed,
  requestedPickupDate,
}: {
  productId: number;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  requestedPickupDate?: string | null;
}) => {
  // This is just a workaround for packaging details for now
  const { data: packagingData } = useGetPackageDimensions(productId);
  const { data: taxesData } = useGetProductTaxes(productId);
  const { t } = useTranslation(['translation', 'orders']);
  const timeZone = useReduxSelector(selectTimezone);

  return (
    <>
      {!collapsed ? (
        <>
          <Spacer marginTop={8}>
            <LabelText
              label={t('orders:detail.shipping.createShipment.packaging.title')}
            >
              <Packaging {...packagingData} />
            </LabelText>
          </Spacer>
          <Spacer marginTop={8}>
            <LabelText
              label={t('orders:detail.shipping.createShipment.taxes.title')}
            >
              <ProductTaxes {...taxesData} />
            </LabelText>
          </Spacer>
          {requestedPickupDate && (
            <Spacer marginTop={8}>
              <LabelText
                label={t(
                  'orders:detail.shipping.createShipment.pickupdate.requested',
                )}
              >
                {t('dateP', {
                  date: utcToZonedTime(requestedPickupDate, timeZone),
                })}
              </LabelText>
            </Spacer>
          )}
        </>
      ) : (
        <></>
      )}

      <Collapse
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
    </>
  );
};

export default ShippingDefaultAdditionalInfo;
