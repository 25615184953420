import Icon, { IconType } from 'components/Icon/Icon';
import icons from 'components/Icon/icon-spec';
import Navi from 'components/Navi/Navi';
import useRoutesObject from 'hooks/useRoutesObject';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export interface Props {
  parentRoute: string;
  onClose?: () => void;
  childCenterSlot?: ReactElement;
  childRightSlot?: ReactElement;
  parentCenterSlot?: ReactElement;
  parentRightSlot?: ReactElement;
}

const ChildNavigator = ({
  parentRoute,
  onClose,
  childCenterSlot,
  childRightSlot,
  parentCenterSlot,
  parentRightSlot,
}: Props) => {
  const childRouteIsActive = useMatch(`${parentRoute}/:path`);
  const navigate = useNavigate();
  const location = useLocation();
  const routes = useRoutesObject();
  const [removeFirst, ...breadcrumbs] = useBreadcrumbs(routes);
  const [current, setCurrent] = useState(
    breadcrumbs.find((i) => i.key === location.pathname),
  );

  useEffect(() => {
    setCurrent(breadcrumbs.find((i) => i.key === location.pathname));
  }, [location]);

  const getCenterSlot = () => {
    return childCenterSlot ? (
      childCenterSlot
    ) : (
      <h2 className="caption caption--primary child-navigator__caption">
        {current?.breadcrumb}
      </h2>
    );
  };

  const getRightSlot = () => {
    if (childRightSlot) {
      return childRightSlot;
    }
    return current?.match.route?.path &&
      icons.hasOwnProperty(current?.match.route?.path) ? (
      <Icon
        icon={current?.match.route?.path as IconType}
        className="child-navigator__icon"
      />
    ) : undefined;
  };

  return (
    <Navi
      childRouteIsActive={childRouteIsActive !== null}
      forwardKey={location.pathname}
      onBackClick={() => navigate(parentRoute)}
      onClose={onClose}
      childRightSlot={getRightSlot()}
      childCenterSlot={getCenterSlot()}
      parentRightSlot={parentRightSlot}
      parentCenterSlot={parentCenterSlot}
    />
  );
};

export default ChildNavigator;
