import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { SideBarContextType } from 'hooks/useSidebar';
import { ElementType, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './sidebar.scss';
import Modal from 'components/Modal/Modal';

export interface CompProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  closeIconIsVisible?: boolean;
  onAfterClose?: () => void;
  onBeforeClose?: () => void;
  onAfterOpen?: () => void;
  appearance?: 'grid' | 'overlay' | 'narrow';
}

export type Props = CompProps & SideBarContextType;

const Sidebar = ({
  sidebarIsOpen = true,
  children,
  className,
  closeIconIsVisible,
  closeSidebar,
  openSidebar,
  onAfterClose,
  onAfterOpen,
  onBeforeClose,
  appearance = 'grid',
  ...props
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const visible =
    closeIconIsVisible !== undefined ? closeIconIsVisible : !isDesktop;
  const targetRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (appearance === 'grid' || appearance === 'narrow') {
      if (!isDesktop && sidebarIsOpen && targetRef.current) {
        disableBodyScroll(targetRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
    }

    if (sidebarIsOpen) {
      onAfterOpen?.();
    }
  }, [sidebarIsOpen, isDesktop, appearance]);

  const handleClose = () => {
    onBeforeClose?.();
    closeSidebar();
    onAfterClose?.();
  };

  useEffect(() => {
    targetRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location.pathname]);

  const showOverlay =
    (appearance === 'overlay' && sidebarIsOpen) ||
    ((appearance === 'grid' || appearance === 'narrow') &&
      !isDesktop &&
      sidebarIsOpen)
      ? true
      : false;

  if (appearance === 'overlay' || appearance === 'narrow') {
    return (
      <Modal
        overlayClassName={classNames(
          'sidebar__overlay',
          appearance === 'narrow' && 'sidebar__overlay--removed',
        )}
        scrollTargetId="sidebar--scroll-target"
        ref={targetRef}
        isOpen={sidebarIsOpen}
        onClose={handleClose}
        className={classNames(
          'sidebar',
          appearance && `sidebar--${appearance}`,
          className,
        )}
        variant="hidden-sidebar"
        hideHeader
        bodyOpenClassName={
          appearance === 'narrow'
            ? 'ReactModal__Body--open modal-body--sidebar-narrow'
            : 'ReactModal__Body--open'
        }
      >
        <>
          {visible && sidebarIsOpen && (
            <div className="sidebar__close">
              <button
                className="sidebar__close-button"
                type="button"
                onClick={handleClose}
              >
                <Icon icon="close" />
              </button>
            </div>
          )}
          {children}
        </>
      </Modal>
    );
  }

  return (
    <>
      {showOverlay && <div className="modal__overlay sidebar__overlay"></div>}

      <div
        id="sidebar--scroll-target"
        {...props}
        className={classNames(
          'sidebar',
          sidebarIsOpen && 'sidebar--open',
          visible && 'sidebar--show-close',
          appearance && `sidebar--${appearance}`,
          className,
        )}
        ref={targetRef}
      >
        <div className="sidebar__content">
          {visible && sidebarIsOpen && (
            <div className="sidebar__close">
              <button
                className="sidebar__close-button"
                type="button"
                onClick={handleClose}
              >
                <Icon icon="close" />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
