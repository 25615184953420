import classNames from 'classnames';
import { forwardRef } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import Icon from '../Icon/Icon';
import './modal.scss';

export type ModalVariant =
  | 'wide'
  | 'extra-wide'
  | 'full'
  | 'bottom-sheet'
  | 'hidden-sidebar';

export type Props = {
  isOpen: boolean;
  headline?: string;
  className?: string;
  overlayClassName?: string;
  onClose?: () => void;
  children: React.ReactNode;
  hasHeaderBorder?: boolean;
  variant?: ModalVariant;
  scrollTargetId?: string;
  appearance?: 'light' | 'dark';
  disableScroll?: boolean;
  hideHeader?: boolean;
  hideScrollBars?: boolean;
} & ReactModalProps;

/** Renders a react-modal component with custom styling. If you just need a simple Modal with a Text and two buttons, you should consider using the ConfirmModal component. */
const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      isOpen,
      className,
      overlayClassName,
      onClose,
      children,
      headline,
      hasHeaderBorder,
      variant,
      scrollTargetId,
      appearance,
      disableScroll,
      hideHeader,
      hideScrollBars = true,
      ...rest
    },
    scrollTargetRef,
  ) => {
    return (
      <ReactModal
        isOpen={isOpen}
        className={classNames(
          'modal',
          variant && `modal--${variant}`,
          appearance && `modal--${appearance}`,
          disableScroll && 'modal--disable-scroll',
          className,
        )}
        overlayClassName={classNames('modal__overlay', overlayClassName)}
        onRequestClose={onClose}
        contentLabel={headline}
        {...rest}
      >
        {(headline || onClose) && !hideHeader && (
          <header
            className={classNames({
              modal__header: true,
              'modal__header--border': hasHeaderBorder,
            })}
          >
            {headline && <h1 className="modal__caption">{headline}</h1>}

            {onClose && (
              <button className="modal__close" type="button" onClick={onClose}>
                <Icon icon="close" />
              </button>
            )}
          </header>
        )}

        <div
          className={classNames(
            'modal__content',
            hideScrollBars && 'modal__content--hide-scrollbars',
          )}
          id={scrollTargetId}
          ref={scrollTargetRef}
        >
          {children}
        </div>
      </ReactModal>
    );
  },
);

export default Modal;
