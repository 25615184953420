import classNames from 'classnames';
import { FlexBox } from 'components/Layout/Layout';
import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Avatar from '../Avatar/Avatar';
import './link-list-item.scss';
import LinkListItemControls from './LinkListItemControls';
import LinkListItemError from './LinkListItemError';
import LinkListItemTitle from './LinkListItemTitle';

export interface Props {
  onEditStart?: () => void;
  onEditSave: (title: string) => void;
  onEditCancel?: () => void;
  onDelete: () => void;
  title: string;
  userName?: string;
  children: React.ReactNode;
  active?: boolean;
  error?: string;
  deleteText?: string;
}

const LinkListItem = ({
  onEditCancel,
  onEditSave,
  onEditStart,
  onDelete,
  title,
  userName,
  children,
  active,
  error,
  deleteText,
}: Props) => {
  const { t } = useTranslation();
  const initalValues = {
    title,
  };
  const [edit, setEdit] = useState(false);
  const [reallyDelete, setReallyDelete] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [showInteraction, setShowInteraction] = useState(isDesktop);

  const handleSubmit = (
    { title }: { title: string },
    { resetForm }: FormikHelpers<{ title: string }>,
  ) => {
    onEditSave(title);
    setEdit(false);
    resetForm();
  };

  const handleDelete = () => {
    setReallyDelete(false);
    onDelete && onDelete();
  };

  const handleEdit = () => {
    setEdit(true);
    onEditStart && onEditStart();
  };

  const handleEditCancel = (
    resetForm: (nextState?: Partial<FormikState<{ title: string }>>) => void,
  ) => {
    setEdit(false);
    resetForm();
    onEditCancel && onEditCancel();
  };

  const controlProps = {
    active,
    edit,
    reallyDelete,
    setReallyDelete,
    handleEdit,
    handleDelete,
  };

  const renderDesktop = (formik: FormikProps<{ title: string }>) => (
    <FlexBox
      tag={edit ? 'form' : 'div'}
      className="link-list-item__inner"
      justifyContent="space-between"
    >
      <div className="link-list-item__content">
        <LinkListItemTitle
          active={active}
          edit={edit}
          title={title}
          showInteraction={showInteraction}
          setShowInteraction={setShowInteraction}
          setReallyDelete={setReallyDelete}
        />
        <FlexBox className="link-list-item__content-bottom" alignItems="center">
          {userName && isDesktop && <Avatar name={userName} />}

          {children}
        </FlexBox>
      </div>

      <LinkListItemControls
        {...controlProps}
        handleEditSave={() => formik.submitForm()}
        handleEditCancel={() => handleEditCancel(formik.resetForm)}
      />

      <LinkListItemError
        reallyDelete={reallyDelete}
        deleteText={deleteText}
        error={error}
      />
    </FlexBox>
  );

  const renderMobile = (formik: FormikProps<{ title: string }>) => (
    <>
      <FlexBox
        tag={edit ? 'form' : 'div'}
        className="link-list-item__inner"
        justifyContent="space-between"
        flexDirection="column"
      >
        <LinkListItemTitle
          active={active}
          edit={edit}
          title={title}
          showInteraction={showInteraction}
          setShowInteraction={setShowInteraction}
          setReallyDelete={setReallyDelete}
        />
        <FlexBox justifyContent="space-between" alignItems="flex-end">
          <FlexBox alignItems="flex-end" className="link-list-item__link-code">
            {children}
          </FlexBox>
          {showInteraction && (
            <LinkListItemControls
              {...controlProps}
              handleEditSave={() => formik.submitForm()}
              handleEditCancel={() => handleEditCancel(formik.resetForm)}
            />
          )}
        </FlexBox>
      </FlexBox>
      <LinkListItemError
        reallyDelete={reallyDelete}
        deleteText={deleteText}
        error={error}
      />
    </>
  );

  return (
    <Formik
      initialValues={initalValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('form.required')),
      })}
    >
      {(formik: FormikProps<{ title: string }>) => (
        <li
          className={classNames(
            'link-list-item',
            active && 'link-list-item--active',
            edit && 'link-list-item--edit',
            showInteraction && 'link-list-item--interaction',
            (reallyDelete || error) && 'link-list-item--delete',
          )}
        >
          {isDesktop ? renderDesktop(formik) : renderMobile(formik)}
        </li>
      )}
    </Formik>
  );
};

export default LinkListItem;
