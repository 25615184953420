import { InfiniteData, useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiError,
  CreateSharingUrlDTO,
  PaginatedVideoResponseDTO,
  SharingService,
  SharingUrlDTO,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import QUERY_KEYS from 'types/QueryKeys';

export const useGetSharingLinks = (videoId: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useQuery<SharingUrlDTO[], ApiError>({
    queryKey: [
      QUERY_KEYS.getSharingLinks,
      vendorAgent?.currentVendor.id,
      videoId,
    ],
    queryFn: () => SharingService.getSharingLinks(videoId),
    initialData: () => {
      const video = queryClient
        .getQueryData<InfiniteData<PaginatedVideoResponseDTO>>([
          QUERY_KEYS.getVideos,
          vendorAgent?.currentVendor.id,
        ])
        ?.pages.map((d) => d.data)
        .flat()
        .find((i) => i.id === videoId);
      if (video?.sharingUrls) {
        return video.sharingUrls;
      }
    },
  });
};

export const useUpdateSharingToken = (videoId: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'video']);

  return useMutation<
    SharingUrlDTO,
    ApiError,
    { sharingTokenId: number; name: string }
  >({
    mutationFn: ({ sharingTokenId, name }) =>
      SharingService.updateSharingToken(sharingTokenId, { name }),
    onError: (error) => {
      setAppStatus(t('video:share.error'), 'error');
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.getSharingLinks, vendorAgent?.currentVendor.id, videoId],
        (old?: SharingUrlDTO[]) =>
          old?.map((i) => (i.id === data.id ? data : i)),
      );
    },
  });
};

export const useDeleteSharingToken = (videoId: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'video']);

  return useMutation<SharingUrlDTO, ApiError, { sharingTokenId: number }>({
    mutationFn: ({ sharingTokenId }) =>
      SharingService.softRemoveSharingToken(sharingTokenId),
    onError: (error) => {
      setAppStatus(t('video:share.error'), 'error');
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.getSharingLinks, vendorAgent?.currentVendor.id, videoId],
        (old?: SharingUrlDTO[]) => old?.filter((i) => i.id !== data.id),
      );
    },
  });
};

export const useAddSharingToken = (videoId: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'video']);

  return useMutation<
    SharingUrlDTO,
    ApiError,
    { title: string; channel: string }
  >({
    mutationFn: ({ title, channel }) =>
      SharingService.createSharingUrl({
        name: title,
        channelId: parseInt(channel),
        videoId,
        sharingRefId: vendorAgent?.id,
        sharingType: CreateSharingUrlDTO.sharingType.VENDOR_AGENT,
      }),
    onError: (error) => {
      setAppStatus(t('video:share.error'), 'error');
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.getSharingLinks, vendorAgent?.currentVendor.id, videoId],
        (old?: SharingUrlDTO[]) => old?.concat(data),
      );
      setAppStatus(t('video:share.success'));
    },
  });
};
