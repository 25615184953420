import ActionPanel from 'components/ActionPanel/ActionPanel';
import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import Grid from 'components/Grid/Grid';
import GridItem from 'components/Grid/GridItem';
import Headline from 'components/Headline/Headline';
import HowTo from 'components/HowTo/HowTo';
import IconButton from 'components/IconButton/IconButton';
import IconTabBar, { IconTab } from 'components/IconTabBar/IconTabBar';
import Search from 'components/Search/Search';
import { ChannelsService, SharingUrlDTO, VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import useList from 'hooks/useList';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import howToImage from 'images/video-empty.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import videoListReducer from 'reducer/reducer-video-list';
import { useReduxSelector } from 'redux/hooks';
import Color from 'types/Color';
import useDebouncedEffect from 'use-debounced-effect';
import MediathekContainer from '../MediathekContainer/MediathekContainer';
import { ChannelDetailTab } from './ChannelVideoContainer';
import ChannelVideoInfoShare from './ChannelVideoInfoShare';
import ChannelVideoList from './ChannelVideoList';

export interface Props {
  channelId?: number;
  tabs: IconTab<ChannelDetailTab>[];
  activeTab: ChannelDetailTab;
  onTabChange: (tab: ChannelDetailTab) => void;
}

const ChannelProductVideoList = ({
  channelId,
  tabs,
  activeTab,
  onTabChange,
}: Props) => {
  const { t } = useTranslation(['video', 'channel']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const isXL = useMediaQuery(`(min-width: ${BREAKPOINTS.xl})`);
  const { isOpen, open, close } = useOpen(isDesktop);
  const [mediathekOpen, setMediathekOpen] = useState<boolean>(false);
  const [deleteCount, setDeleteCount] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const searchLocale = useReduxSelector((state) => state.i18n.localeIso);

  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
  });
  const { get, updateVideoItem, deleteItems, setActiveItem } = listFunctions;

  useEffect(() => {
    if (channelId) {
      get({
        service: () => ChannelsService.getChannelsVideos(channelId),
        props: {
          initial: true,
          onSuccess: (res) => {
            if (isDesktop && res.data[0]) {
              setActiveItem(res.data[0]);
            }

            if (res.data.length === 0) {
              setShowHowTo(true);
            }
          },
        },
      });
    }
  }, []);

  useDebouncedEffect(
    () => {
      if (!channelId) {
        return;
      }

      try {
        listFunctions.get({
          props: {
            initial: true,
          },
          service: () =>
            ChannelsService.getChannelsVideos(
              channelId,
              undefined, // offset
              undefined, // limit
              searchTerm,
              searchLocale,
            ),
        });
      } catch (error) {
        console.log(error);
      }
    },
    400,
    [searchTerm],
  );

  useEffect(() => {
    if (!activeItem && listState.items && isDesktop) {
      setActiveItem(listState.items[0]);
    }
    setShowHowTo(listState.items?.length === 0);
  }, [listState.items]);

  const handleLoadMore = () => {
    if (channelId) {
      get({
        service: () =>
          ChannelsService.getChannelsVideos(
            channelId,
            listState.items?.length, // offset
            undefined, // limit
            searchTerm,
            searchLocale,
          ),
      });
    }
  };

  const handleClose = () => {
    close();
    setTimeout(() => {
      setActiveItem(undefined);
    }, 600);
  };

  const handleOpenDetails = (item: VideoResponseDTO) => {
    setActiveItem(item);
    open();
  };

  const handleSelectionChange = (selectedIds: number[]) => {
    setSelectedIds(selectedIds);
    setDeleteCount(selectedIds.length);
  };

  const handleRemove = () => {
    if (channelId && deleteCount > 0) {
      deleteItems({
        service: () =>
          ChannelsService.removeVideos(channelId, {
            videoIds: selectedIds,
          }),
        itemsToDelete: selectedIds,
        props: {
          onSuccess: (res) => {
            setDeleteCount(0);
          },
        },
      });
    }
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleMediathekClose = () => {
    setMediathekOpen(false);

    if (channelId) {
      get({
        service: () =>
          ChannelsService.getChannelsVideos(
            channelId,
            undefined, // offset
            undefined, // limit
            searchTerm,
            searchLocale,
          ),
        props: { initial: true },
      });
    }
  };

  const handleUpdateShareUrls = (newSharingUrls: SharingUrlDTO[]) => {
    // TODO also possible with new updateItem<T> with dependency injection ??

    updateVideoItem({ newSharingUrls });
  };

  const handleUpdateVisibility = (updatedVideo: VideoResponseDTO) => {
    // TODO also possible with new updateItem<T> with dependency injection ??

    updateVideoItem({ res: updatedVideo });
  };

  const mediaThekProps = {
    open: mediathekOpen,
    onClose: handleMediathekClose,
    channelId,
  };

  return (
    <Grid>
      <GridItem templateArea="header">
        <IconTabBar tabs={tabs} activeTab={activeTab} onChange={onTabChange} />
        <Headline headingLevel="h1" size={2} className="page__title">
          {t('channel:video')}
        </Headline>
      </GridItem>

      {showHowTo && (
        <GridItem column={isXL ? '1/3' : '1/4'}>
          <HowTo
            button={{
              onClick: () => {
                setMediathekOpen(true);
              },
              text: t('video:add'),
            }}
            text={t('channel:video_empty')}
            image={howToImage}
          />
          <MediathekContainer {...mediaThekProps} />
        </GridItem>
      )}

      {!showHowTo && (
        <>
          <GridItem templateArea="main">
            <div className="page__controls page__controls--list">
              <Search
                searchTerm={searchTerm}
                onChange={handleSearchTermChange}
                placeholder={t('video:searchPlaceholder')}
              />
              <Button
                text={t('video:add')}
                icon="add"
                onClick={() => setMediathekOpen(true)}
              />
              <MediathekContainer {...mediaThekProps} />
            </div>

            <div className="page__controls-list page__list">
              <IconButton
                tooltip={
                  deleteCount === 0
                    ? t('video:remove_choose')
                    : t('video:remove', { count: deleteCount })
                }
                icon="delete"
                wrapperClassName="page__controls-delete"
                appearance={deleteCount === 0 ? 'ghost' : 'filled'}
                tooltipPlace="up"
                tooltipDelay={0}
                big={true}
                onClick={handleRemove}
                disabled={deleteCount === 0}
              />

              <ChannelVideoList
                listState={listState}
                handleLoadMore={handleLoadMore}
                activeItem={activeItem}
                handleOpenDetails={handleOpenDetails}
                onSelectionChange={handleSelectionChange}
              />
            </div>
          </GridItem>

          <GridItem templateArea="sidebar">
            {activeItem && (
              <ActionPanel onClose={handleClose} open={isOpen} fixed={false}>
                {channelId && (
                  <ChannelVideoInfoShare
                    channelId={channelId}
                    item={activeItem}
                    shareUrls={activeItem.sharingUrls}
                    updateShareUrls={handleUpdateShareUrls}
                    updateVisibility={handleUpdateVisibility}
                  />
                )}
              </ActionPanel>
            )}
          </GridItem>
        </>
      )}
      <GridItem templateArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default ChannelProductVideoList;
