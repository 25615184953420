/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MediaDto = {
    mediaType: MediaDto.mediaType;
    metaType?: string | null;
    name: string | null;
    path: string | null;
};

export namespace MediaDto {

    export enum mediaType {
        IMAGE = 'image',
    }


}

