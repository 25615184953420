import { GetProductsResponseDTO } from '../generated';
import { Action, ActionType, State } from '../hooks/useList';

const productListReducer = (
  state: State<GetProductsResponseDTO>,
  action: Action<GetProductsResponseDTO>,
): State<GetProductsResponseDTO> => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_SUCCESS:
      return {
        ...state,
        items:
          state.items && !action.payload.initial
            ? state.items.concat(action.payload.items)
            : action.payload.items,
        error: undefined,
        loading: false,
        totalCount: action.payload.totalCount,
        loadMore: action.payload.next ? true : false,
        initialyLoaded: true,
      };
    case ActionType.GET_ERROR:
      return {
        ...state,
        error: {
          status: action.payload.error.status,
          text: action.payload.error.text,
        },
        loading: false,
      };
    case ActionType.DELETE:
      return {
        ...state,
        items:
          state.items &&
          state.items.filter((item) => item.id !== action.payload.id),
        totalCount: state.totalCount && state.totalCount - 1,
      };
    case ActionType.ADD:
      return {
        ...state,
        items: [action.payload.item, ...(state.items || [])],
        loading: false,
        error: undefined,
      };
    case ActionType.DEACTIVATE:
      return {
        ...state,
        items: state?.items?.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              id: action.payload.id,
              isAvailable: action.payload.isAvailable,
            };
          }
          return item;
        }),
      };
    case ActionType.UPDATE:
      return {
        ...state,
        items: state?.items?.map((item) => {
          if (item.id === action.payload.id) {
            return {
              id: action.payload.id,
              ...action.payload.item,
            };
          }
          return item;
        }),
        loading: false,
        error: undefined,
      };
    case ActionType.UPDATE_MULTI:
      return {
        ...state,
        items: state?.items?.map((item) => {
          const newItem = (
            action.payload.items as GetProductsResponseDTO[]
          ).find((i) => i.id === item.id);
          return newItem ? newItem : item;
        }),
        loading: false,
        error: undefined,
      };
  }
  return state;
};

export default productListReducer;
