/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddChannelRequestDTO } from '../models/AddChannelRequestDTO';
import type { ChannelDTO } from '../models/ChannelDTO';
import type { ChannelLocalisationDTO } from '../models/ChannelLocalisationDTO';
import type { ChannelMinimalResponseDTO } from '../models/ChannelMinimalResponseDTO';
import type { ChannelRateCollectionDTO } from '../models/ChannelRateCollectionDTO';
import type { ChannelUpdateDTO } from '../models/ChannelUpdateDTO';
import type { ChannelVideoIdsResponseDTO } from '../models/ChannelVideoIdsResponseDTO';
import type { IntroVideoUpdateDTO } from '../models/IntroVideoUpdateDTO';
import type { PaginatedChannelWithVideosResponseDTO } from '../models/PaginatedChannelWithVideosResponseDTO';
import type { PaginatedVideoResponseDTO } from '../models/PaginatedVideoResponseDTO';
import type { UpdateChannelLocalisationDTO } from '../models/UpdateChannelLocalisationDTO';
import type { VideoDTO } from '../models/VideoDTO';
import type { VideoIdsDTO } from '../models/VideoIdsDTO';
import type { VideoResponseDTO } from '../models/VideoResponseDTO';
import type { VoucherDto } from '../models/VoucherDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChannelsService {

    /**
     * List channels that belong to authenticated VendorAgent
     * @param offset
     * @param limit
     * @returns PaginatedChannelWithVideosResponseDTO
     * @throws ApiError
     */
    public static getChannels(
        offset?: number,
        limit?: number,
    ): CancelablePromise<PaginatedChannelWithVideosResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels',
            query: {
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * Add a new channel.
     * @param requestBody
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static addChannel(
        requestBody: AddChannelRequestDTO,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a collection of all channel names and ids.
     * @returns ChannelMinimalResponseDTO
     * @throws ApiError
     */
    public static getMinimalChannels(): CancelablePromise<Array<ChannelMinimalResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/minimal',
        });
    }

    /**
     * Get the channel with the specified id
     * @param id
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static getChannel(
        id: number,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update a channel
     * @param id
     * @param requestBody
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static updateChannel(
        id: number,
        requestBody: ChannelUpdateDTO,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/channels/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove a channel
     * @param id
     * @returns ChannelMinimalResponseDTO
     * @throws ApiError
     */
    public static removeChannel(
        id: number,
    ): CancelablePromise<ChannelMinimalResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/channels/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * List all videos that are linked to a channel.
     * @param id
     * @param offset
     * @param limit
     * @param searchTerm
     * @param searchLocale
     * @returns PaginatedVideoResponseDTO
     * @throws ApiError
     */
    public static getChannelsVideos(
        id: number,
        offset?: number,
        limit?: number,
        searchTerm?: string,
        searchLocale?: string,
    ): CancelablePromise<PaginatedVideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{id}/videos',
            path: {
                'id': id,
            },
            query: {
                'offset': offset,
                'limit': limit,
                'searchTerm': searchTerm,
                'searchLocale': searchLocale,
            },
        });
    }

    /**
     * List all video ids that are linked to a channel.
     * @param id
     * @returns ChannelVideoIdsResponseDTO
     * @throws ApiError
     */
    public static getChannelVideoIds(
        id: number,
    ): CancelablePromise<ChannelVideoIdsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{id}/videoIds',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Randomizes the channel order
     * @returns ChannelRateCollectionDTO
     * @throws ApiError
     */
    public static randomizeChannels(): CancelablePromise<ChannelRateCollectionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/randomize',
        });
    }

    /**
     * Add videos to a channel
     * @param id
     * @param requestBody
     * @returns VideoIdsDTO
     * @throws ApiError
     */
    public static addVideos(
        id: number,
        requestBody: VideoIdsDTO,
    ): CancelablePromise<VideoIdsDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{id}/addVideos',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove videos from a channel
     * @param id
     * @param requestBody
     * @returns VideoIdsDTO
     * @throws ApiError
     */
    public static removeVideos(
        id: number,
        requestBody: VideoIdsDTO,
    ): CancelablePromise<VideoIdsDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{id}/removeVideos',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change the rate for multiple Channels
     * @param requestBody
     * @returns ChannelRateCollectionDTO
     * @throws ApiError
     */
    public static changeChannelsRate(
        requestBody: ChannelRateCollectionDTO,
    ): CancelablePromise<ChannelRateCollectionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/channels/rates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add intro video to a channel
     * @param id
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static addIntroVideo(
        id: number,
        requestBody: VideoDTO,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{id}/introVideo',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get intro video
     * @param id
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static getIntroVideo(
        id: number,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/channels/{id}/introVideo',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Add a channel localisation
     * @param channelId
     * @param requestBody
     * @returns ChannelLocalisationDTO
     * @throws ApiError
     */
    public static addChannelLocalisation(
        channelId: number,
        requestBody: ChannelLocalisationDTO,
    ): CancelablePromise<ChannelLocalisationDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{channelId}/channelLocalisation',
            path: {
                'channelId': channelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a channel localisation
     * @param channelId
     * @param language
     * @param requestBody
     * @returns ChannelLocalisationDTO
     * @throws ApiError
     */
    public static updateChannelLocalisation(
        channelId: number,
        language: string,
        requestBody: UpdateChannelLocalisationDTO,
    ): CancelablePromise<ChannelLocalisationDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/channels/{channelId}/channelLocalisation/{language}',
            path: {
                'channelId': channelId,
                'language': language,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a channel localisation
     * @param channelId
     * @param language
     * @returns ChannelLocalisationDTO
     * @throws ApiError
     */
    public static deleteChannelLocalisation(
        channelId: number,
        language: string,
    ): CancelablePromise<ChannelLocalisationDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/channels/{channelId}/channelLocalisation/{language}',
            path: {
                'channelId': channelId,
                'language': language,
            },
        });
    }

    /**
     * Update a intro video
     * @param channelId
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static updateIntroVideo(
        channelId: number,
        requestBody: IntroVideoUpdateDTO,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/channels/{channelId}/introVideo',
            path: {
                'channelId': channelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * SoftDelete a intro video from the channel
     * @param channelId
     * @returns any
     * @throws ApiError
     */
    public static deleteIntroVideo(
        channelId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/channels/{channelId}/introVideo',
            path: {
                'channelId': channelId,
            },
        });
    }

    /**
     * Adds a voucher to the channel
     * @param channelId
     * @param requestBody
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static addVoucherToChannel(
        channelId: number,
        requestBody: VoucherDto,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/channels/{channelId}/voucher',
            path: {
                'channelId': channelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Edits a voucher
     * @param channelId
     * @param requestBody
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static editVoucher(
        channelId: number,
        requestBody: VoucherDto,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/channels/{channelId}/voucher',
            path: {
                'channelId': channelId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes a voucher
     * @param channelId
     * @returns ChannelDTO
     * @throws ApiError
     */
    public static deleteVoucher(
        channelId: number,
    ): CancelablePromise<ChannelDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/channels/{channelId}/voucher',
            path: {
                'channelId': channelId,
            },
        });
    }

}
