import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import { isAvailable } from 'features/availability';
import { useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { ChannelMinimalResponseDTO } from '../../generated';
import Color, { ColorName } from '../../types/Color';
import Button from '../Button/Button';
import Select from '../Select/Select';
import './channel-controller.scss';

export interface Props {
  channels: Array<ChannelMinimalResponseDTO>;
  onDeleteItem: (channelId: number) => void;
  onRevert: () => void;
  onAddChannel: (channel: ChannelMinimalResponseDTO) => void;
  allChannels?: Array<ChannelMinimalResponseDTO>;
  className?: string;
}

const ChannelController = ({
  channels,
  onDeleteItem,
  onRevert,
  allChannels,
  onAddChannel,
  className,
}: Props) => {
  const { t } = useTranslation(['channel']);
  const [selectValue, setSelectValue] = useState<string>();
  const [showResetButton, setShowResetButton] = useState(false);

  const handleDelete = (id: number) => {
    onDeleteItem(id);
    setShowResetButton(true);
  };

  const handleAddChannel = () => {
    if (selectValue && allChannels) {
      const channelId = parseInt(selectValue, 10);
      const channelById = allChannels.find((i) => i.id === channelId);

      if (channelById) {
        onAddChannel({
          id: parseInt(selectValue, 10),
          name: channelById.name,
          key: '', // 💩,
          availability: channelById.availability,
        });
        setShowResetButton(true);
        setSelectValue('');
      }
    }
  };

  const handleSelectChange = (value: string) => {
    setSelectValue(value);
  };

  const handleRevert = () => {
    onRevert();
    setShowResetButton(false);
  };

  return (
    <Flipper flipKey={JSON.stringify(channels)}>
      <ul className={classNames('channel-controller', className)}>
        {channels.map(({ name, id, availability }) => (
          <Flipped key={id} flipId={id} translate>
            <li key={id} className="channel-controller__item">
              <Chip
                color={
                  isAvailable(availability)
                    ? Color.confirm
                    : ColorName['black-20']
                }
                onInteraction={() => handleDelete(id)}
              >
                {name !== '' ? name : t('no_title')}
              </Chip>
            </li>
          </Flipped>
        ))}
      </ul>

      {channels.length === 0 && (
        <div className="channel-controller__no-channels">
          {t('channel:noChannels')}
        </div>
      )}

      <div className="channel-controller__controls">
        <div className="channel-controller__add-box">
          <Select
            label={t('channel:title')}
            options={
              allChannels
                ? allChannels
                    .filter(
                      (o) =>
                        channels.findIndex((item) => item.id === o.id) === -1,
                    )
                    .map((o) => ({
                      value: o.id.toString(),
                      text:
                        o.name !== ''
                          ? `#${o.id} - ${o.name}`
                          : `#${o.id} - ${t('no_title')}`,
                    }))
                : []
            }
            onChange={(event) => handleSelectChange(event.target.value)}
            value={selectValue || ''}
            disabled={allChannels ? false : true}
          />
          <Button
            text={t('channel:addChannel')}
            type="button"
            onClick={handleAddChannel}
            color={Color.primary}
            disabled={selectValue ? false : true}
          />
        </div>
      </div>
      {showResetButton && (
        <div className="channel-controller__footer">
          <button
            type="button"
            onClick={handleRevert}
            className="channel-controller__revert"
          >
            {t('channel:revert')}
          </button>
        </div>
      )}
    </Flipper>
  );
};

export default ChannelController;
