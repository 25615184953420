/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BankingDetailDTO } from './BankingDetailDTO';

export type VendorResponseDTO = {
    id: number;
    company: string;
    type: VendorResponseDTO.type;
    bankingDetail?: BankingDetailDTO | null;
};

export namespace VendorResponseDTO {

    export enum type {
        DEFAULT = 'default',
        SALES = 'sales',
    }


}

