/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchUpdateProductsDto } from '../models/BatchUpdateProductsDto';
import type { BatchUpdateProductsResponseDto } from '../models/BatchUpdateProductsResponseDto';
import type { ChannelMinimalResponseDTO } from '../models/ChannelMinimalResponseDTO';
import type { HashtagResponseDTO } from '../models/HashtagResponseDTO';
import type { PaginatedProductResponseDTO } from '../models/PaginatedProductResponseDTO';
import type { PaginatedVideoResponseDTO } from '../models/PaginatedVideoResponseDTO';
import type { ProductCollectionDTO } from '../models/ProductCollectionDTO';
import type { ProductDTO } from '../models/ProductDTO';
import type { ProductIdsDTO } from '../models/ProductIdsDTO';
import type { ProductLocalisationDTO } from '../models/ProductLocalisationDTO';
import type { ProductResponseDTO } from '../models/ProductResponseDTO';
import type { ProductTaxDto } from '../models/ProductTaxDto';
import type { ProductTaxResponseDto } from '../models/ProductTaxResponseDto';
import type { UpdateEntityHashtagsDTO } from '../models/UpdateEntityHashtagsDTO';
import type { UpdateProductDTO } from '../models/UpdateProductDTO';
import type { VideoDTO } from '../models/VideoDTO';
import type { VideoResponseDTO } from '../models/VideoResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * List products that belong to authenticated VendorAgent
     * @param offset
     * @param limit
     * @param searchTerm
     * @param searchLocale
     * @returns PaginatedProductResponseDTO
     * @throws ApiError
     */
    public static getProducts(
        offset?: number,
        limit?: number,
        searchTerm?: string,
        searchLocale?: string,
    ): CancelablePromise<PaginatedProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products',
            query: {
                'offset': offset,
                'limit': limit,
                'searchTerm': searchTerm,
                'searchLocale': searchLocale,
            },
        });
    }

    /**
     * Add a product
     * @param requestBody
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static addProduct(
        requestBody: ProductDTO,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the product with the specified productId
     * @param productId
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static getProductById(
        productId: number,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * Delete the product with the specified productId
     * @param productId
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static deleteProduct(
        productId: number,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/products/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * Update product
     * @param productId
     * @param requestBody
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static updateProduct(
        productId: number,
        requestBody: UpdateProductDTO,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/products/{productId}',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets the channel names that have videos linked to the specified product
     * @param productId
     * @returns ChannelMinimalResponseDTO
     * @throws ApiError
     */
    public static getProductChannels(
        productId: number,
    ): CancelablePromise<Array<ChannelMinimalResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{productId}/channels',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * Adds multiple products
     * @param requestBody
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static addProducts(
        requestBody: ProductCollectionDTO,
    ): CancelablePromise<Array<ProductResponseDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Batch update products
     * @param requestBody
     * @returns BatchUpdateProductsResponseDto
     * @throws ApiError
     */
    public static batchUpdateProducts(
        requestBody: BatchUpdateProductsDto,
    ): CancelablePromise<BatchUpdateProductsResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/products/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add a new localisation to a product
     * @param productId
     * @param requestBody
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static addProductLocalisation(
        productId: number,
        requestBody: ProductLocalisationDTO,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products/{productId}/localisation',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get videos associated with specified productId
     * @param productId
     * @param offset
     * @param limit
     * @returns PaginatedVideoResponseDTO
     * @throws ApiError
     */
    public static getProductVideos(
        productId: number,
        offset?: number,
        limit?: number,
    ): CancelablePromise<PaginatedVideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{productId}/videos',
            path: {
                'productId': productId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * Add a new video to a product
     * @param productId
     * @param requestBody
     * @returns VideoResponseDTO
     * @throws ApiError
     */
    public static addProductVideo(
        productId: number,
        requestBody: VideoDTO,
    ): CancelablePromise<VideoResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products/{productId}/videos',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a product localisation
     * @param productId
     * @param localisationId
     * @param requestBody
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static updateProductLocalisation(
        productId: number,
        localisationId: number,
        requestBody: ProductLocalisationDTO,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/products/{productId}/localisation/{localisationId}',
            path: {
                'productId': productId,
                'localisationId': localisationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes a product localisation
     * @param productId
     * @param localisationId
     * @returns ProductResponseDTO
     * @throws ApiError
     */
    public static deleteProductLocalisation(
        productId: number,
        localisationId: number,
    ): CancelablePromise<ProductResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/products/{productId}/localisation/{localisationId}',
            path: {
                'productId': productId,
                'localisationId': localisationId,
            },
        });
    }

    /**
     * Run status update on videos linked to these products
     * @param authorization
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateProductStatus(
        authorization: string,
        requestBody: ProductIdsDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/products/status-update',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update hashtags for a product
     * @param productId
     * @param requestBody
     * @returns HashtagResponseDTO
     * @throws ApiError
     */
    public static updateProductHashtags(
        productId: number,
        requestBody: UpdateEntityHashtagsDTO,
    ): CancelablePromise<Array<HashtagResponseDTO>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/products/{productId}/hashtags',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get taxes for a product
     * @param productId
     * @returns ProductTaxResponseDto
     * @throws ApiError
     */
    public static getProductTaxes(
        productId: number,
    ): CancelablePromise<ProductTaxResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/products/{productId}/tax',
            path: {
                'productId': productId,
            },
        });
    }

    /**
     * Update taxes for a product
     * @param productId
     * @param requestBody
     * @returns ProductTaxResponseDto
     * @throws ApiError
     */
    public static updateProductTaxes(
        productId: number,
        requestBody: ProductTaxDto,
    ): CancelablePromise<ProductTaxResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/products/{productId}/tax',
            path: {
                'productId': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Throws a NotFoundException if the product is not found or does not belong to the vendor`,
            },
        });
    }

    /**
     * Delete a tax from a product
     * @param productId
     * @returns any
     * @throws ApiError
     */
    public static deleteProductTax(
        productId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/products/{productId}/tax',
            path: {
                'productId': productId,
            },
            errors: {
                404: `Throws a NotFoundException if the product/tax is not found or does not belong to the vendor`,
            },
        });
    }

}
