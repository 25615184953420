import React from 'react';
import { Link } from 'react-router-dom';
import LabelText from '../LabelText/LabelText';
import List from '../List/List';
import './info-video-list.scss';

const InfoVideoList = <T,>({
  label,
  videos,
  renderItem,
  emptyText = '',
  moreLink,
}: {
  moreLink?: {
    link: string;
    text: string;
  };
  emptyText?: string;
  label: string;
  videos: T[];
  renderItem: (item: T) => JSX.Element;
}) => {
  return (
    <LabelText label={label}>
      {videos.length > 0 ? (
        <List>{videos.map((video) => renderItem(video))}</List>
      ) : (
        emptyText
      )}
      {moreLink && (
        <div className="info-video-list__more">
          <Link className="info-video-list__more-link" to={moreLink.link}>
            {moreLink.text}
          </Link>
        </div>
      )}
    </LabelText>
  );
};

export default InfoVideoList;
