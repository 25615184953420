/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppGraphNavigationDTO = {
    autoNavigation?: AppGraphNavigationDTO.autoNavigation;
    northFeedItemKeyReference?: string;
    eastFeedItemKeyReference?: string;
    southFeedItemKeyReference?: string;
    westFeedItemKeyReference?: string;
};

export namespace AppGraphNavigationDTO {

    export enum autoNavigation {
        NORTH = 'north',
        EAST = 'east',
        SOUTH = 'south',
        WEST = 'west',
    }


}

