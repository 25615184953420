import classNames from 'classnames';
import { ElementType } from 'react';
import './grid.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  display?: 'grid' | 'inline-grid';
  justifyItems?: 'start' | 'end' | 'center' | 'stretch';
  alignItems?: 'start' | 'end' | 'center' | 'stretch';
  justifyContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  alignContent?:
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  gridAutoFlow?: 'row' | 'column' | 'row dense' | 'column dense';
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridTemplateAreas?: string;
  gap?: string;
  gridAutoColumns?: string;
  gridAutoRows?: string;
}

const Grid = ({
  children,
  className,
  tag: Tag = 'div',
  display = 'grid',
  justifyItems,
  alignItems,
  justifyContent,
  alignContent,
  gridAutoFlow,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridAutoColumns,
  gridAutoRows,
  gap,
  style,
  ...props
}: Props) => {
  const gridStyle = {
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    gridAutoColumns,
    gridAutoRows,
    gap,
  };
  return (
    <Tag
      {...props}
      className={classNames(
        'grid-layout',
        display && `grid-layout--display-${display}`,
        justifyItems && `grid-layout--justify-items-${justifyItems}`,
        alignItems && `grid-layout--align-items-${alignItems}`,
        justifyContent && `grid-layout--justify-content-${justifyContent}`,
        alignContent && `grid-layout--align-content-${alignContent}`,
        gridAutoFlow && `grid-layout--place-content-${gridAutoFlow}`,
        className,
      )}
      style={{
        ...style,
        ...gridStyle,
      }}
    >
      {children}
    </Tag>
  );
};

export default Grid;
