import classNames from 'classnames';
import { Color, ColorUse } from 'types/Color';
import Icon, { IconType } from '../Icon/Icon';
import './status-icon.scss';

export type Props = {
  icon: IconType;
  color?: Color | ColorUse;
  className?: string;
  square?: boolean;
};

const StatusIcon = ({ className, icon, color, square }: Props) => {
  return (
    <div
      className={classNames(
        'status-icon',
        color && `status-icon--${color}`,
        square && 'status-icon--square',
        className,
      )}
    >
      <Icon icon={icon} />
    </div>
  );
};

export default StatusIcon;
