import Steps from 'components/Steps/Steps';
import gsap from 'gsap';
import { Route } from 'hooks/useRoutesObject';
import { useEffect, useRef, useState } from 'react';
import { Outlet, OutletProps, useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import './animated-outlet.scss';
import { motion } from 'framer-motion/dist/framer-motion';

const AnimatedOutlet = (props: OutletProps) => {
  const location = useLocation();
  const oneRef = useRef<HTMLDivElement>(null);
  const twoRef = useRef<HTMLDivElement>(null);
  const [switchRef, setSwitchRef] = useState(true);

  useEffect(() => {
    setSwitchRef(!switchRef);
  }, [location.pathname]);

  // return (
  //   <Flipper
  //     className="animated-outlet"
  //     flipKey={location.pathname}
  //     handleEnterUpdateDelete={({
  //       hideEnteringElements,
  //       animateEnteringElements,
  //       animateExitingElements,
  //       animateFlippedElements,
  //     }) => {
  //       hideEnteringElements();
  //       animateExitingElements()
  //         .then(animateFlippedElements)
  //         .then(animateEnteringElements);
  //     }}
  //   >
  //     <div className="animated-outlet__container">
  //       <Outlet {...props} />
  //     </div>
  //   </Flipper>
  // );

  // return (
  //   <Steps stepKey={location.pathname} ref={switchRef ? oneRef : twoRef}>
  //     <div
  //       className="animated-outlet__container"
  //       ref={switchRef ? oneRef : twoRef}
  //     >
  //       <Outlet {...props} />
  //     </div>
  //   </Steps>
  // );

  return (
    <motion.div
      className="animated-outlet__container"
      key={location.pathname}
      initial="initial"
      animate="in"
      variants={{
        initial: {
          opacity: 0,
          translateX: '2rem',
        },
        in: {
          opacity: 1,
          translateX: 0,
        },
        out: {
          opacity: 0,
          translateX: '-2rem',
        },
      }}
      transition={{
        type: 'tween',
        ease: 'linear',
        duration: 0.25,
      }}
    >
      <Outlet {...props} />
    </motion.div>
  );

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        // nodeRef={switchRef ? oneRef : twoRef}
        key={location.pathname}
        addEndListener={(node: any, done: any) => {
          node.addEventListener('transitionend', done, false);
        }}
        classNames="steps-transition"
        // timeout={0}
        // onEnter={() => {
        //   const node = switchRef ? oneRef : twoRef;
        //   gsap.set(node.current, { opacity: 0, xPercent: 10 });
        //   gsap
        //     .timeline({ paused: true })
        //     .to(node, { xPercent: 0, duration: 0.25, opacity: 1 })
        //     .play();
        // }}
        // onExit={() => {
        //   const node = switchRef ? oneRef : twoRef;
        //   gsap
        //     .timeline({ paused: true })
        //     .to(node.current, { opacity: 0, xPercent: -10 })
        //     .play();
        // }}
        // unmountOnExit
        // mountOnEnter
      >
        <div
          className="animated-outlet__container"
          // ref={switchRef ? oneRef : twoRef}
        >
          <Outlet {...props} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default AnimatedOutlet;
