import ActionPanel from 'components/ActionPanel/ActionPanel';
import ActivateToggle from 'components/ActivateToggle/ActivateToggle';
import Button from 'components/Button/Button';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Footer from 'components/Footer/Footer';
import GridItem from 'components/Grid/GridItem';
import HowTo from 'components/HowTo/HowTo';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import List from 'components/List/List';
import ListItem from 'components/ListItem/ListItem';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import ProductInfo from 'components/ProductInfo/ProductInfo';
import Search from 'components/Search/Search';
import { HashtagAsyncField } from 'features/hashtags';
import { useLinkHashtagsToProducts } from 'features/hashtags/queries';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  BatchLinkHashtagsErrorDto,
  HashtagResponseDTO,
  KeyValueDTO,
  KeyValueStoreService,
  ProductsService,
} from 'generated';
import { GetProductsResponseDTO } from 'generated/models/GetProductsResponseDTO';
import { BREAKPOINTS } from 'global-constants';
import useAppStatus from 'hooks/useAppStatus';
import useList from 'hooks/useList';
import { useListSelection } from 'hooks/useListSelection';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import placeholderImage from 'images/placeholder-product.svg';
import howToProduct from 'images/product-empty.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import productListReducer from 'reducer/reducer-product-list';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import { selectLocaleIso } from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import useDebouncedEffect from 'use-debounced-effect';
import ErrorModal from './ErrorModal';

type UpdateValues = {
  hashtags?: HashtagResponseDTO[];
};

const ProductContainer = () => {
  const { t } = useTranslation(['translation', 'product']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const {
    isOpen: modalIsOpen,
    open: openModal,
    close: closeModal,
  } = useOpen(false);
  const {
    isOpen: panelIsOpen,
    open: openPanel,
    close: closePanel,
  } = useOpen(isDesktop);
  const { activeItem, activateToggleLoading, listState, listFunctions } =
    useList<GetProductsResponseDTO>({
      reducer: productListReducer,
    });
  const { get, deleteItem, setActiveItem, deactivate, updateProducts } =
    listFunctions;
  const [showHowTo, setShowHowTo] = useState(false);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const localeIso = useReduxSelector(selectLocaleIso);
  const { setAppStatus } = useAppStatus();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedIds, setSelectedItems, toggleSelectItem } =
    useListSelection();
  const [selectAll, setSelectAll] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const updateHashtagsMutation = useLinkHashtagsToProducts();
  const [mutationErrors, setMutationErrors] =
    useState<BatchLinkHashtagsErrorDto[]>();

  // TODO take from kvstore Redux
  const [showDiscount, setShowDiscount] = useState(false);

  useEffect(() => {
    setShowHowTo(false);
    get({
      service: () => ProductsService.getProducts(),
      props: {
        initial: true,
        onSuccess: (res) => {
          if (res.data.length === 0) {
            setShowHowTo(true);
          }
        },
      },
    });
  }, [vendorAgent]);

  useEffect(() => {
    if (!activeItem) {
      closePanel();
    }
  }, [activeItem]);

  useDebouncedEffect(
    () => {
      try {
        listFunctions.get({
          props: {
            initial: true,
          },
          service: () =>
            ProductsService.getProducts(
              undefined,
              undefined,
              searchTerm,
              localeIso,
            ),
        });
      } catch (error) {
        console.log(error);
      }
    },
    400,
    [searchTerm],
  );

  const getShowDiscount = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.ENABLE_DISCOUNT_PRICES,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const discount = async () => {
      const showDiscount = await getShowDiscount();
      setShowDiscount(showDiscount);
    };
    discount();
  }, []);

  const handleDelete = () => {
    if (activeItem) {
      deleteItem({
        service: () => ProductsService.deleteProduct(activeItem.id),
        props: {
          onSuccess: (res) => {
            closeModal();
            setAppStatus(
              t('product:delete.success', { product: res.vendorProductId }),
              'success',
            );
          },
          onError: (error) => {
            closeModal();
            setAppStatus(
              t('product:delete.error', {
                product: activeItem.vendorProductId,
              }),
              'error',
              true,
            );
          },
        },
      });
    }
  };

  const handleOpenDetails = (item: GetProductsResponseDTO) => {
    setActiveItem(item);
    openPanel();
  };

  const handleClose = () => {
    closePanel();
    setTimeout(() => {
      setActiveItem(undefined);
    }, 600);
  };

  const handleLoadMore = () => {
    listFunctions.get({
      service: () =>
        ProductsService.getProducts(
          listState.items?.length,
          undefined,
          searchTerm,
          localeIso,
        ),
    });
  };

  const handleDeactivate = () => {
    if (activeItem) {
      deactivate({
        service: () =>
          ProductsService.updateProduct(activeItem.id, {
            isAvailable: !activeItem.isAvailable,
          }),
      });
    }
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    if (checked) {
      listState.items && setSelectedItems(listState.items.map((i) => i.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectChange = (id: number) => {
    toggleSelectItem(id);
  };

  useEffect(() => {
    setSelectedItems(selectedIds);
    setSelectAll(selectedIds.length === listState.items?.length);
  }, [selectedIds]);

  const handleEdit = (
    values: UpdateValues,
    formikHelpers: FormikHelpers<UpdateValues>,
  ) => {
    updateHashtagsMutation.mutate(
      {
        productIds: selectedIds,
        hashtagIds: values.hashtags?.map((i) => i.id) || [],
      },
      {
        onSuccess: (res, variables) => {
          const { data, errors } = res;
          const productsToUpdate = listState.items?.filter((i) =>
            data.map((i) => i.entityId).includes(i.id),
          );
          if (productsToUpdate) {
            const updated = productsToUpdate.map((i) => {
              const newHashtags = [
                ...i.hashtags,
                ...(values.hashtags || []).filter(
                  (h) => !i.hashtags.map((o) => o.id).includes(h.id),
                ),
              ].sort((a, b) => a.name.localeCompare(b.name));

              return {
                ...i,
                hashtags: newHashtags,
              };
            });
            updateProducts(updated);
          }
          updateHashtagsMutation.reset();
          formikHelpers.resetForm();
          setOpenEditModal(false);

          if (
            (errors?.filter(
              (i) => i.reason === BatchLinkHashtagsErrorDto.reason.MAX_HASHTAGS,
            ).length || 0) > 0
          ) {
            setMutationErrors(errors);
            setOpenErrorModal(true);
          }
        },
        onError: () => {
          updateHashtagsMutation.reset();
          formikHelpers.setStatus(t('product:edit.error'));
        },
      },
    );
  };

  return !showHowTo ? (
    listState.loading && !listState.initialyLoaded ? (
      <GridItem className="page__content" column="1/4">
        <Loader />
      </GridItem>
    ) : (
      <>
        <GridItem className="page__content" templateArea="main">
          <div className="page__controls page__controls--list">
            <Search
              searchTerm={searchTerm}
              onChange={handleSearchTermChange}
              placeholder={t('product:searchPlaceholder')}
            />

            <Button link="/products/new" text={t('add')} icon="add" />
          </div>

          <div className="page__controls-list page__list">
            <IconButton
              icon="edit"
              onClick={() => setOpenEditModal(true)}
              disabled={selectedIds.length === 0}
              wrapperClassName="page__controls-delete"
              appearance={selectedIds.length === 0 ? 'ghost' : 'filled'}
              big
            />
            <List
              count={{
                itemsVisible: listState.items?.length,
                total: listState.totalCount,
              }}
              handleLoadMore={handleLoadMore}
              loading={listState.loading}
              error={listState.error}
              showLoadMore={listState.loadMore}
              className="page__list"
              selectAll={{
                checked: selectAll,
                onSelectAll: handleSelectAll,
              }}
            >
              {listState.items &&
                listState.items.map((item, index) => (
                  <ListItem
                    deactivated={!item.isAvailable}
                    active={item.id === activeItem?.id}
                    key={index}
                    title={
                      item.productLocalisations[0]
                        ? item.productLocalisations[0]?.name
                        : t('no_title')
                    }
                    subtitle={item.vendorProductId}
                    price={{
                      amount: item.productLocalisations[0]
                        ? item.productLocalisations[0].price
                        : 0,
                      currency: item.productLocalisations[0]?.currencyCode,
                    }}
                    discountPrice={
                      showDiscount
                        ? item.productLocalisations[0]?.discountPrice
                        : null
                    }
                    image={
                      item.videos[0]?.videoPreviews?.find(
                        (i) => i.size === 'small',
                      )?.uri
                    }
                    onClick={() => handleOpenDetails(item)}
                    id={`${item.id}`}
                    placeholder={placeholderImage}
                    selectable={{
                      selected: selectedIds?.includes(item.id) || false,
                      onSelectChange: () => handleSelectChange(item.id),
                    }}
                  />
                ))}
            </List>
          </div>
          {listState.totalCount && listState.totalCount > 15 && (
            <div className="page__controls ">
              <Button link="/products/new" text={t('add')} icon="add" />
            </div>
          )}
        </GridItem>
        <GridItem templateArea="sidebar">
          <ActionPanel onClose={handleClose} open={panelIsOpen}>
            {activeItem && (
              <>
                <div className="page__controls">
                  <div className="page__controls-left">
                    <ActivateToggle
                      active={activeItem.isAvailable}
                      onToggle={handleDeactivate}
                      loading={activateToggleLoading}
                    />
                  </div>
                  <div className="page__controls-right">
                    <IconButton
                      tooltip={t('product:editDelete')}
                      onClick={openModal}
                      type="button"
                      icon="delete"
                      big={true}
                    />

                    <ConfirmModal
                      isOpen={modalIsOpen}
                      headline={t('product:editDelete')}
                      text={t('product:really_delete', {
                        title: activeItem.vendorProductId,
                      })}
                      onCancelClick={closeModal}
                      onConfirmClick={handleDelete}
                      confirmText={t('delete')}
                    />
                    <IconButton
                      tooltip={t('product:edit.label')}
                      icon="edit"
                      link={`/products/${activeItem.id}`}
                      appearance="filled"
                      big
                    />
                  </div>
                </div>
                <ProductInfo
                  key={activeItem.vendorProductId}
                  {...activeItem}
                  showDiscountPrice={showDiscount}
                />
              </>
            )}
          </ActionPanel>
        </GridItem>
        <GridItem templateArea="footer">
          <Footer />
        </GridItem>

        <Modal
          isOpen={openEditModal}
          headline={t('product:edit.headline', { count: selectedIds.length })}
          onClose={() => setOpenEditModal(false)}
          variant="wide"
        >
          <div className="modal__text">
            <p>{t('product:edit.desc')}</p>
          </div>
          <Formik
            initialValues={{ hashtags: [] } as UpdateValues}
            onSubmit={handleEdit}
          >
            {(formik: FormikProps<UpdateValues>) => (
              <Form>
                <Spacer marginTop={8}>
                  <LabelText label={t('product:hashtag.label')}>
                    <Spacer
                      marginBottom={2}
                      tag="p"
                      className="page__form-desc"
                    >
                      {t('product:edit.hashtags')}
                    </Spacer>
                    <HashtagAsyncField />
                  </LabelText>
                </Spacer>

                {formik.status && (
                  <Spacer className="c-warning modal__text" marginTop={4}>
                    <p>{formik.status}</p>
                  </Spacer>
                )}

                <div className="modal__controls">
                  <Button
                    text={t('cancel')}
                    type="button"
                    onClick={() => setOpenEditModal(false)}
                    color={Color.primary}
                    appearance="ghost"
                  />

                  <Button
                    text={t('video:batchVisibility.confirm')}
                    type="button"
                    onClick={() => formik.submitForm()}
                    color={Color.primary}
                    loading={updateHashtagsMutation.isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <ErrorModal
          openErrorModal={openErrorModal}
          setOpenErrorModal={setOpenErrorModal}
          products={listState.items}
          errors={mutationErrors}
        />
      </>
    )
  ) : (
    <>
      <GridItem column="1 / 4">
        <HowTo
          button={{ link: '/products/new', text: t('product:addProduct') }}
          text={t('product:empty')}
          image={howToProduct}
        />
      </GridItem>
      <GridItem column="1 / 4">
        <Footer />
      </GridItem>
    </>
  );
};

export default ProductContainer;
