import Button from 'components/Button/Button';
import AvailabilityFieldSet, {
  initialAvailabilityValues,
  mapAvailabilityValues,
  validateAvailability,
} from 'features/availability';
import { Form, Formik, FormikProps } from 'formik';
import { AvailabilityDTO } from 'generated/models/AvailabilityDTO';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'types/Color';
import * as Yup from 'yup';
import { useUpdateChannelAvailability } from '../queries';

export interface Props {
  availability?: AvailabilityDTO;
  onClose: () => void;
  channelId: number;
}

const ChannelActivateContainer = ({
  availability,
  onClose,
  channelId,
}: Props) => {
  const { t } = useTranslation(['translation', 'channel']);
  const availabilityMutation = useUpdateChannelAvailability(channelId);

  const handleSubmit = ({
    availability,
  }: {
    availability: AvailabilityDTO;
  }) => {
    if (channelId) {
      availabilityMutation.mutate(mapAvailabilityValues(availability));
    }
  };

  const nameSchema = Yup.object().shape({
    availability: validateAvailability(t('channel:available.endBeforeStart')),
  });

  useEffect(() => {
    if (
      availabilityMutation.status === 'success' ||
      availabilityMutation.status === 'error'
    ) {
      onClose();
    }
  }, [availabilityMutation.status]);

  return (
    <div>
      <Formik
        initialValues={initialAvailabilityValues(availability)}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize={true}
      >
        {(formik: FormikProps<{ availability: AvailabilityDTO }>) => (
          <Form>
            <AvailabilityFieldSet />

            <div className="modal__controls">
              <Button
                text={t('cancel')}
                type="button"
                onClick={onClose}
                color={Color.primary}
                appearance="ghost"
              />
              <Button
                text={t('save')}
                type="submit"
                color={Color.primary}
                loading={availabilityMutation.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChannelActivateContainer;
