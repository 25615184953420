import classNames from 'classnames';
import { forwardRef } from 'react';
import useOpen from '../../hooks/useOpen';
import Icon from '../Icon/Icon';
import Input, { Props } from '../Input/Input';
import './validate-password-input.scss';

const ValidatePasswordInput = forwardRef<
  HTMLInputElement & HTMLTextAreaElement,
  Props
>(({ ...props }, ref) => {
  const { isOpen: isVisible, toggle } = useOpen(false);

  const rootClassName = classNames(
    'validate-password-input__input',
    props.className,
  );

  const toggleBtnClass = classNames({
    'validate-password-input__password-toggle-btn': true,
    'validate-password-input__password-toggle-btn--visible': isVisible,
  });

  return (
    <div className={rootClassName}>
      <Input type={isVisible ? 'text' : 'password'} {...props} ref={ref} />

      <button onClick={toggle} className={toggleBtnClass} type="button">
        <span className="validate-password-input__toggle-icon">
          <Icon icon="eye" />
        </span>
      </button>
    </div>
  );
});

export default ValidatePasswordInput;
