import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import IconButton from 'components/IconButton/IconButton';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import { utcToZonedTime } from 'date-fns-tz';
import { getAvailabilityAsText, isAvailable } from 'features/availability';
import { useGetChannel } from 'features/channel-detail';
import { useGetChannelVideoIds } from 'features/channel-video-list';
import { ChannelMinimalResponseDTO, VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import placeholderImage from 'images/placeholder-channel.svg';
import { useEffect, useState } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import { ChannelData } from './VideoChannels';

/**
 * Component to display a single channel of a video.
 */
const VideoChannelItem = ({
  channel,
  conflict,
  onRemoveChannel,
  onAddChannel,
}: {
  channel: ChannelMinimalResponseDTO;
  conflict?: boolean;
  onRemoveChannel: (channel: ChannelMinimalResponseDTO) => void;
  onAddChannel: (channel: ChannelMinimalResponseDTO, force?: boolean) => void;
}) => {
  const { data } = useGetChannel(channel.id);
  const { data: ids } = useGetChannelVideoIds(channel.id);
  const [{ iconUrl, name, availability, numberOfVideos }, setDetails] =
    useState<ChannelData>(channel);
  const { t } = useTranslation(['translation', 'list', 'video']);
  const timeZone = useReduxSelector(selectTimezone);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  useEffect(() => {
    setDetails({ ...channel, ...data, numberOfVideos: ids?.videoIds.length });
  }, [data, ids]);

  const getTooltip = () => {
    return getAvailabilityAsText(availability, t, timeZone);
  };

  const handleRemove = () => {
    onRemoveChannel(channel);
  };
  const handleEditSave = () => {
    onAddChannel(channel, true);
  };

  return (
    <Flipped flipId={channel.id}>
      <div
        className={classNames(
          'video-channels__chip',
          conflict && 'video-channels__chip--conflict',
        )}
      >
        <Chip
          image={iconUrl || placeholderImage}
          status={
            conflict
              ? VideoResponseDTO.status.DRAFT
              : isAvailable(availability)
              ? VideoResponseDTO.status.LIVE
              : VideoResponseDTO.status.IDLE
          }
          hint={
            numberOfVideos
              ? t('list:video', { count: numberOfVideos })
              : undefined
          }
          onInteraction={!conflict ? handleRemove : undefined}
          interactionTooltip={t('video:channel.remove')}
          tooltip={getTooltip()}
        >
          {name}
        </Chip>

        {conflict && (
          <FlexBox
            className="inline-edit__controls"
            marginTop={2}
            justifyContent="flex-end"
          >
            <FlexItem tag="p" flexGrow={1} marginRight={2} className="small">
              {t('video:channel.duplicate')}
            </FlexItem>
            <FlexItem>
              <IconButton
                key="check"
                icon="check"
                type="button"
                tooltip={t('save')}
                onClick={handleEditSave}
                appearance="filled"
                big={!isDesktop}
              />
            </FlexItem>
            <FlexItem marginLeft={2}>
              <IconButton
                key="close"
                icon="close"
                onClick={handleRemove}
                tooltip={t('cancel')}
                big={!isDesktop}
              />
            </FlexItem>
          </FlexBox>
        )}
      </div>
    </Flipped>
  );
};

export default VideoChannelItem;
