import Help from 'components/Help/Help';
import { useTranslation } from 'react-i18next';
import ResetPassword from '../features/ResetPassword/ResetPassword';
import LandingPage from './LandingPage';

const ResetPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <LandingPage headline={t('reset-password-headline')} hint={<Help />}>
      <ResetPassword />
    </LandingPage>
  );
};

export default ResetPasswordPage;
