import Button from 'components/Button/Button';
import InputField from 'components/Input/InputField';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import {
  useGetHashtagSettings,
  useUpdateHashtagSettings,
} from 'features/Settings/UpdateHashtagsMax/queries';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Headline from '../../../components/Headline/Headline';

const UpdateHashtagMax = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const { data, isLoading } = useGetHashtagSettings();
  const mutation = useUpdateHashtagSettings();

  const handleSubmit = (values: { max: number }) => {
    mutation.mutate(
      { maximumHashtagsPerEntity: values.max },
      {
        onSuccess: () => {
          mutation.reset();
        },
      },
    );
  };

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:hashtags.title')}
      </Headline>

      <p className="settings__block page__form-desc">
        {t('settings:hashtags.description')}
      </p>
      <Formik
        initialValues={{ max: data?.maximumHashtagsPerEntity || 0 }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <FlexBox justifyContent="space-between">
            <FlexItem flexGrow={1} marginRight={3}>
              <Field
                component={InputField}
                type="number"
                name="max"
                disabled={isLoading}
              />
            </FlexItem>
            <FlexItem pushRight>
              <Button
                text={t('save')}
                type="button"
                onClick={() => formik.submitForm()}
                loading={isLoading || mutation.isLoading}
                disabled={isLoading || mutation.isLoading}
              />
            </FlexItem>
          </FlexBox>
        )}
      </Formik>
    </div>
  );
};

export default UpdateHashtagMax;
