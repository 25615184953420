import filesize from 'filesize';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { maxFileSize } from '../../global-constants';
import i18n from '../../i18n';
import Color from '../../types/Color';
import Button from '../Button/Button';
import InputField from '../Input/InputField';
import UploadField from '../UploadField/UploadField';
import './terms-form.scss';

export interface Props {
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<any>;
  loading?: boolean;
  error?: string;
}

export type Values = {
  title: string;
  file: File | null;
};

const TermsForm = ({ onSubmit, loading, error }: Props) => {
  const { t } = useTranslation(['settings', 'translation']);

  const nameSchema = Yup.object().shape({
    title: Yup.string().required(t('translation:form.required')),
    file: Yup.mixed()
      .test(
        'fileType',
        t('translation:form.wrongType', { types: '.pdf' }),
        (file) => {
          return (
            file &&
            Object.values(['pdf']).includes(
              file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase(),
            )
          );
        },
      )
      .test(
        'fileSize',
        t('translation:form.tooBig', {
          maxFileSize: filesize(maxFileSize, {
            locale: i18n.language,
          }),
        }),
        (file) => {
          return file?.size <= maxFileSize;
        },
      ),
  });

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    onSubmit(values, formikHelpers);
  };

  return (
    <div className="terms-form">
      <div className="caption--primary">{t('settings:terms.add.headline')}</div>
      <Formik
        initialValues={{ title: '0.0.0', file: null } as Values}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
      >
        {(formik: FormikProps<Values>) => (
          <Form className="terms-form__form">
            <Field
              name="file"
              component={UploadField}
              allowedTypes={['pdf']}
              size
              fileName
              maxFileSize={maxFileSize}
            />
            <Field
              component={InputField}
              name="title"
              label={t('settings:terms.add.label')}
              placeholder={t('settings:terms.add.placeholder')}
            />
            <Button
              text={t('add')}
              color={Color.primary}
              appearance="filled"
              type="submit"
              icon="add"
              loading={loading && formik.touched.file}
            />
          </Form>
        )}
      </Formik>
      {error && <div className="terms-form__error input__error">{error}</div>}
    </div>
  );
};

export default TermsForm;
