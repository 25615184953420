import ActivateToggle from 'components/ActivateToggle/ActivateToggle';
import Checkbox from 'components/Checkbox/Checkbox';
import DatePickerField from 'components/DatePicker/DatePickerField';
import { FlexBox } from 'components/Layout/Layout';
import { isAfter, isBefore, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useField } from 'formik';
import { AvailabilityDTO } from 'generated/models/AvailabilityDTO';
import useAvailability from 'hooks/useAvailability';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import './availability-field-set.scss';

const AvailabliltyFieldSet = () => {
  const { available } = useAvailability();
  const [dateIsActive, setDateIsActive] = useState<boolean>(false);
  const [field, meta, helpers] = useField<AvailabilityDTO>('availability');
  const { value } = field;
  const [activateVisible, setActivateVisible] = useState<boolean>();
  const { t } = useTranslation();
  const timeZone = useReduxSelector(selectTimezone);

  const props = {
    startDate: value?.start ? utcToZonedTime(value.start, timeZone) : null,
    endDate: value?.end ? utcToZonedTime(value.end, timeZone) : null,
    showTimeSelect: true,
    timeFormat: 'HH:mm',
    timeIntervals: 15,
    timeCaption: 'time',
    disabled: !dateIsActive,
    isClearable: true,
  };

  const handleActivateToggle = () => {
    helpers.setValue({
      ...value,
      type: AvailabilityDTO.type.PERMANENT,
      active: value?.active ? !value?.active : true,
    });
  };

  // fixes pageCollapse-Animation
  useEffect(() => {
    setTimeout(() => {
      setActivateVisible(true);
    }, 1);
  }, []);

  useEffect(() => {
    if (value?.type === AvailabilityDTO.type.DURATION) {
      setDateIsActive(true);
    }
  }, [value]);

  useEffect(() => {
    if (value?.start || value?.end) {
      helpers.setValue({ ...value, type: AvailabilityDTO.type.DURATION });
    }
  }, [value?.start, value?.end]);

  useEffect(() => {
    if (!dateIsActive) {
      helpers.setValue({
        ...value,
        type: AvailabilityDTO.type.PERMANENT,
        active: value?.active || false,
      });
    }
  }, [dateIsActive]);

  const isBeforeEndDate = (date: Date): boolean => {
    if (value?.end) {
      return isBefore(
        utcToZonedTime(date, timeZone),
        utcToZonedTime(value.end, timeZone),
      );
    }
    return true;
  };
  const isAfterStartDate = (date: Date): boolean => {
    if (value?.start) {
      return isAfter(
        utcToZonedTime(date, timeZone),
        utcToZonedTime(value.start, timeZone),
      );
    }
    return true;
  };

  return (
    <FlexBox className="channel-activate">
      <div className="channel-activate__group">
        <div className="caption--primary channel-activate__caption">
          {t('channel:activate.caption')}
        </div>
        {activateVisible && (
          <ActivateToggle
            active={available || false}
            onToggle={handleActivateToggle}
            disabled={dateIsActive}
            textPosition="top"
          />
        )}
      </div>

      <div className="channel-activate__group">
        <div className="caption--primary channel-activate__caption">
          {`${t('channel:date.caption')} (${timeZone})`}
        </div>
        <FlexBox alignItems="flex-end" className="channel-activate__date">
          <Checkbox
            name="status"
            checked={dateIsActive}
            onChange={() => setDateIsActive(!dateIsActive)}
          />
          <FlexBox className="channel-activate__inputs">
            <DatePickerField
              name="availability.start"
              label={t('channel:date.start.label')}
              {...props}
              selectsStart={true}
              placeholderText={t('channel:date.start.placeholder')}
              maxDate={
                value?.end
                  ? subDays(utcToZonedTime(value.end, timeZone), 0)
                  : null
              }
              filterTime={isBeforeEndDate}
            />
            <DatePickerField
              name="availability.end"
              label={t('channel:date.end.label')}
              {...props}
              selectsEnd={true}
              placeholderText={t('channel:date.end.placeholder')}
              filterTime={isAfterStartDate}
              minDate={
                value?.start &&
                isAfter(
                  utcToZonedTime(value.start, timeZone),
                  utcToZonedTime(new Date(), timeZone),
                )
                  ? subDays(utcToZonedTime(value.start, timeZone), 0)
                  : utcToZonedTime(new Date(), timeZone)
              }
            />
          </FlexBox>
        </FlexBox>
      </div>
    </FlexBox>
  );
};

export default AvailabliltyFieldSet;
