import Headline from '../Headline/Headline';
import './list-item-simple.scss';

export type Props = {
  headline: React.ReactNode;
  headerRight: React.ReactNode;
  body: React.ReactNode;
};

const ListItemSimple = ({ headline, headerRight, body }: Props) => {
  return (
    <li className="list-item-simple">
      <div className="list-item-simple__header">
        <Headline headingLevel="h4" size={2}>
          {headline}
        </Headline>

        <div className="list-item-simple__header-right">{headerRight}</div>
      </div>

      <div className="list-item-simple__body">{body}</div>
    </li>
  );
};

export default ListItemSimple;
