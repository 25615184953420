import { DefaultOption } from 'components/SelectInteractive/components/Option';
import { OptionWithIconProps } from 'components/SelectInteractive';
import { FieldProps } from 'formik';
import { HashtagResponseDTO } from 'generated';
import { OnChangeValue } from 'react-select';
import HashtagSelect, { Props } from './HashtagSelect';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

/**
 * This component renders a component to be used as the "component" prop of a Formik Field.
 * Only use this component with a <Formik> wrapper component for state handling and submitting the value.
 * If you need a Field that is already prefilled with the hahstags from the API use <HashtagsAsyncField> from features/hashtags.
 * If you just want to use an input component without Formik, please use the <HashtagSelect> which is used here internally.
 */
const HashtagSelectField = ({
  field,
  form: { setFieldValue },
  hashtags,
  ...props
}: Props<DefaultOption> & FieldProps) => {
  const onChange = (option: OnChangeValue<OptionWithIconProps, true>) => {
    setFieldValue(
      field.name,
      option?.map((i) => ({ id: parseInt(i.value), name: i.label })),
    );
  };

  const getValue = () => {
    if (!field.value) {
      return '';
    }
    return field.value?.map((i: HashtagResponseDTO) => ({
      value: i.id.toString(),
      label: i.name,
    }));
  };

  return (
    <ErrorBoundary>
      <HashtagSelect
        {...field}
        {...props}
        value={getValue()}
        hashtags={hashtags}
        onChange={onChange}
      />
    </ErrorBoundary>
  );
};

export default HashtagSelectField;
