import { InfiniteData, useQuery } from '@tanstack/react-query';
import { ORDER_LIST_KEYS, orderListKeys } from 'features/order-list';
import {
  ApiError,
  PaginatedVendorOrderDto,
  VendorOrderDto,
  VendorOrderService,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export interface ExtendedVendorOrderDto extends VendorOrderDto {
  manifestLink?: string | null;
  trackingLink?: string | null;
  invoiceLink?: string | null;
  /** YYYY-MM-DD */
  pickupdate?: string | null;
}

export interface PaginatedExtendedVendorOrderDto
  extends PaginatedVendorOrderDto {
  data: Array<ExtendedVendorOrderDto>;
}

export const orderDetailKeys = {
  getOrder: (orderNo: string, vendorAgentId?: number) => {
    const [key, vendorId] = orderListKeys.getOrders(vendorAgentId);
    return [key, vendorId, orderNo];
  },
};

export const useGetOrder = (orderNo: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useQuery<ExtendedVendorOrderDto, ApiError>({
    queryKey: orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
    queryFn: async () => {
      const res = await VendorOrderService.getByOrderNo(orderNo);
      return {
        ...res,
        trackingLink: null,
        manifestLink: null,
        invoiceLink: null,
      } as ExtendedVendorOrderDto;
    },
    initialData: () => {
      const data = queryClient.getQueryData<
        InfiniteData<PaginatedExtendedVendorOrderDto>
      >([ORDER_LIST_KEYS.getOrders, vendorAgent?.currentVendor.id]);
      return data?.pages
        .map((d) => d.data)
        .flat()
        .find((i) => i.orderNo === orderNo);
    },
    staleTime: 2 * 60 * 1000, // 2 minutes
  });
};
