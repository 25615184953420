import Chip from 'components/Chip/Chip';
import Icon from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout';
import { FilterItem } from 'features/video-filter';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import './filter-bar.scss';

export type Props = {
  items?: FilterItem[];
  onRemoveItem: (id: string) => void;
  onClick?: () => void;
  onReset: () => void;
  placeholder?: string;
};

const FilterBar = ({
  onClick,
  onRemoveItem,
  onReset,
  items,
  placeholder,
}: Props) => {
  const { t } = useTranslation(['translation', 'video']);

  const handleRemoveItem = (
    id: string,
    e?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e?.stopPropagation();
    onRemoveItem(id);
  };
  return (
    <div className="filter-bar">
      {(items?.length || 0) > 0 && (
        <button
          className="caption--primary filter-bar__reset"
          type="button"
          onClick={onReset}
        >
          {t('video:filter.reset')}
        </button>
      )}
      <div
        className="input input__tag filter-bar__button"
        onClick={onClick}
        tabIndex={1}
      >
        {(items?.length || 0) > 0 ? (
          <Flipper flipKey={items?.map((i) => i?.id).join('')}>
            <FlexBox
              alignItems="center"
              flexWrap="wrap"
              className="filter-bar__items"
            >
              {items?.map(({ name, id, chipProps }) => (
                <Flipped flipId={id} key={id}>
                  {(flippedProps) => (
                    <Chip
                      key={id}
                      onInteraction={(e) => handleRemoveItem(id, e)}
                      interactionIcon="close"
                      {...chipProps}
                      {...flippedProps}
                    >
                      {name}
                    </Chip>
                  )}
                </Flipped>
              ))}
            </FlexBox>
          </Flipper>
        ) : (
          <span className="filter-bar__placeholder">
            {placeholder || t('video:filter.placeholder')}
          </span>
        )}

        <div className="filter-bar__icon">
          <Icon icon="filter" />
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
