import { format as formatDate, formatRelative, isDate, Locale } from 'date-fns';
import { de, enGB, enIN, fr, it, hi } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { defaultFallbackLanguage, languageWhiteList } from './global-constants';

export const registeredLocales = {
  de,
  'de-DE': de,
  en: enGB,
  'en-IN': enIN,
  enGB: enGB,
  it,
  fr,
  hi,
};

export const getLocale = (locale?: string | null): Locale => {
  const language = locale as RegisteredLocalesType;

  return registeredLocales[language] || registeredLocales.enGB;
};

registerLocale('de', de);
registerLocale('de-DE', de);
registerLocale('en', enGB);
registerLocale('en-IN', enIN);
registerLocale('it', it);
registerLocale('fr', fr);
registerLocale('hi', hi);
setDefaultLocale(defaultFallbackLanguage);

export type RegisteredLocalesType =
  | 'enGB'
  | 'de'
  | 'it'
  | 'en'
  | 'en-IN'
  | 'de-DE'
  | 'fr';

i18n
  .use(Backend)
  // Language detection is only used for the Login page, after login we will use the defaultLocale from the backend (see i18nSlice)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    nonExplicitSupportedLngs: true,
    // These are only used to overwrite sepcific country translations, the fallbacks should still be the main translation folder
    fallbackLng: {
      default: ['en'],
      'de-CH': ['fr'],
      'de-DE': ['de'],
      'en-IN': ['en'],
      hi: ['en-IN', 'en'],
      'hi-IN': ['en-IN', 'en'],
    },
    ns: [
      'channel',
      'edit',
      'list',
      'product',
      'settings',
      'statistics',
      'video',
      'translation',
      'orders',
      'common',
    ],
    debug: false,
    whitelist: languageWhiteList,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', //Path to the translation files
      addPath: '/locales/add/{{lng}}/{{ns}}',
    },
    defaultNS: 'translation',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        try {
          if (isDate(value)) {
            const locale = getLocale(lng);

            if (format === 'short') {
              return formatDate(value, 'P', { locale });
            }
            if (format === 'long') {
              return formatDate(value, 'Pp', { locale });
            }
            if (format === 'relative') {
              return formatRelative(value, new Date(), { locale });
            }

            return formatDate(value, format || '', { locale });
          }
          if (typeof value === 'number') {
            return new Intl.NumberFormat().format(value);
          }
        } catch (error) {
          console.log('i18next failed date format', error);
        }
        return value;
      },
    },
    detection: {
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
  });

export default i18n;
