/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternalOrderShipmentDto } from './InternalOrderShipmentDto';

export type InternalOrderShipmentProviderUpdateDto = {
    createdAt: string;
    updatedAt?: string;
    id: number;
    orderShipmentId: number;
    orderShipment?: InternalOrderShipmentDto | null;
    provider?: InternalOrderShipmentProviderUpdateDto.provider;
    providerOrderId?: number | null;
    providerShipmentId?: number | null;
    status: string;
    statusCode: number;
    type: InternalOrderShipmentProviderUpdateDto.type;
};

export namespace InternalOrderShipmentProviderUpdateDto {

    export enum provider {
        SHIP_ROCKET = 'shipRocket',
        MANUAL = 'Manual',
    }

    export enum type {
        DELIVERY = 'Delivery',
        RETURN = 'Return',
    }


}

