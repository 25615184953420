/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VideoItemDTO } from './VideoItemDTO';
import type { VideoPreviewDTO } from './VideoPreviewDTO';

export type VideoDTO = {
    type: VideoDTO.type;
    language: string;
    name: string;
    threeQFileId: number;
    meta?: string;
    videoOffset?: number;
    visibility?: VideoDTO.visibility;
    videoPreviews?: Array<VideoPreviewDTO>;
    videoItems?: Array<VideoItemDTO>;
    hashtagIds?: Array<number>;
};

export namespace VideoDTO {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }


}

