import classNames from 'classnames';
import SelectInteractive, {
  DefaultOption,
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive/';
import { LocaleDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import LanguageControl from './compontens/LanguageControl';
import './language-select.scss';

export type Props<IsMulti extends boolean = false> = Omit<
  SelectInteractiveProps<DefaultOption, IsMulti>,
  'options'
> & {
  controlAppearance?: 'default' | 'chip';
  languages?: LocaleDTO[];
};

/** Interactive Select that takes languages instead of options and populates them into the Select.
 * It can be used with a default Interactive Select Control or a Chip showing the language in short form.
 */
const LanguageSelect = <isMulti extends boolean = false>({
  controlAppearance,
  languages,
  ...props
}: Props<isMulti>) => {
  const { t } = useTranslation(['translation', 'video']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const getOptionFromLanguage = ({ iso }: LocaleDTO) => ({
    value: iso,
    label: t(`language.${iso?.substring(0, 2)}`),
  });

  return (
    <SelectInteractive
      className={classNames(
        'language-select',
        controlAppearance === 'chip' && 'language-select--chip',
      )}
      noOptionsMessage={t('video:language.empty')}
      title={t('video:language.label')}
      label={t('video:language.label')}
      components={{
        Control:
          controlAppearance === 'chip' && isDesktop
            ? LanguageControl
            : components.Control,
        IndicatorsContainer:
          controlAppearance === 'chip'
            ? () => null
            : components.IndicatorsContainer,
      }}
      {...props}
      options={languages?.map((i) => getOptionFromLanguage(i))}
      isSearchable={false}
      formatValue={(value) =>
        controlAppearance === 'chip'
          ? value.value?.substring(0, 2)
          : value.label
      }
    />
  );
};

export default LanguageSelect;
