import classNames from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import './status.scss';
import { Color, ColorUse } from 'types/Color';

export interface Props {
  status: string;
  tooltip: string;
  className?: string;
  big?: boolean;
  color?: ColorUse | Color;
}

const Status = ({
  status,
  tooltip,
  big,
  className,
  color = ColorUse.confirm,
}: Props) => {
  return (
    <Tooltip
      content={tooltip}
      direction={'up'}
      className={classNames(
        'status',
        big && 'status--big',
        color && `status--${color}`,
        className,
      )}
    >
      <span className="status__marker"></span>
      <span className="status__text">{status}</span>
    </Tooltip>
  );
};

export default Status;
