import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { ElementType } from 'react';
import './steps.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
  stepKey: string;
}

/** Animates the children mount and unmount by a changing stepKey.
 * @param {string} stepKey - Should represent the key that identifies the active Steps child, should change between unique string keys
 * @param children - You need to render the active child yourself, so the Steps component can control the render between state transitions
 */
const Steps = ({
  stepKey,
  children,
  tag: Tag = 'div',
  className,
  ...props
}: Props) => {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={stepKey}
        initial="initial"
        animate="in"
        variants={{
          initial: {
            opacity: 0,
            translateX: '2rem',
          },
          in: {
            opacity: 1,
            translateX: 0,
          },
          out: {
            opacity: 0,
            translateX: '-2rem',
          },
        }}
        transition={{
          type: 'tween',
          ease: 'linear',
          duration: 0.25,
        }}
        className="steps__wrap"
      >
        <Tag {...props} className={classNames('steps', className)}>
          <div className="steps__child">{children}</div>
        </Tag>
      </motion.div>
    </AnimatePresence>
  );
};

export default Steps;
