import React from 'react';
import classNames from 'classnames';
import { Flipped, Flipper } from 'react-flip-toolkit';
import './page-collapse.scss';

const PageCollapse = ({
  children,
  open,
}: {
  children: React.ReactNode;
  open?: boolean;
}) => {
  return (
    <Flipper flipKey={open}>
      <Flipped flipId="pageCollapse" scale>
        <div
          className={classNames('page-collapse', open && 'page-collapse--open')}
        >
          <Flipped inverseFlipId="pageCollapse" scale>
            <div className="page-collapse__inner">{children}</div>
          </Flipped>
        </div>
      </Flipped>
    </Flipper>
  );
};

export default PageCollapse;
