import ActivateToggle from 'components/ActivateToggle/ActivateToggle';
import Button from 'components/Button/Button';
import Chip from 'components/Chip/Chip';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Headline from 'components/Headline/Headline';
import IconButton from 'components/IconButton/IconButton';
import Input from 'components/Input/Input';
import { UtmMediumResponseDTO, UtmSourceResponseDTO } from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import useOpen from 'hooks/useOpen';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import {
  addUtmMedium,
  addUtmSource,
  deleteUtmMedium,
  deleteUtmSource,
  selectUtmMediums,
  selectUtmSources,
  updateUtmSource,
} from 'redux/slices/utm/utmSlice';
import { ColorUse } from 'types/Color';
import './utm.scss';

const Utm = () => {
  const [activeSource, setActiveSource] = useState<UtmSourceResponseDTO>();
  const [activeMediums, setActiveMediums] = useState<number[]>([]);
  const [newSource, setNewSource] = useState<string>('');
  const [newMedium, setNewMedium] = useState<string>('');
  const { isOpen: edit, toggle } = useOpen(false);
  const { t } = useTranslation(['translation', 'settings']);
  const [deleteSourceModal, setDeleteSourceModal] =
    useState<UtmSourceResponseDTO>();
  const [deleteMediumModal, setDeleteMediumModal] =
    useState<UtmMediumResponseDTO>();
  const { setAppStatus } = useAppStatus();
  const mediums = useReduxSelector(selectUtmMediums);
  const sources = useReduxSelector(selectUtmSources);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (activeSource == null) {
      setActiveMediums([]);
    } else {
      setActiveMediums(activeSource.mediums.map((medium) => medium.id));
    }
  }, [activeSource]);

  const handleSourceClick = (source: UtmSourceResponseDTO) => {
    setActiveSource((prev) => {
      if (prev?.id === source.id) {
        return undefined;
      }

      return source;
    });
  };

  const updateMediums = async (medium: UtmMediumResponseDTO) => {
    if (!activeSource) {
      return;
    }

    const newMediumIds = activeMediums.includes(medium.id)
      ? activeMediums.filter((m) => m !== medium.id)
      : [...activeMediums, medium.id];

    setActiveMediums(newMediumIds);

    dispatch(updateUtmSource({ sourceId: activeSource.id, newMediumIds }));
  };

  const handleMediumClick = async (medium: UtmMediumResponseDTO) => {
    if (edit) {
      setDeleteMediumModal(medium);
    } else {
      updateMediums(medium);
    }
  };

  const handleNewSourceSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (newSource == null) {
      return;
    }

    dispatch(addUtmSource(newSource));

    setNewSource('');
  };

  const handleNewMediumSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (newMedium == null) {
      return;
    }

    dispatch(addUtmMedium(newMedium));

    setNewMedium('');
  };

  const handleRemoveSource = async (id: number) => {
    try {
      await dispatch(deleteUtmSource(id)).unwrap();

      setAppStatus(t('settings:success.remove_utm_source'), 'success', true);
    } catch (error) {
      setAppStatus(t('settings:error.remove_utm_source'), 'error', true);
    }

    setDeleteSourceModal(undefined);
  };

  const handleRemoveMedium = async (id: number) => {
    try {
      await dispatch(deleteUtmMedium(id)).unwrap();

      setAppStatus(t('settings:success.remove_utm_medium'), 'success', true);
    } catch (error) {
      console.error(error);
      setAppStatus(t('settings:error.remove_utm_medium'), 'error', true);
    }

    setDeleteMediumModal(undefined);
  };

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.utm')}
      </Headline>

      <div className="utm__container">
        <div className="utm__sources">
          <Headline headingLevel="h3" size={3} className="utm__headline">
            {t('settings:headlines.utm_sources')}
          </Headline>

          <ul className="utm__list utm__list--sources">
            {sources.map((source) => (
              <li
                className="utm__list-item utm__list-item--sources"
                key={source.id}
              >
                <Button
                  disableFocus
                  text={source.name}
                  onClick={() => handleSourceClick(source)}
                  appearance={
                    source.id === activeSource?.id ? 'filled' : 'ghost'
                  }
                />

                {edit && (
                  <div className="utm__icon-button-wrap">
                    <IconButton
                      big
                      onClick={() => setDeleteSourceModal(source)}
                      icon="remove-filled"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>

          {edit && (
            <form onSubmit={handleNewSourceSubmit} className="utm__add-form">
              <Input
                name="newSource"
                label="new source"
                value={newSource}
                onChange={(e) => {
                  setNewSource(e.target.value);
                }}
              />

              <div className="utm__icon-button-wrap">
                <IconButton
                  big
                  onClick={handleNewSourceSubmit}
                  icon="add-filled"
                  disabled={newSource === ''}
                />
              </div>
            </form>
          )}
        </div>

        <div>
          <Headline headingLevel="h3" size={3} className="utm__headline">
            {t('settings:headlines.utm_mediums')}
          </Headline>

          <ul className="utm__list utm__list--mediums">
            {mediums.map((medium) => (
              <li
                className="utm__list-item utm__list-item--mediums"
                key={medium.id}
              >
                <Chip
                  disabled={activeSource == null}
                  onInteraction={
                    edit ? () => handleMediumClick(medium) : undefined
                  }
                  color={
                    activeMediums.includes(medium.id)
                      ? ColorUse['secondary']
                      : ColorUse['secondary-25']
                  }
                >
                  {medium.name}
                </Chip>
              </li>
            ))}
          </ul>

          {edit && (
            <form onSubmit={handleNewMediumSubmit} className="utm__add-form">
              <Input
                name="newMedium"
                label="new medium"
                value={newMedium}
                onChange={(e) => {
                  setNewMedium(e.target.value);
                }}
              />

              <div className="utm__icon-button-wrap">
                <IconButton
                  big
                  onClick={handleNewMediumSubmit}
                  icon="add-filled"
                  disabled={newMedium === ''}
                />
              </div>
            </form>
          )}
        </div>

        <div className="utm__edit-toggle">
          <label id="toggle-desc" className="input__label">
            {t('edit')}
          </label>

          <ActivateToggle
            active={edit}
            onToggle={toggle}
            ariaDescribedBy="#toggle-desc"
            activeIcon="edit"
            inactiveIcon="edit"
          />
        </div>
      </div>

      {deleteSourceModal && (
        <ConfirmModal
          isOpen={true}
          confirmText={t('settings:delete')}
          headline={t('delete')}
          text={t('settings:really_delete_utm_source', {
            name: deleteSourceModal.name,
          })}
          onCancelClick={() => setDeleteSourceModal(undefined)}
          onConfirmClick={() => handleRemoveSource(deleteSourceModal.id)}
        />
      )}

      {deleteMediumModal && (
        <ConfirmModal
          isOpen={true}
          confirmText={t('settings:delete')}
          headline={t('delete')}
          text={t('settings:really_delete_utm_medium', {
            name: deleteMediumModal.name,
          })}
          onCancelClick={() => setDeleteMediumModal(undefined)}
          onConfirmClick={() => handleRemoveMedium(deleteMediumModal.id)}
        />
      )}
    </div>
  );
};

export default Utm;
