// fix for: clickevents get blocked by onblur elements on form elements, so the form submit only works on second click
export const onSubmitDontBlur = (
  event: React.FocusEvent<
    | (HTMLInputElement & HTMLTextAreaElement)
    | HTMLSelectElement
    | HTMLTextAreaElement
    | HTMLInputElement
  >,
  onBlur: (e: React.FocusEvent<any>) => void,
) => {
  const isSubmitButton =
    event.relatedTarget &&
    (event.relatedTarget as HTMLDivElement).getAttribute('type') === 'submit';

  if (!isSubmitButton) {
    onBlur(event);
  }
};

export default onSubmitDontBlur;
