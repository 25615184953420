import { add } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Input from '../../../components/Input/Input';
import Modal from '../../../components/Modal/Modal';
import Select from '../../../components/Select/Select';
import {
  ChannelMinimalResponseDTO,
  LocaleDTO,
  NotificationDTO,
  VendorResponseDTO,
  VendorsService,
} from '../../../generated';
import {
  NotificationDraft,
  NotificationDraftType,
} from '../../../redux/slices/notifications/types';
import Color from '../../../types/Color';
import { InputProps } from '../../../types/InputType';
import './add-notification-form.scss';

const AddNotificationForm = ({
  onSubmit,
  supportedLanguages,
  draft,
  vendors,
  onSendTest,
  t,
  isSubmitLoading,
}: {
  isSubmitLoading: boolean;
  t: TFunction<string[]>;
  vendors: VendorResponseDTO[];
  draft: NotificationDraft;
  supportedLanguages: LocaleDTO[];
  onSubmit: (values: NotificationDraft) => void;
  onSendTest: (token: string, notification: NotificationDraft) => void;
}) => {
  const [vendorChannels, setVendorChannels] =
    useState<ChannelMinimalResponseDTO[]>();
  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [testId, setTestId] = useState('');

  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required(t('form.required')),
    body: Yup.string().trim().required(t('form.required')),
    analyticsLabel: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_.~%]{1,50}$/,
        t('form.specialCharacters', { chars: '- _ . ~ %' }),
      )
      .trim(),
    imageUrl: Yup.string().trim(),
    platforms: Yup.array().min(1, ({ min }) =>
      t('form.checkboxes-min', { min }),
    ),
    language: Yup.string().required(t('form.required')),
    optionKey: Yup.string().nullable(),
    optionValue: Yup.string()
      .nullable()
      .when('optionKey', {
        is: (optionKey: string | null) => optionKey != null,
        then: Yup.string().required(t('form.required')),
      }),
    date: Yup.string(),
  });

  const addNotificationForm = useFormik<NotificationDraft>({
    initialValues: draft,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
  });

  useEffect(() => {
    if (addNotificationForm.values.vendorId) {
      VendorsService.getVendorChannelsMinimal(
        parseInt(addNotificationForm.values.vendorId),
      ).then((channels) => {
        setVendorChannels(channels);
      });
    }
  }, [addNotificationForm.values.vendorId]);

  const titleInputProps: InputProps = {
    label: t(`settings:labels.notifications-title`),
    placeholder: t(`settings:placeholder-text`),
    error:
      addNotificationForm.touched.title && addNotificationForm.errors.title
        ? t(addNotificationForm.errors.title)
        : undefined,
    ...addNotificationForm.getFieldProps('title'),
  };

  const bodyInputProps: InputProps = {
    label: t(`settings:labels.notifications-body`),
    placeholder: t(`settings:placeholder-text`),
    error:
      addNotificationForm.touched.body && addNotificationForm.errors.body
        ? t(addNotificationForm.errors.body)
        : undefined,
    ...addNotificationForm.getFieldProps('body'),
  };

  const analyticsLabelInputProps: InputProps = {
    label: t(`settings:labels.notifications-analyticsLabel`),
    placeholder: t(`settings:placeholder-text`),
    error:
      addNotificationForm.touched.analyticsLabel &&
      addNotificationForm.errors.analyticsLabel
        ? t(addNotificationForm.errors.analyticsLabel)
        : undefined,
    ...addNotificationForm.getFieldProps('analyticsLabel'),
  };

  const imageUrlInputProps: InputProps = {
    label: t(`settings:labels.notifications-imageUrl`),
    placeholder: t(`settings:placeholder-text`),
    error:
      addNotificationForm.touched.imageUrl &&
      addNotificationForm.errors.imageUrl
        ? t(addNotificationForm.errors.imageUrl)
        : undefined,
    ...addNotificationForm.getFieldProps('imageUrl'),
  };

  const iosInputProps: InputProps = {
    label: t(`settings:labels.notifications-ios`),
    ...addNotificationForm.getFieldProps('platforms'),
    value: 'ios',
    checked: addNotificationForm.values.platforms.includes('ios'),
  };

  const androidInputProps: InputProps = {
    label: t(`settings:labels.notifications-android`),
    ...addNotificationForm.getFieldProps('platforms'),
    value: 'android',
    checked: addNotificationForm.values.platforms.includes('android'),
  };

  const optionKeyInputProps: InputProps = {
    label: t(`settings:labels.notifications-optionKey`),
    error:
      addNotificationForm.touched.optionKey &&
      addNotificationForm.errors.optionKey
        ? t(addNotificationForm.errors.optionKey)
        : undefined,
    ...addNotificationForm.getFieldProps('optionKey'),
  };

  const optionValueInputProps: InputProps = {
    label: t(`settings:labels.notifications-optionValue`),
    error:
      addNotificationForm.touched.optionValue &&
      addNotificationForm.errors.optionValue
        ? t(addNotificationForm.errors.optionValue)
        : undefined,
    ...addNotificationForm.getFieldProps('optionValue'),
  };

  const dateInputProps: InputProps = {
    label: t(`settings:labels.notifications-date`),
    error:
      addNotificationForm.touched.date && addNotificationForm.errors.date
        ? t(addNotificationForm.errors.date)
        : undefined,
    ...addNotificationForm.getFieldProps('date'),
    placeholder: t(`settings:placeholders.notifications-date`),
  };

  const vendorIdInputProps: InputProps = {
    label: t(`settings:labels.notifications-vendorId`),
    error:
      addNotificationForm.touched.vendorId &&
      addNotificationForm.errors.vendorId
        ? t(addNotificationForm.errors.vendorId)
        : undefined,
    ...addNotificationForm.getFieldProps('vendorId'),
  };

  const languageInputProps: InputProps = {
    label: t(`settings:labels.notifications-language`),
    ...addNotificationForm.getFieldProps('language'),
  };

  const submitText = {
    [NotificationDraftType.NEW]: t('settings:notifications.add'),
    [NotificationDraftType.EDIT]: t('settings:edit'),
    [NotificationDraftType.COPY]: t('settings:copy'),
  };

  const handleVendorChange = (e: React.ChangeEvent<any>) => {
    addNotificationForm.setFieldValue('optionValue', '');

    vendorIdInputProps.onChange(e);
  };

  const renderOptionValueInput = () => {
    switch (addNotificationForm.values.optionKey) {
      case NotificationDTO.optionKey.CHANNEL_KEY:
        return (
          <div className="settings__block settings__block--inline">
            <div style={{ flex: 1 }}>
              <Select
                enableEmptyOption
                emptyOptionText={t('no-selection')}
                {...vendorIdInputProps}
                onChange={handleVendorChange}
                options={vendors.map((vendor) => ({
                  text: vendor.company,
                  value: vendor.id.toString(),
                }))}
              />
            </div>

            <div style={{ flex: 1 }}>
              {vendorChannels && (
                <Select
                  enableEmptyOption
                  emptyOptionText={t('no-selection')}
                  options={vendorChannels.map((channel) => ({
                    text: channel.name,
                    value: channel.key,
                  }))}
                  {...optionValueInputProps}
                  label={t('settings:labels.notifications-channelkey')}
                />
              )}
            </div>
          </div>
        );
      case NotificationDTO.optionKey.LINK:
      case NotificationDTO.optionKey.SCP:
      case NotificationDTO.optionKey.URL:
        return (
          <div className="settings__block">
            <Input {...optionValueInputProps} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleOptionKeyChange = (e: React.ChangeEvent<any>) => {
    addNotificationForm.setFieldValue('optionValue', '');
    addNotificationForm.setFieldValue('vendorId', '');

    optionKeyInputProps.onChange(e);
  };

  const optionKeyOptionTexts = {
    [NotificationDTO.optionKey.CHANNEL_KEY]: t(
      'settings:notifications.optionKeys.channelKey',
    ),
    [NotificationDTO.optionKey.LINK]: t(
      'settings:notifications.optionKeys.link',
    ),
    [NotificationDTO.optionKey.SCP]: t('settings:notifications.optionKeys.scp'),
    [NotificationDTO.optionKey.URL]: t('settings:notifications.optionKeys.url'),
  };

  const handleSendTest = () => {
    if (!testId) {
      return;
    }

    onSendTest(testId, addNotificationForm.values);
    setTestId('');
    setIsTestModalOpen(false);
  };

  const handleCancelTest = () => {
    setTestId('');
    setIsTestModalOpen(false);
  };

  return (
    <div className="add-notification-form">
      <p className="settings__text settings__block">
        {t('settings:notifications.create-notification-text')}
      </p>

      <form onSubmit={addNotificationForm.handleSubmit}>
        <div className="settings__block">
          <Input {...titleInputProps} maxLength={128} />
        </div>

        <div className="settings__block">
          <Input {...analyticsLabelInputProps} maxLength={50} />
        </div>

        <div className="settings__block">
          <Input
            className="add-notification-form__textarea"
            type="textarea"
            {...bodyInputProps}
            maxLength={256}
          />
        </div>

        <div className="settings__block">
          <Input {...imageUrlInputProps} maxLength={256} />
        </div>

        <div className="settings__block">
          <DatePicker
            {...dateInputProps}
            minDate={new Date()}
            onChange={(name, value) =>
              addNotificationForm.setFieldValue(name, value)
            }
            placeholderText={dateInputProps.placeholder}
            maxDate={add(new Date(), { years: 10 })}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            isClearable
          />

          <p className="add-notification-form__date-info-text">
            {t('settings:notifications.date-info-text')}
          </p>
        </div>

        <div className="settings__block">
          <label id="notifications-target" className="input__label">
            {t('settings:labels.notifications-target')}
          </label>

          <Checkbox
            {...iosInputProps}
            onChange={(checked, id, event) => iosInputProps.onChange(event)}
            label={iosInputProps.label}
            labelId="notifications-target"
          />

          <Checkbox
            {...androidInputProps}
            onChange={(checked, id, event) => androidInputProps.onChange(event)}
            label={androidInputProps.label}
            labelId="notifications-target"
          />

          {addNotificationForm.touched.platforms &&
          addNotificationForm.errors.platforms ? (
            <div role="alert" className="input__error">
              {t(addNotificationForm.errors.platforms)}
            </div>
          ) : undefined}
        </div>

        <div className="settings__block">
          <Select
            enableEmptyOption
            emptyOptionText={t('no-selection')}
            options={Object.values(NotificationDTO.optionKey).map((value) => ({
              text: optionKeyOptionTexts[value],
              value,
            }))}
            {...optionKeyInputProps}
            onChange={handleOptionKeyChange}
          />
        </div>

        {renderOptionValueInput()}

        <div className="settings__block">
          <Select
            options={Object.values(supportedLanguages).map((value) => ({
              text: value.iso,
              value: value.iso,
            }))}
            {...languageInputProps}
          />
        </div>

        <div className="add-notification-form__action-buttons-container">
          <Button
            type="button"
            text={t('settings:test')}
            disabled={!addNotificationForm.isValid}
            onClick={() => setIsTestModalOpen(true)}
          />

          <Button
            type="submit"
            text={submitText[draft.type]}
            disabled={!addNotificationForm.isValid || isSubmitLoading}
            loading={isSubmitLoading}
          />
        </div>
      </form>

      <Modal
        variant="wide"
        isOpen={isTestModalOpen}
        onClose={handleCancelTest}
        headline={t('settings:headlines.tetst-notification')}
      >
        <p className="settings__text settings__block">
          {t('settings:notifications.tetst-notification-text')}
        </p>

        <div className="settings__block">
          <Input
            name="testId"
            placeholder={t('settings:notifications.testId-placeholder')}
            label={t('settings:labels.notifications-testId')}
            value={testId}
            onChange={(e) => setTestId(e.target.value)}
          />
        </div>

        <div className="add-notification-form__action-buttons-container">
          <Button
            type="button"
            text={t('settings:cancel')}
            onClick={handleCancelTest}
            color={Color.primary}
          />

          <Button
            type="button"
            text={t('settings:test')}
            disabled={!testId || isSubmitLoading}
            onClick={handleSendTest}
            loading={isSubmitLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddNotificationForm;
