import {
  ApiError,
  CreateShipmentParametersDto,
  VendorOrderShipmentDto,
} from 'generated';
import {
  CreateShipmentMutationVariables,
  useCreateShipment,
  useGetShipmentManifests,
} from '../queries';
import { MutateOptions } from '@tanstack/react-query';
import { queryClient } from 'index';
import { orderDetailKeys } from 'features/order-detail';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import { useEffect, useState } from 'react';
import { ShipmentStep } from '../Shipping/components/CreateShipment';
import { ExtendedVendorOrderDto } from 'features/order-detail/queries';

const useCreateCompleteShipment = ({
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<ShipmentStep>>;
}) => {
  const createShipmentMutation = useCreateShipment();
  const {
    isLoading: createShipmentIsLoading,
    data: createShipmentData,
    isError,
  } = createShipmentMutation;
  const useGetShipmentManifestsMutation = useGetShipmentManifests();
  const { data: manifestData, isLoading: manifestIsLoading } =
    useGetShipmentManifestsMutation;
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(createShipmentIsLoading || manifestIsLoading);
  }, [manifestIsLoading, createShipmentIsLoading]);

  const handleMutation = (
    variables: CreateShipmentMutationVariables,
    options?: MutateOptions<
      VendorOrderShipmentDto,
      ApiError,
      CreateShipmentMutationVariables
    >,
  ) => {
    createShipmentMutation.mutate(variables, {
      ...options,
      onSuccess: (data, vars, context) => {
        options?.onSuccess?.(data, vars, context);

        useGetShipmentManifestsMutation.mutate(
          {
            orderNumbers: [vars.orderNo],
          },
          {
            onSettled: (manifestData) => {
              // We have to transfer the response data to the downloads step, so the Shipping Component does not rerender too early because of the change of the order action state.
              setStep({
                key: 'downloads',
                data: {
                  ...data.order,
                  manifestLink: manifestData?.manifestLink ?? null,
                } as ExtendedVendorOrderDto,
              });
            },
            // error handling will happen later, by checking if a manifestLink is present.
          },
        );
      },
    });
  };
  return {
    mutate: handleMutation,
    manifestData,
    createShipmentData,
    isLoading,
    isError,
  };
};

export default useCreateCompleteShipment;
