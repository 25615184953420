import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Search from '../../../components/Search/Search';
import Select, { Option } from '../../../components/Select/Select';
import { useReduxSelector } from '../../../redux/hooks';
import {
  selectUtmMediums,
  selectUtmSources,
} from '../../../redux/slices/utm/utmSlice';

export interface Props {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  selectSource: string;
  setSelectSource: Dispatch<SetStateAction<string>>;
  selectMedium: string;
  setSelectMedium: Dispatch<SetStateAction<string>>;
}

const RefLinksFilter = ({
  searchTerm,
  setSearchTerm,
  selectSource,
  setSelectSource,
  selectMedium,
  setSelectMedium,
}: Props) => {
  const { t } = useTranslation(['settings']);
  const sources = useReduxSelector(selectUtmSources);
  const [selectSourceOptions, setSelectSourceOptions] = useState<
    Option[] | undefined
  >(
    sources
      .map((item) => ({
        value: item.name,
        text: item.name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  );

  const mediums = useReduxSelector(selectUtmMediums);
  const [selectMediumOptions, setSelectMediumOptions] = useState<
    Option[] | undefined
  >(
    mediums
      .map((item) => ({
        value: item.name,
        text: item.name,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  );

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleFilterChange = (filter: 'source' | 'medium', value: string) => {
    if (filter === 'source') {
      setSelectSource(value);
    }

    if (filter === 'medium') {
      setSelectMedium(value);
    }
  };

  useEffect(() => {
    if (selectSource !== '') {
      const mediumsInSources = sources.find(
        (i) => i.name === selectSource,
      )?.mediums;
      setSelectMediumOptions(
        mediumsInSources
          ?.map((i) => ({ value: i.name, text: i.name }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      );
    } else {
      setSelectMediumOptions(
        mediums
          .map((i) => ({ value: i.name, text: i.name }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      );
    }
  }, [selectSource]);

  useEffect(() => {
    if (selectSource === '' && selectMedium !== '' && selectMedium) {
      const sourceOptions = sources.filter((i) => {
        const names = i.mediums.map((i) => i.name);
        return names.includes(selectMedium);
      });
      setSelectSourceOptions(
        sourceOptions
          ?.map((i) => ({ value: i.name, text: i.name }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      );
    } else {
      setSelectSourceOptions(
        sources
          .map((i) => ({ value: i.name, text: i.name }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      );
    }
  }, [selectMedium]);

  useEffect(() => {
    setSelectSourceOptions(
      sources
        .map((item) => ({
          value: item.name,
          text: item.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    );
  }, [sources]);

  useEffect(() => {
    setSelectMediumOptions(
      mediums
        .map((i) => ({ value: i.name, text: i.name }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    );
  }, [mediums]);

  return (
    <div className="settings__block settings__block--inline">
      <Search
        searchTerm={searchTerm}
        onChange={handleSearchTermChange}
        placeholder={t('settings:ref-link.searchPlaceholder')}
        className="settings__block--50"
      />

      <Select
        options={selectSourceOptions}
        appearance="filter"
        onChange={(evt) => handleFilterChange('source', evt.target.value)}
        value={selectSource}
        emptyOptionText={t('settings:ref-link.sourceAll')}
        onReset={() => setSelectSource('')}
        wrapperClassName="settings__block--25"
      />

      <Select
        options={selectMediumOptions}
        appearance="filter"
        onChange={(evt) => handleFilterChange('medium', evt.target.value)}
        value={selectMedium}
        emptyOptionText={t('settings:ref-link.mediumAll')}
        onReset={() => setSelectMedium('')}
        wrapperClassName="settings__block--25"
      />
    </div>
  );
};

export default RefLinksFilter;
