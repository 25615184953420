import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { io, Socket } from 'socket.io-client';
import { useReduxSelector } from '../redux/hooks';
import {
  selectIsLoggedIn,
  selectVendorAgent,
} from '../redux/slices/auth/authSlice';

export enum SocketNamespace {
  STATUS_UPDATE = 'status-update',
}

type SocketState = Map<SocketNamespace, Socket>;

const defaultState: SocketState = new Map();

const SocketsContext = createContext<SocketState>(defaultState);

export const SocketProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [socketMap, setSocketMap] = useState(defaultState);
  const apiBaseUrl = useReduxSelector((state) => state.global.apiBaseUrl);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const isLoggedIn = useReduxSelector(selectIsLoggedIn);
  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (!isLoggedIn) {
      // socketMap.forEach((socket) => socket.disconnect());
      setSocketMap(new Map());
      return;
    }
    if (!cookies.token) {
      setSocketMap(new Map());
      return;
    }
    if (!apiBaseUrl) {
      console.log('Cant init socket. No apiBaseUrl');
      return () => {
        socketMap.forEach((socket) => socket.disconnect());
      };
    }
    if (!vendorAgent) {
      console.log('Cant init socket. No vendorAgent');
      return () => {
        socketMap.forEach((socket) => socket.disconnect());
      };
    }
    const token = cookies.token;
    Object.values(SocketNamespace).forEach((namespace) => {
      if (!socketMap.has(namespace)) {
        const socket = io(`${apiBaseUrl}/${namespace}`, {
          path: '/socket.io',
          transports: ['websocket'],
          auth: {
            token,
          },
        });
        socket.on('connect_error', (error) => {
          console.log(error.message);
        });
        setSocketMap(new Map(socketMap.set(namespace, socket)));
      }
    });
    return () => {
      socketMap.forEach((socket) => socket.disconnect());
    };
  }, [isLoggedIn, apiBaseUrl, vendorAgent, cookies]);

  return (
    <SocketsContext.Provider value={socketMap}>
      {children}
    </SocketsContext.Provider>
  );
};

export const useSockets = (namespace: SocketNamespace) => {
  const socketState = useContext(SocketsContext);
  return socketState.get(namespace);
};

export default SocketsContext;
