import OrderActionChip from 'components/OrderActionChip/OrderActionChip';
import { DefaultOption, Option } from 'components/SelectInteractive';
import { AppOrderStatusDto } from 'generated';
import { GroupBase, OptionProps } from 'react-select';
import './order-status-action-option.scss';

export interface OrderStatusActionOptionType
  extends Omit<DefaultOption, 'value'> {
  value: AppOrderStatusDto.action;
}

export const OrderStatusActionOption = <
  OptionType extends OrderStatusActionOptionType,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, Group>,
) => {
  const { data } = optionProps;

  return (
    <Option
      centerZone={
        <OrderActionChip
          status={data.value}
          label={data.label}
          showActionIcon={false}
        />
      }
      className="order-status-action-option"
      {...optionProps}
    />
  );
};

export default OrderStatusActionOption;
