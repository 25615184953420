import Chip from 'components/Chip/Chip';
import Headline from 'components/Headline/Headline';
import { Spacer } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import './help.scss';
import i18n from 'i18n';
import i18next from 'i18next';

const Help = () => {
  const { t } = useTranslation();

  const showPhone = () => {
    const languages = ['fr', 'en-IN', 'hi', 'hi-IN'];
    return languages.includes(i18next.language);
  };

  return (
    <Spacer className="bg-secondary-50 help" padding={5}>
      <Spacer marginBottom={3}>
        <Headline headingLevel="h2" size={3}>
          {t('loginHint.headline')}
        </Headline>
      </Spacer>
      <Spacer tag="p" className="small">
        {t('loginHint.text')}
      </Spacer>
      <Spacer tag="p" className="small" marginBottom={2} marginTop={2}>
        {t('loginHint.contactHeadline')}
      </Spacer>

      {i18n.exists('loginHint.tel', { lng: i18n.language }) && showPhone() && (
        <Spacer>
          <Chip icon="phone">
            <a href={`tel:${t('loginHint.tel')}`}>{t('loginHint.tel')}</a>
          </Chip>
        </Spacer>
      )}
      <Spacer>
        <Chip icon="mail">
          <a href={`mailto:${t('loginHint.mail')}`}>{t('loginHint.mail')}</a>
        </Chip>
      </Spacer>
    </Spacer>
  );
};

export default Help;
