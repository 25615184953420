import IconLink from 'components/IconLink/IconLink';
import { Spacer } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';

const VideoDetailProductControls = ({
  onUnlink,
}: {
  onUnlink?: () => void;
}) => {
  const { t } = useTranslation(['translation', 'product']);

  return (
    <>
      <Spacer className="video-detail__product-controls-item">
        <IconLink
          icon="change"
          tooltip={
            onUnlink
              ? t('product:footer.change-desc')
              : t('product:footer.add-desc')
          }
          to="change"
        >
          {onUnlink ? t('product:footer.change') : t('product:footer.add')}
        </IconLink>
      </Spacer>
      {onUnlink && (
        <Spacer className="video-detail__product-controls-item">
          <IconLink
            icon="remove-filled"
            tooltip={t('product:footer.remove-desc')}
            onClick={onUnlink}
          >
            {t('product:footer.remove')}
          </IconLink>
        </Spacer>
      )}
    </>
  );
};

export default VideoDetailProductControls;
