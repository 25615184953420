import classNames from 'classnames';
import { Validation } from '../../components/ImageUploader/ImageUploader';
import ImageUploadContainer from '../../container/ImageUploadContainer/ImageUploadContainer';
import './image-input.scss';

type Props = {
  key?: string;
  onChange: (paths: { fileUrl: string; filePath: string }) => void;
  label?: string;
  placeholder: string;
  value?: string;
  description?: string;
  disabled?: boolean;
  error?: string;
  hiddenLabel?: boolean;
  className?: string;
} & Validation;

const ImageInput = ({
  key,
  onChange,
  label,
  placeholder,
  value,
  error,
  className,
  ...rest
}: Props) => {
  const handleDelete = () => {
    onChange({ fileUrl: '', filePath: '' });
  };

  return (
    <div className={classNames('image-input', className)}>
      {label && (
        <label
          className={classNames({
            'image-input__label': true,
            'image-input__label--disabled': rest.disabled,
            input__label: true,
          })}
        >
          {label}
        </label>
      )}

      <ImageUploadContainer
        key={key}
        onChange={onChange}
        onDelete={handleDelete}
        text={placeholder}
        value={value}
        {...rest}
      />

      {error && (
        <div role="alert" className="input__error image-input__error">
          {error}
        </div>
      )}
    </div>
  );
};

export default ImageInput;
