import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { components, GroupBase, MenuProps } from 'react-select';
import { DefaultOption } from 'components/SelectInteractive/components/Option';

export const VendorSelectMenu = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  props: MenuProps<OptionType, IsMulti, Group> & {
    showSalesVendors: boolean;
    showDefaultVendors: boolean;
    handleSalesVendorCheckboxChange: () => void;
    handleDefaultVendorCheckboxChange: () => void;
    salesVendorLabel: string;
    defaultVendorLabel: string;
  },
) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  return (
    <components.Menu {...props}>
      <div className="select-interactive__menu-header">
        {props.selectProps.title && isDesktop && (
          <div className="caption--primary select-interactive__title">
            {props.selectProps.title}
          </div>
        )}

        <div className="vendor-select-menu__checkboxes">
          <label className="vendor-select-menu__checkbox">
            <input
              type="checkbox"
              checked={props.showSalesVendors}
              name="sales"
              onChange={() => props.handleSalesVendorCheckboxChange()}
            />
            {props.salesVendorLabel}
          </label>

          <label className="vendor-select-menu__checkbox">
            <input
              type="checkbox"
              checked={props.showDefaultVendors}
              name="default"
              onChange={() => props.handleDefaultVendorCheckboxChange()}
            />
            {props.defaultVendorLabel}
          </label>
        </div>
      </div>

      <div className="select-interactive__drop">{props.children}</div>
    </components.Menu>
  );
};

export default VendorSelectMenu;
