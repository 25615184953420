/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalVendorAgentDto = {
    id: number;
    vendorId: number;
    email: string;
    name: string;
    role: InternalVendorAgentDto.role;
};

export namespace InternalVendorAgentDto {

    export enum role {
        SALES_AGENT = 'sales_agent',
        SALES_ADMIN = 'sales_admin',
        REPORTER = 'reporter',
        ADMIN = 'admin',
        SUPER_ADMIN = 'super_admin',
    }


}

