import TermsViewer from '../../features/Settings/TermsViewer/TermsViewer';
import UpdatePassword from '../../features/Settings/UpdatePassword/UpdatePassword';
import UpdateVendorAgent from '../../features/Settings/UpdateVendorAgent/UpdateVendorAgent';

const SettingsProfilTab = () => {
  return (
    <>
      <div className="form__block form__block--noborder">
        <UpdateVendorAgent />
      </div>

      <div className="form__block">
        <UpdatePassword />
      </div>

      <div className="form__block">
        <TermsViewer />
      </div>
    </>
  );
};

export default SettingsProfilTab;
