import { useMutation } from '@tanstack/react-query';
import { videoDetailKeys } from 'features/video-detail/queries';
import { videoListKeys } from 'features/video-list';
import {
  ApiError,
  ChannelMinimalResponseDTO,
  VideoResponseDTO,
  VideosService,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export type Variables = {
  videoId: number;
  channels?: ChannelMinimalResponseDTO[];
  force?: boolean;
};

export const useUpdateVideoChannels = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<VideoResponseDTO, ApiError, Variables>({
    mutationFn: ({ videoId, channels, force }: Variables) =>
      VideosService.addVideoToChannels(videoId, {
        channelIds: channels?.map((c) => c.id) ?? [],
        force,
      }),
    onSuccess: (data, { videoId }) => {
      queryClient.setQueryData(
        videoDetailKeys.getVideo(videoId, vendorAgent?.currentVendor.id),
        data,
      );

      queryClient.invalidateQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      );
    },
    onError: (error, variables, context) => {
      if (error.status !== 409) {
        queryClient.setQueryData<VideoResponseDTO>(
          videoDetailKeys.getVideo(
            variables.videoId,
            vendorAgent?.currentVendor.id,
          ),
          (context as { previousVideo: VideoResponseDTO })?.previousVideo,
        );
        queryClient.invalidateQueries(
          videoListKeys.getVideos(vendorAgent?.currentVendor.id),
        );
      }
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor.id),
      );

      const previousVideo = queryClient.getQueryData(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor.id,
        ),
      );

      queryClient.setQueryData<VideoResponseDTO>(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor.id,
        ),
        (old?: VideoResponseDTO) => {
          if (old) {
            return {
              ...old,
              channels: variables.channels ? variables.channels : undefined,
            };
          }
        },
      );

      return { previousVideo };
    },
  });
};
