import { useTranslation } from 'react-i18next';
import channelHowTo from '../../images/channel-empty.png';
import Color from '../../types/Color';
import Button, { Props as ButtonProps } from '../Button/Button';
import './howto.scss';
import classNames from 'classnames';

export interface Props {
  image?: string;
  text?: string;
  sub?: string;
  button?: Partial<ButtonProps>;
  hideButton?: boolean;
  className?: string;
}

const HowTo = ({
  image = channelHowTo,
  text,
  button,
  sub,
  hideButton,
  className,
}: Props) => {
  const { t } = useTranslation(['channel']);
  const buttonProps: ButtonProps = Object.assign(
    {
      color: Color.primary,
      text: t('channel:newChannel'),
      icon: 'add',
    },
    button,
  );
  return (
    <div className={classNames('howto', className)}>
      <div className="howto__inner">
        <div className="howto__image">
          <img
            loading="lazy"
            src={image}
            alt="How To"
            width="312"
            height="198"
          />
        </div>
        <div className="howto__text">
          <span className="howto__maintext">{text}</span>
          <span className="howto__subtext">{sub}</span>
        </div>
        <div className="howto__button">
          {!hideButton && <Button {...buttonProps} />}
        </div>
      </div>
    </div>
  );
};

export default HowTo;
