import classNames from 'classnames';
import { isEqual, isAfter, isBefore } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import DatePickerLib, { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from '../../redux/hooks';
import {
  selectTimezone,
  selectLocale,
} from '../../redux/slices/i18n/i18nSlice';
import randomString from '../../utils/random-string';
import 'react-datepicker/dist/react-datepicker.css';
import '../Input/input.scss';
import './date-picker.scss';

export type DatePickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
  onChange: (name: string, value?: Date) => void;
  name: string;
  label?: string;
  error?: string;
  id?: string;
  value: string | Date;
};

const DatePicker = ({
  name,
  label,
  id: propId,
  error,
  onChange,
  value,
  ...props
}: DatePickerProps) => {
  const { t } = useTranslation();
  const timeZone = useReduxSelector(selectTimezone);
  const locale = useReduxSelector(selectLocale);

  const id = propId || randomString();
  const labelId = `label--${id}`;
  const errorId = error && `error--${id}`;

  const attributes = {
    'aria-invalid': error ? true : undefined,
    'aria-labelledby': labelId,
    'aria-errormessage': errorId,
    wrapperClassName: 'date-picker__wrap',
    className: 'input__tag date-picker__input',
    id,
    dateFormat: props.showTimeSelect ? 'Pp' : 'P',
    ...props,
  };

  // Fixes coloring bug that the active day + range is shown in every month
  const activeClass = (dateValue: Date) => {
    const date = utcToZonedTime(dateValue, timeZone);
    const valueDate = value && utcToZonedTime(value, timeZone);
    const endDate = props?.endDate && utcToZonedTime(props?.endDate, timeZone);
    const startDate =
      props?.startDate && utcToZonedTime(props?.startDate, timeZone);

    const activeClass =
      valueDate && isEqual(date, valueDate) ? 'date-picker__active' : undefined;

    const startActiveClass =
      startDate && isEqual(date, startDate)
        ? 'date-picker__active--start'
        : undefined;

    const endActiveClass =
      endDate && isEqual(date, endDate)
        ? 'date-picker__active--end'
        : undefined;

    const rangeColor =
      startDate &&
      endDate &&
      isAfter(date, startDate) &&
      isBefore(date, endDate)
        ? 'date-picker__range'
        : undefined;

    return classNames(
      rangeColor,
      startActiveClass,
      endActiveClass,
      activeClass,
    );
  };

  const handleChange = (
    val: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ): void => {
    if (val) {
      console.log('val', val);
      const utc = zonedTimeToUtc(val, timeZone);
      onChange(name, utc);
    } else {
      onChange(name, undefined);
    }
  };

  const getValue = () => {
    if (typeof value === 'string') {
      return zonedTimeToUtc(value, timeZone);
    }
    return value;
  };

  return (
    <div
      className={classNames(
        'input input--msg-top date-picker',
        error && 'input--error',
        props?.disabled && 'input--disabled',
        props?.className,
      )}
    >
      {label && (
        <label className="input__label date-picker__label" htmlFor={id}>
          {label}
        </label>
      )}
      <DatePickerLib
        {...attributes}
        onChange={handleChange}
        locale={locale}
        timeCaption={t('time')}
        autoComplete="off"
        dayClassName={activeClass}
        selected={getValue()}
        disabledKeyboardNavigation
        showPopperArrow={false}
      />
      {error && (
        <div role="alert" id={errorId} className="input__error">
          {error}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
