import { DefaultOption } from 'components/SelectInteractive';
import {
  ProductTaxDto,
  VendorAddressDto,
  VendorOrderShipmentProductPackageDimensionDto,
} from 'generated';
import { Worldzone } from 'redux/slices/i18n/i18nSlice';

export const packagingNotComplete = (
  packaging?: VendorOrderShipmentProductPackageDimensionDto,
) => {
  const { productId, ...dimensions } = packaging || {};
  return Object.values(dimensions).every((value) => value === null);
};

export const packagingEmpty = (
  packaging?: VendorOrderShipmentProductPackageDimensionDto,
) => {
  const { productId, ...dimensions } = packaging || {};
  return Object.values(dimensions).every(
    (value) => value === null || value === 0 || !value,
  );
};

export const getReqiredAddressFields = (
  address?: VendorAddressDto,
): Partial<VendorAddressDto> => {
  return {
    address1: address?.address1,
    zip: address?.zip,
    city: address?.city,
    country: address?.city,
    firstName: address?.firstName,
    lastName: address?.lastName,
    gstin: address?.gstin,
    company: address?.company,
  };
};
export const addressNotComplete = (address?: VendorAddressDto) =>
  !address ||
  Object.values(getReqiredAddressFields(address)).some((field) => !field);

export const taxesEmpty = (taxes?: Partial<ProductTaxDto>) => {
  const { hsn, rate } = taxes || {};
  return Object.values({ hsn, rate }).every(
    (value) => value === 0 || value == null || !value,
  );
};

export const taxesNotComplete = (taxes?: ProductTaxDto) => {
  const { hsn, rate } = taxes || {};
  return Object.keys({ hsn, rate }).some(
    (key) => taxes?.[key] === 0 || taxes?.[key] == null || !taxes?.[key],
  );
};

export const getTaxOptions = (
  locale: string = 'in-IN',
  worldzone: Worldzone = 'in',
): DefaultOption[] => {
  const option = {
    style: 'percent',
    maximumFractionDigits: 2,
  };

  const formatter = new Intl.NumberFormat(locale, option);
  const zoneValues = {
    in: [
      { value: '0', label: formatter.format(0) },
      { value: '0.25', label: formatter.format(0.0025) },
      { value: '1', label: formatter.format(0.01) },
      { value: '1.5', label: formatter.format(0.015) },
      { value: '2', label: formatter.format(0.02) },
      { value: '3', label: formatter.format(0.03) },
      { value: '5', label: formatter.format(0.05) },
      { value: '6', label: formatter.format(0.06) },
      { value: '12', label: formatter.format(0.12) },
      { value: '18', label: formatter.format(0.18) },
      { value: '28', label: formatter.format(0.28) },
    ],
    eu: [
      { value: '7', label: formatter.format(0.07) },
      { value: '19', label: formatter.format(0.19) },
    ],
  };
  return zoneValues[worldzone];
};
