import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import LinkCode from 'components/LinkCode/LinkCode';
import LinkListItem from 'components/LinkListItem/LinkListItem';
import List from 'components/List/List';
import Loader from 'components/Loader/Loader';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { AnimatedRoute } from 'features/animated-routing';
import { isAvailable } from 'features/availability';
import { useGetVideo } from 'features/video-detail';
import {
  ChannelMinimalResponseDTO,
  CreateSharingUrlDTO,
  SharingUrlDTO,
  VideoResponseDTO,
} from 'generated';
import { useGetVendorAgents } from 'hooks/queries';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ShareAdd from '../ShareAdd/ShareAdd';
import {
  useDeleteSharingToken,
  useGetSharingLinks,
  useUpdateSharingToken,
} from '../queries';

type Sorted = {
  [key: number]: {
    items: SharingUrlDTO[];
    channel: ChannelMinimalResponseDTO;
  };
};

const SortedItem = ({
  items,
  channel,
  videoId,
}: {
  items: SharingUrlDTO[];
  channel: ChannelMinimalResponseDTO;
  videoId: number;
}) => {
  const { vendorAgents } = useGetVendorAgents();
  const { name, availability } = channel;
  const updateSharingTokenMutation = useUpdateSharingToken(videoId);
  const deleteSharingTokenMutation = useDeleteSharingToken(videoId);
  const { t } = useTranslation();
  const statusProps = isAvailable(availability)
    ? VideoResponseDTO.status.LIVE
    : VideoResponseDTO.status.IDLE;
  const getVendorAgentById = (id?: number) =>
    vendorAgents?.find((agent) => agent.id === id);

  return (
    <Spacer marginTop={6}>
      <FlexBox
        alignItems="center"
        marginBottom={2}
        className="caption caption--dark"
      >
        <FlexItem marginRight={1}>
          <VideoStatusIcon status={statusProps} />
        </FlexItem>
        <FlexItem>{name}</FlexItem>
      </FlexBox>

      {!isAvailable(availability) && (
        <Spacer tag="p" className="small" marginBottom={2}>
          {t('video:share.inactive')}
        </Spacer>
      )}

      {items.map((share) => (
        <LinkListItem
          key={share.id}
          userName={
            getVendorAgentById(
              share.referenceType ===
                CreateSharingUrlDTO.sharingType.VENDOR_AGENT &&
                share.referenceId
                ? share.referenceId
                : undefined,
            )?.name
          }
          onDelete={() =>
            deleteSharingTokenMutation.mutate({
              sharingTokenId: share.id,
            })
          }
          onEditSave={(name: string) => {
            updateSharingTokenMutation.mutate({
              sharingTokenId: share.id,
              name,
            });
          }}
          title={share.name || t('no_title')}
        >
          <LinkCode
            link={share.url}
            copyToClipBoard
            className={!isAvailable(availability) ? 'bg-dark-20' : ''}
          />
        </LinkListItem>
      ))}
    </Spacer>
  );
};

const ShareContainer = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation();
  const { data: video } = useGetVideo(videoId);
  const getSharingLinksQuery = useGetSharingLinks(videoId);
  const { data, error, isLoading } = getSharingLinksQuery;
  const [sortedLinks, setSortedLinks] = React.useState<Sorted>({});

  useEffect(() => {
    if (data) {
      const sort: Sorted = {};

      for (const item of data) {
        const { channel } = item;
        if (sort[channel.id]) {
          sort[channel.id].items.push({ ...item, channel });
        } else {
          sort[channel.id] = {
            items: [{ ...item, channel }],
            channel,
          };
        }
      }
      setSortedLinks(sort);
    }
  }, [data]);

  return (
    <AnimatedRoute>
      <section className="video-detail__child video-share">
        <LabelText label={`${video?.name}`}>
          {t('video:share.msg-default')}
        </LabelText>

        {(data?.length === 0 || error) && (
          <Spacer marginTop={4}>
            <p className="small small--dark-50">{t('video:share.empty')}</p>
          </Spacer>
        )}

        {isLoading && (
          <Spacer marginTop={4}>
            <Loader />
          </Spacer>
        )}

        {(data?.length || 0) > 0 && (
          <Spacer marginTop={12}>
            <List loading={getSharingLinksQuery.isLoading}>
              {Object.values(sortedLinks || {}).map((item) => (
                <SortedItem
                  key={item.channel.id}
                  items={item.items}
                  channel={item.channel}
                  videoId={videoId}
                />
              ))}
            </List>
          </Spacer>
        )}

        <Spacer margin={[14, 0, 4]} paddingTop={4} borderTop={1}>
          <ShareAdd channels={video?.channels} videoId={videoId} />
        </Spacer>
      </section>
    </AnimatedRoute>
  );
};

export default ShareContainer;
