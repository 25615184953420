import { FieldProps, getIn } from 'formik';
import { FC } from 'react';
import { onSubmitDontBlur } from '../../utils/on-submit-dont-blur';
import Input, { Props } from './Input';

const InputField: FC<Props & FieldProps & { validateOnChange?: boolean }> = ({
  field,
  form: { errors, touched },
  // this needs to be excluded from props so it doesn't get passed to the input DOM Element
  validateOnChange,
  ...props
}) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);

  return (
    <Input
      {...field}
      {...props}
      error={tou && err ? err : undefined}
      onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
    />
  );
};

export default InputField;
