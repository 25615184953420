import classNames from 'classnames';
import Icon, { IconType } from 'components/Icon/Icon';
import { FlexItem } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionMeta,
  GroupBase,
  InputAction,
  InputActionMeta,
  OnChangeValue,
} from 'react-select';
import {
  OptionWithIconProps,
  OptionWithNumberAndIconProps,
  ReactSelectProps,
  useMultiSelectSort,
} from '..';
import { Props } from '../SelectInteractive';
import OpenSelect from './OpenSelect';
import { DefaultOption } from './Option';

const MobileSelect = <
  OptionType extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, Group>,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { options } = useMultiSelectSort<OptionType, Group>({
    options: props.options,
    value: props.value,
  });
  const scrollId = props.infiniteScrollTargetId || `scroll--${props.id}`;

  const getSelectText = () => {
    if (!props.isMulti) {
      return (props.value as DefaultOption)?.label
        ? props.formatValue
          ? props.formatValue(props.value as OptionType)
          : (props.value as DefaultOption).label
        : props.placeholder;
    }
    return props.placeholder;
  };

  const getSelectIcon = () => {
    const icon = (props.value as OptionWithIconProps)?.icon;
    if (!props.isMulti && icon) {
      return typeof icon === 'string' ? (
        <span className="select-interactive__fake-value-icon">
          <Icon icon={icon as IconType} />
        </span>
      ) : (
        <span className="select-interactive__fake-value-icon">{icon}</span>
      );
    }
  };

  const getSelectNumber = () => {
    const num = (props.value as OptionWithNumberAndIconProps)?.number;
    if (num && !props.isMulti) {
      return (
        <span className="select-interactive__number">
          {Number(num).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </span>
      );
    }
  };

  const handleChange = (
    newValue: OnChangeValue<OptionType, IsMulti>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    props.onChange?.(newValue, actionMeta);
    if (!props.isMulti) {
      setIsOpen(false);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const mobileProps: ReactSelectProps<OptionType, IsMulti, Group> = {
    ...props,
    hint: props.error ? props.error : props.hint,
    options: props.isMulti ? options : props.options,
    onChange: handleChange,
    menuShouldScrollIntoView: false,
    infiniteScrollTargetId: scrollId,
  };
  return (
    <div className="select__wrap">
      <button
        type="button"
        className={classNames(
          'select__select',
          'select-interactive__fake-select',
          props.className,
        )}
        onClick={handleOpen}
        disabled={props.isDisabled}
      >
        {getSelectIcon()}
        <span className="select-interactive__fake-text">{getSelectText()}</span>
        {getSelectNumber()}
        {props.isLoading && (
          <FlexItem pushRight marginRight={2}>
            <Loader small />
          </FlexItem>
        )}
      </button>
      <Modal
        headline={props.title || t('select')}
        variant="bottom-sheet"
        appearance={props.appearance}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          props.onInputChange?.('', {
            action: {} as InputAction,
            prevInputValue: '',
          } as InputActionMeta);
        }}
        scrollTargetId={scrollId}
        className="select-interactive__modal"
      >
        <OpenSelect {...mobileProps} />
      </Modal>
    </div>
  );
};

export default MobileSelect;
