import { useTranslation } from 'react-i18next';
import { VideoResponseDTO } from '../../generated';
import placeholder from '../../images/placeholder-video.svg';
import { useReduxSelector } from '../../redux/hooks';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import InfoVideoList from '../InfoVideoList/InfoVideoList';
import ListItem from '../ListItem/ListItem';

const ProductVideoList = ({ videos }: { videos: VideoResponseDTO[] }) => {
  const { t } = useTranslation(['translation', 'product', 'video']);
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  return (
    <InfoVideoList
      label={t('product:video')}
      videos={videos}
      renderItem={(video) => (
        <ListItem
          key={video.id}
          image={video.videoPreviews?.find((i) => i.size === 'small')?.uri}
          title={video.name}
          subtitle={
            !supportedLanguages?.some((i) => i.iso === video.language)
              ? t('video:languageNotSupported.badge')
              : undefined
          }
          language={video.language}
          border={true}
          placeholder={placeholder}
          languageNotSupportedError={
            !supportedLanguages?.some((i) => i.iso === video.language)
          }
        />
      )}
    />
  );
};

export default ProductVideoList;
