import Headline from 'components/Headline/Headline';
import { FlexItem, Spacer } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { ShipmentComponentProps } from './CreateShipment';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import InputField from 'components/Input/InputField';
import Button from 'components/Button/Button';
import { useCreateVendorAddress } from 'features/vendor-address';
import { VendorAddressDto } from 'generated';
import { useReduxSelector } from 'redux/hooks';
import { selectWorldzone } from 'redux/slices/i18n/i18nSlice';
import { ObjectShape } from 'yup/lib/object';
import ZipCodeField from 'features/zip-code/ZipCodeField/ZipCodeField';

export type Values = Omit<VendorAddressDto, 'defaultInvoice' | 'defaultPickup'>;

const CreateShipmentAddress = ({
  address,
  setStep,
}: ShipmentComponentProps) => {
  const { t } = useTranslation(['translation', 'orders']);
  const createVendorAddressMutation = useCreateVendorAddress();
  const [initialValues, setInitialValues] = useState<Values>({
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    zip: address?.zip || '',
    city: address?.city || '',
    province: address?.province || '',
    provinceCode: address?.provinceCode || '',
    country: address?.country || '',
    countryCode: address?.countryCode || '',
    countryName: address?.countryName || '',
    email: address?.email || '',
    phone: address?.phone || '',
    gstin: address?.gstin || '',
    company: address?.company || '',
  });

  const worldzone = useReduxSelector(selectWorldzone);

  useEffect(() => {
    setInitialValues((pre) => ({
      ...pre,
      ...address,
      email: address?.email || '',
      phone: address?.phone || '',
      gstin: address?.gstin || '',
    }));
  }, [address]);

  const handleSubmit = (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    const query: VendorAddressDto = {
      ...values,
      city: values.city,
      countryName: values.country,
      countryCode: values.countryCode,
      province: values.province,
      provinceCode: values.provinceCode,
      email: values.email?.trim() || undefined,
      phone: values.phone?.trim() || undefined,
      gstin: values.gstin,
      defaultInvoice: true,
      defaultPickup: true,
    };

    createVendorAddressMutation.mutate(query, {
      onSettled() {
        formikHelpers.resetForm({ values });
        setInitialValues(values);
      },
      onSuccess() {
        setStep({ key: 'start', data: null });
      },
    });
  };

  const validateGstin = (value?: string) =>
    /^[0-9]{2}[A-Z]{3}[ABCFGHLJPT][A-Z][0-9]{4}[A-Z][1-9A-Z]Z[0-9A-Z]$/.test(
      value || '',
    );

  // for india a special location validation from the ZipCodeField will be used
  const locationValidation =
    worldzone === 'in'
      ? {}
      : ({
          zip: Yup.string().required(t('translation:form.required')),
          city: Yup.string().required(t('translation:form.required')),
          province: Yup.string().required(t('translation:form.required')),
          country: Yup.string().required(t('translation:form.required')),
        } as ObjectShape);

  const nameSchema = Yup.object().shape({
    firstName: Yup.string().required(t('translation:form.required')),
    lastName: Yup.string().required(t('translation:form.required')),
    company: Yup.string().required(t('translation:form.required')),
    address1: Yup.string().required(t('translation:form.required')),
    email: Yup.string().email(t('form.email')).trim(),
    gstin: Yup.string()
      .required(t('translation:form.required'))
      .test(
        'Valid GSTIN',
        t('orders:detail.shipping.gstn.invalid'),
        validateGstin,
      ),
    ...locationValidation,
  });

  return (
    <>
      <Headline
        headingLevel="h2"
        size={2}
        className="order-detail__box-headline"
      >
        {t('orders:detail.shipping.createShipment.address.title')}
      </Headline>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form noValidate>
            <Spacer marginTop={5}>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="firstName"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.address.firstName',
                  )}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="lastName"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.address.lastName',
                  )}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="company"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.address.company',
                  )}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="gstin"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.address.gstin',
                  )}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="address1"
                  component={InputField}
                  required
                  label={`${t(
                    'orders:detail.shipping.createShipment.address.address1',
                  )}*`}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="address2"
                  component={InputField}
                  label={t(
                    'orders:detail.shipping.createShipment.address.address2',
                  )}
                />
              </Spacer>

              {worldzone === 'in' ? (
                <ZipCodeField />
              ) : (
                <>
                  <Spacer marginBottom={4}>
                    <Field
                      messagePosition="top"
                      name="zip"
                      component={InputField}
                      required
                      label={`${t(
                        'orders:detail.shipping.createShipment.address.zip',
                      )}*`}
                    />
                  </Spacer>
                  <Spacer marginBottom={4}>
                    <Field
                      messagePosition="top"
                      name="city"
                      component={InputField}
                      required
                      label={`${t(
                        'orders:detail.shipping.createShipment.address.city',
                      )}*`}
                    />
                  </Spacer>
                  <Spacer marginBottom={4}>
                    <Field
                      messagePosition="top"
                      name="province"
                      component={InputField}
                      required
                      label={`${t(
                        'orders:detail.shipping.createShipment.address.province',
                      )}*`}
                    />
                  </Spacer>
                  <Spacer marginBottom={4}>
                    <Field
                      messagePosition="top"
                      name="country"
                      component={InputField}
                      required
                      label={`${t(
                        'orders:detail.shipping.createShipment.address.country',
                      )}*`}
                    />
                  </Spacer>
                </>
              )}
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="email"
                  component={InputField}
                  label={t(
                    'orders:detail.shipping.createShipment.address.email',
                  )}
                />
              </Spacer>
              <Spacer marginBottom={4}>
                <Field
                  messagePosition="top"
                  name="phone"
                  component={InputField}
                  label={t(
                    'orders:detail.shipping.createShipment.address.phone',
                  )}
                />
              </Spacer>
            </Spacer>
            <FlexItem display="flex" pushBottom justifyContent="space-between">
              <Button
                appearance="ghost"
                text={t('orders:detail.shipping.createShipment.address.abort')}
                onClick={() => setStep({ key: 'start', data: null })}
              />
              <Button
                text={t('orders:detail.shipping.createShipment.address.save')}
                type="submit"
              />
            </FlexItem>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateShipmentAddress;
