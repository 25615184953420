import React from 'react';
import { GroupBase, OptionProps } from 'react-select';
import Option, { DefaultOption } from './Option';
import { Icon as IconType } from '../../Icon/icon-spec';
import Icon from '../../Icon/Icon';

export interface OptionWithIconProps extends DefaultOption {
  icon?: IconType | React.ReactNode;
}

export const OptionWithIcon = <
  OptionType extends OptionWithIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, Group>,
) => {
  const { data } = optionProps;
  return (
    <Option
      leftZone={
        data.icon && (
          <span className="select-interactive__option-icon">
            {typeof data.icon === 'string' ? (
              <Icon icon={data.icon as IconType} />
            ) : (
              data.icon
            )}
          </span>
        )
      }
      {...optionProps}
    />
  );
};

export default OptionWithIcon;
