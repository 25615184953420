import classNames from 'classnames';
import { FlexBox, Spacer } from 'components/Layout';
import { useGetProductTaxes } from 'features/product-taxes';
import { TotalPricingDto } from 'generated';
import { BREAKPOINTS, localeCurrency } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import {
  selectLanguageCode,
  selectLocaleIso,
} from 'redux/slices/i18n/i18nSlice';

const Pricing = ({
  pricing,
  productId,
}: {
  pricing?: TotalPricingDto;
  productId?: number;
}) => {
  const { t } = useTranslation(['common', 'orders']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { data: taxes } = useGetProductTaxes(productId || 0);
  const languageCode = useReduxSelector(selectLanguageCode);
  const localeIso = useReduxSelector(selectLocaleIso);

  const getPriceWithTaxes = () => {
    try {
      if (taxes?.rate) {
        const amount = (pricing?.totalGrossPrice ?? 0) * (1 - taxes.rate / 100);
        return new Intl.NumberFormat(languageCode, {
          style: 'currency',
          currency: localeCurrency[localeIso.toLocaleLowerCase()] || 'INR',
        }).format(amount / 100);
      }

      return pricing?.totalGrossPriceLocalized;
    } catch (error) {
      return pricing?.totalGrossPriceLocalized;
    }
  };
  const getDiscountPriceWithTaxes = () => {
    try {
      if (taxes?.rate) {
        const amount =
          (pricing?.totalGrossDiscountPrice ?? 0) * (1 - taxes.rate / 100);
        return new Intl.NumberFormat(languageCode, {
          style: 'currency',
          currency: localeCurrency[localeIso.toLocaleLowerCase()] || 'INR',
        }).format(amount / 100);
      }

      return pricing?.totalGrossDiscountPriceLocalized;
    } catch (error) {
      return pricing?.totalGrossDiscountPriceLocalized;
    }
  };

  const getTaxesFromPrices = () => {
    try {
      if (taxes?.rate) {
        const price = pricing?.includesItemsWithDiscountPrice
          ? pricing.totalGrossDiscountPrice
          : pricing?.totalGrossPrice;
        const amount = (price ?? 0) - (price ?? 0) / (taxes.rate / 100 + 1);
        return new Intl.NumberFormat(languageCode, {
          style: 'currency',
          currency: localeCurrency[localeIso.toLocaleLowerCase()] || 'INR',
        }).format(amount / 100);
      }

      return pricing?.totalGrossPriceLocalized;
    } catch (error) {
      return pricing?.totalGrossPriceLocalized;
    }
  };

  const getDiscountFromGrossPrice = () => {
    try {
      const amount =
        (pricing?.totalGrossDiscountPrice ?? 0) -
        (pricing?.totalGrossPrice ?? 0);
      return new Intl.NumberFormat(languageCode, {
        style: 'currency',
        currency: localeCurrency[localeIso.toLocaleLowerCase()] || 'INR',
      }).format(amount / 100);
    } catch (error) {
      return 0;
    }
  };

  return (
    <div>
      {(pricing?.includesItemsWithDiscountPrice || taxes?.rate) && (
        <Spacer tag="dl" marginTop={isDesktop ? 8 : 4}>
          <FlexBox
            className="order-detail__paylist"
            justifyContent="space-between"
            marginTop={isDesktop ? 8 : 4}
          >
            <dt className="order-detail__paylist-label">
              {t('orders:detail.product.pay.gross')}
            </dt>
            <dd className="order-detail__paylist-price">
              {pricing?.totalGrossPriceLocalized}
            </dd>
          </FlexBox>
          {pricing?.includesItemsWithDiscountPrice && (
            <FlexBox
              className="order-detail__paylist"
              justifyContent="space-between"
              marginTop={isDesktop ? 3 : 2}
            >
              <dt className="order-detail__paylist-label">
                {t('orders:detail.product.pay.discountPrice')}
              </dt>
              <dd className="order-detail__paylist-price">
                {getDiscountFromGrossPrice()}
              </dd>
            </FlexBox>
          )}
        </Spacer>
      )}
      <FlexBox
        tag="dl"
        className="order-detail__paylist"
        justifyContent="space-between"
        alignItems="center"
        borderTop={pricing?.includesItemsWithDiscountPrice || isDesktop ? 1 : 0}
        paddingTop={
          pricing?.includesItemsWithDiscountPrice || isDesktop
            ? isDesktop
              ? 3
              : 2
            : 0
        }
        marginTop={
          pricing?.includesItemsWithDiscountPrice || isDesktop
            ? isDesktop
              ? 5
              : 2
            : 0
        }
      >
        <dt className="order-detail__paylist-label">
          {t('orders:detail.product.pay.total')}
        </dt>
        <dd className="order-detail__paylist-price order-detail__paylist-price--total">
          {pricing?.includesItemsWithDiscountPrice
            ? pricing.totalGrossDiscountPriceLocalized
            : pricing?.totalGrossPriceLocalized}
        </dd>
      </FlexBox>
      {taxes?.rate && (
        <FlexBox
          className="order-detail__paylist"
          justifyContent="space-between"
          marginTop={isDesktop ? 3 : 2}
        >
          <dt className="order-detail__paylist-label">
            {t('orders:detail.product.pay.inclusive')}{' '}
            {t('orders:detail.product.pay.taxes')} {taxes.rate}%
          </dt>
          <dd className="order-detail__paylist-price">
            {getTaxesFromPrices()}
          </dd>
        </FlexBox>
      )}
    </div>
  );
};

export default Pricing;
