/* @flow */
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import { FormikErrors } from 'formik';
import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  WheelEvent,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import './input.scss';
import Icon from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout';

export interface Props
  extends Omit<
    DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement> &
        TextareaHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>,
      HTMLInputElement & HTMLTextAreaElement
    >,
    'ref' | 'error'
  > {
  type?: string;
  descriptionId?: string;
  className?: string;
  wrapperClassName?: string;
  name: string;
  id?: string;
  label?: string;
  hiddenLabel?: boolean;
  big?: boolean;
  error?: string;
  success?: string;
  appearance?: 'light' | 'dark';
  loading?: boolean;
  /** Where to show error or success message */
  messagePosition?: 'bottom' | 'top';
  /** Renders a clear button if the value is not empty */
  onClear?: () => void;
}

type Style = Omit<React.CSSProperties, 'maxHeight' | 'minHeight'> & {
  height?: number | undefined;
};

export const Input = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>(
  (
    {
      type = 'text',
      descriptionId,
      error,
      className,
      wrapperClassName,
      name,
      id,
      label,
      hiddenLabel,
      big,
      disabled,
      style,
      success,
      appearance,
      loading,
      messagePosition,
      onClear,
      ...rest
    },
    ref,
  ) => {
    const inputId = id || name;
    const labelId = `label--${inputId}`;
    const errorId = error && `error--${inputId}`;
    const attributes = {
      'aria-invalid': error ? true : undefined,
      'aria-describedby': descriptionId,
      'aria-labelledby': labelId,
      'aria-errormessage': errorId,
      type,
      name,
      id: inputId,
      placeholder: rest.placeholder,
      value: rest.value,
      onChange: rest.onChange,
      disabled,
      ...rest,
    };
    const inputClassNames = classNames(
      {
        input__tag: true,
        'input__tag--textarea': type === 'textarea',
        'input__tag--big': big,
      },
      className,
    );

    const handleWheelScroll = (
      e: WheelEvent<HTMLInputElement & HTMLTextAreaElement>,
    ) => {
      if (type === 'number') {
        // disable changing number value by scroll
        const target = e.target as HTMLInputElement;
        target.blur();
      }
    };

    return (
      <div
        className={classNames(
          'input',
          error && 'input--error',
          disabled && 'input--disabled',
          success && 'input--success',
          appearance && `input--${appearance}`,
          messagePosition && `input--msg-${messagePosition}`,
          loading && 'input--loading',
          wrapperClassName,
        )}
      >
        <div className="input__label-wrap">
          {label && (
            <label
              className={classNames({
                input__label: true,
                'hidden-visually': hiddenLabel,
              })}
              htmlFor={inputId}
              id={labelId}
            >
              {label}
            </label>
          )}
          {rest.maxLength && typeof rest.value !== 'number' && (
            <span
              className={classNames({
                'input__max-length': true,
                'input__max-length--max': rest.value?.length === rest.maxLength,
              })}
            >
              {rest.value?.length || 0}/{rest.maxLength}
            </span>
          )}
        </div>
        {type !== 'textarea' ? (
          <input
            {...attributes}
            className={inputClassNames}
            onWheel={handleWheelScroll}
            style={style}
            ref={ref}
          />
        ) : (
          <TextareaAutosize
            {...attributes}
            ref={ref}
            style={style as Style}
            className={inputClassNames}
          />
        )}
        {onClear && rest.value && !loading && (
          <FlexBox
            tag="button"
            type="button"
            className="input__clear"
            onClick={onClear}
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon="close" />
            <span className="hidden-visually">Clear</span>
          </FlexBox>
        )}
        {loading && (
          <div className="input__loader">
            <Loader small />
          </div>
        )}
        {error && (
          <div role="alert" id={errorId} className="input__error">
            {error}
          </div>
        )}
        {success && (
          <div role="alert" className="input__error input__success">
            {success}
          </div>
        )}
      </div>
    );
  },
);

export default Input;
