import Layout, { Section, Spacer } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer/Footer';
import Headline from '../components/Headline/Headline';
import SettingsContainer from '../container/SettingsContainer';
import '../features/Settings/settings.scss';
import PageType from '../types/PageType';

const SettingsPage = (props: PageType) => {
  const { t } = useTranslation(['translation', 'settings']);

  return (
    <Layout>
      <Section appearance="medium">
        <Spacer marginBottom={7} marginTop={7}>
          <Headline headingLevel="h1" size={2} className="page__title">
            {t('settings:headlines.title')}
          </Headline>
        </Spacer>
      </Section>

      <Section appearance="medium" style={{ flexGrow: 1 }}>
        <SettingsContainer />
        <Spacer marginTop={7}>
          <Footer />
        </Spacer>
      </Section>
    </Layout>
  );
};
export default SettingsPage;
