import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Spacer } from 'components/Layout/Layout';
import { VisibilitySelectField } from 'components/VisiblitySelect';
import { useUpdateVisibility } from 'features/visibility';
import { Field, getIn, useFormikContext } from 'formik';
import { VideoResponseDTO } from 'generated';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Props } from '../VideoUpdateForm';

const VideoUpdateVisibility = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFieldValue, values, initialValues } =
    useFormikContext<Omit<Props, 'id'>>();
  const visibilityMutation = useUpdateVisibility();
  const fieldName = 'visibility';

  const handleChange = (value: VideoResponseDTO.visibility) => {
    if (value) {
      visibilityMutation.mutate(
        { videoId, visibility: value },
        {
          onError: () => {
            setFieldValue(fieldName, getIn(initialValues, fieldName), true);
          },
          onSuccess: () => {
            // show success badge for 7s
            setTimeout(() => {
              visibilityMutation.reset();
            }, 7000);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (getIn(values, fieldName) !== getIn(initialValues, fieldName)) {
      visibilityMutation.reset();
      handleChange(getIn(values, fieldName));
    }
  }, [values]);

  return (
    <ErrorBoundary>
      <Spacer marginBottom={4}>
        <Field
          component={VisibilitySelectField}
          name={fieldName}
          disabled={visibilityMutation.isLoading}
          isLoading={visibilityMutation.isLoading}
          success={
            visibilityMutation.isSuccess ? t('inline-edit.success') : undefined
          }
          error={
            visibilityMutation.isError ? t('inline-edit.error') : undefined
          }
          messagePosition="top"
        />
      </Spacer>
    </ErrorBoundary>
  );
};

export default VideoUpdateVisibility;
