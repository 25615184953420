import { Grid, GridItem } from 'components/Layout';
import { ProductTaxResponseDto } from 'generated';
import { useTranslation } from 'react-i18next';
import './product-taxes.scss';
import { taxesEmpty } from 'features/order-shipment/utils';
import { useEffect, useState } from 'react';

const ProductTaxes = ({ hsn, rate }: Partial<ProductTaxResponseDto>) => {
  const { t } = useTranslation(['translation', 'product']);
  const [isEmpty, setIsEmpty] = useState(taxesEmpty({ hsn, rate }));

  useEffect(() => {
    setIsEmpty(taxesEmpty({ hsn, rate }));
  }, [hsn, rate]);

  if (isEmpty) {
    return (
      <span className="product-taxes product-taxes--empty">
        {t('product:taxes.notFound')}
      </span>
    );
  }
  return (
    <Grid tag="dl" className="product-taxes">
      <GridItem tag="dt">{t('product:taxes.rate')}:</GridItem>
      <GridItem tag="dd">{rate || 0} %</GridItem>
      <GridItem tag="dt">{t('product:taxes.hsn')}:</GridItem>
      <GridItem tag="dd">{hsn || t('product:taxes.hsnNotSet')}</GridItem>
    </Grid>
  );
};

export default ProductTaxes;
