import React from 'react';

const Imprint = () => {
  const thisYear = new Date().getFullYear();
  return (
    <div className="page__text">
      <p>
        jooli.com GmbH <br /> Erkelenzdamm 59/61 <br /> 10999 Berlin
      </p>
      <p>Geschäftsführer: Aykut Çevik, Dr. Riad Nourallah </p>
      <p>
        HRB 193357 B <br />
        Amtsgericht Charlottenburg (Berlin)
      </p>
      <p>© {thisYear} - jooli.com GmbH</p>
    </div>
  );
};

export default Imprint;
