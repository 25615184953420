/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalVendorAddressRequestDto = {
    /**
     * The full qualified country name. Example: USA
     */
    country: string;
    /**
     * The 2 digit iso code. Example: US
     */
    countryCode: string;
    /**
     * The normalized full qualified country name. Example: United States of America
     */
    countryName: string;
    /**
     * The state or province name. Example: Berlin
     */
    province: string;
    /**
     * The state or province code. Example: BE
     */
    provinceCode: string;
    /**
     * Valid email format is required.
     */
    email?: string;
    referenceType: InternalVendorAddressRequestDto.referenceType;
    /**
     * The internal id of the entities.
     */
    referenceId: number;
    firstName: string;
    lastName: string;
    company: string;
    gstin?: string | null;
    address1: string;
    address2: string;
    city: string;
    zip: string;
    phone?: string;
};

export namespace InternalVendorAddressRequestDto {

    export enum referenceType {
        VENDOR = 'vendor',
        APP_CUSTOMER = 'app_customer',
    }


}

