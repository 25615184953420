import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AddVendorFormValues } from '../../../features/Settings/AddVendor/AddVendorForm';
import {
  VendorDTO,
  VendorResponseDTO,
  VendorsService,
} from '../../../generated';
import { startAppListening } from '../../middlewares/listenerMiddleware';
import { init, logIn, selectIsSuperAdmin } from '../auth/authSlice';

type Status = 'not-fetched' | 'fetched';

const SLICE_NAME = 'vendors';
export interface VendorsState {
  vendors: Omit<VendorResponseDTO, 'bankingDetail'>[] | undefined;
  status: Status;
}

const initialState: VendorsState = {
  vendors: [],
  status: 'not-fetched',
};

export const vendorsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.vendors = action.payload.map(
          ({ bankingDetail, ...vendor }) => vendor,
        );
        state.status = 'fetched';
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        state.vendors?.push(action.payload);
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = vendorsSlice.actions;

export const fetchVendors = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await VendorsService.getVendors();

      return response.sort((a, b) => a.company.localeCompare(b.company));
    } catch (error) {
      throw rejectWithValue('error fetching vendors');
    }
  },
);

export const addVendor = createAsyncThunk(
  `${SLICE_NAME}/add`,
  async (
    { company, salesVendor, name, email }: AddVendorFormValues,
    { rejectWithValue },
  ) => {
    try {
      const response = await VendorsService.addVendor({
        vendor: {
          company,
          type: salesVendor ? VendorDTO.type.SALES : VendorDTO.type.DEFAULT,
        },
        vendorAgent: { name, email },
      });

      return response;
    } catch (error) {
      throw rejectWithValue('error adding vendor');
    }
  },
);

// Create the middleware instance and methods

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
startAppListening({
  matcher: isAnyOf(logIn.fulfilled, init.fulfilled),
  effect: async (action, { dispatch, getState }) => {
    const state = getState();
    const isSuperAdmin = selectIsSuperAdmin(state);
    const status = state.vendors.status;

    if (isSuperAdmin && status === 'not-fetched') {
      dispatch(fetchVendors());
    }
  },
});

export default vendorsSlice.reducer;
