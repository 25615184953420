/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetStatisticsQueryDTO = {
    channelId?: number;
    videoId?: number;
    start?: string | null;
    end?: string | null;
    searchTerm?: string;
    showDeleted?: boolean;
    showDeactivated?: boolean;
    visibility?: GetStatisticsQueryDTO.visibility;
    type?: GetStatisticsQueryDTO.type;
    showOnlyLive?: boolean;
    isLive?: boolean;
    inactive?: boolean;
};

export namespace GetStatisticsQueryDTO {

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }


}

