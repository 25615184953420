import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { VendorAgentsService } from '../../generated';
import useVerifyToken from '../../hooks/useVerifyToken';
import { useReduxDispatch } from '../../redux/hooks';
import { logOut } from '../../redux/slices/auth/authSlice';
import NewPasswordForm, { NewPasswordFormValues } from './NewPasswordForm';
import './new-password.scss';

const NewPassword = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const { tokenError } = useVerifyToken(token);
  const [done, setDone] = useState(false);

  useEffect(() => {
    dispatch(logOut());
  }, []);

  const handleSubmit = async ({ password }: NewPasswordFormValues) => {
    if (!token) {
      console.error('no token specified');
      return;
    }

    try {
      await VendorAgentsService.setNewPassword({
        newPassword: password,
        token,
      });
    } catch (error) {
    } finally {
      setDone(true);
    }
  };

  if (tokenError) {
    return (
      <div className="new-password">
        <p className="reset-password__failure-text icon">
          <Icon icon="info" />
          {t('newPassword.texts.tokenError')}
        </p>

        <div className="reset-password__submit-btn">
          <NavLink to="/reset-password">
            <Button text={t('newPassword.buttons.try-again')} />
          </NavLink>
        </div>
      </div>
    );
  }

  return (
    <div className="new-password">
      {!done ? (
        <NewPasswordForm onSubmit={handleSubmit} />
      ) : (
        <div>
          <p className="reset-password__success-text icon">
            <Icon icon="info" />
            {t('newPassword.texts.success')}
          </p>

          <div className="reset-password__submit-btn">
            <NavLink to="/">
              <Button text={t('newPassword.buttons.login')} />
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPassword;
