import classNames from 'classnames';
import Chip from 'components/Chip/Chip';
import Tooltip from 'components/Tooltip/Tooltip';
import { getAvailabilityAsText, isAvailable } from 'features/availability';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone } from 'redux/slices/i18n/i18nSlice';
import { ChannelMinimalResponseDTO, VideoResponseDTO } from '../../generated';
import placeholder from '../../images/placeholder-video-mediathek-gradient.svg';
import EncodingBadge from '../EncodingBadge/EncodingBadge';
import Icon from '../Icon/Icon';
import LabelText from '../LabelText/LabelText';
import LazyImage from '../LazyImage/LazyImage';
import './video-list-item.scss';

export interface Props {
  active?: boolean;
  className?: string;
  onClick?: () => void;
  title?: string;
  image?: string;
  subtitle?: string;
  language?: string;
  channels?: ChannelMinimalResponseDTO[];
  encodingStatus?: VideoResponseDTO.encodingStatus;
  languageNotSupportedError?: boolean;
}

const VideoListItem = ({
  active,
  className,
  onClick,
  image,
  title,
  subtitle,
  language,
  channels,
  encodingStatus,
  languageNotSupportedError,
}: Props) => {
  const { t } = useTranslation(['translation', 'channel', 'list']);
  const timeZone = useReduxSelector(selectTimezone);

  return (
    <li
      className={classNames(
        {
          'video-list-item': true,
          'video-list-item--active': active,
        },
        className,
      )}
      onClick={onClick}
    >
      <div className="video-list-item__image">
        <LazyImage
          src={image || placeholder}
          alt={t('list:preview', { title: title })}
          aspectRatio={100 / 150}
          lqip={placeholder}
          className="video-list-item__image-lazy"
        />
        {language && (
          <div
            className={classNames(
              'video-list-item__language',
              languageNotSupportedError && 'video-list-item__language--error',
            )}
          >
            <span className="video-list-item__lang-text">
              {language.split('-')[0].toUpperCase()}
            </span>
          </div>
        )}
      </div>

      <div className="video-list-item__detail">
        <div className="video-list-item__title">
          {title === '' ? t('no_title') : title}
        </div>
        {subtitle && (
          <div className="video-list-item__subtitle">{subtitle}</div>
        )}

        {channels && channels.length > 0 && (
          <LabelText
            label={t('channel:title')}
            className="video-list-item__info"
          >
            <ul className="video-list-item__tags">
              {channels.map(({ availability, name }, i) => (
                <li key={i} className="video-list-item__tag-item">
                  <Chip
                    status={
                      isAvailable(availability)
                        ? VideoResponseDTO.status.LIVE
                        : VideoResponseDTO.status.IDLE
                    }
                    tooltip={getAvailabilityAsText(availability, t, timeZone)}
                  >
                    {name}
                  </Chip>
                </li>
              ))}
            </ul>
          </LabelText>
        )}
      </div>

      <div className="video-list-item__controls">
        {onClick && (
          <div className="icon video-list-item__arrow">
            <Icon icon="caret-right" />
          </div>
        )}
        <div className="video-list-item__status">
          {languageNotSupportedError && (
            <div className="video-list-item__status-item">
              <Tooltip
                content={t('video:languageNotSupported.text')}
                direction="down"
                className={classNames('encoding-badge__wrap', className)}
              >
                <div className="icon encoding-badge encoding-badge--processing">
                  <Icon icon="info" />
                  {t('video:languageNotSupported.badge')}
                </div>
              </Tooltip>
            </div>
          )}

          {encodingStatus &&
            encodingStatus !== VideoResponseDTO.encodingStatus.NONE &&
            encodingStatus !== VideoResponseDTO.encodingStatus.READY && (
              <div className="video-list-item__status-item">
                <EncodingBadge encodingStatus={encodingStatus} />
              </div>
            )}
        </div>
      </div>
    </li>
  );
};

export default VideoListItem;
