import { useTranslation } from 'react-i18next';

const LinkListItemError = ({
  reallyDelete,
  deleteText,
  error,
}: {
  reallyDelete?: boolean;
  deleteText?: string;
  error?: string;
}) => {
  const { t } = useTranslation();

  if (reallyDelete) {
    return (
      <div className="link-list-item__delete-msg">
        {deleteText || t('video:share.really_delete')}
      </div>
    );
  }

  if (error) {
    return <div className="link-list-item__delete-msg">{error}</div>;
  }

  return null;
};

export default LinkListItemError;
