import { components, ControlProps, GroupBase } from 'react-select';
import { OptionWithIconProps } from './OptionWithIcon';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

export const Control = <
  OptionType extends OptionWithIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>({
  children,
  ...props
}: ControlProps<OptionType, IsMulti, Group>) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <div
      className={!isDesktop ? 'select-interactive__control-wrap' : undefined}
    >
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

export default Control;
