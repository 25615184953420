import { FieldInputProps } from 'formik';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../settings.scss';

const UpdateVendorAgentForm = ({
  onSubmit,
  nameLabel,
  nameError,
  nameFieldProps,
  emailLabel,
  emailError,
  emailFieldProps,
  submitText,
}: {
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  nameLabel: string;
  nameError?: string;
  nameFieldProps: FieldInputProps<any>;
  emailLabel: string;
  emailError?: string;
  emailFieldProps: FieldInputProps<any>;
  submitText: string;
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="settings__block settings__block--50">
        <Input
          label={nameLabel}
          placeholder={nameLabel}
          error={nameError}
          {...nameFieldProps}
        />
      </div>

      <div className="settings__block settings__block--50">
        <Input
          label={emailLabel}
          placeholder={emailLabel}
          error={emailError}
          {...emailFieldProps}
        />
      </div>

      <Button text={submitText} type="submit" />
    </form>
  );
};

export default UpdateVendorAgentForm;
