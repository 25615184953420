import Button from 'components/Button/Button';
import Chip from 'components/Chip/Chip';
import { Spacer, FlexBox, FlexItem } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import { StepChildProps } from '../VideoType';
import { useNavigate } from 'react-router-dom';

const TypeDefaultError = ({ videoId }: StepChildProps) => {
  const { t } = useTranslation(['translation', 'video']);
  const navigate = useNavigate();

  const handleConfirmError = () => {
    navigate(`/videos/${videoId}`, { state: { from: 'type', error: true } });
  };

  return (
    <>
      <Chip role="alert" color={ColorUse['warning-50']}>
        {t('inline-edit.error')}
      </Chip>
      <Spacer tag="p" margin={[2, 0, 8]} className="c-dark-50 small">
        {t('video:type.default-error')}
      </Spacer>

      <Spacer tag="footer" marginTop={4} className="video-type__footer">
        <FlexBox justifyContent="space-between">
          <FlexItem pushRight>
            <Button text={t('confirm')} onClick={handleConfirmError} />
          </FlexItem>
        </FlexBox>
      </Spacer>
    </>
  );
};

export default TypeDefaultError;
