import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { FlexBox, Spacer } from 'components/Layout';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import Loader from 'components/Loader/Loader';
import {
  ApiError,
  CategoriesResponseDto,
  PaginatedGetCategoriesResponseDto,
} from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import React, { Key, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import CategoryBranch from '../../../../components/CategoryBranch/CategoryBranch';
import CategegoriesHeader from './CategoriesHeader';

export type Props = {
  onSelectChange: (value: CategoriesResponseDto[]) => void;
  value?: CategoriesResponseDto[];
  onClose: () => void;
  scrollTargetId: string;
  onOpenTree: (rootCategory: CategoriesResponseDto) => void;
  searchableProps: {
    onInputChange: (inputText: string, event: any) => void;
    inputValue: string;
    onClearInputValue: () => void;
  };
  debouncedSearchTerm: string | void;
  query: UseInfiniteQueryResult<PaginatedGetCategoriesResponseDto, ApiError>;
  isSearchResult?: boolean;
};

const CategoriesList = ({
  onSelectChange,
  onOpenTree,
  value = [],
  onClose,
  scrollTargetId,
  searchableProps,
  debouncedSearchTerm,
  query,
  isSearchResult,
}: Props) => {
  const { data, status, fetchNextPage, hasNextPage } = query;
  const { t } = useTranslation(['translation', 'product']);
  const modal = document.querySelector('.modal__content');
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const handleSelect = (category: CategoriesResponseDto, checked: boolean) => {
    if (checked) {
      if (category.hasChildren || isSearchResult) {
        if (isSearchResult) {
          // fixes flashing the selected state of items in the list that have the same parents
          onSelectChange([]);
        }
        onOpenTree(category);
      }

      const parents =
        category.parents?.map((i) => ({ ...i, hasChildren: true })) || [];
      onSelectChange([...parents, category]);
    } else {
      onSelectChange(value?.filter((i) => i.id !== category.id));
    }
  };

  const handleOpenChild = (parent: CategoriesResponseDto) => {
    if (parent.hasChildren) {
      handleSelect(parent, true);
      onOpenTree(parent);
    }
  };

  useEffect(() => {
    if (
      hasNextPage &&
      (modal?.querySelector('.modal__content')?.scrollHeight || 0) <=
        (modal?.clientHeight || 0)
    ) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  return (
    <div className="categories__modal-inner">
      {!isDesktop && (
        <CategegoriesHeader
          searchableProps={searchableProps}
          debouncedSearchTerm={debouncedSearchTerm}
          onClose={onClose}
          loading={status === 'loading'}
        />
      )}
      <InfiniteScroll
        scrollableTarget={scrollTargetId}
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        dataLength={data?.pages.map((i) => i.data).flat().length || 0}
        loader={<Loader small />}
      >
        {(data?.pages.map((i) => i.data).flat().length ?? 0) === 0 &&
          status === 'success' && (
            <Spacer className="c-black-50">
              {t('product:categories.error.notFound')}
            </Spacer>
          )}
        {status === 'error' && (
          <Spacer className="c-black-50">
            {t('product:categories.error.searchError')}
          </Spacer>
        )}
        {(data?.pages.map((i) => i.data).flat().length ?? 0) > 0 && (
          <ul className="categories__list">
            {data?.pages.map((page, i) => (
              <React.Fragment key={(page.links?.next?.offset || i) as Key}>
                {page.data.map((treeItem) => (
                  <ListItemSelect
                    key={treeItem.id}
                    selected={
                      value?.flatMap((i) => i.id).includes(treeItem.id) || false
                    }
                    id={`check--${treeItem.id}`}
                    name={`check--${treeItem.id}`}
                    onChange={(checked: boolean) =>
                      handleSelect(treeItem, checked)
                    }
                    appearance={isDesktop ? 'chip' : 'default'}
                    className={classNames(
                      'tree-select__option',
                      'categories__option',
                      (treeItem.parents?.length || 0) > 0 &&
                        'categories__option--parents',
                    )}
                  >
                    <FlexBox justifyContent="space-between" alignItems="center">
                      <div className="categories__name">
                        <span>{treeItem.name}</span>
                        {treeItem.parents && (
                          <CategoryBranch category={treeItem} hideRoot />
                        )}
                      </div>

                      {treeItem.hasChildren && !isSearchResult && (
                        <FlexBox
                          tag="button"
                          type="button"
                          className={classNames('tree-select__arrow')}
                          onClick={() => handleOpenChild(treeItem)}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Icon icon="arrow-right" />
                        </FlexBox>
                      )}
                    </FlexBox>
                  </ListItemSelect>
                ))}
              </React.Fragment>
            ))}
          </ul>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default CategoriesList;
