import Help from 'components/Help/Help';
import { useTranslation } from 'react-i18next';
import NewPassword from '../features/NewPassword/NewPassword';
import LandingPage from './LandingPage';

const NewPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <LandingPage headline={t('new-password-headline')} hint={<Help />}>
      <NewPassword />
    </LandingPage>
  );
};

export default NewPasswordPage;
