import { useTranslation } from 'react-i18next';
import QUERY_KEYS from 'types/QueryKeys';
import Headline from '../../../components/Headline/Headline';
import { VersionService } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import { queryClient } from '../../../index';
import UpdateAppVersionsForm from './UpdateAppVersionsForm';

const UpdateAppVersionsContainer = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();

  const handleUpdateVersionsSubmit = async ({
    ios,
    android,
  }: {
    ios: string;
    android: string;
  }) => {
    try {
      await VersionService.setLatestAppVersions({
        platform: 'ios',
        version: ios,
      });

      await VersionService.setLatestAppVersions({
        platform: 'android',
        version: android,
      });

      queryClient.invalidateQueries([QUERY_KEYS.getAppVersions]);

      setAppStatus(t('settings:success.update_app_version'), 'success', true);
    } catch (error) {
      setAppStatus(t('settings:error.update_app_version'), 'error', true);
    }
  };

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.app-versions')}
      </Headline>

      <UpdateAppVersionsForm onSubmit={handleUpdateVersionsSubmit} />
    </>
  );
};

export default UpdateAppVersionsContainer;
