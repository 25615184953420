import Steps from 'components/Steps/Steps';
import { useGetPackageDimensions } from 'features/order-shipment';
import { useGetVendorAddresses } from 'features/vendor-address';
import {
  ProductTaxDto,
  VendorAddressDto,
  VendorOrderDto,
  VendorOrderShipmentProductPackageDimensionDto,
} from 'generated';
import { useEffect, useState } from 'react';
import CreateShipmentAddress from './CreateShipmentAddress';
import CreateShipmentPackaging from './CreateShipmentPackaging';
import CreateShipmentStart from './CreateShipmentStart';
import CreateShipmentTaxes from './CreateShipmentTaxes';
import { useGetProductTaxes } from 'features/product-taxes';
import CreateShipmentsDownloads from './CreateShipmentDownloads';
import {
  ExtendedVendorOrderDto,
  useGetOrder,
} from 'features/order-detail/queries';
import CreateShipmentPickupDate from './CreateShipmentPickupDate';

export type StepsNav =
  | 'start'
  | 'address'
  | 'packaging'
  | 'taxes'
  | 'downloads'
  | 'pickupdate';

export interface ShipmentComponentProps {
  address?: VendorAddressDto;
  step: ShipmentStep;
  setStep: React.Dispatch<React.SetStateAction<ShipmentStep>>;
  packagingData?: VendorOrderShipmentProductPackageDimensionDto;
  taxesData?: ProductTaxDto;
  productId: number;
  orderNo: string;
  paymentMethod?: VendorOrderDto.paymentMethod | null;
  pickupdate?: string | null;
}

export interface Props {
  productId: number;
  orderNo: string;
  paymentMethod?: VendorOrderDto.paymentMethod | null;
}

export type ShipmentStep = {
  key: StepsNav;
  data?: ExtendedVendorOrderDto | null;
};

const CreateShipment = ({ productId, orderNo, paymentMethod }: Props) => {
  const [step, setStep] = useState<ShipmentStep>({ key: 'start' });
  const { data, refetch } = useGetVendorAddresses();
  // This is just a workaround for packaging details for now
  const { data: packagingData } = useGetPackageDimensions(productId);
  const { data: taxesData } = useGetProductTaxes(productId);
  const { data: orderData } = useGetOrder(orderNo);

  const stepsNav = {
    start: CreateShipmentStart,
    address: CreateShipmentAddress,
    packaging: CreateShipmentPackaging,
    taxes: CreateShipmentTaxes,
    downloads: CreateShipmentsDownloads,
    pickupdate: CreateShipmentPickupDate,
  };

  const StepComponent = stepsNav[step?.key];

  useEffect(() => {
    if (step?.key === 'start') {
      refetch();
    }
  }, [step]);

  return (
    <Steps className="shipping__steps" stepKey={step?.key}>
      <StepComponent
        address={data?.[0]}
        packagingData={packagingData}
        taxesData={taxesData}
        setStep={setStep}
        step={step}
        productId={productId}
        orderNo={orderNo}
        paymentMethod={paymentMethod}
        pickupdate={orderData?.pickupdate}
      />
    </Steps>
  );
};

export default CreateShipment;
