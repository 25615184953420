import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Headline from '../../../components/Headline/Headline';
import { VendorAgentsService } from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxSelector } from '../../../redux/hooks';
import { init, selectVendorAgent } from '../../../redux/slices/auth/authSlice';
import UpdateVendorAgentForm from './UpdateVendorAgentForm';

const UpdateVendorAgentContainer = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();
  const dispatch = useDispatch();

  const updateVendorAgentValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('form.required')),
    name: Yup.string().required(t('form.required')),
  });

  const handleSubmit = async ({
    name,
    email,
  }: {
    name?: string;
    email?: string;
  }) => {
    try {
      await VendorAgentsService.updateCurrentVendorAgent({
        email,
        name,
      });

      setAppStatus(t('settings:success.update_vendor_agent'), 'success', true);

      dispatch(init());
    } catch (error) {
      setAppStatus(t('settings:error.update_vendor_agent'), 'error', true);
    }
  };

  const updateVendorAgentForm = useFormik({
    initialValues: { name: vendorAgent?.name, email: vendorAgent?.email },
    validationSchema: updateVendorAgentValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.info')}
      </Headline>

      <UpdateVendorAgentForm
        onSubmit={updateVendorAgentForm.handleSubmit}
        nameLabel={t('settings:labels.name')}
        nameError={
          updateVendorAgentForm.touched.name &&
          updateVendorAgentForm.errors.name
            ? t(updateVendorAgentForm.errors.name)
            : undefined
        }
        nameFieldProps={updateVendorAgentForm.getFieldProps('name')}
        emailLabel={t('settings:labels.email')}
        emailError={
          updateVendorAgentForm.touched.email &&
          updateVendorAgentForm.errors.email
            ? t(updateVendorAgentForm.errors.email)
            : undefined
        }
        emailFieldProps={updateVendorAgentForm.getFieldProps('email')}
        submitText={t('settings:submit')}
      />
    </>
  );
};

export default UpdateVendorAgentContainer;
