import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import { VideoResponseDTO } from '../../generated';
import '../../styles/layout/_tooltip.scss';
import Color from '../../types/Color';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';
import './encoding-badge.scss';

export interface Props {
  encodingStatus: VideoResponseDTO.encodingStatus;
  disableTooltip?: boolean;
  className?: string;
  appearance?: 'badge' | 'overlay';
}

const EncodingBadge = ({
  encodingStatus,
  disableTooltip,
  className,
  appearance,
}: Props) => {
  const { t } = useTranslation(['video']);

  if (
    encodingStatus === VideoResponseDTO.encodingStatus.NONE ||
    encodingStatus === VideoResponseDTO.encodingStatus.READY
  ) {
    return <div></div>;
  }

  return (
    <Tooltip
      content={t(`video:encodingStatus.${encodingStatus}Text`)}
      direction="down"
      isOpen={disableTooltip ? false : undefined}
      className={classNames(
        'encoding-badge__wrap',
        appearance && `encoding-badge__wrap--${appearance}`,
        className,
      )}
    >
      <div
        className={classNames(
          'encoding-badge',
          `encoding-badge--${encodingStatus}`,
        )}
      >
        <div className="encoding-badge__inner">
          {encodingStatus === VideoResponseDTO.encodingStatus.ERROR ? (
            <Icon icon="info" />
          ) : (
            <Loader small color={Color.process} />
          )}
          <span className="encoding-badge__text">
            {t(`video:encodingStatus.${encodingStatus}`)}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

export default EncodingBadge;
