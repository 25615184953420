import {
  CategoryTreeDto,
  CategoryResponseMinimalDTO,
  CategoriesResponseDto,
  CategoryTranslationDTO,
} from 'generated';
import { defaultFallbackLanguage } from 'global-constants';
import i18n from 'i18n';

export const getAncestors = (
  category: CategoryResponseMinimalDTO,
  children?: CategoryTreeDto[],
  ancestors: CategoryResponseMinimalDTO[] = [],
): CategoryResponseMinimalDTO[] | undefined => {
  if (children) {
    for (let node of children) {
      if (node.id === category.id) {
        return ancestors.concat(node);
      }
      const found = getAncestors(
        category,
        node.children,
        ancestors.concat(node),
      );

      if (found) {
        return found;
      }
    }
    return undefined;
  }
};

export function findCategoryById(
  id: number,
  categoryTree: CategoryTreeDto[],
): CategoryTreeDto | undefined {
  let result;
  categoryTree.some(
    (o) =>
      (o.id === id && (result = o)) ||
      (result = findCategoryById(id, o.children || [])),
  );
  return result;
}

export function getBranchFromCategoryDTO(
  category: CategoriesResponseDto,
  hideRoot?: boolean,
) {
  if (!category) return [];
  const rootCategory = hideRoot ? [] : [category];
  return [...(category.parents || []), ...rootCategory];
}

export function getBranchIdsFromCategoryDTO(category: CategoriesResponseDto) {
  return getBranchFromCategoryDTO(category).map((c) => c.id);
}

export const getCategoryTranslation = (
  translations: CategoryTranslationDTO[],
) => {
  const findTranslation = translations.find((i) => i.iso === i18n.language);
  if (findTranslation) {
    return findTranslation.translation;
  }

  const findFallback = translations.find(
    (i) => i.iso === defaultFallbackLanguage,
  );
  if (findFallback) {
    return findFallback.translation;
  }

  return translations[0]?.translation || '';
};
