import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import Headline from '../../../components/Headline/Headline';
import LinkCode from '../../../components/LinkCode/LinkCode';
import LinkListItem from '../../../components/LinkListItem/LinkListItem';
import List from '../../../components/List/List';
import Modal from '../../../components/Modal/Modal';
import Terms from '../../../components/Terms/Terms';
import TermsForm, { Values } from '../../../components/TermsForm/TermsForm';
import { PlatformTermsService, TermsResponseDTO } from '../../../generated';
import { request } from '../../../generated/core/request';
import useAppStatus from '../../../hooks/useAppStatus';
import useList from '../../../hooks/useList';
import useOpen from '../../../hooks/useOpen';
import termsReducer from '../../../reducer/reducer-terms';
import { useReduxDispatch, useReduxSelector } from '../../../redux/hooks';
import { selectLanguageCode } from '../../../redux/slices/i18n/i18nSlice';
import { updateTerms } from '../../../redux/slices/terms/termsSlice';
import { OpenAPI } from 'generated/core/OpenAPI';

const TermsManager = () => {
  const { listState, activeItem, listFunctions } = useList<TermsResponseDTO>({
    reducer: termsReducer,
    options: { disableSetActiveItem: true },
  });
  const { get, setActiveItem, updateActiveItem, add, deleteItem } =
    listFunctions;
  const { open, close, isOpen } = useOpen(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation(['settings', 'translation']);
  const [error, setError] = useState<{ id: number; msg: string }>();
  const [uploadError, setUploadError] = useState<string>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const languageCode = useReduxSelector(selectLanguageCode);
  const { setAppStatus } = useAppStatus();
  const dispatch = useReduxDispatch();

  useEffect(() => {
    get({
      service: () => PlatformTermsService.getPlatformTermsPaginated(0, 5),
      props: {
        initial: true,
      },
    });
  }, []);

  const handleDelete = (termsId: number) => {
    deleteItem({
      service: () => PlatformTermsService.deleteTerms(termsId),
    });
  };

  const handleUpdate = (termId: number, version: string) => {
    updateActiveItem({
      service: () =>
        PlatformTermsService.updateTerms(termId, {
          version,
        }),
      props: {
        onError: () => {
          setError({ id: termId, msg: t('settings:terms.duplicateError') });
        },
        onSuccess: () => {
          setError(undefined);
        },
      },
    });
  };

  const handleViewer = (term: TermsResponseDTO) => {
    setActiveItem(term);
    open();
  };

  const handleAdd = async (
    { file, title }: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => {
    setUploadError(undefined);
    const upload = async () => {
      try {
        const res = await request<{
          fileUrl: string;
          filePath: string;
        }>(OpenAPI, {
          method: 'POST',
          url: '/api/file/doc',
          formData: { file },
        });

        if (res?.filePath) {
          return res?.filePath;
        } else {
          throw new Error('filePath missing in Response');
        }
      } catch (error) {
        console.log(error);
        setUploadError(t('settings:terms.uploadError'));
      }
    };

    const filePath = await upload();

    if (filePath) {
      add({
        service: () =>
          PlatformTermsService.addTerms({
            version: title,
            languageCode,
            path: filePath,
            currentlyActive: false,
          }),
        props: {
          onSuccess: () => formikHelpers.resetForm(),
          onError: () => {
            formikHelpers.setFieldError(
              'title',
              t('settings:terms.duplicateError'),
            );
          },
        },
      });
    }
  };

  const handleActivateVersion = (fileVersion: TermsResponseDTO) => {
    setConfirmLoading(true);

    updateActiveItem({
      service: () =>
        PlatformTermsService.updateTerms(fileVersion.id, {
          currentlyActive: true,
        }),
      props: {
        onSuccess: (res) => {
          setConfirmOpen(false);
          setConfirmLoading(false);
          setAppStatus(
            t('settings:terms.activateSuccess', { title: fileVersion.version }),
            'success',
          );
          dispatch(updateTerms(res));
          get({
            service: () =>
              PlatformTermsService.getPlatformTermsPaginated(
                0,
                (listState.activeRange?.limit || 0) +
                  (listState.activeRange?.offset || 0),
              ),
            props: {
              initial: true,
              onSuccess: close,
            },
          });
        },
        onError: () => {
          setConfirmLoading(false);
          setAppStatus(
            t('settings:terms.activateError', { title: fileVersion.version }),
            'error',
          );
        },
      },
    });
  };

  const handleLoadMore = () => {
    get({
      service: () =>
        PlatformTermsService.getPlatformTermsPaginated(
          listState.items?.length,
          5,
        ),
    });
  };

  return (
    <div>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.terms')}
      </Headline>

      <p className="page__description">{t('settings:terms.adminDesc')}</p>

      <List
        className="modal__list"
        showLoadMore={listState.loadMore}
        handleLoadMore={handleLoadMore}
      >
        {listState.items &&
          listState.items.map((term) => {
            return (
              <LinkListItem
                key={term.id}
                onDelete={() => handleDelete(term.id)}
                onEditSave={(title: string) => handleUpdate(term.id, title)}
                onEditStart={() => setActiveItem(term)}
                title={term.version}
                active={term.currentlyActive}
                error={error?.id === term.id ? error.msg : undefined}
                deleteText={t('settings:terms.reallyDelete')}
              >
                <LinkCode link={term.path} onClick={() => handleViewer(term)} />
              </LinkListItem>
            );
          })}
      </List>

      <TermsForm
        onSubmit={handleAdd}
        loading={listState.loading}
        error={uploadError}
      />

      <Modal
        isOpen={isOpen}
        onClose={close}
        variant="wide"
        headline={t('settings:headlines.terms')}
        hasHeaderBorder
      >
        {activeItem && (
          <>
            <Terms
              terms={activeItem}
              onActivateVersion={() => setConfirmOpen(true)}
            />
            <ConfirmModal
              isOpen={confirmOpen}
              headline={t('settings:terms.activateVersion')}
              text={t('settings:terms.reallyActivate')}
              onCancelClick={() => setConfirmOpen(false)}
              onConfirmClick={() => handleActivateVersion(activeItem)}
              confirmLoading={confirmLoading}
              confirmText={t('settings:terms.activate')}
              variant="wide"
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default TermsManager;
