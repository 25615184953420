import CreatableMultiSelect, {
  Props as CreatableMultiSelectProps,
} from 'components/CreatableMultiSelect';
import SelectInteractive, {
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive';
import { DefaultOption } from 'components/SelectInteractive/components/Option';
import { HashtagResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import { ColorUse } from 'types/Color';

type CustomProps = {
  hashtags?: HashtagResponseDTO[];
  onCreateHashtag?: () => void;
};

type SelectType<
  OptionType extends DefaultOption,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
> =
  | Omit<CreatableMultiSelectProps<OptionType>, 'onCreateOption'>
  | SelectInteractiveProps<OptionType, true, Group>;

export type Props<
  OptionType extends DefaultOption,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
> = SelectType<OptionType, Group> & CustomProps;

/**
 * This component renders a CreatableMultiSelect for hashtags.
 * If you need this to work as a Formik Field use <HashtagSelectField>.
 * If you need a prefilled version use <HashtagsAsyncField> from feature/hashtags.
 */
const HashtagSelect = (props: Props<DefaultOption>) => {
  const { t } = useTranslation(['translation', 'product']);
  const { hashtags, noOptionsMessage, onCreateHashtag } = props;

  const getOptions = () => {
    if (!Array.isArray(hashtags)) {
      return [];
    }

    return hashtags.map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }));
  };

  const sharesProps: Props<DefaultOption> = {
    ...props,
    noOptionsMessage: noOptionsMessage || t('product:hashtag.empty'),
    options: getOptions(),
    createButtonPrefix: '#',
    chipProps: {
      color: ColorUse['secondary-50'],
      icon: 'hashtag',
      iconColor: ColorUse['dark-50'],
    },
    maxSelectedOptionsMessage:
      props.maxSelectedOptionsMessage || t('product:hashtag.max'),
    placeholder: t('product:hashtag.placeholder'),
    title: props.title || t('product:hashtag.label'),
  };

  return onCreateHashtag ? (
    <CreatableMultiSelect
      {...(sharesProps as Omit<
        CreatableMultiSelectProps<DefaultOption>,
        'onCreateOption'
      >)}
      onCreateOption={onCreateHashtag}
    />
  ) : (
    <SelectInteractive
      isMulti
      {...(sharesProps as SelectInteractiveProps<DefaultOption, true>)}
    />
  );
};

export default HashtagSelect;
export { getNormalizedHashtagInputValue } from './utils';
