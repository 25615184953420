import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { isAvailable } from 'features/availability';
import { AvailabilityDTO } from '../generated/models/AvailabilityDTO';

function useAvailability<T extends { availability: AvailabilityDTO }>() {
  const [available, setAvailable] = useState<boolean>();
  const {
    values: { availability },
  } = useFormikContext<T>();

  useEffect(() => {
    setAvailable(isAvailable(availability));
  }, [availability]);

  return {
    available,
  };
}

export default useAvailability;
