/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppCustomerAddressDto } from './AppCustomerAddressDto';
import type { AppOrderBasicChannelDto } from './AppOrderBasicChannelDto';
import type { AppOrderBasicProductDto } from './AppOrderBasicProductDto';
import type { AppOrderItemDto } from './AppOrderItemDto';
import type { AppOrderStatusDto } from './AppOrderStatusDto';
import type { AppPaymentDto } from './AppPaymentDto';
import type { AppVendorDto } from './AppVendorDto';
import type { TotalPricingDto } from './TotalPricingDto';
import type { VendorAddressDto } from './VendorAddressDto';

export type AppOrderDto = {
    paymentMethod: AppOrderDto.paymentMethod | null;
    /**
     * (use pricing instead) The sum of all order items total gross costs. This value is calculated each time the order is fetched.
     * @deprecated
     */
    totalGrossCost: number;
    /**
     * (use pricing instead)
     * @deprecated
     */
    totalGrossCostLocalized: string;
    /**
     * The channel property is only available until we have more than one item per order.
     */
    channel?: AppOrderBasicChannelDto;
    /**
     * The product property is only available until we have more than one item per order.
     */
    product?: AppOrderBasicProductDto;
    pickupAddress?: VendorAddressDto;
    shippingAddress?: AppCustomerAddressDto;
    invoiceAddress?: AppCustomerAddressDto;
    orderItems: Array<AppOrderItemDto>;
    vendor?: AppVendorDto;
    orderStatusHistory: Array<AppOrderStatusDto>;
    currentOrderStatus?: AppOrderStatusDto;
    consolidatedOrderStatus: AppOrderDto.consolidatedOrderStatus;
    payment?: AppPaymentDto;
    createdAt?: string;
    updatedAt?: string | null;
    orderNo: string | null;
    comment: string | null;
    pricing: TotalPricingDto;
};

export namespace AppOrderDto {

    export enum paymentMethod {
        CASH_ON_DELIVERY = 'cashOnDelivery',
        ACSS_DEBIT = 'acss_debit',
        AFFIRM = 'affirm',
        AFTERPAY_CLEARPAY = 'afterpay_clearpay',
        ALIPAY = 'alipay',
        AU_BECS_DEBIT = 'au_becs_debit',
        BACS_DEBIT = 'bacs_debit',
        BANCONTACT = 'bancontact',
        BLIK = 'blik',
        BOLETO = 'boleto',
        CARD = 'card',
        CASHAPP = 'cashapp',
        CUSTOMER_BALANCE = 'customer_balance',
        EPS = 'eps',
        FPX = 'fpx',
        GIROPAY = 'giropay',
        GRABPAY = 'grabpay',
        IDEAL = 'ideal',
        KLARNA = 'klarna',
        KONBINI = 'konbini',
        LINK = 'link',
        OXXO = 'oxxo',
        P24 = 'p24',
        PAYNOW = 'paynow',
        PAYPAL = 'paypal',
        PIX = 'pix',
        PROMPTPAY = 'promptpay',
        SEPA_DEBIT = 'sepa_debit',
        SOFORT = 'sofort',
        US_BANK_ACCOUNT = 'us_bank_account',
        WECHAT_PAY = 'wechat_pay',
        ZIP = 'zip',
    }

    export enum consolidatedOrderStatus {
        PROCESSING = 'processing',
        CANCELLED = 'cancelled',
        SHIPPED = 'shipped',
        DELIVERED = 'delivered',
        RETURN_REQUESTED = 'returnRequested',
        RETURNED = 'returned',
        REFUNDED = 'refunded',
    }


}

