import classNames from 'classnames';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import Chip from 'components/Chip/Chip';
import { FlexBox, Spacer } from 'components/Layout';
import { isAvailable } from 'features/availability';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import Color, { ColorName } from 'types/Color';
import { GetProductsResponseDTO } from '../../generated/models/GetProductsResponseDTO';
import LabelText from '../LabelText/LabelText';
import LabeledInfoText from '../LabeledInfoText/LabeledInfoText';
import LocalisationTabs from '../LocalisationTabs/LocalisationTabs';
import TabPane from '../LocalisationTabs/TabPane';
import ProductVideoList from './ProductVideoList';
import './product-info.scss';

const ProductInfo = ({
  productLocalisations,
  id,
  videos,
  channels,
  categories,
  vendorProductId,
  small,
  hashtags,
  showDiscountPrice,
}: GetProductsResponseDTO & {
  small?: boolean;
  showDiscountPrice?: boolean;
}) => {
  const { t } = useTranslation(['translation', 'product', 'video']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <FlexBox
      className={classNames('product-info', small && 'product-info--small')}
      flexDirection="column"
    >
      <LocalisationTabs showTabPaneContent={isDesktop}>
        {productLocalisations.map((info) => (
          <TabPane
            key={info.locale}
            id={info.locale}
            title={info.name === '' ? t('no_title') : info.name}
            subtitle={vendorProductId}
            name={info.locale}
            price={{ amount: info.price, currency: info.currencyCode }}
            discountPrice={showDiscountPrice ? info.discountPrice : null}
          >
            {isDesktop && (
              <>
                {info.shopUrl && (
                  <LabelText label={t('product:shop_url')}>
                    <p className="product-info__text product-info__text--ellipsis">
                      <a
                        className="link"
                        href={info.shopUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {info.shopUrl}
                      </a>
                    </p>
                  </LabelText>
                )}
                {/* TODO Callapsible when two much content - collapsible=true */}
                {info.productInfo && info.productInfo.length !== 0 && (
                  <Spacer marginTop={4}>
                    <LabelText
                      label={t('product:info')}
                      initiallyCollapsed
                      collapsible
                    >
                      <LabeledInfoText staticContent={info.productInfo} />
                    </LabelText>
                  </Spacer>
                )}

                {categories && categories.length > 0 && (
                  <Spacer marginTop={4}>
                    <LabelText label={t('product:categories.label')}>
                      {categories.map((category, i) => (
                        <Spacer
                          key={category.id}
                          marginBottom={i !== 0 ? 2 : 0}
                        >
                          <CategoryBranch category={category} />
                        </Spacer>
                      ))}
                    </LabelText>
                  </Spacer>
                )}

                {hashtags && hashtags.length > 0 && (
                  <Spacer marginTop={4}>
                    <LabelText label={t('product:hashtag.label')}>
                      {hashtags.map((hashtag, index) => (
                        <span key={hashtag.id}>
                          {`#${hashtag.name}${
                            hashtags.length > 1 && index !== hashtags.length - 1
                              ? ', '
                              : ''
                          }`}
                        </span>
                      ))}
                    </LabelText>
                  </Spacer>
                )}
              </>
            )}
          </TabPane>
        ))}
      </LocalisationTabs>

      {channels && channels.length !== 0 && (
        <Spacer marginTop={8} borderTop={1} paddingTop={4}>
          <LabelText label={t('product:channel')}>
            <FlexBox
              tag="ul"
              className="product-info__channels"
              marginTop={1}
              alignItems="flex-end"
            >
              {channels.map(({ name, id, availability }) => (
                <li key={id} className="product-info__channel-item">
                  <Chip
                    color={
                      isAvailable(availability)
                        ? Color.confirm
                        : ColorName['black-20']
                    }
                  >
                    {name}
                  </Chip>
                </li>
              ))}
            </FlexBox>
          </LabelText>
        </Spacer>
      )}

      {videos && videos.length !== 0 && (
        <Spacer marginTop={8}>
          <ProductVideoList videos={videos} />
        </Spacer>
      )}
    </FlexBox>
  );
};
export default ProductInfo;
