import classNames from 'classnames';
import Button from 'components/Button/Button';
import Chip, { Props as ChipProps } from 'components/Chip/Chip';
import IconButton from 'components/IconButton/IconButton';
import LanguageSelect, {
  Props as LanguageSelectProps,
} from 'components/LanguageSelect/LanguageSelect';
import { FlexBox, FlexItem } from 'components/Layout';
import filesize from 'filesize';
import i18n from 'i18n';
import { ElementType } from 'react';
import { ColorUse } from 'types/Color';
import './file-list-item.scss';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

export interface Props
  extends React.HTMLAttributes<HTMLLIElement | HTMLDivElement> {
  tag?: ElementType;
  className?: string;
  onClose?: () => void;
  language?: string;
  languageSelectProps?: LanguageSelectProps;
  fileName: string;
  /** File Size in bytes */
  size?: number;
  chipProps?: ChipProps;
}

const FileListItem = ({
  tag = 'li',
  className,
  onClose,
  language,
  languageSelectProps,
  fileName,
  size,
  chipProps,
  ...props
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  return (
    <FlexBox
      tag={tag}
      className={classNames('list-item', 'file-list-item', className)}
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {onClose && (
        <FlexItem marginRight={isDesktop ? 3 : 1}>
          <IconButton
            icon="close"
            appearance="blank"
            onClick={onClose}
            color={ColorUse.dark}
          />
        </FlexItem>
      )}
      {language && !languageSelectProps && (
        <FlexItem marginRight={isDesktop ? 4 : 2}>
          <Button color={ColorUse.secondary} text={language} small />
        </FlexItem>
      )}
      {languageSelectProps && (
        <FlexItem marginRight={isDesktop ? 4 : 2}>
          <LanguageSelect
            {...languageSelectProps}
            controlAppearance="chip"
            hiddenLabel
          />
        </FlexItem>
      )}
      <FlexItem flexGrow={1} className="file-list-item__text">
        {fileName}
      </FlexItem>
      {size && (
        <FlexItem marginLeft={2} className="c-dark-50" flexShrink={0}>
          {filesize(size, {
            locale: i18n.language,
          })}
        </FlexItem>
      )}
      {chipProps && (
        <FlexItem
          paddingLeft={8}
          display="flex"
          alignItems="center"
          className="file-list-item__chip"
          justifyContent="flex-end"
        >
          <Chip {...chipProps} />
        </FlexItem>
      )}
    </FlexBox>
  );
};

export default FileListItem;
