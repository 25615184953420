import classNames from 'classnames';
import { Spacer } from 'components/Layout';
import TreeSelect from 'components/TreeSelect';
import { useGetCategoryTree } from 'features/categories/queries';
import { CategoriesResponseDto } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';

export type Props = {
  onSelectChange: (value: CategoriesResponseDto[]) => void;
  value: CategoriesResponseDto[];
  rootCategory: CategoriesResponseDto;
  onClose: () => void;
  onOpenPanel?: (panelId: number) => void;
};
const CategoryTree = ({
  onSelectChange,
  value,
  rootCategory,
  onClose,
  onOpenPanel,
}: Props) => {
  const { data: tree, status } = useGetCategoryTree(
    rootCategory.id > 0 ? rootCategory.id : undefined,
  );
  const isDesktop = useMediaQuery(`(min-width:${BREAKPOINTS.m} )`);
  const { t } = useTranslation(['translation', 'product']);

  const handleTreeSelect = (selectedItems: CategoriesResponseDto[]) => {
    onSelectChange([value[0], ...selectedItems]);
  };
  return (
    <div
      className={classNames(
        'categories__tree',
        isDesktop && 'categories__modal-inner',
      )}
    >
      {tree?.length === 0 && status === 'success' && (
        <Spacer marginLeft={4} className="c-black-50">
          {t('product:categories.error.treeNotFound')}
        </Spacer>
      )}
      {status === 'error' && (
        <Spacer marginLeft={4} className="c-black-50">
          {t('product:categories.error.treeSearchError')}
        </Spacer>
      )}
      {(tree?.length ?? 0) > 0 && (
        <TreeSelect
          tree={tree || []}
          value={value}
          onChange={(items) =>
            handleTreeSelect(items as CategoriesResponseDto[])
          }
          rootPanelId={rootCategory.id}
          mobileNavigationProps={{
            onClose,
            closeIcon: 'arrow-left',
            parentCenterSlot: (
              <span className="caption--lavender">{rootCategory.name}</span>
            ),
          }}
          onOpenPanel={onOpenPanel}
        />
      )}
    </div>
  );
};

export default CategoryTree;
