import { Spacer } from 'components/Layout/Layout';
import { SelectSupportedLanguagesField } from 'features/language';
import { Field, getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateVideoLanguage } from '../../queries';
import { Props } from '../VideoUpdateForm';

const VideoUpdateLanguage = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFieldValue, values, initialValues } =
    useFormikContext<Omit<Props, 'id'>>();
  const languageMutation = useUpdateVideoLanguage();
  const fieldName = 'language';

  const handleChange = (language: string) => {
    languageMutation.mutate(
      { videoId, language },
      {
        onError: () => {
          setFieldValue(fieldName, getIn(initialValues, fieldName), true);
          languageMutation.reset();
        },
        onSuccess: () => {
          // show success badge for 7s
          setTimeout(() => {
            languageMutation.reset();
          }, 7000);
        },
      },
    );
  };

  useEffect(() => {
    if (getIn(values, fieldName) !== getIn(initialValues, fieldName)) {
      languageMutation.reset();
      handleChange(getIn(values, fieldName));
    }
  }, [values]);

  return (
    <Spacer marginBottom={4}>
      <Field
        name={fieldName}
        component={SelectSupportedLanguagesField}
        disabled={languageMutation.isLoading}
        loading={languageMutation.isLoading}
        success={
          languageMutation.isSuccess ? t('inline-edit.success') : undefined
        }
        error={languageMutation.isError ? t('inline-edit.error') : undefined}
        messagePosition="top"
      />
    </Spacer>
  );
};

export default VideoUpdateLanguage;
