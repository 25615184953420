import Icon from 'components/Icon/Icon';
import { Spacer } from 'components/Layout';
import { getBranchFromCategoryDTO } from 'features/categories/utils';
import { CategoriesResponseDto } from 'generated';
import React from 'react';
import './category-branch.scss';

const CategoryBranch = ({
  category,
  hideRoot,
}: {
  category: CategoriesResponseDto;
  hideRoot?: boolean;
}) => {
  const branch = getBranchFromCategoryDTO(category, hideRoot);
  return branch && branch.length > 0 ? (
    <p className="category-branch">
      {branch.map((item, i) => (
        <React.Fragment key={item.id || i}>
          <span className="category-branch__item">{item.name}</span>

          {i < branch.length - 1 && (
            <Spacer
              tag="span"
              className="category-branch__item"
              margin={[0, 1]}
            >
              <Icon icon="caret-right" />
            </Spacer>
          )}
        </React.Fragment>
      ))}
    </p>
  ) : (
    <></>
  );
};

export default CategoryBranch;
