import classNames from 'classnames';
import React, { ElementType } from 'react';
import Sidebar, { Props as SidebarProps } from '../Sidebar/Sidebar';

interface ElementProps extends React.HTMLAttributes<HTMLDivElement> {
  tag?: ElementType;
}

type SideBarProps =
  | {
      templateArea: 'sidebar';
      sidebarProps: SidebarProps;
    }
  | {
      templateArea: 'header' | 'main' | 'footer';
      sidebarProps?: never;
    };

export type Props = ElementProps & SideBarProps;

const LayoutGridItem = ({
  tag: Tag = 'section',
  children,
  className,
  templateArea,
  sidebarProps,
  ...props
}: Props) => {
  const itemClasses = classNames(
    'layout-grid__item',
    templateArea && `layout-grid__item--${templateArea}`,
    className,
  );
  if (templateArea === 'sidebar') {
    return (
      <Sidebar {...props} className={itemClasses} {...sidebarProps}>
        {children}
      </Sidebar>
    );
  }
  return (
    <Tag {...props} className={itemClasses}>
      <div className="layout-grid__item-inner">{children}</div>
    </Tag>
  );
};

export default LayoutGridItem;
