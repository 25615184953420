/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportTableDTO = {
    table: ExportTableDTO.table;
    start?: string;
    end?: string;
};

export namespace ExportTableDTO {

    export enum table {
        AFFILIATE_TOKEN = 'affiliate_token',
        APP_CUSTOMER = 'app_customer',
        APP_CUSTOMER_DEVICE = 'app_customer_device',
        AVAILABILITY = 'availability',
        CATEGORY = 'category',
        CHANNEL = 'channel',
        CHANNEL_FAVORITE = 'channel_favorite',
        PRODUCT = 'product',
        PRODUCT_LOCALISATION = 'product_localisation',
        REFERRAL = 'referral',
        SHARING_TOKEN = 'sharing_token',
        VENDOR = 'vendor',
        VIDEO_ = 'video ',
        VIDEO_IMPRESSION = 'video_impression',
        VIDEO_ITEM = 'video_item',
        WEB_VIEW_URL_CHANGE = 'web_view_url_change',
        WISHLIST_ITEM = 'wishlist_item',
        CHANNEL_VIDEOS_VIDEO = 'channel_videos_video',
        PRODUCT_CATEGORIES_CATEGORY = 'product_categories_category',
    }


}

