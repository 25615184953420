import { useEffect, useState } from 'react';
import { DefaultOption } from '../components/Option';
import { GroupBase, OptionsOrGroups, PropsValue } from 'react-select';

export type Props<
  OptionType extends DefaultOption,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
> = {
  options?: OptionsOrGroups<OptionType, Group>;
  value?: PropsValue<OptionType>;
  inputValue?: string;
};

const useMultiSelectSort = <
  OptionType extends DefaultOption,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, Group>,
) => {
  const [options, setOptions] = useState<OptionType[]>();

  useEffect(() => {
    const filteredOptions =
      // take original options and remove selected options
      (props.options as OptionType[])?.filter((option) => {
        if (Array.isArray(props.value)) {
          return !props.value?.find((op) => op.value === option.value);
        } else {
          return props.value !== option;
        }
      }) || [];

    if (Array.isArray(props.value)) {
      if (props.inputValue) {
        const selectedValues = props.value as OptionType[];

        const sortedAndFilteredSelectedValues =
          options
            ?.filter((o) =>
              selectedValues.map((i) => i.value)?.includes(o.value),
            )
            .filter((o) =>
              `${o.label}${o.value}`.includes(props.inputValue as string),
            ) || [];

        setOptions([...sortedAndFilteredSelectedValues, ...filteredOptions]);
      } else {
        const sortedSelectedValues = props.value?.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
        );
        setOptions([...sortedSelectedValues, ...filteredOptions]);
      }
    } else {
      setOptions([props.value as OptionType, ...filteredOptions]);
    }
  }, [props.options, props.value]);

  return { options, setOptions };
};

export default useMultiSelectSort;
