import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import VideoFilterSideBar from '../VideoFilterSideBar/VideoFilterSideBar';

// TODO use new generic filter feature
const VideoFilterOutlet = () => {
  return (
    <ErrorBoundary>
      <VideoFilterSideBar />
    </ErrorBoundary>
  );
};

export default VideoFilterOutlet;
