import { useField } from 'formik';
import { VendorCountryDto, VendorStateDto } from 'generated';
import { ZipCodeLocationKey } from '../types';

const useZipCodeField = () => {
  const [zipField, , { setValue: setZipValue, setError }] = useField<string>(
    ZipCodeLocationKey.zip,
  );
  const [cityField, , { setValue: setCityValue }] = useField<string>(
    ZipCodeLocationKey.city,
  );
  const [provinceField, , { setValue: setProvinceValue }] = useField<string>(
    ZipCodeLocationKey.province,
  );
  const [provinceCodeField, , { setValue: setProvinceCodeValue }] =
    useField<string>(ZipCodeLocationKey.provinceCode);
  const [, , { setValue: setCountryValue }] = useField<string>(
    ZipCodeLocationKey.country,
  );
  const [countryNameField, , { setValue: setCountryNameValue }] =
    useField<string>(ZipCodeLocationKey.countryName);
  const [countryCodeField, , { setValue: setCountryCodeValue }] =
    useField<string>(ZipCodeLocationKey.countryCode);

  const setCountry = (country?: VendorCountryDto) => {
    setCountryNameValue(country?.name || '');
    setCountryValue(country?.name || '');
    setCountryCodeValue(country?.iso2Code || '');
  };

  const setProvince = (provinceCodeField?: VendorStateDto) => {
    setProvinceValue(provinceCodeField?.name || '');
    setProvinceCodeValue(provinceCodeField?.isoCode || '');
  };

  const resetAutoFillValues = () => {
    setCityValue('');
    setProvinceValue('');
    setProvinceCodeValue('');
    setCountryValue('');
    setCountryNameValue('');
    setCountryCodeValue('');
  };

  return {
    resetAutoFillValues,
    zipField: {
      value: zipField.value,
      inputValue: zipField.value,
      setValue: setZipValue,
      setError,
    },
    cityField: {
      value: cityField.value,
      inputValue: cityField.value,
      setValue: setCityValue,
    },
    provinceField: {
      value: provinceCodeField.value,
      inputValue: provinceField.value,
      setValue: setProvince,
    },
    countryField: {
      value: countryCodeField.value,
      inputValue: countryNameField.value,
      setValue: setCountry,
    },
  };
};

export default useZipCodeField;
