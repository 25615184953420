import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Spacer } from 'components/Layout';
import { FilterDetail, useFilter } from 'features/filter';
import { OrderFilterItem, OrderFilterKeys } from 'features/order-filter/types';
import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const OrderNoFilter = () => {
  const { t } = useTranslation(['translation', 'orders']);
  const [value, setValue] = useState('');
  const { setFilter, resetFilterByKey, filter } = useFilter<
    OrderFilterKeys,
    OrderFilterItem
  >();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (
    e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setFilter('orderNo', [{ name: value, id: value }]);
  };

  const handleClear = () => {
    setValue('');
    resetFilterByKey('orderNo');
  };

  useEffect(() => {
    if (filter?.orderNo?.[0].name !== value) {
      setValue('');
    }
  }, [filter?.orderNo]);

  return (
    <FilterDetail>
      <Spacer tag="p" className="small" marginBottom={2}>
        {t('orders:filter.orderNo.description')}
      </Spacer>
      <form>
        <div className="">
          <Input
            value={value}
            onChange={handleChange}
            name="orderNo"
            placeholder={t('orders:filter.orderNo.placeholder')}
            label={t('orders:filter.orderNo.label')}
            onClear={handleClear}
          />
        </div>
        <Spacer marginTop={2}>
          <Button
            text={t('orders:filter.orderNo.button')}
            onClick={handleSubmit}
            type="submit"
          />
        </Spacer>
      </form>
    </FilterDetail>
  );
};

export default OrderNoFilter;
