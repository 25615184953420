import classNames from 'classnames';
import React, { ElementType, ReactElement } from 'react';
import { Props as GridItemProps } from './LayoutGridItem';
import './layout-grid.scss';
import './../layout.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  gridTag?: ElementType;
  tag?: ElementType;
  children: Array<ReactElement<GridItemProps>>;
  gridProps?: React.HTMLAttributes<HTMLDivElement>;
}

const LayoutGrid = ({
  children,
  gridTag: GridTag = 'div',
  tag: Tag = 'div',
  className,
  gridProps,
  ...props
}: Props) => {
  return (
    <Tag className="layout layout--grid" {...props}>
      <div className="layout__inner">
        <GridTag
          {...gridProps}
          className={classNames('layout-grid', className)}
        >
          {children}
        </GridTag>
      </div>
    </Tag>
  );
};

export default LayoutGrid;
