export enum Color {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'confirm' = 'confirm',
  'warning' = 'warning',
  'process' = 'process',
  'dark' = 'dark',
}

export enum ColorUse {
  // PRIMARY - primary,action
  'primary' = 'primary',
  'primary-75' = 'primary-75',
  'primary-50' = 'primary-50',
  'primary-25' = 'primary-25',
  'primary-petrol' = 'primary-petrol',
  'primary-rgb' = 'primary-rgb',
  'primary-rgb-75' = 'primary-rgb-75',
  'primary-rgb-50' = 'primary-rgb-50',
  'primary-rgb-25' = 'primary-rgb-25',
  'primary-rgb-petrol' = 'primary-rgb-petrol',
  // SECONDARY - emphasis, highlighting
  'secondary' = 'secondary',
  'secondary-75' = 'secondary-75',
  'secondary-50' = 'secondary-50',
  'secondary-25' = 'secondary-25',
  'secondary-rgb' = 'secondary-rgb',
  'secondary-rgb-75' = 'secondary-rgb-75',
  'secondary-rgb-50' = 'secondary-rgb-50',
  'secondary-rgb-25' = 'secondary-rgb-25',
  // CONFIRM - affirmation, positive
  'confirm' = 'confirm',
  'confirm-75' = 'confirm-75',
  'confirm-50' = 'confirm-50',
  'confirm-25' = 'confirm-25',
  'confirm-petrol' = 'confirm-petrol',
  'confirm-rgb' = 'confirm-rgb',
  'confirm-rgb-75' = 'confirm-rgb-75',
  'confirm-rgb-50' = 'confirm-rgb-50',
  'confirm-rgb-25' = 'confirm-rgb-25',
  'confirm-rgb-petrol' = 'confirm-rgb-petrol',
  // WARNING - attention, negative
  'warning' = 'warning',
  'warning-75' = 'warning-75',
  'warning-50' = 'warning-50',
  'warning-25' = 'warning-25',
  'warning-petrol' = 'warning-petrol',
  'warning-rgb' = 'warning-rgb',
  'warning-rgb-75' = 'warning-rgb-75',
  'warning-rgb-50' = 'warning-rgb-50',
  'warning-rgb-25' = 'warning-rgb-25',
  'warning-rgb-petrol' = 'warning-rgb-petrol',
  // PROCESS - processing, waiting
  'process' = 'process',
  'process-75' = 'process-75',
  'process-50' = 'process-50',
  'process-25' = 'process-25',
  // DARK - Text, Lines
  'dark' = 'dark',
  'dark-80' = 'dark-80',
  'dark-50' = 'dark-50',
  'dark-20' = 'dark-20',
  'dark-10' = 'dark-10',
  'dark-rgb' = 'dark-rgb',
  'dark-rgb-80' = 'dark-rgb-80',
  'dark-rgb-50' = 'dark-rgb-50',
  'dark-rgb-20' = 'dark-rgb-20',
  'dark-rgb-10' = 'dark-rgb-10',
}

export enum ColorName {
  'white' = 'white',
  'lavender' = 'lavender',
  'lavender-75' = 'lavender-75',
  'lavender-50' = 'lavender-50',
  'lavender-25' = 'lavender-25',
  'lavender-petrol' = 'lavender-petrol',
  'rgba-lavender' = 'rgba-lavender',
  'rgba-lavender-75' = 'rgba-lavender-75',
  'rgba-lavender-50' = 'rgba-lavender-50',
  'rgba-lavender-25' = 'rgba-lavender-25',
  'rgba-lavender-petrol' = 'rgba-lavender-petrol',
  'rose' = 'rose',
  'rose-75' = 'rose-75',
  'rose-50' = 'rose-50',
  'rose-25' = 'rose-25',
  'rgba-rose' = 'rgba-rose',
  'rgba-rose-75' = 'rgba-rose-75',
  'rgba-rose-50' = 'rgba-rose-50',
  'rgba-rose-25' = 'rgba-rose-25',
  'pale-beryl' = 'pale-beryl',
  'pale-beryl-75' = 'pale-beryl-75',
  'pale-beryl-50' = 'pale-beryl-50',
  'pale-beryl-25' = 'pale-beryl-25',
  'pale-beryl-petrol' = 'pale-beryl-petrol',
  'rgba-pale-beryl' = 'rgba-pale-beryl',
  'rgba-pale-beryl-75' = 'rgba-pale-beryl-75',
  'rgba-pale-beryl-50' = 'rgba-pale-beryl-50',
  'rgba-pale-beryl-25' = 'rgba-pale-beryl-25',
  'rgba-pale-beryl-petrol' = 'rgba-pale-beryl-petrol',
  'pink' = 'pink',
  'pink-75' = 'pink-75',
  'pink-50' = 'pink-50',
  'pink-25' = 'pink-25',
  'pink-petrol' = 'pink-petrol',
  'rgba-pink' = 'rgba-pink',
  'rgba-pink-75' = 'rgba-pink-75',
  'rgba-pink-50' = 'rgba-pink-50',
  'rgba-pink-25' = 'rgba-pink-25',
  'rgba-pink-petrol' = 'rgba-pink-petrol',
  'black' = 'black',
  'black-80' = 'black-80',
  'black-50' = 'black-50',
  'black-20' = 'black-20',
  'black-10' = 'black-10',
  'rgba-black-80' = 'rgba-black-80',
  'rgba-black-50' = 'rgba-black-50',
  'rgba-black-20' = 'rgba-black-20',
  'rgba-black-10' = 'rgba-black-10',
  'gold' = 'gold',
  'gold-75' = 'gold-75',
  'gold-50' = 'gold-50',
  'gold-25' = 'gold-25',
}

export default Color;
