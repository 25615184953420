import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FieldProps, getIn } from 'formik';
import { ChangeEvent, FC } from 'react';
import InputInlineEdit, { Props } from './InputInlineEdit';

/** Text Input Formik Field with submit and cancel button for inline editing.
 * Use this as a component prop in a Formik Field. */
const InputInlineEditField: FC<Props & FieldProps> = ({
  field,
  form: { setFieldValue, validateField, initialValues, setFieldError },
  ...props
}) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
  ) => {
    setFieldValue(field.name, event.target.value);
    validateField(field.name);
    // field.onChange?.(event);
  };

  const handleEditCancel = () => {
    setFieldValue(field.value, getIn(initialValues, field.value), true);
    setFieldError('name', undefined);
    props.onEditCancel?.();
  };

  return (
    <ErrorBoundary>
      <InputInlineEdit
        {...field}
        {...props}
        onChange={handleChange}
        onEditCancel={handleEditCancel}
      />
    </ErrorBoundary>
  );
};

export default InputInlineEditField;
