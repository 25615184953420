import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import useAppStatus from '../../hooks/useAppStatus';
import './link-code.scss';

export interface Props {
  link: string;
  copyToClipBoard?: boolean;
  copyToClipBoardText?: string;
  className?: string;
  copyLink?: string;
  onClick?: () => void;
}

const LinkCode = ({
  link,
  copyToClipBoard,
  copyToClipBoardText,
  className,
  copyLink,
  onClick,
}: Props) => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'settings']);

  const handleWriteToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setAppStatus(t('settings:success.clipboard_write'), 'success', true);
      },
      () => {},
    );
  };

  return copyToClipBoard ? (
    <Tooltip
      className="link-code__wrap"
      content={copyToClipBoardText || t('copyToClipboard')}
    >
      <button
        className={classNames('link-code', className)}
        type="button"
        onClick={() => handleWriteToClipboard(copyLink || link)}
      >
        <span>{link}</span>
      </button>
    </Tooltip>
  ) : (
    <>
      {!onClick && (
        <div className={classNames('link-code', className)}>
          <span>{link}</span>
        </div>
      )}
      {onClick && (
        <button
          className={classNames('link-code', className)}
          onClick={onClick}
          type="button"
        >
          <span>{link}</span>
        </button>
      )}
    </>
  );
};

export default LinkCode;
