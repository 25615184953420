import React from 'react';

const HeaderActiveIcon = () => (
  <div className="header__item-icon">
    <svg width="0" height="0" viewBox="0 0 200 200">
      <defs>
        <clipPath id="activeClip">
          <path d="M14.7 1.43a5.4 5.4 0 11-5.2 9.42 5.31 5.31 0 01-3.08.97 5.37 5.37 0 01-5-3.3A5.4 5.4 0 019.5 1.99a5.38 5.38 0 015.2-.55zm-8.28 1.3v.01a3.63 3.63 0 00-2.57 1.07 3.74 3.74 0 000 5.19 3.61 3.61 0 004.36.59 5.4 5.4 0 01.02-6.36 3.53 3.53 0 00-1.81-.5zm6.18 0v.01a3.63 3.63 0 00-1.83.49 5.4 5.4 0 01.02 6.35 3.61 3.61 0 003.22.19 3.68 3.68 0 001.15-5.96 3.55 3.55 0 00-2.56-1.07zM9.5 4.5l-.04.07A3.74 3.74 0 009.5 8.3a3.68 3.68 0 00.01-3.82z" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default HeaderActiveIcon;
