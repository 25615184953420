import Button from 'components/Button/Button';
import InputField from 'components/Input/InputField';
import { SelectSupportedLanguages } from 'features/language';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import Language from 'types/Language';
import * as Yup from 'yup';

export interface Props {
  editable?: {
    onEditStart?: () => void;
    onEditSave: (values: Values) => void;
  };
  values: Values;
  id: number;
}

export type Values = {
  name: string;
  language: Language;
};

const MediathekItemEdit = ({ editable, values, id }: Props) => {
  const language = useReduxSelector(selectLanguageCode);

  const { t } = useTranslation(['translation', 'video', 'channel']);
  const [initialValues, setInitialValues] = useState<Values>({
    name: values.name,
    language: values.language || language,
  });

  const nameSchema = Yup.object().shape({
    name: Yup.string().required(t('form.required')),
  });

  const handleEditSave = (values: Values) => {
    editable?.onEditSave(values);
  };

  useEffect(() => {
    if (language) {
      setInitialValues((pre) => ({
        ...pre,
        language: language as Language,
      }));
    }
  }, [language]);

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    formik: FormikProps<Values>,
  ) => {
    formik.setFieldValue('language', event.target.value);
  };

  return (
    <div className="mediathek-item__label">
      <Formik
        initialValues={initialValues}
        onSubmit={handleEditSave}
        validationSchema={nameSchema}
        enableReinitialize
      >
        {(formik: FormikProps<Values>) => (
          <Form>
            <div className="mediathek-item__fields">
              <Field
                name="name"
                component={InputField}
                label={t('video:name.label')}
              />

              <SelectSupportedLanguages
                onChange={(event) => handleLanguageChange(event, formik)}
                value={formik.values.language}
              />
              <Button text={t('save')} type="submit" color={Color.primary} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MediathekItemEdit;
