import ChannelSelect from 'components/ChannelSelect/ChannelSelect';
import Chip from 'components/Chip/Chip';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import { OptionWithIconProps } from 'components/SelectInteractive';
import { useGetMinimalChannels } from 'features/channel-list';
import { useGetVideo } from 'features/video-detail';
import {
  ApiError,
  ChannelMinimalResponseDTO,
  UpdateVideoTypeConflictResponseDTO,
} from 'generated';
import { useState } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { ColorUse } from 'types/Color';
import { Variables, useUpdateVideoChannels } from '../queries';
import '../video-channels.scss';
import VideoChannelItem from './VideoChannelItem';

export type ChannelData = ChannelMinimalResponseDTO & {
  numberOfVideos?: number;
  conflict?: boolean;
  iconUrl?: string | null;
};

/**
 * Component to display and controll the channels of a video.
 */
const VideoChannels = ({ videoId }: { videoId: number }) => {
  const { t } = useTranslation(['translation', 'video']);
  const { data: video, isLoading } = useGetVideo(videoId);
  const [select, setSelect] = useState<OptionWithIconProps | null>();
  const mutation = useUpdateVideoChannels();
  const { data: allChannels } = useGetMinimalChannels();
  const [conflict, setConflict] = useState<number[]>();

  // using a seperate error state, because the mutation can have two different errors
  const [error, setError] = useState();

  const afterMutate = {
    onError: (error: ApiError, variables: Variables) => {
      if (error.status === 409) {
        const conflict = error.body as UpdateVideoTypeConflictResponseDTO;
        setConflict(conflict.channelIds);
      } else {
        setError(
          !conflict || conflict?.length === 0
            ? t('inline-edit.error')
            : undefined,
        );
      }
    },
    onSettled: () => {
      setSelect(null);
      mutation.reset();
    },
    onSuccess: () => {
      setConflict(undefined);
    },
  };

  const handleRemoveChannel = (channel: ChannelMinimalResponseDTO) => {
    mutation.mutate(
      {
        videoId,
        channels: video?.channels?.filter((i) => i.id !== channel.id),
      },
      afterMutate,
    );
    // }
  };

  const handleAddChannel = (
    channel: ChannelMinimalResponseDTO,
    force?: boolean,
  ) => {
    mutation.reset();

    // Because of optimistic updates the channel could already have been added
    const channels = video?.channels?.includes(channel)
      ? video?.channels
      : video?.channels?.concat(channel);
    mutation.mutate(
      {
        videoId,
        channels,
        force,
      },
      afterMutate,
    );
  };

  const handleChange = (newValue: SingleValue<OptionWithIconProps>) => {
    mutation.reset();
    setError(undefined);
    setSelect(newValue as OptionWithIconProps);
    const channel = allChannels?.find(
      (i) => i.id.toString() === newValue?.value,
    );
    if (channel) {
      handleAddChannel(channel);
    }
  };

  return (
    <ErrorBoundary>
      <Flipper flipKey={video?.channels?.toString()}>
        <div className="video-channels">
          {error && <Chip color={ColorUse['warning-50']}>{error}</Chip>}
          {mutation.isLoading && (
            <Spacer marginTop={-2} marginBottom={2}>
              <Loader small />
            </Spacer>
          )}
          {video?.channels?.length === 0 && (
            <Spacer tag="p" className="small">
              {t('video:channel.no-channels')}
            </Spacer>
          )}
          {isLoading && <Loader small />}
          {!isLoading &&
            video?.channels?.map((c) => (
              <VideoChannelItem
                conflict={conflict?.includes(c.id)}
                channel={c}
                key={c.id}
                onRemoveChannel={handleRemoveChannel}
                onAddChannel={handleAddChannel}
              />
            ))}

          {(!conflict || conflict?.length === 0) && (
            <Spacer marginTop={2}>
              <ChannelSelect
                hiddenLabel
                className="video-channels__select"
                value={select}
                onChange={handleChange}
                channels={allChannels?.filter(
                  (i) => !video?.channels?.map((c) => c.id).includes(i.id),
                )}
                noOptionsMessage={t('video:channel.no-options')}
                indicatorIcon="add-filled"
                menuPlacement="top"
              />
            </Spacer>
          )}
        </div>
      </Flipper>
    </ErrorBoundary>
  );
};

export default VideoChannels;
