import Chip from 'components/Chip/Chip';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FlexBox } from 'components/Layout';
import ListItemNested from 'components/ListItemNested/ListItemNested';
import Navi from 'components/Navi/Navi';
import Steps from 'components/Steps/Steps';
import useSidebar from 'hooks/useSidebar';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useVideoFilter from '../hooks/useVideoFilter';
import { FilterName } from '../types';
import CategoryFilter from './components/CategoryFilter';
import ChannelFilter from './components/ChannelFilter';
import HashtagFilter from './components/HashtagFilter';
import ProductFilter from './components/ProductFilter';
import StatusFilter from './components/StatusFilter';
import TypeFilter from './components/TypeFilter';
import VisibilityFilter from './components/VisibilityFilter';
import './video-filter-side-bar.scss';

// TODO use new generic filter feature
const VideoFilterSideBar = () => {
  const { t } = useTranslation(['product']);
  const [showPanel, setShowPanel] = useState<string[]>(['index']);
  const [caption, setCaption] = useState<string>(t('video:filter.placeholder'));
  const navigate = useNavigate();
  const { closeSidebar } = useSidebar();
  const { filter, resetFilter, resetFilterByKey } = useVideoFilter();

  const nav: {
    [key in FilterName]?: { caption: string; component: ReactNode };
  } = {
    channel: {
      caption: t('video:filter.channel.caption'),
      component: <ChannelFilter />,
    },
    product: {
      caption: t('video:filter.product.caption'),
      component: <ProductFilter />,
    },
    category: {
      caption: t('video:filter.category.caption'),
      component: <CategoryFilter />,
    },
    hashtag: {
      caption: t('video:filter.hashtag.caption'),
      component: <HashtagFilter />,
    },
    type: {
      caption: t('video:filter.type.caption'),
      component: <TypeFilter />,
    },
    status: {
      caption: t('video:filter.status.caption'),
      component: <StatusFilter />,
    },
    visibility: {
      caption: t('video:filter.visibility.caption'),
      component: <VisibilityFilter />,
    },
  };

  const navigateBack = () => {
    setShowPanel(showPanel.slice(0, -1));
  };

  const handleClose = () => {
    closeSidebar();
    navigate('/videos');
  };

  const navigateTo = (key: FilterName) => {
    setShowPanel([...showPanel, key]);
    setCaption(nav?.[key]?.caption || t('video:filter.placeholder'));
  };

  const handleReset = () => {
    if (showPanel.length > 1) {
      resetFilterByKey(showPanel[showPanel.length - 1] as FilterName);
      return;
    } else {
      resetFilter();
    }
  };

  return (
    <div className="video-filter-side-bar">
      <Navi
        forwardKey={showPanel.toString()}
        onBackClick={navigateBack}
        onClose={handleClose}
        childCenterSlot={<span className="caption">{caption}</span>}
        childRouteIsActive={showPanel.length > 1}
        parentCenterSlot={
          <span className="caption--lavender">
            {t('video:filter.placeholder')}
          </span>
        }
        parentRightSlot={<div style={{ width: 20 }}></div>}
        childRightSlot={<div style={{ width: 20 }}></div>}
      />
      <Steps stepKey={showPanel.toString()} tag="ul">
        {showPanel.length <= 1 ? (
          Object.keys(nav).map((key) => (
            <ListItemNested
              key={key}
              title={nav?.[key]?.caption}
              onClick={() => navigateTo(key)}
            >
              {(filter?.[key]?.length || 0) > 0 && (
                <Chip>{filter?.[key]?.length}</Chip>
              )}
            </ListItemNested>
          ))
        ) : (
          <ErrorBoundary>
            {nav[showPanel[showPanel.length - 1] as FilterName]?.component}
          </ErrorBoundary>
        )}
      </Steps>

      <div className="video-filter-side-bar__footer">
        <FlexBox justifyContent="flex-end" alignItems="center" paddingRight={3}>
          <button
            className="caption--primary video-filter-side-bar__reset"
            onClick={handleReset}
          >
            {t('video:filter.reset')}
          </button>
        </FlexBox>
      </div>
    </div>
  );
};

export default VideoFilterSideBar;
