import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import Modal from 'components/Modal/Modal';
import Steps from 'components/Steps/Steps';
import { CategoriesResponseDto } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import randomString from 'utils/random-string';
import CategoryBranch from '../../../components/CategoryBranch/CategoryBranch';
import { useGetCategories } from '../queries';
import './categories.scss';
import CategegoriesHeader from './components/CategoriesHeader';
import CategoriesList from './components/CategoriesList';
import CategoryTree from './components/CategoryTree';

export type Props = {
  onSelectChange: (value: CategoriesResponseDto[]) => void;
  value: CategoriesResponseDto[];
  scrollTargetId?: string;
};

const Categories = ({
  onSelectChange,
  value,
  scrollTargetId: _scrollTargetId,
}: Props) => {
  const { t } = useTranslation(['translation', 'product']);
  const { open, close, isOpen } = useOpen(false);
  const isDesktop = useMediaQuery(`(min-width:${BREAKPOINTS.m} )`);
  const scrollTargetId = _scrollTargetId || `categories--${randomString()}`;
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [rootCategory, setRootCategory] = useState<CategoriesResponseDto>(
    value?.[0]?.parents?.map((i) => ({ ...i, hasChildren: true }))[0] || {
      id: 0,
      name: '',
      hasChildren: false,
    },
  );
  // Using an internal state here so we can reset to the old value if needed
  const [selectedItems, setSelectedItems] =
    useState<CategoriesResponseDto[]>(value);
  const { searchableProps, debouncedSearchTerm, setInputValue } =
    useSearchableSelect({
      onInputChange: () => {
        handleCloseTree();
        setSelectedItems([]);
      },
    });
  const getCategoriesQuery = useGetCategories(debouncedSearchTerm as string);
  const { status } = getCategoriesQuery;

  useEffect(() => {
    setSelectedItems(value);
    if (isDesktop) {
      setRootCategory(
        value?.[0]?.parents?.map((i) => ({ ...i, hasChildren: true }))[0] || {
          id: 0,
          name: '',
          hasChildren: false,
        },
      );
    }
  }, [value]);

  const handleOpenInitialTreeWithSelection = (
    selectedCategory: CategoriesResponseDto,
  ) => {
    setInputValue('');
    setSelectedItems([selectedCategory]);
    setRootCategory(
      selectedCategory.parents?.map((i) => ({
        ...i,
        hasChildren: true,
      }))[0] || {
        id: 0,
        name: '',
        hasChildren: false,
      },
    );
  };

  const handleOpenTree = (rootCategory: CategoriesResponseDto) => {
    setRootCategory(rootCategory);
    setSelectedItems([rootCategory]);
  };

  const handleCloseTree = () => {
    setRootCategory({
      id: 0,
      name: '',
      hasChildren: false,
    });
  };

  const handleSelect = (item: CategoriesResponseDto[]) => {
    setSelectedItems([
      {
        ...item.slice(-1)[0],
        parents: item.slice(0, -1),
      },
    ]);
  };

  const handleSetValue = () => {
    onSelectChange(selectedItems);
    handleClose();
  };

  const handleResetValue = () => {
    setSelectedItems(value);

    if (isDesktop) {
      setRootCategory(
        value?.[0]?.parents?.map((i) => ({ ...i, hasChildren: true }))[0] || {
          id: 0,
          name: '',
          hasChildren: false,
        },
      );
    }
  };

  const handleClose = () => {
    setInputValue('');
    handleCloseTree();
    close();
  };

  const handleCancel = () => {
    handleClose();
    handleResetValue();
  };
  /**
   * The old data structure allowed multiple categories, that we will get rid of here and only use the first one
   * @returns The selected Categegories as a sorted array instead of a nested category
   */
  const getValue = () => {
    const parents =
      selectedItems?.[0]?.parents?.map((i) => ({ ...i, hasChildren: true })) ||
      [];
    return [...parents, selectedItems[0]].filter((i) => i !== undefined);
  };

  const handleScroll = () => {
    scrollTargetRef.current?.scrollTo({
      left: scrollTargetRef.current?.scrollWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  const listProps = {
    value: getValue(),
    onSelectChange: handleSelect,
    onClose: handleCancel,
    scrollTargetId,
    onOpenTree:
      isDesktop && !!debouncedSearchTerm
        ? handleOpenInitialTreeWithSelection
        : handleOpenTree,
    debouncedSearchTerm,
    searchableProps,
    query: getCategoriesQuery,
  };

  const treeProps = {
    onSelectChange: handleSelect,
    value: getValue(),
    rootCategory,
    onClose: handleCloseTree,
    onOpenPanel: handleScroll,
    key: rootCategory.id,
  };

  return (
    <div className="categories">
      {selectedItems.map((v) => (
        <Spacer margin={[6, 0, 8]} key={v.id}>
          <CategoryBranch category={v} />
        </Spacer>
      ))}

      {selectedItems.length > 1 && (
        <Spacer className="bg-secondary" padding={4} tag="p" marginBottom={6}>
          {t('product:categories.legacyHint')}
        </Spacer>
      )}

      {selectedItems.length === 0 && (
        <Spacer tag="p" className="categories__empty" margin={[2, 0, 4]}>
          {t('product:categories.empty')}
        </Spacer>
      )}

      <button
        type="button"
        className="caption--primary categories__button"
        onClick={open}
      >
        {t('product:categories.button')}
      </button>

      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        variant={isDesktop ? 'full' : 'bottom-sheet'}
        scrollTargetId={scrollTargetId}
        headline={t('product:categories.modalTitle')}
        hideHeader={true}
        className="categories__modal"
        ref={scrollTargetRef}
        hideScrollBars={false}
      >
        {isDesktop ? (
          <>
            <CategegoriesHeader
              searchableProps={searchableProps}
              debouncedSearchTerm={debouncedSearchTerm}
              onClose={handleCancel}
              loading={status === 'loading'}
            />
            <FlexBox padding={[6, 4, 4]}>
              <CategoriesList
                {...listProps}
                isSearchResult={!!debouncedSearchTerm}
              />
              {rootCategory.id !== 0 && <CategoryTree {...treeProps} />}
            </FlexBox>
          </>
        ) : (
          <Steps stepKey={rootCategory.id.toString()}>
            {rootCategory.id === 0 ? (
              <CategoriesList {...listProps} />
            ) : (
              <CategoryTree {...treeProps} />
            )}
          </Steps>
        )}
        <footer className="modal__fixed-footer">
          <FlexBox justifyContent="space-between" alignItems="center">
            {isDesktop ? (
              <>
                <Button
                  text={t('cancel')}
                  onClick={handleCancel}
                  color={ColorUse.confirm}
                  appearance="ghost"
                />
                <Button
                  text={t('confirm')}
                  onClick={handleSetValue}
                  color={ColorUse.confirm}
                />
              </>
            ) : (
              <>
                <FlexItem flexGrow={1} marginRight={2}>
                  <CategoryBranch category={selectedItems[0]} />
                </FlexItem>
                <FlexItem marginRight={4}>
                  <IconButton
                    icon="close"
                    onClick={handleCancel}
                    color={ColorUse.confirm}
                    appearance="ghost"
                    big
                  />
                </FlexItem>
                <FlexItem>
                  <IconButton
                    icon="check"
                    onClick={handleSetValue}
                    color={ColorUse.confirm}
                    appearance="filled"
                    big
                  />
                </FlexItem>
              </>
            )}
          </FlexBox>
        </footer>
      </Modal>
    </div>
  );
};

export default Categories;
