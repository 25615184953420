import getSymbolFromCurrency from 'currency-symbol-map';
import { Field, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/Input/InputField';
import TextAreaField from '../../components/Input/TextAreaField';
import LabeledInfoText from '../../components/LabeledInfoText/LabeledInfoText';
import LabelText from '../../components/LabelText/LabelText';
import {
  KeyValueDTO,
  KeyValueStoreService,
  ProductLocalisationResponseDTO,
  ProductResponseDTO,
} from '../../generated';
import { useReduxSelector } from '../../redux/hooks';
import { selectLocaleIso } from '../../redux/slices/i18n/i18nSlice';
import { localeToCurrency } from '../../utils/currency';
import { Spacer } from 'components/Layout';

export interface Props {
  index: number;
  currency?: string;
}

const ProductLocalisation = ({ index, currency }: Props) => {
  const { t } = useTranslation(['product']);
  const field = (fieldName: keyof ProductLocalisationResponseDTO) =>
    `productLocalisations.[${index}].${fieldName}`;
  const { getFieldProps, setFieldValue } =
    useFormikContext<ProductResponseDTO>();
  const localeIso = useReduxSelector(selectLocaleIso);

  // TODO take from kvstore Redux
  const [showDiscount, setShowDiscount] = useState(false);

  const handlePriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: keyof ProductLocalisationResponseDTO,
  ) => {
    setFieldValue?.(
      field(fieldName),
      Math.round(parseFloat(event.target.value) * 100),
    );
  };

  const getShowDiscount = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.ENABLE_DISCOUNT_PRICES,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const discount = async () => {
      const showDiscount = await getShowDiscount();
      setShowDiscount(showDiscount);
    };
    discount();
  }, []);

  return (
    <>
      <Spacer>
        <Spacer>
          <Field
            component={TextAreaField}
            className="form__title"
            placeholder={t('product:placeholder.title')}
            name={field('name')}
            big
          />
          <LabelText
            label={`${t('product:shop_url')}*`}
            className="form__group"
          >
            <Field
              component={InputField}
              placeholder={t('product:placeholder.shop_url')}
              name={field('shopUrl')}
            />
          </LabelText>

          <div className="form__group">
            <LabelText
              label={`${t('product:price', {
                currency: currency
                  ? getSymbolFromCurrency(currency)
                  : getSymbolFromCurrency(localeToCurrency(localeIso) || ''),
              })}*`}
            >
              <Field
                component={InputField}
                step=".01"
                min="0"
                type="number"
                name={field('price')}
                value={
                  isNaN(getFieldProps(field('price')).value)
                    ? ''
                    : getFieldProps(field('price')).value / 100
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePriceChange(e, 'price')
                }
              />
            </LabelText>
          </div>
          {showDiscount && (
            <div className="form__group">
              <LabelText
                label={t('product:discountPrice', {
                  currency: currency
                    ? getSymbolFromCurrency(currency)
                    : getSymbolFromCurrency(localeToCurrency(localeIso) || ''),
                })}
              >
                <p className="page__form-desc">
                  {t('product:discountPrice_desc')}
                </p>
                <Field
                  component={InputField}
                  step=".01"
                  min="0"
                  type="number"
                  name={field('discountPrice')}
                  value={
                    isNaN(getFieldProps(field('discountPrice')).value)
                      ? ''
                      : getFieldProps(field('discountPrice')).value / 100
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handlePriceChange(e, 'discountPrice')
                  }
                />
              </LabelText>
            </div>
          )}
        </Spacer>

        <Spacer paddingTop={5} marginTop={8} borderTop={1}>
          <LabelText label={t('product:info')}>
            <p className="page__form-desc">{t('product:info_text_desc')}</p>
          </LabelText>
          <LabeledInfoText fieldName={field('productInfo')} />
        </Spacer>
      </Spacer>
    </>
  );
};

export default ProductLocalisation;
