import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Status } from '../../container/VideoUploadContainer/VideoUploadContainer';
import finishedLeft from '../../images/upload-finished-left.png';
import finishedRight from '../../images/upload-finished-right.png';
import Icon from '../Icon/Icon';
import './progress.scss';
import ProgressIcon from './ProgressIcon';

export interface Props {
  progress: number;
  title?: string;
  subtitle?: string;
  status: Status;
}

const Progress = ({ progress, title, subtitle, status }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const paleBeryl = 'rgba(150, 224, 218, 1)';
  const lightBlue = 'rgba(200, 212, 236, 1)';
  const rose = 'rgba(234, 204, 248, 1)';
  const darkRose = 'rgba(212, 184, 247, 1)';
  const lavender = 'rgba(147, 126, 243, 1)';
  const white = 'rgba(255, 255, 255, 1)';

  const getGradientString = (gradient: [number, string][]) => {
    let string = `linear-gradient(0deg, ${paleBeryl} 0%,`;

    gradient.forEach(([percentage, color]) => {
      if (percentage < 1) {
        string = `${string} ${color} ${progress * percentage}%,`;
      } else {
        string = `${string} ${color} ${progress - 1}%, ${white} ${progress}%`;
      }
    });

    return string;
  };

  useEffect(() => {
    let gradient: [number, string][] = [[1, lightBlue]];

    if (progress > 25) {
      gradient = [
        [0.5, lightBlue],
        [1, rose],
      ];
    }

    if (progress > 50) {
      gradient = [
        [0.33, lightBlue],
        [0.66, rose],
        [1, darkRose],
      ];
    }

    if (progress > 75) {
      gradient = [
        [0.25, lightBlue],
        [0.5, rose],
        [0.75, darkRose],
        [1, lavender],
      ];
    }

    ref.current?.style.setProperty(
      '--gradientWithWhite',
      getGradientString(gradient),
    );
  }, [progress]);

  return (
    <div className={classNames('progress', `progress--${status}`)}>
      <div className="progress__icon-wrap">
        <div className=" progress__icon" ref={ref}>
          <ProgressIcon />
        </div>
        <div className=" progress__overlay"></div>
        <div className="progress__border">
          <Icon icon="progress-border" />
        </div>
        {status === Status.success && (
          <div className="progress__finished">
            <div className="progress__finished-left">
              <img alt="" src={finishedLeft} width={166} height={148} />
            </div>
            <div className="progress__finished-right">
              <img alt="" src={finishedRight} width={166} height={148} />
            </div>
          </div>
        )}
      </div>

      <div className="progress__text">
        {status === Status.active && (
          <div className="propgress__number">{progress}%</div>
        )}
        {status === Status.exception && (
          <div className="progress__error">
            <Icon icon="info" />
          </div>
        )}
        {title && <div className="progress__title">{title}</div>}
        {subtitle && <div className="progress__subtitle">{subtitle}</div>}
      </div>
    </div>
  );
};

export default Progress;
