import Chip from 'components/Chip/Chip';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { FlexBox } from 'components/Layout';
import ListItemNested from 'components/ListItemNested/ListItemNested';
import Navi from 'components/Navi/Navi';
import Steps from 'components/Steps/Steps';
import useSidebar from 'hooks/useSidebar';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFilter from '../hooks/useFilter';
import './filter-side-bar.scss';
import { FilterItem } from 'features/video-filter';

export type Props<FilterKeys extends string> = {
  nav: {
    [key in FilterKeys]?: { caption: string; component: ReactNode };
  };
  onClose?: () => void;
  title?: string;
};

const FilterSideBar = <FilterKeys extends string, T extends FilterItem>({
  nav,
  onClose,
  title,
}: Props<FilterKeys>) => {
  const { t } = useTranslation(['product']);
  const [showPanel, setShowPanel] = useState<string[]>(['index']);
  const [caption, setCaption] = useState<string>(
    title || t('video:filter.placeholder'),
  );
  const { closeSidebar } = useSidebar();
  const { filter, resetFilter, resetFilterByKey } = useFilter<FilterKeys, T>();

  const navigateBack = () => {
    setShowPanel(showPanel.slice(0, -1));
  };

  const handleClose = () => {
    closeSidebar();
    onClose?.();
  };

  const navigateTo = (key: FilterKeys) => {
    setShowPanel([...showPanel, key]);
    setCaption(nav?.[key]?.caption || t('video:filter.placeholder'));
  };

  const handleReset = () => {
    if (showPanel.length > 1) {
      resetFilterByKey(showPanel[showPanel.length - 1] as FilterKeys);
      return;
    } else {
      resetFilter();
    }
  };

  return (
    <div className="filter-side-bar">
      <Navi
        forwardKey={showPanel.toString()}
        onBackClick={navigateBack}
        onClose={handleClose}
        childCenterSlot={<span className="caption">{caption}</span>}
        childRouteIsActive={showPanel.length > 1}
        parentCenterSlot={
          <span className="caption--lavender">
            {t('video:filter.placeholder')}
          </span>
        }
        parentRightSlot={<div style={{ width: 20 }}></div>}
        childRightSlot={<div style={{ width: 20 }}></div>}
      />
      <Steps stepKey={showPanel.toString()} tag="ul">
        {showPanel.length <= 1 ? (
          Object.keys(nav).map((key) => (
            <ListItemNested
              key={key}
              title={nav?.[key]?.caption}
              onClick={() => navigateTo(key as FilterKeys)}
            >
              {(filter?.[key]?.length || 0) > 0 && (
                <Chip>{filter?.[key]?.length}</Chip>
              )}
            </ListItemNested>
          ))
        ) : (
          <ErrorBoundary>
            {nav[showPanel[showPanel.length - 1] as FilterKeys]?.component}
          </ErrorBoundary>
        )}
      </Steps>

      <div className="filter-side-bar__footer">
        <FlexBox justifyContent="flex-end" alignItems="center" paddingRight={3}>
          <button
            className="caption--primary filter-side-bar__reset"
            onClick={handleReset}
          >
            {t('video:filter.reset')}
          </button>
        </FlexBox>
      </div>
    </div>
  );
};

export default FilterSideBar;
