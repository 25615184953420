import classnames from 'classnames';
import ReactTooltip, { TooltipProps } from 'react-tooltip-lite';
import { ColorName } from 'types/Color';
import TooltipPlace from 'types/TooltipPlace';

export interface Props extends TooltipProps {
  direction?: TooltipPlace;
  children: React.ReactNode;
  disabled?: boolean;
}

const Tooltip = ({
  content,
  direction = 'down',
  hoverDelay = 500,
  children,
  disabled,
  ...rest
}: Props) => (
  <ReactTooltip
    content={content}
    direction={direction}
    tipContentClassName={classnames('tooltip', disabled && 'tooltip--disabled')}
    hoverDelay={hoverDelay}
    className="tooltip__wrap"
    arrowContent={
      <svg
        style={{ display: 'block' }}
        width="10"
        height="6"
        viewBox="0 0 10 6"
      >
        <path
          d="m1 5 3.8-3.7c.3-.3.7-.3 1 0L9 5"
          style={{
            stroke: `var(--color-${ColorName['black-20']})`,
            fill: `var(--color-${ColorName.white})`,
          }}
        />
      </svg>
    }
    {...rest}
  >
    {children}
  </ReactTooltip>
);

export default Tooltip;
