/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VendorOrderDto } from './VendorOrderDto';

export type VendorOrderShipmentDto = {
    order?: VendorOrderDto;
    createdAt: string;
    updatedAt?: string | null;
    status: VendorOrderShipmentDto.status;
    type: VendorOrderShipmentDto.type;
    packageWeight?: number | null;
    packageLength?: number | null;
    packageBreadth?: number | null;
    packageHeight?: number | null;
    requestedPickupDate?: string;
};

export namespace VendorOrderShipmentDto {

    export enum status {
        PENDING = 'pending',
        PROGRESS = 'progress',
        CREATED = 'created',
        FULFILLED = 'fulfilled',
        ERROR = 'error',
        CANCELLED = 'cancelled',
        RETURN_CREATED = 'returnCreated',
        RETURN_FULFILLED = 'returnFulfilled',
        RETURN_PROGRESS = 'returnProgress',
    }

    export enum type {
        DELIVERY = 'Delivery',
        RETURN = 'Return',
    }


}

