import { FieldInputProps } from 'formik';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import ValidatePasswordInput from '../../../components/ValidatePasswordInput/ValidatePasswortInput';

const UpdatePasswordForm = ({
  onSubmit,
  oldPasswordLabel,
  oldPasswordError,
  oldPasswordFieldProps,
  newPasswordLabel,
  newPasswordError,
  newPasswordFieldProps,
  submitText,
}: {
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  oldPasswordLabel: string;
  oldPasswordError?: string;
  oldPasswordFieldProps: FieldInputProps<any>;
  newPasswordLabel: string;
  newPasswordError?: string;
  newPasswordFieldProps: FieldInputProps<any>;
  submitText: string;
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="settings__block settings__block--50">
        <Input
          label={oldPasswordLabel}
          placeholder={oldPasswordLabel}
          type="password"
          error={oldPasswordError}
          {...oldPasswordFieldProps}
        />
      </div>

      <div className="settings__block settings__block--50">
        <ValidatePasswordInput
          label={newPasswordLabel}
          placeholder={newPasswordLabel}
          error={newPasswordError}
          {...newPasswordFieldProps}
        />
      </div>

      <Button text={submitText} type="submit" />
    </form>
  );
};

export default UpdatePasswordForm;
