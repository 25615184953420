import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout';
import { VendorCustomerAddressDto } from 'generated';
import { useTranslation } from 'react-i18next';

const Customer = ({
  shippingAdress,
  invoiceAdress,
}: {
  shippingAdress?: VendorCustomerAddressDto;
  invoiceAdress?: VendorCustomerAddressDto;
}) => {
  const { t } = useTranslation(['translation', 'orders']);
  return (
    <>
      <LabelText label={t('orders:detail.customer.shippingAdress')}>
        {shippingAdress?.firstName} {shippingAdress?.lastName}
        <br />
        {shippingAdress?.address1}
        <br />
        {shippingAdress?.zip} {shippingAdress?.city}
        <br />
        {shippingAdress?.country}
      </LabelText>

      {/* Not MVP {invoiceAdress && (
        <Spacer marginTop={8} borderTop={1} paddingTop={8}>
          <LabelText label={t('orders:detail.customer.invoiceAdress')}>
            {invoiceAdress?.firstName} {invoiceAdress?.lastName}
            <br />
            {invoiceAdress?.address1}
            <br />
            {invoiceAdress?.zip} {invoiceAdress?.city}
            <br />
            {invoiceAdress?.country}
          </LabelText>
        </Spacer>
      )} */}
    </>
  );
};

export default Customer;
