import classNames from 'classnames';
import React, { useState } from 'react';
import Headline from '../Headline/Headline';
import Icon from '../Icon/Icon';
import './label-text.scss';

export interface Props {
  label: string;
  collapsible?: boolean;
  initiallyCollapsed?: boolean;
  className?: string;
  children: React.ReactNode;
}

const LabelText = ({
  label,
  children,
  collapsible,
  className,
  initiallyCollapsed = false,
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(initiallyCollapsed);

  if (collapsible) {
    return (
      <div
        className={classNames(
          'label-text',
          collapsed && 'label-text--collapsed',
          className,
        )}
      >
        <button
          className="caption label-text__label"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <span>{label}</span>
          <span className="label-text__icon">
            <Icon icon="caret-down" />
          </span>
        </button>
        <div className="small label-text__text">{children}</div>
      </div>
    );
  }
  return (
    <div className={classNames('label-text', className)}>
      <h3 className="caption label-text__label">{label}</h3>
      <div className="small label-text__text">{children}</div>
    </div>
  );
};

export default LabelText;
