/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternalCustomerAddressDto } from './InternalCustomerAddressDto';
import type { InternalCustomerDeviceDto } from './InternalCustomerDeviceDto';

export type InternalCustomerDto = {
    createdAt: string;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    deletedDate?: string | null;
    id: number;
    customerNo?: string;
    key: string;
    appsflyerId: string | null;
    recommendation: InternalCustomerDto.recommendation;
    fireBaseUid: string | null;
    firstName: string | null;
    lastName: string | null;
    gender: InternalCustomerDto.gender;
    dateOfBirth: string | null;
    email: string | null;
    telephone: string | null;
    devices: Array<InternalCustomerDeviceDto> | null;
    defaultShippingAddressId: number | null;
    defaultShippingAddress?: InternalCustomerAddressDto;
    defaultInvoiceAddressId: number | null;
    defaultInvoiceAddress?: InternalCustomerAddressDto;
};

export namespace InternalCustomerDto {

    export enum recommendation {
        DEFAULT = 'default',
        NONE = 'none',
        MINI = 'mini',
        AI = 'ai',
    }

    export enum gender {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_9' = 9,
    }


}

