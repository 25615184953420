import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { OptionWithIconProps } from 'components/SelectInteractive';
import { FieldProps } from 'formik';
import { VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { OnChangeValue } from 'react-select';
import onSubmitDontBlur from 'utils/on-submit-dont-blur';
import VisibilitySelect, { getVisibilityIcon, Props } from './VisibilitySelect';

const VisibilitySelectField = ({
  field,
  form: { setFieldValue },
  ...props
}: Props & FieldProps) => {
  const { t } = useTranslation(['translation', 'video']);

  const options = Object.values(VideoResponseDTO.visibility).map((v) => ({
    value: v,
    label: t(`video:visibility.${v}.label`),
    icon: getVisibilityIcon(v),
  }));

  const getValue = () => {
    if (!field.value) {
      return null;
    }
    return options.find((i) => i.value === field.value);
  };

  const onChange = (option: OnChangeValue<OptionWithIconProps, false>) => {
    setFieldValue(field.name, (option as OptionWithIconProps).value);
  };
  return (
    <ErrorBoundary>
      <VisibilitySelect
        {...field}
        {...props}
        value={getValue()}
        onChange={onChange}
        onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
      />
    </ErrorBoundary>
  );
};

export default VisibilitySelectField;
