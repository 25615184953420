import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationLink = ({
  onClick,
  to,
  text,
  icon,
}: {
  onClick: (to?: string) => void;
  to?: string;
  text: string;
  icon?: JSX.Element;
}) => {
  return to ? (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'header__link header__link--active' : 'header__link'
      }
      to={to}
      onClick={() => onClick(to)}
    >
      {icon && <span className="icon header__link-icon">{icon}</span>}
      <span className="header__link-text">{text}</span>
    </NavLink>
  ) : (
    <button className="header__link" type="button" onClick={() => onClick(to)}>
      {icon && <span className="icon header__link-icon">{icon}</span>}

      <span className="header__link-text">{text}</span>
    </button>
  );
};

export default NavigationLink;
