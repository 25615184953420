import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../..';
import {
  LandingPageSettingResponseDTO,
  LandingPageSettingsService,
} from '../../../generated';
import useAppStatus from '../../../hooks/useAppStatus';
import './landing-page.scss';
import LandingPageSettingsForm, {
  LandingPageSettingsFormValues,
} from './LandingPageSettingsForm';

const LandingPageSettingsFormContainer = ({
  activeSetting,
  languageIso,
}: {
  languageIso: string;
  activeSetting?: LandingPageSettingResponseDTO;
}) => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'settings']);

  const addMutation = useMutation(
    LandingPageSettingsService.addLandingpageSetting,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getLandingPageSettings']);
        setAppStatus(
          t('settings:landingPageSettingsForm.success.add'),
          'success',
          true,
        );
      },
      onError: () => {
        setAppStatus(
          t('settings:landingPageSettingsForm.error.add'),
          'error',
          true,
        );
      },
    },
  );

  const edit = useMemo(() => {
    if (activeSetting) {
      const { languageIso, id, ...rest } = activeSetting;
      return rest;
    }
  }, [activeSetting]);

  const handleEdit = async (
    id: number,
    values: LandingPageSettingsFormValues,
  ) => {
    try {
      await LandingPageSettingsService.updateLandingpageSetting(id, {
        ...values,
        languageIso,
      });
      queryClient.invalidateQueries(['getLandingPageSettings']);
      setAppStatus(
        t('settings:landingPageSettingsForm.success.edit'),
        'success',
        true,
      );
      return;
    } catch (error) {
      setAppStatus(
        t('settings:landingPageSettingsForm.error.edit'),
        'error',
        true,
      );
    }
  };

  const handleSubmit = async (values: LandingPageSettingsFormValues) => {
    if (edit && activeSetting) {
      await handleEdit(activeSetting.id, values);
      return;
    }

    addMutation.mutate({ ...values, languageIso });
  };

  return <LandingPageSettingsForm onSubmit={handleSubmit} edit={edit} />;
};

export default LandingPageSettingsFormContainer;
