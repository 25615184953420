/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternalCurrencyDto } from './InternalCurrencyDto';
import type { InternalCustomerAddressDto } from './InternalCustomerAddressDto';
import type { InternalCustomerDto } from './InternalCustomerDto';
import type { InternalOrderBasicChannelDto } from './InternalOrderBasicChannelDto';
import type { InternalOrderBasicProductDto } from './InternalOrderBasicProductDto';
import type { InternalOrderItemDto } from './InternalOrderItemDto';
import type { InternalOrderShipmentDto } from './InternalOrderShipmentDto';
import type { InternalOrderStatusDto } from './InternalOrderStatusDto';
import type { InternalPaymentDto } from './InternalPaymentDto';
import type { InternalVendorAddressDto } from './InternalVendorAddressDto';
import type { InternalVendorDto } from './InternalVendorDto';
import type { TotalPricingDto } from './TotalPricingDto';

export type InternalOrderDto = {
    paymentMethod: InternalOrderDto.paymentMethod | null;
    paymentProvider: InternalOrderDto.paymentProvider;
    /**
     * The channel property is only available until we have more than one item per order.
     */
    channel?: InternalOrderBasicChannelDto;
    /**
     * The product property is only available until we have more than one item per order.
     */
    product?: InternalOrderBasicProductDto;
    /**
     * (use pricing instead) The sum of all order items total gross costs. This value is calculated each time the order is fetched.
     * @deprecated
     */
    totalGrossCost: number;
    /**
     * (use pricing instead)
     * @deprecated
     */
    totalGrossCostLocalized: string;
    createdAt?: string;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    deletedDate?: string | null;
    id: number;
    orderNo: string | null;
    comment: string | null;
    shipments?: Array<InternalOrderShipmentDto>;
    appCustomerId: number;
    appCustomer?: InternalCustomerDto;
    currencyId: number;
    currency?: InternalCurrencyDto;
    pickupAddressId: number | null;
    pickupAddress?: InternalVendorAddressDto;
    shippingAddressId: number | null;
    shippingAddress?: InternalCustomerAddressDto;
    invoiceAddressId: number | null;
    invoiceAddress?: InternalCustomerAddressDto;
    pricing: TotalPricingDto;
    orderItems: Array<InternalOrderItemDto>;
    vendorId: number;
    vendor?: InternalVendorDto;
    orderStatusHistory: Array<InternalOrderStatusDto>;
    currentOrderStatus: InternalOrderStatusDto | null;
    possibleOrderStatusActions: Array<'purchaseBasketItems' | 'paidBasketItems' | 'createShipment' | 'cancelShipment' | 'cancelOrderBeforeShipping' | 'pickupOrderFromWarehouse' | 'deliverOrderToCustomer' | 'notDeliveredOrder' | 'doPaymentCOD' | 'closeOrder' | 'lostOrder' | 'requestReturnByCustomer' | 'createReturnShipment' | 'pickupOrderFromCustomer' | 'rejectReturnRequest' | 'deliverOrderToWarehouse' | 'refundPayment'>;
    paymentId?: number | null;
    payment?: InternalPaymentDto;
    appsFlyerId?: string | null;
    appInstanceId?: string | null;
    appCustomerInstanceId?: string | null;
    trackingId?: string | null;
};

export namespace InternalOrderDto {

    export enum paymentMethod {
        CASH_ON_DELIVERY = 'cashOnDelivery',
        ACSS_DEBIT = 'acss_debit',
        AFFIRM = 'affirm',
        AFTERPAY_CLEARPAY = 'afterpay_clearpay',
        ALIPAY = 'alipay',
        AU_BECS_DEBIT = 'au_becs_debit',
        BACS_DEBIT = 'bacs_debit',
        BANCONTACT = 'bancontact',
        BLIK = 'blik',
        BOLETO = 'boleto',
        CARD = 'card',
        CASHAPP = 'cashapp',
        CUSTOMER_BALANCE = 'customer_balance',
        EPS = 'eps',
        FPX = 'fpx',
        GIROPAY = 'giropay',
        GRABPAY = 'grabpay',
        IDEAL = 'ideal',
        KLARNA = 'klarna',
        KONBINI = 'konbini',
        LINK = 'link',
        OXXO = 'oxxo',
        P24 = 'p24',
        PAYNOW = 'paynow',
        PAYPAL = 'paypal',
        PIX = 'pix',
        PROMPTPAY = 'promptpay',
        SEPA_DEBIT = 'sepa_debit',
        SOFORT = 'sofort',
        US_BANK_ACCOUNT = 'us_bank_account',
        WECHAT_PAY = 'wechat_pay',
        ZIP = 'zip',
    }

    export enum paymentProvider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }


}

