import { OptionWithIconProps } from 'components/SelectInteractive';
import { InfiniteScrollProps } from 'components/SelectInteractive/SelectInteractive';
import StatusSelect from 'components/StatusSelect/StatusSelect';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { FilterItem } from 'features/video-filter/types';
import { VideoResponseDTO } from 'generated';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useState } from 'react';
import { MultiValue } from 'react-select';
import { useGetStatusFilter } from '../../queries';
import FilterDetail from './FilterDetail';

const StatusFilter = () => {
  const { setFilter, filter } = useVideoFilter();
  const [isInfinite, setIsInfinite] = useState(true);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetStatusFilter(
    debouncedSearchTerm as string,
  );

  const handleChange = (newValue: MultiValue<OptionWithIconProps>) => {
    setFilter(
      'status',
      newValue?.map(
        ({ value, label }) =>
          ({
            id: value,
            key: value,
            name: label,
            chipProps: {
              status: value as VideoResponseDTO.status,
            },
          } as FilterItem),
      ),
    );
  };

  const getValue = () => {
    return (
      filter?.status?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
          icon: <VideoStatusIcon status={id as VideoResponseDTO.status} />,
        };
      }) || []
    );
  };

  const loadMore = () => {
    fetchNextPage();
  };

  const infiniteScrollProps: InfiniteScrollProps = {
    dataLength: data?.pages.map((i) => i.data).flat().length || 0,
    next: () => loadMore(),
    hasMore: hasNextPage || false,
  };

  const infiniteProps = isInfinite
    ? {
        infiniteScrollProps,
        filterOption: () => true,
        ...searchableProps,
      }
    : {};

  useEffect(() => {
    // when there are not more types then the limit of 10, the clientside search can be used
    const totals = data?.pages[data?.pages.length - 1]?.total;
    if (totals && totals < 10) {
      setIsInfinite(false);
    }
  }, [data]);

  return (
    <FilterDetail>
      <StatusSelect
        {...infiniteProps}
        isMulti
        variant="open"
        options={
          data?.pages
            .map((i) => i.data)
            .flat()
            .map((i) => i.status) || []
        }
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        infiniteScrollTargetId="sidebar--scroll-target"
        isOpenSelect
        name="type-filter"
      />
    </FilterDetail>
  );
};

export default StatusFilter;
