/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppPaginatedCitiesDto } from '../models/AppPaginatedCitiesDto';
import type { AppPaginatedCountriesDto } from '../models/AppPaginatedCountriesDto';
import type { AppPaginatedStatesDto } from '../models/AppPaginatedStatesDto';
import type { VendorAddressDto } from '../models/VendorAddressDto';
import type { VendorAddressRequestDto } from '../models/VendorAddressRequestDto';
import type { VendorPaginatedPostCodesDto } from '../models/VendorPaginatedPostCodesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorAddressService {

    /**
     * @param requestBody
     * @returns VendorAddressDto
     * @throws ApiError
     */
    public static createAddress(
        requestBody: VendorAddressRequestDto,
    ): CancelablePromise<VendorAddressDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendor/address',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns VendorAddressDto
     * @throws ApiError
     */
    public static getAddresses(): CancelablePromise<Array<VendorAddressDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/address',
        });
    }

    /**
     * @param offset
     * @param limit
     * @param sortBy
     * @param sortDirection
     * @param searchTerm
     * @returns AppPaginatedCountriesDto
     * @throws ApiError
     */
    public static searchCountries(
        offset?: number,
        limit: number = 15,
        sortBy: string = 'name',
        sortDirection: 'ASC' | 'DESC' = 'ASC',
        searchTerm?: string,
    ): CancelablePromise<AppPaginatedCountriesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/address/country/search',
            query: {
                'offset': offset,
                'limit': limit,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * @param countryId
     * @param offset
     * @param limit
     * @param sortBy
     * @param sortDirection
     * @param searchTerm
     * @returns AppPaginatedStatesDto
     * @throws ApiError
     */
    public static searchStates(
        countryId: number,
        offset?: number,
        limit: number = 15,
        sortBy: string = 'name',
        sortDirection: 'ASC' | 'DESC' = 'ASC',
        searchTerm?: string,
    ): CancelablePromise<AppPaginatedStatesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/address/state/search',
            query: {
                'offset': offset,
                'limit': limit,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'searchTerm': searchTerm,
                'countryId': countryId,
            },
        });
    }

    /**
     * @param stateId
     * @param offset
     * @param limit
     * @param sortBy
     * @param sortDirection
     * @param searchTerm
     * @returns AppPaginatedCitiesDto
     * @throws ApiError
     */
    public static searchCities(
        stateId: number,
        offset?: number,
        limit: number = 15,
        sortBy: string = 'name',
        sortDirection: 'ASC' | 'DESC' = 'ASC',
        searchTerm?: string,
    ): CancelablePromise<AppPaginatedCitiesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/address/city/search',
            query: {
                'offset': offset,
                'limit': limit,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'searchTerm': searchTerm,
                'stateId': stateId,
            },
        });
    }

    /**
     * @param countryIso2Code
     * @param offset
     * @param limit
     * @param sortBy
     * @param sortDirection
     * @param searchTerm
     * @returns VendorPaginatedPostCodesDto
     * @throws ApiError
     */
    public static searchPostCodes(
        countryIso2Code: string,
        offset?: number,
        limit: number = 15,
        sortBy: string = 'name',
        sortDirection: 'ASC' | 'DESC' = 'ASC',
        searchTerm?: string,
    ): CancelablePromise<VendorPaginatedPostCodesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendor/address/post-code/search',
            query: {
                'offset': offset,
                'limit': limit,
                'sortBy': sortBy,
                'sortDirection': sortDirection,
                'searchTerm': searchTerm,
                'countryIso2Code': countryIso2Code,
            },
        });
    }

}
