/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LoginCustomerPropertiesDTO = {
    customerNo: string;
    token: string;
    recommendation: LoginCustomerPropertiesDTO.recommendation;
    appCustomerKey: string;
    requestOnboarding?: boolean;
};

export namespace LoginCustomerPropertiesDTO {

    export enum recommendation {
        DEFAULT = 'default',
        NONE = 'none',
        MINI = 'mini',
        AI = 'ai',
    }


}

