import { UseMutationResult } from '@tanstack/react-query';
import LabelText from 'components/LabelText/LabelText';
import { Formik, useFormikContext } from 'formik';
import { ApiError, HashtagEntityDTO, HashtagResponseDTO } from 'generated';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashtagAsyncField } from '..';
import {
  hashtagQueryKeys,
  useAddHashtag,
  useGetHashtags,
  useGetHashtagsForEntity,
  useUpdateHashtagsOfProduct,
  useUpdateHashtagsOfVideo,
} from '../queries';
import HashtagSelect from 'components/HashtagSelect/HashtagSelect';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { queryClient } from 'index';

export type Props = {
  entityId: number;
  entityType: HashtagEntityDTO.entityType;
  description?: string;
};

const Observer = ({ entityId, entityType, description }: Props) => {
  const { values, initialValues, isValid, resetForm } = useFormikContext<{
    hashtags?: HashtagResponseDTO[];
  }>();
  const { t } = useTranslation(['translation', 'product']);
  const [lastValues, updateState] = useState(values);
  const mutateProductHashtags = useUpdateHashtagsOfProduct();
  const mutateVideoHashtags = useUpdateHashtagsOfVideo();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const afterMutate = (mutationRes: UseMutationResult<any, ApiError, any>) => ({
    onSuccess: () => {
      setTimeout(() => {
        mutationRes.reset();
      }, 7000);
    },
    onError: () => {
      resetForm();

      setTimeout(() => {
        mutationRes.reset();
      }, 7000);
    },
  });

  const mutation = (values: { hashtags?: HashtagResponseDTO[] }) => {
    const hashtagIds = values.hashtags?.map((i) => i.id) || [];
    if (entityType === HashtagEntityDTO.entityType.PRODUCT) {
      mutateProductHashtags.mutate(
        { hashtagIds, productId: entityId },
        afterMutate(mutateProductHashtags),
      );
    } else if (entityType === HashtagEntityDTO.entityType.VIDEO) {
      mutateVideoHashtags.mutate(
        { hashtagIds, videoId: entityId },
        afterMutate(mutateVideoHashtags),
      );
    }
  };

  const handleSubmit = useCallback(
    debounce(
      (): void => {
        mutation(values);
      },
      500,
      { maxWait: 1500 },
    ),
    [values],
  );

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, values);
    const valuesEqualInitialValues = isEqual(values, initialValues);

    if (!valuesEqualLastValues) {
      updateState(values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues && isValid) {
      handleSubmit();
    }
  }, [values, isValid]);

  useEffect(() => {
    if (mutateProductHashtags.isError || mutateVideoHashtags.isError) {
      setError(t('product:hashtag.error'));
    } else {
      setError(undefined);
    }
  }, [mutateProductHashtags.isError, mutateVideoHashtags.isError]);

  useEffect(() => {
    if (mutateProductHashtags.isSuccess || mutateVideoHashtags.isSuccess) {
      setSuccess(t('product:hashtag.success'));
    } else {
      setSuccess(undefined);
    }
  }, [mutateProductHashtags.isSuccess, mutateVideoHashtags.isSuccess]);

  return (
    <LabelText label={t('product:hashtag.label')}>
      <HashtagAsyncField
        error={error}
        success={success}
        description={description}
      />
    </LabelText>
  );
};

const Hashtags = ({ entityId, entityType, description }: Props) => {
  const { data: initialHashtags } = useGetHashtagsForEntity(
    entityId,
    entityType,
  );

  return (
    <Formik
      initialValues={{
        hashtags: initialHashtags || [],
      }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formik) => (
        <Observer
          entityId={entityId}
          entityType={entityType}
          description={description}
        />
      )}
    </Formik>
  );
};

export default Hashtags;
