import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import {
  ApiError,
  BatchUpdateProductsDto,
  PaginatedProductResponseDTO,
  ProductsService,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  getProducts = 'getProducts',
  getProduct = 'getProduct',
  updateProducts = 'updateProducts',
}

export const productListKeys = {
  getProducts: (vendorId?: number, searchTerm?: string | void) => [
    KEYS.getProducts,
    vendorId,
    searchTerm,
  ],
};

export const useUpdateProducts = () => {
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'product']);
  return useMutation<any, ApiError, BatchUpdateProductsDto>({
    mutationFn: (variables) => ProductsService.batchUpdateProducts(variables),
    mutationKey: [KEYS.updateProducts],
    onSuccess: (data, variables) => {
      setAppStatus(t('product:edit.success'));
    },
  });
};

export const useGetProducts = (searchTerm?: string) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useInfiniteQuery<PaginatedProductResponseDTO, ApiError>({
    queryKey: productListKeys.getProducts(
      vendorAgent?.currentVendor.id,
      searchTerm,
    ),
    queryFn: async ({ pageParam = 0 }) =>
      ProductsService.getProducts(
        pageParam,
        10,
        (searchTerm?.replace(/\s/g, '').length || 0) > 0
          ? searchTerm
          : undefined,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
