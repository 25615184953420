import { FlexBox } from 'components/Layout';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { InputProps } from '../../types/InputType';
import getFormError from '../../utils/get-form-error';
import './reset-password.scss';

export type ResetPasswordFormValues = {
  email: string;
};

const ResetPasswordForm = ({
  onSubmit,
}: {
  onSubmit: (values: ResetPasswordFormValues) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('form.required')).email(t('form.email')),
  });

  const initialValues: ResetPasswordFormValues = {
    email: '',
  };

  const resetPasswordForm = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const emailInputProps: InputProps = {
    ...resetPasswordForm.getFieldProps('email'),
    label: t('resetPasswordForm.labels.email'),
    placeholder: t('resetPasswordForm.placeholders.email'),
    error: getFormError(resetPasswordForm, 'email'),
  };

  return (
    <form onSubmit={resetPasswordForm.handleSubmit}>
      <Input {...emailInputProps} />

      <FlexBox
        className="reset-password__submit-btn"
        justifyContent="space-between"
      >
        <NavLink to="/">
          <Button text={t('back')} appearance="ghost" />
        </NavLink>
        <Button text={t('resetPasswordForm.submit')} type="submit" />
      </FlexBox>
    </form>
  );
};

export default ResetPasswordForm;
