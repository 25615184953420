export function millisecondsToMinutesAndSeconds(ms?: number): string {
  if (!ms) return '00:00';
  // Convert milliseconds to seconds
  let totalSeconds = Math.floor(ms / 1000);

  // Compute minutes and seconds
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  // Add leading zero to minutes if necessary
  let formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  // Add leading zero to seconds if necessary
  let formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  // Format time as MM:SS
  return `${formattedMinutes}:${formattedSeconds}`;
}

export function secondsToMinutesAndSeconds(value: number) {
  const sec = Math.floor(value);
  let hours: number | string = Math.floor(sec / 3600); // get hours
  let minutes: number | string = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds: number | string = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds; // Return is HH : MM : SS
}
export default millisecondsToMinutesAndSeconds;
