import classNames from 'classnames';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { Cell, Column, Row } from 'react-table';
import Tooltip from 'components/Tooltip/Tooltip';
import Headline from 'components/Headline/Headline';
import Icon from 'components/Icon/Icon';
import { DefaultOption } from 'components/SelectInteractive/components/Option';
import Chart from 'components/Statistics/Chart';
import Controls from 'components/Statistics/Controls';
import StatisticFilters from 'components/Statistics/StatisticFilters';
import Table from 'components/Statistics/Table';
import {
  ApiError,
  AvailabilityDTO,
  ChannelMinimalResponseDTO,
  ChannelsService,
  DailyCountDTO,
  StatisticsService,
  SummaryDTO,
  SummaryTotalsDTO,
  VideoDTO,
} from 'generated';
import { GetStatisticsQueryDTO } from 'generated/models/GetStatisticsQueryDTO';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut, selectVendorAgent } from 'redux/slices/auth/authSlice';
import PageType from 'types/PageType';
import notAuthenticated from 'utils/not-authenticated';
import { secondsToMinutesAndSeconds } from 'utils/time';

export type Series = {
  label: string;
  data: DailyCountDTO[];
};

export type Filter = {
  main: MultiValue<DefaultOption> | null;
  type: MultiValue<DefaultOption> | null;
  visibility: MultiValue<DefaultOption> | null;
  searchTerm: string | null;
  showOnlyLive: boolean | null;
};

const StatisticsPage = (props: PageType) => {
  const today = new Date();
  today.setHours(23, 59, 59, 59);
  const thirtyDaysBefore = addDays(today, -30);
  thirtyDaysBefore.setHours(0, 0, 0, 0);

  const [channels, setChannels] = useState<ChannelMinimalResponseDTO[]>();
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [selectedVideoId, setSelectedVideoId] = useState<number>();
  const [start, setStart] = useState<string | undefined>(
    thirtyDaysBefore.toISOString(),
  );
  const [end, setEnd] = useState<string | undefined>(today.toISOString());
  const [data, setData] = useState<Series[] | null>();
  const [summary, setSummary] = useState<SummaryDTO[]>();
  const [totals, setTotals] = useState<SummaryTotalsDTO>({
    clicks: 0,
    views: 0,
    avgViewDuration: 0,
    videoDuration: 0,
  });
  // TODO take from kvstore Redux
  const [showSummaryClicks, setShowSummaryClicks] = useState<boolean>();
  const dispatch = useReduxDispatch();
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const initialFilterValues = {
    main: null,
    type: null,
    visibility: null,
    searchTerm: null,
    showOnlyLive: true,
  };
  const [filterValues, setFilterValues] = useState<Filter>(initialFilterValues);
  const [loading, setLoading] = useState({ table: false, chart: false });

  const { t } = useTranslation(['translation', 'statistics']);

  const getFilters = (): GetStatisticsQueryDTO => {
    const activeMain = Object.fromEntries(
      filterValues?.main?.map((i) => [i.value, true]) || [],
    );
    const activeType = Object.fromEntries(
      filterValues?.type?.map((i) => [i.value, true]) || [],
    );
    const activeVisibility = Object.fromEntries(
      filterValues?.visibility?.map((i) => [i.value, true]) || [],
    );

    const empty: GetStatisticsQueryDTO = Object.fromEntries(
      [
        'showDeactivated',
        'showDeleted',
        'type',
        'visibility',
        'showOnlyLive',
        'isLive',
        'inactive',
      ].map((i) => [i, undefined]),
    );

    // TODO when BE is ready enable array selection
    const getTypeFilter = (): VideoDTO.type | undefined => {
      if (
        activeType[VideoDTO.type.CHANNEL] &&
        activeType[VideoDTO.type.PRODUCT]
      ) {
        return undefined;
      }
      if (activeType[VideoDTO.type.CHANNEL]) {
        return VideoDTO.type.CHANNEL;
      }
      if (activeType[VideoDTO.type.PRODUCT]) {
        return VideoDTO.type.PRODUCT;
      }
    };

    const getVisibilityFilter = (): VideoDTO.visibility | undefined => {
      if (
        (activeVisibility.private && activeVisibility.public) ||
        (!activeVisibility.private && !activeVisibility.public)
      ) {
        return undefined;
      }
      return filterValues.visibility
        ?.filter((i) => ['private', 'public'].includes(i.value))
        .map((i) => i.value as VideoDTO.visibility)?.[0];
    };

    return {
      ...empty,
      showDeleted: activeMain.showDeleted ? activeMain.showDeleted : undefined,
      showDeactivated: activeMain.showDeactivated
        ? activeMain.showDeactivated
        : undefined,
      type: getTypeFilter(),
      visibility: getVisibilityFilter(),
      searchTerm: filterValues.searchTerm ? filterValues.searchTerm : undefined,
      showOnlyLive: filterValues.showOnlyLive ? true : undefined,
      isLive: activeMain.isLive,
      inactive: activeMain.inactive,
    };
  };

  const getDailyStatistics = () => {
    const {
      searchTerm,
      showDeleted,
      showDeactivated,
      type,
      visibility,
      showOnlyLive,
      isLive,
      inactive,
    } = getFilters();

    setLoading((obj) => ({ ...obj, chart: true }));

    StatisticsService.getDailyStatistics(
      selectedChannel !== '0' && selectedChannel !== undefined
        ? Number(selectedChannel)
        : undefined,
      selectedVideoId,
      start,
      end,
      searchTerm,
      showDeleted,
      showDeactivated,
      visibility,
      type,
      showOnlyLive,
      isLive,
      inactive,
    )
      .then((response) => {
        const { dailyViews, dailyClicks, showClicks } = response;

        if (dailyViews.length === 0) {
          setData(null);
          return;
        }

        const viewsSeries: Series = {
          label: 'Views',
          data: dailyViews.map(({ date, count }) => ({
            date,
            count,
          })),
        };

        const clicksSeries: Series = {
          label: 'Clicks',
          data: dailyClicks.map(({ date, count }) => ({
            date,
            count,
          })),
        };

        const data: Series[] = showClicks
          ? [viewsSeries, clicksSeries]
          : [viewsSeries];

        setData(data);
        setLoading((obj) => ({ ...obj, chart: false }));
      })
      .catch((error) => {
        setLoading((obj) => ({ ...obj, chart: false }));
      });
  };

  const getSummary = () => {
    const {
      searchTerm,
      showDeleted,
      showDeactivated,
      type,
      visibility,
      showOnlyLive,
      isLive,
      inactive,
    } = getFilters();

    setSummary(undefined);
    setLoading((obj) => ({ ...obj, table: true }));

    StatisticsService.getSummary(
      selectedChannel !== '0' && selectedChannel !== undefined
        ? Number(selectedChannel)
        : undefined,
      undefined,
      start ? `"${start}"` : undefined,
      end ? `"${end}"` : undefined,
      searchTerm,
      showDeleted,
      showDeactivated,
      visibility,
      type,
      showOnlyLive,
      isLive,
      inactive,
    )
      .then((response) => {
        const { summary, showClicks, totals } = response;
        setTotals(totals);
        setSummary(summary);
        setShowSummaryClicks(showClicks);
        setLoading((obj) => ({ ...obj, table: false }));
      })
      .catch((error) => {
        setLoading((obj) => ({ ...obj, table: false }));
      });
  };

  useEffect(() => {
    const init = async () => {
      try {
        const channelsRes = await ChannelsService.getMinimalChannels();

        const allChannelsItem = {
          id: 0,
          name: t('statistics:allChannels'),
          key: '',
          availability: { type: AvailabilityDTO.type.PERMANENT, active: true },
        };
        setChannels([allChannelsItem, ...channelsRes]);
        setSelectedChannel('0');
        setData(undefined);
        setLoading((obj) => ({ ...obj, table: true, chart: true }));
        setFilterValues(initialFilterValues);
        setStart(thirtyDaysBefore.toISOString());
        setEnd(today.toISOString());
        setTotals({
          clicks: 0,
          views: 0,
          avgViewDuration: 0,
          videoDuration: 0,
        });
        if (channelsRes.length > 0) {
          getDailyStatistics();
          getSummary();
        } else {
          setLoading((obj) => ({ ...obj, table: false, chart: false }));
          setData(null);
          setSummary(undefined);
        }
      } catch (error) {
        if (notAuthenticated(error as ApiError)) {
          dispatch(logOut());
        }
      }
    };

    init();
  }, [vendorAgent?.currentVendor.id]);

  useEffect(() => {
    if (!selectedChannel) {
      return;
    }

    if ((channels?.length || 0) > 1 && channels !== undefined) {
      getDailyStatistics();
    } else {
      setLoading((obj) => ({ ...obj, chart: false }));
      setData(null);
    }
  }, [selectedChannel, selectedVideoId, start, end, filterValues]);

  useEffect(() => {
    if (!selectedChannel) {
      return;
    }

    if ((channels?.length || 0) > 1 && channels !== undefined) {
      getSummary();
    } else {
      setLoading((obj) => ({ ...obj, table: false }));
      setSummary(undefined);
    }
  }, [selectedChannel, start, end, filterValues]);

  const columns = React.useMemo<Column<SummaryDTO>[]>(() => {
    const statusCol: Column<SummaryDTO> & { style: Object } = {
      accessor: 'isLive',
      sortType: 'basic',
      width: 34,
      style: { padding: 0 },
      Cell: ({ value }: Cell<SummaryDTO>) => (
        <Tooltip
          content={
            value ? t('statistics:table.live') : t('statistics:table.draft')
          }
          className="statistics__status"
        >
          <span
            className={classNames(
              'statistics__status-icon',
              value && 'statistics__status-icon--live',
            )}
          ></span>
        </Tooltip>
      ),
      Footer: '',
    };
    const videoCol: Column<SummaryDTO> & { style: Object } = {
      Header: t('statistics:video') || 'Video',
      accessor: 'videoName',
      style: {
        flex: 3,
        paddingLeft: 0,
      },
      Footer: <span>{t('statistics:totals')}</span>,
      Cell: ({ row, value }: Cell<SummaryDTO>) => {
        return (
          <span className="statistics__name">
            {row.original.isDeleted && (
              <Tooltip
                content={t('statistics:table.filter.showDeleted')}
                className="statistics__marker"
              >
                <div className="statistics__remove-icon">
                  <Icon icon="remove" />
                </div>
              </Tooltip>
            )}
            {row.original.visibility === SummaryDTO.visibility.PRIVATE && (
              <Tooltip
                content={t('statistics:table.filter.private')}
                className="statistics__marker"
              >
                <Icon icon="eye-off" />
              </Tooltip>
            )}
            <span>{value}</span>
          </span>
        );
      },
    };

    const productCol: Column<SummaryDTO> & { style: Object } = {
      Header: t('statistics:product') || 'Product',
      accessor: 'vendorProductId',
      style: {
        flex: 2,
        textAlign: 'left',
      },
      Footer: '',
      Cell: ({ row, value }: Cell<SummaryDTO>) => {
        if (!row.original.productIsAvailable && row.original.vendorProductId) {
          return (
            <span className="statistics__name statistics__name--black-50">
              <Tooltip
                content={t('statistics:table.filter.showDeactivated')}
                className="statistics__marker"
              >
                <Icon icon="block" />
              </Tooltip>

              <span>{value}</span>
            </span>
          );
        }

        return (
          <span
            className={classNames(
              'statistics__name statistics__name--black-50',
              row.original.type === SummaryDTO.type.CHANNEL &&
                'statistics__name--italic',
            )}
          >
            {row.original.type === SummaryDTO.type.CHANNEL
              ? t('statistics:table.introVideo')
              : value}
          </span>
        );
      },
    };

    const viewsCol: Column<SummaryDTO> = {
      Header: (
        <span className="statistics__title">
          <Icon
            icon="sum"
            className="statistics__smybol statistics__smybol--sum"
          />
          {t('statistics:views')}
        </span>
      ),
      accessor: 'views',
      width: 98,
      Footer: 'views',
    };

    const clicksCol: Column<SummaryDTO> = {
      Header: (
        <span className="statistics__title">
          <Icon
            icon="sum"
            className="statistics__smybol statistics__smybol--sum"
          />
          {t('statistics:clicks')}
        </span>
      ),
      accessor: 'clicks',
      width: 98,
      Footer: 'clicks',
    };

    const viewDurationCol: Column<SummaryDTO> = {
      Header: (
        <span className="statistics__title ">
          <Icon icon="average" className="statistics__smybol" />
          {t('statistics:viewDuration')}
        </span>
      ),
      accessor: 'avgViewDuration',
      sortType: 'basic',
      width: 144,
      Cell: ({ value, row }: Cell<SummaryDTO>) => {
        return `${secondsToMinutesAndSeconds(
          value,
        )} / ${secondsToMinutesAndSeconds(row.original.videoDuration)}`;
      },
      Footer: 'avgViewDuration',
    };

    if (showSummaryClicks) {
      return [
        statusCol,
        videoCol,
        productCol,
        viewsCol,
        clicksCol,
        viewDurationCol,
      ];
    }

    return [statusCol, videoCol, productCol, viewsCol, viewDurationCol];
  }, [showSummaryClicks]);

  const handleRowClick = (row: Row<SummaryDTO>) => {
    setSelectedVideoId((prev) => {
      if (prev === row.original.videoId) {
        return undefined;
      }

      return Number(row.original.videoId);
    });
  };

  const handleStartChange = (name: string, value?: Date | [Date, Date]) => {
    if (value && !Array.isArray(value)) {
      setStart(new Date(value).toISOString());
    } else {
      setStart(undefined);
    }
  };

  const handleEndChange = (name: string, value?: Date | [Date, Date]) => {
    if (value && !Array.isArray(value)) {
      const d = new Date(value);
      d.setHours(23, 59, 59, 59);
      setEnd(d.toISOString());
    } else {
      setEnd(undefined);
    }
  };

  const handleFilterChange = (
    value: MultiValue<DefaultOption> | null | string,
    filter: keyof Filter,
  ) => {
    setFilterValues((pre) => ({
      ...pre,
      [filter]: value,
      showOnlyLive:
        filter === 'main' && value?.length !== 0 ? false : pre.showOnlyLive,
    }));
  };

  const handleSearchTermChange = (searchTerm: string) => {
    setFilterValues((pre) => ({
      ...pre,
      searchTerm,
    }));
  };

  const handleCheckBoxChange = (checked: boolean) => {
    setFilterValues((pre) => ({
      ...pre,
      showOnlyLive: checked ? true : null,
      main: (filterValues.main?.length || 0) > 0 && checked ? null : pre.main,
    }));
  };

  return (
    <div className="settings page page--900">
      <div className="page__content">
        {channels?.length === 0 ? (
          <div>{t('statistics:no-channels-text')}</div>
        ) : (
          <div>
            <Controls
              channels={channels}
              selectedChannel={selectedChannel}
              onChannelChange={setSelectedChannel}
              onStartChange={handleStartChange}
              onEndChange={handleEndChange}
            />
            <>
              <Chart
                data={data}
                showClicks={showSummaryClicks}
                loading={loading.chart}
              />

              <StatisticFilters
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                onSearchTermChange={handleSearchTermChange}
                onCheckBoxChange={handleCheckBoxChange}
              />

              <Headline
                headingLevel="h2"
                size={2}
                className="statistics__table-title"
              >
                {t('statistics:table.title')}
              </Headline>

              <Table
                columns={columns}
                data={summary}
                getRowProps={(row) => ({
                  onClick: () => handleRowClick(row),
                })}
                selectedVideoId={selectedVideoId}
                loading={loading.table}
                totals={totals}
              />
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticsPage;
