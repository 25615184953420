import Button from 'components/Button/Button';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Headline from 'components/Headline/Headline';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import VideoUpload from '../VideoUpload/VideoUpload';
import { BREAKPOINTS, HEIGHT_BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

/**
 * Renders a modal with a VideoUpload component inside. The opening button is also included.
 */
const VideoUploadModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['translation', 'video']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const isDesktopHeight = useMediaQuery(
    `(min-height: ${HEIGHT_BREAKPOINTS.m})`,
  );

  return (
    <>
      <Button
        text={t('video:title')}
        icon="add"
        color={ColorUse['primary-50']}
        onClick={() => setIsOpen(true)}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        variant="full"
        className="video-upload__modal"
      >
        <FlexBox justifyContent="space-between" flexDirection="column">
          <Spacer
            paddingBottom={isDesktopHeight ? 8 : 4}
            paddingTop={isDesktop && isDesktopHeight ? 8 : 0}
            className="bg-white"
          >
            <Headline size={2} headingLevel="h1">
              {t('video:upload.title-multi')}
            </Headline>
            <Spacer
              marginTop={isDesktopHeight ? 3 : 1}
              tag="p"
              className="small"
            >
              {t('video:upload.description')}
            </Spacer>
          </Spacer>
          <FlexItem flexGrow={1} className="video-upload__wrap">
            <ErrorBoundary spacerProps={{ margin: [0, isDesktop ? 5 : 2] }}>
              <VideoUpload onClose={() => setIsOpen(false)} />
            </ErrorBoundary>
          </FlexItem>
        </FlexBox>
      </Modal>
    </>
  );
};

export default VideoUploadModal;
