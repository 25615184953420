/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddVendorDTO } from '../models/AddVendorDTO';
import type { BankingDetailDTO } from '../models/BankingDetailDTO';
import type { ChannelMinimalResponseDTO } from '../models/ChannelMinimalResponseDTO';
import type { VendorDTO } from '../models/VendorDTO';
import type { VendorResponseDTO } from '../models/VendorResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorsService {

    /**
     * Lists all Vendors
     * @returns VendorResponseDTO
     * @throws ApiError
     */
    public static getVendors(): CancelablePromise<Array<VendorResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors',
        });
    }

    /**
     * Adds a new Vendor
     * @param requestBody
     * @returns VendorResponseDTO
     * @throws ApiError
     */
    public static addVendor(
        requestBody: AddVendorDTO,
    ): CancelablePromise<VendorResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Throws a BadRequestException`,
            },
        });
    }

    /**
     * Updates the current vendor
     * @param requestBody
     * @returns VendorResponseDTO
     * @throws ApiError
     */
    public static updateVendor(
        requestBody: VendorDTO,
    ): CancelablePromise<VendorResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vendors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Lists all channels associated with Vendor id
     * @param id
     * @returns ChannelMinimalResponseDTO
     * @throws ApiError
     */
    public static getVendorChannelsMinimal(
        id: number,
    ): CancelablePromise<Array<ChannelMinimalResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/{id}/channels-minimal',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Gets the current vendor
     * @returns any
     * @returns BankingDetailDTO
     * @throws ApiError
     */
    public static getBankingDetails(): CancelablePromise<Record<string, any> | BankingDetailDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/bankingDetails',
        });
    }

    /**
     * Updates the banking details of current vendor
     * @param requestBody
     * @returns VendorResponseDTO
     * @throws ApiError
     */
    public static updateBankingDetails(
        requestBody: BankingDetailDTO,
    ): CancelablePromise<VendorResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vendors/bankingDetails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes the banking details of current vendor
     * @returns VendorResponseDTO
     * @throws ApiError
     */
    public static deleteBankingDetails(): CancelablePromise<VendorResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/vendors/bankingDetails',
        });
    }

}
