import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QUERY_KEYS from 'types/QueryKeys';
import { queryClient } from '../../..';
import Headline from '../../../components/Headline/Headline';
import {
  AddVendorAgentWithRoleWithoutSuperAdminDTO,
  UpdateVendorAgentRoleDTO,
  VendorAgentResponseDTO,
  VendorAgentsService,
  VendorResponseDTO,
} from '../../../generated';
import {
  useAddVendorAgent,
  useGetRoles,
  useGetVendorAgents,
} from '../../../hooks/queries';
import useAppStatus from '../../../hooks/useAppStatus';
import { useReduxSelector } from '../../../redux/hooks';
import { selectVendorAgent } from '../../../redux/slices/auth/authSlice';
import AddVendorAgentForm, {
  AddVendorAgentFormValues,
} from './AddVendorAgentForm';
import VendorAgentsList from './VendorAgentsList';

const VendorAgentsContainer = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const { t } = useTranslation(['translation', 'settings']);

  const { setAppStatus } = useAppStatus();
  const { vendorAgents } = useGetVendorAgents();
  const { rolesData } = useGetRoles();
  const addVendorAgent = useAddVendorAgent();
  const [deleteLoading, setDeleteLoading] = useState<number[]>([]);

  const handleVendorAgentDelete = async (id: number) => {
    setDeleteLoading((current) => [...current, id]);
    try {
      await VendorAgentsService.deleteVendorAgent(id);
      queryClient.invalidateQueries([QUERY_KEYS.getVendorAgents]);
      setDeleteLoading((current) => current.filter((i) => i !== id));
      setAppStatus(t('settings:success.remove_vendor_agent'));
    } catch (error) {
      setAppStatus(t('settings:error.delete_vendor_agent'), 'error', true);
      setDeleteLoading((current) => current.filter((i) => i !== id));
    }
  };

  const handleAddVendorAgentSubmit = async ({
    email,
    name,
    role,
  }: AddVendorAgentFormValues) => {
    addVendorAgent.mutate({ email, name, role });
  };

  const handleVendorAgentRoleChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: number,
  ) => {
    const role = event.currentTarget.value as UpdateVendorAgentRoleDTO.role;

    await VendorAgentsService.updateVendorAgentRole(id, {
      role,
    });
  };

  const options = rolesData?.roles
    .filter((role) =>
      vendorAgent?.role === VendorAgentResponseDTO.role.SALES_ADMIN
        ? role.startsWith('sales')
        : true,
    )
    .map((role) => ({
      value: role,
      text: role,
    }));

  return rolesData && options ? (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.vendor_agents')}
      </Headline>

      <VendorAgentsList
        vendorAgents={vendorAgents}
        roleOptions={rolesData.roles.map((role) => ({
          value: role,
          text: role,
        }))}
        onDeleteClick={handleVendorAgentDelete}
        onRoleChange={handleVendorAgentRoleChange}
        deleteLoading={deleteLoading}
      />

      <Headline className="settings__headline" headingLevel="h3" size={3}>
        {t('settings:headlines.add_vendor_agent')}
      </Headline>

      <AddVendorAgentForm
        roleOptions={options}
        initialRole={
          vendorAgent?.vendor.type === VendorResponseDTO.type.SALES
            ? AddVendorAgentWithRoleWithoutSuperAdminDTO.role.SALES_AGENT
            : AddVendorAgentWithRoleWithoutSuperAdminDTO.role.REPORTER
        }
        onSubmit={handleAddVendorAgentSubmit}
      />
    </>
  ) : null;
};

export default VendorAgentsContainer;
