/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetDailyStatisticsResponseDTO } from '../models/GetDailyStatisticsResponseDTO';
import type { GetStatisticsSummaryResponseDTO } from '../models/GetStatisticsSummaryResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Get daily views/clicks for selected channel between two selected UTC-dates in the current timezone.
     * @param channelId
     * @param videoId
     * @param start
     * @param end
     * @param searchTerm
     * @param showDeleted
     * @param showDeactivated
     * @param visibility
     * @param type
     * @param showOnlyLive
     * @param isLive
     * @param inactive
     * @returns GetDailyStatisticsResponseDTO
     * @throws ApiError
     */
    public static getDailyStatistics(
        channelId?: number,
        videoId?: number,
        start?: string | null,
        end?: string | null,
        searchTerm?: string,
        showDeleted?: boolean,
        showDeactivated?: boolean,
        visibility?: 'public' | 'private',
        type?: 'product' | 'promotion' | 'channel',
        showOnlyLive?: boolean,
        isLive?: boolean,
        inactive?: boolean,
    ): CancelablePromise<GetDailyStatisticsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/dailyStatistics',
            query: {
                'channelId': channelId,
                'videoId': videoId,
                'start': start,
                'end': end,
                'searchTerm': searchTerm,
                'showDeleted': showDeleted,
                'showDeactivated': showDeactivated,
                'visibility': visibility,
                'type': type,
                'showOnlyLive': showOnlyLive,
                'isLive': isLive,
                'inactive': inactive,
            },
        });
    }

    /**
     * Get daily views/clicks for selected channel between two selected dates.
     * @param channelId
     * @param videoId
     * @param start
     * @param end
     * @param searchTerm
     * @param showDeleted
     * @param showDeactivated
     * @param visibility
     * @param type
     * @param showOnlyLive
     * @param isLive
     * @param inactive
     * @returns GetStatisticsSummaryResponseDTO
     * @throws ApiError
     */
    public static getSummary(
        channelId?: number,
        videoId?: number,
        start?: string | null,
        end?: string | null,
        searchTerm?: string,
        showDeleted?: boolean,
        showDeactivated?: boolean,
        visibility?: 'public' | 'private',
        type?: 'product' | 'promotion' | 'channel',
        showOnlyLive?: boolean,
        isLive?: boolean,
        inactive?: boolean,
    ): CancelablePromise<GetStatisticsSummaryResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/summary',
            query: {
                'channelId': channelId,
                'videoId': videoId,
                'start': start,
                'end': end,
                'searchTerm': searchTerm,
                'showDeleted': showDeleted,
                'showDeactivated': showDeactivated,
                'visibility': visibility,
                'type': type,
                'showOnlyLive': showOnlyLive,
                'isLive': isLive,
                'inactive': inactive,
            },
        });
    }

    /**
     * Export daily views/clicks for selected channel between two selected UTC-dates in the current timezone as a CSV-file.
     * @param channelId
     * @param videoId
     * @param start
     * @param end
     * @param searchTerm
     * @param showDeleted
     * @param showDeactivated
     * @param visibility
     * @param type
     * @param showOnlyLive
     * @param isLive
     * @param inactive
     * @returns any
     * @throws ApiError
     */
    public static exportSummary(
        channelId?: number,
        videoId?: number,
        start?: string | null,
        end?: string | null,
        searchTerm?: string,
        showDeleted?: boolean,
        showDeactivated?: boolean,
        visibility?: 'public' | 'private',
        type?: 'product' | 'promotion' | 'channel',
        showOnlyLive?: boolean,
        isLive?: boolean,
        inactive?: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/summary/export',
            query: {
                'channelId': channelId,
                'videoId': videoId,
                'start': start,
                'end': end,
                'searchTerm': searchTerm,
                'showDeleted': showDeleted,
                'showDeactivated': showDeactivated,
                'visibility': visibility,
                'type': type,
                'showOnlyLive': showOnlyLive,
                'isLive': isLive,
                'inactive': inactive,
            },
        });
    }

}
