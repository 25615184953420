import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './lazyimage.scss';

export interface Props {
  className?: string;
  src: string;
  alt: string;
  lqip?: string; // TODO Low Quality Image Placeholders
  aspectRatio?: number;
}

const LazyImage = ({
  className,
  src,
  alt,
  lqip,
  aspectRatio = 2 / 3,
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, []);
  return (
    <div className={classNames('lazyimage', className)}>
      <div style={{ paddingBottom: `${100 / aspectRatio}%` }} />
      <img src={lqip} aria-hidden="true" alt="Low Quality Image Placeholder" />
      <img
        loading="lazy"
        src={src}
        alt={alt}
        ref={imgRef}
        onLoad={() => setLoaded(true)}
        className={classNames(
          'lazyimage__source',
          loaded && 'lazyimage__source--loaded',
        )}
      />
    </div>
  );
};
export default LazyImage;
