import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

type Type = 'success' | 'error' | 'warning';

interface StatusContextType {
  appStatus?: string;
  type?: Type;
  setAppStatus: (s?: string, type?: Type, temporarely?: boolean) => void;
}

const StatusContext = createContext<StatusContextType>({} as StatusContextType);

export const StatusProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<string>();
  const [type, setType] = useState<Type>();

  const handleSetStatus = (
    status?: string,
    statusType?: Type,
    temporarely: boolean = true,
  ) => {
    setStatus(status);
    setType(statusType);

    if (temporarely) {
      setTimeout(() => {
        setStatus(undefined);
        setType(undefined);
      }, 5000);
    }
  };

  const memoedValue = useMemo(
    () => ({
      setAppStatus: handleSetStatus,
      appStatus: status,
      type,
    }),
    [status, type],
  );

  return (
    <StatusContext.Provider value={memoedValue}>
      {children}
    </StatusContext.Provider>
  );
};

export default function useAppStatus() {
  return useContext(StatusContext);
}
