import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout';
import './list-item-nested.scss';
import React from 'react';

export type Props = {
  title: React.ReactNode;
  children?: React.ReactNode;
  showArrow?: boolean;
  onClick?: () => void;
};

const ListItemNested = ({
  title,
  children,
  showArrow = true,
  onClick,
}: Props) => {
  return (
    <li className="list-item-nested">
      <FlexBox
        tag={onClick !== undefined ? 'button' : 'div'}
        type={onClick !== undefined ? 'button' : undefined}
        onClick={onClick}
        justifyContent="space-between"
        alignItems="center"
        className="list-item-nested__button"
      >
        <FlexItem className="list-item-nested__title" flexGrow={1}>
          {title}
        </FlexItem>
        {children && (
          <FlexItem className="list-item-nested__content">{children}</FlexItem>
        )}
        {showArrow && (
          <FlexItem
            className="list-item-nested__arrow"
            alignItems="center"
            display="flex"
          >
            <Icon icon="arrow-right" />
          </FlexItem>
        )}
      </FlexBox>
    </li>
  );
};

export default ListItemNested;
