import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem } from 'components/Layout';
import Tooltip from 'components/Tooltip/Tooltip';
import VideoUploadStatusIcon from 'components/VideoUploadStatusIcon/VideoUploadStatusIcon';
import { VideoUploadFeature } from 'features/video-upload/types';
import { finishedUploadStatus } from 'features/video-upload/videoUploadSlice';
import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import './file-progress-item.scss';

export type Props = React.HTMLAttributes<HTMLDivElement> &
  Omit<VideoUploadFeature.FileData, 'language' | 'fileData'> & {
    tag?: ElementType;
    onClose?: () => void;
  };

const FileProgressItem = ({
  tag: Tag = 'li',
  onClose,
  fileName,
  status,
  progress,
}: Props) => {
  const { t } = useTranslation(['translation', 'video']);
  const isCreatingStatus = [
    VideoUploadFeature.Status.CREATE,
    VideoUploadFeature.Status.CREATE_NAME_ERROR,
  ].includes(status);
  return (
    <Tag className="file-progress-item">
      <FlexBox alignItems="center">
        {onClose && !isCreatingStatus && (
          <FlexItem marginRight={1} className="file-progress-item__close">
            <Tooltip
              content={
                finishedUploadStatus.includes(status)
                  ? t('video:upload.close')
                  : t('video:upload.cancel')
              }
            >
              <button type="button" onClick={onClose}>
                <Icon icon="close" />
              </button>
            </Tooltip>
          </FlexItem>
        )}
        <FlexItem marginRight={1}>
          <Tooltip content={t(`video:upload.status.${status}.description`)}>
            <VideoUploadStatusIcon status={status} />
          </Tooltip>
        </FlexItem>
        <FlexItem className="file-progress-item__title">{fileName}</FlexItem>
        {(progress || status === VideoUploadFeature.Status.UPLOAD_CANCELED) && (
          <FlexItem
            marginLeft={2}
            pushRight
            className="c-dark-50 file-progress-item__progress"
          >
            {status === VideoUploadFeature.Status.UPLOAD_CANCELED
              ? t('video:upload.canceled')
              : `${progress}%`}
          </FlexItem>
        )}
      </FlexBox>
    </Tag>
  );
};

export default FileProgressItem;
