import ConfirmModal, {
  Props as ConfirmProps,
} from 'components/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch } from 'redux/hooks';
import { clearFiles } from '../videoUploadSlice';

export type Props = Omit<ConfirmProps, 'text' | 'headline' | 'confirmText'> & {
  text?: string;
  headline?: string;
  confirmText?: string;
};

const CancelVideoUploadsModal = ({
  text,
  headline,
  onConfirmClick,
  confirmText,
  ...props
}: Props) => {
  const { t } = useTranslation(['translation', 'video']);
  const dispatch = useReduxDispatch();

  const handleConfirm = () => {
    dispatch(clearFiles());
    onConfirmClick?.();
  };

  return (
    <ConfirmModal
      {...props}
      text={text || t('video:upload.cancelModal.text')}
      headline={headline || t('video:upload.cancelModal.headline')}
      confirmText={confirmText || t('video:upload.cancelModal.confirm')}
      onConfirmClick={handleConfirm}
    />
  );
};

export default CancelVideoUploadsModal;
