import { Spacer } from 'components/Layout';
import { VendorAddressDto } from 'generated';
import { useTranslation } from 'react-i18next';

const Address = (props: VendorAddressDto) => {
  const { t } = useTranslation(['translation', 'orders']);
  return (
    <address>
      {props.firstName && props.firstName}
      {props.lastName && <> {props.lastName}</>}
      {props.company && (
        <>
          <br /> {props.company}
        </>
      )}
      {props.gstin && (
        <>
          <br /> GSTIN: {props.gstin}
        </>
      )}
      <Spacer marginTop={2}>
        {props.address1 && props.address1}
        {props.address2 && (
          <>
            <br /> {props.address2}
          </>
        )}
        {props.city && (
          <>
            <br /> {props.city}
          </>
        )}
        {props.province && <>, {props.province}</>}
        {props.zip && <>, {props.zip}</>}
        {props.country && (
          <>
            <br /> {props.country}
          </>
        )}
      </Spacer>
      <Spacer marginTop={2}>
        {props.email && (
          <>
            {t('orders:detail.shipping.createShipment.address.email')}:{' '}
            {props.email}
          </>
        )}
        {props.phone && (
          <>
            <br />
            {t('orders:detail.shipping.createShipment.address.phone')}:{' '}
            {props.phone}
          </>
        )}
      </Spacer>
    </address>
  );
};

export default Address;
