import { ReactNode } from 'react';
import './filter-detail.scss';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const FilterDetail = ({ children }: { children: ReactNode }) => {
  return (
    <div className="filter-detail">
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};

export default FilterDetail;
