import classNames from 'classnames';
import Color from '../../types/Color';
import Button from '../Button/Button';
import './tabs.scss';

export type Tab<T> = { tab: T; text: string };

type Props<T> = {
  activeTab: T;
  onTabChange: (tab: T) => void;
  tabs: Tab<T>[];
  className?: string;
};

const Tabs = <T extends unknown>({
  tabs,
  className,
  activeTab,
  onTabChange,
}: Props<T>) => {
  return (
    <nav className={classNames('tabs', className)}>
      <ul className="tabs__list">
        {tabs.map(({ tab, text }) => (
          <li key={text} className="tabs__list-item">
            <Button
              color={Color.primary}
              text={text}
              onClick={() => onTabChange(tab)}
              appearance={activeTab === tab ? 'filled' : 'ghost'}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Tabs;
