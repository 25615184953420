import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { AddVendorAgentWithRoleWithoutSuperAdminDTO } from '../../../generated';
import { InputProps } from '../../../types/InputType';

export type AddVendorAgentFormValues = {
  email: string;
  name: string;
  role: AddVendorAgentWithRoleWithoutSuperAdminDTO.role;
};

const AddVendorAgentForm = ({
  onSubmit,
  roleOptions,
  initialRole,
}: {
  onSubmit: ({ email, name, role }: AddVendorAgentFormValues) => void;
  roleOptions: { value: string; text: string }[];
  initialRole: AddVendorAgentWithRoleWithoutSuperAdminDTO.role;
}) => {
  const { t } = useTranslation(['translation', 'settings']);

  const addVendorAgentValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('form.required')).email(t('form.email')),
    name: Yup.string().required(t('form.required')),
  });

  const initialValues: AddVendorAgentFormValues = {
    name: '',
    email: '',
    role: initialRole,
  };

  const addVendorAgentForm = useFormik({
    initialValues,
    validationSchema: addVendorAgentValidationSchema,
    onSubmit,
  });

  const nameInputProps: InputProps = {
    ...addVendorAgentForm.getFieldProps('name'),
    label: t('settings:labels.name'),
    error:
      addVendorAgentForm.touched.name && addVendorAgentForm.errors.name
        ? t(addVendorAgentForm.errors.name)
        : undefined,
  };

  const emailInputProps: InputProps = {
    ...addVendorAgentForm.getFieldProps('email'),
    label: t('settings:labels.email'),
    error:
      addVendorAgentForm.touched.email && addVendorAgentForm.errors.email
        ? t(addVendorAgentForm.errors.email)
        : undefined,
  };

  const roleInputProps: InputProps = {
    ...addVendorAgentForm.getFieldProps('role'),
    label: t('settings:labels.role'),
  };

  return (
    <form className="settings__form" onSubmit={addVendorAgentForm.handleSubmit}>
      <div className="settings__block settings__block--50">
        <Input {...nameInputProps} />
      </div>

      <div className="settings__block settings__block--50">
        <Input {...emailInputProps} />
      </div>

      <div className="settings__block settings__block--50">
        <Select {...roleInputProps} options={roleOptions} />
      </div>

      <Button
        className="settings__submit-btn"
        text={t('settings:add')}
        type="submit"
      />
    </form>
  );
};

export default AddVendorAgentForm;
