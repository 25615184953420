import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TermsResponseDTO } from '../../generated';
import { useReduxSelector } from '../../redux/hooks';
import {
  selectIsAdmin,
  selectIsSuperAdmin,
} from '../../redux/slices/auth/authSlice';
import Color from '../../types/Color';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import PdfViewer from '../PdfViewer/PdfViewer';
import './terms.scss';

export interface Props {
  terms?: TermsResponseDTO;
  isAccepted?: boolean;
  onAcceptDecline?: (accepted: boolean) => void;
  onActivateVersion?: () => void;
  error?: string;
  onLoadError?: () => void;
}

const Terms = ({
  terms,
  isAccepted,
  onAcceptDecline,
  onActivateVersion,
  error,
  onLoadError,
}: Props) => {
  const { t } = useTranslation(['settings']);
  const isAdmin = useReduxSelector(selectIsAdmin);
  const isSuperAdmin = useReduxSelector(selectIsSuperAdmin);
  const [hasError, setHasError] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);

  const handleSetError = (error: Error) => {
    setHasError(true);
  };

  useEffect(() => {
    if (hasError && onLoadError) {
      onLoadError();
    }
  }, [hasError]);

  return (
    <div className="terms">
      {isSuperAdmin && terms && (
        <div className="terms__version-wrap">
          <div className="caption--primary">
            <span className="terms__version"> Version: {terms.version}</span>
          </div>
        </div>
      )}

      <div className="terms__content">
        {terms?.path && (
          <PdfViewer
            file={terms.path}
            setError={handleSetError}
            onLoadSuccess={() => setFileLoaded(true)}
          />
        )}
      </div>

      {((onAcceptDecline && !isAccepted) || onActivateVersion || error) && (
        <div className="terms__footer">
          {!isAdmin && (
            <div className="icon page__description terms__desc">
              <Icon icon="info-filled" />
              {t('settings:terms.adminAccept')}
            </div>
          )}
          {!hasError && onAcceptDecline && !isAccepted && (
            <Button
              text={t('settings:terms.accept')}
              disabled={!isAdmin}
              onClick={() => onAcceptDecline(true)}
            />
          )}
          {onActivateVersion && terms?.currentlyActive && (
            <div className="terms__public">
              <span>{t('settings:terms.isActivated')}</span>
              <IconButton
                icon="check"
                color={Color.confirm}
                appearance="filled"
              />
            </div>
          )}

          {onActivateVersion && !terms?.currentlyActive && (
            <Button
              text={t('settings:terms.activate')}
              onClick={onActivateVersion}
              disabled={!fileLoaded}
            />
          )}

          {error && <div className="input__error terms__error">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default Terms;
