import Chip from 'components/Chip/Chip';
import LabelText from 'components/LabelText/LabelText';
import { FlexBox, FlexItem } from 'components/Layout';
import LazyImage from 'components/LazyImage/LazyImage';
import OrderItem from 'components/OrderItem/OrderItem';
import { TotalPricingDto, VendorOrderItemDto } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import placeholder from 'images/placeholder-video-mediathek-gradient.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Collapse from 'components/Collapse/Collapse';
import Pricing from './Pricing';

const Product = ({
  orderItems,
  pricing,
}: {
  orderItems?: Array<VendorOrderItemDto>;
  pricing?: TotalPricingDto;
}) => {
  const { t } = useTranslation(['translation', 'orders']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const isLarge = useMediaQuery(`(min-width: ${BREAKPOINTS.l})`);
  const [collapsed, setCollapsed] = useState(!isDesktop);

  useEffect(() => {
    setCollapsed(!isDesktop);
  }, [isDesktop]);

  return (
    <div className="order-detail__product">
      {orderItems &&
        isLarge &&
        orderItems.map((item) => (
          <OrderItem
            key={item.createdAt}
            {...item}
            subtitle={item.product?.vendorProductId}
            title={item.product?.name}
            productId={item.product?.id}
            image={item.product?.imageUri}
          />
        ))}

      {orderItems &&
        !isLarge &&
        orderItems.map(({ createdAt, product, amount, variant }) => (
          <div key={createdAt} className="order-item order-item--small">
            <FlexBox>
              <FlexItem flexGrow={1} className="order-item__title-wrap">
                <div className="order-item__title">
                  {product?.name === '' ? t('no_title') : product?.name}
                </div>
                {product?.vendorProductId && (
                  <div className="order-item__subtitle">
                    {product?.vendorProductId}
                  </div>
                )}
              </FlexItem>
              {amount && (
                <FlexItem pushRight>
                  <Chip tooltip={t('orders:product.amount')}>{amount}</Chip>
                </FlexItem>
              )}
            </FlexBox>

            {!collapsed && (
              <FlexBox
                marginTop={2}
                justifyContent="space-between"
                marginBottom={isDesktop && !isLarge ? 0 : 4}
              >
                <div className="order-item__image">
                  <LazyImage
                    src={product?.imageUri || placeholder}
                    alt={t('list:preview', { title: product?.name })}
                    aspectRatio={70 / 70}
                    lqip={placeholder}
                    className="order-item__image-lazy"
                  />
                </div>

                <FlexItem marginLeft={2} flexGrow={1}>
                  <FlexBox
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <LabelText label={t('orders:product.variant.label')}>
                      {variant ? variant : t('orders:product.variant.empty')}
                    </LabelText>
                    {product?.id && (
                      <FlexItem alignSelf="flex-end">
                        <Link
                          className="order-item__link"
                          to={`/products/${product?.id}`}
                        >
                          {t('orders:product.link')}
                        </Link>
                      </FlexItem>
                    )}
                  </FlexBox>
                </FlexItem>
              </FlexBox>
            )}
          </div>
        ))}

      {!isDesktop && (
        <Collapse
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}

      {pricing && isDesktop && (
        <Pricing
          pricing={pricing}
          // This is only a temporary solution and only works for one single orderItem
          productId={orderItems?.[0]?.product?.id || 0}
        />
      )}
    </div>
  );
};

export default Product;
