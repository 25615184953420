import { useMutation, useQuery } from '@tanstack/react-query';
import { orderDetailKeys } from 'features/order-detail';
import { orderListKeys } from 'features/order-list';
import {
  ApiError,
  CreateShipmentParametersDto,
  OrderNumbersDto,
  VendorOrderService,
  VendorOrderShipmentDto,
  VendorOrderShipmentInvoiceDto,
  VendorOrderShipmentManifestDto,
  VendorOrderShipmentService,
  VendorOrderShipmentTrackingLabelDto,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export type CreateShipmentMutationVariables = {
  orderNo: string;
} & CreateShipmentParametersDto;

export const orderShipmentKeys = {
  getPackageDimensions: (productId: number, vendorAgentId?: number) => [
    productId,
    vendorAgentId,
  ],
};
// This is just a workaround for packaging details for now
export const useGetPackageDimensions = (productId: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useQuery({
    queryKey: orderShipmentKeys.getPackageDimensions(
      productId,
      vendorAgent?.currentVendor.id,
    ),
    queryFn: () => VendorOrderShipmentService.getPackageDimensions(productId),
    retry: 2,
    refetchOnWindowFocus: false,
  });
};

export const useCreateShipment = () => {
  return useMutation<
    VendorOrderShipmentDto,
    ApiError,
    CreateShipmentMutationVariables
  >({
    mutationFn: ({ orderNo, ...variables }) =>
      VendorOrderService.createShipment(orderNo, { orderNo, ...variables }),
    // onSuccess: (data, { orderNo }) => {
    // Updating the actual Order will happen in the component. We need to call useGetShipmentManifests onSuccess, because this actually really creates the shipment in shiprocket, even if the status will stay the same (status = createShipment). Also changing the order action status early will rerender the Shipping Component.
    // },
  });
};
/**
 * This hook is used to get the shipment manifests for a given order numbers.
 * Please use this directly after useCreateShipment fulfilled, becuase this will actually fully create the shipment in shiprocket, even if the status will stay the same (status = createShipment)
 * This can also be used without chaining it with createShipment. This is relevant when the manifestUrl could not be successfully created in the first place. The getOrder query should be updated manually after that.
 */
export const useGetShipmentManifests = () => {
  return useMutation<VendorOrderShipmentManifestDto, ApiError, OrderNumbersDto>(
    {
      mutationFn: (req) => VendorOrderService.getShipmentManifests(req),
      // Updating the actual Order will happen in the component, because we need to also use the data from createShipment to do so.
    },
  );
};

export const useGetShipmentTrackingLabel = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<
    VendorOrderShipmentTrackingLabelDto,
    ApiError,
    OrderNumbersDto
  >({
    mutationFn: (req) => VendorOrderService.getShipmentTrackingLabel(req),
    onSuccess: ({ response, trackingLink }, { orderNumbers }) => {
      console.log(response);
      orderNumbers.forEach((orderNo) => {
        queryClient.setQueryData(
          orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
          (oldData: any) => ({
            ...oldData,
            trackingLink,
          }),
        );
      });
    },
  });
};

export const useGetShipmentInvoice = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<VendorOrderShipmentInvoiceDto, ApiError, OrderNumbersDto>({
    mutationFn: (req) => VendorOrderService.getShipmentInvoice(req),
    onSuccess: ({ invoiceLink }, { orderNumbers }) => {
      orderNumbers.forEach((orderNo) => {
        queryClient.setQueryData(
          orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor.id),
          (oldData: any) => ({
            ...oldData,
            invoiceLink,
          }),
        );
      });
    },
  });
};
