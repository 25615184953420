import Button from 'components/Button/Button';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Controls from 'components/Controls/Controls';
import LabeledInfoText from 'components/LabeledInfoText/LabeledInfoText';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import LocalisationTabs from 'components/LocalisationTabs/LocalisationTabs';
import TabPane from 'components/LocalisationTabs/TabPane';
import AvailabilityFieldSet from 'features/availability';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ChannelType from 'types/ChannelType';

export interface Props {
  onDeleteClick: () => void;
  onCancelClick: () => void;
  loadingButton: {
    save?: boolean;
    delete?: boolean;
    activate?: boolean;
  };
  deleteModalIsOpen: boolean;
  openDeleteModal: () => void;
  closeDeleteModal: () => void;
  cancelModalIsOpen: boolean;
  closeCancelModal: () => void;
  onCancelComfirm?: () => void;
  edit?: boolean;
}

const ChannelForm = ({
  onDeleteClick,
  onCancelClick,
  loadingButton,
  deleteModalIsOpen,
  cancelModalIsOpen,
  closeCancelModal,
  openDeleteModal,
  closeDeleteModal,
  onCancelComfirm,
  edit,
}: Props) => {
  const spacerProps = {
    marginTop: 8,
    paddingTop: 5,
  };
  const { t } = useTranslation(['translation', 'channel', 'edit']);
  const { values } = useFormikContext<ChannelType>();

  return (
    <>
      <Spacer {...spacerProps}>
        <LabelText label={t('channel:status.caption')}>
          <p>{t('channel:status.description')}</p>
        </LabelText>
        <AvailabilityFieldSet />
      </Spacer>

      <Spacer {...spacerProps} borderTop={1}>
        <LocalisationTabs hideActiveTabHeadline>
          {(values.channelLocalisations || []).map((loc, index) => (
            <TabPane
              key={loc.language}
              id={`locale--${index}`}
              name={loc.language}
            >
              <>
                <LabelText label={t('channel:info.caption.description')}>
                  <p>{t('channel:info.description')}</p>
                  <p>{t('channel:info_text_desc')}</p>
                </LabelText>
                <LabeledInfoText
                  fieldName={`channelLocalisations[${index}].description`}
                />

                <Spacer {...spacerProps} borderTop={1}>
                  <LabelText label={`${t('channel:info.caption.legal')}*`}>
                    <p>{t('channel:info.legal')}</p>
                    <p>{t('channel:info_text_desc')}</p>
                  </LabelText>
                  <LabeledInfoText
                    fieldName={`channelLocalisations[${index}].legalInfo`}
                  />
                </Spacer>
              </>
            </TabPane>
          ))}
        </LocalisationTabs>
      </Spacer>

      <Controls
        left={
          <Button
            text={t('delete')}
            appearance="ghost"
            onClick={openDeleteModal}
          />
        }
        right={
          <>
            <Button
              text={t('cancel')}
              appearance="ghost"
              onClick={onCancelClick}
              key={'cancel-button'}
            />

            <Button
              text={t('save')}
              type="submit"
              loading={loadingButton.save}
              key={'save-button'}
            />
          </>
        }
      />
      <ConfirmModal
        isOpen={deleteModalIsOpen}
        headline={t('delete')}
        text={t('channel:really_delete', {
          title: values.name,
        })}
        onCancelClick={closeDeleteModal}
        confirmText={t('delete')}
        onConfirmClick={onDeleteClick}
        confirmLoading={loadingButton.delete}
      />
      <ConfirmModal
        isOpen={cancelModalIsOpen}
        headline={t('cancel')}
        text={t('channel:really_delete_changes')}
        cancelText={t('no')}
        onCancelClick={closeCancelModal}
        confirmText={t('leave')}
        onConfirmClick={onCancelComfirm}
        confirmLink={!edit ? '/channels' : undefined}
      />
    </>
  );
};

export default ChannelForm;
